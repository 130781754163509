import { fetchDigestsData } from 'Api';
import React, { useState } from 'react';
import { useEffect } from 'react';
import styled from 'styled-components';

const ExistingArticleContainer = styled.div`
  max-width: 40%;
  border: 1px solid grey;
  margin-top: 5px;
  max-height: 450px;
  overflow-y: scroll;
  overflox-x: hidden;
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;
const BoxHeadingContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin: 5px;
  padding: 5px;
`;
const BoxTitle = styled.p`
  font-weight: bold;
  font-size: 16px;
  margin: 0px;
`;

const ExistingArticleSearchBox = styled.input`
  min-width: 100%;
  padding: 4px;
`;

const CancelButton = styled.p`
  margin: 0px;
  cursor: pointer;
`;

const ArticleContainer = styled.div`
  padding: 14px;
  background: #f1f1f1;
  margin: 2px;
  cursor: pointer;
`;

let fe_url = 'https://news.travelnewsdigest.in';
switch (process.env.REACT_APP_ENV) {
  case 'oped-production':
    fe_url = 'https://news.getdailybrief.com';
    break;
  case 'clip-production':
    fe_url = 'https://watch.sphr.tv'; // 'https://theclipapp.com';
    break;
  case 'oped-staging':
    fe_url = 'https://news.travelnewsdigest.in';
    break;
  case 'und-production':
    fe_url = 'https://read.urbannewsdigest.in';
    break;
  case 'und-staging':
    fe_url = 'https://staging-site.urbannewsdigest.in';
    break;
  case 'staging':
    fe_url = 'https://news.getdailybrief.com/';
    break;
  case 'production':
    fe_url = 'https://news.travelnewsdigest.in';
    break;
  default:
    break;
}

const LinkExistingArticle = ({ handleClose, setSelectedArticle }) => {
  const [searchBoxVal, setSearchBoxVal] = useState();
  const [articleData, setArticleData] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchBoxVal) {
        setArticleData([]);
        setLoadingList(true);
        fetchDigestsData({ search: searchBoxVal }).then((res) => {
          setArticleData(res.data?.results);
          setLoadingList(false);
        });
      }
    }, 2000);

    return () => clearTimeout(delayDebounceFn);
  }, [searchBoxVal]);

  return (
    <>
      <ExistingArticleContainer>
        <ExistingArticleSearchBox
          value={searchBoxVal}
          onChange={(e) => setSearchBoxVal(e.target.value)}
        />
        <BoxHeadingContainer>
          <CancelButton onClick={handleClose}>X</CancelButton>
          <BoxTitle>Add a Link</BoxTitle>
        </BoxHeadingContainer>
        <hr />

        {loadingList && <p>Loading...</p>}

        {searchBoxVal &&
          articleData.length > 0 &&
          articleData?.map(({ title, slug }) => (
            <ArticleContainer
              onClick={() => {
                setSelectedArticle(`${fe_url}/digests/${slug}`);
                handleClose();
              }}
              key={title}
            >
              <p>{title}</p>
            </ArticleContainer>
          ))}
      </ExistingArticleContainer>
    </>
  );
};

export default LinkExistingArticle;
