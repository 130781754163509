import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect } from 'react';
import {tinyMceApiKey} from './EditorFile'
import { useState } from 'react';

const FAQAnswerEditor = ({
  setShowLinkArticleBox = (() => {}),
  showLinkArticleBox = false,
  editorRef,
  handleEditorChange,
  data,
  name,
  disabled,
  setOpenVersion =() => {},
  openVersion = "",
  onBlur = ()=>{},
}) => {  
  const [editorInitialValu, setEditorInitialValu] = useState(data);

  useEffect(() => {
    if (!editorInitialValu?.replace(/(<([^>]+)>)/gi, '') && data?.replace(/(<([^>]+)>)/gi, '')) {
      setEditorInitialValu(data);
    }
    // eslint-disable-next-line
  }, [data]);

  return (
    <>
      <Editor  
      disabled={disabled}
        initialValue={editorInitialValu}        
        id={name}
        onEditorChange={(content) => {
          handleEditorChange(content);         
        }}
        onBlur={onBlur}
        apiKey={tinyMceApiKey}
        onInit={(evt, editor) => (editorRef.current = editor)}
        init={{
          contextmenu: true,         

          setup: (editor) => {
            editor.on('input', (e) => {
              e.preventDefault();
              handleEditorChange(e.target?.innerHTML);
            });
            editor.on('ScrollIntoView', (e) => {
              e.preventDefault();
            });

          
            editor.on('keydown', function (e) {
              if (e.keyCode === 56 && e.ctrlKey && e.shiftKey) {
                e.preventDefault();
                editor.execCommand('InsertUnorderedList');
              }
            });
           
            editor.ui.registry.addButton('linkArticle', {
              icon: 'document-properties',
              onAction: () => {
                setShowLinkArticleBox(!showLinkArticleBox);
              },
            });

            editor.ui.registry.addButton('version', {
              icon: 'insert-time',
              onAction: () => {
                setOpenVersion(!openVersion);
              },
            });
          },
          toolbar_mode: 'wrap',
          resize: false,
          height: 450,
          menubar: false,        
          plugins: [
            'advlist',
            'lists',
            'link',          
            'anchor',                       
          ],
          toolbar:          
            'blocks | lists bold italic forecolor | alignleft aligncenter alignright | bullist numlist outdent indent | removeformat  link linkArticle',
          content_css:
            'https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap',
          content_style: `body { font-family: 'PT Serif', serif; font-size: 20px; }`,
        }}
      />
    </>
  );
};

export default FAQAnswerEditor;
