/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable camelcase */
/* eslint-disable react/button-has-type */
/* eslint-disable indent */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useReducer, useCallback, useState } from 'react';
import styled from 'styled-components';
import {  TimePicker } from 'antd';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import produce from 'immer';
import { Multiselect } from 'multiselect-react-dropdown';

import {
  Input,
  Button,
  Icon,
  Image,
  Checkbox,
  Select,
  Dropdown,
  Accordion,
  Grid,
  Modal,
  Loader,
} from 'semantic-ui-react';
import ReactPlayer from 'react-player';
import { DebounceInput } from 'react-debounce-input';
import TextareaAutosize from 'react-textarea-autosize';
import Datetime from 'react-datetime';
import Chips from 'react-chips';
import Parser from 'html-react-parser';
import 'react-datetime/css/react-datetime.css';
import createMentionPlugin from 'draft-js-mention-plugin';
import 'draft-js-mention-plugin/lib/plugin.css';
import Editor from 'draft-js-plugins-editor';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import moment from 'moment';
import createToolbarPlugin from 'draft-js-static-toolbar-plugin';
import createLinkPlugin from 'draft-js-anchor-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import { EditorState } from 'draft-js';
import UploadImageComponent from './UploadImage';
import Toggle from '../../Components/Form/Toggle';
import ToggleSpecial from '../../Components/Form/ToggleSpecial';
import { convertSecondsToTimeFormat } from '../../Utils/convertTime';
import LowercaseIcon from './lowercase.svg';
import PollComponent, {
  LeftWrapper,
  ToggleLabel,
  Label,
  ToggleWrapper,
  FieldErrorMessage,
  FieldWrapper,
} from './PollComponent';
import { shelfLifeOption, clickActionsData, editorScoreQualityOptions } from './StaticOptions';
import VisibleFieldsComp from './VisibleFields';
import TableView from '../../Components/TableView';
import { fetchAllStatuses, fetchDigests, collaboratorsSearch, fetchLabels, generateFAQs, generateAnswers, regenerateFAQAnswerById, updateSaveFAQQuestions, updateFAQsStatus } from '../../Api';
import TranscriptList from './TranscriptList';
import EditorFile from './EditorFile';
import TagsModal from './TagsModal';
import LinkExistingArticle from 'Components/LinkExistingArticle';
import VersionControl from './VersionControl';
import TwitterPlayer from 'Components/TwitterPlayer';
import ConfirmationModal from './QuestionAnswerModal';
import FAQQuestionsSection from './FAQQuestionsSection';
import CuratedTweetForm from './CuratedTweetForm';

const toSentenceCase = (str) =>
  str?.toLowerCase()?.replace(/(^\s*\w|[.!?]\s*\w)/g, (c) => c?.toUpperCase());

const Wrapper = styled.div`
  // padding-bottom: 100px;
  -webkit-font-smoothing: antialiased;
  position: relative;
  #react-autowhatever-1 {
    background-color: #000;
    ul {
      max-height: 150px;
      overflow: auto;
    }
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${({ isYtVideo }) => (isYtVideo ? 'auto' : '287px')};
  overflow: hidden;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  img {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
  }
`;

const ImageContainerRight = styled.div`
  width: 100%;
  height: 50px;
`;

const VideoContainer = styled.div`
  width: 100%;
  height: calc(100% - 15px);
  position: relative;
  padding-top: 56.25%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const CopyIconImage = styled.div`
  padding: 8px 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px #000;
  position: absolute;
  right: 10px;
  bottom: 50px;
  background-color: #fff;
  cursor: pointer;
  i {
    margin-right: 0;
  }
`;

const ArrowIconImage = styled(CopyIconImage)`
  top: 105px;
  right: 28px;
  position: fixed;
  bottom: auto;
  @media (max-width: 580px) {
    display: none !important;
  }
`;

const ContentStatusWrapper = styled(Grid.Column)`
  @media (max-width: 580px) {
    display: none !important;
  }
`;

const SummaryGridColumn = styled(Grid.Column)`
  @media (max-width: 580px) {
    width: 100% !important;
  }
`;

export const HintText = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: #a9a9a9;
  margin: ${({ marginLeft }) => `5px 0 5px ${marginLeft || 70}px`};
  display: block;
  font-weight: 800;
`;

const EditorsWrapperContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const ToggleWrapperScore = styled(ContentWrapper)`
  border: 2px solid #d4d4d5;
  padding: 10px;
  text-align: center;
  margin: 20px 0;
  > div {
    margin-bottom: 5px;
    justify-content: center;
  }
`;

const RightContainer = styled.div`
  position: fixed;
  top: 150px;
  bottom: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  // right: ${({ showFullRight }) => (showFullRight ? '100px' : '65px')};
`;

const IconDiv = styled.div``;

const TitleFieldWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  flex: 1;
`;

const TitleFieldIcon = styled.div`
  width: 25px;
  cursor: pointer;
`;

const TitleField = styled(TextareaAutosize)`
  font-size: 32px !important;
  font-weight: 500;
  width: 100%;
  border: none !important;
  border-left: 2px solid #d4d4d5 !important;
  font-family: Georgia, serif !important;
  color: rgba(0, 0, 0, 0.8) !important;
  padding: 0.3em 0.5em !important;
  overflow: hidden;
  background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
  flex: 1;
  &:hover {
    outline: none !important;
  }
  @media (max-width: 1024px) {
    font-size: 24px !important;
    line-height: 30px !important;
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: 0 auto;
    line-height: 1.3 !important;
    font-size: 24px !important;
  }
  @media (max-width: 560px) {
    line-height: 1.3 !important;
    font-size: 24px !important;
  }
`;

const TimePickerField = styled(TimePicker)`
  width: 100%;
  @media (max-width: 768px) {
    width: 90%;
  }
  input {
    font-size: 15px !important;
    // border: none !important;
    // border-left: 2px solid #d4d4d5 !important;
    font-family: Georgia, serif !important;
    color: rgba(0, 0, 0, 0.8) !important;
    padding: 0.3em 0.5em !important;
    overflow: hidden;
    background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    flex: 1;
    &:hover {
      outline: none !important;
    }
    @media (max-width: 1024px) {
      font-size: 15px !important;
      // line-height: 30px !important;
    }
    @media (max-width: 768px) {
      margin: 0 auto;
      // line-height: 1.3 !important;
      font-size: 15px !important;
    }
    @media (max-width: 560px) {
      // line-height: 1.3 !important;
      font-size: 15px !important;
    }
  }
`;

const SegmentSegregate = styled.div`
  border-top: 2px solid #d4d4d5 !important;
  margin-top: 25px;
  font-size: 16px;
  line-height: 16px;
  color: #000;
  padding: 15px 0 0;
  font-weight: 600;
`;
const DisplayWrapper = styled.div`
  border-top: 2px solid #d4d4d5 !important;
  margin-top: 25px;
  font-size: 16px;
  line-height: 16px;
  color: #000;
  padding: 15px 0 0;
  font-weight: 600;
`;
const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-around;
`;

const SegmentDurationWrapper = styled.div`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 10px;

  span {
    font-weight: 500;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  button {
    width: 100%;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
`;

const AccordionPanel = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

const AccordionContent = styled(Accordion.Content)`
  display: block;
  background-color: rgb(255, 255, 255);
  max-height: 200px;
  position: relative;
  padding: 10px !important;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(233, 233, 233);
  border-image: initial;
  overflow: auto;
  margin: -4px auto 0px;
`;

const AccordionIcon = styled(Icon)`
  color: #f32826;
  font-size: 14px !important;
  font-weight: bolder !important;
`;

const CountWrapper = styled.div`
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: space-between;
`;

export const InputElement = styled(DebounceInput)`
  width: 100%;
  font-size: 16px !important;
  /* input { */
  background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
  border: none !important;
  border-left: ${(params) =>
    params.disabled ? '2px solid #8d8d98' : '2px solid #d4d4d5'} !important;
  &:focus {
    background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
  }
  padding: 5px 10px;
  /* } */
`;

const AuthorElement = styled(InputElement)`
  /* > input { */
  color: rgba(0, 0, 0, 0.8) !important;
  font-family: Georgia, serif !important;
  border: none !important;
  border-left: 2px solid #d4d4d5 !important;
  /* } */
`;

const AddRemoveAuthor = styled.div`
  display: flex;
  align-items: center;
  div {
    cursor: pointer;
  }
`;

const DateTimeWrapper = styled.div`
  flex: 1;
  font-weight: 600 !important;
  font-size: 16px !important;
  border-left: 2px solid #d4d4d5;
  .rdt {
    width: 100%;
    height: 100%;
    input {
      width: 100%;
      height: 100%;
      font-family: Metropolis, sans-serif !important;
      text-transform: uppercase;
      border: none !important;
      outline: none !important;
      color: rgba(0, 0, 0, 0.8) !important;
      padding: 15px !important;
    }
  }
  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const SubtitleElement = styled(Input)`
  width: 100%;
  input {
    background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    border: none !important;
    border-left: ${(params) =>
      params.disabled ? '2px solid #8d8d98' : '2px solid #d4d4d5'} !important;
    &:focus {
      background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    }
    font-size: 16px !important;
    padding: 5px 10px;
  }
`;

const NotesField = styled(InputElement)`
  font-size: 16px !important;
  font-weight: 500;
  width: 100%;
  border: none !important;
  border-left: 2px solid #d4d4d5 !important;
  color: rgba(0, 0, 0, 0.8) !important;
  padding: 0.3em 0.5em !important;
  overflow: hidden;
  background-color: #fff !important;
  &:hover {
    outline: none !important;
  }
`;

const DropdownWrapper = styled.div`
  margin: 10px 0;
  padding-left: 20px;
  border-left: 2px solid #d4d4d5;
  div {
    border: none !important;
  }
  .multi-select .arrow {
    position: absolute;
    width: 100%;
    padding: 7px;
    left: 0;
    top: 0;
    text-align: right;
  }
  .selected-options-badges {
    &::after {
      display: none;
    }
  }
  input {
    min-width: 80px;
  }
`;

const TagsListWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const AddTagsBtn = styled.div`
  padding: 6px 15px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4px;
`;

const ChipWrapper = styled.div`
  position: relative;
  background-color: rgb(233, 233, 233);
  color: rgb(128, 128, 128);
  font-size: 14px;
  font-weight: 500;
  padding: 5px 30px 5px 10px;
  margin: 0 10px 0 0px;
  border-radius: 4px;
  font-family: Metropolis, sans-serif;
`;

const ChipCloseIcon = styled.div`
  background-color: #fff;
  padding: 1px 2px;
  position: absolute;
  top: 4px;
  right: 2px;
  border-radius: 50%;
  cursor: pointer;
  i {
    color: rgb(128, 128, 128);
    margin: 0;
  }
`;

const ChoiceWrapper = styled.div`
  width: 100%;
  button {
    margin: 10px 0 !important;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
`;

const SlugWrapper = styled(ChoiceWrapper)`
  button {
    background-color: transparent !important;
    color: #000 !important;
    padding: 0;
  }
`;

const GridColumns = styled.div`
  display: flex;
  align-items: center;
  button {
    margin: 0 !important;
  }
  span {
    width: 80px;
  }
`;

const Slug = styled(InputElement)`
  color: rgba(0, 0, 0, 0.8) !important;
  font-family: Georgia, serif !important;
  border: none !important;
  border-left: 2px solid #d4d4d5 !important;
  /* input {
    ${(props) => (props.published ? '40px' : 0)};
    border:  none !important;
  } */
`;

const CheckboxFieldWrapper = styled.div`
  border-left: 2px solid #d4d4d5 !important;
  display: flex;
  align-items: center;
`;

const CheckboxFieldLabel = styled.div`
  font-size: 16px;
  line-height: 16px;
  color: #000;
  margin: 5px 15px 5px 5px;
  display: block;
  font-weight: 800;
  cursor: pointer;
`;

const CheckboxWrapper = styled(Checkbox)`
  margin-bottom: ${(props) => (props.published ? '40px' : 0)};
`;

const AssignToDropdown = styled.div`
  flex: 1;
  padding-left: 15px;
  border-left: 2px solid #d4d4d5;
  .description {
    margin-bottom: 15px !important;
    margin-left: 0 !important;
  }
`;

const ClickActionTypeWrapper = styled.div`
  flex: 1;
  padding-left: 15px;
  margin-top: 10px;
`;

const ClickActionSubWrapper = styled.div`
  margin-top: 10px;
`;

const ErrorMessage = styled.div`
  color: #cc0000;
  font-size: 14px;
  text-align: ${({ textAlign }) => textAlign || 'center'};
  margin-top: 5px;
`;

const EditorScoreLabel = styled.span`
  font-size: 14px;
  line-height: 14px;
  color: #a9a9a9;
  margin-bottom: 5px;
  display: block;
  font-weight: 800;
  margin-top: 6px;
  width: 100px;
`;

const EditorScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const EditorScoreDropDownWrapper = styled.div`
  flex: 1;
`;

const PublishButtonWrapper = styled.div`
  position: absolute;
  bottom: ${(props) => (props.published ? '40px' : 0)};
  display: flex;
  left: 0;
  width: 100%;
  justify-content: space-between;
  button {
    width: 48%;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
  .selection,
  .ui.selection.visible.dropdown > .text:not(.default) {
    width: 48%;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
    text-align: center;
  }
`;

const CustomContent = styled.div`
  padding: ${({ pd }) => pd || '10px'};
  ul {
    padding-inline-start: 0;
  }
`;

const EditorContent = styled.div`
  font-size: 20px;
  min-height: ${({ mh }) => mh || '100px'};
  line-height: 1.5;
  font-family: Georgia, serif;
  color: rgba(0, 0, 0, 0.8);
  -moz-osx-font-smoothing: grayscale;
  a {
    pointer-events: auto !important;
    color: #4183c4 !important;
    text-decoration: none !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }
  ins {
    text-decoration: none;
  }
  div {
    border: none !important;
  }
  img {
    max-width: 100%;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  a {
    pointer-events: auto !important;
    color: #4183c4 !important;
    text-decoration: none !important;
    font-size: inherit !important;
    line-height: inherit !important;
  }
  p {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 12px;
    @media (max-width: 768px) {
      font-size: 16px;
      margin-bottom: 10px;
    }
  }
  li {
    margin: 0 0 8px 0;
  }
  blockquote {
    margin: 15px 0;
    display: block;
    font-style: italic;
    position: relative;
    color: rgba(0, 0, 0, 0.8);
    border-left: none;
    &:before {
      content: '';
      width: 4px;
      height: 100%;
      background-color: #333;
      position: absolute;
      top: 0;
      left: -15px;
    }
    @media (max-width: 768px) {
      &:before {
        left: -10px;
      }
    }
  }
  a {
    color: #15c !important;
    text-decoration: underline;
    pointer-events: none;
    background: transparent !important;
    font-size: 32px;
    line-height: 1.3;
    font-weight: 500;
    font-family: Georgia, serif;
    @media (max-width: 1024px) {
      font-size: 24px;
      line-height: 30px;
    }
    @media (max-width: 768px) {
      font-size: 24px;
      line-height: 1.3;
      width: 90%;
      margin: 0 auto;
    }
    @media (max-width: 560px) {
      font-size: 24px;
      line-height: 1.3;
    }
  }
`;

const FocusField = styled.div`
  box-sizing: border-box;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  border-left: 2px solid #d4d4d5;
  width: 100%;
  .editor:global(.public-DraftEditor-content) {
    min-height: 50px;
  }
`;
const RadioWrapper = styled.div`
  display: flex;
  width: 50%;
  justify-content: space-between;
`;

const FocusContent = styled(EditorContent)`
  min-height: 50px;
  a {
    color: rgba(0, 0, 0, 0.8) !important;
    text-decoration: none;
    cursor: auto;
  }
`;

const ViewPublishButtonWrapper = styled(PublishButtonWrapper)`
  bottom: 0;
  a {
    width: 100%;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    vertical-align: baseline;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    user-select: none;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
      box-shadow 0.1s ease background 0.1s ease;
    -webkit-tap-highlight-color: transparent;
  }
`;

const CountTextButton = styled.span`
  text-align: right;
  background-color: rgb(243, 40, 38);
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: #fff;
  cursor: pointer;
  margin-right: 5px;
  float: right;
`;

const EditorWrapper = styled.div`
  flex: 1;
  .draftJsEmojiPlugin__buttonWrapper__1Dmqh:nth-child(2) {
    display: none;
  }
  .draftJsEmojiPlugin__buttonWrapper__1Dmqh:nth-child(4) {
    display: none;
  }
  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const InputField = styled(Input)`
  width: 100%;
  input {
    background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    &:focus {
      background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    }
  }
`;

const LastModifiedAt = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: #a9a9a9;
  margin: 5px 0 25px 5px;
  display: block;
  font-weight: 800;
`;

const Timer = styled(InputField)`
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  height: 31px;
  font-size: 15px !important;
`;

const ContentBox = styled.div`
  background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
  box-sizing: border-box;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  border-left: 2px solid #d4d4d5;
  position: relative;
  width: 100%;
  .editor:global(.public-DraftEditor-content) {
    min-height: 140px;
  }
`;

// const ToolbarWrapper = styled.div`
//   position: ${(props) => (props.scrollabel ? 'fixed' : '')};
//   margin-top: -37px;
//   z-index: 1000;
//   top: ${(props) => (props.scrollabel ? '150px' : '')};
//   background-color: ${(props) => (props.scrollabel ? 'white' : 'transparent')};
//   padding-bottom: 10px;
//   border-bottom: 1px solid #ddd;
//   button {
//     background: transparent;
//     border: none;
//     cursor: pointer;
//   }
//   input {
//     width: 50%;
//     padding: 10px;
//   }
// `;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 0 5px;
`;

const HintWrapper = styled.div`
  justify-content: center;
  display: flex;
`;

const TitleInput = styled(TitleField)`
  font-size: 15px !important;
`;

const TitleTab = styled(Accordion.Title)`
  box-shadow: #d8d8d8 0px 0px 10px -2px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: ${(props) => (props.active ? '#f6f6f6' : '#ffffff')};
  font-weight: ${(props) => (props.active ? 900 : 500)};
  z-index: 2;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #e9e9e9;
  border-image: initial;
  border-left: ${(props) => (props.active ? '8px solid #f32826' : '1px solid #e9e9e9')};
  overflow: hidden;
  padding: 20px 10px 20px 14px !important;
`;

const Tag = styled.div`
  width: 115px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: ${(params) => (params.mustWatch ? '#f32826' : '#3bb300')};
  color: #ffffff;
  text-align: center;
  padding: 2px 5px 3px;
  text-align: center;
  text-transform: uppercase;
  padding: 2px 5px 3px;
`;

const Time = styled.div`
  font-size: 12px;
  color: ${(props) => (props.active ? '#00000' : '#b0b0b0 !important')};
`;

const CheckboxColumn = styled.div`
  padding: 7;
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'UPLOAD_IMAGE_STATUS':
        if (action.addNewUpload) {
          draft.addNewUpload = action.addNewUpload;
          draft.uploadImageType = action.uploadImageType;
        } else if (action.status) {
          draft.addNewUpload = false;
          draft.uploadImageType = null;
        }
        draft.uploadImage = action.status;
        break;
      case 'CONTINUE_EDIT':
        draft.continueEditing = !draft.continueEditing;
        break;
      case 'SET_STATUSES':
        draft.statuses = action.value.map((status) => ({
          key: status.status_name,
          value: status.status_name,
          text: status.status_name,
        }));
        break;
      case 'SET_SUGGESTIONS':
        draft.suggestions = action.values;
        break;
      case 'SET_SUGGESTIONS_FOCUS':
        draft.suggestionsFocus = action.values;
        break;
      case 'SET_TOGGLE_ADVANCE_SETTINGS':
        draft.showAdvanceSetting = !draft.showAdvanceSetting;
        break;
      case 'SET_QUESTIONS_AND_ANSWERS':
        draft.showQuestionsandAnswers = action.value;
        break;
      case 'SET_FOCUS_EDITOR_STATE':
        draft.focusedEditorState = action.value;
        break;
      case 'SET_ACTIVE':
        if (draft.active !== action.tab) {
          draft.active = action.tab;
          break;
        }
        break;
      case 'SET_TAGS_MODAL_SHOW':
        draft.selectTagsModalShow = action.value;
        break;
      case 'QUESTION_ANSWER_MODAL_SHOW':
        draft.selectQuestionAnswerModalShow = action.value;
        break;
      case 'SET_FAQ_QUESTIONS_AND_ANSWERS':
        draft.faqQuestions = action.value;
        break;
      case 'SET_GENERATING_LOADER':
        draft.faqQuestionsGenLoader = action.value;
        break;
      case 'SET_REGENERATING_LOADER':
        draft.regenerateAnswerLoading = action.value;
        break;
      case 'UPDATE_REGENERATE_ANSWER_ID_LIST':
        draft.regenerateAnswersList = action.value;
        break;
      case 'FINAL_UPDATE_FAQ_QUESTIONS_ANSWERS':
        draft.updateSavingAllQuestionsLoader = action.value;
        break;
      default: break;
    }
  });

export const convertTimeToSeconds = (time) => {
  const a = time.split(':'); // split it at the colons
  if (a.length === 2) {
    a.unshift('00');
  } else if (a.length === 3) {
    a.unshift('00');
    a.unshift('00');
  }
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;
};

const calcluateSegmentDuration = (list) => {
  let totalDurationMilliSec = 0;

  if (list && list.length > 0) {
    list
      // .filter((item) => item.is_for_preview)
      .forEach((item) => {
        const diff =
          convertTimeToSeconds(item.timestamp_end) - convertTimeToSeconds(item.timestamp_start);

        totalDurationMilliSec += diff;
      });
  }
  return convertSecondsToTimeFormat(totalDurationMilliSec);
};
const initialState = {
  addNewUpload: false,
  uploadImage: false,
  continueEditing: false,
  showAdvanceSetting: false,
  showQuestionsandAnswers: false,
  faqQuestions: [],
  statuses: [],
  focusedEditorState: EditorState.createEmpty(),
  isFocusStateEditor: false,
  suggestions: [
    {
      name: '',
      link: '',
    },
  ],
  suggestionsFocus: [
    {
      name: '',
      link: '',
    },
  ],
  active: 0,
  selectTagsModalShow: false,
  selectQuestionAnswerModalShow: false,
  faqQuestionsGenLoader: false,
  regenerateAnswerLoading: false,
  regenerateAnswersList: [],
  updateSavingAllQuestionsLoader: false,
};

const staticToolbarPlugin = createToolbarPlugin();
// const { Toolbar } = staticToolbarPlugin;
const mentionPlugin = createMentionPlugin({
  mentions: [
    {
      link: '',
      name: '',
    },
  ],
  // mentionPrefix: '@'
});

// const { MentionSuggestions } = mentionPlugin;

const linkPlugin = createLinkPlugin();
const imagePlugin = createImagePlugin();
const plugins = [staticToolbarPlugin, mentionPlugin, linkPlugin, imagePlugin];
const mentionPluginFocus = createMentionPlugin({
  mentions: [
    {
      link: '',
      name: '',
    },
  ],
});
const { MentionSuggestions: Mentions } = mentionPluginFocus;
const pluginsFocus = [mentionPluginFocus];

let feUrl = 'https://news.getdailybrief.com';
switch (process.env.REACT_APP_ENV) {
  case 'oped-production':
    feUrl = 'https://news.getdailybrief.com';
    break;
  case 'clip-production':
    feUrl = 'https://watch.sphr.tv'; // 'https://theclipapp.com';
    break;
  case 'oped-staging':
    feUrl = 'https://news.getdailybrief.com';
    break;
  case 'und-production':
    feUrl = 'https://read.urbannewsdigest.in';
    break;
  case 'und-staging':
    feUrl = 'https://staging-site.urbannewsdigest.in';
    break;
  case 'staging':
    feUrl = 'https://prod1.opiniondigest.in';
    break;
  case 'production':
    feUrl = 'https://news.travelnewsdigest.in';
    break;
}

const SummerisePage = ({
  handleEditorChange,
  digestId,
  dispatch: dispatchFromParent,
  editorRef,
  openUploadImage,
  data,
  errors,
  onChange,
  removeChoice,
  addChoices,
  onChangeCategoriesOption,
  fetchSuggestions,
  hanldeAddTags,
  handleAddTagsGroups,
  fetchTagSuggestions,
  editSlug,
  setEditSlug,
  scrollabel,
  validateSlug,
  digestTypesData,
  usersData,
  extraTags,
  extraCategories,
  regionsList,
  hostsList,
  articleFormatTypes,
  handleChangeStatus,
  handlePublish,
  loadingPublish,
  modifiedatDigest,
  autoSummarize,
  onChangeActiveSegment,
  segmentList,
  disabledHours,
  disabledMinutes,
  disabledSeconds,
  disabledEndHours,
  disabledEndMinutes,
  disabledEndSeconds,
  onSegmentSave,
  videoRef,
  previewMode,
  videoSummary,
  activeSegment,
  subtitleMode,
  activeTranscript,
  videoTranscriptData,
  onChangeTranscript,
  onChangeTranscriptSegment,
  onClickSegment,
  segmentError,
  videoDigest,
  removeSegment,
  scoreList,
  scoreRelevance,
  checkPlagiarism,
  plagiarised,
  plagiarisedTitle,
  handleSegmentPreview,
  editorScoreText,
  aspectRatio,
  setAspectRatio,
  setCoOrdinate,
  setIsMarkAsStart,
  updateActiveSegment,
  isReset,
  setIsReset,
  FAQData, faqStatus,
  faqId
}) => {
  // function removeHtmlTagsAndEntities(htmlString) {
  //   if (htmlString) {
  //     // Remove HTML entities
  //     const entityPattern = /&[a-z]+;/gi;
  //     htmlString = htmlString?.replace(entityPattern, '');

  //     // Remove HTML tags
  //     const tagPattern = /<[^>]+>/gi;
  //     htmlString = htmlString?.replace(tagPattern, '');

  //     return htmlString;
  //   }
  // }
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    env: `${feUrl}/digests/${data.slug}`,
    focusedEditorState: data.focus_card,
  });
  const [showFullRight, setShowFullRight] = useState(true);
  const [checkPlagiarismModal, setCheckPlagiarismModal] = useState(false);
  // const [aspectRatio, setAspectRatio] = useState(false);
  const [newData] = useState(EditorState.createEmpty());
  const [newTitle, compareTitle] = useState('');
  const [videoDisplayType, setVideoDisplayType] = useState(data.display_type);
  // const [localselectedItem, setLocalSelectItem] = useState(data.collaborators);
  // const [UsersData, setUsersData] = useState(usersData);
  const [selectedOptionValue, setSelectedOptionValue] = useState(null);
  const [selectedLabelsValue, setSelectedLabelsValue] = useState(null);
  const [selectedOptionValueCreatedby, setSelectedOptionValueCreatedBy] = useState(
    data.created_by !== undefined ? [data.created_by] : [],
  );
  const [options, setOptions] = useState([]);
  const [showLinkArticleBox, setShowLinkArticleBox] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState('');
  const [labels, setLabels] = useState([]);
  const [openVersion, setOpenVersion] = useState(false);
  const [currentFaqId, setCurrentFaqId] = useState(null);
  // const dataToText = data?.content ? JSON.stringify(removeHtmlTagsAndEntities(data?.content)) : '';
  // const [file, setFile] = useState();
  // var dataTextLength = dataToText.length > 2 ? dataToText.trim().split(/\s+/).length : 0;

  const [linkValue] = useState('');
  const [toggleLinkBox, setToggleLinkBox] = useState(false);  
  useEffect(() => {
    if (FAQData.length !== 0) {
      dispatch({ type: 'SET_FAQ_QUESTIONS_AND_ANSWERS', value: FAQData });
    }
  }, [FAQData])

  useEffect(()=>{
    if(faqStatus === true){
      dispatch({ type: 'SET_QUESTIONS_AND_ANSWERS', value: true });
    }if(faqStatus === false){
      dispatch({ type: 'SET_QUESTIONS_AND_ANSWERS', value: false });
    }
  },[faqStatus])

  useEffect(()=>{
    if(faqId){
      setCurrentFaqId(faqId);
    }
  },[faqId])

  useEffect(() => {
    callSearchApi('');

    // if (window.innerWidth <= 768) {
    //   setShowFullRight(false);
    // }
  }, []);
  useEffect(() => {
    data.collaborators && setSelectedOptionValue(data.collaborators);
    data.labels && setSelectedLabelsValue(data.labels);
  }, [data]);

  const linkBoxRef = useRef(null);

  useEffect(() => {
    if (toggleLinkBox && linkBoxRef.current) {
      linkBoxRef.current.focus();
    }
  }, [toggleLinkBox]);

  const columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      render: (value, row, index) => <span>{index + 1}</span>,
      width: 50,
    },
    {
      title: 'Preview',
      dataIndex: 'is_for_preview',
      key: 'is_for_preview',
      render: (value, row, index) => (
        <CheckboxColumn
          onClick={(e) => {
            e.stopPropagation();
            handleSegmentPreview(row, index);
          }}
        >
          <CheckboxWrapper
            checked={value}
            // published={data.status === 'Published'}
            onClick={(e) => {
              e.stopPropagation();
              handleSegmentPreview(row, index);
            }}
          />
        </CheckboxColumn>
      ),
      width: 70,
    },
    {
      title: 'Segment Title',
      dataIndex: 'title',
      key: 'title',
      width: 150,
    },
    {
      title: 'Start Time',
      dataIndex: 'timestamp_start',
      // key: 'startTime',
      render: (value, row, index) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
          }}
        >
          <div>
            <Icon
              name="backward"
              onClick={() => {
                if (videoRef && videoRef.current) {
                  const seconds = convertTimeToSeconds(value);
                  if (typeof videoRef.current.seekTo === 'function') {
                    videoRef.current.seekTo(seconds);
                    videoRef.current.pauseVideo();
                  }
                }
              }}
            />
          </div>
          <div>
            <p>{value}</p>
          </div>
        </div>
      ),
      width: 140,
    },
    {
      title: 'End Time',
      dataIndex: 'timestamp_end',
      // key: 'endTime',
      render: (value, row, index) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%',
            flexDirection: 'row',
          }}
        >
          <div>
            <p>{value}</p>
          </div>
          <div>
            <Icon
              name="forward"
              onClick={() => {
                if (videoRef && videoRef.current) {
                  const seconds = convertTimeToSeconds(value);
                  if (typeof videoRef.current.seekTo === 'function') {
                    videoRef.current.seekTo(seconds);
                    videoRef.current.pauseVideo();
                  }
                }
              }}
            />
          </div>
        </div>
      ),
      width: 140,
    },
    {
      title: 'Tag',
      dataIndex: 'watch_label',
      key: 'watchLabel',
      width: 150,
    },
    {
      title: 'SA',
      dataIndex: 'sa',
      key: 'sa',
      width: 150,
      render: (value, row) => (
        <span>
          {row.hasOwnProperty('topLeft')
            ? `${row.topLeft.x_coord_}:${row.topLeft.y_coord}:${row.bottomRight.x_coord_}:${row.bottomRight.y_coord}`
            : ''}
        </span>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      fixed: 'right',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (value, val) => (
        <div className="action-wrapper">
          <ActionWrapper>
            <IconWrapper
              onClick={() => {
                removeSegment(val);
              }}
            >
              <Icon name="archive" size="large" />
            </IconWrapper>
            {/* <IconWrapper
              onClick={() => {
                onCopySegment(val);
              }}
            >
              <Icon name="copy outline" size="large" />
            </IconWrapper> */}
            <IconWrapper
              onClick={() => {
                onClickSegment(val);
              }}
            >
              <Icon name="play" size="large" />
            </IconWrapper>
          </ActionWrapper>
          asdasd
        </div>
      ),
    },
  ];

  const openUploadImageRef = useRef(false);
  const modalInputRef = useRef(null);
  const dragSelectRef = useRef(null);
  const activeRef = useRef(null);
  const intervalRef = useRef(null);

  const {
    uploadImage,
    addNewUpload,
    continueEditing,
    statuses,
    // suggestions,
    showAdvanceSetting,
    showQuestionsandAnswers,
    suggestionsFocus,
    // isFocusStateEditor,
    // focusedEditor,
    focusedEditorState,
    publishErrorMessage,
    uploadImageType,
    active,
    selectTagsModalShow,
    selectQuestionAnswerModalShow,
    faqQuestionsGenLoader,
    faqQuestions,    
    regenerateAnswersList,
    updateSavingAllQuestionsLoader,
  } = state;
  //function to handle first fetching of all questions
  const handleConfirmQAModal = async () => {

    dispatch({ type: 'QUESTION_ANSWER_MODAL_SHOW', value: false });
    dispatch({ type: 'SET_QUESTIONS_AND_ANSWERS', value: true });

    // generate the initial state of questions - answers and then get questions again.
    dispatch({ type: 'SET_GENERATING_LOADER', value: true });

    if(faqQuestions?.length > 0){
      updateFAQsStatus(currentFaqId, !showQuestionsandAnswers).then((res)=> {
        if(res){
          dispatch({ type: 'SET_QUESTIONS_AND_ANSWERS', value : !showQuestionsandAnswers });
        }
      }).catch((error)=> {
        ToastsStore.error(error)
      })
    };

    generateFAQs(digestId).then((response) => {

      // once get initial list of questions

      // start all questions answer generation
      generateAnswers(digestId).then((response) => {

        // once all questions answer generation started

        // get latest list of questions in case there are any answers.
        generateFAQs(digestId).then((response) => {

          // format the data for formik form
          const toArray = Object.entries(response?.data?.question_answer).map(([key, value]) => ({ ...value, is_regenerated: response?.data?.is_answer_generate ? value.is_regenerated : true }));

          dispatch({ type: 'SET_FAQ_QUESTIONS_AND_ANSWERS', value: toArray ?? [] });

          //turn off the loader
          dispatch({ type: 'SET_GENERATING_LOADER', value: false });

        }).catch(() => {

          //turn off the loader
          dispatch({ type: 'SET_GENERATING_LOADER', value: false });
        })
      }).catch(() => {
        //turn off the loader
        dispatch({ type: 'SET_GENERATING_LOADER', value: false });
      })
    }).catch(() => {

      //turn off the loader
      dispatch({ type: 'SET_GENERATING_LOADER', value: false });
    })
  }


  // function to handle regeneration of answer of an particular question
  const handleRegenerateAnswers = async (questionId) => {

    // add the question to loading list
    const updatedList = JSON.parse(JSON.stringify(regenerateAnswersList))
    updatedList.push(questionId)

    // add id to list to start loader for API call.
    dispatch({ type: 'UPDATE_REGENERATE_ANSWER_ID_LIST', value: updatedList })

    // generate the initial state of questions - answers and then get questions again.
    regenerateFAQAnswerById(digestId, questionId).then((response) => {

      // get the latest list of questions and answers
      generateFAQs(digestId).then((response) => {
        const toArray = Object.entries(response?.data?.question_answer).map(([key, value]) => ({ ...value, is_regenerated: response?.data?.is_answer_generate ? value.is_regenerated : true }));

        dispatch({ type: 'SET_FAQ_QUESTIONS_AND_ANSWERS', value: toArray ?? [] });

      }).catch(() => { }).finally(() => {
      })

    }).catch(() => { }).finally(() => {

      // remove id from list to stop loader
      const updatedList = JSON.parse(JSON.stringify(regenerateAnswersList))
      let indexToRemove = updatedList.indexOf(questionId);

      if (indexToRemove !== -1) {
        updatedList.splice(indexToRemove, 1);
      }

      dispatch({ type: 'UPDATE_REGENERATE_ANSWER_ID_LIST', value: updatedList })
    })
  }

  // function to handle saving of all questions and there answer
  const handleSaveAllQuestions = async (data) => {

    // create data payload according to requirements.

    const payload = {}

    data.faqs.forEach((record, index) => {
      payload[`${index + 1}`] = {
        question: record.question,
        answer: record.answer,
      }
    })

    // start updating loading
    dispatch({ type: 'FINAL_UPDATE_FAQ_QUESTIONS_ANSWERS', value: true })

    // generate the initial state of questions - answers and then get questions again.
    updateSaveFAQQuestions(digestId, payload).then(async (response) => {

      // refetch the latest questions and answers
      await generateFAQs(digestId).then((response) => {
        const toArray = Object.entries(response?.data?.question_answer).map(([key, value]) => ({ ...value, is_regenerated: response?.data?.is_answer_generate ? value.is_regenerated : true }));

        dispatch({ type: 'SET_FAQ_QUESTIONS_AND_ANSWERS', value: toArray ?? [] });

      }).catch(() => { }).finally(() => {
      })
    }).catch(() => { }).finally(() => {

      // turn off the updating loader
      dispatch({ type: 'FINAL_UPDATE_FAQ_QUESTIONS_ANSWERS', value: false })
    })
  }

  useEffect(() => {
    activeRef.current = active;
  }, [active]);

  const playVideo = useCallback(
    (interval, videoState) => {
      if (!videoState && segmentList[active] && videoRef.current) {
        // const timeStart = segmentList[active].timestamp_start.split(':');
        const seconds = convertTimeToSeconds(segmentList[active].timestamp_start);
        // const timeEnd = segmentList[active].timestamp_end.split(':');
        videoRef.current.seekTo(seconds);
        clearInterval(intervalRef.current);
        intervalRef.current = setInterval(() => {
          const time = videoRef.current.getCurrentTime();

          const decimalFixedTime = time.toFixed(2);
          const secondsEnd = convertTimeToSeconds(segmentList[activeRef.current].timestamp_end);
          if (decimalFixedTime >= secondsEnd) {
            if (activeRef.current === segmentList.length - 1) {
              const activeTab = 0;
              clearInterval(intervalRef.current);
              videoRef.current.seekTo(0);
              setTimeout(() => {
                dispatch({ type: 'SET_ACTIVE', tab: activeTab });
              }, 10);
            } else {
              const activeTab = activeRef.current + 1;
              dispatch({ type: 'SET_ACTIVE', tab: activeTab });
            }
          }
        }, 50);
      } else clearInterval(intervalRef.current);
    },
    [videoRef, active, segmentList],
  );

  useEffect(() => {
    let interval;
    if (previewMode) {
      playVideo(interval);
    } else if (videoRef.current) {
      playVideo(interval, 'pause');
    }
  }, [previewMode, playVideo, videoRef]);

  useEffect(() => {
    fetchAllStatuses().then((response) => {
      dispatch({
        type: 'SET_STATUSES',
        value: response.data.results || [],
        total: response.data.total,
      });
    });
    fetchLabels({ search: '' }).then((res) => res.data.results && setLabels(res.data.results));
  }, []);
  useEffect(() => {
    if (openUploadImage) {
      openUploadImageRef.current = true;
      dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: true });
    }
  }, [openUploadImage]);

  // const onSearchChange = async ({ value }) => {
  //   const response = await fetchDigests(value, data.id);
  //   let values = [];
  //   if (response.data.results.length) {
  //     values = response.data.results.slice(0, 5).map((val) => ({
  //       id: val.id,
  //       link: val.title,
  //       avatar:
  //         val.cover_pic && val.cover_pic.includes('https://www.youtube.com/watch?v')
  //           ? `https://i3.ytimg.com/vi/${val.cover_pic.substring(
  //               val.cover_pic.indexOf('=') + 1,
  //               val.cover_pic.length,
  //             )}/hqdefault.jpg`
  //           : val.cover_pic,
  //       name: `[@${val.slug}](${val.id})`,
  //     }));
  //   }
  //   dispatch({ type: 'SET_SUGGESTIONS', values });
  // };

  const onSearchFocusChange = async ({ value }) => {
    const response = await fetchDigests(value, data.id);
    let values = [];
    if (response.data.results.length) {
      values = response.data.results.slice(0, 5).map((val) => ({
        id: val.id,
        link: val.title,
        avatar:
          val.cover_pic && val.cover_pic.includes('https://www.youtube.com/watch?v')
            ? `https://i3.ytimg.com/vi/${val.cover_pic.substring(
                val.cover_pic.indexOf('=') + 1,
                val.cover_pic.length,
              )}/hqdefault.jpg`
            : val.cover_pic,
        name: `${val.slug}`,
      }));
    }
    dispatch({ type: 'SET_SUGGESTIONS_FOCUS', values });
  };

  const setActiveTab = (tab) => {
    if (tab !== active) {
      clearInterval(intervalRef.current);
      dispatch({ type: 'SET_ACTIVE', tab });
    }
  };

  const blurMilliSecs = (time) => {
    if (time === 'timestamp_start_ms') {
      const timeStart = activeSegment.timestamp_start;
      const start = timeStart || '00:00:00:00';
      const timeVal = start.split(':');
      if (!timeVal[3]) {
        timeVal[3] = 0;
        onChangeActiveSegment({
          type: 'timestamp_start_ms',
          value: `${timeVal[0]}:${timeVal[1]}:${timeVal[2]}:${timeVal[3]}`,
        });
      }
    }
  };

  const onSelect = (selectedList, selectedItem) => {
    setSelectedOptionValue(selectedList);

    onChange({ type: 'collaborators', text: selectedList });
  };

  const onLabelSelect = (selectedLabelsList) => {
    setSelectedLabelsValue(selectedLabelsList);
    onChange({ type: 'labels', text: selectedLabelsList });
  };

  const onRemove = (selectedList, removedItem) => {
    setSelectedOptionValue(selectedList);
    onChange({ type: 'collaborators', text: selectedList });
  };

  const onLabelRemove = (selectedLabelsList) => {
    setSelectedLabelsValue(selectedLabelsList);

    onChange({ type: 'labels', text: selectedLabelsList });
  };

  const onSelectForCreatedBy = (selectedList, selectedItem) => {
    setSelectedOptionValueCreatedBy(selectedList);
    // const selectId = selectedList.map((element) => element.id);
    onChange({ type: 'created_by', text: selectedList[0] });
  };

  const onRemoveForCreatedBy = (selectedList, removedItem) => {
    setSelectedOptionValueCreatedBy(selectedList);
    // const selectId = selectedList.map((element) => element.id);
    onChange({ type: 'created_by', text: selectedList });
  };

  const callSearchApi = (name) => {
    collaboratorsSearch(name)
      .then((response) => {
        if (response.data.results.length > 0) {
          const mdata = [];
          response.data.results.forEach((x, i) => {
            if (x.name === null) {
              x.name = x.phone || "";
            }
            mdata.push(x);
          });
          setOptions(mdata);
          // setUsersData(response.data.results);
        }
        // const getArry = response.request.filter((el) => el.name === name);
      })
      .catch((error) => {});
  };

  // const debounceEventHandler = (...args) => {
  //   const debounced = debounce(...args)
  //   return function (e) {
  //     e.persist();
  //     return debounced(e);
  //   }
  // }
  // const handleChangeTitle = e => {
  //   if (e.target.value.split(" ").length <= 30) {
  //     onChange({ type: 'title', text: e.target.value });
  //   }
  // }

  // const handleChangeT = debounceEventHandler(handleChangeTitle, 15);
  let titleBg = '#fff';
  if (data.title && data.title.split(' ').length >= 20) titleBg = '#ffb1b1';
  else if (data.title && data.title.split(' ').length >= 12) titleBg = '#ffffcc';

  let subTitleBg = '#fff';
  if (data.subtitle && data.subtitle.split(' ').length >= 10) subTitleBg = '#ffb1b1';
  else if (data.subtitle && data.subtitle.split(' ').length >= 7) subTitleBg = '#ffffcc';

  const imageContainer =
    !data.images ||
    (data.images &&
      !(
        data.images.includes('https://twitter.com/') ||
        data.images.includes('https://www.youtube.com/watch?v') ||
        data.images.includes('https://youtu.be/') ||
        data.images.includes('https://stream.mux.com/') ||
        data.images.includes('.mp4')
      ));
  // data.images.includes('https://youtu.be/')
  // const singleColumn = videoDigest || localStorage.getItem('privilege') !== '33';
  const singleColumn = false;
  let gridWidth;
  if (singleColumn) gridWidth = 16;
  else if (!showFullRight) gridWidth = 13;
  else gridWidth = window.innerWidth <= 580 ? 16 : 12;

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleOk = () => {
  //   setIsModalVisible(false);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };
  const handleToggleClick = () => {
    if (showQuestionsandAnswers) {
      updateFAQsStatus(currentFaqId, !showQuestionsandAnswers).then((res)=> {
        if(res){
          dispatch({ type: 'SET_QUESTIONS_AND_ANSWERS', value : !showQuestionsandAnswers });
        }
      }).catch((error)=> {
        ToastsStore.error(error)
      })
    } else {
      dispatch({ type: 'QUESTION_ANSWER_MODAL_SHOW', value: true });
    }
  };

  return (
    <Wrapper key="summerisePageWrapper">
      {data && (
        <>
          <Helmet>
            <title>{data && data.title ? `XND | ${data.title}` : 'XND | Edit Digest'}</title>
          </Helmet>
          <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
          {subtitleMode && (
            <TranscriptList
              activeSegment={activeSegment}
              dragSelectRef={dragSelectRef}
              activeTranscript={activeTranscript}
              videoTranscriptData={videoTranscriptData}
              onChangeTranscript={onChangeTranscript}
              onSelectTranscript={(value) => {
                let currentSegmentStartTime = null;
                let currentSegmentEndTime = null;
                if (value.length === 1) {
                  currentSegmentStartTime = videoTranscriptData[value[0]].start;
                  currentSegmentEndTime = (
                    videoTranscriptData[value[0]].start + videoTranscriptData[value[0]].duration
                  ).toFixed(2);
                } else if (value.length > 1) {
                  currentSegmentStartTime = videoTranscriptData[value[0]].start;
                  currentSegmentEndTime = (
                    videoTranscriptData[value[value.length - 1]].start +
                    videoTranscriptData[value[value.length - 1]].duration
                  ).toFixed(2);
                }
                currentSegmentStartTime = convertSecondsToTimeFormat(currentSegmentStartTime);
                currentSegmentEndTime = convertSecondsToTimeFormat(currentSegmentEndTime);
                currentSegmentStartTime = moment(
                  `${moment().format('DD:MM:YY')} ${currentSegmentStartTime}`,
                  'DD:MM:YY HH:mm:ss:SS',
                );
                currentSegmentEndTime = moment(
                  `${moment().format('DD:MM:YY')} ${currentSegmentEndTime}`,
                  'DD:MM:YY HH:mm:ss:SS',
                );
                onChangeTranscriptSegment({
                  start: currentSegmentStartTime,
                  end: currentSegmentEndTime,
                });
              }}
            />
          )}
          {!previewMode ? (
            <>
              <Grid padded="vertically" columns={singleColumn ? 1 : 2} style={{ border: 'none' }}>
                <SummaryGridColumn width={gridWidth} style={{ paddingBottom: 100, border: 'none' }}>
                  <ImageWrapper
                    isYtVideo={
                      data.images &&
                      (data.images.includes('https://www.youtube.com/watch?v') ||
                        data.images.includes('https://youtu.be/') ||
                        data.images.includes('https://stream.mux.com/') ||
                        data.images.includes('.mp4'))
                    }
                  >
                    {imageContainer ? (
                      data.thumbnailImage.includes('https://twitter.com/')  ?
                      <VideoContainer>
                       <TwitterPlayer tweetLink={data.thumbnailImage} /> 
                       </VideoContainer>
                       : 
                      <ImageContainer imageUrl={data.images}>
                        {data.images && <Image src={data.images} />}
                        <CopyIconImage
                          onClick={() => {
                            setAspectRatio(true);
                            dispatch({
                              type: 'UPLOAD_IMAGE_STATUS',
                              status: true,
                              addNewUpload: true,
                            });
                          }}
                        >
                          <Icon name="plus" size="large" />
                        </CopyIconImage>
                      </ImageContainer>
                    ) : (
                      <VideoContainer>
                        {data.images.includes('https://twitter.com/')  ?(
                          <TwitterPlayer tweetLink={data.images} />
                        ) : (
                          <ReactPlayer
                            controls
                            playing
                            volume="0"
                            className="react-player"
                            muted
                            url={data.images}
                            width="100%"
                            height="100%"
                            config={{
                              youtube: {
                                // playerVars: { showinfo: 0 }
                              },
                            }}
                          />
                        )}
                        {/* <VideoFooter> */}
                        {!videoDigest && (
                          <>
                            <ArrowIconImage onClick={() => setShowFullRight(!showFullRight)}>
                              <Icon
                                name={!showFullRight ? 'arrow right' : 'arrow left'}
                                size="large"
                              />
                            </ArrowIconImage>
                            <CopyIconImage
                              onClick={() =>
                                dispatch({
                                  type: 'UPLOAD_IMAGE_STATUS',
                                  status: true,
                                  addNewUpload: true,
                                })
                              }
                            >
                              <Icon name="plus" size="large" />
                            </CopyIconImage>
                          </>
                        )}
                        {/* </VideoFooter> */}
                      </VideoContainer>
                    )}
                    <HintText marginLeft={5}>Cover Pic/ Video.</HintText>
                    <FieldErrorMessage
                      style={{
                        marginLeft: 0,
                      }}
                      fontSize={errors.transcriptError ? 20 : 12}
                    >
                      {errors.transcriptError || errors.images}
                    </FieldErrorMessage>
                    {uploadImage && (
                      <UploadImageComponent
                        currentDigest={data}
                        onSave={({ type, text, status = false }) => {
                          // hanldeUploadVideo();
                          onChange({ type, text });
                          dispatch({ type: 'UPLOAD_IMAGE_STATUS', status });
                        }}
                        aspectRatio={aspectRatio}
                        setAspectRatio={(isRatio) => setAspectRatio(isRatio)}
                        uploadImageType={uploadImageType}
                        code={data.type ? data.type.code : '200'}
                        localImageUrl={addNewUpload ? null : openUploadImage}
                        open={uploadImage}
                        modalInputRef={modalInputRef}
                        // confirmUpdate={confirmUpdate}
                        // updateValue={updateValue}
                        // updateConfirm={updateConfirm}
                        onClose={() => {
                          // onChange({ type: uploadImageType || 'images', text: '' });
                          dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: false });
                        }}
                      />
                    )}
                  </ImageWrapper>
                  {data.images &&
                    (data.images.includes('https://www.youtube.com/watch?v') ||
                      data.images.includes('https://youtu.be/') ||
                      data.images.includes('https://stream.mux.com/') ||
                      data.images.includes('.mp4')) && (
                      <ImageWrapper isYtVideo marginTop={15}>
                        <ImageContainer
                          imageUrl={data.thumbnailImage}
                          style={{ backgroundColor: '#dcdcdc', height: '230px' }}
                        >
                          {data.thumbnailImage && <Image src={data.thumbnailImage} />}
                          <CopyIconImage
                            onClick={() => {
                              setAspectRatio(true);
                              dispatch({
                                type: 'UPLOAD_IMAGE_STATUS',
                                status: true,
                                addNewUpload: true,
                                uploadImageType: 'thumbnailImage',
                              });
                            }}
                          >
                            <Icon name="plus" size="large" />
                          </CopyIconImage>
                        </ImageContainer>
                        <HintText marginLeft={5}>Custom Thumbnail</HintText>
                        <FieldErrorMessage
                          style={{
                            marginLeft: 0,
                          }}
                          fontSize={errors.transcriptError ? 20 : 12}
                        >
                          {errors.transcriptError || errors.images}
                        </FieldErrorMessage>
                        {/* {uploadImage && (
                        <UploadImageComponent
                          onSave={({ type, text }) => {
                            onChange({ type, text });
                            dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: false });
                          }}
                          code={data.type ? data.type.code : '200'}
                          localImageUrl={addNewUpload ? null : openUploadImage}
                          open={uploadImage}
                          modalInputRef={modalInputRef}
                          // confirmUpdate={confirmUpdate}
                          // updateValue={updateValue}
                          // updateConfirm={updateConfirm}
                          onClose={() => {
                            onChange({ type: 'images', text: '' });
                            dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: false });
                          }}
                        />
                      )} */}
                      </ImageWrapper>
                    )}
                  {data &&
                    data.images &&
                    !(
                      data.images.includes('https://www.youtube.com/watch?v') ||
                      data.images.includes('https://youtu.be/') ||
                      data.images.includes('https://stream.mux.com/') ||
                      data.images.includes('.mp4')
                    ) && (
                      <FieldWrapper>
                        <LeftWrapper title="Show Thumbnail Large">
                          <span>STL</span>
                        </LeftWrapper>
                        <ToggleWrapper>
                          <ToggleLabel>
                            <Toggle
                              on={data.visibleFields.is_thumbnail_large}
                              onClick={() => {
                                const value = {
                                  ...data.visibleFields,
                                  is_thumbnail_large: !data.visibleFields.is_thumbnail_large,
                                };
                                onChange({ type: 'visibleFields', text: value });
                              }}
                            />
                            <Label>Show Thumbnail Large</Label>
                          </ToggleLabel>
                        </ToggleWrapper>
                      </FieldWrapper>
                      // <FieldWrapper>
                      //   <LeftWrapper title="Focus cover image">
                      //     <span>F</span>
                      //   </LeftWrapper>
                      //   <ToggleWrapper>
                      //     <ToggleLabel>
                      //       <Toggle
                      //         on={data.focusCover}
                      //         onClick={() => {
                      //           const value = {
                      //             ...data.visibleFields,
                      //             is_thumbnail_large: !data.visibleFields.is_thumbnail_large,
                      //           };
                      //             console.log('focusCover value', value)

                      //           onChange({ type: 'focusCover', text: !data.focusCover });
                      //         }}
                      //       />
                      //       <Label>Focus cover image</Label>
                      //     </ToggleLabel>
                      //   </ToggleWrapper>
                      // </FieldWrapper>
                    )}
                  <FieldWrapper>
                    <LeftWrapper mt="10px" title="Title">
                      <span>T</span>
                      <div>{data.title ? `${data.title.split(' ').length}/30` : `0/30`}</div>
                    </LeftWrapper>
                    <TitleFieldWrapper>
                      <TitleFieldIcon
                        onClick={() => {
                          onChange({ type: 'title', text: toSentenceCase(data.title) });
                        }}
                      >
                        <img src={LowercaseIcon} alt="lowercase icon" />
                      </TitleFieldIcon>
                      <TitleField
                        onChange={(e) => {
                          if (e.target.value.split(' ').length <= 30)
                            onChange({ type: 'title', text: e.target.value });
                        }}
                        key="title"
                        element="textarea"
                        title="Title"
                        value={data.title}
                        placeholder="Title"
                        bgColor={titleBg}
                      />
                    </TitleFieldWrapper>
                  </FieldWrapper>
                  {/* <CountWrapper>
                <FieldErrorMessage>{errors.title}</FieldErrorMessage>
              </CountWrapper> */}
                  <FieldWrapper>
                    <LeftWrapper title="Published AT">
                      <span>PA</span>
                    </LeftWrapper>
                    <DateTimeWrapper>
                      <Datetime
                        value={
                          data.publishedAt ? moment(new Date(data.publishedAt)) : moment(new Date())
                        }
                        dateFormat="MMM DD YYYY"
                        timeFormat="hh:mm A"
                        onChange={(val) => {
                          if (typeof val === 'object') {
                            onChange({
                              type: 'publishedAt',
                              text: new Date(val.format('MMM DD YYYY hh:mm A')),
                            });
                          }
                        }}
                      />
                    </DateTimeWrapper>
                  </FieldWrapper>
                  <HintText>Date</HintText>
                  <FieldErrorMessage>{errors.publishedAt}</FieldErrorMessage>
                  <EditorsWrapperContainer>
                    <div></div>
                    {plagiarised.length > 0 && (
                      <div>
                        <FieldWrapper>
                          <LeftWrapper mt="16px" title="Content Compare">
                            <span>CC</span>
                          </LeftWrapper>
                          <EditorWrapper>
                            <>
                              {data.status === 'Draft' ? (
                                <LastModifiedAt>{`Last modified at ${moment(
                                  modifiedatDigest,
                                ).format('DD-MM-YYYY hh:mm:ss A')}`}</LastModifiedAt>
                              ) : (
                                ''
                              )}
                            </>
                            <ContentBox>
                              <EditorContent>
                                <CustomContent>{Parser(plagiarised)}</CustomContent>
                              </EditorContent>
                            </ContentBox>
                          </EditorWrapper>
                        </FieldWrapper>
                      </div>
                    )}
                    {plagiarisedTitle.length > 0 && (
                      <FieldWrapper>
                        <LeftWrapper mt="10px" title="Title">
                          <span>TC</span>
                        </LeftWrapper>
                        <EditorWrapper>
                          <ContentBox>
                            <EditorContent mh="30px">
                              <CustomContent pd="5px">{Parser(plagiarisedTitle)}</CustomContent>
                            </EditorContent>
                          </ContentBox>
                        </EditorWrapper>
                      </FieldWrapper>
                    )}
                    <div>
                      <FieldWrapper>
                        <LeftWrapper mt="16px" title="Content">
                          <span>C</span>
                          {/* <div>{dataToText ? `${dataTextLength}` : ''}</div> */}
                          <div>
                            {/* {dataToText ? (
                          `${dataToText.split(" ").length}
                            ${data.type.code === '200' || data.type.code === '275' || data.type.code === '300' ? '' : ''}`
                        ) : (
                            `0${data.type.code === '200' || data.type.code === '275' || data.type.code === '300' ? '' : ''}`
                          )} */}
                          </div>
                        </LeftWrapper>

                        <EditorWrapper>
                          <>
                            {data.status === 'Draft' ? (
                              <LastModifiedAt>{`Last modified at ${moment(modifiedatDigest).format(
                                'DD-MM-YYYY hh:mm:ss A',
                              )}`}</LastModifiedAt>
                            ) : (
                              ''
                            )}
                          </>
                          <ContentBox id="scrollableBar">
                            {/* {editorRef && editorRef.current && (
                              <ToolbarWrapper scrollabel={scrollabel}>
                                <Toolbar style={{ borderTop: '10px' }}>
                                  {(externalProps) => (
                                    <>
                                      <EditorToolBar
                                        externalProps={externalProps}
                                        data={data}
                                        imageAddOnChange={(value) => {
                                            onChange({ type: 'content', text: value, markup: true });
                                        }}
                                        imagePlugin={imagePlugin}
                                        linkBoxRef={linkBoxRef}
                                        linkPlugin={linkPlugin}
                                        linkValue={linkValue}
                                        setLinkValue={setLinkValue}
                                        setShowLinkArticleBox={setShowLinkArticleBox}
                                        setToggleLinkBox={setToggleLinkBox}
                                        showLinkArticleBox={showLinkArticleBox}
                                        toggleLinkBox={toggleLinkBox}
                                        versionControlOnChange={(value) => {
                                          onChange({
                                            type: 'title',
                                            text: value.title,
                                          });
                                          setTimeout(() =>
                                            onChange({
                                              type: 'version_content',
                                              text: value.digest,
                                            }),
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                </Toolbar>
                              </ToolbarWrapper>
                            )} */}
                            <VersionControl
                              openVersion={openVersion}
                              setOpenVersion={setOpenVersion}
                              version={{
                                ...data.version,
                                current_version: {
                                  user: {
                                    id: data?.digestAuthor?.id,
                                    name: data?.digestAuthor?.name,
                                    description: data?.digestAuthor?.description,
                                    profile_pic: data?.digestAuthor?.profile_pic,
                                  },
                                  title: data?.title,
                                  digest: data?.content,
                                },
                              }}
                              onChange={(value) => {
                                onChange({
                                  type: 'title',
                                  text: value.title,
                                });
                                onChange({
                                  type: 'version_content',
                                  text: value.digest,
                                });
                              }}
                            />
                            <EditorContent>
                              <div
                                style={{
                                  display: `${showLinkArticleBox ? 'flex' : ''}`,
                                  alignItems: 'flex-start',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <EditorFile
                                  openVersion={openVersion}
                                  setOpenVersion={setOpenVersion}
                                  dispatch={dispatchFromParent}
                                  handleEditorChange={handleEditorChange}
                                  showLinkArticleBox={showLinkArticleBox}
                                  setShowLinkArticleBox={setShowLinkArticleBox}
                                  selectedArticleLink={selectedArticle}
                                  linkValue={linkValue}
                                  setToggleLinkBox={setToggleLinkBox}
                                  editorRef={editorRef}
                                  data={data.content}
                                  plugins={plugins}
                                  onChange={(value) => {
                                    onChange(value);
                                  }}
                                />

                                {showLinkArticleBox && (
                                  <LinkExistingArticle
                                    handleClose={() => setShowLinkArticleBox(false)}
                                    setSelectedArticle={setSelectedArticle}
                                  />
                                )}
                              </div>

                              {/* <MentionSuggestions
                                onSearchChange={onSearchChange}
                                suggestions={suggestions}
                              /> */}
                            </EditorContent>
                          </ContentBox>
                        </EditorWrapper>
                      </FieldWrapper>
                      {!videoDigest && (
                        <>
                          <CountTextButton onClick={autoSummarize}>Auto Summarize</CountTextButton>
                          <CountTextButton
                            onClick={() => {
                              if (data.id) checkPlagiarism();
                              else
                                ToastsStore.warning(
                                  'Pleas save the digest to check for plagiarism',
                                );
                            }}
                          >
                            Check for plagiarism
                          </CountTextButton>
                          <CountTextButton
                            onClick={() => {
                              if (data.id) setCheckPlagiarismModal(true);
                              else
                                ToastsStore.warning(
                                  'Pleas save the digest to check for plagiarism',
                                );
                            }}
                          >
                            Add content for plagiarism check
                          </CountTextButton>
                        </>
                      )}
                      <FieldErrorMessage>{errors.content}</FieldErrorMessage>
                    </div>
                  </EditorsWrapperContainer>
                  {/* {!pollStatus && ( */}
                  {/* )} */}
                  <FieldWrapper>
                    <LeftWrapper title="Notification short title">
                      <span>NST</span>
                    </LeftWrapper>
                    <NotesField
                      element="textarea"
                      onChange={(e) => {
                        onChange({ type: 'shortTitle', text: e.target.value });
                        // dispatch({ type: 'SET_DATA', data: ({ ...data, shortTitle: e.target.value }) });
                      }}
                      key="Short Title"
                      title="Notification Short Title"
                      value={data.shortTitle}
                      placeholder="Notification Short Title"
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper title="Notification short title">
                      <span>NST</span>
                    </LeftWrapper>
                    <NotesField
                      element="textarea"
                      onChange={(e) => {
                        onChange({ type: 'notificationShortText', text: e.target.value });
                        // dispatch({ type: 'SET_DATA', data: ({ ...data, shortTitle: e.target.value }) });
                      }}
                      key="Short Title"
                      title="Notification Short Text"
                      value={data.notificationShortText}
                      placeholder="Notification Short Text"
                    />
                  </FieldWrapper>
                  {/* <FieldWrapper>
                    <LeftWrapper title="Notification short title">
                      <span>QUS</span>
                    </LeftWrapper>
                    <NotesField
                      element="textarea"
                      onChange={(e) => {
                        onChange({ type: 'question', text: e.target.value });
                        // dispatch({ type: 'SET_DATA', data: ({ ...data, shortTitle: e.target.value }) });
                      }}
                      key="Question"
                      title="Question"
                      value=''
                      placeholder="Add Question"
                    />
                  </FieldWrapper> */}
                  {/* <FieldWrapper>
                    <LeftWrapper mt="16px" title="Select Categories">
                      <span>C</span>
                    </LeftWrapper>
                    <DropdownWrapper>
                      <Chips
                        placeholder="Select/Add Categories"
                        value={JSON.parse(JSON.stringify(data.categories))}
                        onChange={onChangeCategoriesOption}
                        title="Category"
                        fetchSuggestions={fetchSuggestions}
                        alwaysRenderSuggestions
                        // fromSuggestionsOnly={localStorage.getItem('privilege') !== '33'}
                        createChipKeys={[13]}
                        renderChip={(chipData) => (
                          <ChipWrapper>
                            {chipData.title || chipData}
                            <ChipCloseIcon
                              onClick={() => {
                                let index;
                                if (chipData.id)
                                  index = data.categories.findIndex(
                                    (category) => category.id === chipData.id,
                                  );
                                else
                                  index = data.categories.findIndex(
                                    (category) => category === chipData,
                                  );
                                const tagsData = JSON.parse(JSON.stringify(data.categories));
                                tagsData.splice(index, 1);
                                onChangeCategoriesOption(tagsData, true);
                              }}
                            >
                              <Icon size="small" name="close" />
                            </ChipCloseIcon>
                          </ChipWrapper>
                        )}
                        renderSuggestion={(val) => <span>{val.title}</span>}
                        suggestionsFilter={(opt) =>
                          data.categories.findIndex((tag) => tag.id === opt.id) === -1
                        }
                      />
                    </DropdownWrapper>
                  </FieldWrapper> */}

                  <FieldWrapper>
                    <LeftWrapper mt="16px" title="Select Collaborators">
                      <span>COLLAB</span>
                    </LeftWrapper>
                    <DropdownWrapper>
                      {options ? (
                        <Multiselect
                          onSearch={(e) => callSearchApi(e)}
                          options={options}
                          style={{
                            chips: {
                              background: 'rgb(233,233,233)',
                              color: 'rgb(128,128,128)',
                            },
                            icon: {
                              color: 'black',
                            },
                            searchBox: {
                              border: 'none',
                            },
                          }}
                          customCloseIcon={
                            <Icon name="close" style={{ margin: '0px 0px 3px 5px' }} />
                          }
                          selectedValues={selectedOptionValue}
                          onSelect={onSelect}
                          onRemove={onRemove}
                          displayValue="name"
                        />
                      ) : null}
                    </DropdownWrapper>
                  </FieldWrapper>
                  <FieldErrorMessage>{errors.collaborators}</FieldErrorMessage>
                  <FieldWrapper>
                    <LeftWrapper mt="16px" title="Select Creator">
                      <span>LABEL</span>
                    </LeftWrapper>
                    <DropdownWrapper>
                      {labels ? (
                        <Multiselect
                          placeholder="Select Label"
                          onSearch={(e) => callSearchApi(e)}
                          options={labels}
                          style={{
                            chips: {
                              background: 'rgb(233,233,233)',
                              color: 'rgb(128,128,128)',
                            },
                            icon: {
                              color: 'black',
                            },
                            searchBox: {
                              border: 'none',
                            },
                          }}
                          customCloseIcon={
                            <Icon name="close" style={{ margin: '0px 0px 3px 5px' }} />
                          }
                          selectedValues={selectedLabelsValue}
                          onSelect={onLabelSelect}
                          onRemove={onLabelRemove}
                          displayValue="title"
                        />
                      ) : null}
                    </DropdownWrapper>
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper mt="16px" title="Select Creator">
                      <span>CREATE</span>
                    </LeftWrapper>
                    <DropdownWrapper>
                      {options ? (
                        <Multiselect
                          placeholder="Select Creator"
                          onSearch={(e) => callSearchApi(e)}
                          options={options}
                          style={{
                            chips: {
                              background: 'rgb(233,233,233)',
                              color: 'rgb(128,128,128)',
                            },
                            icon: {
                              color: 'black',
                            },
                            searchBox: {
                              border: 'none',
                            },
                          }}
                          customCloseIcon={
                            <img
                              style={{ height: 15, width: 15, marginLeft: 5 }}
                              src="http://cdn.onlinewebfonts.com/svg/img_127536.png"
                            />
                          }
                          selectionLimit={1}
                          selectedValues={selectedOptionValueCreatedby}
                          onSelect={onSelectForCreatedBy}
                          onRemove={onRemoveForCreatedBy}
                          displayValue="name"
                        />
                      ) : null}
                    </DropdownWrapper>
                  </FieldWrapper>
                  <FieldErrorMessage>{errors.categories}</FieldErrorMessage>
                  <FieldWrapper>
                    <LeftWrapper mt="16px" title="Add Tags">
                      <span>T</span>
                    </LeftWrapper>
                    <DropdownWrapper>
                      <TagsListWrapper>
                        {/*  {data.tags &&
                          data.tags.map((tagItem) => (
                            <TagListItem>
                              {tagItem.title}
                              <TagListCrossIcon
                                onClick={() => {
                                  const selectedTags = JSON.parse(JSON.stringify(data.tags));
                                  const index = selectedTags.findIndex(
                                    (elm) => elm.id === tagItem.id,
                                  );
                                  selectedTags.splice(index, 1);
                                  handleAddTagsGroups(selectedTags);
                                }}
                              >
                                x
                              </TagListCrossIcon>
                            </TagListItem>
                          ))} */}
                        <AddTagsBtn
                          onClick={() => {
                            dispatch({ type: 'SET_TAGS_MODAL_SHOW', value: true });
                          }}
                        >
                          Add Tags
                        </AddTagsBtn>
                      </TagsListWrapper>
                      <TagsModal
                        value={JSON.parse(JSON.stringify(data.tags))}
                        onClose={() => {
                          dispatch({ type: 'SET_TAGS_MODAL_SHOW', value: false });
                        }}
                        show={selectTagsModalShow}
                        handleSave={handleAddTagsGroups}
                      />
                      <Chips
                        placeholder="Add tags"
                        value={JSON.parse(JSON.stringify(data.tags))}
                        onChange={hanldeAddTags}
                        fetchSuggestions={fetchTagSuggestions}
                        alwaysRenderSuggestions
                        createChipKeys={[13]}
                        title="Tag"
                        renderChip={(chipData) => (
                          <ChipWrapper>
                            {chipData.title || chipData}
                            <ChipCloseIcon
                              onClick={() => {
                                let index;
                                if (chipData.id)
                                  index = data.tags.findIndex((tag) => tag.id === chipData.id);
                                else index = data.tags.findIndex((tag) => tag === chipData);
                                const tagsData = JSON.parse(JSON.stringify(data.tags));

                                tagsData.splice(index, 1);
                                hanldeAddTags(tagsData);
                              }}
                            >
                              <Icon size="small" name="close" />
                            </ChipCloseIcon>
                          </ChipWrapper>
                        )}
                        renderSuggestion={(val) => <span>{val.title}</span>}
                        suggestionsFilter={(opt) =>
                          data.tags.findIndex((tag) => tag.id === opt.id) === -1
                        }
                      />
                    </DropdownWrapper>
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper title="Editor Score">
                      <span>ES</span>
                    </LeftWrapper>
                    <AssignToDropdown>
                      <EditorScoreWrapper>
                        <EditorScoreLabel>Quality</EditorScoreLabel>
                        <EditorScoreDropDownWrapper>
                          <Dropdown
                            placeholder="Select User"
                            value={data.quality || 0}
                            fluid
                            // clearable
                            // search
                            selection
                            onChange={(e, val) => {
                              let selectedData = null;
                              val.options.forEach((item) => {
                                if (item.value === val.value) {
                                  selectedData = item;
                                }
                              });
                              onChange({
                                type: 'quality',
                                text: (selectedData && selectedData.value) || 0,
                              });
                            }}
                            options={scoreList}
                          />
                          <FieldErrorMessage>{errors.quality}</FieldErrorMessage>
                        </EditorScoreDropDownWrapper>
                      </EditorScoreWrapper>
                      <EditorScoreWrapper>
                        <EditorScoreLabel>Relevance</EditorScoreLabel>
                        <EditorScoreDropDownWrapper>
                          <Dropdown
                            placeholder="Select User"
                            value={data.relevance || 0}
                            fluid
                            // clearable
                            // search
                            selection
                            onChange={(e, val) => {
                              let selectedData = null;
                              val.options.forEach((item) => {
                                if (item.value === val.value) {
                                  selectedData = item;
                                }
                              });
                              onChange({
                                type: 'relevance',
                                text: (selectedData && selectedData.value) || 0,
                              });
                            }}
                            options={scoreRelevance}
                          />
                          <FieldErrorMessage>{errors.relevance}</FieldErrorMessage>
                        </EditorScoreDropDownWrapper>
                      </EditorScoreWrapper>
                      <EditorScoreWrapper>
                        <EditorScoreLabel>Shelf Life</EditorScoreLabel>
                        <EditorScoreDropDownWrapper>
                          <Dropdown
                            placeholder="Select User"
                            value={data.shelfLife || 'DAYS'}
                            fluid
                            // clearable
                            // search
                            selection
                            onChange={(e, val) => {
                              let selectedData = null;
                              val.options.forEach((item) => {
                                if (item.value === val.value) {
                                  selectedData = item;
                                }
                              });
                              onChange({
                                type: 'shelfLife',
                                text: (selectedData && selectedData.value) || 0,
                              });
                            }}
                            options={shelfLifeOption}
                          />
                          <FieldErrorMessage>{errors.shelf_life}</FieldErrorMessage>
                        </EditorScoreDropDownWrapper>
                      </EditorScoreWrapper>

                      <EditorScoreWrapper>
                        <EditorScoreLabel>Editor's Score Quality</EditorScoreLabel>
                        <EditorScoreDropDownWrapper>
                          <Dropdown
                            placeholder="Select User"
                            value={data.quality2 || 0}
                            fluid
                            // clearable
                            // search
                            selection
                            onChange={(e, val) => {
                              let selectedData = null;
                              val.options.forEach((item) => {
                                if (item.value === val.value) {
                                  selectedData = item;
                                }
                              });
                              onChange({
                                type: 'quality2',
                                text: (selectedData && selectedData.value) || 0,
                              });
                            }}
                            options={editorScoreQualityOptions}
                          />
                          <FieldErrorMessage>{errors.quality2}</FieldErrorMessage>
                        </EditorScoreDropDownWrapper>
                      </EditorScoreWrapper>
                    </AssignToDropdown>
                  </FieldWrapper>
                  <FieldErrorMessage>{errors.tags}</FieldErrorMessage>
                  {data.status === 'Published' && (
                    <>
                      <FieldWrapper>
                        <LeftWrapper mt="15px" title="Slug">
                          <span>SL</span>
                        </LeftWrapper>
                        <SlugWrapper>
                          <GridColumns>
                            {!editSlug ? (
                              <>
                                <Slug value={data.slug} disabled title="Slug" placeholder="Slug" />
                                <Button icon onClick={setEditSlug}>
                                  <Icon name="edit" />
                                </Button>
                              </>
                            ) : (
                              <>
                                <Slug
                                  defaultValue={data.slug}
                                  placeholder="Slug"
                                  title="Slug"
                                  onChange={(e) => {
                                    onChange({ type: 'slug', text: e.target.value });
                                    // dispatch({ type: 'SET_DATA', data: ({ ...data, slug: e.target.value })});
                                  }}
                                />
                                <Button
                                  icon
                                  onClick={() => {
                                    validateSlug(data.slug);
                                  }}
                                >
                                  <Icon name="check" />
                                </Button>
                                <Button
                                  icon
                                  onClick={() => {
                                    onChange({ type: 'slug', text: data.originalSlug });
                                    setEditSlug();
                                    // dispatch({ type: 'SET_DATA', data: ({ ...data, slug: data.slug })});
                                  }}
                                >
                                  <Icon name="cancel" />
                                </Button>
                              </>
                            )}
                          </GridColumns>
                        </SlugWrapper>
                      </FieldWrapper>
                      {editSlug && !errors.slug && (
                        <FieldErrorMessage>
                          Users won&apos;t be able to navigate to this news digest with old url
                        </FieldErrorMessage>
                      )}
                      {editSlug && errors.slug && (
                        <FieldErrorMessage>{errors.slug}</FieldErrorMessage>
                      )}
                    </>
                  )}
                  <FieldWrapper>
                    <LeftWrapper mt="8px" title="Continue Edit">
                      <span>CE</span>
                    </LeftWrapper>
                    <CheckboxFieldWrapper>
                      <CheckboxFieldLabel onClick={() => dispatch({ type: 'CONTINUE_EDIT' })}>
                        Continue Editing
                      </CheckboxFieldLabel>
                      <CheckboxWrapper
                        checked={continueEditing}
                        // published={data.status === 'Published'}
                        onClick={() => dispatch({ type: 'CONTINUE_EDIT' })}
                      />
                    </CheckboxFieldWrapper>
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper title="Assigned To">
                      <span>AT</span>
                    </LeftWrapper>
                    <AssignToDropdown>
                      <Dropdown
                        placeholder="Select User"
                        value={(data.assignedTo && data.assignedTo.id) || null}
                        fluid
                        // clearable
                        search
                        selection
                        onChange={(e, val) => {
                          let selectedData = null;
                          val.options.forEach((item) => {
                            if (item.id === val.value) {
                              selectedData = item;
                            }
                          });
                          onChange({ type: 'assignedTo', text: selectedData });
                        }}
                        options={usersData}
                      />
                    </AssignToDropdown>
                  </FieldWrapper>
                  <FieldErrorMessage>{errors.assignedTo}</FieldErrorMessage>
                  <FieldWrapper>
                    <LeftWrapper title="Digest Author">
                      <span>DA</span>
                    </LeftWrapper>
                    <AssignToDropdown>
                      <Dropdown
                        placeholder="Select User"
                        value={(data.digestAuthor && data.digestAuthor.id) || null}
                        fluid
                        clearable
                        search
                        selection
                        onChange={(e, val) => {
                          let selectedData = null;
                          val.options.forEach((item) => {
                            if (item.id === val.value) {
                              selectedData = item;
                            }
                          });
                          onChange({ type: 'digestAuthor', text: selectedData });
                        }}
                        options={usersData}
                      />
                    </AssignToDropdown>
                  </FieldWrapper>
                  <FieldErrorMessage>{errors.digestAuthor}</FieldErrorMessage>
                  <FieldWrapper>
                    <LeftWrapper title="Editorial Notes">
                      <span>EN</span>
                    </LeftWrapper>
                    <NotesField
                      onChange={(e) => {
                        const val = e.target.value;
                        onChange({ type: 'editorialNotes', text: val });
                      }}
                      key="Editorial notes"
                      title="Editorial notes"
                      value={data.editorialNotes}
                      placeholder="Editorial notes"
                    />
                  </FieldWrapper>
                  <FieldErrorMessage>{errors.editorialNotes}</FieldErrorMessage>
                  <FieldWrapper>
                    <LeftWrapper title="Article Clickable">
                      <span>AC</span>
                    </LeftWrapper>
                    <ToggleWrapper>
                      <ToggleLabel>
                        <Toggle
                          on={data.articleClikable}
                          onClick={() => {
                            onChange({ type: 'articleClikable', text: !data.articleClikable });
                          }}
                        />
                        <Label>Article Clikable</Label>
                      </ToggleLabel>
                      {data.articleClikable && (
                        <ClickActionTypeWrapper>
                          <Dropdown
                            placeholder="Select Click Action"
                            value={(data.articleClickAction && data.articleClickAction.id) || null}
                            fluid
                            // clearable
                            search
                            selection
                            onChange={(e, val) => {
                              let selectedData = null;
                              val.options.forEach((item) => {
                                if (item.id === val.value) {
                                  selectedData = item;
                                }
                              });
                              onChange({ type: 'articleClickAction', text: selectedData });
                              dispatch({
                                type: 'SET_DATA',
                                data: {
                                  ...data,
                                  articleClickActionObject: null,
                                  articleClickAction: selectedData,
                                },
                              });
                            }}
                            options={clickActionsData}
                          />
                          <ErrorMessage textAlign="left">{errors.articleClickAction}</ErrorMessage>
                          <ClickActionSubWrapper>
                            {data.articleClickAction && data.articleClickAction.id === 'tag' && (
                              <Dropdown
                                placeholder="Select Tag"
                                value={
                                  (data.articleClickActionObject &&
                                    data.articleClickActionObject.id) ||
                                  null
                                }
                                fluid
                                clearable
                                search
                                selection
                                // onSearchChange={fetchTagSuggestions}
                                onChange={(e, val) => {
                                  let selectedData = null;
                                  val.options.forEach((item) => {
                                    if (item.id === val.value) {
                                      selectedData = item;
                                    }
                                  });
                                  onChange({
                                    type: 'articleClickActionObject',
                                    text: selectedData,
                                  });
                                  dispatch({
                                    type: 'SET_DATA',
                                    data: { ...data, articleClickActionObject: selectedData },
                                  });
                                }}
                                options={extraTags}
                              />
                            )}
                            {data.articleClickAction &&
                              data.articleClickAction.id === 'category' && (
                                <Dropdown
                                  placeholder="Select Category"
                                  value={
                                    (data.articleClickActionObject &&
                                      data.articleClickActionObject.id) ||
                                    null
                                  }
                                  fluid
                                  clearable
                                  search
                                  selection
                                  // onSearchChange={fetchTagSuggestions}
                                  onChange={(e, val) => {
                                    let selectedData = null;
                                    val.options.forEach((item) => {
                                      if (item.id === val.value) {
                                        selectedData = item;
                                      }
                                    });
                                    onChange({
                                      type: 'articleClickActionObject',
                                      text: selectedData,
                                    });
                                    dispatch({
                                      type: 'SET_DATA',
                                      data: { ...data, articleClickActionObject: selectedData },
                                    });
                                  }}
                                  options={extraCategories}
                                />
                              )}
                            {data.articleClickAction && data.articleClickAction.id === 'host' && (
                              <Dropdown
                                placeholder="Select Hosts"
                                value={
                                  (data.articleClickActionObject &&
                                    data.articleClickActionObject.id) ||
                                  null
                                }
                                fluid
                                clearable
                                search
                                selection
                                // onSearchChange={fetchTagSuggestions}
                                onChange={(e, val) => {
                                  let selectedData = null;
                                  val.options.forEach((item) => {
                                    if (item.id === val.value) {
                                      selectedData = item;
                                    }
                                  });
                                  onChange({
                                    type: 'articleClickActionObject',
                                    text: selectedData,
                                  });
                                  dispatch({
                                    type: 'SET_DATA',
                                    data: { ...data, articleClickActionObject: selectedData },
                                  });
                                }}
                                options={hostsList}
                              />
                            )}
                            <ErrorMessage textAlign="left">
                              {errors.articleClickActionObject}
                            </ErrorMessage>
                          </ClickActionSubWrapper>
                        </ClickActionTypeWrapper>
                      )}
                    </ToggleWrapper>
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper title="Select Article Format">
                      <span>AF</span>
                    </LeftWrapper>
                    <AssignToDropdown>
                      <Dropdown
                        selectOnBlur={false}
                        placeholder="Select Article Format"
                        value={(data.articleFormat && data.articleFormat.id) || null}
                        fluid
                        clearable
                        search
                        selection
                        onChange={(e, val) => {
                          let selectedData = null;
                          val.options.forEach((item) => {
                            if (item.id === val.value) {
                              selectedData = item;
                            }
                          });
                          onChange({ type: 'articleFormat', text: selectedData });
                        }}
                        options={articleFormatTypes}
                      />
                    </AssignToDropdown>
                  </FieldWrapper>
                  <FieldErrorMessage>{errors.articleFormat}</FieldErrorMessage>
                  <FieldWrapper>
                    <LeftWrapper title="Curated Tweet">
                      <span>CT</span>
                    </LeftWrapper>
                    <CuratedTweetForm 
                      data={data.twitter_links}
                      onChange={(index,link)=>{onChange({type:"twitter_links",text: link,index})}}
                      removeLink={(index)=>{onChange({type:"remove_twitter_links",index})}}
                      addLink={()=>{onChange({type:"add_twitter_links"})}}
                      error={errors.twitter_links}
                    />
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper mt="8px" title="Update published time">
                      <span>FU</span>
                    </LeftWrapper>
                    <CheckboxFieldWrapper>
                      <CheckboxFieldLabel
                        onClick={() => {
                          onChange({ type: 'futureUpdate', text: !data.futureUpdate });
                          // dispatch({ type: 'SET_FUTURE_UPDATE' });
                        }}
                      >
                        Update published time
                      </CheckboxFieldLabel>
                      <CheckboxWrapper
                        checked={data.futureUpdate}
                        // published={data.status === 'Published'}
                        onClick={() => {
                          onChange({ type: 'futureUpdate', text: !data.futureUpdate });
                          // dispatch({ type: 'SET_FUTURE_UPDATE' });
                        }}
                      />
                    </CheckboxFieldWrapper>
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper title="Show Advance Settings">
                      <span>AS</span>
                    </LeftWrapper>
                    <ToggleWrapper>
                      <ToggleLabel>
                        <Toggle
                          on={showAdvanceSetting}
                          onClick={() => {
                            dispatch({ type: 'SET_TOGGLE_ADVANCE_SETTINGS' });
                          }}
                        />
                        <Label>Show Advance Settings</Label>
                      </ToggleLabel>
                    </ToggleWrapper>
                  </FieldWrapper>
                  {data.videoTimeStamp ? (
                    <SegmentDurationWrapper>
                      Total Video Duration: <span>{data.videoTimeStamp}</span>
                    </SegmentDurationWrapper>
                  ) : null}

                  {/* {!showAdvanceSetting && (
                    <FieldWrapper>
                      <LeftWrapper title="Editor Score">
                        <span>ES</span>
                      </LeftWrapper>
                      <AssignToDropdown>
                        <EditorScoreWrapper>
                          <EditorScoreLabel>Quality</EditorScoreLabel>
                          <EditorScoreDropDownWrapper>
                            <Dropdown
                              placeholder="Select User"
                              value={data.quality || 0}
                              fluid
                              // clearable
                              // search
                              selection
                              onChange={(e, val) => {
                                let selectedData = null;
                                val.options.forEach((item) => {
                                  if (item.value === val.value) {
                                    selectedData = item;
                                  }
                                });
                                onChange({
                                  type: 'quality',
                                  text: (selectedData && selectedData.value) || 0,
                                });
                              }}
                              options={scoreList}
                            />
                            <FieldErrorMessage>{errors.quality}</FieldErrorMessage>
                          </EditorScoreDropDownWrapper>
                        </EditorScoreWrapper>
                      </AssignToDropdown>
                    </FieldWrapper>
                  )} */}
                  {showAdvanceSetting && (
                    <>
                      {data.authors &&
                        data.authors.map((author, index) => (
                          <FieldWrapper>
                            <LeftWrapper title="Authors">
                              <span>A</span>
                            </LeftWrapper>
                            <AuthorElement
                              title="Author"
                              onChange={(e) => {
                                const authorsData = JSON.parse(JSON.stringify(data.authors));
                                authorsData[index].name = e.target.value;
                                authorsData[index].index = index;
                                onChange({ type: 'authors', text: authorsData });
                                // dispatch({ type: 'SET_DATA', setAuthor: true, data: ({ ...data, authors: authorsData, index }) });
                              }}
                              placeholder="Author"
                              value={author.name}
                            />
                            <AddRemoveAuthor>
                              {index !== 0 && (
                                <IconDiv
                                  onClick={() => {
                                    onChange({
                                      type: 'removeAuthors',
                                      text: { removeAuthor: true, index },
                                    });
                                  }}
                                >
                                  <Icon name="minus circle" size="large" />
                                </IconDiv>
                              )}
                              {index === data.authors.length - 1 &&
                                data.authors[index].name &&
                                data.authors[index].name.trim() !== '' && (
                                  <IconDiv
                                    onClick={() => {
                                      onChange({ type: 'addAuthors', text: { addAuthor: true } });
                                    }}
                                  >
                                    <Icon name="plus circle" size="large" />
                                  </IconDiv>
                                )}
                            </AddRemoveAuthor>
                          </FieldWrapper>
                        ))}
                      <FieldWrapper>
                        <LeftWrapper mt="15px">
                          <span>FF</span>
                        </LeftWrapper>
                        <FocusField>
                          <FocusContent>
                            <Editor
                              editorState={focusedEditorState}
                              plugins={pluginsFocus}
                              title="FocusEditor"
                              className="outEditor"
                              key="outEditor"
                              placeholder="Type '@' and digest slug  suggestion will appear"
                              onChange={(value) => {
                                dispatch({ type: 'SET_FOCUS_EDITOR_STATE', value });
                                // if (focusedEditorState.getCurrentContent() !== value.getCurrentContent() || stateToHTML(value.getCurrentContent()).includes('@')) {
                                onChange({ type: 'focus_card', text: value });
                                // }
                              }}
                            />
                          </FocusContent>
                          <Mentions
                            onSearchChange={onSearchFocusChange}
                            suggestions={suggestionsFocus}
                          />
                        </FocusField>
                      </FieldWrapper>
                      <FieldErrorMessage>{errors.focus_card}</FieldErrorMessage>
                      <PollComponent
                        data={data.digest_poll}
                        onChange={onChange}
                        removeChoice={removeChoice}
                        addChoices={addChoices}
                        error={data.digest_poll.has_poll && errors.pollQuestion}
                      />
                      <div style={{ marginTop: 15 }}>
                        <FieldWrapper>
                          <LeftWrapper mt="15px" title="Subtitle">
                            <span>ST</span>
                            <div>
                              {data.subtitle ? `${data.subtitle.split(' ').length}/12` : `0/12`}
                            </div>
                          </LeftWrapper>
                          <SubtitleElement
                            title="Subtitle"
                            onChange={(e) => {
                              if (e.target.value.split(' ').length <= 12) {
                                onChange({ type: 'subtitle', text: e.target.value });
                                // dispatch({ type: 'SET_DATA', data: ({ ...data, subtitle: e.target.value }) });
                              }
                            }}
                            placeholder="Subtitle"
                            value={data.subtitle}
                            bgColor={subTitleBg}
                          />
                        </FieldWrapper>
                        <CountWrapper>
                          <FieldErrorMessage>{errors.subtitle}</FieldErrorMessage>
                        </CountWrapper>
                        {/* {!mentionPresent && ( */}
                        <>
                          <FieldWrapper title="Source Action">
                            <LeftWrapper mt="15px">
                              <span>SA</span>
                            </LeftWrapper>
                            <SubtitleElement
                              title="Source Action"
                              onChange={(e) => {
                                if (e.target.value.split(' ').length <= 10) {
                                  onChange({ type: 'sourceAction', text: e.target.value });
                                  // dispatch({ type: 'SET_DATA', data: ({ ...data, sourceAction: e.target.value }) });
                                }
                              }}
                              placeholder="Source Action"
                              value={data.sourceAction}
                              // bgColor={data.subtitle && data.subtitle.split(" ").length >= 10 ? '#ffb1b1' : data.subtitle && data.subtitle.split(" ").length >= 7 ? '#ffffcc' : '#fff'}
                            />
                          </FieldWrapper>
                          <FieldErrorMessage>{errors.sourceAction}</FieldErrorMessage>
                        </>
                        {/* )} */}
                      </div>
                      <FieldWrapper>
                        <LeftWrapper mt="15px" title="Digest Category">
                          <span>DC</span>
                        </LeftWrapper>
                        <ChoiceWrapper>
                          <GridColumns>
                            {/* <InputElement
                    title="Digest Code"
                    value={data.type.code ?
                      `${data.type.code}-${data.type.title.split(' ').join('_')}`
                      : '200-news'
                    }
                    disabled
                    placeholder="Digest Code"
                  /> */}
                            <Dropdown
                              placeholder="Select Type"
                              value={(data.type && data.type.code) || null}
                              fluid
                              selection
                              onChange={(e, val) => {
                                let selectedData = null;
                                val.options.forEach((item) => {
                                  if (item.code === val.value) {
                                    selectedData = item;
                                  }
                                });
                                onChange({ type: 'type', text: selectedData });
                              }}
                              options={digestTypesData}
                            />
                          </GridColumns>
                        </ChoiceWrapper>
                      </FieldWrapper>
                      <FieldWrapper>
                        <LeftWrapper title="Visible Field">
                          <span>VF</span>
                        </LeftWrapper>
                        <VisibleFieldsComp data={data} onChange={onChange} />
                      </FieldWrapper>
                      <FieldWrapper>
                        <LeftWrapper mt="15px" title="Default preview count">
                          <span>PC</span>
                        </LeftWrapper>
                        <SubtitleElement
                          title="Default preview count"
                          type="number"
                          min="0"
                          onKeyPress={(e) => {
                            if (e.which === 45) {
                              e.preventDefault();
                              onChange({ type: 'defaultPreviewCount', text: e.target.value });
                              // dispatch({ type: 'SET_DATA', data: ({ ...data, defaultPreviewCount: e.target.value }) });
                            }
                          }}
                          onChange={(e) => {
                            if (e.target.value <= 100) {
                              onChange({ type: 'defaultPreviewCount', text: e.target.value });
                              // dispatch({ type: 'SET_DATA', data: ({ ...data, defaultPreviewCount: e.target.value }) });
                            }
                          }}
                          placeholder="Default Preview Count"
                          value={data.defaultPreviewCount}
                        />
                      </FieldWrapper>
                      <FieldErrorMessage>{errors.defaultPreviewCount}</FieldErrorMessage>
                      <FieldWrapper>
                        <LeftWrapper title="Read Reason">
                          <span>RR</span>
                        </LeftWrapper>
                        <NotesField
                          onChange={(e) => {
                            onChange({ type: 'readReason', text: e.target.value });
                            // dispatch({ type: 'SET_DATA', data: ({ ...data, readReason: e.target.value }) });
                          }}
                          key="Editorial notes"
                          title="Editorial notes"
                          value={data.readReason}
                          placeholder="Why you should read the original"
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <LeftWrapper title="Region">
                          <span>R</span>
                        </LeftWrapper>
                        <Dropdown
                          placeholder="Select Region"
                          value={(data.regionDetail && data.regionDetail.id) || null}
                          fluid
                          clearable
                          search
                          selection
                          // onSearchChange={fetchTagSuggestions}
                          onChange={(e, val) => {
                            let selectedData = null;
                            val.options.forEach((item) => {
                              if (item.id === val.value) {
                                selectedData = item;
                              }
                            });
                            onChange({
                              type: 'regionDetail',
                              text: selectedData,
                            });
                            // dispatch({
                            //   type: 'SET_DATA',
                            //   data: { ...data, articleClickActionObject: selectedData },
                            // });
                          }}
                          options={regionsList}
                        />
                        {/* regionDetail */}
                      </FieldWrapper>
                    </>
                  )}
                  {digestId && <FieldWrapper>
                    <LeftWrapper title="Generate FAQ">
                      <span>FAQ</span>
                    </LeftWrapper>
                    <ToggleWrapper>
                      <ToggleLabel>
                        <Toggle
                          on={showQuestionsandAnswers}
                        
                          onClick={() => handleToggleClick()}
                        />
                        <Label>Show Question Answers</Label>
                      </ToggleLabel>
                    </ToggleWrapper>
                  </FieldWrapper>}
                  <ConfirmationModal
                    value={JSON.parse(JSON.stringify(data.tags))}
                    onClose={() => {
                      dispatch({ type: 'QUESTION_ANSWER_MODAL_SHOW', value: false });
                    }}
                    handleConfirm={handleConfirmQAModal}
                    show={selectQuestionAnswerModalShow}
                    handleSave={handleAddTagsGroups}
                  />
                  {
                    showQuestionsandAnswers && faqQuestionsGenLoader &&
                                   
                    <div className='loader-box'>
                      <Loader active inline />
                    </div>
                  }
                  {showQuestionsandAnswers && !faqQuestionsGenLoader && faqQuestions.length !== 0 && (
                    <FAQQuestionsSection
                      initialValues={{ faqs: faqQuestions }}
                      handleRegenerateAnswers={handleRegenerateAnswers}
                      regenerateAnswersList={regenerateAnswersList}
                      updateSavingAllQuestionsLoader={updateSavingAllQuestionsLoader}
                      handleSaveAllQuestions={handleSaveAllQuestions}
                    />
                  )}

                  {videoDigest && (
                    <>
                      <DisplayWrapper>Zoom Type</DisplayWrapper>
                      {/* <div style={{margin:'5%'}}>
                        <input type="radio" value="horizontal" name="video-type" /> Horizontal
                        <input type="radio" value="vertical" name="video-type" /> Vertical
                      </div> */}
                      <FieldWrapper>
                        <RadioWrapper
                          onChange={(e) => {
                            setVideoDisplayType(e.target.value);
                            onChange({ type: 'display_type', text: e.target.value });
                          }}
                        >
                          <div>
                            <input
                              type="radio"
                              value="3x"
                              checked={videoDisplayType === '3x'}
                              name={videoDisplayType}
                            />{' '}
                            3x
                          </div>
                          <div>
                            <input
                              type="radio"
                              checked={videoDisplayType === '2x'}
                              value="2x"
                              name={videoDisplayType}
                            />{' '}
                            2x
                          </div>
                          <div>
                            <input
                              type="radio"
                              checked={videoDisplayType === '1x'}
                              value="1x"
                              name={videoDisplayType}
                            />{' '}
                            1x
                          </div>
                        </RadioWrapper>
                      </FieldWrapper>

                      <SegmentSegregate>Segment</SegmentSegregate>
                      <FieldWrapper>
                        <LeftWrapper mt="8px">
                          <span title="Segment Title">ST</span>
                        </LeftWrapper>
                        <TitleInput
                          onChange={(e) =>
                            onChangeActiveSegment({ type: 'title', value: e.target.value })
                          }
                          value={activeSegment.title}
                          placeholder="Segment Title"
                          title="Segment Title"
                        />
                      </FieldWrapper>
                      <FieldErrorMessage>
                        {errors.segment_title || segmentError.segment_title}
                      </FieldErrorMessage>
                      <FieldWrapper>
                        <LeftWrapper mt="8px">
                          <span title="Segment start time">SST</span>
                        </LeftWrapper>
                        <div style={{ width: '100%' }}>
                          <TimePickerField
                            disabledHours={disabledHours}
                            disabledMinutes={disabledMinutes}
                            disabledSeconds={disabledSeconds}
                            onChange={(value) => {
                              // alert(value);
                              onChangeActiveSegment({ type: 'timestamp_start', value });
                            }}
                            value={
                              activeSegment.timestamp_start
                                ? moment(
                                    `${moment().format('DD:MM:YY')} ${
                                      activeSegment.timestamp_start
                                    }`,
                                    'DD:MM:YY HH:mm:ss',
                                  )
                                : moment('00:00:00', 'HH:mm:ss')
                            }
                          />
                          <HintText marginLeft="0">Hrs, mins, secs</HintText>
                        </div>
                        <div style={{ width: '100%' }}>
                          <Timer
                            type="number"
                            min="0"
                            max="99"
                            onBlur={(e) => blurMilliSecs('timestamp_start_ms')}
                            onChange={(e) =>
                              onChangeActiveSegment({
                                type: 'timestamp_start_ms',
                                value: e.target.value,
                              })
                            }
                            value={
                              activeSegment.timestamp_start
                                ? activeSegment.timestamp_start.split(':')[3]
                                : '00'
                            }
                          />
                          <HintText marginLeft="0">Millisecs</HintText>
                        </div>
                      </FieldWrapper>
                      <FieldErrorMessage>
                        {errors.timestamp_start || segmentError.timestamp_start}
                      </FieldErrorMessage>
                      <FieldWrapper>
                        <LeftWrapper mt="8px">
                          <span title="Segment end time">SET</span>
                        </LeftWrapper>
                        <div style={{ width: '100%' }}>
                          <TimePickerField
                            disabledHours={disabledEndHours}
                            disabledMinutes={disabledEndMinutes}
                            disabledSeconds={disabledEndSeconds}
                            onChange={(value) =>
                              onChangeActiveSegment({ type: 'timestamp_end', value })
                            }
                            value={
                              activeSegment.timestamp_end
                                ? moment(
                                    `${moment().format('DD:MM:YY')} ${activeSegment.timestamp_end}`,
                                    'DD:MM:YY HH:mm:ss',
                                  )
                                : moment('00:00:00', 'HH:mm:ss')
                            }
                          />
                          <HintText marginLeft="0">Hrs, mins, secs</HintText>
                        </div>
                        <div style={{ width: '100%' }}>
                          <Timer
                            type="number"
                            min="0"
                            max="99"
                            onChange={(e) =>
                              onChangeActiveSegment({
                                type: 'timestamp_end_ms',
                                value: e.target.value,
                              })
                            }
                            value={
                              activeSegment.timestamp_end
                                ? activeSegment.timestamp_end.split(':')[3]
                                : '00'
                            }
                          />
                          <HintText marginLeft="0">Millisecs</HintText>
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <LeftWrapper mt="8px">
                          <span title="Segment Description">SD</span>
                        </LeftWrapper>
                        <TitleInput
                          onChange={(e) =>
                            onChangeActiveSegment({ type: 'description', value: e.target.value })
                          }
                          value={activeSegment.description}
                          placeholder="Segment Description"
                          title="Segment Description"
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <LeftWrapper mt="8px">
                          <span title="Segment Tag">STs</span>
                        </LeftWrapper>
                        <AssignToDropdown>
                          <Dropdown
                            placeholder="Select Tag"
                            fluid
                            clearable
                            selection
                            options={videoSummary}
                            onChange={(event, val) => {
                              onChangeActiveSegment({
                                type: 'video_summary_tag',
                                value: val.value,
                              });
                            }}
                            value={activeSegment.watch_label}
                            title="Segment Tag"
                          />
                        </AssignToDropdown>
                      </FieldWrapper>
                      <FieldWrapper>
                        <LeftWrapper mt="8px">
                          <span title="Segment end time">AS</span>
                        </LeftWrapper>
                        <div style={{ width: '100%' }}>
                          <TitleInput
                            value={
                              activeSegment.hasOwnProperty('topLeft')
                                ? `${activeSegment.topLeft.x_coord_}:${activeSegment.topLeft.y_coord}`
                                : ''
                            }
                            title="as"
                            disabled
                          />
                        </div>
                        <div style={{ width: '100%' }}>
                          <TitleInput
                            disabled
                            value={
                              activeSegment.hasOwnProperty('bottomRight')
                                ? `${activeSegment.bottomRight.x_coord_}:${activeSegment.bottomRight.y_coord}`
                                : ''
                            }
                          />
                        </div>
                      </FieldWrapper>
                      <ButtonsContainer>
                        <ButtonWrapper>
                          <Button onClick={onSegmentSave}>Save Segment</Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                          <Button
                            onClick={() => {
                              if (dragSelectRef.current) {
                                dragSelectRef.current.clearSelection();
                              }

                              onClickSegment(
                                {
                                  title: '',
                                  timestamp_start: null,
                                  timestamp_end: null,
                                  description: '',
                                },
                                true,
                              );
                            }}
                          >
                            Clear
                          </Button>
                        </ButtonWrapper>
                        <ButtonWrapper>
                          <Button
                            onClick={() => {
                              setCoOrdinate({});
                              const removeCoordinate = { ...activeSegment, timeCoordinate: {} };
                              setIsReset(true);
                              updateActiveSegment(removeCoordinate);
                            }}
                          >
                            Reset coordinates
                          </Button>
                        </ButtonWrapper>
                      </ButtonsContainer>
                      <TableView
                        tableColumns={columns}
                        data={segmentList || []}
                        pagination
                        totalItems={0}
                        scroll={{ x: false, y: false }}
                        // onRow={(record) => ({
                        //   onClick: (e) => {
                        //     onClickSegment(record);
                        //   },
                        // })}
                      />
                      <SegmentDurationWrapper>
                        Segments Total Duration:{' '}
                        <span>{calcluateSegmentDuration(segmentList)}</span>
                      </SegmentDurationWrapper>
                    </>
                  )}
                  {publishErrorMessage && <ErrorMessage>{publishErrorMessage}</ErrorMessage>}
                  <PublishButtonWrapper published={data.originalStatus === 'Published'}>
                    <Select
                      placeholder="Status"
                      options={statuses}
                      value={data.status}
                      defaultValue={data.status}
                      onChange={(e, val) => {
                        handleChangeStatus(val.value);
                        // dispatch({ type: 'SET_CURRENT_STATUS', value: data.value });
                      }}
                    />
                    <Button
                      onClick={(e) => handlePublish(continueEditing, data.status)}
                      loading={loadingPublish}
                      disabled={editSlug || loadingPublish}
                    >
                      Save
                    </Button>
                  </PublishButtonWrapper>
                  {data.originalStatus === 'Published' && (
                    <ViewPublishButtonWrapper>
                      <a
                        href={`${feUrl}/digests/${data.slug}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View published page
                      </a>
                    </ViewPublishButtonWrapper>
                  )}
                </SummaryGridColumn>
                {!singleColumn && editorScoreText && editorScoreText.length > 0 && (
                  <ContentStatusWrapper
                    width={!showFullRight ? 3 : 4}
                    style={{ height: 'auto', border: 'none', borderLeft: '1px solid #dcdcdc' }}
                  >
                    <ImageContainerRight>
                      <ArrowIconImage onClick={() => setShowFullRight(!showFullRight)}>
                        <Icon name={showFullRight ? 'arrow left' : 'arrow right'} size="large" />
                      </ArrowIconImage>
                    </ImageContainerRight>
                    {showFullRight && (
                      <RightContainer showFullRight={showFullRight}>
                        <HintWrapper>
                          <div title="Toggle on/off to set values 1/0 respectively">
                            <Icon name="info circle" size="large" />
                          </div>
                        </HintWrapper>
                        {editorScoreText.map((scoreItem) => (
                          <ToggleWrapperScore>
                            <ToggleLabel>{scoreItem.content}</ToggleLabel>
                            <ToggleSpecial
                              on={data.digestInternal[scoreItem.key]}
                              id={scoreItem.key}
                              onClick={(val) => {
                                onChange({
                                  type: 'editorScoreInternal',
                                  text: val,
                                  name: scoreItem.key,
                                });
                              }}
                            />
                          </ToggleWrapperScore>
                        ))}
                        {/* <ToggleWrapperScore>
                          <ToggleLabel>Accuracy/ Length/ Research</ToggleLabel>
                          <ToggleSpecial
                            on={data.digestInternal.content_accuracy}
                            id="content_accuracy"
                            onClick={(val) => {
                              onChange({
                                type: 'contentAccuracy',
                                text: val,
                              });
                            }}
                          />
                        </ToggleWrapperScore>
                        <ToggleWrapperScore>
                          <ToggleLabel>Video, Category, Tags</ToggleLabel>
                          <ToggleSpecial
                            on={data.digestInternal.content_operational}
                            id="content_operational"
                            onClick={(val) => {
                              onChange({
                                type: 'contentOperational',
                                text: val,
                              });
                            }}
                          />
                        </ToggleWrapperScore>
                        <ToggleWrapperScore>
                          <ToggleLabel>Plagiarism</ToggleLabel>
                          <ToggleSpecial
                            on={data.digestInternal.plagiarism_score}
                            id="plagiarism_score"
                            onClick={(val) => {
                              onChange({
                                type: 'plagiarismScore',
                                text: val,
                              });
                            }}
                          />
                        </ToggleWrapperScore>
                        <ToggleWrapperScore>
                          <ToggleLabel>Length / Brevity</ToggleLabel>
                          <ToggleSpecial
                            on={data.digestInternal.content_length}
                            id="content_length"
                            onClick={(val) => {
                              onChange({
                                type: 'contentLength',
                                text: val,
                              });
                            }}
                          />
                        </ToggleWrapperScore> */}
                      </RightContainer>
                    )}
                  </ContentStatusWrapper>
                )}
              </Grid>
            </>
          ) : (
            <Accordion fluid exclusive={false}>
              {segmentList.map((segment, index) => {
                const activeSegmentCurrent = active === index;
                let timeStart = segment.timestamp_start;
                let timeEnd;
                if (timeStart.indexOf('00') === 0) {
                  timeStart = timeStart.substr(3, 5);
                  timeEnd = segment.timestamp_end.substr(3, 5);
                } else {
                  timeStart = timeStart.substr(0, 8);
                  timeEnd = segment.timestamp_end.substr(0, 8);
                }
                return (
                  <AccordionPanel
                    key={segment.id}
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveTab(index);
                    }}
                  >
                    <TitleTab active={activeSegmentCurrent} index={segment.segment_id}>
                      <AccordionIcon
                        name={activeSegmentCurrent ? 'chevron up' : 'chevron down'}
                        active={activeSegmentCurrent}
                      />
                      {segment.title}
                      {segment.watch_label && (
                        <Tag mustWatch={segment.watch_label === 'Must Watch'}>
                          {segment.watch_label}
                        </Tag>
                      )}
                      <Time active={activeSegmentCurrent}>
                        {timeStart} - {timeEnd}
                      </Time>
                    </TitleTab>
                    {segment.description && (
                      <AccordionContent active={activeSegmentCurrent}>
                        <p>{segment.description}</p>
                      </AccordionContent>
                    )}
                  </AccordionPanel>
                );
              })}
            </Accordion>
          )}
        </>
      )}
      {checkPlagiarismModal && (
        <Modal size="small" open closeIcon onClose={() => setCheckPlagiarismModal(false)}>
          <Modal.Header>Add text to check for plagiarism</Modal.Header>
          <Modal.Content>
            <FieldWrapper>
              <LeftWrapper mt="10px" title="Title">
                <span>T</span>
              </LeftWrapper>
              <TitleFieldWrapper>
                <TitleFieldIcon
                  onClick={() => {
                    onChange({ type: 'title', text: toSentenceCase(data.title) });
                  }}
                >
                  <img src={LowercaseIcon} alt="lowercase icon" />
                </TitleFieldIcon>
                <TitleField
                  onChange={(e) => compareTitle(e.target.value)}
                  key="title"
                  element="textarea"
                  title="Title"
                  value={newTitle}
                  placeholder="Title"
                  bgColor={titleBg}
                />
              </TitleFieldWrapper>
            </FieldWrapper>
            <FieldWrapper>
              <LeftWrapper mt="16px" title="Content">
                <span>C</span>
              </LeftWrapper>
              <EditorWrapper>
                <>
                  {data.status === 'Draft' ? (
                    <LastModifiedAt>{`Last modified at ${moment(modifiedatDigest).format(
                      'DD-MM-YYYY hh:mm:ss A',
                    )}`}</LastModifiedAt>
                  ) : (
                    ''
                  )}
                </>
                <ContentBox>
                  {/* {editorRef && editorRef.current && (
                    <ToolbarWrapper >
                      <Toolbar style={{ borderTop: '10px' }}>
                        {(externalProps) => (
                          <div style={{ display: 'flex' }}>
                            <BoldButton {...externalProps} />
                            <ItalicButton {...externalProps} />
                            <UnderlineButton {...externalProps} />
                            <CodeButton {...externalProps} />
                            <Separator {...externalProps} />
                            <HeadlineOneButton {...externalProps} />
                            <HeadlineTwoButton {...externalProps} />
                            <HeadlineThreeButton {...externalProps} />
                            <UnorderedListButton {...externalProps} />
                            <OrderedListButton {...externalProps} />
                            <BlockquoteButton {...externalProps} />
                            <linkPlugin.LinkButton {...externalProps} />
                            <ImageAdd
                              editorState={newData}
                              onChange={(value) => {
                                compareData(value);
                                // onChange(value);
                              }}
                              modifier={imagePlugin.addImage}
                            />
                          </div>
                        )}
                      </Toolbar>
                    </ToolbarWrapper>
                  )} */}
                  {/* <EditorContent style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                    <Editor
                      // ref={editorRef}
                      editorState={newData}
                      plugins={plugins}
                      className="inEditor"
                      key="inEditor"
                      title="Content"
                      placeholder="Content"
                      onChange={(value) => {
                        compareData(value);
                      }}
                    />
                    <MentionSuggestions onSearchChange={onSearchChange} suggestions={suggestions} />
                  </EditorContent> */}
                </ContentBox>
              </EditorWrapper>
            </FieldWrapper>
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              // disabled={addTagsLoading}
              onClick={() => {
                checkPlagiarism(newData, newTitle);
              }}
            >
              Check Plagiarism
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </Wrapper>
  );
};

SummerisePage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  onChange: PropTypes.func,
  hanldeAddTags: PropTypes.func,
  handleAddTagsGroups: PropTypes.func,
  fetchTagSuggestions: PropTypes.func,
  onChangeCategoriesOption: PropTypes.func,
  handlePublish: PropTypes.func,
  loadingPublish: PropTypes.bool,
  publishErrorMessage: PropTypes.string,
  fetchSuggestions: PropTypes.func,
  update: PropTypes.bool,
  openUploadImage: PropTypes.bool,
  autoSummarize: PropTypes.func,
  checkPlagiarism: PropTypes.func,
  plagiarised: PropTypes.string,
  plagiarisedTitle: PropTypes.string,
  addChoices: PropTypes.func,
  removeChoice: PropTypes.func,
  validateSlug: PropTypes.func,
  editSlug: PropTypes.func,
  setEditSlug: PropTypes.func,
  usersData: PropTypes.array,
  articleFormatTypes: PropTypes.array,
  onHandleContentAlert: PropTypes.func,
  handleChangeStatus: PropTypes.func,
  modifiedatDigest: PropTypes.string,
  extraTags: PropTypes.array,
  extraCategories: PropTypes.array,
  regionsList: PropTypes.array,
  hostsList: PropTypes.array,
  digestTypesData: PropTypes.array,
  removeSegment: PropTypes.func,
  onChangeActiveSegment: PropTypes.func,
  segmentList: PropTypes.array,
  disabledHours: PropTypes.func,
  disabledMinutes: PropTypes.func,
  disabledSeconds: PropTypes.func,
  disabledEndHours: PropTypes.func,
  disabledEndMinutes: PropTypes.func,
  disabledEndSeconds: PropTypes.func,
  onSegmentSave: PropTypes.func,
  videoRef: PropTypes.object,
  previewMode: PropTypes.bool,
  videoSummary: PropTypes.array,
  activeSegment: PropTypes.object,
  subtitleMode: PropTypes.bool,
  activeTranscript: PropTypes.string,
  videoTranscriptData: PropTypes.array,
  onChangeTranscript: PropTypes.func,
  onChangeTranscriptSegment: PropTypes.func,
  handleSegmentPreview: PropTypes.func,
  onClickSegment: PropTypes.func,
  segmentError: PropTypes.object,
  videoDigest: PropTypes.array,
  scoreList: PropTypes.array,
  editorScoreText: PropTypes.array,
  aspectRatio: PropTypes.bool,
  setAspectRatio: PropTypes.func,
};

export default SummerisePage;
