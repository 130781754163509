export const convertTimeToSeconds = (time) => {
  const a = time.split(':'); // split it at the colons
  if (a.length === 2) {
    a.unshift('00');
  } else if (a.length === 3) {
    a.unshift('00');
    a.unshift('00');
  }
  return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]) + ((+a[3]) / 100);
}

export const convertSecondsToTimeFormat = (time, removeZeroValue) => {
  const date = new Date(null);
  date.setMilliseconds(time * 1000);
  if (date.toISOString().substr(11, 2) === "00" && removeZeroValue) {
    return date.toISOString().substr(14, 8).replace('.', ':');
  } 
  return date.toISOString().substr(11, 11).replace('.', ':');
}