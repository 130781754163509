import React, { useEffect, useReducer } from 'react';
import produce from 'immer';
import moment from 'moment';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import renderHTML from 'react-render-html';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import { Image, Loader, Input, Icon, Dropdown, Button, Select, Message } from 'semantic-ui-react';
import Layout from '../../Components/Layout';
import { fetchSourceInfo, fetchAllFeedCategories, fetchHosts, fetchFilteredHosts } from '../../Api/';
import Pagination from '../../Components/Pagination';

const PageHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    button {
        font-size: 15px;
        line-height: 1rem;
        font-weight: 600;
    }
`;

const Header = styled.div`
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.25em;
    margin-bottom: 3rem;
    text-transform: capitalize;
`;

const HeaderSub = styled.div`
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 20px;
    letter-spacing: -0.04em;
    line-height: 1.25em;
    text-transform: capitalize;
`;

const BothHeadings = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: 10px;
`;

const SubHeading = styled.div`
    color: #9e9e9e;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1em;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`;

const SearchList = styled.div`
    margin-top: 70px;
`;

const FeedDesc = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: start;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    max-width: 600px;
    padding: 1.5rem;
    img {
        min-width: 60px;
        height: 60px;
    }
    button {
        text-transform: uppercase;
    }
`;

const Brand = styled.div`
    color: #333333;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 5px;
`;

const BrandSource = styled.div`
    color: #9e9e9e;
    margin-bottom: 15px;
`;

const Desc = styled.div`
  flex-grow: 5;
  margin-left: 20px;
  margin-right: 20px;
  color: #757575;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;

  .rc-pagination-prev,
  .rc-pagination-next, rc-pagination-item-active {
    outline: none;
    border-color: transparent;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const ButtonGroup = styled.div`
    display: flex;
    margin-top: 20px;
`;

const FieldWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  font-weight: bold !important;
  font-size: 14px !important;

  div {
    margin-left: 15px;
    margin-right: 25px;
  }
`;

const initialValue = {
	categories: [],
	hosts: [],
	details: [],
	currentPage: 1,
	totalItems: 0,
	loading: false,
	sortOrder: null,
	filter: {},
};

const SORT_ORDER_OPTIONS = [
	{
		key: '',
		value: '',
		text: '',
	},
	{
		key: 'id',
		value: 'id',
		text: 'Id',
	},
	{
		key: 'title',
		value: 'title',
		text: 'Title',
	},
	{
		key: 'author',
		value: 'author',
		text: 'Author',
	},
	{
		key: 'created_at',
		value: 'created_at',
		text: 'Created At',
	},
	{
		key: 'modified_at',
		value: 'modified_at',
		text: 'Modified At',
	},
];

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
	produce(state, draft => {
		switch (action.type) {
			case 'CATEGORIES_SET':
				draft.categories = action.value;
				if (action.categoryId)
					draft.categoryTitle = action.value.find(category => category.key === parseInt(action.categoryId, 10));
				break;
			case 'FEED_DETAILS':
				draft.details = action.value;
				draft.totalItems = action.totalItems;
				break;
			case 'SET_CURRENT_PAGE':
				draft.currentPage = action.value;
				break;
			case 'LOADING':
				if (action.check && !draft.loading)
					draft.loading = !draft.loading;
				else if (!action.check)
					draft.loading = !draft.loading;
				break;
			case 'SORT':
				draft.sortOrder = action.value;
				break;
			case 'FILTER':
				draft.filter[action.key] = action.value;
				draft.currentPage = 1;
				break;
			case 'HOST_SET':
				draft.hosts = action.value;
				if (action.id) {
					draft.filter.host_name = action.value.find(host => host.id === parseInt(action.id, 10)).key;
				}
				break;
			case 'DATE':
				draft.date = action.value;
				break;
		}
	});

const NewsFeeds = props => {
	const [state, dispatch] = useReducer(reducer, { ...initialValue, date: props.defaultValue });
	const {
		details,
		currentPage,
		totalItems,
		loading,
		sortOrder,
		filter,
		categories,
		hosts,
		categoryTitle,
		date,
	} = state;

	const { match: { params: { id, categoryId } } } = props;

	const now = moment();
	const defaultCalendarValue = now.clone();
	defaultCalendarValue.add(-1, 'month');


	const disabledDate = current => {
		if (!current) return false;

		const date = moment(new Date());
		return current.valueOf() >= date.valueOf();
	}


	const calendar = (
		<Calendar
			style={{ zIndex: 1001 }}
			dateInputPlaceholder="please select"
			format={'DD-MM-YYYY'}
			disabledTime={null}
			timePicker={null}
			showDateInput={state.showDateInput}
			disabledDate={disabledDate}
			focusablePanel={false}
		/>
	);



	useEffect(() => {
		fetchAllFeedCategories().then(response => {
			let tempCategories = response.data.results || [];
			tempCategories = tempCategories.map(category => (
				{ key: category.id, value: category.id, text: category.title }
			));
			tempCategories.unshift({ key: '', value: '', text: 'None' });
			dispatch({ type: 'CATEGORIES_SET', value: tempCategories, categoryId });
			if (categoryId) {
				dispatch({ type: 'FILTER', key: 'categories', value: categoryId });
			}
		});
	}, [categoryId]);

	useEffect(() => {
		if (categoryId && !id) {
			fetchFilteredHosts({ categories: categoryId, followed: true }).then(response => {
				let tempHosts = response.data.results || [];
				let uniqueHosts = [];
				tempHosts.map(x => uniqueHosts.filter(a => a.host_name === x.host_name).length > 0 ? null : uniqueHosts.push(x));
				uniqueHosts = uniqueHosts.map(host => (
					{ key: host.host_name, value: host.host_name, text: host.brand_name, id: host.id }
				));
				uniqueHosts.unshift({ key: '', value: '', text: 'None', id: '' });
				dispatch({ type: 'HOST_SET', value: uniqueHosts, id });
			});
		} else {
			fetchHosts().then(response => {
				let tempHosts = response.data.results || [];
				let uniqueHosts = [];
				tempHosts.map(x => uniqueHosts.filter(a => a.host_name === x.host_name).length > 0 ? null : uniqueHosts.push(x));
				uniqueHosts = uniqueHosts.map(host => (
					{ key: host.host_name, value: host.host_name, text: host.brand_name, id: host.id }
				));
				uniqueHosts.unshift({ key: '', value: '', text: 'None', id: '' });
				dispatch({ type: 'HOST_SET', value: uniqueHosts, id });
			});
		}
	}, [categoryId, id])

	useEffect(() => {
		if (((categoryId && filter.categories) || !categoryId) && ((id && filter.host_name) || !id)) {
			dispatch({ type: 'LOADING' });
			let ordering = sortOrder && sortOrder.value;
			console.log("ordering", ordering)
			if (ordering === 'modified_at' || ordering === 'created_at' || ordering === 'id') ordering = `-${ordering}`;
			let params = {
				page: currentPage,
				...filter,
			};
			if (ordering) params.ordering = ordering;
			if (categoryId && !id) params.followed = true;
			if (filter.publish_date && moment(new Date(filter.publish_date)).format('DD-MM-YYYY') === '01-01-1970')
				delete params.publish_date;
			// 01-01-1970if (filter.publish_date === )
			fetchSourceInfo(params).then(response => {
				dispatch({ type: 'FEED_DETAILS', value: response.data.results, totalItems: response.data.count })
				dispatch({ type: 'LOADING' });
			});
		}
	}, [categoryId, currentPage, filter, id, sortOrder]);

	const sortFeeds = (event, data) => {
		data.options.forEach(option => {
			if (option.value === data.value) {
				dispatch({ type: 'SORT', value: option });
			}
		});
	};

	const defaultSort = event => {
		event.stopPropagation();
		event.preventDefault();
		dispatch({ type: 'SORT', value: '' });
	};

	const changeCategory = (event, data) => {
		dispatch({ type: 'FILTER', key: 'categories', value: data.value });
	};

	const changeHost = (event, data) => {
		dispatch({ type: 'FILTER', key: 'host_name', value: data.value });
	};

	const changeDate = (date) => {
		dispatch({ type: 'FILTER', key: 'publish_date', value: new Date(date).toISOString() });
		dispatch({ type: 'DATE', value: date });
	}

	return (
		<Layout location={props.location} feed={true}>
			{(id || categoryId) && (
				<>
					{id && categoryTitle && categoryTitle.text && (
						<BothHeadings>
							<SubHeading>
								Board:
                        </SubHeading>
							<HeaderSub>
								{categoryTitle.text}
							</HeaderSub>
						</BothHeadings>
					)}
					<SubHeading>
						{!id ? (
							'Board'
						) : (
								'Source'
							)}
					</SubHeading>
				</>
			)}
			<PageHeading>
				<Header>
					{!id && categoryTitle && categoryTitle.text}
					{id && details.length > 0 && details[0].feed_source && details[0].feed_source.host && details[0].feed_source.host.brand_name}
					{!id && !categoryId && 'All Feeds'}
				</Header>
				<ButtonWrapper>
					{(categoryId || id) && (
						<Button
							basic
							to={`/board-details/${categoryId}/sources/`}
							as={Link}
						>
							<Icon name='list' /> Manage Sources
						</Button>
					)}
					<Dropdown
						trigger={
							<span>
								<Icon
									name="x"
									link
									className={sortOrder ? 'pull-right' : 'hiddenField'}
									onClick={defaultSort}
									data-test="clear-sort"
								/>
								{(sortOrder && sortOrder.text) || 'Sort Feeds'}
							</span>
						}
						selectOnBlur={false}
						value={sortOrder && sortOrder.value}
						className="btn-group-dropdown selection"
						options={SORT_ORDER_OPTIONS}
						onChange={sortFeeds}
					/>
				</ButtonWrapper>
			</PageHeading>
			<ButtonGroup>
				<>
					{!id && !categoryId && (
						<FieldWrapper>
							Board
							<Select
								placeholder='Select board'
								options={categories}
								onChange={changeCategory}
							/>
						</FieldWrapper>
					)}
					{!id && (
						<FieldWrapper>
							Source
							<Select
								placeholder='Select source'
								options={hosts}
								onChange={changeHost}
							/>
						</FieldWrapper>
					)}
					<FieldWrapper>
						Published Date
                        <div>
							<DatePicker
								animation="slide-up"
								calendar={calendar}
								value={date}
								onChange={changeDate}
							>
								{
									({ value }) => {
										return (
											<span>
												<Input
													value={(value && moment(new Date(filter.publish_date)).format('DD-MM-YYYY')) || ''}
													icon="calendar"
												/>
											</span>
										);
									}
								}
							</DatePicker>
						</div>
					</FieldWrapper>
				</>
			</ButtonGroup>
			{!loading ? (
				<>
					<SearchList>
						{details.length > 0 ? (
							details.map(feed => {
								let time, displayValue;
								time = moment(new Date()).diff(moment(feed.created_at), 'hours');
								if (time > 23) {
									time = moment(new Date()).diff(moment(feed.created_at), 'days');
									displayValue = `${time}d`;
								} else {
									if (time === 0) {
										time = moment(new Date()).diff(moment(feed.created_at), 'minutes');
										if (time === 0) displayValue = 'Just now';
										else displayValue = `${time}m`;
									} else displayValue = `${time}h`;
								}
								const media = feed.media.length ? feed.media[0].url : feed.feed_source.host.logo_link;
								return (
									<FeedDesc key={feed.id}>
										<Image src={media} />
										<Desc>
											<Brand>{feed.title}</Brand>
											<BrandSource>{displayValue}</BrandSource>
											{renderHTML(feed.description)};
                                </Desc>
									</FeedDesc>
								)
							})
						) : (
								<Message info className="margin-top-20">
									<Message.Header>No Data Found</Message.Header>
								</Message>
							)}
					</SearchList>
					{totalItems > 20 && (
						<PaginationWrapper>
							<Pagination
								activePage={currentPage}
								totalItems={totalItems}
								totalPages={Math.round(totalItems / 20)}
								onChangePage={current => dispatch({ type: 'SET_CURRENT_PAGE', value: current })}
								pageSize={20}
							/>
						</PaginationWrapper>
					)}
				</>
			) : <Loader active={loading} />
			}
		</Layout>
	)
};

export default NewsFeeds;
