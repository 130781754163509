import React from 'react';
import styled from 'styled-components';
import { Input, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Toggle from '../../Components/Form/Toggle';

const PollWrapper = styled.div`
  margin-top: 20px;
`;

export const LeftWrapper = styled.div`
  font-size: 15px;
  color: #52575a;
  margin-top: ${({ mt }) => mt || '.53em'};
  display: block;
  font-weight: 900;
  min-width: 65px;
  span {
    display: inline-flex;
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    min-width: 45px;
  }
`;

export const ToggleLabel = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: #a9a9a9;
  display: block;
  font-weight: 800;
`;

export const ChoiceLabel = styled(Label)`
  margin-right: 10px;
  font-weight: 800;
  margin-bottom: 0;
  margin-top: 0;
`;

export const FieldErrorMessage = styled.div`
  font-size: ${({ fontSize }) => fontSize || 12}px;
  line-height: ${({ fontSize }) => fontSize || 12}px;
  margin-top: 2px;
  margin-left: 85px;
  color: #cc0000;
  font-weight: 300;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

export const ToggleWrapper = styled(ContentWrapper)`
  border-left: 2px solid #d4d4d5;

  span {
    margin: 0 0 0 10px;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  margin-top: 20px;
`;

const ChoiceWrapper = styled.div`
  width: 100%;
  button {
    margin: 10px 0 !important;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
`;

const LeftMargin = styled.div`
  padding-left: 10px;
  button {
    margin-left: 10px !important;
  }
`;

const Choices = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GridColumns = styled.div`
  display: flex;
  align-items: center;
  button {
    margin: 0 !important;
  }
  span {
    width: 80px;
  }
`;
const InputElement = styled(Input)`
  width: 100%;
  font-size: 16px !important;
  border-left: ${(params) => (params.disabled ? '2px solid #8d8d98' : '2px solid #d4d4d5')};

  input {
    background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    border: none !important;
    &:focus {
      background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    }
  }
`;

const PollInputElement = styled(InputElement)`
  margin-bottom: 5px;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  width: calc(100% - 20px);
  margin-left: 20px;
`;

const PollComponent = ({ data, onChange, removeChoice, addChoices, error }) => {
  return (
    <>
      <FieldWrapper>
        <LeftWrapper>
          <span>P</span>
        </LeftWrapper>
        <ToggleWrapper>
          <ToggleLabel>
            <Toggle
              on={data.has_poll}
              onClick={() => {
                onChange({ type: 'digest_poll', text: !data.has_poll, name: 'has_poll' });
              }}
            />
            <Label>Add Poll</Label>
          </ToggleLabel>
          {data.has_poll && (
            <PollWrapper>
              <PollInputElement
                style={{ border: 'none' }}
                onChange={(e) => {
                  onChange({ type: 'digest_poll', text: e.target.value, name: 'question' });
                }}
                key="question"
                placeholder="Poll Question"
                defaultValue={
                  data && data.question ? data.question.question : ''
                }
              />
              <ChoiceWrapper>
                <LeftMargin>
                  <Choices>
                    <GridColumns>
                      <ChoiceLabel>Choice 1</ChoiceLabel>
                      <Input
                        onChange={(e) => {
                          onChange({
                            type: 'digest_poll',
                            text: e.target.value,
                            name: 'choice',
                            index: 0,
                          });
                        }}
                        key="1"
                        action={
                          data &&
                          data.choices &&
                          data.choices.length > 2 && (
                            <Button
                              icon="minus"
                              style={{ margin: '0 !important' }}
                              onClick={() => removeChoice(0)}
                            />
                          )
                        }
                        value={
                          data && data.choices && data.choices[0]
                            ? data.choices[0].choice
                            : ''
                        }
                      />
                    </GridColumns>
                    <GridColumns>
                      <ChoiceLabel>Choice 2</ChoiceLabel>
                      <Input
                        onChange={(e) => {
                          onChange({
                            type: 'digest_poll',
                            text: e.target.value,
                            name: 'choice',
                            index: 1,
                          });
                        }}
                        key="2"
                        action={
                          data &&
                          data.choices &&
                          data.choices.length > 2 && (
                            <Button
                              icon="minus"
                              style={{ margin: '0 !important' }}
                              onClick={() => removeChoice(1)}
                            />
                          )
                        }
                        value={
                          data && data.choices && data.choices[1]
                            ? data.choices[1].choice
                            : ''
                        }
                      />
                    </GridColumns>
                  </Choices>
                  {data && data.choices && data.choices.length > 2 && (
                    <Choices style={{ marginTop: 10 }}>
                      <GridColumns>
                        <ChoiceLabel>Choice 3</ChoiceLabel>
                        <Input
                          onChange={(e) => {
                            onChange({
                              type: 'digest_poll',
                              text: e.target.value,
                              name: 'choice',
                              index: 2,
                            });
                          }}
                          key="3"
                          action={
                            <Button
                              icon="minus"
                              style={{ margin: '0 !important' }}
                              onClick={() => removeChoice(2)}
                            />
                          }
                          value={
                            data && data.choices && data.choices[2]
                              ? data.choices[2].choice
                              : ''
                          }
                        />
                      </GridColumns>
                      {data && data.choices && data.choices.length === 4 && (
                        <GridColumns>
                          <ChoiceLabel>Choice 4</ChoiceLabel>
                          <Input
                            onChange={(e) => {
                              onChange({
                                type: 'digest_poll',
                                text: e.target.value,
                                name: 'choice',
                                index: 3,
                              });
                            }}
                            key="4"
                            action={
                              <Button
                                icon="minus"
                                style={{ margin: '0 !important' }}
                                onClick={() => removeChoice(3)}
                              />
                            }
                            value={
                              data && data.choices && data.choices[3]
                                ? data.choices[3].choice
                                : ''
                            }
                          />
                        </GridColumns>
                      )}
                    </Choices>
                  )}
                  {data && data.choices && data.choices.length < 4 && (
                    <Button onClick={addChoices}>Add Choice</Button>
                  )}
                </LeftMargin>
              </ChoiceWrapper>
            </PollWrapper>
          )}
        </ToggleWrapper>
      </FieldWrapper>
      <FieldErrorMessage>{error}</FieldErrorMessage>
    </>
  );
};

PollComponent.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  removeChoice: PropTypes.func,
  addChoices: PropTypes.func,
  error: PropTypes.string,
};

export default PollComponent;
