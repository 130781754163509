import React from 'react';
import styled from 'styled-components';

const GraphDiv = styled.div`
    position: relative;
    max-width: 800px;
    display: flex;
    align-items: center;
`;

const BarContent = styled.div`
    height: 100%;
    width: 12%;
    div {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;

const BarGraph = styled.div`
    height: 35px;
    width: ${(props => `${props.width}%`)};
    background: #e05b42;
    z-index: 25;
    border-bottom: 1px solid #222;
    border-right: 1px solid #222;
`;

const GraphContent = styled.div`
    width: 88%;
    height: 100%;
`;

const Bar = ({count, label, totalVotes}) => (
    <BarGraph title={count} key={label} width={count / totalVotes * 100 } />
);

const BarTextContent = ({ label }) => (
    <BarContent>
        {label.map(val => (
            <div key={`label${label}`}>
                {val.choice}
            </div>
        ))}
    </BarContent>
);

const Graph = (props) => (
    <div className="graph-wrapper">
        <GraphDiv>
            <BarTextContent label={props.data} />
            <GraphContent>
                {props.data.map(val => (
                    <Bar key={val.choice} count={val.choice_count} label={val.choice} totalVotes={props.totalVotes} />
                ))}
            </GraphContent>
        </GraphDiv>
    </div>
);

export default Graph;