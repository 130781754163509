import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import transform from 'lodash/transform';

/* eslint-disable no-param-reassign */
export function trimObjectValues(payload, options = { removeBlanks: false }) {
  const values = { ...payload };
  function transformer(result, value, key) {
    if (isString(value)) {
      const trimmedValue = value.trim();
      if (
        !options.removeBlanks ||
        !isArray(result) ||
        (options.removeBlanks && trimmedValue && isArray(result))
      ) {
        result[key] = trimmedValue;
      }
    } else if (isArray(value)) {
      result[key] = transform(value, transformer, []);
    } else if (isObject(value)) {
      result[key] = transform(value, transformer, {});
    } else {
      result[key] = value;
    }
  }
  return transform(values, transformer, {});
}

export function autoGeneratePassword() {
  return Math.random().toString(36).slice(7) + Math.random().toString(36).slice(10).toLocaleUpperCase();
}
