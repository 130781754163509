/* istanbul ignore file */

import axios from 'axios';

export function setAxiosAuthorizationHeader() {
  const token = localStorage.getItem('token');
  const selectedLanguage = localStorage.getItem('selectedLanguage');
  if (selectedLanguage) {
    axios.defaults.headers.common[`Accept-Language`] = JSON.parse(selectedLanguage).code;
  }
  if (token) {
    axios.defaults.headers.common.Authorization = `Token ${token}`;
  } else {
    axios.defaults.headers.common.Authorization = null;
    delete axios.defaults.headers.common.Authorization;
  }
}

export function removeHeaderTransform() {
  return {
    transformRequest: [
      (data, headers) => {
        delete headers.common.Authorization; // eslint-disable-line no-param-reassign
        return data;
      },
    ],
  };
}
