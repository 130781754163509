/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react/prop-types */
import React, { useRef, useReducer, useEffect, useCallback, useState } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Button, Input, Icon, Modal } from 'semantic-ui-react';
import 'antd/dist/antd.css';
import 'antd/es/date-picker/style/css';
import { TimePicker } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import ResizableRect from 'react-resizable-rotatable-draggable';
import { getUpdatedHtml } from './PlayerFile';
import Toggle from '../../Components/Form/Toggle';
import { fetchAllStatuses } from '../../Api';
import { useLongPress } from '../../Utils/useLongPress';

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  /* padding-bottom: 50px; */
`;

const VideoFooter = styled.div`
  /* width: 100%;
  position: absolute;
  bottom: 0;
  left: 0; */
  padding: 5px 15px;
  height: 50px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  i {
    color: #fff;
  }
`;

// const PlayPauseButton = styled.div`
//   padding: 5px;
//   cursor: pointer;
// `;

const VideoFooterTimeWrapper = styled.div`
  span {
    font-size: 14px;
    color: #fff;
    font-weight: 900;
    margin-right: 10px;
  }

  .ant-time-picker-clear {
    display: none;
  }
`;

const SummaryWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const VideoPlayer = styled.div`
  z-index: 9999999;
`;

const VideoCropperArea = styled.div`
  height: 77%;
  width: 100%;
  z-index: 111;
  position: absolute;
  top: 0;
  overflow: hidden;
  .single-resizer {
    box-shadow: 0 0 0 9999em;
    color: rgba(0, 0, 0, 0.5);
  }
`;

const MarkAsReportWrapper = styled.div`
  width: 50%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 135px;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
`;

const FieldWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: bold !important;
  font-size: 14px !important;
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_PLAYING_VIDEO':
        draft.playingVideo = action.value;
        break;
      case 'SET_VIDEO_CURRENT_TIME':
        draft.currentTime = action.value;
        break;
      case 'SET_STATUSES':
        draft.statuses = action.value.map((status) => ({
          key: status.status_name,
          value: status.status_name,
          text: status.status_name,
        }));
        break;
      case 'SET_CURRENT_STATUS':
        draft.status = action.value;
        break;
      case 'CONTINUE_EDIT':
        draft.continueEditing = !draft.continueEditing;
        break;
    }
  });

let feUrl = 'https://news.travelnewsdigest.in';
switch (process.env.REACT_APP_ENV) {
  case 'oped-production':
    feUrl = 'https://news.getdailybrief.com';
    break;
  case 'clip-production':
    feUrl = 'https://watch.sphr.tv'; // 'https://theclipapp.com';
    break;
  case 'oped-staging':
    feUrl = 'https://staging.getdailybrief.com';
    break;
  case 'und-production':
    feUrl = 'https://read.urbannewsdigest.in';
    break;
  case 'und-staging':
    feUrl = 'https://staging-site.urbannewsdigest.in';
    break;
  case 'staging':
    feUrl = 'https://prod1.opiniondigest.in';
    break;
  case 'production':
    feUrl = 'https://news.travelnewsdigest.in';
    break;
}

export const convertTimeToSeconds = (time) => {
  const a = time.split(':'); // split it at the colons
  if (a.length === 2) {
    a.unshift('00');
  } else if (a.length === 3) {
    a.unshift('00');
    a.unshift('00');
  }
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;
};

const percentageToPx = (wholeValue, parts) => {
  const percentage = Number(((Number(wholeValue) / 100) * Number(parts)).toFixed(2));
  return percentage;
};
const VideoColumn = ({
  urlString,
  onSetActiveSegment,
  videoRef,
  segmentList,
  mobilePreviewData,
  // onPublishVideoArticle,
  // setVideoPublishLoading,
  showDuration,
  disabledHours,
  disabledMinutes,
  disabledSeconds,
  startTime,
  previewMode,
  subtitleMode,
  togglePreview,
  toggleSubtitle,
  onChangeTranscript,
  videoTranscriptData,
  activeTranscript,
  // initialStatus,
  activeSegment,
  slug,
  onChangeCropper,
  cropCoord,
  coOrdinate,
  setCoOrdinate,
  isMarkAsStart,
  setIsMarkAsStart,
  isReset,
  setIsReset,
}) => {
  const intervalRef = useRef(null);
  const intervalOfVideo = useRef(null);
  // const intervalOfPlayVideo = useRef(null);
  const currentVideoTimeRef = useRef(0);
  const activeTranscriptRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, {
    playingVideo: false,
    currentTime: null,
    env: `${feUrl}/digests/${slug}`,
  });
  const defaultWidth = 180;
  const defaultHeight = 320;
  const defaultLeftX = 140;
  const defaultTopY = 0;

  const [leftX, setLeftX] = useState(defaultLeftX);
  const [open, setOpen] = React.useState(false);
  const [topY, setTopY] = useState(defaultTopY);
  const [rectWidth, setWidth] = useState(defaultWidth);
  const [rectHeight, setHeight] = useState(defaultHeight);
  const [cropperCoOrdinate, setCropperCoOrdinate] = useState({});
  // const [isMarkAsStart, setIsMarkAsStart] = useState(true);
  const [getLatestHtml, setGetLatestHtml] = useState('');
  const [cropRecTime, setCropRecTime] = useState(null);
  const { playingVideo, currentTime } = state;
  // let onCropEnd = null;

  const videoCropLongPressProps = useLongPress({
    onClick: (ev) => {
      if (videoRef.current) {
        const playerState = videoRef.current.getPlayerState();
        if (playerState === 5 || playerState === 2) {
          videoRef.current.playVideo();
        } else if (playerState === 1) {
          videoRef.current.pauseVideo();
        }
      }
    },
    onLongPress: (ev) => {},
  });

  useEffect(() => {
    const validUrl =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

    if (
      mobilePreviewData &&
      mobilePreviewData.cover_pic &&
      mobilePreviewData.cover_pic.match(validUrl)
    ) {
      // eslint-disable-next-line prefer-destructuring
      const videoIds = mobilePreviewData.cover_pic.match(validUrl)[1];

      const file = getUpdatedHtml(
        videoIds,
        1,
        mobilePreviewData.custom_thumbnail,
        mobilePreviewData.video_summary,
        mobilePreviewData.display_type,
      );
      setGetLatestHtml(file);
    }
    if (activeSegment.id) {
      setCropperCoOrdinate(activeSegment.timeCoordinate);
    }
  }, [activeSegment, mobilePreviewData]);

  useEffect(() => {
    if (
      videoRef.current &&
      typeof videoRef.current.pauseVideo &&
      typeof videoRef.current.playVideo
    ) {
      if (previewMode) videoRef.current.playVideo();
      else {
        // dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
        videoRef.current.pauseVideo();
      }
    }
  }, [previewMode, videoRef]);

  useEffect(() => {
    activeTranscriptRef.current = activeTranscript;
    if (activeTranscript && activeTranscript.manuallyChange) {
      videoRef.current.seekTo(parseFloat(activeTranscript.start));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTranscript]);

  useEffect(() => {
    fetchAllStatuses().then((response) => {
      dispatch({ type: 'SET_STATUSES', value: response.data.results, total: response.data.total });
    });
  }, []);

  const onCropEnd = useCallback(
    (x, y, width, height) => {
      const maxWidth = document.getElementById('youtubePlayer').clientWidth;
      const maxHeight = document.getElementById('youtubePlayer').clientHeight;
      onChangeCropper(
        {
          topLeft: { x_coord_: x, y_coord: y },
          bottomRight: { x_coord_: x + width, y_coord: y + height },
        },
        maxWidth,
        maxHeight,
      );
    },
    [onChangeCropper],
  );

  useEffect(() => {
    const maxWidth = document.getElementById('youtubePlayer').clientWidth;
    const maxHeight = document.getElementById('youtubePlayer').clientHeight;
    if (
      cropCoord.topLeft.x_coord_ !== 0 &&
      cropCoord.topLeft.x_coord_ !== 0 &&
      cropCoord.topLeft.x_coord_ !== 0 &&
      cropCoord.topLeft.x_coord_ !== 0
    ) {
      const left = percentageToPx(maxWidth, cropCoord.topLeft.x_coord_);
      const top = percentageToPx(maxHeight, cropCoord.topLeft.y_coord);
      const right = percentageToPx(maxWidth, cropCoord.bottomRight.x_coord_);
      const bottom = percentageToPx(maxHeight, cropCoord.bottomRight.y_coord);
      setLeftX(left);
      setTopY(top);
      setWidth(right - left);
      setHeight(bottom - top);
    } else {
      setLeftX(defaultLeftX);
      setTopY(defaultTopY);
      setWidth(defaultWidth);
      setHeight(defaultHeight);
      onCropEnd(defaultLeftX, defaultTopY, defaultWidth, defaultHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropCoord]);

  useEffect(() => {
    if (videoRef.current) {
      if (playingVideo) {
        videoRef.current.playVideo();
      } else {
        dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
        videoRef.current.pauseVideo();
      }
    }
  }, [playingVideo, videoRef]);

  useEffect(() => {
    if (playingVideo && !activeTranscriptRef.current) {
      onChangeTranscript(videoTranscriptData[0]);
    }
    //  else if (playingVideo && activeTranscriptRef.current) setTimeout(() => dispatch({ type: 'SET_PLAYING_VIDEO', value: true }));
  }, [onChangeTranscript, playingVideo, videoTranscriptData]);

  useEffect(() => {
    dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: startTime });
  }, [startTime]);

  const checkActiveTranscript = (time) => {
    if (time === 0) {
      onChangeTranscript(videoTranscriptData[0]);
    } else {
      videoTranscriptData.forEach((item) => {
        const { start } = item;
        const endTime = (item.start + item.duration).toFixed(2);
        if (time >= start && time < endTime) {
          onChangeTranscript(item);
        }
      });
    }
  };

  useEffect(() => {
    if (playingVideo && videoRef && videoRef.current && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (playingVideo && videoRef && videoRef.current) {
          const time = videoRef.current.getCurrentTime();
          const date = new Date(null);
          date.setMilliseconds(time * 1000);
          const timeFormat = date.toISOString().substr(11, 11).replace('.', ':');

          const transcriptValue = activeTranscriptRef.current;
          // const transcriptEndValue = (transcriptValue.start + transcriptValue.duration).toFixed(2);
          if (Math.abs(time - currentVideoTimeRef.current) > 0.3) {
            checkActiveTranscript(time);
          } else if (transcriptValue) {
            const transcriptEndValue = (transcriptValue.start + transcriptValue.duration).toFixed(
              2,
            );
            if (time >= transcriptEndValue) {
              onChangeTranscript(videoTranscriptData[transcriptValue.index + 1]);
            }
          }
          currentVideoTimeRef.current = time;
          dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: timeFormat });

          // eslint-disable-next-line no-empty
        }
      }, 50);
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingVideo, videoRef, videoTranscriptData]);

  const setMillisecs = (e) => {
    const { value } = e.target;
    const time = currentTime || '00:00:00:00';
    const a = time.split(':');

    a[3] = value > 99 || value < 0 ? a[3] : value;
    const s = `${a[0]}:${a[1]}:${a[2]}:${a[3]}`;
    const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;

    videoRef.current.seekTo(parseFloat(seconds));
    dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: s });
    setTimeout(() => videoRef.current.pauseVideo());
  };

  const createYTRef = useCallback(() => {
    const validUrl =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (urlString.match(validUrl)) {
      const videoId = urlString.match(validUrl)[1];
      videoRef.current = new window.YT.Player(`youtubePlayer`, {
        videoId,
        width: '100%',
        events: {
          onReady: showDuration,
        },
      });
      videoRef.current.addEventListener('onStateChange', (stateValue) => {
        if (stateValue.data === 1) {
          dispatch({ type: 'SET_PLAYING_VIDEO', value: true });
        }

        if (stateValue.data === 2) {
          videoRef.current.pauseVideo();
          dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
        }
      });
    }
  }, [showDuration, urlString, videoRef]);

  const loadVideo = useCallback(() => {
    if (urlString && !videoRef.current && window.YT) {
      if (typeof window.YT.Player === 'undefined') {
        window.onYouTubeIframeAPIReady = () => {
          createYTRef();
        };
      } else {
        createYTRef();
      }
    }
  }, [createYTRef, urlString, videoRef]);

  useEffect(() => {
    loadVideo();
  }, [loadVideo, playingVideo, showDuration, urlString, videoRef]);

  const handleDrag = (deltaX, deltaY) => {
    const left = leftX + deltaX;
    const top = topY + deltaY;
    const rightSize = document.getElementById('youtubePlayer').clientWidth;
    const rightBottom = document.getElementById('youtubePlayer').clientHeight - 40;
    setLeftX(left > 0 && left + rectWidth < rightSize ? left : leftX);
    setTopY(top > 0 && top + rectHeight < rightBottom ? top : topY);
    if (rightSize + 50 < left) {
      document.getElementById('resizableRect').click();
    }
  };

  const handleResize = (style) => {
    let { top, left, width, height } = style;
    top = Math.round(top);
    left = Math.round(left);
    width = Math.round(width);
    height = Math.round(height);
    const right = left + width;
    const bottom = top + height;
    const maxWidth = document.getElementById('youtubePlayer').clientWidth;
    const maxHeight = document.getElementById('youtubePlayer').clientHeight - 40;
    if (top >= 0 && bottom > 0 && bottom < maxHeight && right > 0 && right < maxWidth) {
      setHeight(height);
      setWidth(width);
      setTopY(top);
      setLeftX(left);
    } else {
      window.focus();
    }
  };

  useEffect(() => {
    if (isMarkAsStart && typeof videoRef.current.getCurrentTime !== 'undefined') {
      const time = videoRef.current.getCurrentTime();
      const date = new Date(null);
      date.setMilliseconds(time * 1000);
      const timeFormat = date.toISOString().substr(11, 11).replace('.', ':');
      const timeSlot = moment(timeFormat, 'HH:mm:ss:SS').format('HH:mm:ss');
      if (activeSegment.id === undefined || isReset) {
        cropperCoOrdinate[timeSlot] = {
          topLeft: { x_coord_: leftX, y_coord: topY },
          bottomRight: { x_coord_: rectWidth + leftX, y_coord: rectHeight + topY },
        };
        setCropperCoOrdinate(cropperCoOrdinate);
      }
    }
    if (activeSegment.id && !isMarkAsStart) {
      const time = videoRef.current.getCurrentTime();
      const date = new Date(null);
      date.setMilliseconds(time * 1000);
      const timeFormat = date.toISOString().substr(11, 11).replace('.', ':');
      const timeSlot = moment(timeFormat, 'HH:mm:ss:SS').format('HH:mm:ss');
      if (cropperCoOrdinate[timeSlot]) {
        setLeftX(cropperCoOrdinate[timeSlot].topLeft.x_coord_);
        setTopY(cropperCoOrdinate[timeSlot].topLeft.y_coord);
        setWidth(
          cropperCoOrdinate[timeSlot].bottomRight.x_coord_ -
            cropperCoOrdinate[timeSlot].topLeft.x_coord_,
        );
        setHeight(
          cropperCoOrdinate[timeSlot].bottomRight.y_coord -
            cropperCoOrdinate[timeSlot].topLeft.y_coord,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cropRecTime, isMarkAsStart]);

  useEffect(() => {
    if (intervalOfVideo.current === null) {
      intervalOfVideo.current = setInterval(() => {
        if (videoRef.current.getPlayerState() === 1) {
          const time = videoRef.current.getCurrentTime();
          setCropRecTime(time);
        }
      }, 1000);
    }
    return () => {
      clearInterval(intervalOfVideo.current);
      intervalOfVideo.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoRef]);

  /* useEffect(() => {
    if (!intervalOfPlayVideo.current && activeSegment.id) {
      intervalOfPlayVideo.current = setInterval(() => {
        const time = videoRef.current.getCurrentTime();
        const date = new Date(null);
        date.setMilliseconds(time * 1000);
        const timeFormat = date.toISOString().substr(11, 11).replace('.', ':');
        const timeSlot = moment(timeFormat, 'HH:mm:ss:SS').format('HH:mm:ss');
        if (cropperCoOrdinate[timeSlot]) {
          setLeftX(cropperCoOrdinate[timeSlot].topLeft.x_coord_);
          setTopY(cropperCoOrdinate[timeSlot].topLeft.y_coord);
          setWidth(
            cropperCoOrdinate[timeSlot].bottomRight.x_coord_ - cropperCoOrdinate[timeSlot].topLeft.x_coord_,
          );
          setHeight(
            cropperCoOrdinate[timeSlot].bottomRight.y_coord - cropperCoOrdinate[timeSlot].topLeft.y_coord,
          );
        }
      }, 1000);
    }

    return () => {
      clearInterval(intervalOfPlayVideo.current);
      intervalOfPlayVideo.current = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingVideo, videoRef, videoTranscriptData, activeSegment, leftX, topY, rectWidth, rectHeight, setLeftX]);
*/
  return (
    <div>
      <VideoWrapper>
        <VideoPlayer id="youtubePlayer" />
        <VideoCropperArea id="videoCropperArea" {...videoCropLongPressProps}>
          {videoRef && videoRef.current && (
            <ResizableRect
              left={leftX}
              top={topY}
              width={rectWidth}
              height={rectHeight}
              aspectRatio={9 / 16}
              zoomable="n, w, s, e, nw, ne, se, sw"
              onResize={(style) => handleResize(style)}
              // onResizeEnd={() => onCropEnd(leftX, topY, rectWidth, rectHeight)}
              onDrag={(deltaX, deltaY) => handleDrag(deltaX, deltaY)}
              // onDragEnd={() => onCropEnd(leftX, topY, rectWidth, rectHeight)}
            />
          )}
        </VideoCropperArea>
        <VideoFooter>
          <VideoFooterTimeWrapper
            onClick={() => {
              dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
            }}
          >
            <TimePicker
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
              disabledSeconds={disabledSeconds}
              value={
                currentTime
                  ? moment(`${moment().format('DD:MM:YY')} ${currentTime}`, 'DD:MM:YY HH:mm:ss:SS')
                  : moment('00:00:00', 'HH:mm:ss:fff')
              }
              size="large"
              onChange={(value) => {
                if (value && value.format('HH:mm:ss:SS') && videoRef.current) {
                  // const a = value.format('HH:mm:ss:SS').split(':'); // split it at the colons
                  const seconds = convertTimeToSeconds(value.format('HH:mm:ss:SS'));
                  videoRef.current.seekTo(seconds);
                  dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: value.format('HH:mm:ss:SS') });
                  if (!playingVideo) {
                    setTimeout(() => videoRef.current.pauseVideo());
                    videoRef.current.playVideo();
                  }
                }
              }}
            />
            {!previewMode && (
              <Input
                value={`${currentTime ? currentTime.split(':')[3] : 0}`}
                type="number"
                onChange={setMillisecs}
                min="0"
                max="99"
              />
            )}
          </VideoFooterTimeWrapper>
        </VideoFooter>
      </VideoWrapper>
      <SummaryWrapper>
        <div>{currentTime}</div>
        <MarkAsReportWrapper>
          <Button
            onClick={() => {
              const time = currentTime
                ? moment(`${moment().format('DD:MM:YY')} ${currentTime}`, 'DD:MM:YY HH:mm:ss:SSS')
                : moment('00:00:00', 'HH:mm:ss:fff');
              onSetActiveSegment({ type: 'timestamp_start', value: time });
              setCropperCoOrdinate({});
              setCoOrdinate({});
              setIsMarkAsStart(true);
            }}
          >
            Mark As Start
          </Button>
          <Button
            onClick={() => {
              const time = currentTime
                ? moment(`${moment().format('DD:MM:YY')} ${currentTime}`, 'DD:MM:YY HH:mm:ss:SSS')
                : moment('00:00:00', 'HH:mm:ss:fff');
              onSetActiveSegment({ type: 'timestamp_end', value: time });
              setIsMarkAsStart(false);
              setCoOrdinate(cropperCoOrdinate);
            }}
          >
            Mark As End
          </Button>
        </MarkAsReportWrapper>
        {/* <FieldWrapper>
          Continue Editing
          <CheckboxWrapper
            checked={continueEditing}
            onClick={() => dispatch({ type: 'CONTINUE_EDIT' })}
          />
        </FieldWrapper>
        <PublishButtonWrapper>
          <Select
            placeholder="Status"
            options={statuses}
            defaultValue={initialStatus || 'Draft'}
            onChange={(e, data) => {
              dispatch({ type: 'SET_CURRENT_STATUS', value: data.value });
            }}
          />
          <Button
            onClick={(e) => onPublishVideoArticle(status, continueEditing)}
            disabled={segmentList.length === 0 || setVideoPublishLoading}
          >
            Save
          </Button>
        </PublishButtonWrapper>
        {initialStatus === 'Published' && (
          <PublishButtonWrapper>
            <a href={env} target="_blank" rel="noopener noreferrer">
              View published page
            </a>
          </PublishButtonWrapper>
        )} */}
        {segmentList.length > 0 && (
          <>
            <FieldWrapper>
              Preview Mode
              <Toggle on={previewMode} onClick={togglePreview} />
            </FieldWrapper>
            <FieldWrapper>
              Mobile Mode
              <Icon
                name="mobile alternate"
                color="green"
                size="big"
                onClick={() => setOpen(true)}
              />
            </FieldWrapper>
          </>
        )}
        {videoTranscriptData && videoTranscriptData.length > 0 && (
          <FieldWrapper>
            Subtitle Mode
            <Toggle on={subtitleMode} onClick={toggleSubtitle} />
          </FieldWrapper>
        )}
      </SummaryWrapper>
      <Modal onClose={open} onOpen={open} open={open}>
        <Modal.Header>Mobile Preview</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <center>
              <iframe
                width="375"
                height="667"
                frameBorder="0"
                id="yt-player"
                srcDoc={getLatestHtml}
              ></iframe>
            </center>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)} basic color="red">
            Close
          </Button>
        </Modal.Actions>
      </Modal>
    </div>
  );
};

VideoColumn.propTypes = {
  urlString: PropTypes.string,
  onSetActiveSegment: PropTypes.func,
  videoRef: PropTypes.object,
  segmentList: PropTypes.array,
  // onPublishVideoArticle: PropTypes.func,
  // setVideoPublishLoading: PropTypes.bool,
  showDuration: PropTypes.func,
  disabledHours: PropTypes.func,
  disabledMinutes: PropTypes.func,
  disabledSeconds: PropTypes.func,
  startTime: PropTypes.string,
  previewMode: PropTypes.bool,
  subtitleMode: PropTypes.bool,
  togglePreview: PropTypes.func,
  toggleSubtitle: PropTypes.func,
  onChangeTranscript: PropTypes.func,
  videoTranscriptData: PropTypes.array,
  activeTranscript: PropTypes.string,
  // initialStatus: PropTypes.string,
  slug: PropTypes.string,
  onChangeCropper: PropTypes.func,
  activeSegment: PropTypes.object,
  cropCoord: PropTypes.object,
};

export default VideoColumn;
