import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from '../Icon';

export const SubText = styled.div`
  font-size: ${({ fontSize }) => fontSize || '14px'};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  color: ${({ color }) => color || '#666666'};
  margin: ${({ noGutter }) => (noGutter ? '0' : '4px 0')};
  font-family: 'Roboto', sans-serif;
  display: -webkit-box; /* stylelint-disable-line */
  -webkit-line-clamp: ${({ noLineClamp }) => (noLineClamp ? 'none' : 2)};
  -webkit-box-orient: vertical; /* stylelint-disable-line */
  overflow: ${({ showOverflow }) => (showOverflow ? 'inherit' : 'hidden')};
  padding: ${({ padding }) => padding || 0};
  cursor: ${({ addCursorPointer }) => (addCursorPointer ? 'pointer' : '')};
  font-style: ${({ fontStyle }) => fontStyle || 'none'};
  line-height: ${({ lineHeight }) => lineHeight || 'auto'};
  height: ${({ height }) => height || 'auto'};
`;

export const PageTitleText = ({ title, children }) => (
  <SubText
    fontSize="29px"
    fontWeight="900"
    color="#1b253a"
    padding="0 40px 0 0"
    lineHeight="34px"
    height="auto"
  >
    {title}
    {children}
  </SubText>
);

PageTitleText.propTypes = {
  title: PropTypes.string,
  children: PropTypes.object,
};

const BackButtonIcon = styled(Icon)`
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
  svg {
    vertical-align: top;
  }
`;

const PageTitle = styled(SubText)`
  display: flex;
  align-items: center;
`;

export const HorizontalLine = styled.div`
  height: 2px;
  background-color: '#dfdfdf';
  margin: 5px 0;
`;

export const PageTitleWithBackButton = ({ title, onClickBack }) => (
  <>
    <PageTitle
      fontSize="29px"
      fontWeight="900"
      color="#1b253a"
      padding="0 40px 0 0"
      lineHeight="34px"
      height="auto"
    >
      <BackButtonIcon
        onClick={onClickBack}
        name="leftArrow"
        size={20}
        color="#1b253a"
        data-test="go-back"
      />
      {title}
    </PageTitle>
    <HorizontalLine />
  </>
);

PageTitleWithBackButton.propTypes = {
  title: PropTypes.string,
  onClickBack: PropTypes.func,
};
