import React, { useEffect } from "react";
import styled from 'styled-components';

const VideoContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
`;

const TwitterWidgetScript = "https://platform.twitter.com/widgets.js"

function getScriptNode(src) {
    const node = document.querySelector(
        `script[src="${src}"]`,
    )
    const status = node?.getAttribute('data-status')
    return {
        node,
        status,
    }
}

function loadScript(src) {

    let script = getScriptNode(src)

    if (script.node) {
        return;
    }

    return new Promise((resolve) => {
        const script = document.createElement('script')
        script.src = src
        script.onload = () => {
            resolve(true)
        }
        script.onerror = () => {
            resolve(false)
        }
        document.body.appendChild(script)
    })
}

const TwitterPlayer = ({ tweetLink }) => {

    useEffect(() => {
        loadScript(TwitterWidgetScript)

        return () => {
            let script = getScriptNode(TwitterWidgetScript)

            if (script.node) {
                script.node.remove()
            }
        }
    }, [])

    return (<VideoContainer className="twitter-embed">
        <blockquote className="twitter-tweet" data-media-max-width="1024">
            <a href={tweetLink}>Tweeter Preview</a>
        </blockquote>
    </VideoContainer>)
};

export default TwitterPlayer;