import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableView from '../../Components/TableView';

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-top: solid 6px #52575a;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  margin-left: 5px;
`;

const UpArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 6px solid #52575a;
  margin-left: 5px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  align-items: baseline;
  cursor: pointer;
`;

const List = styled.div`
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
  box-shadow: 0px 4px 30px 0px rgba(220, 220, 220, 1);
  position: absolute;
  z-index: 50;
  background-color: #fff;
  border-radius: 10px;
  width: 40%;
  max-height: 310px;
  overflow-y: scroll;
`;

const FlexList = styled.div`
  height: 50px;
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: center;
  padding: 5px;
  border-top: 1px solid #fafafa;

  &:hover {
    background: #e6f3ff;
  }

  img {
    height: 40px;
    width: 40px;
    border-radius: 10px;
    margin-right: 20px;
  }
`;

const ScoreTable = styled.div`
  .rc-table,
  .rc-table-content {
    position: inherit;
  }
`;

const DigestScoreTable = ({ data, showDigestList, openDigestList }) => {
  const columns = [
    {
      title: 'Category Title',
      dataIndex: 'category__title',
      key: 'category__title',
      render: (value) => value || '-',
      align: 'center',
    },
    {
      title: 'Number of digests',
      dataIndex: 'count',
      key: 'count',
      render: (value, row, index) => (
        <div>
          <Flex
            onClick={(e) => {
              e.stopPropagation();
              openDigestList(index);
            }}
          >
            <span>{value || 0}</span>
            {showDigestList !== index ? <DownArrow /> : <UpArrow />}
          </Flex>
          {showDigestList === index && (
            <List>
              {data[index].digests.map((digest) => (
                <FlexList>
                  <img
                    src={
                      digest.cover_pic &&
                      digest.cover_pic.includes('https://www.youtube.com/watch?v')
                        ? `https://i3.ytimg.com/vi/${digest.cover_pic.substring(
                          digest.cover_pic.indexOf('=') + 1,
                          digest.cover_pic.length,
                        )}/hqdefault.jpg`
                        : digest.cover_pic
                    }
                    alt=""
                  />
                  <span>{digest.slug}</span>
                </FlexList>
              ))}
            </List>
          )}
        </div>
      ),
      align: 'center',
    },
    {
      title: 'Quality',
      dataIndex: 'editor_score_quality',
      key: 'editor_score_quality',
      render: (value) => value || 0,
      align: 'center',
    },
  ];
  return (
    <ScoreTable>
      <TableView tableColumns={columns} data={data} />
    </ScoreTable>
  );
};

DigestScoreTable.propTypes = {
  data: PropTypes.object,
  showDigestList: PropTypes.bool,
  openDigestList: PropTypes.func,
};

export default DigestScoreTable;
