import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SubText } from '../../Components/SubText';
import Icon from '../../Components/Icon';
import { titleCase } from '../../Utils/titleCase';

const Container = styled.div`
  flex: 1;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
`;

const PillWrapper = styled.div`
  background-color: #f3f3f3;
  height: 25px;
  border-radius: 10.5px;
  padding: 2.5px 24px 2.5px 10px;
  position: relative;
  margin: 5px 5px 5px 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding: 2px 5px 5px;
  cursor: pointer;
`;

const Pills = ({ filterKey, filterObject, onSelectFilter }) => {
  const showTags = () => {
    if (filterKey === 'notificationType' && typeof filterObject === 'string')
      return titleCase(filterObject);
    if (filterKey === 'selectedSource' || filterKey === 'selectedAuthor')
      return filterObject[filterKey];

    if (filterKey === 'quality') {
      return 'Quality ' + filterObject[filterKey];
    }
    
    if (filterKey === 'relevanceScore') {
      return 'Relevance ' + filterObject[filterKey];
    }
    if (
      filterKey === 'selectedCategory' ||
      filterKey === 'notificationType' ||
      filterKey === 'selectedLabels'
    )
      return filterObject.text;
    return titleCase(filterObject[filterKey]);
  };

  return (
    <PillWrapper key={filterKey}>
      <SubText
        color="#4a4a4a"
        fontWeight={300}
        noGutter
        data-test={`pill.${filterKey.split(' ').join('_')}`}
      >
        {showTags(filterKey)}
      </SubText>
      <IconWrapper
        onClick={() => {
          if (
            filterKey === 'selectedCategory' ||
            filterKey === 'notificationType' ||
            filterKey === 'selectedLabels'
          ) {
            onSelectFilter(filterKey, filterObject);
          } else onSelectFilter(filterKey, '');
        }}
      >
        <Icon
          name="fillClose"
          size={15}
          color="#009fa2"
          data-test={`remove-pill.${filterKey.split(' ').join('_')}`}
        />
      </IconWrapper>
    </PillWrapper>
  );
};

Pills.propTypes = {
  filterObject: PropTypes.object,
  onSelectFilter: PropTypes.func,
  filterKey: PropTypes.string,
};

const FilterPills = ({ filterObject, onSelectFilter }) => {
  const filterArray = Object.keys(filterObject);
  if (!filterArray.length) return null;
filterArray.map((filterKey) => {
  return Array.isArray(filterObject[filterKey]) ? (
    filterObject[filterKey].map(
      (val) =>
        val && (
          <Pills filterKey={filterKey} filterObject={val} onSelectFilter={onSelectFilter} />
        ),
    )
  ) : (
      <Pills
        filterObject={filterObject}
        onSelectFilter={onSelectFilter}
        filterKey={filterKey}
      />
  );
})

  return (
    <Container>
      {filterArray.map((filterKey) =>     
        Array.isArray(filterObject[filterKey]) ? (
          filterObject[filterKey].map(
            (val) =>
              val && (
                <Pills filterKey={filterKey} filterObject={val} onSelectFilter={onSelectFilter} />
              ),
          )
        ) : (
          <>
            <Pills
              filterObject={filterObject}
              onSelectFilter={onSelectFilter}
              filterKey={filterKey}
            />
          </>
        ),
      )}
    </Container>
  );
};

FilterPills.propTypes = {
  filterObject: PropTypes.object,
  onSelectFilter: PropTypes.func,
};

export default FilterPills;
