import React, { useState } from 'react';
import { Button, Form, Grid, Message, Segment } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { loginApi } from '../../Api';
import { setAxiosAuthorizationHeader } from '../../Utils/axios';
import Layout from '../../Components/Layout';

export const validateEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const regx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
  return regx.test(email);
};

const LoginPage = (props) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [error, setError] = useState(null);
  const history = useHistory();

  const handleLogin = () => {
    if (email && password && validateEmail(email)) {
      loginApi({ email, password })
        .then((response) => {
          if (response && response.data && response.data.token) {
            localStorage.setItem('token', response.data.token);
            localStorage.setItem(
              'privilege',
              response.data.roles[0] && response.data.roles[0].codename,
            );
            localStorage.setItem('loggedUser', response.data.email);
            localStorage.setItem('loggedUserId', response.data.id);
            setAxiosAuthorizationHeader();
            setError(null);
            history.push('/');
          }
        })
        .catch((err) => {
          if (window) window.Raven.captureException(err.response);
          if (err.response && err.response.status === 401) {
            setError((err.response.data && err.response.data.detail) || 'Something went wrong!');
          } else {
            setError('Something went wrong!');
          }
        });
    } else {
      setError('Wrong Email and Password combination.Please try again');
    }
  };

  return (
    <Layout location={props.location}>
      <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Form size="large">
            <Segment stacked>
              <Form.Input
                fluid
                icon="user"
                iconPosition="left"
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
              <Form.Input
                fluid
                icon="lock"
                iconPosition="left"
                placeholder="Password"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {error && <Message color="red">{error}</Message>}
              <Button color="teal" fluid size="large" onClick={handleLogin}>
                Login
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </Layout>
  );
};

LoginPage.propTypes = {
  location: PropTypes.object,
};

export default LoginPage;
