/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { useReducer, useEffect, useRef, useState } from 'react';
import { Modal, Input, Button, Icon, Image, Dimmer, Loader } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import produce from 'immer';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Cropper from 'react-easy-crop';
import Dropzone from 'react-dropzone';
import {
  storeCropImage,
  fetchInlineImageUrl,
  digestCreate,
  updateDigest,
  verifyVideo,
} from '../../Api';
import Toggle from '../../Components/Form/Toggle';
import { ToggleLabel, Label } from './PollComponent';

const ModalInput = styled.div`
  width: 100%;
  .input,
  input {
    width: 100%;
  }
`;

const ModalContent = styled(Modal.Content)``;

const ModalContentWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  height: ${({ height }) => height}px;
  position: relative;
`;

const HiddenImage = styled.img`
  width: 2px;
  height: 2px;
`;

const FieldErrorMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const ModalSaveButton = styled(Button)`
  background-color: #009fa2 !important;
  color: #fff !important;
`;

const DropzoneWrapper = styled.div`
  margin-bottom: 30px;
  border-radius: 4px;
  position: relative;
  border: none;
  /* height: 30px; */
  outline: none;
  &:hover {
    border: none;
    cursor: pointer;
  }
`;

const DropzoneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'space-between';
  position: relative;
  justify-content: center;
  margin-right: 35px;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${({ isYtVideo }) => (isYtVideo ? 'auto' : '220px')};
  overflow: hidden;
  width: 50%;
  img {
    width: 100%;
  }
`;

const CamIcon = styled(Icon)`
  color: #fff;
  position: absolute;
  bottom: 46%;
  left: 46%;
  width: 16px;
  height: 14px;
`;

const ImageContainer = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  height: calc(100% - 37px);
  margin-top: 20px;
`;

const LogoImg = styled.img`
  width: 100%;
  height: 100%;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  position: relative;
  margin: 30px 0;
  span {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
    font-size: 16px;
    font-weight: 800;
    color: #808080;
    background-color: #fff;
  }
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_IMAGE_URL':
        draft.imageUrl = action.value;
        break;
      case 'SET_ORIGINAL_IMAGE_URL':
        draft.originalImageUrl = action.value;
        break;
      case 'SET_CROP_IMAGE_ZOOM':
        draft.cropImageZoom = action.value;
        break;
      case 'SET_CROP_VALUE':
        draft.cropValue = action.value;
        break;
      case 'SET_CROP_IMAGES':
        draft.cropImages = action.value;
        break;
      case 'SET_IMAGE_CROP_AREA':
        draft.imageCropArea = action.value;
        break;
      case 'SET_STORE_CROP_IMAGE':
        draft.storeCropLoading = action.value;
        break;
      case 'CONFIRM_UPDATION':
        draft.confirmUpdate = !draft.confirmUpdate;
        break;
      case 'VALID_IMAGE':
        draft.validImage = action.value;
        break;
      case 'SET_HIGHER_RES_TOGGLE':
        draft.higherResolution = action.value;
    }
  });

const initialState = {
  imageUrl: '',
  originalImageUrl: '',
  cropImageZoom: 1,
  cropValue: { x: 0, y: 0 },
  cropImages: '',
  imageCropArea: null,
  storeCropLoading: false,
  confirmUpdate: false,
  validImage: '',
  higherResolution: false,
};

const UploadImage = ({
  currentDigest,
  open,
  modalInputRef,
  onClose,
  onSave,
  localImageUrl,
  updateValue,
  updateConfirm,
  code,
  categoryAdd,
  uploadImageType,
  setAspectRatio,
  aspectRatio,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const hiddenImageRef = useRef(null);
  const history = useHistory();
  const [loader, setLoader] = useState(false);
  const {
    imageUrl,
    originalImageUrl,
    cropImageZoom,
    cropValue,
    imageCropArea,
    storeCropLoading,
    confirmUpdate,
    validImage,
    higherResolution,
  } = state;

  const closeUpdateModal = () => {
    updateConfirm();
    dispatch({ type: 'CONFIRM_UPDATION' });
  };

  const verifyLongText = (value) => {
    if (code === '275') dispatch({ type: 'CONFIRM_UPDATION', value });
    else if (code === '300')
      dispatch({ type: 'VALID_IMAGE', value: 'Cannot upload video image for digest type 300' });
    else if (code === '') dispatch({ type: 'VALID_IMAGE', value: 'Invalid image url' });
    else if (updateValue) updateValue(value);
  };

  const checkImage = (url) => {
    const image = new Image();

    image.onload = () => {
      // image exists and is loaded
      const extension = url.split('.').pop();
      dispatch({ type: 'VALID_IMAGE', value: '' });
      if (!originalImageUrl && !categoryAdd && extension !== 'gif') {
        dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: imageUrl });
      } else if (originalImageUrl) {
        onSaveCropImage();
      } else if (extension !== 'png' && extension !== 'gif')
        dispatch({ type: 'VALID_IMAGE', value: 'Only pngs and gifs allowed' });
      else if (extension === 'gif') onSave({ type: uploadImageType || 'images', text: url });
      else onSave(url);
    };
    image.onerror = () => {
      // image did not load
      dispatch({ type: 'VALID_IMAGE', value: 'Invalid Image' });
    };

    image.src = url;
    setAspectRatio(false);
    // dispatch({ type: 'SET_IMAGE_URL', value: e.target.value })
  };

  // const urlPattern = /(http(s?):)\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;

  useEffect(() => {
    if (localImageUrl) {
      dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: localImageUrl });
    }
  }, [localImageUrl]);

  const onHandleAddImage = async (selectedImage) => {
    let imageSource = selectedImage;
    let imageUrl = '';
    if (typeof selectedImage === 'object') {
      const formData = new FormData();
      formData.append('higher_resolution', higherResolution);
      formData.append('content_image', selectedImage, selectedImage.name);
      try {
        const response = await fetchInlineImageUrl(formData);
        imageUrl = response.data.content_image_url;
        onSave({
          type: uploadImageType || 'images',
          text: `${response.data.content_image_url}`,
          status: true,
        });
        // imageSource = response.data && response.data.content_image_url;
      } catch (error) {
        imageSource = null;
        // ToastsStore.error('Something went wrong. Please try again');
      }
    }
    if (imageSource) {
      dispatch({ type: 'VALID_IMAGE', value: '' });
      dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: imageUrl });
    }
  };
  const hanldeUploadVideo = async (files) => {
    setLoader(true);
    if (currentDigest?.id) {
      let { created_by, collaborators, title: currentTitle, ...rest } = currentDigest;
      let collaboratorIds =
        collaborators.length > 0 ? collaborators.map((collaborator) => collaborator?.id) : [];
      let payload = {
        ...rest,
        digest_video_file: files.name,
        type: currentDigest?.type?.code,
        collaborators: collaboratorIds,
      };
      if (!currentTitle) {
        payload.title = files.name;
      }
      updateDigest({ data: payload, id: currentDigest.id })
        .then((response) => {
          const { s3_signed_data } = response.data;
          if (s3_signed_data && s3_signed_data.fields) {
            const { fields, object_name, url } = s3_signed_data;
            uploadDigest(fields, object_name, url, files.name, response.data.id, files);
          } else {
            console.log('Something Went Wrong...');
          }
        })
        .catch((error) => {
          setLoader(false);
          console.log(error);
        });
    } else {
      digestCreate({
        title: files.name,
        subtitle: 'video subtitle  ',
        content: 'video Content/description',
        image:
          'https://ichef.bbci.co.uk/news/624/cpsprodpb/6121/production/_111156842_gettyimages-1205322703.jpg',
        tag: [],
        categories: [],
        source: {},
        type: '295',
        default_preview_count: 0,
        editor_score_quality_new: 0,
        digest_video_file: files.name,
        reply_to: '',
      })
        .then((response) => {
          if (response.data.message) {
          } else {
            const { s3_signed_data } = response.data;
            if (s3_signed_data && s3_signed_data.fields) {
              const { fields, object_name, url } = s3_signed_data;
              uploadDigest(fields, object_name, url, files.name, response.data.id, files);
            } else {
              console.log('Something Went Wrong...');
            }
          }
        })
        .catch((error) => {
          // Toast.show(error.message, Toast.LONG);
          setLoader(false);
          console.log('digestCreate error---->', error);
        });
    }
  };

  const uploadDigest = async (fields, object_name, url, videoSource, id, files) => {
    return handleAwsApi({
      url,
      method: 'POST',
      data: { fields, object_name, videoSource },
      files,
    })
      .then((response) => {
        if (response) {
          verifyUploadVideo(id);
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
  };

  const verifyUploadVideo = async (digest_id) =>
  verifyVideo({ digest_id })
      .then((response) => {
        const { is_uploaded } = response.data.data;
        if (is_uploaded) {
          setLoader(false);
          console.log('Video uploaded successfully');
          history.push(`/digests/${digest_id}`);
          // Toast.show('Video uploaded successfully', Toast.LONG);
          window.location.reload();
        } else {
          console.log('Something went wrong');
        }
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      })
      .finally(() => {
        onClose();
      });

  const handleAwsApi = ({ url, method, data, files }) =>
    new Promise((resolve, reject) => {
      const { fields } = data;
      const formData = new FormData();

      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });
      formData.append('file', files, files.name);
      fetch(url, {
        method: 'POST',
        body: formData,
      })
        // .then((r) => r.json())
        .then((response) => {
          if (response !== undefined) {
            resolve(response);
          } else {
            setLoader(false);
            reject(response);
          }
        })
        .catch((error) => {
          setLoader(false);
          reject(error);
        });
    });
  // -----------END UPLOADS---------------
  const onSaveCropImage = () => {
    const data = {
      image_link: originalImageUrl,
      ...imageCropArea.croppedAreaPixels,
      higher_resolution: higherResolution,
    };
    dispatch({ type: 'SET_STORE_CROP_IMAGE', value: true });
    storeCropImage({ data })
      .then((response) => {
        // console.log('response onSaveCropImage', response);
        dispatch({ type: 'SET_STORE_CROP_IMAGE', value: false });
        setAspectRatio(false);
        onSave({ type: uploadImageType || 'images', text: `${response.data.cropped_image}` });
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        dispatch({ type: 'SET_STORE_CROP_IMAGE', value: false });
        setAspectRatio(false);
      });
  };

  if (loader === true) {
    return (
      <div>
        <Dimmer active inverted>
          <Loader active />
        </Dimmer>
      </div>
    );
  }
  return (
    <div>
      {categoryAdd && (
        <Modal size="tiny" open={categoryAdd} onClose={onClose}>
          <Modal.Header>Enter Category Icon(Only png or gif)</Modal.Header>
          <ModalContent height={100}>
            <ModalInput>
              <Input
                ref={modalInputRef}
                value={imageUrl}
                onChange={(e) => {
                  dispatch({ type: 'SET_IMAGE_URL', value: e.target.value });
                }}
              />
              <FieldErrorMessage>{validImage}</FieldErrorMessage>
            </ModalInput>
          </ModalContent>
          <Modal.Actions>
            <Button negative onClick={onClose} disabled={storeCropLoading}>
              Cancel
            </Button>
            <Button
              disabled={
                storeCropLoading ||
                !(originalImageUrl || imageUrl) ||
                (originalImageUrl && !imageCropArea)
              }
              loading={storeCropLoading}
              positive
              content="Save"
              onClick={() => {
                checkImage(imageUrl);
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
      {confirmUpdate && (
        <Modal size="tiny" open={confirmUpdate} onClose={closeUpdateModal}>
          <ModalContent>
            <div>This will cause the content to be reduced to 100 words.</div>
            <div>Are you sure you want to proceed?</div>
          </ModalContent>
          <Modal.Actions>
            <Button onClick={closeUpdateModal}> No </Button>
            <ModalSaveButton
              onClick={() => {
                if (imageUrl && (imageUrl.includes('https://www.youtube.com/watch?v') || imageUrl.includes('https://twitter.com/'))) {
                  onSave({ type: uploadImageType || 'images', text: imageUrl });
                } else if (!originalImageUrl) {
                  dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: imageUrl });
                } else if (originalImageUrl) {
                  onSaveCropImage();
                }
              }}
            >
              {' '}
              Yes{' '}
            </ModalSaveButton>
          </Modal.Actions>
        </Modal>
      )}
      {!categoryAdd && (
        <Modal size={originalImageUrl ? 'small' : 'tiny'} open={open} onClose={onClose}>
          <Modal.Header
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {originalImageUrl
              ? 'Crop uploaded image'
              : uploadImageType === 'thumbnailImage'
              ? 'Enter Image Url/Upload Image'
              : 'Enter Image/YouTube Video Url/Upload Image'}
          </Modal.Header>
          <ModalContent height={originalImageUrl ? 400 : 100}>
            <div style={{ marginBottom: 10 }}>
              <ToggleLabel>
                <Label>Higher Resolution</Label>
                <Toggle
                  on={higherResolution}
                  onClick={() => {
                    dispatch({ type: 'SET_HIGHER_RES_TOGGLE', value: !higherResolution });
                  }}
                />
              </ToggleLabel>
            </div>
            {!originalImageUrl && (
              <ModalInput>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    //setFile(acceptedFiles[0]);
                    setLoader(true);
                    hanldeUploadVideo(acceptedFiles[0]);
                    // dispatch({ type: 'VALID_IMAGE', value: '' });
                    // dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: acceptedFiles[0] });
                  }}
                  accept="video/mp4,video/quicktime"
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      {/* eslint-disable react/jsx-props-no-spreading */}
                      <DropzoneWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        <DropzoneContent>
                          <ImageWrapper>
                            <ImageContainer>
                              {originalImageUrl && (
                                <LogoImg
                                  src={
                                    typeof originalImageUrl === 'object'
                                      ? window.URL.createObjectURL(originalImageUrl)
                                      : originalImageUrl
                                  }
                                  alt="preview"
                                />
                              )}
                            </ImageContainer>
                          </ImageWrapper>
                          <CamIcon name="video camera" size="large" />
                        </DropzoneContent>
                      </DropzoneWrapper>
                    </>
                  )}
                </Dropzone>
                <Separator>
                  <span>OR</span>
                </Separator>
                <Input
                  ref={modalInputRef}
                  value={imageUrl}
                  placeholder="YouTube Video Url..."
                  onChange={(e) => {
                    if (e.target.value.includes('https://www.youtube.com/watch?v') || e.target.value.includes('https://twitter.com/')) {
                      verifyLongText('image');
                    }
                    // else checkImage(e.target.value);
                    dispatch({ type: 'SET_IMAGE_URL', value: e.target.value });
                  }}
                />
                <Separator>
                  <span>OR</span>
                </Separator>
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    // dispatch({ type: 'VALID_IMAGE', value: '' });
                    // dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: acceptedFiles[0] });
                    onHandleAddImage(acceptedFiles[0]);
                  }}
                  accept="image/jpeg, image/png, image/avif, image/webp ,image/gif"
                >
                  {({ getRootProps, getInputProps }) => (
                    <>
                      {/* eslint-disable react/jsx-props-no-spreading */}
                      <DropzoneWrapper {...getRootProps()}>
                        <input {...getInputProps()} />
                        <DropzoneContent>
                          <ImageWrapper>
                            <ImageContainer>
                              {originalImageUrl && (
                                <LogoImg
                                  src={
                                    typeof originalImageUrl === 'object'
                                      ? window.URL.createObjectURL(originalImageUrl)
                                      : originalImageUrl
                                  }
                                  alt="preview"
                                />
                              )}
                            </ImageContainer>
                          </ImageWrapper>
                          <CamIcon name="camera" size="large" />
                        </DropzoneContent>
                      </DropzoneWrapper>
                    </>
                  )}
                </Dropzone>
                <FieldErrorMessage>{validImage}</FieldErrorMessage>
              </ModalInput>
            )}
            {originalImageUrl && (
              <ModalContentWrapper height={originalImageUrl ? 400 : 100}>
                <HiddenImage ref={hiddenImageRef} src={originalImageUrl} />
                <Cropper
                  image={originalImageUrl}
                  crop={cropValue}
                  zoom={cropImageZoom}
                  aspect={aspectRatio ? 9 / 16 : 3 / 2}
                  minZoom={1}
                  onCropChange={(crop) => {
                    dispatch({ type: 'SET_CROP_VALUE', value: crop });
                  }}
                  onCropComplete={async (croppedArea, croppedAreaPixels) => {
                    dispatch({
                      type: 'SET_IMAGE_CROP_AREA',
                      value: {
                        croppedArea,
                        croppedAreaPixels,
                      },
                    });
                  }}
                  onZoomChange={(zoom) => dispatch({ type: 'SET_CROP_IMAGE_ZOOM', value: zoom })}
                />
              </ModalContentWrapper>
            )}
          </ModalContent>
          <Modal.Actions>
            <Button
              negative
              onClick={() => {
                setAspectRatio(false);
                onClose();
              }}
              disabled={storeCropLoading}
            >
              Cancel
            </Button>
            <Button
              disabled={
                storeCropLoading ||
                !(originalImageUrl || imageUrl) ||
                (originalImageUrl && !imageCropArea) ||
                ((imageUrl.includes('https://www.youtube.com/watch?v') || imageUrl.includes('https://twitter.com/')) && validImage)
              }
              loading={storeCropLoading}
              positive
              content="Save"
              onClick={async () => {
                if (imageUrl && (imageUrl.includes('https://www.youtube.com/watch?v') || imageUrl.includes('https://twitter.com/'))) {
                  if (uploadImageType === 'thumbnailImage') {
                    dispatch({ type: 'VALID_IMAGE', value: 'Only pngs and gifs allowed' });
                    setAspectRatio(false);
                  } else {
                    onSave({ type: uploadImageType || 'images', text: imageUrl });
                    setAspectRatio(false);
                  }
                } else if (!originalImageUrl) {
                  checkImage(imageUrl);
                } else if (originalImageUrl) {
                  onSaveCropImage();
                }
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
    </div>
  );
};

UploadImage.propTypes = {
  open: PropTypes.bool,
  modalInputRef: PropTypes.object,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  localImageUrl: PropTypes.string,
  uploadImageType: PropTypes.string,
  updateValue: PropTypes.func,
  updateConfirm: PropTypes.func,
  code: PropTypes.string,
  categoryAdd: PropTypes.bool,
  aspectRatio: PropTypes.bool,
  setAspectRatio: PropTypes.func,
};

export default UploadImage;
