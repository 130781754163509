import React from 'react';
import styled from 'styled-components';
import { Input, Button } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FieldErrorMessage, ToggleWrapper } from './PollComponent';

const TwitterLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
  padding-left: 15px;
`;

const FieldWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`;

const CuratedTweetForm = ({ data, onChange, removeLink, addLink, error }) => {
  return (
    <>
      <FieldWrapper>
        <ToggleWrapper> 
            <TwitterLinksWrapper>
              {(data?.length?data:[""]).map((link,index)=>{
                 return <Input
                  onChange={(e) => {
                    onChange(index,e.target.value);
                  }}
                  key={index}
                  action={
                    (!data||data?.length<=index+1)&&index<4 ? (
                      <Button
                        icon="plus"
                        style={{ margin: '0 !important' }}
                        onClick={() => addLink()}
                      />
                    )
                    :
                    <Button
                    icon="minus"
                    style={{ margin: '0 !important' }}
                    onClick={() => removeLink(index)}
                  />
                  }
                  value={
                    link || ''
                  }
                  placeholder="Enter Curated Twitter Link"
                />
              })}
            </TwitterLinksWrapper>
        </ToggleWrapper>
      </FieldWrapper>
     {error&& <FieldErrorMessage>{error}</FieldErrorMessage>}
    </>
  );
};

CuratedTweetForm.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
  removeLink: PropTypes.func,
  addChoices: PropTypes.func,
  error: PropTypes.string,
};

export default CuratedTweetForm;
