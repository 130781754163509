import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { fetchTagsGroupList } from 'Api';

const Container = styled.div`
  margin-top: 10px;
`;

const TagsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const TagItemWrapper = styled.div`
  padding: 5px 10px;
  border-radius: 12px;
  margin: 5px;
  background-color: ${({ active }) => (active ? '#f22726' : '#3CB371')};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #fff;
`;

const HeadlineTagsPills = ({selectedTagsIds,handleTagAdd}) => {
    const [tagsData, setTagsData] = useState([]);
    const [pillsLoader, setPillsLoader] = useState(true);

    const fetchTagsData = () => {
        setPillsLoader(true);
        fetchTagsGroupList()
            .then((response) => {
                setTagsData(response.data.results);
                setPillsLoader(false);
            })
            .catch((error) => {
                setPillsLoader(false);
            });
    };

    useEffect(() => {
        fetchTagsData();
    }, []);
   
    return (
        <Container>
            {!pillsLoader && tagsData.length > 0 && (
                <TagsListContainer>
                    {tagsData
                        .filter((groups) =>
                            groups.tags &&
                            groups.tags.results &&
                            groups.tags.results.length &&
                            groups.tag_group.title === "Major Topics"
                        )
                        .flatMap((groups) => groups.tags.results) 
                        .map((item) => (
                            <TagItemWrapper
                                active={selectedTagsIds.includes(item.id)}
                                onClick={()=>handleTagAdd(item.id)}
                            >
                                {item.title}
                            </TagItemWrapper>
                        ))}
                </TagsListContainer>
            )}
        </Container>
    );
};

export default HeadlineTagsPills;
