import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Toggle from '../../Components/Form/Toggle';

const VisibleFieldWrapper = styled.div`
  margin-bottom: 10px;
  min-width: 270px;

  span {
    margin-left: 10px;
  }
`;

const VisibleFieldsWrapper = styled.div`
  border-left: 2px solid #d4d4d5 !important;
`;

const ToggleLabel = styled.div`
  display: flex;
  align-items: center;
`;

const Label = styled.span`
  font-size: 12px;
  line-height: 12px;
  color: #a9a9a9;
  display: block;
  font-weight: 800;
`;

const VisibleFieldsSubWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const VisibleFieldsComp = ({ data, onChange }) => (
  <VisibleFieldsWrapper>
    <VisibleFieldsSubWrapper>
      <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.show_share}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                show_share: !data.visibleFields.show_share,
              };
              onChange({ type: 'visibleFields', text: value });
              // dispatch({ type: 'SET_DATA', data: ({ ...data, visibleFields: value }) });
            }}
          />
          <Label>Show Share</Label>
        </ToggleLabel>
      </VisibleFieldWrapper>
      <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.show_bookmark}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                show_bookmark: !data.visibleFields.show_bookmark,
              };
              onChange({ type: 'visibleFields', text: value });
              // dispatch({ type: 'SET_DATA', data: ({ ...data, visibleFields: value }) });
            }}
          />
          <Label>Show Bookmark</Label>
        </ToggleLabel>
      </VisibleFieldWrapper>
    </VisibleFieldsSubWrapper>
    <VisibleFieldsSubWrapper>
      <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.show_category}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                show_category: !data.visibleFields.show_category,
              };
              onChange({ type: 'visibleFields', text: value });
              // dispatch({ type: 'SET_DATA', data: ({ ...data, visibleFields: value }) });
            }}
          />
          <Label>Show Category</Label>
        </ToggleLabel>
      </VisibleFieldWrapper>
      <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.show_feedback}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                show_feedback: !data.visibleFields.show_feedback,
              };
              onChange({ type: 'visibleFields', text: value });
              // dispatch({ type: 'SET_DATA', data: ({ ...data, visibleFields: value }) });
            }}
          />
          <Label>Show Feedback</Label>
        </ToggleLabel>
      </VisibleFieldWrapper>
    </VisibleFieldsSubWrapper>
    <VisibleFieldsSubWrapper>
      <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.show_feedback_question}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                show_feedback_question: !data.visibleFields.show_feedback_question,
              };
              onChange({ type: 'visibleFields', text: value });
              // dispatch({ type: 'SET_DATA', data: ({ ...data, visibleFields: value }) });
            }}
          />
          <Label>Show Feedback Question</Label>
        </ToggleLabel>
      </VisibleFieldWrapper>
      <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.first_published_at}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                first_published_at: !data.visibleFields.first_published_at,
              };
              onChange({ type: 'visibleFields', text: value });
              // dispatch({ type: 'SET_DATA', data: ({ ...data, visibleFields: value }) });
            }}
          />
          <Label>Show Date</Label>
        </ToggleLabel>
      </VisibleFieldWrapper>
    </VisibleFieldsSubWrapper>
    <VisibleFieldsSubWrapper>
      <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.format_human}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                format_human: !data.visibleFields.format_human,
              };
              onChange({ type: 'visibleFields', text: value });
              // dispatch({ type: 'SET_DATA', data: ({ ...data, visibleFields: value }) });
            }}
          />
          <Label>Show Format Human</Label>
        </ToggleLabel>
      </VisibleFieldWrapper>
      {/* <VisibleFieldWrapper>
        <ToggleLabel>
          <Toggle
            on={data.visibleFields.is_thumbnail_large}
            onClick={() => {
              const value = {
                ...data.visibleFields,
                is_thumbnail_large: !data.visibleFields.is_thumbnail_large,
              };

              console.log('Thumbnail value', value,"data",data)
              onChange({ type: 'visibleFields', text: value });
            }}
          />
          <Label>Show Thumbnail Large</Label>
        </ToggleLabel>
      </VisibleFieldWrapper> */}
    </VisibleFieldsSubWrapper>
  </VisibleFieldsWrapper>
);

VisibleFieldsComp.propTypes = {
  data: PropTypes.object,
  onChange: PropTypes.func,
};

export default VisibleFieldsComp;
