import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TableView from '../../Components/TableView';

const List = styled.li`
  list-style: none;
  text-align: left;
  padding: 10px 0;
  border-bottom: 1px solid #d8d8d8 !important;
`;

const NotificationTable = ({ data }) => {
  const columns = [
    {
      title: 'Notification Sent Count',
      dataIndex: 'include_all_channel_count',
      key: 'include_all_channel_count',
      render: (value) => value || '',
      align: 'center',
    },
    {
      title: 'Notifications Sent',
      dataIndex: 'all_channel_slugs',
      key: 'all_channel_slugs',
      render: (value) => (
        <ul>
          {value.map((val) => (
            <List>{val}</List>
          ))}
        </ul>
      ),
      align: 'center',
    },
    {
      title: 'Notification Not Sent Count',
      dataIndex: 'not_include_all_channel_count',
      key: 'not_include_all_channel_count',
      render: (value) => value || '',
      align: 'center',
    },
    {
      title: 'Notifications Not Sent',
      dataIndex: 'not_include_all_channel_slugs',
      key: 'not_include_all_channel_slugs',
      render: (value) => (
        <ul>
          {value.map((val) => (
            <List>{val}</List>
          ))}
        </ul>
      ),
      align: 'center',
    },
  ];
  return (
    <>
      <TableView tableColumns={columns} data={data} vAlign="baseline" />
    </>
  );
};

NotificationTable.propTypes = {
  data: PropTypes.object,
};

export default NotificationTable;
