import React  from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { matchPath } from 'react-router';
// import EALogo from '../../images/ea-logo.png';

const activeMenuColor = 'rgb(233, 30, 99)';

const MenuMain = styled.div`
  position: fixed;
  top: 75px;
  left: 70px;
  scroll-height: ${({ height }) => height };
  background-color: #fff;
  overflow: hidden !important;
  transition: all 300ms ease 0s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.15) 0px 3px 6px;
`;

const Menu = styled.div`
  width: 200px !important;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;

const MenuItem = styled.div`
  width: 100%;
  white-space: nowrap;
  display: flex;
  cursor: pointer;
  color: rgb(103, 128, 152);
  font-weight: 600;
  padding: 15px 15px 15px 0;
  font-size: 16px;
  line-height: 18px;
  color: ${({ active }) => active && activeMenuColor};
  border-left: 5px solid ${({ active }) => (active ? activeMenuColor : 'transparent')};

  &:hover {
    color: ${({ active }) => (active ? activeMenuColor : 'rgb(103, 128, 152)')};
    border-left: 5px solid ${activeMenuColor};
  }
`;

const LinkItemText = styled.span`
  margin-left: 10px;
`;

const ActionLink = styled(({ active, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link {...rest} />
))``;

const matchActiveMenu = (path, testPath) => {
  const matchData = testPath.filter(url =>
    matchPath(path, {
      path: url,
      exact: true,
      strict: false,
    }),
  );

  return matchData.length > 0;
};

export const FeedsMenu = props => {
  return (
    <MenuMain>
      <Menu vertical size="large">
      <MenuItem
          title="Feeds"
          as={ActionLink}
          to={'/feeds'}
          active={matchActiveMenu(props.location.pathname, ['/feeds'])}
        >
          <LinkItemText>Feeds</LinkItemText>
        </MenuItem>
      <MenuItem
          title="All Boards"
          as={ActionLink}
          to={'/boards'}
          active={matchActiveMenu(props.location.pathname, ['/boards', '/board-details/:categoryId', '/board-details/:categoryId/sources/', '/feed/:id/details'])}
        >
          <LinkItemText>All Boards</LinkItemText>
        </MenuItem>
        <MenuItem
          title="Create New Board"
          as={ActionLink}
          to="/board"
          active={matchActiveMenu(props.location.pathname, ['/board'])}
        >
          <LinkItemText>Create New Board</LinkItemText>
        </MenuItem>
        <MenuItem
          title="Add Feed Sources"
          as={ActionLink}
          to="/feed"
          active={matchActiveMenu(props.location.pathname, ['/feed'])}
        >
          <LinkItemText>Add Feed Sources</LinkItemText>
        </MenuItem>
      </Menu>
    </MenuMain>
)};

export default FeedsMenu;
