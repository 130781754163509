export default {
  close: {
    elements: [
      {
        type: 'path',
        value:
          'M12.946 11l8.65-8.651A1.375 1.375 0 1019.652.403l-8.65 8.652L2.348.403A1.375 1.375 0 10.403 2.35L9.055 11 .403 19.652a1.375 1.375 0 101.946 1.945L11 12.945l8.651 8.652a1.372 1.372 0 001.946 0 1.375 1.375 0 000-1.946l-8.651-8.65z',
      },
    ],
    height: 24,
    width: 24,
  },
  fillClose: {
    elements: [
      {
        type: 'path',
        value:
          'M44.373 7.603c-10.137-10.137-26.632-10.138-36.77 0-10.138 10.138-10.137 26.632 0 36.77s26.632 10.138 36.77 0c10.137-10.138 10.137-26.633 0-36.77zm-8.132 28.638a2 2 0 01-2.828 0l-7.425-7.425-7.778 7.778a2 2 0 11-2.828-2.828l7.778-7.778-7.425-7.425a2 2 0 112.828-2.828l7.425 7.425 7.071-7.071a2 2 0 112.828 2.828l-7.071 7.071 7.425 7.425a2 2 0 010 2.828z',
      },
    ],
    height: 51.976,
    width: 51.976,
  },
  filter: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M0 0v1.833h17V0H0zm6.611 11h3.778V9.167H6.61V11zm7.556-4.583H2.833V4.583h11.334v1.834z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
    ],
    height: 11,
    width: 17,
  },
  search: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M11.347 10.677h.745l4.708 4.717-1.406 1.406-4.717-4.708v-.745l-.254-.264a6.105 6.105 0 01-3.99 1.481 6.132 6.132 0 116.131-6.132 6.105 6.105 0 01-1.48 3.99l.263.255zm-9.16-4.245a4.24 4.24 0 004.245 4.245 4.24 4.24 0 004.245-4.245 4.24 4.24 0 00-4.245-4.245 4.24 4.24 0 00-4.245 4.245z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
    ],
    height: 17,
    width: 17,
  },
  fillCircle: {
    elements: [
      {
        type: 'path',
        value:
          'M409.133 109.203c-19.608-33.592-46.205-60.189-79.798-79.796C295.736 9.801 259.058 0 219.273 0c-39.781 0-76.47 9.801-110.063 29.407-33.595 19.604-60.192 46.201-79.8 79.796C9.801 142.8 0 179.489 0 219.267c0 39.78 9.804 76.463 29.407 110.062 19.607 33.592 46.204 60.189 79.799 79.798 33.597 19.605 70.283 29.407 110.063 29.407s76.47-9.802 110.065-29.407c33.593-19.602 60.189-46.206 79.795-79.798 19.603-33.596 29.403-70.284 29.403-110.062.001-39.782-9.8-76.472-29.399-110.064z',
      },
    ],
    height: 438.533,
    width: 438.533,
  },
  downArrow: { elements: [{ type: 'path', value: 'M5 8l4 4 4-4z' }], height: 18, width: 18 },
  downUp: { elements: [{ type: 'path', value: 'M9 6l-4 4h8z' }], height: 18, width: 18 },
  lock: {
    elements: [
      {
        type: 'path',
        value:
          'M357.45 190.721c-5.331-5.33-11.8-7.993-19.417-7.993h-9.131v-54.821c0-35.022-12.559-65.093-37.685-90.218C266.093 12.563 236.025 0 200.998 0c-35.026 0-65.1 12.563-90.222 37.688-25.126 25.126-37.685 55.196-37.685 90.219v54.821h-9.135c-7.611 0-14.084 2.663-19.414 7.993-5.33 5.326-7.994 11.799-7.994 19.417V374.59c0 7.611 2.665 14.086 7.994 19.417 5.33 5.325 11.803 7.991 19.414 7.991H338.04c7.617 0 14.085-2.663 19.417-7.991 5.325-5.331 7.994-11.806 7.994-19.417V210.135c.004-7.612-2.669-14.084-8.001-19.414zm-83.363-7.993H127.909v-54.821c0-20.175 7.139-37.402 21.414-51.675 14.277-14.275 31.501-21.411 51.678-21.411 20.179 0 37.399 7.135 51.677 21.411 14.271 14.272 21.409 31.5 21.409 51.675v54.821z',
      },
    ],
    height: 401.998,
    width: 401.998,
  },
  signOut: {
    elements: [
      {
        type: 'path',
        value:
          'M182.725 379.151c-.572-1.522-.769-2.816-.575-3.863.193-1.04-.472-1.902-1.997-2.566-1.525-.664-2.286-1.191-2.286-1.567 0-.38-1.093-.667-3.284-.855-2.19-.191-3.283-.288-3.283-.288H82.224c-12.562 0-23.317-4.469-32.264-13.421-8.945-8.946-13.417-19.698-13.417-32.258V123.335c0-12.562 4.471-23.313 13.417-32.259 8.947-8.947 19.702-13.422 32.264-13.422h91.361c2.475 0 4.421-.614 5.852-1.854 1.425-1.237 2.375-3.094 2.853-5.568.476-2.474.763-4.708.859-6.707.094-1.997.048-4.521-.144-7.566-.189-3.044-.284-4.947-.284-5.712 0-2.474-.905-4.611-2.712-6.423-1.809-1.804-3.949-2.709-6.423-2.709H82.224c-22.648 0-42.016 8.042-58.101 24.125C8.042 81.323 0 100.688 0 123.338v200.994c0 22.648 8.042 42.018 24.123 58.095 16.085 16.091 35.453 24.133 58.101 24.133h91.365c2.475 0 4.422-.622 5.852-1.854 1.425-1.239 2.375-3.094 2.853-5.571.476-2.471.763-4.716.859-6.707.094-1.999.048-4.518-.144-7.563-.191-3.048-.284-4.95-.284-5.714z',
      },
      {
        type: 'path',
        value:
          'M442.249 210.989L286.935 55.67c-3.614-3.612-7.898-5.424-12.847-5.424s-9.233 1.812-12.851 5.424c-3.617 3.617-5.424 7.904-5.424 12.85v82.226H127.907c-4.952 0-9.233 1.812-12.85 5.424-3.617 3.617-5.424 7.901-5.424 12.85v109.636c0 4.948 1.807 9.232 5.424 12.847 3.621 3.61 7.901 5.427 12.85 5.427h127.907v82.225c0 4.945 1.807 9.233 5.424 12.847 3.617 3.617 7.901 5.428 12.851 5.428 4.948 0 9.232-1.811 12.847-5.428L442.249 236.69c3.617-3.62 5.425-7.898 5.425-12.848 0-4.948-1.808-9.236-5.425-12.853z',
      },
    ],
    height: 447.674,
    width: 447.674,
  },
  location: {
    elements: [
      {
        type: 'path',
        value:
          'M256 0C153.755 0 70.573 83.182 70.573 185.426c0 126.888 165.939 313.167 173.004 321.035 6.636 7.391 18.222 7.378 24.846 0 7.065-7.868 173.004-194.147 173.004-321.035C441.425 83.182 358.244 0 256 0zm0 278.719c-51.442 0-93.292-41.851-93.292-93.293S204.559 92.134 256 92.134s93.291 41.851 93.291 93.293-41.85 93.292-93.291 93.292z',
      },
    ],
    height: 512,
    width: 512,
  },
  global: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M16.605 15.797A9.46 9.46 0 0019 9.5a9.462 9.462 0 00-2.579-6.497V3L16.418 3l-.002-.002A9.473 9.473 0 009.829.008L9.644.003a4.194 4.194 0 00-.288 0l-.184.005a9.473 9.473 0 00-6.593 2.994v.001A9.46 9.46 0 000 9.5a9.46 9.46 0 002.395 6.297l.002.004c0 .002.001.004.003.005l.01.01a9.478 9.478 0 006.76 3.175l.001.001.185.005h.025a3.917 3.917 0 00.263 0l.184-.005a9.478 9.478 0 006.764-3.18.043.043 0 00.005-.003.025.025 0 00.003-.003l.001-.002.001-.002v-.001l.002-.002v-.002zM.663 9.827h3.932c.037 1.39.309 2.739.792 4.003a12.08 12.08 0 00-2.658 1.353A8.804 8.804 0 01.663 9.828zm4.833-4.944A12.05 12.05 0 012.908 3.61 8.806 8.806 0 00.663 9.172h3.932c.04-1.493.347-2.944.901-4.29zm12.84 4.29h-3.931a12.097 12.097 0 00-.901-4.29 12.049 12.049 0 002.588-1.272 8.806 8.806 0 012.245 5.561zM6.398 4.48c.899.253 1.828.394 2.775.42V.867a11.462 11.462 0 00-2.775 3.615zm2.775 1.076v3.615H5.251c.04-1.424.339-2.805.873-4.086.985.285 2.007.443 3.048.47zm3.704-.471c-.985.285-2.007.443-3.048.47v3.616h3.922a11.441 11.441 0 00-.874-4.086zm-3.048-.184V.866a11.461 11.461 0 012.775 3.615c-.899.253-1.828.394-2.775.42zm.825-4.17a12.097 12.097 0 012.584 3.55 11.395 11.395 0 002.39-1.152A8.82 8.82 0 0010.653.733zm-4.89 3.55a11.384 11.384 0 01-2.39-1.152A8.818 8.818 0 018.347.733a12.093 12.093 0 00-2.584 3.55zm3.41 5.546H5.25a11.44 11.44 0 00.757 3.793 12.083 12.083 0 013.164-.505V9.828zm0 3.943v4.363a11.456 11.456 0 01-2.91-3.904 11.42 11.42 0 012.91-.459zm.655 0v4.363a11.454 11.454 0 002.91-3.904 11.42 11.42 0 00-2.91-.459zm0-.655V9.828h3.921a11.436 11.436 0 01-.757 3.793 12.067 12.067 0 00-3.164-.505zm8.509-3.288h-3.932a12.097 12.097 0 01-.791 4.002c.931.337 1.822.789 2.657 1.353a8.803 8.803 0 002.066-5.355zM3.175 15.674c.775-.515 1.6-.93 2.462-1.239a12.087 12.087 0 002.71 3.832 8.826 8.826 0 01-5.172-2.593zm10.188-1.239a12.081 12.081 0 01-2.71 3.832 8.826 8.826 0 005.172-2.593c-.775-.515-1.6-.93-2.462-1.239z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
    ],
    height: 19,
    width: 19,
  },
  clear: {
    elements: [
      {
        type: 'path',
        value:
          'M28.228 23.986L47.092 5.122a2.998 2.998 0 000-4.242 2.998 2.998 0 00-4.242 0L23.986 19.744 5.121.88a2.998 2.998 0 00-4.242 0 2.998 2.998 0 000 4.242l18.865 18.864L.879 42.85a2.998 2.998 0 104.242 4.241l18.865-18.864L42.85 47.091c.586.586 1.354.879 2.121.879s1.535-.293 2.121-.879a2.998 2.998 0 000-4.242L28.228 23.986z',
      },
    ],
    height: 47.971,
    width: 47.971,
  },
  list: {
    elements: [
      {
        type: 'path',
        value:
          'M118.771 200.999H27.406c-7.611 0-14.084 2.664-19.414 7.994C2.663 214.32 0 220.791 0 228.407v54.823c0 7.61 2.663 14.078 7.992 19.406 5.33 5.329 11.803 7.994 19.414 7.994h91.365c7.611 0 14.084-2.665 19.414-7.994 5.33-5.328 7.992-11.796 7.992-19.406v-54.823c0-7.616-2.662-14.087-7.992-19.414s-11.803-7.994-19.414-7.994zM118.771 54.814H27.406c-7.611 0-14.084 2.663-19.414 7.993C2.663 68.137 0 74.61 0 82.221v54.821c0 7.616 2.663 14.091 7.992 19.417 5.33 5.327 11.803 7.994 19.414 7.994h91.365c7.611 0 14.084-2.667 19.414-7.994s7.992-11.798 7.992-19.414v-54.82c0-7.611-2.662-14.084-7.992-19.417-5.33-5.328-11.803-7.994-19.414-7.994zM118.771 347.177H27.406c-7.611 0-14.084 2.662-19.414 7.994C2.663 360.502 0 366.974 0 374.585v54.826c0 7.61 2.663 14.086 7.992 19.41 5.33 5.332 11.803 7.991 19.414 7.991h91.365c7.611 0 14.084-2.663 19.414-7.991 5.33-5.324 7.992-11.8 7.992-19.41v-54.826c0-7.611-2.662-14.083-7.992-19.411s-11.803-7.997-19.414-7.997zM484.215 200.999H210.131c-7.614 0-14.084 2.664-19.414 7.994s-7.992 11.798-7.992 19.414v54.823c0 7.61 2.662 14.078 7.992 19.406 5.327 5.329 11.8 7.994 19.414 7.994h274.091c7.61 0 14.085-2.665 19.41-7.994 5.332-5.328 7.994-11.796 7.994-19.406v-54.823c0-7.616-2.662-14.087-7.997-19.414-5.329-5.33-11.796-7.994-19.414-7.994zM484.215 347.177H210.131c-7.614 0-14.084 2.662-19.414 7.994-5.33 5.331-7.992 11.8-7.992 19.41v54.823c0 7.611 2.662 14.089 7.992 19.417 5.327 5.328 11.8 7.987 19.414 7.987h274.091c7.61 0 14.085-2.662 19.41-7.987 5.332-5.331 7.994-11.806 7.994-19.417v-54.823c0-7.61-2.662-14.085-7.997-19.41-5.329-5.325-11.796-7.994-19.414-7.994zM503.629 62.811c-5.329-5.327-11.797-7.993-19.414-7.993H210.131c-7.614 0-14.084 2.663-19.414 7.993s-7.992 11.803-7.992 19.414v54.821c0 7.616 2.662 14.083 7.992 19.414 5.327 5.327 11.8 7.994 19.414 7.994h274.091c7.61 0 14.078-2.667 19.41-7.994s7.994-11.798 7.994-19.414V82.225c0-7.612-2.662-14.084-7.997-19.414z',
      },
    ],
    height: 511.627,
    width: 511.627,
  },
  split: {
    elements: [
      {
        type: 'path',
        value:
          'M431.379.222h-394.7C16.456.222 0 16.671 0 36.895v394.268c0 20.221 16.456 36.677 36.679 36.677h394.7c20.228 0 36.683-16.456 36.683-36.677V36.895c0-20.23-16.456-36.673-36.683-36.673zm-24.86 41.744c8.689 0 15.723 7.04 15.723 15.72 0 8.683-7.033 15.717-15.723 15.717-8.688 0-15.723-7.04-15.723-15.717 0-8.68 7.034-15.72 15.723-15.72zm-56.33 0c8.688 0 15.723 7.04 15.723 15.72 0 8.683-7.034 15.717-15.723 15.717-8.684 0-15.711-7.04-15.711-15.717.001-8.68 7.028-15.72 15.711-15.72zm-308.276 70.46h184.055v313.495H41.913V112.426zm384.235 313.495H242.104V112.426h184.044v313.495z',
      },
    ],
    height: 468.062,
    width: 468.062,
  },
  oval: {
    elements: [
      {
        type: 'path',
        value:
          'M30 0C13.458 0 0 13.458 0 30s13.458 30 30 30 30-13.458 30-30S46.542 0 30 0zm0 58C14.561 58 2 45.439 2 30S14.561 2 30 2s28 12.561 28 28-12.561 28-28 28z',
      },
    ],
    width: 60,
    height: 60,
  },
  ovalFill: {
    elements: [
      {
        type: 'path',
        value: 'M30 0C13.458 0 0 13.458 0 30s13.458 30 30 30 30-13.458 30-30S46.542 0 30 0z',
      },
    ],
    width: 60,
    height: 60,
  },
  plus: {
    elements: [
      {
        type: 'path',
        value:
          'M306 0C136.992 0 0 136.992 0 306s136.992 306 306 306 306-137.012 306-306S475.008 0 306 0zm0 573.75C158.125 573.75 38.25 453.875 38.25 306S158.125 38.25 306 38.25 573.75 158.125 573.75 306 453.875 573.75 306 573.75zm114.75-286.875h-95.625V191.25c0-10.557-8.568-19.125-19.125-19.125s-19.125 8.568-19.125 19.125v95.625H191.25c-10.557 0-19.125 8.568-19.125 19.125s8.568 19.125 19.125 19.125h95.625v95.625c0 10.557 8.568 19.125 19.125 19.125s19.125-8.568 19.125-19.125v-95.625h95.625c10.557 0 19.125-8.568 19.125-19.125s-8.568-19.125-19.125-19.125z',
      },
    ],
    height: 612,
    width: 612,
  },
  circleCheck: {
    elements: [
      {
        type: 'path',
        value:
          'M437.019 74.98C388.667 26.629 324.38 0 256 0 187.619 0 123.332 26.629 74.98 74.98 26.629 123.332 0 187.62 0 256s26.629 132.667 74.98 181.019C123.332 485.371 187.62 512 256 512s132.667-26.629 181.019-74.98C485.371 388.667 512 324.38 512 256s-26.629-132.667-74.981-181.02zm-58.713 120.093L235.241 338.139a14.953 14.953 0 01-10.606 4.393 14.953 14.953 0 01-10.607-4.393l-80.334-80.333c-5.858-5.857-5.858-15.354 0-21.213 5.857-5.858 15.355-5.858 21.213 0l69.728 69.727 132.458-132.46c5.857-5.858 15.355-5.858 21.213 0 5.858 5.858 5.858 15.355 0 21.213z',
      },
    ],
    width: 510,
    height: 510,
  },
  reload: {
    elements: [
      {
        type: 'path',
        value:
          'M112.156 97.111c72.3-65.4 180.5-66.4 253.8-6.7l-58.1 2.2c-7.5.3-13.3 6.5-13 14 .3 7.3 6.3 13 13.5 13h.5l89.2-3.3c7.3-.3 13-6.2 13-13.5v-1-.6l-3.3-88.2c-.3-7.5-6.6-13.3-14-13-7.5.3-13.3 6.5-13 14l2.1 55.3c-36.3-29.7-81-46.9-128.8-49.3-59.2-3-116.1 17.3-160 57.1-60.4 54.7-86 137.9-66.8 217.1 1.5 6.2 7 10.3 13.1 10.3 1.1 0 2.1-.1 3.2-.4 7.2-1.8 11.7-9.1 9.9-16.3-16.8-69.6 5.6-142.7 58.7-190.7zM462.456 195.511c-1.8-7.2-9.1-11.7-16.3-9.9-7.2 1.8-11.7 9.1-9.9 16.3 16.9 69.6-5.6 142.7-58.7 190.7-37.3 33.7-84.1 50.3-130.7 50.3-44.5 0-88.9-15.1-124.7-44.9l58.8-5.3c7.4-.7 12.9-7.2 12.2-14.7s-7.2-12.9-14.7-12.2l-88.9 8c-7.4.7-12.9 7.2-12.2 14.7l8 88.9c.6 7 6.5 12.3 13.4 12.3.4 0 .8 0 1.2-.1 7.4-.7 12.9-7.2 12.2-14.7l-4.8-54.1c36.3 29.4 80.8 46.5 128.3 48.9 3.8.2 7.6.3 11.3.3 55.1 0 107.5-20.2 148.7-57.4 60.4-54.7 86-137.8 66.8-217.1z',
      },
    ],
    width: 510,
    height: 510,
  },
  upload: {
    elements: [
      {
        type: 'path',
        value:
          'M15.944 10.545V29h5.111V10.545l5.111 5.273L30 11.864 18.5 0 7 11.864l3.833 3.954 5.111-5.273zM0 31h36v6H0v-6z',
      },
    ],
    width: 36,
    height: 37,
  },
  leftArrow: {
    elements: [
      {
        type: 'path',
        value:
          'M.328 7.688L7.485.336a1.098 1.098 0 011.582 0 1.17 1.17 0 010 1.625L3.818 7.35h14.064c.617 0 1.118.515 1.118 1.149s-.5 1.149-1.118 1.149H3.818l5.249 5.39a1.17 1.17 0 010 1.625 1.1 1.1 0 01-1.582 0L.328 9.312a1.17 1.17 0 010-1.624z',
      },
    ],
    width: 19,
    height: 17,
  },
  minus: {
    elements: [
      {
        type: 'path',
        value:
          'M199.991 0C89.715 0 .002 89.72.002 200c0 110.279 89.713 200 199.989 200 110.281 0 200.007-89.721 200.007-200 0-110.28-89.726-200-200.007-200zm0 373.77c-95.81 0-173.759-77.953-173.759-173.77 0-95.817 77.949-173.77 173.759-173.77 95.821 0 173.775 77.953 173.775 173.77.002 95.816-77.954 173.77-173.775 173.77z',
      },
      {
        type: 'path',
        value:
          'M279.476 186.884l-158.958.003c-7.242 0-13.115 5.873-13.115 13.115 0 7.243 5.873 13.116 13.115 13.116l158.958-.005c7.243 0 13.115-5.872 13.115-13.114s-5.872-13.115-13.115-13.115z',
      },
    ],
    height: 400,
    width: 400,
  },
  user: {
    elements: [
      {
        type: 'path',
        value:
          'M219.265 219.267c30.271 0 56.108-10.71 77.518-32.121 21.412-21.411 32.12-47.248 32.12-77.515 0-30.262-10.708-56.1-32.12-77.516C275.366 10.705 249.528 0 219.265 0S163.16 10.705 141.75 32.115c-21.414 21.416-32.121 47.253-32.121 77.516 0 30.267 10.707 56.104 32.121 77.515 21.416 21.411 47.251 32.121 77.515 32.121z',
      },
      {
        type: 'path',
        value:
          'M419.258 335.036c-.668-9.609-2.002-19.985-3.997-31.121-1.999-11.136-4.524-21.457-7.57-30.978-3.046-9.514-7.139-18.794-12.278-27.836-5.137-9.041-11.037-16.748-17.703-23.127-6.666-6.377-14.801-11.465-24.406-15.271-9.617-3.805-20.229-5.711-31.84-5.711-1.711 0-5.709 2.046-11.991 6.139a2716.072 2716.072 0 01-21.266 13.708c-7.898 5.037-18.182 9.609-30.834 13.695-12.658 4.093-25.361 6.14-38.118 6.14-12.752 0-25.456-2.047-38.112-6.14-12.655-4.086-22.936-8.658-30.835-13.695a2784.143 2784.143 0 01-21.267-13.708c-6.283-4.093-10.278-6.139-11.991-6.139-11.61 0-22.222 1.906-31.833 5.711-9.613 3.806-17.749 8.898-24.412 15.271-6.661 6.379-12.562 14.086-17.699 23.127-5.137 9.042-9.229 18.326-12.275 27.836-3.045 9.521-5.568 19.842-7.566 30.978-2 11.136-3.332 21.505-3.999 31.121a427.742 427.742 0 00-.998 29.554c0 22.836 6.949 40.875 20.842 54.104 13.896 13.224 32.36 19.835 55.39 19.835h249.533c23.028 0 41.49-6.611 55.388-19.835 13.901-13.229 20.845-31.265 20.845-54.104-.002-10.088-.334-19.938-1.008-29.554z',
      },
    ],
    height: 438.529,
    width: 438.529,
  },
  import: {
    elements: [
      {
        type: 'path',
        value:
          'M9.1 297.189c1 .3 2.1.4 3.1.4 4.3 0 8.4-2.3 10.7-6.2 47-82.8 96-88.4 151.9-88.8v49.6c0 4.6 2.6 8.8 6.7 10.9 4.1 2.1 9 1.7 12.7-.9l152.8-109.8c3.2-2.3 5.1-6 5.1-10s-1.9-7.7-5.1-10l-152.8-109.2c-3.7-2.7-8.6-3-12.7-.9s-6.6 6.3-6.6 10.9v50.4c-70.2 6-120.1 33.4-148.2 81.5-23.9 40.8-26.7 88.2-26.7 120.3 0 5.5 3.8 10.4 9.1 11.8zm178.6-189.9c6.5-.3 11.6-5.7 11.6-12.2v-38l119.5 85.5-119.5 85.8v-38c0-3.2-1.3-6.4-3.6-8.7s-5.4-3.6-8.7-3.6c-53.8 0-109.1 0-159.9 63.1 8.8-63.2 42.6-128 160.6-133.9z',
      },
      {
        type: 'path',
        value:
          'M403.9 154.889h62.4v290.4H119.9v-175.9c0-6.8-5.5-12.3-12.3-12.3s-12.2 5.6-12.2 12.3v188.2c0 6.8 5.5 12.3 12.3 12.3h370.8c6.8 0 12.3-5.5 12.3-12.3v-315c0-6.8-5.5-12.3-12.3-12.3h-74.7c-6.8 0-12.3 5.5-12.3 12.3.1 6.8 5.6 12.3 12.4 12.3z',
      },
    ],
    height: 490.8,
    width: 490.8,
  },
  export: {
    elements: [
      {
        type: 'path',
        value:
          'M143.701 152.374l152.8 109.7c3.7 2.7 8.7 3 12.7.9 4.1-2.1 6.7-6.3 6.7-10.9v-49.6c55.9.4 104.9 6 151.9 88.8 2.2 3.9 6.3 6.2 10.7 6.2 1 0 2.1-.1 3.1-.4 5.4-1.4 9.1-6.3 9.1-11.8 0-32.1-2.8-79.4-26.6-120.3-28.1-48.1-77.9-75.5-148.2-81.5v-50.3c0-4.6-2.6-8.8-6.6-10.9-4.1-2.1-9-1.7-12.7.9l-152.9 109.3c-3.2 2.3-5.1 6-5.1 10 0 3.9 1.9 7.6 5.1 9.9zm147.7-95.4v38c0 6.5 5.1 11.9 11.6 12.2 118 5.9 151.8 70.7 160.5 133.9-50.8-63.1-106.1-63.1-159.9-63.1-3.2 0-6.4 1.3-8.7 3.6s-3.6 5.4-3.6 8.7v38l-119.5-85.8 119.6-85.5z',
      },
      {
        type: 'path',
        value:
          'M12.201 469.774h370.8c6.8 0 12.3-5.5 12.3-12.3v-188.1c0-6.8-5.5-12.3-12.3-12.3s-12.3 5.5-12.3 12.3v175.9h-346.3v-290.4h62.4c6.8 0 12.3-5.5 12.3-12.3s-5.5-12.3-12.3-12.3h-74.6c-6.8 0-12.2 5.5-12.2 12.3v314.9c-.1 6.9 5.4 12.3 12.2 12.3z',
      },
    ],
    height: 490.701,
    width: 490.701,
  },
  checkbox: {
    elements: [
      {
        type: 'path',
        value:
          'M408 0H51C22.95 0 0 22.95 0 51v357c0 28.05 22.95 51 51 51h357c28.05 0 51-22.95 51-51V51c0-28.05-22.95-51-51-51zM178.5 357L51 229.5l35.7-35.7 91.8 91.8L372.3 91.8l35.7 35.7L178.5 357z',
      },
    ],
    height: 459,
    width: 459,
  },
  emptyCheckbox: {
    elements: [
      {
        type: 'path',
        value:
          'M377.87 24.126C361.786 8.042 342.417 0 319.769 0H82.227C59.579 0 40.211 8.042 24.125 24.126 8.044 40.212.002 59.576.002 82.228v237.543c0 22.647 8.042 42.014 24.123 58.101 16.086 16.085 35.454 24.127 58.102 24.127h237.542c22.648 0 42.011-8.042 58.102-24.127 16.085-16.087 24.126-35.453 24.126-58.101V82.228c-.004-22.648-8.046-42.016-24.127-58.102zm-12.422 295.645c0 12.559-4.47 23.314-13.415 32.264-8.945 8.945-19.698 13.411-32.265 13.411H82.227c-12.563 0-23.317-4.466-32.264-13.411-8.945-8.949-13.418-19.705-13.418-32.264V82.228c0-12.562 4.473-23.316 13.418-32.264 8.947-8.946 19.701-13.418 32.264-13.418h237.542c12.566 0 23.319 4.473 32.265 13.418 8.945 8.947 13.415 19.701 13.415 32.264v237.543h-.001z',
      },
    ],
    height: 401.998,
    width: 401.998,
  },
  delete: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M8.193 1.53h1.858c.533 0 .949.389.949.887V3.867h-.815v8.254c0 .498-.416.879-.95.879h-7.47c-.534 0-.942-.38-.942-.879V3.867H0V2.417c0-.498.415-.886.949-.886h1.866V.403c0-.227.188-.403.43-.403h4.431c.008 0 .023.007.031.015C7.715.007 7.731 0 7.754 0c.243 0 .44.176.44.403V1.53zm-.87-.724H3.677v.725h3.646V.806zM9.314 12.12c0 .051-.023.073-.078.073H1.764c-.055 0-.078-.022-.078-.073V3.867h7.628v8.254zM.87 3.061h9.26v-.644c0-.051-.024-.08-.079-.08H.95c-.055 0-.079.029-.079.08v.644z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
      { type: 'path', value: { path: 'M7 5h1v7H7zM5 5h1v7H5zM3 5h1v7H3z' } },
    ],
    height: 13,
    width: 11,
  },
  download: {
    elements: [
      {
        type: 'path',
        value:
          'M11.685 4.044a.306.306 0 00-.315.3v2.718c0 .74-.635 1.34-1.413 1.34H3.043c-.78 0-1.413-.602-1.413-1.34V4.3A.306.306 0 001.315 4 .306.306 0 001 4.299v2.763C1 8.132 1.917 9 3.043 9h6.914C11.085 9 12 8.13 12 7.062V4.343a.308.308 0 00-.315-.299z',
      },
      {
        type: 'path',
        value:
          'M6.262 6.915a.376.376 0 00.24.085.364.364 0 00.238-.085L8.9 5.091a.256.256 0 000-.406.384.384 0 00-.48 0L6.84 6.02V1.287c0-.16-.151-.287-.34-.287-.189 0-.34.128-.34.287V6.02l-1.58-1.335a.384.384 0 00-.481 0 .256.256 0 000 .406l2.162 1.824z',
      },
    ],
    height: 10,
    width: 13,
  },
  print: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M8.992 2.46h.298c.942 0 1.71.796 1.71 1.773v2.86c0 .977-.768 1.773-1.71 1.773h-.3v2.249c0 .487-.384.885-.854.885H2.833c-.47 0-.854-.398-.854-.885V8.866H1.71C.768 8.866 0 8.07 0 7.093v-2.86C0 3.256.768 2.46 1.71 2.46h.271v-.687C1.981.796 2.75 0 3.691 0h3.591c.943 0 1.71.796 1.71 1.773v.687zM3.69.672c-.585 0-1.063.494-1.063 1.101v.687H8.34v-.687c0-.607-.477-1.101-1.063-1.101H3.69zM8.342 11.12a.212.212 0 01-.206.214H2.833a.212.212 0 01-.207-.214V7.41h5.716v3.709zm.948-2.923c.585 0 1.062-.495 1.062-1.101v-2.86c0-.607-.477-1.102-1.062-1.102H1.71c-.585 0-1.062.495-1.062 1.101v2.86c0 .607.477 1.102 1.062 1.102h.271v-.786h-.477a.329.329 0 01-.324-.335c0-.187.144-.336.324-.336h7.915c.18 0 .324.15.324.336a.329.329 0 01-.324.335h-.427v.786h.298z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
      {
        type: 'path',
        value:
          'M4.271 9H7.73C7.879 9 8 8.778 8 8.5S7.88 8 7.729 8H4.27C4.121 8 4 8.222 4 8.5s.123.5.271.5zM7.73 10H4.272c-.15 0-.271.222-.271.5s.12.5.271.5H7.73c.15 0 .271-.222.271-.5s-.12-.5-.27-.5zM9.504 4H8.496A.496.496 0 008 4.5c0 .278.22.5.496.5h1.008A.496.496 0 0010 4.5c0-.278-.22-.5-.496-.5z',
      },
    ],
    height: 12,
    width: 11,
  },
  replicate: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M.428 9.625H8.51c.236 0 .428-.177.428-.395V1.77c0-.218-.192-.395-.428-.395H.428C.19 1.375 0 1.552 0 1.77v7.46c0 .218.191.395.428.395zm7.654-.79H.855v-6.67h7.227v6.67z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
      {
        type: 'path',
        value:
          'M10.572 8.25h-.868c-.236 0-.428-.177-.428-.395 0-.218.192-.395.428-.395h.44V.79H2.919v.439c0 .218-.192.395-.428.395s-.428-.177-.428-.395V.395c0-.218.192-.395.428-.395h8.082c.237 0 .428.177.428.395v7.46c0 .218-.191.395-.428.395z',
      },
    ],
    height: 10,
    width: 11,
  },
  transfer: {
    elements: [
      {
        type: 'path',
        value:
          'M12.864 3.352L9.614.135a.468.468 0 00-.657 0 .457.457 0 00-.136.325v1.378h-3.25a.462.462 0 00-.464.46c0 .254.208.46.464.46h3.715a.462.462 0 00.464-.46v-.729l2.13 2.108-2.13 2.107v-.728a.462.462 0 00-.464-.46H4.179V3.217a.467.467 0 00-.793-.325L.136 6.11a.456.456 0 000 .65l3.25 3.217a.467.467 0 00.793-.325V8.274h3.25a.462.462 0 00.464-.46.462.462 0 00-.464-.46H3.714a.462.462 0 00-.464.46v.729L1.12 6.434l2.13-2.107v.729c0 .253.208.46.464.46h5.107v1.378a.467.467 0 00.793.325l3.25-3.217a.456.456 0 000-.65z',
      },
    ],
    height: 11,
    width: 13,
  },
  sms: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M10.906.087a.33.33 0 00-.35-.062L.19 4.318a.304.304 0 00-.19.26.294.294 0 00.16.274L3.774 6.79 5.92 10.24c.056.091.162.149.272.149h.019a.317.317 0 00.272-.18l4.49-9.792a.284.284 0 00-.067-.33zM9.257 1.215l-5.305 4.98-2.929-1.569 8.234-3.411zM4.403 6.617L6.15 9.429l3.588-7.822-5.335 5.01z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
    ],
    height: 11,
    width: 11,
  },
  whatsapp: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'm17.507 14.307-.009.075c-2.199-1.096-2.429-1.242-2.713-.816-.197.295-.771.964-.944 1.162-.175.195-.349.21-.646.075-.3-.15-1.263-.465-2.403-1.485-.888-.795-1.484-1.77-1.66-2.07-.293-.506.32-.578.878-1.634.1-.21.049-.375-.025-.524-.075-.15-.672-1.62-.922-2.206-.24-.584-.487-.51-.672-.51-.576-.05-.997-.042-1.368.344-1.614 1.774-1.207 3.604.174 5.55 2.714 3.552 4.16 4.206 6.804 5.114.714.227 1.365.195 1.88.121.574-.091 1.767-.721 2.016-1.426.255-.705.255-1.29.18-1.425-.074-.135-.27-.21-.57-.345z',
        },
      },
      {
        type: 'path',
        value: {
          path:
            'm20.52 3.449c-7.689-7.433-20.414-2.042-20.419 8.444 0 2.096.549 4.14 1.595 5.945l-1.696 6.162 6.335-1.652c7.905 4.27 17.661-1.4 17.665-10.449 0-3.176-1.24-6.165-3.495-8.411zm1.482 8.417c-.006 7.633-8.385 12.4-15.012 8.504l-.36-.214-3.75.975 1.005-3.645-.239-.375c-4.124-6.565.614-15.145 8.426-15.145 2.654 0 5.145 1.035 7.021 2.91 1.875 1.859 2.909 4.35 2.909 6.99z',
        },
      },
    ],
    height: 25,
    width: 25,
  },
  mail: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M10.033 0H.967C.434 0 0 .402 0 .896v5.973c0 .492.432.896.967.896h9.066c.531 0 .967-.401.967-.896V.896C11 .404 10.568 0 10.033 0zm-.19.555L5.518 4.437 1.158.555h8.684zm-9.264.554v5.546L3.474 3.87.579 1.11zm.579 6.101l3.065-2.773 1.074.963a.342.342 0 00.45 0l1.046-.948 3.05 2.758H1.157zm6.368-3.328l2.895 2.773V1.11L7.526 3.882z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
    ],
    height: 8,
    width: 11,
  },
  indeterminate: {
    elements: [
      { type: 'rect', value: { width: '16', height: '15', rx: '2' } },
      {
        type: 'path',
        value: {
          path: 'M3.167 7.5H13.5',
          props: { stroke: 'white', 'stroke-width': '1.5', 'stroke-linecap': 'square' },
        },
      },
    ],
    height: 15,
    width: 16,
  },
  sort: {
    elements: [
      {
        type: 'path',
        value:
          'M6.158 12.954A.292.292 0 016 13a.292.292 0 01-.16-.046c-.044-.03-1.112-.791-1.797-1.645a.186.186 0 01-.013-.22.268.268 0 01.227-.114h.893c.094-.469.122-7.708.187-7.785C5.433 3.077 5.742 3 6 3c.256 0 .53.077.624.19.065.077.13 7.316.224 7.785h.896c.094 0 .181.044.226.114.044.07.04.154-.013.22-.686.854-1.753 1.615-1.799 1.645zM1.842.046A.293.293 0 012 0c.056 0 .113.015.16.046.044.03 1.112.791 1.797 1.644.052.067.058.151.013.221a.268.268 0 01-.227.114H2.85c-.094.469-.122 7.708-.187 7.785-.096.113-.405.19-.663.19-.256 0-.53-.077-.624-.19-.065-.077-.13-7.316-.224-7.785H.256a.268.268 0 01-.226-.114.186.186 0 01.013-.22C.73.836 1.796.075 1.842.045z',
      },
    ],
    height: 13,
    width: 8,
  },
  sortAsc: {
    elements: [
      {
        type: 'path',
        value:
          'M10.158 12.94A.246.246 0 0110 13a.245.245 0 01-.16-.06c-.044-.04-1.112-1.028-1.797-2.138a.3.3 0 01-.013-.287.255.255 0 01.227-.148h.893c.094-.61.122-10.02.187-10.12C9.433.1 9.742 0 10 0c.256 0 .53.1.624.247.065.1.13 9.51.224 10.12h.896c.094 0 .181.058.226.148.044.091.04.201-.013.287-.686 1.11-1.753 2.099-1.799 2.138z',
      },
      {
        type: 'path',
        value: {
          path:
            'M3.35.063L4.985 5.86c.009.014.015.032.015.052C5 5.96 4.963 6 4.918 6H3.625a.084.084 0 01-.079-.064l-.39-1.43H1.763l-.361 1.428a.083.083 0 01-.08.066H.083a.078.078 0 01-.066-.037.094.094 0 01-.012-.08L1.622.064A.083.083 0 011.7 0h1.572c.036 0 .067.026.078.063zM1.98 3.342h.955l-.3-1.119a19.78 19.78 0 01-.194-.801l-.025.119-.004.021c-.047.228-.095.46-.146.66l-.285 1.12z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
      {
        type: 'path',
        value:
          'M4.923 11.732H2.706l2.243-3.83a.103.103 0 00.014-.052v-.76c0-.05-.035-.09-.077-.09h-3.59c-.041 0-.076.04-.076.09v1.088c0 .05.034.09.077.09h2.01L1.014 12.15a.098.098 0 00-.014.051v.709c0 .049.034.089.077.089h3.846c.042 0 .077-.04.077-.09v-1.088c0-.05-.035-.09-.077-.09z',
      },
    ],
    height: 13,
    width: 12,
  },
  sortDes: {
    elements: [
      {
        type: 'path',
        value:
          'M9.842.06A.246.246 0 0110 0c.056 0 .113.02.16.06.044.04 1.112 1.028 1.797 2.138a.3.3 0 01.013.287.255.255 0 01-.227.148h-.893c-.094.61-.122 10.02-.187 10.12-.096.147-.405.247-.663.247-.256 0-.53-.1-.624-.247-.065-.1-.13-9.51-.224-10.12h-.896a.255.255 0 01-.226-.148.302.302 0 01.013-.287C8.73 1.088 9.796.099 9.842.06z',
      },
      {
        type: 'path',
        value: {
          path:
            'M3.35.063L4.985 5.86c.009.014.015.032.015.052C5 5.96 4.963 6 4.918 6H3.625a.084.084 0 01-.079-.064l-.39-1.43H1.763l-.361 1.428a.083.083 0 01-.08.066H.083a.078.078 0 01-.066-.037.094.094 0 01-.012-.08L1.622.064A.083.083 0 011.7 0h1.572c.036 0 .067.026.078.063zM1.98 3.342h.955l-.3-1.119a19.78 19.78 0 01-.194-.801l-.025.119-.004.021c-.047.228-.095.46-.146.66l-.285 1.12z',
          props: { fillRule: 'evenodd', clipRule: 'evenodd' },
        },
      },
      {
        type: 'path',
        value:
          'M4.923 11.732H2.706l2.243-3.83a.103.103 0 00.014-.052v-.76c0-.05-.035-.09-.077-.09h-3.59c-.041 0-.076.04-.076.09v1.088c0 .05.034.09.077.09h2.01L1.014 12.15a.098.098 0 00-.014.051v.709c0 .049.034.089.077.089h3.846c.042 0 .077-.04.077-.09v-1.088c0-.05-.035-.09-.077-.09z',
      },
    ],
    height: 13,
    width: 12,
  },
  file: {
    elements: [
      {
        type: 'path',
        value:
          'M28.998 7.894a.916.916 0 00-.263-.637L21.803.267a.898.898 0 00-.64-.267H1.906A1.916 1.916 0 000 1.922v35.156C0 38.138.855 39 1.906 39h25.188A1.917 1.917 0 0029 37.078V7.903 7.9l-.001-.002v-.004zm-6.93-4.776l3.84 3.872h-3.744a.096.096 0 01-.096-.096V3.118zm5.026 34.056a.096.096 0 00.095-.096V8.816h-5.025a1.917 1.917 0 01-1.907-1.922V1.826H1.907a.096.096 0 00-.096.096v35.156c0 .053.043.096.095.096h25.188z',
      },
      {
        type: 'path',
        value:
          'M10.746 14H6.254c-.14 0-.254.448-.254 1s.114 1 .254 1h4.492c.14 0 .254-.448.254-1s-.114-1-.254-1zM10.746 24H6.254c-.14 0-.254.448-.254 1s.114 1 .254 1h4.492c.14 0 .254-.448.254-1s-.114-1-.254-1zM10.746 19H6.254c-.14 0-.254.448-.254 1s.114 1 .254 1h4.492c.14 0 .254-.448.254-1s-.114-1-.254-1zM10.746 29H6.254c-.14 0-.254.448-.254 1s.114 1 .254 1h4.492c.14 0 .254-.448.254-1s-.114-1-.254-1zM23.39 14H12.61c-.337 0-.61.448-.61 1s.273 1 .61 1h10.78c.337 0 .61-.448.61-1s-.273-1-.61-1zM23.39 24H12.61c-.337 0-.61.448-.61 1s.273 1 .61 1h10.78c.337 0 .61-.448.61-1s-.273-1-.61-1zM23.39 19H12.61c-.337 0-.61.448-.61 1s.273 1 .61 1h10.78c.337 0 .61-.448.61-1s-.273-1-.61-1zM23.39 29H12.61c-.337 0-.61.448-.61 1s.273 1 .61 1h10.78c.337 0 .61-.448.61-1s-.273-1-.61-1z',
      },
    ],
    width: 29,
    height: 39,
  },
  edit: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M11.215 1.165a3.97 3.97 0 015.62 0A3.934 3.934 0 0118 3.973a3.95 3.95 0 01-1.166 2.811l-10.301 10.3a.508.508 0 01-.73 0l-4.149-4.151-.533 3.943 2.73-.368a.519.519 0 01.139 1.028l-3.406.46C.561 18 .538 18 .515 18a.524.524 0 01-.364-.153.514.514 0 01-.146-.434l.763-5.654a.515.515 0 01.146-.295L11.215 1.165zm1.93 2.996l-1.73-1.73-9.4 9.401 1.73 1.73 9.4-9.401zM6.172 15.986l-1.691-1.691 9.4-9.402 1.691 1.692-9.4 9.401zm5.983-14.28l4.138 4.139a2.922 2.922 0 00.672-1.872c0-.786-.303-1.527-.86-2.08a2.93 2.93 0 00-2.078-.858c-.69 0-1.346.237-1.872.67z',
          props: {
            fillRule: 'evenodd',
            clipRule: 'evenodd',
          },
        },
      },
    ],
    width: 18,
    height: 18,
  },
  rightArrow: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M6.8548 0.888611L5.61035 1.83927L12.3659 6.99997L5.61035 12.1607L6.8548 13.1113L13.6104 7.95063L14.8548 6.99997L13.6104 6.04931L6.8548 0.888611Z',
        },
      },
    ],
    width: 17,
    height: 14,
  },
  facebook: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35z',
          props: {
            fill: '#3B5998',
          },
        },
      },
      {
        type: 'path',
        value: {
          path:
            'M22.597 17.979h-2.966V29h-4.494V17.979H13v-3.873h2.137v-2.507c0-1.792.84-4.599 4.534-4.599L23 7.014v3.76h-2.415c-.396 0-.954.2-.954 1.056v2.28h3.359l-.393 3.869z',
          props: {
            fill: '#fff',
          },
        },
      },
    ],
    width: 35,
    height: 35,
  },
  twitter: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35z',
          props: {
            fill: '#55ACEE',
          },
        },
      },
      {
        type: 'path',
        value: {
          path:
            'M28 13.776a7.96 7.96 0 01-2.239.595 3.82 3.82 0 001.714-2.094 7.945 7.945 0 01-2.475.918A3.949 3.949 0 0022.154 12c-2.152 0-3.898 1.696-3.898 3.787 0 .297.034.585.101.863a11.176 11.176 0 01-8.035-3.957 3.69 3.69 0 00-.527 1.904c0 1.313.688 2.473 1.734 3.152a3.958 3.958 0 01-1.766-.474v.048c0 1.834 1.344 3.365 3.127 3.712a3.99 3.99 0 01-1.76.066c.496 1.504 1.935 2.599 3.641 2.63a7.968 7.968 0 01-4.842 1.62c-.314 0-.625-.017-.929-.052A11.263 11.263 0 0014.975 27c7.17 0 11.09-5.77 11.09-10.775 0-.164-.003-.327-.01-.49A7.785 7.785 0 0028 13.776z',
          props: {
            fill: '#F1F2F2',
          },
        },
      },
    ],
    width: 35,
    height: 35,
  },
  linkedIn: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M17.5 35C27.165 35 35 27.165 35 17.5S27.165 0 17.5 0 0 7.835 0 17.5 7.835 35 17.5 35z',
          props: {
            fill: '#007AB9',
          },
        },
      },
      {
        type: 'path',
        value: {
          path:
            'M11.184 8C9.864 8 9 9.008 9 10.331c0 1.296.839 2.333 2.133 2.333h.025c1.346 0 2.183-1.037 2.183-2.333C13.315 9.008 12.504 8 11.184 8zM27 20.261V28h-3.859v-7.22c0-1.813-.557-3.051-1.954-3.051-1.066 0-1.7.833-1.98 1.64-.101.289-.127.69-.127 1.094V28h-3.86s.052-12.23 0-13.495h3.86v1.912a.298.298 0 01-.012.021l-.014.023h.026v-.044c.513-.917 1.427-2.229 3.478-2.229 2.539 0 4.442 1.929 4.442 6.073zM13.087 28H9.229V14.505h3.858V28z',
          props: {
            fill: '#F1F2F2',
          },
        },
      },
    ],
    width: 35,
    height: 35,
  },
  info: {
    elements: [
      {
        type: 'path',
        value: {
          path:
            'M12 0C5.371 0 0 5.371 0 12s5.371 12 12 12 12-5.371 12-12S18.629 0 12 0zm0 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 3.813c-.184 0-.336-.004-.5.03a1.127 1.127 0 00-.438.22.98.98 0 00-.28.375c-.071.152-.095.332-.095.562 0 .227.024.406.094.563.07.156.16.28.281.375.122.093.274.148.438.187.164.04.316.063.5.063.18 0 .371-.024.531-.063.16-.04.285-.094.406-.188a.951.951 0 00.282-.375c.07-.152.125-.335.125-.562 0-.23-.055-.41-.125-.563a.98.98 0 00-.281-.375c-.122-.093-.247-.183-.407-.218-.16-.035-.351-.032-.531-.032zm-1.219 3.343v8.969h2.438V9.156z',
        },
      },
    ],
    width: 24,
    height: 24,
  },
};
