// import React, { useState, useEffect, useRef } from 'react';
// import styled from 'styled-components';
// import { Loader } from 'semantic-ui-react';
// import PropTypes from 'prop-types';
// import { fetchTagsGroupList } from '../../Api';
// import OutsideClick from '../../Components/OutsideClick';

// const Wrapper = styled.div`
//   display: ${({ show }) => (show ? 'flex' : 'none')};
//   justify-content: center;
//   align-items: center;
//   position: fixed;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: rgba(0, 0, 0, 0.4);
//   z-index: 1000;
// `;

// const Container = styled.div`
//   width: 60%;
//   margin: 0 auto;
//   background-color: #fff;
//   height: 80%;
//   min-height: 200px;
//   border-radius: 10px;
//   display: flex;
//   flex-direction: column;
//   /* overflow: auto; */
// `;

// const HeaderWrapper = styled.p`
//   padding: 10px;
//   text-align: center;
//   font-size: 22px;
//   font-weight: 900;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
// `;

// const GroupWrapper = styled.div`
//   width: 48%;
//   margin: 15px 0;
//   background-color: #d8d8d8;
//   border-radius: 6px;
// `;

// const GroupTitle = styled.div`
//   padding: 7px 10px;
//   text-align: center;
//   font-size: 16px;
//   font-weight: 800;
//   border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
// `;

// const GroupListContainer = styled.div`
//   flex: 1;
//   overflow: auto;
//   position: relative;
// `;
// const GroupListWrapper = styled.div`
//   margin: 0 auto;
//   width: 95%;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// `;

// const TagItemWrapper = styled.div`
//   padding: 5px 10px;
//   border-radius: 12px;
//   margin: 5px;
//   background-color: ${({ active }) => (active ? '#f22726' : '#fff')};
//   font-size: 14px;
//   line-height: 16px;
//   cursor: pointer;
//   color: ${({ active }) => (active ? '#fff' : '#000')};
// `;

// const TagsListWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   padding: 15px 15px 15px;
// `;

// const ModalFooter = styled.div`
//   padding: 5px 10px;
//   display: flex;
//   justify-content: flex-end;
// `;
// const SaveButtonWrapper = styled.div`
//   margin: 0 10px;
//   padding: 6px 15px;
//   border-radius: 4px;
//   background-color: #21ba45;
//   color: #fff;
//   font-size: 14px;
//   font-weight: 700;
//   cursor: pointer;
// `;

// const CancelButtonWrapper = styled.div`
//   margin: 0 10px;
//   padding: 6px 15px;
//   border-radius: 4px;
//   background-color: #a9a9a9;
//   color: #fff;
//   font-size: 14px;
//   font-weight: 700;
//   cursor: pointer;
// `;

// const LoaderWrapper = styled.div`
//   position: absolute;
//   top: 0;
//   right: 0;
//   bottom: 0;
//   left: 0;
//   background-color: rgba(255, 255, 255, 0.5);
//   z-index: 200;
// `;
// const TagsModal = ({ show, onClose, value = [], handleSave }) => {
//   const [tagsGroupsList, setTagsGroupsList] = useState([]);
//   const [selectedTags, setSelectedTags] = useState([]);
//   const [allTagsListId, setAllTagsListId] = useState([]);
//   const [tagListLoading, setTagListLoading] = useState(true);
//   const contentRef = useRef(null);

//   useEffect(() => {
//     setTagListLoading(true);
//     fetchTagsGroupList()
//       .then((response) => {
//         setTagsGroupsList(response.data.results);
//         const idsList = [];
//         response.data.results.forEach((groups) => {
//           idsList.push(groups.tag_group.id);

//           if (groups.tags && groups.tags.results && groups.tags.results.length > 0) {
//             groups.tags.results.forEach((tags) => {
//               idsList.push(tags.id);
//             });
//           }
//         });
//         setTagListLoading(false);
//         setAllTagsListId(idsList);
//       })
//       .catch((error) => {
//         setTagListLoading(false);
//       });
//   }, []);

//   const handleClose = () => {
//     onClose();
//   };

//   useEffect(() => {
//     setSelectedTags(value.map((item) => item.id).filter((item) => allTagsListId.includes(item)));
//   }, [value, allTagsListId]);

//   const handleClickSave = () => {
//     const listData = [];
//     const uncatagorisedItems = [];
//     tagsGroupsList.forEach((groups) => {
//       if (selectedTags.includes(groups.tag_group.id)) {
//         const index = listData.findIndex((item) => item.id === groups.tag_group.id);
//         if (index === -1) {
//           listData.push(groups.tag_group);
//         }
//       }

//       if (groups.tags && groups.tags.results && groups.tags.results.length > 0) {
//         groups.tags.results.forEach((tags) => {
//           if (selectedTags.includes(tags.id)) {
//             const index = listData.findIndex((item) => item.id === tags.id);
//             if (index === -1) {
//               listData.push(tags);
//             }
//           }
//         });

//         value.forEach((valueItem) => {
//           if (isNaN(valueItem?.tag_group) && !uncatagorisedItems?.includes(valueItem)) {
//             if (valueItem?.tag_group && Object.keys(valueItem?.tag_group).length === 0) {
//               uncatagorisedItems.push(valueItem);
//             }
//           }
//         });
//       }
//     });
//     handleSave([...uncatagorisedItems, ...listData]);
//     onClose();
//   };
//   return (
//     <Wrapper show={show}>
//       <Container ref={contentRef}>
//         <HeaderWrapper>Tags</HeaderWrapper>
//         <GroupListContainer>
//           {tagListLoading && (
//             <LoaderWrapper>
//               <Loader active={tagListLoading} />
//             </LoaderWrapper>
//           )}
//           <GroupListWrapper>
//             {tagsGroupsList
//               .filter((groups) => groups.tags && groups.tags.results && groups.tags.results.length)
//               .map((groupItem) => (
//                 <GroupWrapper>
//                   <GroupTitle>{groupItem.tag_group.title}</GroupTitle>
//                   <TagsListWrapper>
//                     {/* <TagItemWrapper
//                       active={selectedTags.includes(groupItem.tag_group.id)}
//                       onClick={() => {
//                         const selectedTagsList = JSON.parse(JSON.stringify(selectedTags));
//                         if (selectedTags.includes(groupItem.tag_group.id)) {
//                           const index = selectedTags.indexOf(groupItem.tag_group.id);
//                           selectedTagsList.splice(index, 1);
//                         } else {
//                           selectedTagsList.push(groupItem.tag_group.id);
//                         }

//                         setSelectedTags(selectedTagsList);
//                       }}
//                     >
//                       {groupItem.tag_group.title}
//                     </TagItemWrapper> */}
//                     {groupItem.tags.results.map((tagItem) => (
//                       <>
//                         <TagItemWrapper
//                           active={selectedTags.includes(tagItem.id)}
//                           onClick={() => {
//                             const selectedTagsList = JSON.parse(JSON.stringify(selectedTags));
//                             if (selectedTags.includes(tagItem.id)) {
//                               const index = selectedTags.indexOf(tagItem.id);
//                               selectedTagsList.splice(index, 1);
//                             } else {
//                               selectedTagsList.push(tagItem.id);
//                             }
//                             setSelectedTags(selectedTagsList);
//                           }}
//                         >
//                           {tagItem.title}
//                         </TagItemWrapper>
//                       </>
//                     ))}
//                   </TagsListWrapper>
//                 </GroupWrapper>
//               ))}
//           </GroupListWrapper>
//         </GroupListContainer>
//         <ModalFooter>
//           <CancelButtonWrapper onClick={handleClose}>Cancel</CancelButtonWrapper>
//           <SaveButtonWrapper onClick={handleClickSave}>Save</SaveButtonWrapper>
//         </ModalFooter>
//       </Container>
//       <OutsideClick
//         elementRef={contentRef}
//         handleOutsideClick={handleClose}
//         activeOutsideClick={show}
//       />
//     </Wrapper>
//   );
// };

// TagsModal.propTypes = {
//   show: PropTypes.bool,
//   onClose: PropTypes.func,
//   handleSave: PropTypes.func,
//   value: PropTypes.array,
// };

// export default TagsModal;







import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import OutsideClick from '../../Components/OutsideClick';
// import { fetchQuestionAnswerList } from 'Api';

const Wrapper = styled.div`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
`;

// const Container = styled.div`
//   width: 40%;
//   margin: 0 auto;
//   background-color: #fff;
//   height: 20%;
//   min-height: 200px;
//   border-radius: 10px;
//   display: flex;
//   flex-direction: column;
// `;

const Container = styled.div`
  width: 40%;
  margin: 0 auto;
  background-color: #fff;
  height: 20%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;

const HeaderWrapper = styled.p`
  padding: 10px;
  text-align: center;
  font-size: 22px;
  font-weight: 900;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;
`;

const ModalFooter = styled.div`
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;
`;

const SaveButtonWrapper = styled.div`
  margin: 0 10px;
  padding: 6px 15px;
  border-radius: 4px;
  background-color: #21ba45;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`;

const CancelButtonWrapper = styled.div`
  margin: 0 10px;
  padding: 6px 15px;
  border-radius: 4px;
  background-color: #a9a9a9;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
`;

const ConfirmationModal = ({ show, onClose, handleConfirm }) => {
  const contentRef = useRef(null);  
  const handleClose = () => {
    onClose();
  };

  const handleConfirmAction = () => {
    handleConfirm();
    onClose();
  };

  // useEffect(() => {
  //   fetchQuestionAnswerList(1)
  //     .then((response) => {        
  //       const idsList = [];
  //       response.data.results.forEach((groups) => {
  //         if (groups.tag_group && groups.tag_group.id) {
  //           idsList.push(groups.tag_group.id);
  //         }

  //         if (groups.tags && groups.tags.results && groups.tags.results.length > 0) {
  //           groups.tags.results.forEach((tags) => {
  //             if (tags.id) {
  //               idsList.push(tags.id);
  //             }
  //           });
  //         }
  //       });
  //       // setAllTagsListId(idsList);
  //     })
  //     .catch((error) => {
  //       console.error(error)
  //     });
  // }, []);

  return (
    <Wrapper show={show}>
      <Container ref={contentRef}>
        <HeaderWrapper>Are you sure you want to turn on FAQs?</HeaderWrapper>
        <ModalFooter>
          <CancelButtonWrapper onClick={handleClose}>Cancel</CancelButtonWrapper>
          <SaveButtonWrapper onClick={handleConfirmAction}>OK</SaveButtonWrapper>
        </ModalFooter>
      </Container>
      <OutsideClick
        elementRef={contentRef}
        handleOutsideClick={handleClose}
        activeOutsideClick={show}
      />
    </Wrapper>
  );
};

ConfirmationModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  handleConfirm: PropTypes.func,
};

export default ConfirmationModal;
