/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import moment from 'moment';
import { EditorState, ContentState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import htmlToFormattedText from 'html-to-formatted-text';
import htmlToDraft from 'html-to-draftjs';

export const convertToDraftFormat = (str) => {
  const contentBlock = htmlToDraft(str);
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    return EditorState.createWithContent(contentState);
  }
};

export const convertToPollDataFormat = (pollData) => {
  const {question,headline,choices,has_poll} = pollData;

  const data = {
    has_poll,
    question: {
      question: question?.title,
      ...(headline && { headline })
    },
    choices: choices?.map((item) => ({
      id: item.id,
      choice: item.title,
    }))
  };

  return data;
};

export const draftToMarkup = (str) => htmlToFormattedText(stateToHTML(str.getCurrentContent()));
export const defaultArticleClick = {
  key: 'expand2',
  id: 'EXPAND2',
  value: 'EXPAND2',
  text: 'EXPAND2',
};
export const convertToLocalFormat = (data) => {
  const editData = JSON.parse(JSON.stringify(data));
  const convertedData = {
    title: editData.title || '',
    subtitle: editData.subtitle,
    content: (editData.digest && editData.digest) || '<p></p>',
    focus_card:
      (editData.focus_card &&
        editData.focus_card.slug &&
        convertToDraftFormat(editData.focus_card.slug)) ||
      EditorState.createEmpty(),
    images: editData.cover_pic,
    display_type: editData.display_type,
    videoTimeStamp: editData.timestamp_full_length,
    thumbnailImage: editData.custom_thumbnail || '',
    tags: editData.tag || [],
    collaborators: editData.collaborators,
    created_by: editData.created_by,
    isEditPage: true,
    sourceAction: (editData.source && editData.source.title) || 'Tap To Read More',
    sourceLink: editData.source && editData.source.link,
    categories: editData.categories,
    id: editData.id,
    labels: editData.labels || [],
    status: editData.status,
    originalStatus: editData.originalStatus || editData.status,
    type: editData.type || { code: '275', title: 'long_digest' },
    digest_poll: editData.digest_poll ? convertToPollDataFormat({...editData.digest_poll,"has_poll":editData.has_poll}) : {},
    markup: editData.markup,
    focusCover: editData.is_cover_pic_focused || false,
    articleClikable: typeof editData.is_clickable === 'boolean' ? editData.is_clickable : true,
    articleFormat: editData.format ? { id: editData.format } : null,
    regionDetail: editData.region_id ? { id: editData.region_id } : null,
    articleClickAction:
      editData.click_action && editData.click_action.action
        ? { id: editData.click_action.action }
        : defaultArticleClick,
    articleClickActionObject:
      (editData.click_action &&
        (editData.click_action.category ||
          editData.click_action.tag ||
          editData.click_action.host)) ||
      null,
    format_human: editData.format_human,
    slug: editData.slug,
    editorialNotes: editData.digest_internal ? editData.digest_internal.note : '',
    assignedTo: editData.digest_internal ? editData.digest_internal.assigned_to : null,
    digestAuthor:
      editData.digest_author ||
      (localStorage.getItem('loggedUserId') && {
        id: parseInt(localStorage.getItem('loggedUserId'), 10),
      }) ||
      null,
    twitter_links: editData?.digest_curated_tweet?.map(tweet=>tweet.link) || [],
    shortTitle: editData.notification_short_title || '',
    notificationShortText: editData.notification_text || '',
    futureUpdate: editData.reset_first_published_at || false,
    quality: editData.editor_score_quality || 0,
    quality2: editData.editor_score_quality_new || 0,
    relevance: editData.editor_score_relevance || 0,
    shelfLife: editData.shelf_life || 'DAYS',
    readReason: (editData.source && editData.source.read_reason) || '',

    version: editData.version || {},
    digestInternal: editData.digest_internal || {
      content_quality: null,
      content_accuracy: null,
      content_operational: null,
      plagiarism_score: null,
      content_length: null,
      assigned_to: '',
    },
    authors:
      editData.source && editData.source.authors && editData.source.authors.length > 0
        ? editData.source.authors
        : [{ name: '' }],
    publishedAt: (editData.source && editData.source.original_published_date) || new Date(),
    modified_at: editData.modified_at,
    visibleFields: editData.visible_fields,
    defaultPreviewCount: (editData.default_preview_count || 0).toString(),
  };

  return convertedData;
};

export const convertTimeToSeconds = (time) => {
  const a = time.split(':'); // split it at the colons
  if (a.length === 2) {
    a.unshift('00');
  } else if (a.length === 3) {
    a.unshift('00');
    a.unshift('00');
  }
  return +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;
};

export const convertToServerFormat = (ldata, urlString, validVideoUrl, dataVideo, videoSummary) => {
  const data = {};
  // const rawContentState = convertToRaw(ldata.content.getCurrentContent());
  const rawContentState = ldata.content;
  const markup = rawContentState.replace(/(<ins>)/gi, '').replace(/(<\/ins>)/gi, '');
  if (ldata.id) {
    data.id = ldata.id;
  }

  const focusMarkup = draftToMarkup(ldata.focus_card) && draftToMarkup(ldata.focus_card).trim();
  if (ldata.id) {
    data.id = ldata.id;
  }

  data.title = ldata.title;
  data.display_type = ldata.display_type;
  data.labels = ldata.labels.map((label) => label.id);
  data.subtitle = Object.keys(ldata.digest_poll).length ? '' : ldata.subtitle;
  data.content = markup;
  if (focusMarkup) {
    data.digest_slug = focusMarkup;
    data.focus_card = focusMarkup;
  } else {
    data.focus_card = {};
  }
  data.categories = ldata.categories.map((category) => category.id || category);
  data.tags = ldata.tags.map((tag) => tag.id || tag);
  data.collaborators =
    ldata.collaborators !== undefined ? ldata.collaborators.map((item) => item.id) : [];
  data.image = ldata.images;
  data.created_by = ldata.created_by ? ldata.created_by.id : '';
  data.custom_thumbnail =
    ldata.images.includes('youtube.com') ||
      ldata.images.includes('youtu.be') ||
      ldata.images.includes('https://stream.mux.com/') ||
      ldata.images.includes('.mp4')
      ? ''
      : ldata.images;
  // data.custom_thumbnail = ldata.thumbnailImage;
  // if (markup.match(mentionPattern)) {
  //   data.sourceAction = '';
  //   data.source = {
  //     read_reason: ldata.readReason,
  //   };
  // } else {data.images.includes('https://stream.mux.com/')
  data.source = {
    link: urlString,
    title: ldata.sourceAction,
    authors: ldata.authors,
    original_published_date: moment(ldata.publishedAt).format('YYYY-MM-DDTHH:mm:ssZZ'),
    read_reason: ldata.readReason,
  };
  if (
    data.source &&
    data.source.authors &&
    data.source.authors.length === 1 &&
    data.source.authors[0].name === ''
  )
    delete data.source.authors;
  else if (data.source && data.source.authors && data.source.authors.length > 1) {
    data.source.authors = data.source.authors.filter((author) => author.name.length);
  }
  // }
  data.format_human = ldata.format_human || 'Key Takeaways';
  data.apply_markup = true;
  data.digest_author = ldata.digestAuthor;
  data.notification_short_title = ldata.shortTitle;
  data.notification_text = ldata.notificationShortText;
  if (ldata.articleClikable) {
    data.click_action = {
      action: ldata.articleClickAction && ldata.articleClickAction.id,
      object_id: ldata.articleClickActionObject && ldata.articleClickActionObject.id,
    };
  } else {
    data.click_action = {
      action: null,
      object_id: null,
    };
  }
  data.is_clickable = ldata.articleClikable;
  data.format = ldata.articleFormat;
  data.region_id = ldata.regionDetail && ldata.regionDetail.id;
  data.visible_fields = ldata.visibleFields;
  // data.newsletter_order
  data.reset_first_published_at = ldata.futureUpdate;
  data.is_cover_pic_focused = ldata.focusCover;
  data.default_preview_count = ldata.defaultPreviewCount && parseInt(ldata.defaultPreviewCount, 10);
  data.type = ldata.type.code;
  data.originalStatus = ldata.status;
  data.status = ldata.status;
  data.twitter_links = ldata.twitter_links;
  data.show_embed = ldata.twitter_links && ldata.twitter_links.length > 0 ? true : false;

  if (!Object.keys(ldata.digest_poll).length) {
    data.digest_poll = { question: '' };
  } else {
    data.digest_poll = ldata.digest_poll;
  }

  // if (!ldata.apply_markup) data.content = htmlToFormattedText(data.content.replace(/<br s*\/?>/mg, "\n").replace(/<p[^>]*>/g, '').replace(/<\/p>/g, '\n')).replace(/(<([^>]+)>)/ig, '');
  // else data.content = data.content.replace(/&nbsp;/g, '');
  data.editor_score_relevance = ldata.relevance;
  data.editor_score_quality = ldata.quality;
  data.editor_score_quality_new = ldata.quality2;
  data.shelf_life = ldata.shelfLife;
  data.digest_internal = {
    ...ldata.digestInternal,
    assigned_to: (ldata.assignedTo && ldata.assignedTo.id) || null,
    note: ldata.editorialNotes || '',
  };

  if (urlString && urlString.match(validVideoUrl)) {
    dataVideo = dataVideo.map((segment) => {
      segment.timestamp_start = convertTimeToSeconds(segment.timestamp_start);
      segment.timestamp_end = convertTimeToSeconds(segment.timestamp_end);
      const tag = videoSummary.filter((summary) => summary.text === segment.watch_label);
      if (tag.length) segment.video_summary_tag = tag[0].key;
      return segment;
    });
    // const summaryData = {};
    data.video_summary = {
      summary: dataVideo,
    };
    data.type = 255;
    data.image = urlString;
  }

  return data;
};

// const mentionPattern = /\[@.*\]\(.*\)/;
const feedMentionPattern = /^[<p> <br>]?[a-z-0-9]+[</p>]?$/;

export const checkSummariseErrors = ({ data, transcriptError }) => {
  const errors = {};

  if (transcriptError) {
    errors.image = transcriptError;
    errors.transcriptError = transcriptError;
  }
  const focusCardData = draftToMarkup(data.focus_card).trim();
  if (focusCardData && !focusCardData.match(feedMentionPattern)) {
    errors.focus_card = 'This field should contain only 1 digest';
  }

  const contentData = data.content;
  if (
    !contentData ||
    !contentData.trim() ||
    contentData.replace(/(<([^>]+)>)/gi, '').length === 0
  ) {
    errors.content = 'This field is mandatory.';
  }

  if (data.tags && data.tags.length === 0) {
    errors.tags = 'This field is mandatory.';
  }
  if ((!data.collaborators || (data?.collaborators && data.collaborators.length === 0)) && data.type.code==="295") {
    errors.collaborators = 'This field is mandatory.';
  }
  if (data.digest_poll.has_poll && Object.keys(data.digest_poll)?.length) {
    if ( !data.digest_poll.question || !data.digest_poll.question?.question?.trim())
      errors.pollQuestion = "Entering question is mandatory. You can opt to toggle off 'Add Polls'";
    if (!data.digest_poll.choices || data.digest_poll.choices.length < 2)
      errors.pollQuestion = errors.pollQuestion
        ? `${errors.pollQuestion} Enter atleast 2 choices`
        : 'Enter atleast 2 choices';
    else {
      data.digest_poll.choices.some((choice) => {
        if (!choice.choice || !choice.choice.trim()) {
          errors.pollQuestion = errors.pollQuestion
            ? `${errors.pollQuestion} Choices cannot be blank`
            : 'Choices cannot be blank';
          return true;
        }
        return false;
      });
    }
  }
  if (data.format_human && data.format_human.length > 70) {
    errors.format = 'Format cannot be more than 70 characters long';
  }

  data.defaultPreviewCount = data.defaultPreviewCount.toString();

  if (data.articleClikable) {
    if (
      data.articleClickAction &&
      data.articleClickAction.id !== 'DETAILS' &&
      data.articleClickAction.id !== 'EXPAND' &&
      data.articleClickAction.id !== 'EXPAND2' &&
      !data.articleClickActionObject
    ) {
      errors.articleClickActionObject = 'This field is mandatory.';
    }
  }
  return errors;
};
