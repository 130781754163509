import React, { useReducer, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Loader, Button, Icon, Modal } from 'semantic-ui-react';
import produce from 'immer';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import TableView from '../../Components/TableView';
import Layout from '../../Components/Layout';
import { fetchUsers, fetchPermissions, updateUser, createNewUser } from '../../Api';
import UserForm from './UserForm';
import CreateUserForm from './CreateUserForm';
import { trimObjectValues } from '../../Utils/trimValues';

const ListWrapper = styled.div`
  position: relative;
  margin-top: 15px;

  .ui.checkbox {
    min-height: 14px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 6%;
`;

const NormalButton = styled(Button)`
  background-color: transparent !important;
  color: ${(props) => (props.active ? '#cc0000 !important' : '#21ba45 !important')};
  // > .chevron {
  //     background-color: transparent !important;
  // }
`;

const NameField = styled.div`
  cursor: pointer;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  padding-right: 20px;
`;

const AddUser = styled(Button)`
  cursor: pointer;
`;

const initialValue = {
  userListLoading: true,
  users: [],
  totalUsers: 0,
  roles: [],
  roleStatus: false,
  editStatus: false,
  currentUser: 0,
  createStatus: false,
  currentPage: 1,
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_USER_LOADING':
        draft.userListLoading = action.userListLoading;
        break;
      case 'SET_USERS':
        draft.users = action.value;
        draft.userListLoading = false;
        draft.totalUsers = action.count;
        break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        break;
      case 'SET_PERMISSIONS':
        // draft.roles = action.value;
        draft.roles = action.value.map((role) => ({
          key: role.id,
          value: role.id,
          text: role.name,
        }));
        draft.roles.unshift([{ key: '', value: '', text: '' }]);
        // draft.roleList = action.value.map(role => role.name);
        break;
      case 'SHOW_ROLES':
        draft.roleStatus = !draft.roleStatus;
        break;
      case 'SET_CURRENT_USER':
        draft.currentUser = action.index;
        break;
      case 'SET_CREATE':
        draft.createStatus = action.value;
        break;
      case 'SET_EDIT':
        draft.editStatus = action.value;
        break;
    }
  });

const Users = (props) => {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const {
    userListLoading,
    users,
    totalUsers,
    currentPage,
    roles,
    editStatus,
    createStatus,
    currentUser,
  } = state;

  const setCurrent = (idx) => {
    dispatch({ type: 'SET_CURRENT_USER', index: idx });
    dispatch({ type: 'SET_EDIT', value: true });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      render: (value, data, idx) => (
        <NameField
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setCurrent(idx);
          }}
        >
          {value}
          <Icon size="large" name="pencil alternate" />
        </NameField>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      width: 120,
    },
    {
      title: 'Role',
      dataIndex: 'roles',
      key: 'roles',
      width: 90,
      render: (value, data) => {
        const codeName = value.length ? value[0].name : '';
        return <span>{codeName}</span>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      width: 90,
      render: (value, row, index) => (
        <NormalButton
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            editActiveStatus(index);
          }}
          disabled={row.email === localStorage.getItem('loggedUser')}
          active={value}
        >
          {value === true ? 'Deactivate' : 'Activate'}
        </NormalButton>
      ),
    },
  ];

  const getUserList = useCallback(() => {
    fetchUsers(currentPage).then((response) => {
      dispatch({ type: 'SET_USERS', value: response.data.results, count: response.data.count });
    });
  }, [currentPage]);

  useEffect(() => {
    getUserList();
  }, [getUserList]);

  useEffect(() => {
    fetchPermissions().then((response) => {
      dispatch({ type: 'SET_PERMISSIONS', value: response.data.results });
    });
  }, []);

  const closeModal = () => {
    dispatch({ type: 'SET_EDIT', value: false });
    dispatch({ type: 'SET_CREATE', value: false });
  };

  const editUser = ({ values, setSubmitting }) => {
    setSubmitting(true);
    const payload = trimObjectValues(values, { removeBlanks: true });
    if (!payload.password || !payload.password.length) {
      delete payload.password;
    }
    payload.roles =
      typeof payload.role === 'string'
        ? [parseInt(payload.role, 10)]
        : [parseInt(payload.role.key, 10)];
    updateUser(payload)
      .then(() => {
        getUserList();
        ToastsStore.success('User updated successfully.');
        setSubmitting(false);
        closeModal();
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        const errorMsg =
          typeof error.response.data === 'object'
            ? Object.values(error.response.data)[0]
            : 'Unable to update user.';
        ToastsStore.error(errorMsg);
        setSubmitting(false);
      });
  };

  const editActiveStatus = (index) => {
    const values = {
      ...users[index],
      is_active: !users[index].is_active,
      roles: [parseInt(users[index].roles[0].id, 10)],
    };
    updateUser(values)
      .then(() => {
        ToastsStore.success('User updated successfully.');
        getUserList();
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        const errorMsg =
          typeof error.response.data === 'object'
            ? Object.values(error.response.data)[0]
            : 'Unable to update user.';
        ToastsStore.error(errorMsg);
        // setSubmitting(false);
      });
  };

  const createUserModal = () => {
    dispatch({ type: 'SET_CREATE', value: true });
  };

  const createUser = ({ values, setSubmitting }) => {
    setSubmitting(true);
    const payload = trimObjectValues(values, { removeBlanks: true });
    payload.roles = [parseInt(payload.roles, 10)];
    createNewUser(payload)
      .then(() => {
        ToastsStore.success('User created successfully.');
        getUserList();
        setSubmitting(false);
        closeModal();
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        const errorMsg =
          typeof error.response.data === 'object'
            ? Object.values(error.response.data)[0]
            : 'Unable to create user.';
        ToastsStore.error(errorMsg);
        setSubmitting(false);
      });
  };

  return (
    <Layout location={props.location}>
      <Helmet>
        <title>XND | Manage Users</title>
      </Helmet>
      <ButtonWrapper>
        <AddUser
          icon="add user"
          content="Add User"
          positive
          labelPosition="right"
          onClick={createUserModal}
        />
      </ButtonWrapper>
      <ListWrapper>
        {!userListLoading ? (
          <TableView
            tableColumns={columns}
            data={users}
            pagination
            totalItems={totalUsers}
            activePage={currentPage}
            pageSize={10}
            onPageChange={(value) => dispatch({ type: 'SET_CURRENT_PAGE', value })}
          />
        ) : (
          <Loader active={userListLoading} />
        )}
      </ListWrapper>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
      {editStatus && (
        <Modal open={editStatus} onClose={closeModal} closeIcon>
          <Modal.Header>Edit User</Modal.Header>
          <UserForm
            user={{
              ...users[currentUser],
              role: users[currentUser].roles.map((role) => ({
                key: role.id,
                value: role.id,
                text: role.name,
              }))[0],
            }}
            editUser={editUser}
            closeModal={closeModal}
            roles={roles}
          />
        </Modal>
      )}
      {createStatus && (
        <Modal open={createStatus} onClose={closeModal} closeIcon>
          <Modal.Header>Add User</Modal.Header>
          <CreateUserForm createUser={createUser} closeModal={closeModal} roles={roles} />
        </Modal>
      )}
    </Layout>
  );
};

Users.propTypes = {
  location: PropTypes.object,
};

export default Users;
