import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, Field, ErrorMessage } from 'formik';
import styled from 'styled-components';
import { Button, Icon, Form } from 'semantic-ui-react';

const CustomField = styled(Field)`
    border: ${param => (param.error && '1px solid #cc0000 !important')};
`;

const FormFields = styled.div`
    input {
        margin-top: 10px !important;
    }
`;

const Label = styled.span`
  font-size: 17px;
  color: #000;
  line-height: 12px;
  margin-bottom: 20px;
  display: block;
  font-weight: 800;
  margin-top: 20px;
`;

const FieldSelect = styled(Field)`
    height: 40px !important;
    margin-top: 10px !important;
    border: #85b7d9;
    &:focus {
        outline: none;
    }
`;

const ButtonWrapper = styled.div`
    margin-top: 25px;
`;

const FieldError = styled.span`
    font-size: 12px;
    line-height: 12px;
    margin-top: 2px;
    color: #cc0000;
    font-weight: 300;
`;

const initialValue = {
    url: '',
    title: '',
    categoryId: '',
};

const CreateNewsFeedForm = props => {
    const redirectPath = props.feedState.url ? `/board-details/${props.feedState.feed_categories[0].id}/sources/` : '/boards';
    return (
            <Formik
                initialValues={props.feedState || initialValue}
                validate={validate}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    props.saveFeeds({ values, setSubmitting, resetForm });
            }}>
                {({ handleSubmit, errors, touched, handleChange, values }) => (
                    <Form onSubmit={handleSubmit}>
                        <FormFields>
                            <Label htmlFor="title">Title:</Label>
                            <CustomField name="title" type="text" placeholder="title" error={errors.title && touched.title}/>
                            <FieldError>
                                <ErrorMessage name="title" />
                            </FieldError>
                            <Label htmlFor="url">Link:</Label>
                            <CustomField disabled={props.feedState.url} name="url" type="text" placeholder="url" error={errors.url && touched.url}/>
                            <FieldError>
                                <ErrorMessage name="url" />
                            </FieldError>
                            {!props.feedState.url && (
                                <>
                                    <Label htmlFor="categoryId">Board:</Label>
                                    <FieldSelect
                                        as="select"
                                        name="categoryId"
                                        value={values.categoryId}
                                        onChange={handleChange}
                                    >
                                        {props.categories && props.categories.map(category => (
                                            <option value={category.id} key={category.id}>{category.title}</option>
                                        ))}
                                    </FieldSelect>
                                    <FieldError>
                                        <ErrorMessage name="categoryId" />
                                    </FieldError>
                                </>
                            )}
                        </FormFields>
                        <ButtonWrapper>
                            <Button positive type="submit">
                                <Icon name='save' /> Save
                            </Button>
                            <Button as={Link} style={{ marginLeft: 20 }} to={redirectPath}>
                                <Icon name='remove' /> Cancel
                            </Button>
                        </ButtonWrapper>
                    </Form>
                )}
            </Formik>
    )
};

const validate = values => {
    const errors = {};
    const urlPattern = /(http(s?):)\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
    if (!values.title || !values.title.trim().length) {
      errors.title = 'Please enter title';
    }
    if (!values.url || !values.url.match(urlPattern)) {
        errors.url = 'Please enter valid link';
    }
    return errors;
  };

export default CreateNewsFeedForm;