/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, { useReducer, useEffect, useCallback, useRef, useState } from 'react';
import {
  Image,
  Input,
  Icon,
  Button,
  Checkbox,
  Loader,
  Modal,
  Menu,
  Dropdown,
  Message,
} from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
// import renderHTML from 'react-render-html';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
// import { SketchPicker } from 'react-color';
import produce from 'immer';
import moment from 'moment';
import PropTypes from 'prop-types';
import Datetime from 'react-datetime';
import TextareaAutosize from 'react-textarea-autosize';
import 'react-datetime/css/react-datetime.css';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import FilterTagModal from 'Container/Dashboard/FilterTagModal';
import AddTagsModal from '../../Components/AddTagsModal';
import RemoveTagsModal from '../../Components/RemoveTagsModal';
import Layout from '../../Components/Layout';
import FilterPills from './FilterPills';
import Pagination from '../../Components/Pagination';
import {
  fetchDigestsData,
  deleteDigestApi,
  copyDigestApi,
  fetchCategoriesData,
  fetchDigestType,
  updateDigest,
  fetchSources,
  fetchUsers,
  editSources,
  saveDigests,
  editTag,
  getDigestScore,
  fetchAllStatuses,
  fetchUserListFull,
  sendNotificationApi,
  fetchProgress,
  fetchLabels,
  addLablesDigest,
  removeTags,
} from '../../Api';
import TagsPills from './TagsPills';

import TableView from '../../Components/TableView';
import Pills from '../../Components/Pills';
import Slider from '../../Components/Slider';
import MobileList from './MobileList';
import TagsModal from '../Dashboard/TagsModal';
import TagPillsFilter from './TagPillsFilter';
import TwitterPlayer from 'Components/TwitterPlayer';

const ImageWrapper = styled.div`
  min-height: 50px;
  overflow: hidden;
  .twitter-embed{
    position:unset;
  }
  .twitter-embed{
    position:unset;
  }
`;

const EditIconWrapper = styled.div`
  cursor: pointer;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 6%;
`;

const DatePickerScroller = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  height: 150px;
`;

const SearchWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .input {
    width: 85%;
    border: 2px solid rgba(34, 36, 38, 0.15);
    border-radius: 0.28571429rem;

    @media (max-width: 768px) {
      width: 79%;
    }
  }
  input {
    border: none !important;
  }

  @media (max-width: 580px) {
    display: block;

    .input {
      width: 100%;
    }
  }
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  i {
    color: ${({ disabled }) => (disabled ? '#a2a2a2' : '#52575a')};
  }
  padding: 0 5px;
`;

const NewTabIconWrapper = styled(Link)`
  padding: 0 5px;
  display: block;
  i {
    color: rgba(0, 0, 0, 0.77) !important;
  }
`;

const MenuWrapper = styled(Menu)`
  padding: 0 5px;
  background: transparent !important;
  border: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  > a {
    padding: 0 !important;
    background: transparent !important;
  }
  .item:before {
    background: transparent !important;
  }
`;

const ListWrapper = styled.div`
  .ui.checkbox {
    min-height: 14px;
  }
`;

const PillWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 15px;
  font-weight: bold;
`;

const TableWrapper = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }
`;

const SliderWrapperContainer = styled.div`
  margin: 15px 0;

  @media (max-width: 768px) {
    margin: 5px 0;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 10px 0;
  ${({ fixed }) =>
    fixed
      ? `
    box-shadow: 0 2px 2px -2px rgba(0,0,0,0.15);
  `
      : ``}
  .slick-prev:before, .slick-next:before {
    color: #000;
  }
  .slick-slide {
    outline: none !important;
  }
  .slick-arrow:before {
    display: none;
  }
  .slick-prev,
  .slick-next {
    height: auto;
  }
  .slick-next {
    right: -40px;
  }
`;

const SliderContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  text-transform: uppercase;
  max-width: 95%;
  @media (max-width: 1024px) {
    width: 95%;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
  @media (max-width: 768px) {
    width: 100%;
    /* padding: 0 30px; */
  }
`;

const TableHeader = styled.div`
  height: 50px;
  left: 53px;
  width: calc(100% - 60px);
  background-color: #fff;
  z-index: 5;
  display: flex;
  align-items: center;
  padding: 5px;
  @media (max-width: 768px) {
    height: 100%;
    display: block;
    width: 100%;
    left: 0;
    text-align: center;
  }
`;

const LabelSelected = styled.span`
  background: ${({ color }) => color};
  border: ${({ color }) => `4px solid ${color}`};
  width: 50px;
  margin-right: 10px;
  border-radius: 13px;
  margin-bottom: 7px;
`;

const Flex = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin: 10px auto;
  }
`;

const TagFlex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  @media (max-width: 768px) {
    margin: 10px auto;
  }
`;

const TableHeaderDelete = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
  cursor: pointer;
  span {
    padding: 0 5px;
  }
  @media (max-width: 768px) {
    height: 100%;
    display: block;
    margin-left: 10px;
    font-size: 12px;
    > span {
      height: 1.6em;
    }
    span {
      display: block;
    }
  }
`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 200;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 30px;
  .rc-pagination-prev,
  .rc-pagination-next,
  rc-pagination-item-active {
    outline: none;
    border-color: transparent;
  }
`;

const FlexFields = styled.div`
  display: flex;
  margin: 30px 20px;
  align-items: center;
  > div {
    color: ${({ error }) => (error ? '#FF5722' : 'rgba(0,0,0,.87)')} !important;
  }
  input,
  .ui.selection.dropdown {
    border: ${({ error }) =>
      error ? '1px solid #FF5722' : '1px solid rgba(34,36,38,.15)'} !important;
  }
  .ui.selection.dropdown .menu > .item {
    // line-height: 1.5;
  }
`;

const Label = styled.div`
  margin: ${({ m }) => m || 0};
  margin-right: 30px;
  color: ${({ error }) => (error ? '#FF5722' : 'rgba(0,0,0,.87)')} !important;
  width: ${({ w }) => w || '20%'};
`;

const Container = styled.div`
  display: grid;
  width: 100%;
`;

const DateTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600 !important;
  font-size: 16px !important;
  // border-left: 2px solid #d4d4d5;
  margin-top: 30px;
  .rdt {
    width: auto
    height: 100%;
    input {
      width: 100%;
      height: 100%;
      font-family: Metropolis, sans-serif !important;
      text-transform: uppercase;
      border: 1px solid rgba(34,36,38,.15) !important;
      color: rgba(0,0,0,.87) !important;
      border-radius: .28571429rem;
      outline: none !important;
      padding: 15px !important;
    }
  }
  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const FlexIcon = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
`;

const TitleField = styled(TextareaAutosize)`
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15) !important;
  color: rgba(0, 0, 0, 0.8) !important;
  padding: 0.67857143em 1em !important;
  overflow: hidden;
  border-radius: 0.28571429rem;
`;

const DownArrow = styled.div`
  width: 0;
  height: 0;
  border-top: solid 7px #000;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
  margin-left: 5px;
`;

const UpArrow = styled.div`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid black;
  margin-left: 5px;
`;

const CustomDiv = styled.div``;
const FilterCustomDiv = styled.div`
  @media (max-width: 580px) {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
`;

const CustomDropdown = styled.div`
  padding: 10px;
  background-color: #cacbcd;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgb(34 36 38 / 15%) inset;
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
`;

const DropdownList = styled.div`
  height: 200px;
  overflow: scroll;
  position: absolute;
  top: 40px;
  z-index: 10;
  background: #fff;
  width: 200px;
  border: 2px solid #ccc;
  border-radius: 5px;
  right: 0;
`;

const DropdownItemMain = styled(Dropdown.Item)`
  margin: 10px;
  .input {
    width: 100%;
  }
`;

const CustomButton = styled(Button)`
  padding: 5px !important;
  width: 25px;
  background: transparent !important;
  i {
    margin: 0 !important;
  }
`;

const LabelButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
`;

const FlexButtons = styled.div`
  justify-content: flex-end;
`;

const DropdownColor = styled.div`
  border-radius: 6px;
  // width: 75%;
  width: 100%;
  // margin-right: 20px;
  height: 25px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  span {
    text-shadow: 1px 0px #fff;
    color: #fff;
    margin-left: 5px;
  }
  i {
    color: ${({ selected }) => (selected ? '#fff' : 'transparent')};
    margin-right: 5px;
  }
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const Status = styled(Message)`
  padding: 0.5em 1em !important;
  margin: 0.3em 0 !important;
`;

const TagStatus = styled.div`
  display: flex;
  align-items: center;
  margin: 4px 2px 0 0;
`;

const StickyFields = styled.div`
  position: sticky;
  top: 70px;
  padding-top: 15px;
  z-index: 10;
  background: #fff;
`;
const TwitterPic = styled.div`
.twitter-embed{
  position:unset;
}
iframe{
  width:60px !important;
  height:60px !important;
}
`;

// const MoreButton = styled.div`
//   cursor: pointer;
//   color: #096dd9;
//   float: right;
//   font-weight: bold;
// `;

// const Description = styled.div`
//   white-space: break-spaces;
// `;

// const eligibleCodes = ['250', '200', '225', '230', '255', '275', '300'];
// const videoCodes = ['255'];
// const newsCodes = ['250', '200', '225', '230', '275', '300']
const urlParams = new URLSearchParams(window.location.search);
let paramObj = {};
for (var value of urlParams.keys()) {
  if(value !== "notificationSource" && value !== "notificationPackages")
  paramObj[value] = urlParams.get(value);
}

const initialValue = {
  digestsList: [],
  digestLoading: true,
  currentPage: 1,
  digestsTotalCount: 0,
  sortOrder: 'desc',
  sortBy: 'first_published_at',
  searchQuery: '',
  search: '',
  suggestedFilters: {},
  selectedDigestsId: [],
  selectedLabelsTemp: [],
  selectAllDigest: false,
  deleteModal: null,
  openDeleteModal: false,
  openEditTagModal: false,
  editTagsData: null,
  digestModalStatus: false,
  digest: {},
  digestStatus: '',
  deleteDigestsId: [],
  deleteDigestsLoading: false,
  categoriesList: [],
  digestType: [],
  sources: [],
  authors: [],
  statuses: [],
  filterObject: { ...paramObj },
  edit: {},
  filterLoading: false,
  errors: {},
  editable: {},
  relevenceEditable: {},
  currentLabels: [],
  searchLabel: '',
  searchMain: '',
  mainLabels: [],
  quality: '',
  relevanceScore: '',
  filteredTags: '',
  filteredRegions: '',
};

// const EditorScoreOptions = [
//   {
//     key: 0,
//     value: 0,
//     text: 0,
//   },
//   {
//     key: 1,
//     value: 1,
//     text: 1,
//   },
//   {
//     key: 2,
//     value: 2,
//     text: 2,
//   },
//   {
//     key: 3,
//     value: 3,
//     text: 3,
//   },
//   {
//     key: 4,
//     value: 4,
//     text: 4,
//   },
//   {
//     key: 5,
//     value: 5,
//     text: 5,
//   },
// ];

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_DIGESTS_LIST':
        draft.digestsList = action.value;
        draft.digestLoading = false;
        draft.filterLoading = false;
        draft.digestsTotalCount = action.totalCount;
        draft.selectedDigestsId = [];
        draft.selectAllDigest = false;
        draft.suggestedFilters = {};
        if (action.suggestedFilters) {
          draft.suggestedFilters = action.suggestedFilters;
        }
        break;
      // case 'SHOW_COLOR_PICKER':
      //   draft.showPicker = !draft.showPicker;
      //   break;
      // case 'SHOW_EDIT':
      //   draft.editLabel = action.value;
      //   break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        draft.selectedDigestsId = [];
        draft.selectAllDigest = false;
        break;
      case 'SET_SORT':
        if (action.sortBy.dataIndex === draft.sortBy) {
          draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          draft.sortOrder = 'asc';
          draft.sortBy = action.sortBy.dataIndex;
        }
        break;
      case 'SET_DIGEST_LOADING':
        draft.digestLoading = action.value;
        break;
      case 'SET_EDIT':
        draft.edit = action.value;
        draft.current = action.value.title ? 'title' : 'brand_name';
        break;
      case 'SET_SEARCH_QUERY':
        draft.searchQuery = action.value;
        if (!action.value) {
          draft.search = '';
          draft.currentPage = 1;
        }
        break;
      case 'SEARCH_DIGEST':
        draft.search = action.value;
        draft.currentPage = 1;
        break;
      case 'SET_SELECTED_DIGEST': {
        const selectedDigestsIdData = draft.selectedDigestsId;
        const index = selectedDigestsIdData.findIndex((data) => data === action.value.id);
        if (index > -1) {
          selectedDigestsIdData.splice(index, 1);
          draft.selectAllDigest = false;
        } else {
          selectedDigestsIdData.push(action.value.id);
        }
        if (selectedDigestsIdData.length === 1) {
          const selectedDigest = draft.digestsList.find(
            (data) => data.id === selectedDigestsIdData[0],
          );
          draft.currentLabels = selectedDigest.labels.map((label) => label.id);
          draft.checkedDigest = selectedDigest;
        }
        draft.selectedDigestsId = selectedDigestsIdData;
        break;
      }
      case 'SET_SELECT_ALL_DIGESTS':
        if (draft.selectAllDigest) {
          draft.selectAllDigest = false;
          draft.selectedDigestsId = [];
        } else if (
          draft.selectedDigestsId.length > 0 &&
          draft.selectedDigestsId.length <= draft.digestsTotalCount
        ) {
          draft.selectAllDigest = false;
          draft.selectedDigestsId = [];
        } else {
          draft.selectAllDigest = true;
          draft.selectedDigestsId = draft.digestsList.map((item) => item.id);
        }
        break;
      case 'SET_DELETE_MODAL':
        draft.openDeleteModal = action.value;
        if (action.value) {
          draft.deleteDigestsId = action.deleteDigestsId;
        } else {
          draft.deleteDigestsId = null;
          draft.deleteDigestsLoading = false;
        }
        break;
      case 'SET_TAG_EDIT_MODAL':
        draft.openEditTagModal = action.value;
        if (action.value) {
          draft.editTagsData = { tags: action.tagsData, digestId: action.digestId };
        } else {
          draft.editTagsData = null;
        }
        break;
      case 'SET_COPY_MODAL':
        draft.openCopyModal = action.value;
        if (action.value) {
          draft.deleteDigestsId = action.deleteDigestsId;
        } else {
          draft.deleteDigestsId = null;
          draft.deleteDigestsLoading = false;
        }
        break;
      case 'SET_ADD_TAGS_MODAL':
        draft.openAddTagsModal = action.value;
        if (action.value) {
          draft.deleteDigestsId = action.deleteDigestsId;
        } else {
          draft.deleteDigestsId = null;
          draft.deleteDigestsLoading = false;
        }
        break;
      case 'SET_REMOVE_TAGS_MODAL':
        draft.openRemoveTagsModal = action.value;
        if (action.value) {
          draft.deleteDigestsId = action.deleteDigestsId;
        } else {
          draft.deleteDigestsId = null;
          draft.deleteDigestsLoading = false;
        }
        break;
      case 'SET_DELETE_DIGESTS_LOADING':
        draft.deleteDigestsLoading = action.value;
        break;
      case 'SET_CATEGORIES_LIST':
        if (action.value && action.value.length > 0) {
          const data = action.value.map((category) => ({
            text: category.title,
            value: category.id,
          }));
          draft.categoriesList = data;
          if (draft.selectedCategory.length) {
            const categorySelected =
              data.filter((val) => draft.selectedCategory.includes(val.value)) || [];
            draft.filterObject.selectedCategory = categorySelected.slice();
          }
        }
        break;
      case 'SET_TYPE': {
        const data = action.value.map((digestType) => ({
          text: `${digestType.code}-${digestType.title}`,
          value: `${digestType.code}-${digestType.title}`,
        }));
        if (data.length) data.unshift({ type: '', value: '' });
        draft.digestType = data;
        break;
      }
      case 'SET_SOURCES': {
        const source = action.value.map((sources) => ({
          text: `${sources.brand_name}`,
          value: `${sources.host_name}`,
        }));
        if (source.length) {
          source.unshift({ type: '', value: '' });
          if (draft.selectedSource) {
            const sourceSelected =
              source.filter((val) => val.value === draft.selectedSource) || null;

            draft.filterObject.selectedSource = sourceSelected[0].text;
          }
        }
        draft.sources = source;

        break;
      }
      case 'SET_QUALITY_VALUE': {
        const quality = action.value;
        draft.filterObject.quality = quality;
        draft.quality = quality;

        break;
      }
      case 'SET_RELEVANCE_SCORE': {
        const relevanceScore = action.value;
        draft.filterObject.relevanceScore = relevanceScore;
        draft.relevanceScore = relevanceScore;

        break;
      }

      case 'SET_FILTERS_TAGS': {
        const filteredTags = action.value;
        draft.filteredTags = filteredTags;
        break;
      }

      case 'SET_FILTERS_REGIONS': {
        const filteredRegions = action.value;
        draft.filteredRegions = filteredRegions;
        break;
      }

      case 'SET_AUTHORS': {
        const authors = action.value.map((val) => ({
          text: `${val.name}`,
          value: `${val.id}`,
        }));
        if (authors.length) {
          authors.unshift({ type: '', value: '' });
          if (draft.selectedAuthor) {
            const authorSelected =
              authors.filter((val) => val.value === draft.selectedAuthor) || null;
            draft.filterObject.selectedAuthor = authorSelected[0].text;
          }
        }
        draft.authors = authors;
        break;
      }
      case 'SET_SELECTED_CATEGORY':
        draft.selectedCategory = action.value;
        if (action.value && action.value.length) {
          draft.filterObject.selectedCategory = [];
          draft.filterObject.selectedCategory = action.options;
        } else if (
          draft.filterObject.selectedCategory &&
          draft.filterObject.selectedCategory.length
        )
          delete draft.filterObject.selectedCategory;
        draft.currentPage = 1;
        break;
      case 'SET_SELECTED_SOURCE':
        draft.selectedSource = action.value;
        if (
          action.option &&
          (!draft.filterObject.selectedSource ||
            action.option.text !== draft.filterObject.selectedSource)
        ) {
          draft.filterObject.selectedSource = action.option.text;
        } else if (draft.filterObject.selectedSource) delete draft.filterObject.selectedSource;
        draft.currentPage = 1;
        break;
      case 'SET_SELECTED_AUTHOR':
        draft.selectedAuthor = action.value;
        if (
          action.option &&
          (!draft.filterObject.selectedAuthor ||
            action.option.text !== draft.filterObject.selectedAuthor)
        ) {
          draft.filterObject.selectedAuthor = action.option.text;
        } else if (draft.filterObject.selectedAuthor) delete draft.filterObject.selectedAuthor;
        draft.currentPage = 1;
        break;
      // case 'SET_SELECTED_AUTHOR':
      //   draft.selectedAuthor = action.value;
      //   draft.currentPage = 1;
      //   break;

      case 'SET_SELECTED_QUALITY':
        draft.quality = action.value;

        if (draft.filterObject.quality !== undefined) delete draft.filterObject.quality;
        draft.currentPage = 1;
        break;

      case 'SET_SELECTED_RELEVANCE':
        draft.relevanceScore = action.value;

        if (draft.filterObject.relevanceScore !== undefined)
          delete draft.filterObject.relevanceScore;
        draft.currentPage = 1;
        break;

      case 'SET_SELECTED_STATUS':
        draft.selectedStatus = draft.selectedStatus === action.value ? '' : action.value;
        if (!draft.selectedStatus || draft.selectedStatus === 'published') {
          draft.sortBy = 'first_published_at';
        } else {
          draft.sortBy = 'modified_at';
        }
        draft.currentPage = 1;
        break;
      case 'SET_SELECTED_TYPE':
        draft.selectedType = action.value;
        draft.currentPage = 1;
        break;
      case 'DRAFT_MODAL_STATUS':
        draft.digestModalStatus = action.digestModalStatus;
        draft.digestStatus = action.digestStatus !== 'Published' ? 'published' : 'saved to draft';
        draft.digest = action.digest;
        draft.updateDigestLoading = false;
        break;
      // case 'SHOW_STATUS':
      //   if (draft.more.includes(action.index))
      //    draft.more = draft.more.filter(val => val !== action.index);
      //   else draft.more.push(action.index);
      //   break;
      case 'SET_STATUSES':
        draft.statuses = action.value;
        draft.totalStatus = action.total;
        break;
      case 'RESET_FILTERS':
        draft.selectedCategory = [];
        draft.selectedSource = null;
        draft.selectedAuthor = null;
        draft.selectedStatus = null;
        draft.selectedType = null;
        draft.search = '';
        draft.searchQuery = '';
        draft.quality = null;
        draft.relevanceScore = null;
        draft.filteredTags = '';
        draft.filteredRegions = '';
        break;
      case 'SET_UPDATE_DIGESTS_LOADING':
        draft.updateDigestLoading = action.value;
        break;
      case 'SET_FILTER':
        draft.filterObject = action.value;
        break;
      case 'SET_FILTER_LOADING':
        draft.filterLoading = action.value;
        draft.digestLoading = true;
        break;
      case 'SEND_NOTIFICATION':
        draft.sendNotificationModal = action.value;
        draft.digest = { ...action.digest, text: 'Alert', bottom_tab_index: '1' };
        draft.scheduleNotification = false;
        draft.scheduledTime = null;
        break;
      case 'SET_USER_LIST':
        draft.userList = action.value;
        break;
      case 'SET_DIGEST':
        draft.digest = action.value;
        break;
      case 'SET_ERRORS':
        draft.errors = action.value;
        break;
      case 'SCHEDULE_NOTIF':
        if (!draft.scheduleNotification)
          draft.scheduledTime = action.time || moment(new Date()).add(5, 'minutes');
        else draft.scheduledTime = null;
        draft.scheduleNotification = !draft.scheduleNotification;
        break;
      case 'SET_DATE_TIME':
        draft.scheduledTime = action.value;
        break;
      case 'CONFIRM_MODAL': {
        draft.confirm = !draft.confirm;
        draft.selectedType = action.value;
        if (action.submit) draft.submit = action.submit;
        else draft.submit = '';
        break;
      }
      case 'SHOW_PROGRESS':
        draft.showProgressModal = action.open;
        draft.currentStatus = action.value;
        draft.progress = { percentage: 0, total: 0, success_count: 0, fail_count: 0 };
        break;
      case 'SHOW_PROGRESS_STATUS':
        draft.progress = action.value;
        break;
      case 'SET_NOTIF_TYPE':
        draft.testing = action.valType;
        break;
      case 'SET_EDITABLE':
        if (action.val)
          draft.editable = {
            value: action.data.quality || 0,
            id: action.data.id,
          };
        else draft.editable = {};
        break;
      case 'SET_RELEVANCED_EDITABLE':
        if (action.val)
          draft.relevenceEditable = {
            value: action.data.quality || 0,
            id: action.data.id,
          };
        else draft.relevenceEditable = {};
        break;
      case 'SET_EDITABLE_VALUE': {
        // if (draft.editable)
        draft.editable = {
          value: action.val,
          id: action.data.id,
        };
        //   );
        // else draft.editable[index].value = action.val;
        break;
      }
      case 'SET_RELEVENCE_EDITABLE_VALUE': {
        // if (draft.editable)
        draft.relevenceEditable = {
          value: action.val,
          id: action.data.id,
        };
        //   );
        // else draft.editable[index].value = action.val;
        break;
      }
      case 'SET_LABEL':
        if (action.field === 'main' || action.field === 'both') draft.mainLabels = action.value;
        if (action.field !== 'main' || action.field === 'both') draft.labels = action.value;
        if (action.field === 'both') draft.allLabels = action.value;
        if (draft.selectedLabels.length) {
          const labelSelected =
            action.value.filter((val) => draft.selectedLabels.includes(val.key)) || [];
          draft.filterObject.selectedLabels = labelSelected.slice();
          draft.selectedLabelsTemp = labelSelected.slice();
        }
        break;
      case 'SHOW_FILTER_LABELS':
        if (!draft.showFilterLabels) draft.mainLabels = draft.allLabels;
        draft.showFilterLabels = !draft.showFilterLabels;
        draft.showLabels = false;
        draft.currentLabels = [];
        break;
      case 'SET_FILTER_LABELS':
        if (action.options && action.options.length) {
          draft.filterObject.selectedLabels = [];
          draft.filterObject.selectedLabels = action.options;
          draft.selectedLabels = action.options.map((option) => option.key);
        } else if (draft.filterObject.selectedLabels && draft.filterObject.selectedLabels.length) {
          delete draft.filterObject.selectedLabels;
          draft.selectedLabels = [];
        }
        draft.currentPage = 1;
        draft.showFilterLabels = false;
        break;
      case 'SHOW_LABELS':
        draft.showFilterLabels = false;
        draft.selectedLabelsTemp = draft.filterObject.selectedLabels || [];
        if (!draft.showLabels) draft.labels = draft.allLabels;
        draft.showLabels = action.value;
        draft.editedLabel = true;
        draft.currentLabels = draft.checkedDigest.labels.map((label) => label.id);
        // if (action.labels) {
        //   draft.currentLabels = action.labels;
        // }
        break;
      case 'SET_CURRENT_LABELS':
        if (!draft.currentLabels.includes(action.value)) draft.currentLabels.push(action.value);
        else draft.currentLabels.splice(draft.currentLabels.indexOf(action.value), 1);
        draft.editedLabel = true;
        break;
      case 'SET_SCORE_LIST':
        draft.scoreList = action.value;
        draft.scoreRelevance = action.scoreRelevance;
        draft.digestLoading = false;
        break;
      case 'SET_SELECTED_LABEL':
        draft.selectedLabelsTemp = action.options;
        break;
      case 'RESET_LABELS':
        draft.currentLabels = [];
        break;
      case 'SET_SEARCH_LABEL':
        if (action.field === 'main') draft.searchMain = action.value;
        else draft.searchLabel = action.value;
        break;
    }
  });

const dynamicFields = [
  {
    key: 1,
    value: '1',
    text: 'Briefing',
  },
  // {
  //   key: 0,
  //   value: '0',
  //   text: 'Discover',
  // },
  {
    key: 2,
    value: '2',
    text: 'DailyBrief TV',
  },
  {
    key: 3,
    value: '3',
    text: 'Notification',
  },
];

let typingTimeout;

const Digests = ({ location }) => {
  const [openTagModal, setOpenTagModal] = useState(false);
  const [slectedTags, setSlectedTags] = useState([]);
  const [selectedRegions, setSelectedRegions] = useState([]);
  const [filterTagStrings, setFilterTagStrings] = useState('');
  const [filterRegionStrings, setFilterRegionStrings] = useState('');
  const history = useHistory();
  const querySelectedStatus = queryString.parse(location.search).selectedStatus;
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    selectedCategory: queryString.parse(location.search).selectedCategory
      ? queryString
          .parse(location.search)
          .selectedCategory.split(',')
          .map((val) => parseInt(val, 10))
      : [],
    selectedLabels: queryString.parse(location.search).selectedLabels
      ? queryString
          .parse(location.search)
          .selectedLabels.split(',')
          .map((val) => parseInt(val, 10))
      : [],
    selectedSource: queryString.parse(location.search).selectedSource || '',
    quality: queryString.parse(location.search).quality || '',
    relevanceScore: queryString.parse(location.search).relevanceScore || '',
    filteredTags: queryString.parse(location.search).filteredTags || '',
    filteredRegions: queryString.parse(location.search).filteredRegions || '',

    selectedAuthor: queryString.parse(location.search).selectedAuthor || '',
    selectedStatus: queryString.parse(location.search).selectedStatus || '',
    sortBy:
      !querySelectedStatus || querySelectedStatus === 'published'
        ? 'first_published_at'
        : 'modified_at',
    selectedType: queryString.parse(location.search).selectedType || '',
    search: queryString.parse(location.search).search || '',
    searchQuery: queryString.parse(location.search).search || '',
    updateDigestLoading: false,
  });

  const {
    digestsList,
    digestLoading,
    digestsTotalCount,
    currentPage,
    sortBy,
    sortOrder,
    searchQuery,
    search,
    selectedDigestsId,
    selectAllDigest,
    openDeleteModal,
    openEditTagModal,
    openCopyModal,
    deleteDigestsId,
    deleteDigestsLoading,
    categoriesList,
    digestType,
    sources,
    authors,
    selectedCategory,
    selectedSource,
    selectedAuthor,
    selectedStatus,
    selectedType,
    digestStatus,
    digest,
    digestModalStatus,
    suggestedFilters,
    statuses,
    edit,
    current,
    totalStatus,
    updateDigestLoading,
    openAddTagsModal,
    filterObject,
    filterLoading,
    openRemoveTagsModal,
    sendNotificationModal,
    userList,
    scheduleNotification,
    scheduledTime,
    confirm,
    submit,
    editable,
    relevenceEditable,
    labels,
    currentLabels,
    checkedDigest,
    scoreList,
    scoreRelevance,
    showLabels,
    showFilterLabels,
    editedLabel,
    selectedLabels,
    selectedLabelsTemp,
    mainLabels,
    editTagsData,
    quality,
    relevanceScore,
    filteredTags,
    filteredRegions,
  } = state;

  const disabledStatus = ['COMPLETE', 'SCHEEDULED', 'IN_PROGRESS'];
  const [isMobile, setMobile] = useState(false);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [handleResize]);

  const sliderWrapperRef = useRef(null);
  const intervalRef = useRef(null);

  useEffect(() => {
    let searchStr = '';
    if (selectedCategory.length) searchStr = `?selectedCategory=${selectedCategory}`;
    if (selectedSource)
      if (!searchStr) searchStr = `?selectedSource=${selectedSource}`;
      else searchStr += `&selectedSource=${selectedSource}`;
    if (selectedAuthor)
      if (!searchStr) searchStr = `?selectedAuthor=${selectedAuthor}`;
      else searchStr += `&selectedAuthor=${selectedAuthor}`;

    ///
    if (quality)
      if (!searchStr) searchStr = `?quality=${quality}`;
      else searchStr += `&quality=${quality}`;
    if (relevanceScore)
      if (!searchStr) searchStr = `?relevanceScore=${relevanceScore}`;
      else searchStr += `&relevanceScore=${relevanceScore}`;

    if (filteredTags)
      if (!searchStr) searchStr = `?filteredTags=${filteredTags}`;
      else searchStr += `&filteredTags=${filteredTags}`;

    ///

    if (filteredRegions)
      if (!searchStr) searchStr = `?filteredRegions=${filteredRegions}`;
      else searchStr += `&filteredRegions=${filteredRegions}`;

    ///

    if (selectedStatus)
      if (!searchStr) searchStr = `?selectedStatus=${selectedStatus}`;
      else searchStr += `&selectedStatus=${selectedStatus}`;
    if (selectedType)
      if (!searchStr) searchStr = `?selectedType=${selectedType}`;
      else searchStr += `&selectedType=${selectedType}`;

    if (search)
      if (!searchStr) searchStr = `?search=${search}`;
      else searchStr += `&search=${search}`;
    if (selectedLabels.length)
      if (!searchStr) searchStr = `?selectedLabels=${selectedLabels}`;
      else searchStr += `&selectedLabels=${selectedLabels}`;
    history.push({
      pathname: '/',
      search: searchStr,
    });
  }, [
    history,
    location.search,
    search,
    selectedCategory,
    selectedSource,
    selectedAuthor,
    selectedStatus,
    selectedType,
    selectedLabels,
    quality,
    relevanceScore,
    filteredTags,
    filteredRegions,
  ]);


  const filterDisgestbyTags = (tags, region) => {
    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
    setSlectedTags(tags);
    setSelectedRegions(region);
    const ids = tags.map((item) => item.id);
    const regionsIds = region.map((item) => item.id);
    const idString = ids.toString();
    const idRegionString = regionsIds.toString();
    const dyanamicKeys = {};
    dyanamicKeys.filterTags = idString;
    dyanamicKeys.filterRegions = idRegionString;
    dispatch({ type: 'SET_FILTERS_TAGS', value: idString });
    dispatch({ type: 'SET_FILTERS_REGIONS', value: idRegionString });
    setFilterTagStrings(idString);
    setFilterRegionStrings(idRegionString);
    dispatch({ type: 'SET_CURRENT_PAGE', value: 1 });
    fetchDigestsData({
      page: 1,
      sortBy,
      sortOrder,
      search,
      size: 10,
      ...dyanamicKeys,
      editorScoreRelevanceTerm: '',
      editorScoreQuality: '',
      // changeStats,
    })
      .then((response) => {
        dispatch({
          type: 'SET_DIGESTS_LIST',
          value: response.data.results,
          totalCount: response.data.count,
          suggestedFilters: response.data.suggested_filters,
        });
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        if (response.data.status_statistics && response.data.status_statistics.results) {
          dispatch({
            type: 'SET_STATUSES',
            value: response.data.status_statistics.results,
            total: response.data.status_statistics.total,
          });
          dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        }
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        ToastsStore.error('Something went wrong!');
      });
    if (!digestType.length) {
      fetchDigestType().then((result) => {
        dispatch({ type: 'SET_TYPE', value: result.data.results });
      });
    }

    if (!sources.length) {
      fetchSources().then((result) => {
        dispatch({ type: 'SET_SOURCES', value: result.data.results });
      });
    }

    if (!authors.length) {
      fetchUsers(1, 50)
        .then((result) => {
          dispatch({ type: 'SET_AUTHORS', value: result.data.results });
        })
        .catch(() => {});
    }

    if (categoriesList && categoriesList.length === 0) {
      fetchCategoriesData()
        .then((response) => {
          dispatch({ type: 'SET_CATEGORIES_LIST', value: response.data.results });
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
          ToastsStore.error('Something went wrong!');
        });
    }
  };
  const clearFIlterDigest = (id, region) => {
    const filteredArr = slectedTags.filter((el) => el.id !== id);
    const filteredRegion = selectedRegions.filter((el) => el.region !== region);
    setSlectedTags(filteredArr);
    setSelectedRegions(filteredRegion);

    dispatch({ type: 'SET_DIGEST_LOADING', value: true });

    const ids = filteredArr.map((item) => item.id);
    const regionsIds = filteredRegion.map((item) => item.id);
    const idString = ids.toString();
    const idRegionString = regionsIds.toString();
    const dyanamicKeys = {};
    dyanamicKeys.filterTags = idString;
    dyanamicKeys.filterRegions = idRegionString;
    dispatch({ type: 'SET_FILTERS_TAGS', value: idString });
    dispatch({ type: 'SET_FILTERS_REGIONS', value: idRegionString });
    setFilterTagStrings(idString);
    setFilterRegionStrings(idRegionString);
    dispatch({ type: 'SET_CURRENT_PAGE', value: 1 });
    fetchDigestsData({
      page: 1,
      sortBy,
      sortOrder,
      search,
      size: 10,
      ...dyanamicKeys,
      // changeStats,
    })
      .then((response) => {
        dispatch({
          type: 'SET_DIGESTS_LIST',
          value: response.data.results,
          totalCount: response.data.count,
          suggestedFilters: response.data.suggested_filters,
        });
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        if (response.data.status_statistics && response.data.status_statistics.results) {
          dispatch({
            type: 'SET_STATUSES',
            value: response.data.status_statistics.results,
            total: response.data.status_statistics.total,
          });
          dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        }
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        ToastsStore.error('Something went wrong!');
      });
    if (!digestType.length) {
      fetchDigestType().then((result) => {
        dispatch({ type: 'SET_TYPE', value: result.data.results });
      });
    }

    if (!sources.length) {
      fetchSources().then((result) => {
        dispatch({ type: 'SET_SOURCES', value: result.data.results });
      });
    }

    if (!authors.length) {
      fetchUsers(1, 50)
        .then((result) => {
          dispatch({ type: 'SET_AUTHORS', value: result.data.results });
        })
        .catch(() => {});
    }

    if (categoriesList && categoriesList.length === 0) {
      fetchCategoriesData()
        .then((response) => {
          dispatch({ type: 'SET_CATEGORIES_LIST', value: response.data.results });
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
          ToastsStore.error('Something went wrong!');
        });
    }
  };
  const filterDisgestbyValue = (filterVal, type) => {
    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
    const dyanamicKeys = {};
    if (type === 'quality') {
      dispatch({ type: 'SET_QUALITY_VALUE', value: filterVal });
      dyanamicKeys.editorScoreQuality = filterVal;
    } else {
      dispatch({ type: 'SET_RELEVANCE_SCORE', value: filterVal });
      dyanamicKeys.editorScoreRelevanceTerm = filterVal;
    }
    dyanamicKeys.filterTags = filterTagStrings;
    dyanamicKeys.filterRegions = filterRegionStrings;

    dispatch({ type: 'SET_CURRENT_PAGE', value: 1 });
    fetchDigestsData({
      page: 1,
      sortBy,
      sortOrder,
      search,
      size: 10,
      // filterTags: filterTagStrings,
      // editorScoreQuality: 2,
      ...dyanamicKeys,

      // changeStats,
    })
      .then((response) => {
        dispatch({
          type: 'SET_DIGESTS_LIST',
          value: response.data.results,
          totalCount: response.data.count,
          suggestedFilters: response.data.suggested_filters,
        });
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        if (response.data.status_statistics && response.data.status_statistics.results) {
          dispatch({
            type: 'SET_STATUSES',
            value: response.data.status_statistics.results,
            total: response.data.status_statistics.total,
          });
          dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        }
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        ToastsStore.error('Something went wrong!');
      });
    if (!digestType.length) {
      fetchDigestType().then((result) => {
        dispatch({ type: 'SET_TYPE', value: result.data.results });
      });
    }

    if (!sources.length) {
      fetchSources().then((result) => {
        dispatch({ type: 'SET_SOURCES', value: result.data.results });
      });
    }

    if (!authors.length) {
      fetchUsers(1, 50)
        .then((result) => {
          dispatch({ type: 'SET_AUTHORS', value: result.data.results });
        })
        .catch(() => {});
    }

    if (categoriesList && categoriesList.length === 0) {
      fetchCategoriesData()
        .then((response) => {
          dispatch({ type: 'SET_CATEGORIES_LIST', value: response.data.results });
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
          ToastsStore.error('Something went wrong!');
        });
    }
  };
  const fetchDigestScores = async (id) => {
    getDigestScore(id)
      .then((response) => {
        dispatch({
          type: 'SET_SCORE_LIST',
          value: response.data.editor_score_quality.map((score) => ({
            key: score.score,
            value: score.score,
            text: score.title,
          })),
          scoreRelevance: response.data.editor_score_relevance.map((score) => ({
            key: score.score,
            value: score.score,
            text: score.title,
          })),
        });
      })
      .catch((error) => {
        ToastsStore.error('Unable to fetch digest list');
      });
  };
  const fetchData = useCallback(async () => {
    const response = await fetchUserListFull();
    const groups = response.data.results.map((val) => ({
      key: val.id,
      value: val.id,
      text: val.title,
    }));
    groups.unshift({
      key: 'channel_follower',
      value: 'channel_follower',
      text: 'Channel Followers Only',
    });
    groups.unshift({ key: 'all', value: 'all', text: 'All' });
    dispatch({
      type: 'SET_USER_LIST',
      value: groups,
    });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const clearDigestsListFilter = () => {
    dispatch({ type: 'RESET_FILTERS' });
  };

  // prevent typing of alt+c
  const handleKeyDown = (keyDownEvent) => {
    if (keyDownEvent.keyCode === 32) {
      keyDownEvent.stopPropagation();
    }
    if ((keyDownEvent.keyCode === 67 && keyDownEvent.altKey) || keyDownEvent.keyCode === 191) {
      keyDownEvent.preventDefault();
    }

    if (keyDownEvent.keyCode === 13) {
      keyDownEvent.preventDefault();
      dispatch({ type: 'SEARCH_DIGEST', value: searchQuery });
    }

    if (keyDownEvent.keyCode === 27) {
      keyDownEvent.preventDefault();
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDigestsList = useCallback(
    (
      typeSelected,
      statusSelected,
      categorySelected,
      sourceSelected,
      authorSelected,
      labelSelected,
      quality,
      relevanceScore,
      filteredTags,
      filteredRegions,
    ) => {
      dispatch({ type: 'SET_DIGEST_LOADING', value: true });
      fetchDigestsData({
        page: currentPage,
        sortBy,
        sortOrder,
        search,
        selectedCategory: categorySelected.join(','),
        typeSelected,
        selectedSource: sourceSelected,
        selectedAuthor: authorSelected,
        selectedStatus: statusSelected,
        labels: labelSelected.join(','),
        size: 10,
        editorScoreQuality: quality,
        editorScoreRelevanceTerm: relevanceScore,
        filterTags: filteredTags,
        filterRegions: filteredRegions

        // changeStats,
      })
        .then((response) => {
          if (response.data.length === 0 && currentPage > 1) {
            dispatch({ type: 'SET_CURRENT_PAGE', value: currentPage - 1 });
          } else {
            dispatch({
              type: 'SET_DIGESTS_LIST',
              value: response.data.results,
              totalCount: response.data.count,
              suggestedFilters: response.data.suggested_filters,
            });
          }
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
          ToastsStore.error('Something went wrong!');
        });

      if (!digestType.length) {
        fetchDigestType().then((result) => {
          dispatch({ type: 'SET_TYPE', value: result.data.results });
        });
      }

      if (!sources.length) {
        fetchSources().then((result) => {
          dispatch({ type: 'SET_SOURCES', value: result.data.results });
        });
      }

      if (!authors.length) {
        fetchUsers(1, 50)
          .then((result) => {
            dispatch({ type: 'SET_AUTHORS', value: result.data.results });
          })
          .catch(() => {});
      }

      if (categoriesList && categoriesList.length === 0) {
        fetchCategoriesData()
          .then((response) => {
            dispatch({ type: 'SET_CATEGORIES_LIST', value: response.data.results });
          })
          .catch((error) => {
            if (window) window.Raven.captureException(error.response);
            dispatch({ type: 'SET_DIGESTS_LIST', value: [], totalCount: 0 });
            ToastsStore.error('Something went wrong!');
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentPage, sortBy, sortOrder, search],
  );

  useEffect(() => {
    const typeSelected = selectedType ? selectedType.substring(0, selectedType.indexOf('-')) : '';
    fetchDigestsList(
      typeSelected,
      selectedStatus,
      selectedCategory,
      selectedSource,
      selectedAuthor,
      selectedLabels,
      quality,
      relevanceScore,
      filteredTags,
      filteredRegions,
    );
  }, [
    fetchDigestsList,
    currentPage,
    selectedType,
    selectedStatus,
    selectedCategory,
    selectedSource,
    selectedAuthor,
    selectedLabels,
    quality,
    relevanceScore,
    filteredTags,
    filteredRegions,
  ]);

  const fetchLabelList = ({ field, searchVal }) => {
    // let fields = { page: labelPage, searchLabel };
    // if (searchField === 'main') fields = { page: labelPageMain, searchLabel: searchMain };
    fetchLabels({ search: searchVal })
      .then((response) => {
        dispatch({
          type: 'SET_LABEL',
          value: response.data.results.map((label) => ({
            key: label.id,
            value: label.color_code,
            text: label.title,
          })),
          field,
        });
      })
      .catch(() => {
        ToastsStore.error('Unable to fetch labels');
      });
  };

  useEffect(() => {
    fetchLabelList({ field: 'both', searchVal: '', page: 1 });
  }, []);

  const handleFetchDigestList = () => {
    const typeSelected = selectedType ? selectedType.substring(0, selectedType.indexOf('-')) : '';
    fetchDigestsList(
      typeSelected,
      selectedStatus,
      selectedCategory,
      selectedSource,
      selectedAuthor,
      selectedLabels,
      quality,
      relevanceScore,
      filteredTags,
      filteredRegions,
    );
  };

  const setDraftModal = ({ status, digestStatusValue, data }) => {
    dispatch({
      type: 'DRAFT_MODAL_STATUS',
      digestModalStatus: status,
      digestStatus: digestStatusValue,
      digest: data,
    });
  };

  const setDeleteModal = ({ value, deleteIds }) => {
    dispatch({ type: 'SET_DELETE_MODAL', value: true, deleteDigestsId: deleteIds });
  };

  const categoryFilterSelect = ({ value }) => {
    dispatch({ type: 'SET_SELECTED_CATEGORY', value });
  };

  const sourceFilterSelect = ({ value }) => {
    dispatch({ type: 'SET_SELECTED_SOURCE', value });
  };

  const qualityFilterSelect = ({ value }) => {
    dispatch({ type: 'SET_SELECTED_QUALITY', value });
  };
  const relevanceScoreFilterSelect = ({ value }) => {
    dispatch({ type: 'SET_SELECTED_RELEVANCE', value });
  };

  const selectDigest = ({ data }) => {
    dispatch({ type: 'SET_SELECTED_DIGEST', value: data });
  };

  const selectAllDigests = () => {
    dispatch({ type: 'SET_SELECT_ALL_DIGESTS' });
  };

  const editDigest = ({ data }) => {
    // if (data.type && videoCodes.includes(data.type.code)) {
    //   history.push(`/video/${data.id}`, {
    //     urlString: data.source.link,
    //   });
    // }
    // // if (data.type && newsCodes.includes(data.type.code))
    // else {
    history.push(`/digests/${data.id}`, {
      urlString: data.source.link,
    });
    // }
  };

  let feUrl = 'https://news.travelnewsdigest.in';
  switch (process.env.REACT_APP_ENV) {
    case 'oped-production':
      feUrl = 'https://news.getdailybrief.com';
      break;
    case 'clip-production':
      feUrl = 'https://watch.sphr.tv';
      break;
    case 'oped-staging':
      feUrl = 'https://staging.getdailybrief.com';
      break;
    case 'und-production':
      feUrl = 'https://read.urbannewsdigest.in';
      break;
    case 'und-staging':
      feUrl = 'https://staging-site.urbannewsdigest.in';
      break;
    case 'staging':
      feUrl = 'https://prod1.opiniondigest.in';
      break;
    case 'production':
      feUrl = 'https://news.travelnewsdigest.in';
      break;
  }

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = (day) => day.isAfter(yesterday);

  const fetchCurrentStatuses = () => {
    fetchAllStatuses().then((response) => {
      dispatch({ type: 'SET_STATUSES', value: response.data.results, total: response.data.total });
    });
  };
  useEffect(() => {
    fetchCurrentStatuses();
  }, []);

  const setEditable = (data, val) => {
    dispatch({ type: 'SET_EDITABLE', data, val });
  };
  const setRelevanceditable = (data, val) => {
    dispatch({ type: 'SET_RELEVANCED_EDITABLE', data, val });
  };

  const editedField = (val, data) => {
    dispatch({ type: 'SET_EDITABLE_VALUE', val, data });
  };
  const editedRelevenceField = (val, data) => {
    dispatch({ type: 'SET_RELEVENCE_EDITABLE_VALUE', val, data });
  };

  const handleEditData = (tagsList, digestId) => {
    dispatch({ type: 'SET_UPDATE_DIGESTS_LOADING', value: true });
    updateDigest({
      data: {
        tags: tagsList.map((item) => item.id),
      },
      id: digestId,
    })
      .then(() => {
        handleFetchDigestList();
        fetchCurrentStatuses();
        dispatch({
          type: 'DRAFT_MODAL_STATUS',
          digestModalStatus: false,
          digestStatus: '',
          digest: {},
        });
        ToastsStore.success('Digests updated successfully.');
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        dispatch({
          type: 'DRAFT_MODAL_STATUS',
          digestModalStatus: false,
          digestStatus: '',
          digest: {},
        });
        if (error.response.status === 400) {
          ToastsStore.error(
            (error.response.data && error.response.data.title && error.response.data.title[0]) ||
              'Something went wrong',
          );
        } else {
          ToastsStore.error((error.response && error.response.message) || 'Something went wrong');
        }
      });
  };

  const columnsClip = [
    {
      title: digestsList && digestsList.length > 0 && (
        <Checkbox
          checked={
            (digestsList.length && selectedDigestsId.length === digestsList.length) ||
            selectAllDigest
          }
          indeterminate={
            !selectAllDigest &&
            selectedDigestsId.length > 0 &&
            selectedDigestsId.length < digestsList.length
          }
          onClick={() => dispatch({ type: 'SET_SELECT_ALL_DIGESTS' })}
        />
      ),
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      dataIndex: 'select',
      key: 'select',
      width: 40,
      render: (value, data) => (
        <Checkbox
          onClick={() => dispatch({ type: 'SET_SELECTED_DIGEST', value: data })}
          checked={selectedDigestsId.indexOf(data.id) !== -1}
        />
      ),
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: digestsList ? digestsList.length : 0,
      width: 60,
    },
    {
      title: 'Digest Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      render: (value, data) => (
        <div>
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
            {data.labels.map((label) => (
              <LabelSelected color={label.color_code} key={label.color_code}></LabelSelected>
            ))}
          </div>
          <span>{value}</span>
        </div>
      ),
    },
    {
      title: 'Total Segment/Video Duration',
      dataIndex: 'video_summary',
      key: 'video_summary',
      width: 120,
      render: (value, data) => (
        <div>
          {/* {value && value.total_length !== undefined ? <div>{value.total_length || ''}</div> : null} */}
          {data.cover_pic.includes('https://stream.mux.com/') || data.cover_pic.includes('.mp4') ? (
            data.timestamp_full_length
          ) : value && value.total_length !== undefined ? (
            <div>{value.total_length || ''}</div>
          ) : null}
        </div>
      ),
    },

    // {
    //   title: 'Digest Subtitle',
    //   dataIndex: 'subtitle',
    //   key: 'subtitle',
    //   width: 110,
    // },
    // {
    //   title: 'Description',
    //   dataIndex: 'digest',
    //   key: 'digest',
    //   width: 250,
    //   render: (value, data, index) => (
    //     !more.includes(index) ? (
    //       value && value.split(" ").length > 100 ? (
    //         <Description>
    //           {renderHTML(value.split(" ").slice(0, 100).join(" "))}
    //           <MoreButton
    //               onClick={(e) => {
    //                 e.stopPropagation();
    //                 dispatch({ type: 'SHOW_STATUS', index });
    //               }}
    //             >
    //               Show More
    //               <Icon name="chevron down" size="large" />
    //             </MoreButton>
    //         </Description>
    //       ) : (
    //         value ? <span>{renderHTML(value)}</span> : ''
    //       )
    //     ) : (
    //       <Description>
    //         {value ? <span>{renderHTML(value)}</span> : ''}
    //         <MoreButton
    //              onClick={(e) => {
    //               e.stopPropagation();
    //               dispatch({ type: 'SHOW_STATUS', index });
    //             }}
    //         >
    //           Show Less
    //           <Icon name="chevron up" size="large" />
    //         </MoreButton>
    //       </Description>
    //     )
    //   )
    // },
    {
      title: 'Cover Pic',
      dataIndex: 'cover_pic',
      key: 'cover_pic',
      width: 80,
      render: (value, data) => {
          if(value){
            if (value.includes('.mp4')){
            return (
              <ImageWrapper>
                <Image src={data.custom_thumbnail} style={{ width: 80 }} />
              </ImageWrapper>
            );
            }
            else if (value.includes('https://www.youtube.com/watch?v')){
               return (
                <ImageWrapper>
                  <Image
                    src={`https://i3.ytimg.com/vi/${value.substring(
                      value.indexOf('=') + 1,
                      value.length,
                    )}/hqdefault.jpg`}
                    style={{ width: 80 }}
                  />
                </ImageWrapper>
              );
            }else if(data.custom_thumbnail.includes('https://twitter.com/')){
               return (
                <TwitterPic>
                <TwitterPlayer tweetLink={data.custom_thumbnail} />
                </TwitterPic>
               )
            }else{
                return (
                  <ImageWrapper>
                    <Image src={value} />
                  </ImageWrapper>
                );
            }
          } else if (data) {
            if(data?.curated_tweet?.link && data?.curated_tweet?.link.includes('https://twitter.com/')){
              return (
                <TwitterPic>
                <TwitterPlayer tweetLink={data?.curated_tweet?.link} />
                </TwitterPic>
              )
            }else{
            return (
              <ImageWrapper>
                <Image src={data.custom_thumbnail} style={{ width: 80 }} />
              </ImageWrapper>
            );
            }
          }
      },
    },
    {
      title: 'First Published At',
      dataIndex: 'first_published_at',
      key: 'first_published_at',
      width: 110,
      sorter: digestsList ? digestsList.length : 0,
      render: (value) =>
        value ? <div>{moment(value).format('MMM DD,YYYY hh:mm A')}</div> : <div></div>,
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   width: 80,
    //   render: (value) => {
    //     const data = Object.keys(value).length ? `${value.code}-${value.title}` : '';
    //     return <span>{data}</span>
    //   },
    //   filter: true,
    //   filterOptions: digestType,
    //   onFilterSelect: value => dispatch({ type: 'SET_SELECTED_TYPE', value }),
    //   selectedOption: selectedType
    // },
    // {
    //   title: 'Categories',
    //   dataIndex: 'categories',
    //   key: 'categories',
    //   multiple: true,
    //   // selectedValue,
    //   // setSelectedValue,
    //   width: 90,
    //   render: (value) => {
    //     const data = value.map((item) => item.title);
    //     return <div>{data.join(', ')}</div>;
    //   },
    //   filter: true,
    //   filterOptions: categoriesList,
    //   onFilterSelect: (value, options) => {
    //     dispatch({ type: 'SET_FILTER_LOADING', value: true });
    //     dispatch({ type: 'SET_SELECTED_CATEGORY', value, options });
    //   },
    //   selectedOption: selectedCategory,
    // },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 100,
      render: (value) => <div style={{ width: 100 }}>{value.host}</div>,
      filter: true,
      filterOptions: sources,
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_SOURCE', value, option }),
      selectedOption: selectedSource,
    },
    {
      title: (
        <Dropdown text="Quality">
          <Dropdown.Menu>
            {[0, 1, 2, 3, 4, 5].map((item) => (
              <Dropdown.Item onClick={() => filterDisgestbyValue(item, 'quality')} text={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: 'editor_score_quality',
      key: 'editor_score_quality',
      // sorter: digestsList ? digestsList.length : 0,
      width: 170,
      onCell: (data, index) => ({
        onClick: (e) => {
          e.stopPropagation();
          // setEditable(index, data, true);
        },
      }),
      render: (value, data, index) => {
        const quality = value || 0;
        const isEditable = editable.id === data.id;
        if (isEditable)
          return (
            <div style={{ textAlign: 'center' }}>
              <Dropdown
                placeholder="Select User"
                value={editable.value}
                fluid
                selection
                onChange={(e, val) => {
                  editedField(val.value, data);
                }}
                options={scoreList}
              />
              <FlexIcon>
                <IconWrapper
                  onClick={() => {
                    setEditable(data, false);
                  }}
                >
                  <div title="cancel">
                    <Icon name="cancel" size="large" />
                  </div>
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                    updateDigest({
                      data: {
                        editor_score_quality: editable.value,
                      },
                      id: data.id,
                    })
                      .then(() => {
                        handleFetchDigestList();
                        fetchCurrentStatuses();
                        setEditable(data, false);
                        ToastsStore.success('Digests updated successfully.');
                      })
                      .catch((error) => {
                        if (window) window.Raven.captureException(error.response);
                        if (error.response.status === 400) {
                          ToastsStore.error(
                            (error.response.data &&
                              error.response.data.title &&
                              error.response.data.title[0]) ||
                              'Something went wrong',
                          );
                        } else {
                          ToastsStore.error(
                            (error.response && error.response.message) || 'Something went wrong',
                          );
                        }
                        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
                      });
                  }}
                >
                  <div title="save">
                    <Icon name="save" size="large" />
                  </div>
                </IconWrapper>
              </FlexIcon>
              {/* )} */}
            </div>
          );
        return (
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                fetchDigestScores(data.categories.length ? data.categories[0].id : 1);
                editedField(quality, data);
              }}
            >
              {quality}
            </Button>
          </div>
        );
      },
      // render: (value, data, index) => <QualityInput>{value}</QualityInput>,
      // render: (value, data, index) => {
      //   if (editable.includes(index)) return <QualityInput>{value}</QualityInput>;
      //   return <span>{value}</span>;
      // },
    },
    {
      title: (
        <Dropdown text="Relevance">
          <Dropdown.Menu>
            {[1, 2, 3].map((item) => (
              <Dropdown.Item onClick={() => filterDisgestbyValue(item, 'relevance')} text={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: 'editor_score_relevance',
      key: 'editor_score_relevance',
      // sorter: digestsList ? digestsList.length : 0,
      width: 170,
      onCell: (data, index) => ({
        onClick: (e) => {
          e.stopPropagation();
          // setEditable(index, data, true);
        },
      }),
      render: (value, data, index) => {
        const quality = value || 0;
        const isEditable = relevenceEditable.id === data.id;
        if (isEditable)
          return (
            <div style={{ textAlign: 'center' }}>
              <Dropdown
                placeholder="Select User"
                value={relevenceEditable.value}
                fluid
                selection
                onChange={(e, val) => {
                  editedRelevenceField(val.value, data);
                }}
                options={scoreRelevance}
              />
              <FlexIcon>
                <IconWrapper
                  onClick={() => {
                    setRelevanceditable(data, false);
                  }}
                >
                  <div title="cancel">
                    <Icon name="cancel" size="large" />
                  </div>
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                    updateDigest({
                      data: {
                        editor_score_relevance: relevenceEditable.value,
                      },
                      id: data.id,
                    })
                      .then(() => {
                        handleFetchDigestList();
                        fetchCurrentStatuses();
                        setRelevanceditable(data, false);
                        ToastsStore.success('Digests updated successfully.');
                      })
                      .catch((error) => {
                        if (window) window.Raven.captureException(error.response);
                        if (error.response.status === 400) {
                          ToastsStore.error(
                            (error.response.data &&
                              error.response.data.title &&
                              error.response.data.title[0]) ||
                              'Something went wrong',
                          );
                        } else {
                          ToastsStore.error(
                            (error.response && error.response.message) || 'Something went wrong',
                          );
                        }
                        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
                      });
                  }}
                >
                  <div title="save">
                    <Icon name="save" size="large" />
                  </div>
                </IconWrapper>
              </FlexIcon>
              {/* )} */}
            </div>
          );
        return (
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                fetchDigestScores(data.categories.length ? data.categories[0].id : 1);
                editedRelevenceField(quality, data);
              }}
            >
              {quality}
            </Button>
          </div>
        );
      },
      // render: (value, data, index) => <QualityInput>{value}</QualityInput>,
      // render: (value, data, index) => {
      //   if (editable.includes(index)) return <QualityInput>{value}</QualityInput>;
      //   return <span>{value}</span>;
      // },
    },
    {
      title:
        process.env.REACT_APP_ENV === 'oped-production'
          ? 'Article metrics notif open, read, shared, Avg. read'
          : 'Article metrics Notif open, shared, Avg % watch, Watch count',
      dataIndex: 'metrics',
      key: 'metrics',
      width: 170,
      render: (value) => (
        <Flex style={{ justifyContent: 'space-around', width: 150 }}>
          {process.env.REACT_APP_ENV === 'oped-production' ? (
            <>
              <Status color="yellow">{value.notification_open_count}</Status>
              <Status color="yellow">{value.read_count}</Status>
              <Status color="yellow">{value.share_cont}</Status>
              <Status color="yellow">{value.avg_read}</Status>
            </>
          ) : (
            <>
              <Status color="yellow">{value.notification_open_count}</Status>

              <Status color="yellow">{value.share_cont}</Status>

              <Status color="yellow">{value.avg_watch}</Status>
              <Status color="yellow">{value.watch_count}</Status>
            </>
          )}
        </Flex>
      ),
    },
    {
      // title: 'Tags',
      title: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <parent
          onClick={() => {
            setOpenTagModal(true);
          }}
        >
          Tags
          <Icon name="caret down" />
        </parent>
      ),
      dataIndex: 'tag',
      key: 'tag',
      width: 150,
      render: (value, row) => (
        <TagFlex style={{ width: 150 }}>
          {value.slice(0, 5).map((item) => (
            <TagStatus>{`${item.title}, `}</TagStatus>
          ))}{' '}
          {'  ...'}
          <EditIconWrapper
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch({
                type: 'SET_TAG_EDIT_MODAL',
                tagsData: value,
                digestId: row.id,
                value: true,
              });
            }}
          >
            <Icon name="edit" />
          </EditIconWrapper>
        </TagFlex>
      ),
    },
    {
      title: 'Modified At',
      dataIndex: 'modified_at',
      key: 'modified_at',
      sorter: digestsList ? digestsList.length : 0,
      width: 100,
      render: (value) => <div>{moment(value).format('MMM DD,YYYY hh:mm A')}</div>,
    },
    {
      title: 'Author',
      dataIndex: 'digest_author',
      key: 'digest_author',
      width: 110,
      render: (value) => <div style={{ width: 110 }}>{value.name}</div>,
      filter: true,
      filterOptions: authors,
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_AUTHOR', value, option }),
      selectedOption: selectedAuthor,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      fixed: 'right',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (value, data) => (
        <div>
          <div className="action-wrapper">
            <ActionWrapper>
              {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_DELETE_MODAL', value: true, deleteDigestsId: [data.id] });
                  }}
                >
                  <div title="Delete">
                    <Icon name="archive" size="large" />
                  </div>
                </IconWrapper>
              )}
              {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_COPY_MODAL', value: true, deleteDigestsId: [data.id] });
                  }}
                >
                  <div title="Copy">
                    <Icon name="copy" size="large" />
                  </div>
                </IconWrapper>
              )}
              {data.status !== 'Published' ? (
                <>
                  <IconWrapper
                    onClick={() => {
                      dispatch({
                        type: 'DRAFT_MODAL_STATUS',
                        digestModalStatus: true,
                        digestStatus: data.status,
                        digest: data,
                      });
                    }}
                  >
                    <div title="Publish">
                      <Icon name="upload" size="large" />
                    </div>
                  </IconWrapper>
                  <NewTabIconWrapper
                    to={`/video/edit/${data.id}`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div title="Edit">
                      <Icon name="pencil alternate" />
                    </div>
                  </NewTabIconWrapper>
                </>
              ) : (
                <>
                  <IconWrapper
                    onClick={() => {
                      dispatch({
                        type: 'DRAFT_MODAL_STATUS',
                        digestModalStatus: true,
                        digestStatus: data.status,
                        digest: data,
                      });
                    }}
                  >
                    <div title="Save to draft">
                      <Icon name="save" size="large" />
                    </div>
                  </IconWrapper>
                  <MenuWrapper>
                    <Menu.Item
                      title="View published page"
                      href={`${feUrl}/digests/${data.slug}`}
                      target="_blank"
                    >
                      <Icon name="eye" size="large" />
                    </Menu.Item>
                  </MenuWrapper>
                  <NewTabIconWrapper to={`/digests/${data.id}`} target="_blank">
                    <div title="Digest">
                      <Icon name="external alternate" size="large" />
                    </div>
                  </NewTabIconWrapper>
                  <IconWrapper
                    onClick={() => {
                      if (data.categories && data.categories.length)
                        dispatch({
                          type: 'SEND_NOTIFICATION',
                          value: true,
                          digest: data,
                        });
                      else {
                        ToastsStore.error('No category present. Cannot create notification');
                      }
                    }}
                  >
                    <div title="Send Notification">
                      <Icon
                        name="bell"
                        size="large"
                        disabled={disabledStatus.includes(data.notification_status)}
                      />
                    </div>
                  </IconWrapper>
                  <NewTabIconWrapper
                    to={`/video/edit/${data.id}`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div title="Edit">
                      <Icon name="pencil alternate" />
                    </div>
                  </NewTabIconWrapper>
                </>
              )}
            </ActionWrapper>
          </div>
        </div>
      ),
    },
  ];

  const columns = [
    {
      title: digestsList && digestsList.length > 0 && (
        <Checkbox
          checked={
            (digestsList.length && selectedDigestsId.length === digestsList.length) ||
            selectAllDigest
          }
          indeterminate={
            !selectAllDigest &&
            selectedDigestsId.length > 0 &&
            selectedDigestsId.length < digestsList.length
          }
          onClick={() => dispatch({ type: 'SET_SELECT_ALL_DIGESTS' })}
        />
      ),
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      dataIndex: 'select',
      key: 'select',
      width: 40,
      render: (value, data) => (
        <Checkbox
          onClick={() => dispatch({ type: 'SET_SELECTED_DIGEST', value: data })}
          checked={selectedDigestsId.indexOf(data.id) !== -1}
        />
      ),
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: digestsList ? digestsList.length : 0,
      width: 60,
    },
    {
      title: 'Digest Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
      render: (value, data) => (
        <div>
          <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
            {data.labels.map((label) => (
              <LabelSelected color={label.color_code} key={label.color_code}></LabelSelected>
            ))}
          </div>
          <span>{value}</span>
        </div>
      ),
    },

    // {
    //   title: 'Digest Subtitle',
    //   dataIndex: 'subtitle',
    //   key: 'subtitle',
    //   width: 110,
    // },
    // {
    //   title: 'Description',
    //   dataIndex: 'digest',
    //   key: 'digest',
    //   width: 250,
    //   render: (value, data, index) => (
    //     !more.includes(index) ? (
    //       value && value.split(" ").length > 100 ? (
    //         <Description>
    //           {renderHTML(value.split(" ").slice(0, 100).join(" "))}
    //           <MoreButton
    //               onClick={(e) => {
    //                 e.stopPropagation();
    //                 dispatch({ type: 'SHOW_STATUS', index });
    //               }}
    //             >
    //               Show More
    //               <Icon name="chevron down" size="large" />
    //             </MoreButton>
    //         </Description>
    //       ) : (
    //         value ? <span>{renderHTML(value)}</span> : ''
    //       )
    //     ) : (
    //       <Description>
    //         {value ? <span>{renderHTML(value)}</span> : ''}
    //         <MoreButton
    //              onClick={(e) => {
    //               e.stopPropagation();
    //               dispatch({ type: 'SHOW_STATUS', index });
    //             }}
    //         >
    //           Show Less
    //           <Icon name="chevron up" size="large" />
    //         </MoreButton>
    //       </Description>
    //     )
    //   )
    // },
    {
      title: 'Cover Pic',
      dataIndex: 'cover_pic',
      key: 'cover_pic',
      width: 80,
      render: (value, data) => {
        if(value){          
          if (value.includes('.mp4')){
          return (
            <ImageWrapper>
              <Image src={data.custom_thumbnail} style={{ width: 80 }} />
            </ImageWrapper>
          );
        }else if (value.includes('https://www.youtube.com/watch?v')){
             return (
              <ImageWrapper>
                <Image
                  src={`https://i3.ytimg.com/vi/${value.substring(
                    value.indexOf('=') + 1,
                    value.length,
                  )}/hqdefault.jpg`}
                  style={{ width: 80 }}
                />
              </ImageWrapper>
            );
          }else if(data.custom_thumbnail.includes('https://twitter.com/')){
            return (
              <TwitterPic>
              <TwitterPlayer tweetLink={data.custom_thumbnail} />
              </TwitterPic>
            )
          }else{
              return (
                <ImageWrapper>
                  <Image src={value} />
                </ImageWrapper>
              );
          }
        } else if (data) {
           if(data?.curated_tweet?.link && data?.curated_tweet?.link.includes('https://twitter.com/')){
            return (
              <TwitterPic>
              <TwitterPlayer tweetLink={data?.curated_tweet?.link} />
              </TwitterPic>
            )
          }else{
          return (
            <ImageWrapper>
              <Image src={data.custom_thumbnail} style={{ width: 80 }} />
            </ImageWrapper>
          );
          }
        }
      },
    },
    {
      title: 'First Published At',
      dataIndex: 'first_published_at',
      key: 'first_published_at',
      width: 110,
      sorter: digestsList ? digestsList.length : 0,
      render: (value) =>
        value ? <div>{moment(value).format('MMM DD,YYYY hh:mm A')}</div> : <div></div>,
    },
    // {
    //   title: 'Type',
    //   dataIndex: 'type',
    //   key: 'type',
    //   width: 80,
    //   render: (value) => {
    //     const data = Object.keys(value).length ? `${value.code}-${value.title}` : '';
    //     return <span>{data}</span>
    //   },
    //   filter: true,
    //   filterOptions: digestType,
    //   onFilterSelect: value => dispatch({ type: 'SET_SELECTED_TYPE', value }),
    //   selectedOption: selectedType
    // },
    // {
    //   title: 'Categories',
    //   dataIndex: 'categories',
    //   key: 'categories',
    //   multiple: true,
    //   // selectedValue,
    //   // setSelectedValue,
    //   width: 90,
    //   render: (value) => {
    //     const data = value.map((item) => item.title);
    //     return <div>{data.join(', ')}</div>;
    //   },
    //   filter: true,
    //   filterOptions: categoriesList,
    //   onFilterSelect: (value, options) => {
    //     dispatch({ type: 'SET_FILTER_LOADING', value: true });
    //     dispatch({ type: 'SET_SELECTED_CATEGORY', value, options });
    //   },
    //   selectedOption: selectedCategory,
    // },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 100,
      render: (value) => <div style={{ width: 100 }}>{value.host}</div>,
      filter: true,
      filterOptions: sources,
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_SOURCE', value, option }),
      selectedOption: selectedSource,
    },
    {
      title: (
        <Dropdown text="Quality">
          <Dropdown.Menu>
            {[0, 1, 2, 3, 4, 5].map((item) => (
              <Dropdown.Item onClick={() => filterDisgestbyValue(item, 'quality')} text={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: 'editor_score_quality',
      key: 'editor_score_quality',
      width: 170,
      onCell: (data, index) => ({
        onClick: (e) => {
          e.stopPropagation();
          // setEditable(index, data, true);
        },
      }),
      render: (value, data, index) => {
        const quality = value || 0;
        const isEditable = editable.id === data.id;
        if (isEditable)
          return (
            <div style={{ textAlign: 'center' }}>
              <Dropdown
                placeholder="Select User"
                value={editable.value}
                fluid
                selection
                onChange={(e, val) => {
                  editedField(val.value, data);
                }}
                options={scoreList}
              />
              <FlexIcon>
                <IconWrapper
                  onClick={() => {
                    setEditable(data, false);
                  }}
                >
                  <div title="cancel">
                    <Icon name="cancel" size="large" />
                  </div>
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                    updateDigest({
                      data: {
                        editor_score_quality: editable.value,
                      },
                      id: data.id,
                    })
                      .then(() => {
                        handleFetchDigestList();
                        fetchCurrentStatuses();
                        setEditable(data, false);
                        ToastsStore.success('Digests updated successfully.');
                      })
                      .catch((error) => {
                        if (window) window.Raven.captureException(error.response);
                        if (error.response.status === 400) {
                          ToastsStore.error(
                            (error.response.data &&
                              error.response.data.title &&
                              error.response.data.title[0]) ||
                              'Something went wrong',
                          );
                        } else {
                          ToastsStore.error(
                            (error.response && error.response.message) || 'Something went wrong',
                          );
                        }
                        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
                      });
                  }}
                >
                  <div title="save">
                    <Icon name="save" size="large" />
                  </div>
                </IconWrapper>
              </FlexIcon>
              {/* )} */}
            </div>
          );
        return (
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                fetchDigestScores(data.categories.length ? data.categories[0].id : 1);
                editedField(quality, data);
              }}
            >
              {quality}
            </Button>
          </div>
        );
      },
      // render: (value, data, index) => <QualityInput>{value}</QualityInput>,
      // render: (value, data, index) => {
      //   if (editable.includes(index)) return <QualityInput>{value}</QualityInput>;
      //   return <span>{value}</span>;
      // },
    },
    {
      // title: 'RelevanceAA',
      title: (
        <Dropdown text="Relevance">
          <Dropdown.Menu>
            {[1, 2, 3].map((item) => (
              <Dropdown.Item onClick={() => filterDisgestbyValue(item, 'relevance')} text={item} />
            ))}
          </Dropdown.Menu>
        </Dropdown>
      ),
      dataIndex: 'editor_score_relevance',
      key: 'editor_score_relevance',
      // sorter: digestsList ? digestsList.length : 0,
      width: 170,
      onCell: (data, index) => ({
        onClick: (e) => {
          e.stopPropagation();
          // setEditable(index, data, true);
        },
      }),
      render: (value, data, index) => {
        const quality = value || 0;
        const isEditable = relevenceEditable.id === data.id;
        if (isEditable)
          return (
            <div style={{ textAlign: 'center' }}>
              <Dropdown
                placeholder="Select User"
                value={relevenceEditable.value}
                fluid
                selection
                onChange={(e, val) => {
                  editedRelevenceField(val.value, data);
                }}
                options={scoreRelevance}
              />
              <FlexIcon>
                <IconWrapper
                  onClick={() => {
                    setRelevanceditable(data, false);
                  }}
                >
                  <div title="cancel">
                    <Icon name="cancel" size="large" />
                  </div>
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                    updateDigest({
                      data: {
                        editor_score_relevance: relevenceEditable.value,
                      },
                      id: data.id,
                    })
                      .then(() => {
                        handleFetchDigestList();
                        fetchCurrentStatuses();
                        setRelevanceditable(data, false);
                        ToastsStore.success('Digests updated successfully.');
                      })
                      .catch((error) => {
                        if (window) window.Raven.captureException(error.response);
                        if (error.response.status === 400) {
                          ToastsStore.error(
                            (error.response.data &&
                              error.response.data.title &&
                              error.response.data.title[0]) ||
                              'Something went wrong',
                          );
                        } else {
                          ToastsStore.error(
                            (error.response && error.response.message) || 'Something went wrong',
                          );
                        }
                        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
                      });
                  }}
                >
                  <div title="save">
                    <Icon name="save" size="large" />
                  </div>
                </IconWrapper>
              </FlexIcon>
              {/* )} */}
            </div>
          );
        return (
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                fetchDigestScores(data.categories.length ? data.categories[0].id : 1);
                editedRelevenceField(quality, data);
              }}
            >
              {quality}
            </Button>
          </div>
        );
      },
      // render: (value, data, index) => <QualityInput>{value}</QualityInput>,
      // render: (value, data, index) => {
      //   if (editable.includes(index)) return <QualityInput>{value}</QualityInput>;
      //   return <span>{value}</span>;
      // },
    },
    {
      title:
        process.env.REACT_APP_ENV === 'oped-production'
          ? 'Article metrics notif open, read, shared, Avg. read'
          : 'Article metrics Notif open, shared, Avg % watch, Watch count',
      dataIndex: 'metrics',
      key: 'metrics',
      width: 170,
      render: (value) => (
        <Flex style={{ justifyContent: 'space-around', width: 150 }}>
          {process.env.REACT_APP_ENV === 'oped-production' ? (
            <>
              <Status color="yellow">{value.notification_open_count}</Status>
              <Status color="yellow">{value.read_count}</Status>
              <Status color="yellow">{value.share_cont}</Status>
              <Status color="yellow">{value.avg_read}</Status>
            </>
          ) : (
            <>
              <Status color="yellow">{value.notification_open_count}</Status>

              <Status color="yellow">{value.share_cont}</Status>

              <Status color="yellow">{value.avg_watch}</Status>
              <Status color="yellow">{value.watch_count}</Status>
            </>
          )}
        </Flex>
      ),
    },
    {
      // title: 'Tags',
      title: (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <parent
          onClick={() => {
            setOpenTagModal(true);
          }}
        >
          Tags
          <Icon name="caret down" />
        </parent>
      ),

      dataIndex: 'tag',
      key: 'tag',
      width: 150,
      render: (value, row) => (
        <TagFlex style={{ width: 150 }}>
          {value.slice(0, 5).map((item) => (
            <TagStatus>{`${item.title}, `}</TagStatus>
          ))}{' '}
          {'  ...'}
          <EditIconWrapper
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              dispatch({
                type: 'SET_TAG_EDIT_MODAL',
                tagsData: value,
                digestId: row.id,
                value: true,
              });
            }}
          >
            <Icon name="edit" />
          </EditIconWrapper>
        </TagFlex>
      ),
    },
    {
      title: 'Modified At',
      dataIndex: 'modified_at',
      key: 'modified_at',
      sorter: digestsList ? digestsList.length : 0,
      width: 100,
      render: (value) => <div>{moment(value).format('MMM DD,YYYY hh:mm A')}</div>,
    },
    {
      title: 'Author',
      dataIndex: 'digest_author',
      key: 'digest_author',
      width: 110,
      render: (value) => <div style={{ width: 110 }}>{value.name}</div>,
      filter: true,
      filterOptions: authors,
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_AUTHOR', value, option }),
      selectedOption: selectedAuthor,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      fixed: 'right',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (value, data) => (
        <div>
          <div className="action-wrapper">
            <ActionWrapper>
              {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_DELETE_MODAL', value: true, deleteDigestsId: [data.id] });
                  }}
                >
                  <div title="Delete">
                    <Icon name="archive" size="large" />
                  </div>
                </IconWrapper>
              )}
              {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                <IconWrapper
                  onClick={() => {
                    dispatch({ type: 'SET_COPY_MODAL', value: true, deleteDigestsId: [data.id] });
                  }}
                >
                  <div title="Copy">
                    <Icon name="copy" size="large" />
                  </div>
                </IconWrapper>
              )}
              {data.status !== 'Published' ? (
                <>
                  <IconWrapper
                    onClick={() => {
                      dispatch({
                        type: 'DRAFT_MODAL_STATUS',
                        digestModalStatus: true,
                        digestStatus: data.status,
                        digest: data,
                      });
                    }}
                  >
                    <div title="Publish">
                      <Icon name="upload" size="large" />
                    </div>
                  </IconWrapper>
                  <NewTabIconWrapper
                    to={`/video/edit/${data.id}`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div title="Edit">
                      <Icon name="pencil alternate" size="large" />
                    </div>
                  </NewTabIconWrapper>
                </>
              ) : (
                <>
                  <IconWrapper
                    onClick={() => {
                      dispatch({
                        type: 'DRAFT_MODAL_STATUS',
                        digestModalStatus: true,
                        digestStatus: data.status,
                        digest: data,
                      });
                    }}
                  >
                    <div title="Save to draft">
                      <Icon name="save" size="large" />
                    </div>
                  </IconWrapper>
                  <MenuWrapper>
                    <Menu.Item
                      title="View published page"
                      href={`${feUrl}/digests/${data.slug}`}
                      target="_blank"
                    >
                      <Icon name="eye" size="large" />
                    </Menu.Item>
                  </MenuWrapper>
                  <NewTabIconWrapper to={`/digests/${data.id}`} target="_blank">
                    <div title="Digest">
                      <Icon name="external alternate" size="large" />
                    </div>
                  </NewTabIconWrapper>
                  {/* <NewTabIconWrapper to={`/digests/${data.id}`} target="_blank">
                    <div title="Digest">
                      <Icon name="external alternate" size="large" />
                    </div>
                  </NewTabIconWrapper> */}

                  <IconWrapper
                    onClick={() => {
                      if (data.categories && data.categories.length)
                        dispatch({
                          type: 'SEND_NOTIFICATION',
                          value: true,
                          digest: data,
                        });
                      else {
                        ToastsStore.error('No category present. Cannot create notification');
                      }
                    }}
                  >
                    <div title="Send Notification">
                      <Icon
                        name="bell"
                        size="large"
                        disabled={disabledStatus.includes(data.notification_status)}
                      />
                    </div>
                  </IconWrapper>
                  <NewTabIconWrapper
                    to={`/video/edit/${data.id}`}
                    target="_blank"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <div title="Edit">
                      <Icon name="pencil alternate" />
                    </div>
                  </NewTabIconWrapper>
                </>
              )}
            </ActionWrapper>
          </div>
        </div>
      ),
    },
  ];

  const searchDigest = (value) => {
    dispatch({ type: 'SET_SEARCH_QUERY', value });
  };

  const editSource = (value, key) => {
    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
    editSources({ values: { ...key, brand_name: value } })
      .then(() => {
        dispatch({ type: 'SET_EDIT', value: {} });
        ToastsStore.success('Source updated successfully.');
        setTimeout(() => window.location.reload());
        handleFetchDigestList();
        fetchCurrentStatuses();
      })
      .catch((error) => {
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        if (error.response.status === 400) {
          ToastsStore.error(
            (error.response.data && error.response.data.title && error.response.data.title[0]) ||
              'Something went wrong',
          );
        } else {
          ToastsStore.error((error.response && error.response.message) || 'Something went wrong');
        }
      });
  };

  const editTags = (value, key) => {
    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
    editTag({ values: { ...key, title: value } })
      .then(() => {
        dispatch({ type: 'SET_EDIT', value: {} });
        ToastsStore.success('Tag updated successfully.');
        setTimeout(() => window.location.reload());
        handleFetchDigestList();
        fetchCurrentStatuses();
      })
      .catch((error) => {
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        if (error.response.status === 400) {
          ToastsStore.error(
            (error.response.data && error.response.data.title && error.response.data.title[0]) ||
              'Something went wrong',
          );
        } else {
          ToastsStore.error((error.response && error.response.message) || 'Something went wrong');
        }
      });
  };

  const edited = (key) => {
    dispatch({ type: 'SET_EDIT', value: key });
  };

  const onClickDigestsCategory = (value) => {
    dispatch({ type: 'SET_SELECTED_STATUS', value: value.slug });
  };

  const onSelectFilter = (key, value) => {
    dispatch({ type: 'SET_FILTER_LOADING', value: true });
    const tempFilter = filterObject.selectedCategory
      ? JSON.parse(JSON.stringify(filterObject.selectedCategory))
      : [];
    const category = JSON.parse(JSON.stringify(selectedCategory)) || [];
    const labelFilter = filterObject.selectedLabels
      ? JSON.parse(JSON.stringify(filterObject.selectedLabels))
      : [];
    if (key === 'selectedSource') {
      dispatch({ type: 'SET_SELECTED_SOURCE', value: '' });
    } else if (key === 'selectedAuthor') {
      dispatch({ type: 'SET_SELECTED_AUTHOR', value: '' });
    } else if (key === 'selectedCategory') {
      const index = tempFilter.findIndex((val) => val.value === value.value);
      tempFilter.splice(index, 1);
      const index1 = selectedCategory.findIndex((val) => val === value.value);
      category.splice(index1, 1);
      if (!tempFilter.length) delete tempFilter[key];
      dispatch({ type: 'SET_SELECTED_CATEGORY', value: category, options: tempFilter });
    } else if (key === 'selectedLabels') {
      const index = labelFilter.findIndex((val) => val.key === value.key);
      labelFilter.splice(index, 1);
      if (!labelFilter.length) delete labelFilter[key];
      dispatch({ type: 'SET_FILTER_LABELS', options: labelFilter });
      dispatch({ type: 'SET_SELECTED_LABEL', options: labelFilter });
    } else if (key === 'quality') {
      dispatch({ type: 'SET_SELECTED_QUALITY', value: '' });
    } else if (key === 'relevanceScore') {
      dispatch({ type: 'SET_SELECTED_RELEVANCE', value: '' });
    }
  };

  const saveSelectedDigests = async (status) => {
    try {
      await saveDigests(selectedDigestsId, status);
      ToastsStore.success(
        `Selected digests ${status === 'draft' ? 'saved to drafts' : 'published'}`,
      );
      setTimeout(() => handleFetchDigestList(), 1000);
    } catch {
      ToastsStore.error(`Unable to  ${status === 'draft' ? 'save to drafts' : 'publish'}`);
    }
  };

  const remove = () => {
    dispatch({ type: 'CONFIRM_MODAL' });
    dispatch({ type: 'SET_DIGEST', value: { ...digest, user_list_type: '' } });
    dispatch({ type: 'HIDE_ADD_NOTIF', value: true });
    if (submit) dispatch({ type: 'SET_DIGEST_LOADING', value: false });
  };

  const addAll = () => {
    dispatch({ type: 'CONFIRM_MODAL' });
    dispatch({ type: 'HIDE_ADD_NOTIF', value: true });
    if (submit) {
      confirmSubmit(submit);
    } else if (selectedType === 'all') {
      dispatch({ type: 'SET_DIGEST', value: { ...digest, user_list_type: 'all' } });
    } else
      dispatch({ type: 'SET_DIGEST', value: { ...digest, user_list_type: 'channel_follower' } });
  };

  const handleChangeValue = ({ value, type }) => {
    if (type === 'user_list_type') {
      if (value === 'all' || value === 'channel_follower')
        dispatch({ type: 'CONFIRM_MODAL', value });
      else dispatch({ type: 'SET_DIGEST', value: { ...digest, user_list_type: value } });
    } else if (type === 'test_user') {
      dispatch({ type: 'SET_DIGEST', value: { ...digest, test_user: value } });
    } else {
      dispatch({ type: 'SET_DIGEST', value: { ...digest, [type]: value } });
    }
  };

  const closeProgressModal = (valType) => {
    dispatch({ type: 'SHOW_PROGRESS', open: false, value: -1 });
    dispatch({ type: 'SET_DIGEST_LOADING', value: false });
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    if (!(valType && typeof valType === 'string')) {
      handleChangeValue({ type: 'notification_type', value: '' });
    }
  };

  const showProgress = async (id, index, length, valType) => {
    const response = await fetchProgress(id);
    if (response.data.notification_data[index]) {
      const progressValue = response.data.notification_data[index].report.sent_status;
      if (parseInt(progressValue.percentage, 10) !== 100) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        intervalRef.current = setInterval(showProgress, 5000, id, index, length, valType);
        dispatch({ type: 'SHOW_PROGRESS_STATUS', value: progressValue });
      } else if (index < length) {
        dispatch({ type: 'SHOW_PROGRESS', open: true, value: index, length });
        dispatch({ type: 'SHOW_PROGRESS_STATUS', value: progressValue });
        setTimeout(() => {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          showProgress(id, index + 1, length, valType);
          if (index + 1 !== length)
            dispatch({ type: 'SHOW_PROGRESS', open: true, value: index + 1, length });
        }, 5000);
      } else {
        closeProgressModal(valType);
      }
    } else {
      closeProgressModal(valType);
    }
  };

  const confirmSubmit = async (valType) => {
    // const newData = JSON.parse(JSON.stringify(digest));
    const videoImage = digest.cover_pic && digest.cover_pic.includes('https://www.youtube.com/');
    const newData = {
      notification_datas: [
        {
          text: digest.notification_text,
          channel: digest.categories
            ? digest.categories.map((category) => category.title)[0]
            : null,
          group: 2,
          imageUrl: videoImage
            ? `https://i3.ytimg.com/vi/${digest.cover_pic.substring(
                digest.cover_pic.indexOf('=') + 1,
                digest.cover_pic.length,
              )}/hqdefault.jpg`
            : digest.cover_pic,
          notification_type: 'Articles',
          object_id: digest.id,
          subtext: '',
          title: digest.notification_title,
        },
      ],
      bottom_tab_index: digest.bottom_tab_index,
      user_list_type:
        valType && typeof valType === 'string' ? digest.test_user : digest.user_list_type,
    };
    try {
      if (!scheduleNotification || (valType && typeof valType === 'string')) {
        const progressValue = await sendNotificationApi(newData);
        showProgress(progressValue.data.broadcast.id, 0, 1, valType);
        dispatch({ type: 'SHOW_PROGRESS', value: 0, open: true });
        ToastsStore.success('Sending notification.');
        if (!(valType && typeof valType === 'string')) {
          dispatch({ type: 'SEND_NOTIFICATION', value: false, digest: {} });
          dispatch({
            type: 'SET_VALUE',
            value: { notification_datas: [], tag_followed: false },
          });
        }
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
      } else {
        newData.schedule_datetime = scheduledTime;
        // dispatch({
        //   type: 'SET_VALUE',
        //   value: { notification_datas: [], tag_followed: false },
        // });
        if (scheduleNotification && moment(new Date()).add(5, 'minutes').isAfter(scheduledTime)) {
          ToastsStore.error('Please select atleast five minute more than current time');
          // dispatch({ type: 'SEND_NOTIFICATION', value: false, digest: {} });
          dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        } else if (scheduleNotification) {
          await sendNotificationApi(newData);
          ToastsStore.success('Notification scheduled.');
          dispatch({
            type: 'SET_VALUE',
            value: { notification_datas: [], tag_followed: false },
          });
          dispatch({ type: 'SEND_NOTIFICATION', value: false, digest: {} });
          dispatch({ type: 'SCHEDULE_NOTIF' });
          dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        }
      }
    } catch (error) {
      if (error.response.status === 400) ToastsStore.error('Channel not found.');
      else ToastsStore.error('Unable to send notifications.');
      dispatch({ type: 'SET_DIGEST_LOADING', value: false });
    }
  };

  const sendNotification = async (type) => {
    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
    dispatch({ type: 'SET_NOTIF_TYPE', valType: type });
    try {
      // if (digest.user_list_type === 'all')
      //   dispatch({ type: 'CONFIRM_MODAL', submit: true, value: 'all' });
      // else if (digest.user_list_type === 'channel_follower')
      //   dispatch({ type: 'CONFIRM_MODAL', submit: true, value: 'channel_follower' });
      // else
      confirmSubmit(type);
    } catch {
      ToastsStore.error('Unable to send notifications.');
      dispatch({ type: 'SET_DIGEST_LOADING', value: false });
    }
  };

  const openFilterLabels = (e) => {
    dispatch({ type: 'SHOW_FILTER_LABELS' });
    if (e && e.relatedTarget) e.relatedTarget.click();
  };

  const filterLabel = (option) => {
    let labelList = selectedLabelsTemp.slice();
    if (selectedLabelsTemp.some((label) => label.key === option.key))
      labelList = labelList.filter((label) => label.key !== option.key);
    else labelList.push(option);
    dispatch({ type: 'SET_SELECTED_LABEL', options: labelList });
  };

  const openLabels = () => {
    if (showLabels)
      dispatch({
        type: 'SHOW_LABELS',
        value: false,
        // labels: checkedDigest.labels.map((label) => label.id),
      });
    else dispatch({ type: 'SHOW_LABELS', value: true });
  };

  const closeLabels = (e) => {
    dispatch({
      type: 'SHOW_LABELS',
      value: false,
      // labels: checkedDigest.labels.map((label) => label.id),
    });
    if (e && e.relatedTarget) e.relatedTarget.click();
  };
  const [addTagsOpen, setAddTagsOpen] = useState();
  const [removeTagsOpen, setRemoveTagsOpen] = useState();

  const TagsOpen = () => {
    dispatch({ type: 'RESET_LABELS', value: false });
    setAddTagsOpen(true);
    setRemoveTagsOpen(false);
  };
  const TagsClose = () => {
    setAddTagsOpen(false);
  };

  const rTagsOpen = () => {
    dispatch({ type: 'RESET_LABELS', value: false });
    setRemoveTagsOpen(true);
    setAddTagsOpen(false);
  };
  const rTagsClose = () => {
    setRemoveTagsOpen(false);
  };

  const setLabel = (value) => {
    dispatch({ type: 'SET_CURRENT_LABELS', value });
  };

  const setLabels = async (e) => {
    dispatch({ type: 'SHOW_LABELS', value: false });
    if (editedLabel) {
      dispatch({ type: 'SET_DIGEST_LOADING', value: true });
      try {
        await updateDigest({
          data: {
            labels: currentLabels,
          },
          id: checkedDigest.id,
        });
        ToastsStore.success('Labels updated');
        setTimeout(() => handleFetchDigestList(), 1000);
      } catch {
        ToastsStore.error('Unable to update labels');
      }
    }
  };

  const addLabelsMultiple = async (e) => {
    let labelsArr = [];
    currentLabels.map((item) => {
      let labelsId = {};
      labelsId.id = item;
      return labelsArr.push(labelsId);
    });
    dispatch({ type: 'SHOW_LABELS', value: false });
    if (editedLabel) {
      dispatch({ type: 'SET_DIGEST_LOADING', value: true });
      try {
        await addLablesDigest({
          data: {
            digests: selectedDigestsId,
            labels: labelsArr,
          },
        });
        ToastsStore.success('Labels updated');
        setTimeout(() => handleFetchDigestList(), 1000);
      } catch {
        ToastsStore.error('Unable to update labels');
      }
    }
    setAddTagsOpen(false);
  };

  const removeLabelsMultiple = async (e) => {
    dispatch({ type: 'SHOW_LABELS', value: false });
    if (editedLabel) {
      dispatch({ type: 'SET_DIGEST_LOADING', value: true });
      try {
        await removeTags({
          data: {
            digests: selectedDigestsId,
            labels: currentLabels,
          },
        });
        ToastsStore.success('Labels updated');
        setTimeout(() => handleFetchDigestList(), 1000);
      } catch {
        ToastsStore.error('Unable to update labels');
      }
    }
    setRemoveTagsOpen(false);
  };

  const handleSearchLabel = ({ value, field }) => {
    dispatch({ type: 'SET_SEARCH_LABEL', value, field });
    clearTimeout(typingTimeout);
    typingTimeout = setTimeout(() => {
      fetchLabelList({
        field,
        searchVal: value,
      });
    }, 500);
  };

  // const showColorPicker = (key) => {
  //   dispatch({ type: 'SHOW_COLOR_PICKER' });
  //   dispatch({ type: 'SHOW_EDIT', value: key });
  // };

  // const editColor = (color) => {
  //   console.log('dsf', color);
  // }

  return (
    <Layout isDigestList location={location} clearDigestsListFilter={clearDigestsListFilter}>
      <Helmet>
        <title>XND | Dashboard</title>
      </Helmet>
      <StickyFields>
        <ButtonWrapper>
          <SearchWrapper>
            <Input
              icon="search"
              placeholder="Search..."
              value={searchQuery}
              disabled={digestLoading}
              onKeyDown={handleKeyDown}
              onChange={(e, data) => searchDigest(data.value)}
              iconPosition="left"
              action={
                <Icon
                  name="x"
                  link
                  className={searchQuery.length ? 'pull-right left-auto' : 'hiddenField'}
                  onClick={() => searchDigest('')}
                />
              }
            />
            <FilterCustomDiv
              style={{ position: 'relative' }}
              // onBlur={(e) => openFilterLabels(e)}
              // tabIndex="-1"
            >
              <CustomDropdown onClick={openFilterLabels}>
                Filter by Labels
                <Icon name="filter" size="small" />
              </CustomDropdown>
              {showFilterLabels && (
                <DropdownList role="list">
                  <LabelButtonWrapper style={{ display: 'flex' }}>
                    <Dropdown.Header content="Select Labels" />
                    <FlexButtons>
                      <CustomButton
                        onClick={() =>
                          dispatch({ type: 'SET_FILTER_LABELS', options: selectedLabelsTemp })
                        }
                      >
                        <Icon name="check" />
                      </CustomButton>
                      <CustomButton onClick={openFilterLabels}>
                        <Icon name="cancel" />
                      </CustomButton>
                    </FlexButtons>
                  </LabelButtonWrapper>
                  <DropdownItemMain>
                    <Input
                      onChange={(e, selected) => {
                        handleSearchLabel({
                          value: selected.value,
                          field: 'main',
                        });
                      }}
                      placeholder="Search labels"
                    />
                  </DropdownItemMain>
                  {mainLabels.map((option) => (
                    <DropdownItemMain
                      key={option.key}
                      value={option.value}
                      onClick={() => filterLabel(option)}
                    >
                      <DropdownItem>
                        <DropdownColor
                          style={{ backgroundColor: option.value }}
                          selected={selectedLabelsTemp.some((val) => val.key === option.key)}
                        >
                          <span>{option.text}</span>
                          <Icon name="check" />
                        </DropdownColor>
                      </DropdownItem>
                    </DropdownItemMain>
                  ))}
                </DropdownList>
              )}
            </FilterCustomDiv>
          </SearchWrapper>
        </ButtonWrapper>
        <TagsPills />
        <ListWrapper>
          {Object.keys(suggestedFilters).length > 0 && (
            <>
              {suggestedFilters.tags.results.length > 0 && (
                <PillWrapper>
                  Tags
                  <Pills
                    current={current}
                    filters={suggestedFilters.tags.results}
                    editField={editTags}
                    edited={edited}
                    edit={edit}
                  />
                </PillWrapper>
              )}
              {suggestedFilters.sources.results.length > 0 && (
                <PillWrapper>
                  Sources
                  <Pills
                    current={current}
                    filters={suggestedFilters.sources.results}
                    editField={editSource}
                    edited={edited}
                    edit={edit}
                  />
                </PillWrapper>
              )}
            </>
          )}
          {!filterLoading && (
            <TableWrapper>
              <SliderWrapperContainer
                ref={(a) => {
                  if (!a) return;
                  const sliderWrapper = a.querySelector('.slider-wrapper');
                  if (sliderWrapper) {
                    a.style.height = `${sliderWrapper.offsetHeight}px`;
                  }
                  sliderWrapperRef.current = a;
                }}
              >
                <SliderWrapper fixed={false} className="slider-wrapper">
                  <SliderContainer>
                    <Slider
                      sliderData={statuses}
                      total={totalStatus}
                      selectedStatus={selectedStatus}
                      onClickItem={onClickDigestsCategory}
                    />
                  </SliderContainer>
                </SliderWrapper>
              </SliderWrapperContainer>
              {selectedDigestsId.length > 0 && (
                <TableHeader>
                  <div>{`${selectedDigestsId.length} selected digests`}</div>
                  <Flex>
                    {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                      <TableHeaderDelete
                        onClick={() => {
                          dispatch({
                            type: 'SET_DELETE_MODAL',
                            value: true,
                            deleteDigestsId: selectedDigestsId,
                          });
                        }}
                      >
                        <Icon name="archive" />
                        <span>Delete</span>
                      </TableHeaderDelete>
                    )}
                    {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                      <TableHeaderDelete
                        onClick={() => {
                          dispatch({
                            type: 'SET_ADD_TAGS_MODAL',
                            value: true,
                            deleteDigestsId: selectedDigestsId,
                          });
                        }}
                      >
                        {/* <Icon name="archive" /> */}
                        <span></span>
                        <span>Add Tags</span>
                      </TableHeaderDelete>
                    )}
                    {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                      <TableHeaderDelete
                        onClick={() => {
                          dispatch({
                            type: 'SET_REMOVE_TAGS_MODAL',
                            value: true,
                            deleteDigestsId: selectedDigestsId,
                          });
                        }}
                      >
                        {/* <Icon name="archive" /> */}
                        <span>Remove Tags</span>
                      </TableHeaderDelete>
                    )}
                    <TableHeaderDelete
                      onClick={() => {
                        saveSelectedDigests('draft');
                      }}
                    >
                      <Icon name="save" />
                      <span>Save to Draft</span>
                    </TableHeaderDelete>
                    <TableHeaderDelete
                      onClick={() => {
                        saveSelectedDigests('Published');
                      }}
                    >
                      <Icon name="upload" />
                      <span>Publish</span>
                    </TableHeaderDelete>
                    {selectedDigestsId.length === 1 && (
                      <TableHeaderDelete
                        style={{ position: 'relative' }}
                        // onBlur={(e) => closeLabels(e)}
                        // tabIndex="-1"
                      >
                        <CustomDiv onClick={openLabels}>Labels</CustomDiv>
                        {!showLabels ? (
                          <DownArrow onClick={openLabels} />
                        ) : (
                          <UpArrow onClick={closeLabels} />
                        )}
                        {showLabels && (
                          <DropdownList role="list">
                            <LabelButtonWrapper style={{ display: 'flex' }}>
                              <Dropdown.Header content="Select Labels" />
                              <FlexButtons>
                                <CustomButton onClick={setLabels}>
                                  <Icon name="check" />
                                </CustomButton>
                                <CustomButton onClick={openLabels}>
                                  <Icon name="cancel" />
                                </CustomButton>
                              </FlexButtons>
                            </LabelButtonWrapper>
                            <DropdownItemMain>
                              <Input
                                onChange={(e, selected) => {
                                  handleSearchLabel({
                                    value: selected.value,
                                  });
                                }}
                                placeholder="Search labels"
                              />
                            </DropdownItemMain>
                            <DropdownItemMain></DropdownItemMain>
                            {labels.map((option) => (
                              <DropdownItemMain
                                key={option.value}
                                value={option.value}
                                onClick={() => setLabel(option.key)}
                              >
                                <DropdownItem>
                                  <DropdownColor
                                    style={{ backgroundColor: option.value }}
                                    selected={currentLabels.includes(option.key)}
                                  >
                                    <span>{option.text}</span>
                                    <Icon name="check" />
                                  </DropdownColor>
                                </DropdownItem>
                              </DropdownItemMain>
                            ))}
                          </DropdownList>
                        )}
                      </TableHeaderDelete>
                    )}
                    {selectedDigestsId.length > 1 && (
                      <>
                        <TableHeaderDelete
                          style={{ position: 'relative' }}
                          // onBlur={(e) => closeLabels(e)}
                          // tabIndex="-1"
                        >
                          <CustomDiv onClick={TagsOpen}>Add Labels</CustomDiv>
                          {!addTagsOpen ? (
                            <DownArrow onClick={TagsOpen} />
                          ) : (
                            <UpArrow onClick={TagsClose} />
                          )}
                          {addTagsOpen && (
                            <DropdownList role="list">
                              <LabelButtonWrapper style={{ display: 'flex' }}>
                                <Dropdown.Header content="Select Labels" />
                                <FlexButtons>
                                  <CustomButton onClick={addLabelsMultiple}>
                                    <Icon name="check" />
                                  </CustomButton>
                                  <CustomButton onClick={TagsClose}>
                                    <Icon name="cancel" />
                                  </CustomButton>
                                </FlexButtons>
                              </LabelButtonWrapper>
                              <DropdownItemMain>
                                <Input
                                  onChange={(e, selected) => {
                                    handleSearchLabel({
                                      value: selected.value,
                                    });
                                  }}
                                  placeholder="Search labels"
                                />
                              </DropdownItemMain>
                              <DropdownItemMain></DropdownItemMain>
                              {labels.map((option) => (
                                <DropdownItemMain
                                  key={option.value}
                                  value={option.value}
                                  onClick={() => setLabel(option.key)}
                                >
                                  <DropdownItem>
                                    <DropdownColor
                                      style={{ backgroundColor: option.value }}
                                      selected={currentLabels.includes(option.key)}
                                    >
                                      <span>{option.text}</span>
                                      <Icon name="check" />
                                    </DropdownColor>
                                  </DropdownItem>
                                </DropdownItemMain>
                              ))}
                            </DropdownList>
                          )}
                        </TableHeaderDelete>
                        <TableHeaderDelete
                          style={{ position: 'relative' }}
                          // onBlur={(e) => closeLabels(e)}
                          // tabIndex="-1"
                        >
                          <CustomDiv onClick={rTagsOpen}>Remove Labels</CustomDiv>
                          {!removeTagsOpen ? (
                            <DownArrow onClick={rTagsOpen} />
                          ) : (
                            <UpArrow onClick={rTagsClose} />
                          )}
                          {removeTagsOpen && (
                            <DropdownList role="list">
                              <LabelButtonWrapper style={{ display: 'flex' }}>
                                <Dropdown.Header content="Select Labels" />
                                <FlexButtons>
                                  <CustomButton onClick={removeLabelsMultiple}>
                                    <Icon name="check" />
                                  </CustomButton>
                                  <CustomButton onClick={rTagsClose}>
                                    <Icon name="cancel" />
                                  </CustomButton>
                                </FlexButtons>
                              </LabelButtonWrapper>
                              <DropdownItemMain>
                                <Input
                                  onChange={(e, selected) => {
                                    handleSearchLabel({
                                      value: selected.value,
                                    });
                                  }}
                                  placeholder="Search labels"
                                />
                              </DropdownItemMain>
                              <DropdownItemMain></DropdownItemMain>
                              {labels.map((option) => (
                                <DropdownItemMain
                                  key={option.value}
                                  value={option.value}
                                  onClick={() => setLabel(option.key)}
                                >
                                  <DropdownItem>
                                    <DropdownColor
                                      style={{ backgroundColor: option.value }}
                                      selected={currentLabels.includes(option.key)}
                                    >
                                      <span>{option.text}</span>
                                      <Icon name="check" />
                                    </DropdownColor>
                                  </DropdownItem>
                                </DropdownItemMain>
                              ))}
                            </DropdownList>
                          )}
                        </TableHeaderDelete>
                      </>
                    )}
                  </Flex>
                </TableHeader>
              )}
              {Object.keys(filterObject).length > 0 && (
                <TableHeader>
                  <FilterPills filterObject={filterObject} onSelectFilter={onSelectFilter} />
                </TableHeader>
              )}

              <TableHeader>
                <TagPillsFilter
                  tagsData={slectedTags}
                  handleTagsMethod={(data, region) => {
                    clearFIlterDigest(data, region);
                  }}
                />
              </TableHeader>
            </TableWrapper>
          )}
        </ListWrapper>
      </StickyFields>
      {!filterLoading ? (
        <ListWrapper
        // style={{
        //   marginTop:
        //     Object.keys(filterObject).length > 0
        //       ? selectedDigestsId.length > 0
        //         ? 235
        //         : 195
        //       : selectedDigestsId.length > 0
        //       ? 190
        //       : 50,
        // }}
        >
          <TableWrapper>
            {!isMobile ? (
              <TableView
                tableColumns={
                  process.env.REACT_APP_ENV === 'clip-production' ? columnsClip : columns
                }
                data={digestsList}
                pagination
                pageSize={10}
                totalItems={digestsTotalCount}
                activePage={currentPage}
                onPageChange={(value) => dispatch({ type: 'SET_CURRENT_PAGE', value })}
                toggleSortOrder={(sort) => dispatch({ type: 'SET_SORT', sortBy: sort })}
                sortBy={sortBy}
                sortOrder={sortOrder === 'asc' ? 'ascend' : 'descend'}
                // scroll={{x: false, y: false }}
                onRow={(record) => ({
                  onClick: (e) => {
                    // if (record.type && videoCodes.includes(record.type.code)) {
                    //   history.push(`/video/${record.id}`, {
                    //     urlString: record.source.link,
                    //   });
                    // }
                    // // if (record.type && newsCodes.includes(record.type.code))
                    // else {
                    history.push(`/digests/${record.id}`, {
                      urlString: record.source.link,
                    });
                    // }
                  },
                })}
              />
            ) : (
              <>
                <MobileList
                  categoriesList={categoriesList}
                  digestsList={digestsList}
                  selectedDigestsId={selectedDigestsId}
                  feUrl={feUrl}
                  selectedCategory={selectedCategory}
                  selectedSource={selectedSource}
                  sources={sources}
                  setDraftModal={setDraftModal}
                  setDeleteModal={setDeleteModal}
                  categoryFilterSelect={categoryFilterSelect}
                  sourceFilterSelect={sourceFilterSelect}
                  qualityFilterSelect={qualityFilterSelect}
                  relevanceScoreFilterSelect={relevanceScoreFilterSelect}
                  selectDigest={selectDigest}
                  selectAllDigests={selectAllDigests}
                  editDigest={editDigest}
                  quality={quality}
                  relevanceScore={relevanceScore}
                  filteredTags={filteredTags}
                  filteredRegions={filteredRegions}
                />
                {digestsTotalCount > 10 && (
                  <PaginationWrapper>
                    <Pagination
                      activePage={currentPage}
                      totalItems={digestsTotalCount}
                      totalPages={Math.round(digestsTotalCount / 10)}
                      onChangePage={(currentVal) =>
                        dispatch({ type: 'SET_CURRENT_PAGE', value: currentVal })
                      }
                      pageSize={10}
                    />
                  </PaginationWrapper>
                )}
              </>
            )}
          </TableWrapper>
        </ListWrapper>
      ) : (
        <LoaderWrapper>
          <Loader active={digestLoading} />
        </LoaderWrapper>
      )}
      {digestLoading && (
        <LoaderWrapper>
          <Loader active={digestLoading} />
        </LoaderWrapper>
      )}
      {openEditTagModal && (
        <TagsModal
          show
          onClose={() => {
            dispatch({ type: 'SET_TAG_EDIT_MODAL', value: false });
          }}
          value={editTagsData.tags}
          handleSave={(data) => {
            dispatch({ type: 'SET_TAG_EDIT_MODAL', value: false });
            handleEditData(data, editTagsData.digestId);
          }}
        />
      )}
      {openTagModal === true ? (
        <FilterTagModal
          show
          onClose={() => {
            setOpenTagModal(false);
          }}
          value={slectedTags}
          handleSave={(data, region) => {
            filterDisgestbyTags(data, region);
          }}
        />
      ) : null}

            {/* show, onClose, value, handleSave */}
      {openDeleteModal && (
        <Modal size="tiny" open={openDeleteModal} onClose={() => {}}>
          <Modal.Content>Are you sure you want to delete digests?</Modal.Content>
          <Modal.Actions>
            <Button
              negative
              disabled={deleteDigestsLoading}
              onClick={() => {
                dispatch({ type: 'SET_DELETE_MODAL', value: false, deleteDigestsId: [] });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              content="Yes delete"
              disabled={deleteDigestsLoading}
              onClick={() => {
                dispatch({ type: 'SET_DELETE_DIGESTS_LOADING', value: true });
                deleteDigestApi({ digestIds: deleteDigestsId })
                  .then((res) => {
                    setTimeout(() => handleFetchDigestList(), 1000);
                    fetchCurrentStatuses();
                    dispatch({ type: 'SET_DELETE_MODAL', value: false });
                    ToastsStore.success('Digests deleted successfully.');
                  })
                  .catch((error) => {
                    if (window) window.Raven.captureException(error.response);
                    dispatch({ type: 'SET_DELETE_MODAL', value: false });
                    if (error.response.status === 400) {
                      ToastsStore.error(
                        (error.response.data &&
                          error.response.data.title &&
                          error.response.data.title[0]) ||
                          'Something went wrong',
                      );
                    } else {
                      ToastsStore.error(
                        (error.response && error.response.message) || 'Something went wrong',
                      );
                    }
                  });
              }}
            />
          </Modal.Actions>
        </Modal>
      )}

      {openCopyModal && (
        <Modal size="tiny" open={openCopyModal} onClose={() => {}}>
          <Modal.Content>Are you sure you want to copy digest?</Modal.Content>
          <Modal.Actions>
            <Button
              negative
              disabled={deleteDigestsLoading}
              onClick={() => {
                dispatch({ type: 'SET_COPY_MODAL', value: false, deleteDigestsId: [] });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              content="Yes copy"
              disabled={deleteDigestsLoading}
              onClick={() => {
                dispatch({ type: 'SET_DELETE_DIGESTS_LOADING', value: true });
                copyDigestApi({ digestId: deleteDigestsId[0] })
                  .then((res) => {
                    handleFetchDigestList();
                    fetchCurrentStatuses();
                    dispatch({ type: 'SET_COPY_MODAL', value: false });
                    ToastsStore.success('Digest copied successfully.');
                    window.open(`/digests/${res.data.id}`, '_blank');
                  })
                  .catch((error) => {
                    if (window) window.Raven.captureException(error.response);
                    dispatch({ type: 'SET_COPY_MODAL', value: false });
                    if (error.response.status === 400) {
                      ToastsStore.error(
                        (error.response.data &&
                          error.response.data.title &&
                          error.response.data.title[0]) ||
                          'Something went wrong',
                      );
                    } else {
                      ToastsStore.error(
                        (error.response && error.response.message) || 'Something went wrong',
                      );
                    }
                  });
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
      {openAddTagsModal && (
        <AddTagsModal
          onCloseModal={() => {
            dispatch({ type: 'SET_ADD_TAGS_MODAL', value: false });
          }}
          digestIds={deleteDigestsId}
        />
      )}
      {openRemoveTagsModal && (
        <RemoveTagsModal
          onCloseModal={() => {
            dispatch({ type: 'SET_REMOVE_TAGS_MODAL', value: false });
          }}
          digestIds={deleteDigestsId}
        />
      )}
      {confirm && (
        <Modal size="tiny" open={confirm}>
          <Modal.Content>
            {`This will send notification to all the ${
              selectedType === 'all' ? 'users' : 'channel followers'
            }. Are you sure you want to
            proceed ?`}
          </Modal.Content>
          <Modal.Actions>
            <Button positive content="Yes" onClick={addAll} />
            <Button tertiary onClick={remove} content="No" />
          </Modal.Actions>
        </Modal>
      )}
      {sendNotificationModal && (
        <Modal
          size="small"
          open={sendNotificationModal}
          onClose={() =>
            dispatch({
              type: 'SEND_NOTIFICATION',
              value: false,
              digest: {},
            })
          }
          closeIcon
        >
          <Modal.Header>Send Notification</Modal.Header>
          <Modal.Content>
            <FlexFields>
              <Label>Users*</Label>
              <Container>
                <Dropdown
                  placeholder="Select users"
                  options={userList}
                  value={digest.user_list_type}
                  selection
                  search
                  onChange={(e, selected) => {
                    handleChangeValue({ type: 'user_list_type', value: selected.value });
                  }}
                />
              </Container>
            </FlexFields>
            <FlexFields>
              <Label>Title*</Label>
              <Container>
                <Input
                  placeholder="Title"
                  value={digest.notification_title}
                  onChange={(e, selected) => {
                    handleChangeValue({
                      type: 'notification_title',
                      value: selected.value,
                    });
                  }}
                />
              </Container>
            </FlexFields>
            <FlexFields>
              <Label>Text</Label>
              <Container>
                <TitleField
                  placeholder="Text"
                  value={digest.notification_text}
                  onChange={(e) => {
                    handleChangeValue({ type: 'notification_text', value: e.target.value });
                  }}
                />
              </Container>
            </FlexFields>
            <FlexFields>
              <Label>Dynamic Field</Label>
              <Container>
                <Dropdown
                  placeholder="Select field"
                  options={dynamicFields}
                  value={digest.bottom_tab_index}
                  selection
                  search
                  onChange={(e, selected) => {
                    handleChangeValue({ type: 'bottom_tab_index', value: selected.value });
                  }}
                />
              </Container>
            </FlexFields>
            <ButtonWrapper>
              <FlexFields>
                <Label>Test Users*</Label>
                <Container>
                  <Dropdown
                    placeholder="Select users"
                    value={digest.test_user}
                    options={userList.filter((list) => list.value !== 'channel_follower')}
                    selection
                    search
                    onChange={(e, selected) => {
                      handleChangeValue({
                        type: 'test_user',
                        value: selected.value,
                      });
                    }}
                  />
                </Container>
              </FlexFields>
            </ButtonWrapper>
            <ButtonWrapper mt="30px">
              <Checkbox
                label="Schedule Notification"
                onClick={() => {
                  dispatch({ type: 'SCHEDULE_NOTIF' });
                }}
              />
            </ButtonWrapper>
            {scheduleNotification && (
              <DatePickerScroller className="date-scroll">
                <DateTimeWrapper>
                  <Datetime
                    value={
                      scheduledTime
                        ? moment(new Date(scheduledTime))
                        : moment(new Date()).add(5, 'minutes')
                    }
                    dateFormat="MMM DD YYYY"
                    timeFormat="hh:mm A"
                    isValidDate={disablePastDt}
                    onChange={(value) => {
                      if (typeof value === 'object') {
                        if (moment(new Date()).add(5, 'minutes').isAfter(value))
                          ToastsStore.error(
                            'Please select atleast five minute more than current time',
                          );
                        else
                          dispatch({
                            type: 'SET_DATE_TIME',
                            value: new Date(value.format('MMM DD YYYY hh:mm A')),
                          });
                      }
                    }}
                  />
                </DateTimeWrapper>
              </DatePickerScroller>
            )}
          </Modal.Content>
          <Modal.Actions>
            <ButtonWrapper mt="20px">
              <Button
                type="button"
                disabled={digestLoading || !(digest.user_list_type && digest.title && digest.text)}
                content="Test Notification"
                tertiary
                onClick={() => {
                  dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                  sendNotification('test');
                }}
              />
              <Button
                positive
                type="submit"
                onClick={() => {
                  dispatch({ type: 'SET_DIGEST_LOADING', value: true });
                  sendNotification(null);
                }}
                disabled={digestLoading || !(digest.user_list_type && digest.title && digest.text)}
              >
                {scheduleNotification ? 'Schedule Notification' : 'Send Notification'}
              </Button>
            </ButtonWrapper>
          </Modal.Actions>
        </Modal>
      )}
      <Modal size="tiny" open={digestModalStatus} onClose={() => {}}>
        <Modal.Content>Are you sure you want the digest to be {digestStatus}?</Modal.Content>
        <Modal.Actions>
          <Button
            negative
            disabled={updateDigestLoading}
            onClick={() => {
              dispatch({
                type: 'DRAFT_MODAL_STATUS',
                digestModalStatus: false,
                digestStatus: '',
                digest: {},
              });
            }}
          >
            Cancel
          </Button>
          <Button
            positive
            disabled={updateDigestLoading}
            loading={updateDigestLoading}
            content={digest.status !== 'Published' ? 'Publish' : 'Save to draft'}
            onClick={() => {
              dispatch({ type: 'SET_UPDATE_DIGESTS_LOADING', value: true });
              updateDigest({
                data: {
                  status: digest.status !== 'Published' ? 'Published' : 'Draft',
                },
                id: digest.id,
              })
                .then(() => {
                  handleFetchDigestList();
                  fetchCurrentStatuses();
                  dispatch({
                    type: 'DRAFT_MODAL_STATUS',
                    digestModalStatus: false,
                    digestStatus: '',
                    digest: {},
                  });
                  ToastsStore.success('Digests updated successfully.');
                })
                .catch((error) => {
                  if (window) window.Raven.captureException(error.response);
                  dispatch({
                    type: 'DRAFT_MODAL_STATUS',
                    digestModalStatus: false,
                    digestStatus: '',
                    digest: {},
                  });
                  if (error.response.status === 400) {
                    ToastsStore.error(
                      (error.response.data &&
                        error.response.data.title &&
                        error.response.data.title[0]) ||
                        'Something went wrong',
                    );
                  } else {
                    ToastsStore.error(
                      (error.response && error.response.message) || 'Something went wrong',
                    );
                  }
                });
            }}
          />
        </Modal.Actions>
      </Modal>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
    </Layout>
  );
};

Digests.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default Digests;
