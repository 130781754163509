import React, { useEffect, useReducer } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Loader, Message, Button, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { fetchFeedCategories, deleteFeedCategories } from '../../Api/';
import Pagination from '../../Components/Pagination';
import Layout from '../../Components/Layout';

const Header = styled.div`
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.25em;
    margin-bottom: 3rem;
`;

const IconGroup = styled.div``;

const CategoryListItem = styled(Link)`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: baseline;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    max-width: 600px;
    padding: 1.5rem;
    text-transform: capitalize;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;

  .rc-pagination-prev,
  .rc-pagination-next, rc-pagination-item-active {
    outline: none;
    border-color: transparent;
  }
`;

const initialValue = {
    categories: [],
    searchQuery: '',
    currentPage: 1,
    totalItems: 0,
    loading: false,
    openDeleteModal: {},
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'CATEGORIES_SET':
          draft.categories = action.value;
          draft.totalItems = action.totalItems;
          break;
      case 'SET_SEARCH_QUERY':
        draft.searchQuery = action.value;
        break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        break;
      case 'LOADING':
        draft.loading = !draft.loading;
        break;
      case 'SET_DELETE_MODAL':
        draft.openDeleteModal = action.value;
        break;
    }
  });

const NewsBoard = props => {
    const [state, dispatch] = useReducer(reducer, initialValue);
    const { categories, currentPage, totalItems, loading, openDeleteModal  } = state;

    useEffect(() => {
        dispatch({ type: 'LOADING' });
        fetchFeedCategories(currentPage).then(response => {
            dispatch({ type: 'CATEGORIES_SET', value: response.data.results, totalItems: response.data.count });
            dispatch({ type: 'LOADING' });
        });
    }, [currentPage]);

    const deleteCategory = (event, category) => {
        event.stopPropagation();
        event.preventDefault();
        dispatch({ type: 'SET_DELETE_MODAL', value: category });
    }

    const deleteCategoryConfirm = () => {
        dispatch({ type: 'LOADING' });
        deleteFeedCategories(openDeleteModal.id).then(() => {
            ToastsStore.success(`Board ${openDeleteModal.title} deleted!`);
            fetchFeedCategories(currentPage).then(response => {
                dispatch({ type: 'SET_DELETE_MODAL', value: {} });
                dispatch({ type: 'CATEGORIES_SET', value: response.data.results, totalItems: response.data.count });
                dispatch({ type: 'LOADING' });
            });
        }).catch(error => {
            dispatch({ type: 'LOADING' });
            ToastsStore.error(error.response);
        });
    }

    return (
            <Layout location={props.location} feed={true}>
                <Header>
                    All Boards
                </Header>
                {!loading ? (
                    <>
                        {categories.length > 0 ? (
                            categories.map(category => (
                                <CategoryListItem
                                    to={`/board-details/${category.id}`}
                                    key={category.id}
                                >
                                    {category.title}
                                    <IconGroup>
                                    <Button
                                        basic
                                        to={{
                                            pathname: `/board/${category.id}`,
                                            state: category,
                                        }}
                                        as={Link}
                                        icon="edit"
                                    />
                                    <Button
                                        basic
                                        onClick={event => deleteCategory(event, category)}
                                        icon="cancel"
                                    />
                                    </IconGroup>
                                </CategoryListItem>
                            ))
                        ) : (
                                <Message info className="margin-top-20">
                                     <Message.Header>No Data Found</Message.Header>
                                </Message>
                        )}
                        {totalItems > 20 && (
                            <PaginationWrapper>
                            <Pagination
                                activePage={currentPage}
                                totalItems={totalItems}
                                totalPages={Math.round(totalItems / 20)}
                                    onChangePage={current => dispatch({ type: 'SET_CURRENT_PAGE', value: current })}
                                    pageSize={20}
                            />
                            </PaginationWrapper>
                        )}
                    </>
                ) : <Loader active={loading} />
                }
                {Object.keys(openDeleteModal).length > 0 && (
                    <Modal size="tiny" open={Object.keys(openDeleteModal).length}>
                        <Modal.Content>
                        Are you sure you want to delete board `{openDeleteModal.title}`?
                        </Modal.Content>
                        <Modal.Actions>
                        <Button
                            negative
                            disabled={loading}
                            onClick={() => {
                            dispatch({ type: 'SET_DELETE_MODAL', value: {} })
                            }}
                        >Cancel</Button>
                        <Button
                            positive
                            content='Yes delete'
                            disabled={loading}
                            onClick={deleteCategoryConfirm}
                        />
                        </Modal.Actions>
                    </Modal>
                )}
                <ToastsContainer
                    position={ToastsContainerPosition.BOTTOM_LEFT}
                    store={ToastsStore}
                />
                </Layout>
    )
};

export default NewsBoard;