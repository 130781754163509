import React from 'react';
import PropTypes from 'prop-types';
// import styled from 'styled-components';
import TableView from '../../Components/TableView';

// const Wrapper = styled.div``;

const TypeTable = ({ data }) => {
  const columns = [
    {
      title: 'Type Title',
      dataIndex: 'type_id__title',
      key: 'type_id__title',
      render: (value) => value || '-',
      align: 'center',
    },
    {
      title: 'Number of Digests',
      dataIndex: 'count',
      key: 'count',
      render: (value) => value || '-',
      align: 'center',
    },
  ];
  return (
    <>
      <TableView tableColumns={columns} data={data} />
    </>
  );
};

TypeTable.propTypes = {
  data: PropTypes.object,
};

export default TypeTable;
