export const notificationTypes = [
  { key: '', value: '', text: '' },
  {
    key: 'HOME',
    value: 'Home',
    text: 'Home',
  },
  {
    key: 'SHOW_PUBLICATION_FEED_LIST',
    value: 'Publications',
    text: 'Publications',
  },
  {
    key: 'SHOW_CATEGORY_FEED_LIST',
    value: 'Categories',
    text: 'Categories',
  },
  { key: 'SHOW_ARTICLE', value: 'Articles', text: 'Articles' },
  { key: 'SHOW_PILLS', value: 'Pills', text: 'Pills' },
  {
    key: 'OPEN_FORMATTED_ARTICLES',
    value: 'Formatted articles',
    text: 'Formatted articles',
  },
  { key: 'SHOW_TAG_FEED_LIST', value: 'Tags', text: 'Tags' },
];
