import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Dashboard from './Container/Dashboard';
import AdminPoll from './Container/AdminPollView';
import Digests from './Container/Digests';
import LoginPage from './Container/LoginPage';
import VideoSearch from './Container/VideoSummary/VideoSearch';
import DailyReport from './Container/DailyReport';
import VideoSummaryCreate from './Container/VideoSummary/VideoSummaryCreate';
import Users from './Container/Users';
import NewsFeeds from './Container/NewsFeeds';
import NewsBoard from './Container/NewsBoards';
import Notifications from './Container/Notifications';
import NotificationCreate from './Container/NotificationCreate';
import ViewGroup from './Container/Groups';
import Tags from './Container/Tags';
import NewsBoardDetails from './Container/NewsBoardDetails';
import NewsFeedsCreate from './Container/NewsFeedsCreate';
import NewsBoardCreate from './Container/NewsBoardCreate';
import Settings from './Container/Settings';
import AddStatus from './Container/AddStatus';
import 'semantic-ui-css/semantic.min.css';
import { setAxiosAuthorizationHeader } from './Utils/axios';
import ProtectedRoute from './Components/ProtectedRoute';
import 'antd/dist/antd.css';
import 'draft-js/dist/Draft.css';
import loadYtApi from './Utils/LoadYtApi';
import VideoEdit from 'Container/VideoEdit';
import VideoPreview from 'Container/VideoEdit/VideoPreview';
import Headlines from 'Container/Headlines';

function App() {
  const [siteLoading, setSiteLoading] = useState(true);

  useEffect(() => {
    if (siteLoading) {
      setTimeout(() => {
        setSiteLoading(false);
      }, 100);
    }
  }, [siteLoading]);

  loadYtApi();
  setAxiosAuthorizationHeader();
  return (
    <>
      {!siteLoading && (
        <Router>
          <Switch>
            <Route path="/login" exact component={LoginPage} />
            <ProtectedRoute path="/" exact component={Digests} authorizedFor="all" />
            <ProtectedRoute path="/poll" exact component={AdminPoll} authorizedFor="all" />
            <ProtectedRoute path="/users" exact component={Users} authorizedFor="super_user" />
            <ProtectedRoute
              path="/settings"
              exact
              component={Settings}
              authorizedFor="super_user"
            />
            <ProtectedRoute
              path="/settings/:id"
              exact
              component={AddStatus}
              authorizedFor="super_user"
              key="editStatus"
            />
            <ProtectedRoute
              path="/status-add"
              exact
              component={AddStatus}
              authorizedFor="super_user"
              key="addStatus"
            />
            <ProtectedRoute path="/feeds" exact component={NewsFeeds} authorizedFor="super_user" />
            <ProtectedRoute path="/boards" exact component={NewsBoard} authorizedFor="super_user" />
            <ProtectedRoute
              path="/feed"
              exact
              component={NewsFeedsCreate}
              authorizedFor="super_user"
              key="createfeed"
            />
            <ProtectedRoute
              path="/feed/:id"
              exact
              component={NewsFeedsCreate}
              authorizedFor="super_user"
              key="editfeed"
            />
            <ProtectedRoute
              path="/board-details/:categoryId/feed/:id/details"
              exact
              component={NewsFeeds}
              authorizedFor="super_user"
              key="viewCategoryFeedDetails"
            />
            <ProtectedRoute
              path="/board"
              exact
              component={NewsBoardCreate}
              authorizedFor="super_user"
              key="createboard"
            />
            <ProtectedRoute
              path="/board/:id"
              exact
              component={NewsBoardCreate}
              authorizedFor="super_user"
              key="editboard"
            />
            <ProtectedRoute
              path="/board-details/:categoryId"
              exact
              component={NewsFeeds}
              authorizedFor="super_user"
              key="viewCategoryFeeds"
            />
            <ProtectedRoute
              path="/board-details/:categoryId/sources/"
              exact
              component={NewsBoardDetails}
              authorizedFor="super_user"
            />
            <ProtectedRoute
              path="/digest-create"
              exact
              component={Dashboard}
              authorizedFor="all"
              key="createDigest"
            />
            <ProtectedRoute
              path="/digests/:id"
              exact
              component={Dashboard}
              authorizedFor="all"
              key="editDigest"
            />

            <ProtectedRoute
              path="/video/edit/:id"
              exact
              component={VideoEdit}
              authorizedFor="all"
            />
            <ProtectedRoute
              path="/video/preview/:id"
              exact
              component={VideoPreview}
              authorizedFor="all"
            />

            <ProtectedRoute
              path="/video/search"
              exact
              component={VideoSearch}
              authorizedFor="all"
            />
            <ProtectedRoute
              path="/dailyReports"
              exact
              component={DailyReport}
              authorizedFor="all"
            />
            <ProtectedRoute
              path="/video/create"
              exact
              component={VideoSummaryCreate}
              authorizedFor="all"
            />
            <ProtectedRoute
              path="/video/:id"
              exact
              component={VideoSummaryCreate}
              authorizedFor="all"
            />
            <ProtectedRoute
              path="/notifications"
              exact
              component={Notifications}
              authorizedFor="super_user"
            />
            <ProtectedRoute
              path="/notification/create"
              exact
              component={NotificationCreate}
              authorizedFor="super_user"
            />
            <ProtectedRoute
              path="/notifications/groups"
              exact
              component={ViewGroup}
              authorizedFor="super_user"
            />
            <ProtectedRoute path="/tags" exact component={Tags} authorizedFor="all" />
            <ProtectedRoute path="/headlines" exact component={Headlines} authorizedFor="all" />

          </Switch>
        </Router>
      )}
    </>
  );
}

export default App;
