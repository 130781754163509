import Layout from 'Components/Layout';
import React, { useCallback, useEffect, useMemo, useReducer, memo } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import produce from 'immer';
import { Loader, Icon, Dropdown, Button, Modal, TableHeader,Checkbox } from 'semantic-ui-react';
import moment from 'moment';
import TableView from 'Components/TableView';
import { createNewDigest, fetchHeadlinesApi, fetchNotificationSources, fetchNotificationPackages, updateHeadlinesStatus, DeclusterHeadlinesAPI } from 'Api';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Slider from 'Components/Slider';
import { useHistory, Link } from 'react-router-dom';
import CreateDigestForm from './createDigestForm';
import Multiselect from 'multiselect-react-dropdown';
import queryString from 'query-string';
import FilterPills from 'Container/Digests/FilterPills';
import ReClusterModal from './ReClusterModal';
import HeadlineTagsPills from './HeadlineTagPills';

const tabs = ['UNPROCESSED', 'PROCESSING', 'PUBLISHED', 'REJECTED'];

const TableWrapper = styled.div`
  margin-top: 20px;

  @media (max-width: 768px) {
    margin-top: 10px;
  }

`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 200;
`;

const IconWrapper = styled.div`
  i {
    color: ${({ disabled }) => (disabled ? '#a2a2a2' : '#52575a')};
  }
  padding: 0 5px;
`;

const FlexIcon = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 5px;
`;

const SliderWrapperContainer = styled.div`
  margin: 15px 0;

  @media (max-width: 768px) {
    margin: 5px 0;
  }
`;

const SliderWrapper = styled.div`
  width: 100%;
  position: ${({ fixed }) => (fixed ? 'fixed' : 'static')};
  top: 0;
  background-color: #fff;
  z-index: 10;
  padding: 10px 0;
  ${({ fixed }) =>
    fixed
      ? `
    box-shadow: 0 2px 2px -2px rgba(0,0,0,0.15);
  `
      : ``}
  .slick-prev:before, .slick-next:before {
    color: #000;
  }
  .slick-slide {
    outline: none !important;
  }
  .slick-arrow:before {
    display: none;
  }
  .slick-prev,
  .slick-next {
    height: auto;
  }
  .slick-next {
    right: -40px;
  }
`;

const SliderContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  text-transform: uppercase;
  max-width: 95%;
  @media (max-width: 1024px) {
    width: 95%;
  }
  @media (max-width: 900px) {
    width: 95%;
  }
  @media (max-width: 768px) {
    width: 100%;
    /* padding: 0 30px; */
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 6%;
  height:40px;
`;
const HideButton = styled(Button)`
  cursor: pointer;
`;

const FilterDropDowns = styled.div`    
  display:flex;  
  gap:10px;
  justify-content:end
`;

const NotificationDropdown = styled.div`    
  width:20% ;  
  .multiselect-container{
    .searchWrapper {
      height:auto;
      padding:7.5px 10px;
    }
  }
  .ui.dropdown .menu>.item:hover {
    background: #1890ff;
    color: rgb(255 255 255 / 95%);
}
`;

const Flex = styled.div`
  display: flex;
  @media (max-width: 768px) {
    margin: 10px auto;
  }
`;

const TableHeaderSubFilter = styled.div`
  display: flex;
  margin-left: 20px;
  align-items: center;
  cursor: pointer;
  span {
    padding: 0 5px;
  }
  @media (max-width: 768px) {
    height: 100%;
    display: block;
    margin-left: 10px;
    font-size: 12px;
    > span {
      height: 1.6em;
    }
    span {
      display: block;
    }
  }
`;
const ListWrapper = styled.div`
  .ui.checkbox {
    min-height: 14px;
  }
`;
const urlParams = new URLSearchParams(window.location.search);
let paramObj = {};
for (var value of urlParams.keys()) {
  paramObj[value] = urlParams.get(value);
}

const initialValue = {
  headlinesList: [],
  createDigestLoading: false,
  headlinesTotalCount: 0,
  headlinesLoading: true,
  currentPage: 1,
  latestId: undefined,
  editable: {},
  selectedStatus: 'UNPROCESSED',
  sortBy: 'score',
  sortOrder: '',
  pageSize: 100,
  createDigest: false,
  notificationData: {},
  hidden: false,
  hiddenData: [],
  notification_source: '',
  notification_source_data: [],
  notification_packages: [],
  notification_packages_id: '',
  notification_package_data: [],
  filterObject: { ...paramObj },
  selectedHeadlinesId: [],
  selectAllHeadline: false,
  isModalOpen: false,
  expandedRowKeys: [],
  selectedTagsIds:[]
};

const parsedDate = (date) => {
  return moment.utc(date);
}
/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_HEADLINES_LIST':
        let temp, uniqueTemp;

        if (action.polling) {
          temp = JSON.parse(JSON.stringify(draft.headlinesList));
          temp.unshift(...action.value);
          uniqueTemp = Array.from(new Set(temp.map((a) => a.id))).map((id) => {
            return temp.find((a) => a.id === id);
          });
          draft.headlinesTotalCount = draft.headlinesTotalCount + action.totalCount - 1;
        } else {
          uniqueTemp = action.value
          draft.headlinesTotalCount = action.totalCount;
        }
        draft.headlinesList = uniqueTemp;
        draft.latestId = action.latestId;
        draft.selectedHeadlinesId = [];
        draft.selectAllHeadline = false;
        break;
      case 'SET_HEADLINES_LOADING':
        draft.headlinesLoading = action.value;
        break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        draft.selectedHeadlinesId = [];
        draft.selectAllHeadline = false;
        break;
      case 'SET_PAGE_SIZE':
        draft.headlinesList = [];
        draft.pageSize = action.value;
        break;
      case 'SET_SORT':
        draft.headlinesList = [];
        if (action.sortBy.dataIndex === draft.sortBy) {
          draft.sortOrder = ""
          draft.sortBy = "";
        } else {
          draft.sortOrder = 'asc';
          draft.sortBy = action.sortBy.dataIndex;
        }
        break;
      case 'SET_EDITABLE':
        if (action.val)
          draft.editable = {
            value: action.data.quality || 0,
            id: action.data.id,
          };
        else draft.editable = {};
        break;
      case 'SET_SELECTED_STATUS':
        draft.selectedStatus = draft.selectedStatus === action.value ? '' : action.value;
        draft.headlinesList = [];
        draft.latestId = undefined;
        draft.currentPage = 1;
        break;
      case 'SET_EDITABLE_VALUE': {
        draft.editable = {
          value: action.val,
          id: action.data.id,
        };
        break;
      }
      case 'SET_DIGEST_CREATE': {
        draft.createDigest = action.value;
        break;
      }
      case 'SET_NOTIFICATION_DATA': {
        draft.notificationData = action.value;
        break;
      }
      case 'SET_DIGEST_LOADING': {
        draft.createDigestLoading = action.value;
        break;
      }
      case 'SET_HIDDEN': {
        let temp;
        if (action.value) {
          draft.hiddenData = draft.headlinesList
          temp = JSON.parse(JSON.stringify(draft.headlinesList));
          temp = temp.filter((headline) => {
            return parsedDate(headline.created_at).isSame(moment(), 'day')
          })
          draft.headlinesList = temp
          draft.headlinesTotalCount = temp.length
        } else {
          draft.headlinesList = draft.hiddenData
          draft.headlinesTotalCount = draft.hiddenData.length
        }
        draft.hidden = action.value;
        break;
      }
      case 'SET_NOTIFICATION_SOURCE': {
        draft.notification_source = action.value;
        break;
      }
      case 'SET_NOTIFICATION_SOURCE_DATA': {
        draft.notification_source_data = action.value;
        break;
      }
      case 'SET_NOTIFICATION_PACKAGE': {
        const { selectedIds, selectedList } = action.value;
        draft.notification_packages_id = selectedIds.join(",");
        draft.notification_packages = selectedList;
        break;
      }
      case 'SET_NOTIFICATION_PACKAGE_DATA': {
        let tempArr = [...draft.notification_package_data]
        action.value.forEach((val) => {
          if(!tempArr.some((item) => item.id === val.id)) {
            tempArr.push(val)
          }
        })

        draft.notification_package_data = tempArr;
        break;
      }     
      case 'SET_SELECTED_HEADLINE': {
        const { id, children } = action.value;
        const selectedHeadlinesId = draft.selectedHeadlinesId;
    
        const index = selectedHeadlinesId.indexOf(id);
    
        if (index > -1) {
            // If the headline is already selected
            if (children && children.length > 0) {
                children.forEach(child => {
                    const childIndex = selectedHeadlinesId.indexOf(child.id);
                    if (childIndex > -1) {
                        selectedHeadlinesId.splice(childIndex, 1);
                    }
                });
            }
            // Remove the headline itself
            const parnetIndex = selectedHeadlinesId.findIndex((data) => data === action.value.id);
            selectedHeadlinesId.splice(parnetIndex, 1);
            draft.selectAllHeadline = false;
        } else {
            // If the headline is not selected
            if (children && children.length > 0) {
                children.forEach(child => selectedHeadlinesId.push(child.id));
            }
            selectedHeadlinesId.push(id);
        }
        draft.selectedHeadlinesId = selectedHeadlinesId;
        break;
   }
      case 'SET_SELECT_ALL_HEADLINES':
        if (draft.selectAllHeadline) {
          draft.selectAllHeadline = false;
          draft.selectedHeadlinesId = [];
        } else if (
          draft.selectedHeadlinesId.length > 0 &&
          draft.selectedHeadlinesId.length <= draft.headlinesTotalCount
        ) {
          draft.selectAllHeadline = false;
          draft.selectedHeadlinesId = [];
        } else {
          draft.selectAllHeadline = true;
          draft.selectedHeadlinesId = draft.headlinesList.map((item) => item.id);
        }  
        break;
      case 'SET_MODAL_FLAG': {
          draft.isModalOpen = action.value;          
          break;
      }      
      case 'SET_EXPANDED_ROW_KEYS': {
          draft.expandedRowKeys = action.value;          
          break;
      }      
      case 'ADD_TAG_IDS': {
        if(draft.selectedTagsIds.includes(action.value)){
          const index = draft.selectedTagsIds.indexOf(action.value);
          draft.selectedTagsIds.splice(index, 1);
        } else {
          draft.selectedTagsIds.push(action.value);
        }                   
        break;
      }      
    }
  });

const statuses = tabs.map((item, index) => ({
  id: index,
  status_name: item,
  slug: item,
}));
const Headlines = ({ location }) => {
  const queryParams = queryString.parse(location.search);
  const { notificationSource, notificationPackages } = queryParams;
  const history = useHistory();
  
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    notification_source: notificationSource, notification_packages_id: notificationPackages,
  });

  const {
    createDigestLoading,
    headlinesList,
    headlinesTotalCount,
    headlinesLoading,
    currentPage,
    latestId,
    editable,
    selectedStatus,
    sortBy,
    sortOrder,
    pageSize,
    createDigest,
    notificationData,
    hidden,
    notification_source,
    notification_source_data,
    notification_packages,
    notification_packages_id,
    notification_package_data,
    selectedHeadlinesId,
    selectAllHeadline,
    isModalOpen,
    expandedRowKeys,
    selectedTagsIds
  } = state;
  useEffect(() => {
    if (notification_package_data?.length > 0) {
        let arr = notificationPackages && notificationPackages?.split(",").map(Number);
        const selected = notification_package_data?.filter(item => {
            return arr?.some(data => item.id === Number(data));
        });
        const selectedPackageId = selected.map((item) => item.id);
        dispatch({ type: 'SET_NOTIFICATION_PACKAGE', value: { "selectedList": selected, "selectedIds": selectedPackageId } })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [notification_package_data.map(item => item.id).join(",")]);

  const CustomExpandIcon = (props) => {
    const { record } = props;
    const { children = [] } = record;

    if (children.length === 0) {
      return null;
    }

    const expanded = expandedRowKeys.includes(record.id);
    return (
      <span onClick={(e) => {
        e.stopPropagation();
        handleExpand(!expanded, record);
      }}>
        {expanded ? <Icon name="angle up" /> : <Icon name="angle down" />}
      </span>
    );
  };

  const handleExpand = (expanded, record) => {
    const newExpandedKeys = expanded
      ? [...expandedRowKeys, record.id]
      : expandedRowKeys.filter(id => id !== record.id);
      dispatch({ type: 'SET_EXPANDED_ROW_KEYS', value: newExpandedKeys });
  };

  const getNameOfNotificationPackage = notification_packages?.map((item) => item?.title);
  const fetchHeadlinesList = useCallback(
    (status, id, polling, sortBy) => {
      let requestParams = {
        page: currentPage,
        status: status,
        id: id,
        sort_by: sortBy,
        page_size: pageSize,        
      };
            
      if (notification_packages_id) {
        requestParams.source = notification_packages_id;
      }
      if (notification_source) {
        requestParams.notification_source = notification_source;
      }
      if (selectedTagsIds && selectedTagsIds.length > 0) {
        requestParams.tag_ids = selectedTagsIds.join(',')
      }
      dispatch({ type: 'SET_HEADLINES_LOADING', value: true });
      let finalList
      fetchHeadlinesApi(requestParams)
        .then((response) => {
          if (response.status === 200) {
            finalList = []
            finalList = response.data.results.map((item) => {
              let newObj = { ...item, children: item.groups };
              delete newObj.groups;
              return newObj;
            })
            dispatch({
              type: 'SET_HEADLINES_LIST',
              value: finalList,
              totalCount: response.data.count,
              latestId: finalList[0]?.id,
              polling: polling,
            });
            dispatch({ type: 'SET_HEADLINES_LOADING', value: false });
          }
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          dispatch({ type: 'SET_HEADLINES_LOADING', value: false });
          ToastsStore.error('Something went wrong!');
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [currentPage, pageSize, notification_source, notification_packages_id,selectedTagsIds],
  );
  //end fetch headlines
  useEffect(() => {
    let searchStr = '';
    if (notification_source)
      if (!searchStr) searchStr = `?notificationSource=${notification_source}`;
      else searchStr += `?notificationSource=${notification_source}`;
      if (notification_packages_id) searchStr = `?notificationPackages=${notification_packages_id}`;
    if (notification_packages?.length) searchStr = `?notificationPackages=${notification_packages.map(packageData => packageData.id)}`;
    if (notification_source && notification_packages_id) searchStr = `?notificationSource=${notification_source}&notificationPackages=${notification_packages_id}`;
    callSearchApi(notification_packages, "package")
    history.push({
      pathname: '/headlines',
      search: searchStr,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    history,
    // selectedStatus,
    notification_source,
    notification_packages,
  ]);

  useEffect(() => {
    getNotificationSources(notification_source)
    if (!notification_source &&  !notification_packages_id) {
      getNotificationSources('')
      callSearchApi('',"package")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  useEffect(() => {
    fetchHeadlinesList(selectedStatus, null, false, sortBy);
  }, [fetchHeadlinesList, selectedStatus, sortBy]);

  useEffect(() => {
    const startPolling = () => {
      return setInterval(() => {
        fetchHeadlinesList(selectedStatus, latestId, true);
      }, 1000 * 60); 
    };
  
    let pollingInterval;
    if (selectedHeadlinesId.length === 0) {
      pollingInterval = startPolling();
    }
  
    if (currentPage > 1 || sortBy || hidden || selectedHeadlinesId.length > 0) {
      clearInterval(pollingInterval);
    }
  
    return () => {
      clearInterval(pollingInterval);
    };
  }, [fetchHeadlinesList, currentPage, selectedStatus, latestId, sortBy, hidden, selectedHeadlinesId.length]);
  
  const setEditable = useCallback((data, val) => {
    dispatch({ type: 'SET_EDITABLE', data, val });
  }, []);

  const editedField = useCallback((val, data) => {
    dispatch({ type: 'SET_EDITABLE_VALUE', val, data });
  }, []);

  const onClickHeadlinesStatus = useCallback((value) => {
    dispatch({ type: 'SET_SELECTED_STATUS', value: value.slug });
  }, []);

  const onSelectFilter = (key, value) => {
    if (key === 'notification_source') {
      dispatch({ type: 'SET_NOTIFICATION_SOURCE', value: value });
    } else{      
      const selectedPackages = notification_packages.filter((item, index) => {
        return index !== Number(key)
      })    
      const selectedPackageId = selectedPackages.map((item) => item.id)
      dispatch({ type: 'SET_NOTIFICATION_PACKAGE', value: { "selectedList": selectedPackages, "selectedIds": selectedPackageId } })
    }
   
  };

  const updateHeadlineStatusAPI = useCallback((status, singleId) => {
    const payload = {
      status,
      notification_ids: singleId ? singleId : selectedHeadlinesId
    }
    updateHeadlinesStatus(payload)
      .then(() => {
        fetchHeadlinesList(selectedStatus);        
        ToastsStore.success('Headline updated successfully.');
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error.response);
        if (error.response.status === 400) {
          ToastsStore.error(
            (error.response.data &&
              error.response.data.title &&
              error.response.data.title[0]) ||
            'Something went wrong',
          );
        } else {
          ToastsStore.error(
            (error.response && error.response.message) || 'Something went wrong',
          );
        }
      });
  }, [selectedStatus, selectedHeadlinesId, fetchHeadlinesList]);

  const DeclusterHeadlines = () => {

    const ids = selectedHeadlinesId.reduce((acc, id) => {
    const item = headlinesList.find(item => item.id === id);
    if (item) {
      if (item.children && item.children.length > 0) {
        acc.push(item.id, ...item.children.map(subItem => subItem.id));
      } else {
        acc.push(item.id);
      }
    }else{
      acc.push(id);
    }    
    return acc;
  }, []);
  
    DeclusterHeadlinesAPI({"ids":ids})
    .then(() => {
      ToastsStore.success('Decluster headlines');
      fetchHeadlinesList(selectedStatus);
    })
    .catch(() => {          
      ToastsStore.error('Something went wrong!');
    });            
  }

  const StatusSelectMenu = ()=>{
    let tempStatus
    return(
      <div style={{ display: 'flex', gap: '3px' }}>
        <div style={{ textAlign: 'center', width: '142px' }}>          
          <Dropdown
            placeholder="Select Status"
            value={tempStatus}
            fluid
            selection            
            selectOnBlur={false}            
            onChange={(e, val) => {              
              tempStatus = val.value
              updateHeadlineStatusAPI(tempStatus)              
            }}            
            options={tabs.filter(item => item !== selectedStatus).map(item => ({
              key: item,
              value: item,
              text: item
            }))}          
          />       
        </div>     
      </div>
    )
  }

  const renderStatus = useCallback(
    (value, data) => {
      const status = value || '';

      const isEditable = editable.id === data.id;
      if (isEditable) {
        return (
          <div style={{ display: 'flex', gap: '3px' }}>
            <div style={{ textAlign: 'center', width: '142px' }}>
              <Dropdown
                placeholder="Select User"
                value={editable.value}
                fluid
                selection
                onChange={(e, val) => {
                  editedField(val.value, data);
                }}
                options={tabs.map((item) => ({
                  key: item,
                  value: item,
                  text: item,
                }))}
              />
              <FlexIcon>
                <IconWrapper
                  onClick={() => {
                    setEditable(data, false);
                  }}
                >
                  <div title="cancel">
                    <Icon name="cancel" size="large" />
                  </div>
                </IconWrapper>
                <IconWrapper
                  onClick={() => {
                    updateHeadlineStatusAPI(editable.value, [data.id]);
                  }}
                >
                  <div title="save">
                    <Icon name="save" size="large" />
                  </div>
                </IconWrapper>
              </FlexIcon>
            </div>
            {status !== 'REJECTED' &&
              <Button style={{ width: '40px', height: '33px', padding: '0px' }}>
                <IconWrapper
                  onClick={() => {
                    updateHeadlineStatusAPI(editable.value, [data.id]);
                  }}
                >
                  <div title="cancel">
                    <Icon name="cancel" size="small" />
                  </div>
                </IconWrapper>
              </Button>
            }
          </div>
        );
      }
      return (
        <div style={{ textAlign: 'center', display: 'flex' }}>
          <Button
            onClick={() => {
              editedField(status, data);
            }}
          >
            {status}
          </Button>
          {status !== 'REJECTED' &&
            <Button style={{ width: '40px', padding: '0px' }}>
              <IconWrapper
              onClick={() => {
                updateHeadlineStatusAPI('REJECTED', [data.id]);
              }}
              >
                <div title="cancel">
                  <Icon name="cancel" size="small" />
                </div>
              </IconWrapper>
            </Button>
          }
        </div>
      );
    },
    [editedField, editable, setEditable, updateHeadlineStatusAPI],
  );
  const renderLink = useCallback(
    (value, data) => {
      if (value) {
        return (
          <div style={{ textAlign: 'center', textDecoration: "underline" }}>
            <Link onClick={() => {
              history.push(`/digests/${value}`);
            }}
            >
              {value}
            </Link>
          </div>
        );
      } else {
        return (
          <div style={{ textAlign: 'center' }}>
            <Button circular icon="plus" positive onClick={() => { dispatch({ type: "SET_DIGEST_CREATE", value: true }); dispatch({ type: "SET_NOTIFICATION_DATA", value: { id: data.id, title: data.text } }) }} />
          </div>
        );
      }

    },
    [history],
  );
  const columns = useMemo(
    () => [
      {
        title: headlinesList && headlinesList.length > 0 && (
          <Checkbox    
            checked={
              (headlinesList.length && selectedHeadlinesId.length === headlinesList.length) ||
              selectAllHeadline
            }
            indeterminate={
              !selectAllHeadline &&
              selectedHeadlinesId.length > 0 &&
              selectedHeadlinesId.length < headlinesList.length
            }
            onClick={() => dispatch({ type: 'SET_SELECT_ALL_HEADLINES' })}
          />
        ),
        onCell: () => ({
          onClick: (e) => {
            e.stopPropagation();
          },
        }),
        dataIndex: 'select',
        key: 'select',
        width: 5,
        render: (value, data) => (
          <Checkbox
            onClick={() => dispatch({ type: 'SET_SELECTED_HEADLINE', value: data })}
            checked={selectedHeadlinesId.indexOf(data.id) !== -1}
          />
        ),
        align: 'center',
        // fixed: 'left',
      },
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 70,
      },
      {
        title: 'Headline',
        dataIndex: 'text',
        key: 'text',
        width: 400,
        render: (value,data) => data?.tag?.title ? `${data?.tag?.title} - ${value}` : 'None - '+value
      },
      {
        title: 'Digest',
        dataIndex: 'digest',
        key: 'digest',
        width: 100,
        align: "center",
        render: renderLink,
      },
      {
        title: 'Score',
        dataIndex: 'score',
        key: 'score',
        sorter: headlinesList ? headlinesList.length : 0,
        width: 100,
      },
      {
        title: 'Created At',
        dataIndex: 'created_at',
        key: 'created_at',
        sorter: headlinesList ? headlinesList.length : 0,
        width: 200,
        render: (value) =>
          value ? <div>{moment(value).format('MMM DD,YYYY hh:mm A')}</div> : null,
      },
      {
        title: 'Modified At',
        dataIndex: 'modified_at',
        key: 'modified_at',
        sorter: headlinesList ? headlinesList.length : 0,
        width: 200,
        render: (value) =>
          value ? <div>{moment(value).format('MMM DD,YYYY hh:mm A')}</div> : null,
      },
      {
        title: 'Notification Source',
        dataIndex: 'notification_source',
        key: 'notification_source',
        sorter: headlinesList ? headlinesList.length : 0,
        width: 200
      },
      {
        title: 'Package Name',
        dataIndex: 'package_name.package_name',
        key: 'package_name',
        sorter: headlinesList ? headlinesList.length : 0,
        width: 200
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        width: 175,
        onCell: () => ({
          onClick: (e) => {
            e.stopPropagation();
          },
        }),
        render: renderStatus,
      },
    ],
    [renderStatus, headlinesList, renderLink,selectAllHeadline,selectedHeadlinesId],
  );
  const addDigest = ({ values }) => {
    dispatch({ type: 'SET_DIGEST_LOADING', value: true });
    createNewDigest(values)
      .then(() => {
        updateHeadlineStatusAPI("PROCESSING", [values.notification_id]);
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
        ToastsStore.success('Digest created successfully.');
        closeModal();
      })
      .catch((error) => {
        if (window) window.Raven.captureException(error?.response);
        const errorMsg =
          typeof error?.response?.data === 'object'
            ? Object.values(error.response.data)[0]
            : 'Unable to create digest.';
        ToastsStore.error(errorMsg);
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
      })
      .finally(() => {
        dispatch({ type: 'SET_DIGEST_LOADING', value: false });
      });
  };

  const closeModal = () => {
    dispatch({ type: 'SET_DIGEST_CREATE', value: false });
    dispatch({ type: 'SET_NOTIFICATION_DATA', value: {} });
  };

  const parsedArray = (res, type) => {
    const keyProp = type === "source" ? "name" : "package_name";

    return res.map(item => ({
      key: item[keyProp],
      value: item[keyProp],
      text: item[keyProp],
      title: item[keyProp],
      id: item.id ?? "",
    }));
  };  

  const onLabelSelect = (selectedLabelsList) => {
    const selectedPackageId = selectedLabelsList.map((item) => item.id);
    dispatch({ type: 'SET_NOTIFICATION_PACKAGE', value: { "selectedList": selectedLabelsList, "selectedIds": selectedPackageId } })
  };

  const onRemove = (selectedList) => {
    const selectedPackageId = selectedList.map((item) => item.id)
    dispatch({ type: 'SET_NOTIFICATION_PACKAGE', value: { "selectedList": selectedList, "selectedIds": selectedPackageId } })
  };

  const callSearchApi = (key, type) => {
    fetchNotificationPackages(key)
      .then((response) => {
        if (response.data.results.length > 0) {
          const parseResponse = parsedArray(response.data.results, type)
          dispatch({ type: 'SET_NOTIFICATION_PACKAGE_DATA', value: parseResponse })
        }
      })
      .catch((error) => {
        console.error(error)
      });
  };

  const getNotificationSources = (key) => {
    fetchNotificationSources(key)
      .then((response) => {
        if (response.data.length > 0) {
          const parseResponse = parsedArray(response.data, "source")
          dispatch({ type: 'SET_NOTIFICATION_SOURCE_DATA', value: parseResponse })
        }
      })
      .catch((error) => {
        console.error(error)
      });
  };

  const handleCloseModal = (isLoad)=>{  
    dispatch({ type: 'SET_MODAL_FLAG', value:!isModalOpen})
    if(isLoad){
      fetchHeadlinesList(selectedStatus);
    }
  }

  const handleTagAdd = (value)=>{  
    dispatch({ type: 'ADD_TAG_IDS', value})  
  }
  
  return (
    <Layout location={location}>
      <Helmet>
      <title>XND 
        {!notification_source ? notification_packages?.length === 0 ? ' | Headlines' : "" : ""}
       {notification_source === "IN_SHORTS" ? ` | Inshorts ` : ""}
       {notification_source === "NOTIFICATION_TRACKER" ? ` | Notification Tracker ` : ""}
       {notification_source === "NEWSLETTER" ? ` | Newsletter ` : ""}
       {notification_source === "RSS" ? ` | Rss ` : ""}
        {notification_packages?.length > 0 ? notification_packages.map((pack, index)=> {
          return(
            ` | ${pack.title} `
          )
        }).join("") : ""}</title>
      </Helmet>
      {isModalOpen && (
        <>
          <ReClusterModal onClose={(isLoad)=>handleCloseModal(isLoad)} ids={selectedHeadlinesId} headlinesList={headlinesList}/>
        </>
      )}
      <FilterDropDowns>
        <NotificationDropdown>
          <Dropdown
            selectOnBlur={false}
            placeholder="Select notification source"
            value={notification_source ?? null}
            fluid
            clearable
            search
            selection
            onChange={(e, val) => {
              let selectedData = null;
              val.options.forEach((item) => {
                if (item.value === val.value) {
                  selectedData = item.value;
                }
              });
              onSelectFilter('notification_source', selectedData);
              dispatch({ type: 'SET_NOTIFICATION_SOURCE', value: selectedData })
            }}
            options={notification_source_data}
          />
        </NotificationDropdown>
        <NotificationDropdown>
          <Multiselect
            placeholder="Select notification package"
            onSearch={(e) => callSearchApi(e, "package")}
            options={notification_package_data}
            style={{
              chips: {
                background: 'rgb(233,233,233)',
                color: 'rgb(128,128,128)',
              },
              icon: {
                color: 'black',
              },
            }}
            onChange={(e, val) => {              
              let selectedData = null;
              val.options.forEach((item) => {
                if (item.value === val.value) {
                  selectedData = item.value;
                }
              });              
              onSelectFilter('notificationPackages', selectedData);
              dispatch({ type: 'SET_NOTIFICATION_PACKAGE', value: selectedData })
            }}
            customCloseIcon={
              <Icon name="close" style={{ margin: '0px 0px 3px 5px', cursor: "pointer" }} />
            }
            selectedValues={notification_packages}
            onSelect={onLabelSelect}
            onRemove={onRemove}
            displayValue="title"
          />
        </NotificationDropdown>
        <ButtonWrapper>
          <HideButton
            icon={`${hidden ? 'eye slash' : 'eye'}`}
            content={`${hidden ? 'Show All' : 'Hide'} Headlines`}
            positive
            labelPosition="right"
            onClick={() => { dispatch({ type: 'SET_HIDDEN', value: !hidden }) }}
          />
        </ButtonWrapper>
      </FilterDropDowns>
      <HeadlineTagsPills selectedTagsIds={selectedTagsIds} handleTagAdd={handleTagAdd}/>
      <ListWrapper>
        <TableWrapper>
          <SliderWrapperContainer>
            <SliderWrapper fixed={false} className="slider-wrapper">
              <SliderContainer>
                <Slider
                  sliderData={statuses}
                  selectedStatus={selectedStatus}
                  onClickItem={onClickHeadlinesStatus}
                />
              </SliderContainer>
            </SliderWrapper>
          </SliderWrapperContainer>
          {selectedHeadlinesId.length > 0 && (
            <TableHeader style={{
              display:'flex',
              gap:'10px',
              alignItems:'center',
              margin:'25px 0px',
            }}>
              <div>{`${selectedHeadlinesId.length} selected headlines`}</div>
              <Flex>
                {selectedHeadlinesId.length >= 1 && (
                  <TableHeaderSubFilter>
                    <StatusSelectMenu />
                  </TableHeaderSubFilter>
                )
                }
              </Flex>
              <Flex>
                <Button
                  onClick={() => dispatch({ type: 'SET_MODAL_FLAG', value:!isModalOpen})}
                >
                 Re-cluster Headlines
                </Button>
              </Flex>
              <Flex>
                <Button
                  onClick={() => DeclusterHeadlines()}
                >
                 De-cluster Headlines
                </Button>
              </Flex>
            </TableHeader>
          )}
          {
            (Object.keys(notification_source ? notification_source : getNameOfNotificationPackage)?.length > 0) && (
              <TableHeader style={{
                marginBottom:'25px',
                display:'block',
              }}> 
                <FilterPills
                  filterObject={notification_source && notification_source?.trim() !== '' ? { notification_source, ...getNameOfNotificationPackage } : getNameOfNotificationPackage}
                  onSelectFilter={onSelectFilter}
                />
              </TableHeader>
            )}
          <TableView
            onPageSizeChange={(value) => { dispatch({ type: 'SET_PAGE_SIZE', value }) }}
            pageSizeChange={true}
            pageSizeOptions={['10', '20', '50', '100']}
            tableColumns={columns}
            data={headlinesList}
            indentSize={10}
            pagination
            pageSize={pageSize}
            totalItems={headlinesTotalCount}
            activePage={currentPage}
            onPageChange={(value) => dispatch({ type: 'SET_CURRENT_PAGE', value })}
            toggleSortOrder={(sort) => dispatch({ type: 'SET_SORT', sortBy: sort })}
            sortBy={sortBy}
            sortOrder={sortOrder === 'asc' ? 'ascend' : 'descend'}
            expandIconColumnIndex={1}
            expandIcon={CustomExpandIcon}
            expandRowByClick={CustomExpandIcon}
            expandedRowKeys={expandedRowKeys}
            onExpand={(expanded, record) => handleExpand(expanded, record)}
            rowClassName={(record) => {
              if (record?.children?.length > 0) {
                return record?.score >= 15
                  ? 'bg_light_red'
                  : record?.score >= 10 && record?.score <= 14
                    ? 'bg_light_yellow'
                    : record?.score < 9
                      ? 'bg_none'
                      : ''
              }
            }
            }           
          />
        </TableWrapper>
      </ListWrapper>
      {headlinesLoading && (
        <LoaderWrapper>
          <Loader active={headlinesLoading} />
        </LoaderWrapper>
      )}
      {createDigest && <Modal open={createDigest} onClose={closeModal} closeIcon>
        <Modal.Header>Add Digest</Modal.Header>
        <CreateDigestForm createDigestLoading={createDigestLoading} notificationData={notificationData} addDigest={addDigest} closeModal={closeModal} />
      </Modal>}
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
    </Layout>
  );
};

Headlines.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default memo(Headlines);
