import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const ToggleButton = styled.span`
  display: inline-block;
  width: 55px;
  height: 32px;
  margin-left: 20px;
  border-radius: 21px;
  background: ${(props) => (props.on ? '#009fa2' : '#9b9b9b')};
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 6px;
    height: 24px;
    width: 24px;
    border-radius: 24px;
    background: #fff;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25), 0 4px 11px 0 rgba(0, 0, 0, 0.08),
      -1px 3px 3px 0 rgba(0, 0, 0, 0.14);
    transition: transform 0.25s ease, width 0.25s, left 0.25s;
    transform: translateX(${(props) => (props.on ? '20px' : 0)});
  }

  @media (max-width: 768px) {
    width: 45px;
    height: 25px;
    &:before {
      height: 17px;
      width: 17px;
      border-radius: 17px;
      left: 6px;
    }
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const Toggle = (props) => <ToggleButton on={props.on} {...props} />;

export default Toggle;

Toggle.propTypes = {
  on: PropTypes.bool,
};
