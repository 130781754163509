/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const {
    component: Component,
    location: { pathname, search },
    token,
    authorizedFor,
    ...rest
  } = props;

  const privilege = localStorage.getItem('privilege');

  const isAuthenticated = useCallback(() => {
    let redirectPath;
    if (privilege && localStorage.getItem('token')) {
      switch (privilege) {
        case 'STAFF_USER_PERM':
          if (authorizedFor !== 'super_user') return { allowed: true };
          redirectPath = '/';
          break;
        default:
          return { allowed: true };
      }
    }
    return { allowed: false, redirectPath };
  }, [authorizedFor, privilege]);

  return (
    <Route
      {...rest}
      render={(componentProps) =>
        isAuthenticated().allowed ? (
          <Component {...componentProps} />
        ) : (
          <Redirect
            to={{
              pathname: isAuthenticated().redirectPath || '/login',
              state: { referrer: { pathname, search } },
            }}
          />
        )
      }
    />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }),
  token: PropTypes.string,
  authorizedFor: PropTypes.string,
};

export default ProtectedRoute;
