/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Dropdown, Input } from 'semantic-ui-react';
import { fetchAllUsers } from '../../Api';

const FormFields = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  label {
    width: ${({ w }) => w || '20%'};
  }
  > input,
  div {
    margin-top: 10px !important;
    margin-left: 20px !important;
    flex: 1;
  }
  > textarea::placeholder {
    opacity: 0.4;
  }
  div {
    margin-left: 0;
    input {
      width: 51% !important;
    }
  }
`;

const DescriptionField = styled(TextareaAutosize)`
  width: 100%;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87) !important;
  padding: 0.8em 0.5em !important;
  overflow: hidden;
  background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
  flex: 1;
  &:hover {
    outline: none !important;
  }
`;

const FieldError = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const ButtonWrapper = styled.div`
  padding: 50px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const CreateUserForm = (props) => {
  const { closeModal, saveUser, createLoading, deleteGroup } = props;

  const [errors, setErrors] = useState({});
  const [userOptions, setUserOptions] = useState([]);
  const [value, setValue] = useState(deleteGroup);

  const fetchUsers = useCallback(async () => {
    const response = await fetchAllUsers();
    let arrayResult = response.data.results;
    arrayResult = arrayResult.filter((val) => val.name);
    const options = arrayResult.map((val) => ({
      key: val.id,
      value: val.id,
      text: val.name,
    }));
    setUserOptions(options);
  }, []);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const validate = () => {
    const tempErrors = {};
    if (!value.title || !value.title.trim()) tempErrors.title = 'Please enter group name';
    if (!value.users.length) tempErrors.users = 'Please select users';
    setErrors(tempErrors);
    return tempErrors;
  };

  return (
    <>
      <FormFields>
        <label htmlFor="name">Group Name</label>
        <Input
          name="title*"
          type="text"
          value={value.title}
          error={errors.title}
          placeholder="title"
          onChange={(e) => setValue({ ...value, title: e.target.value })}
        />
        <FieldError>{errors.title}</FieldError>
      </FormFields>
      <FormFields w="22%">
        <label htmlFor="name">Description</label>
        <DescriptionField
          name="description"
          placeholder="description"
          value={value.description}
          onChange={(e) => setValue({ ...value, description: e.target.value })}
        />
      </FormFields>
      <FormFields>
        <label htmlFor="users">Group Audience</label>
        <Dropdown
          placeholder="Select users*"
          multiple
          search
          error={errors.users}
          selection
          options={userOptions}
          defaultValue={value.users}
          onChange={(e, data) => {
            setValue({
              ...value,
              users: data.value,
            });
          }}
        />
        <FieldError>{errors.users}</FieldError>
      </FormFields>
      <ButtonWrapper>
        <Button negative onClick={closeModal} disabled={createLoading}>
          Cancel
        </Button>
        <Button
          positive
          content="Save"
          disabled={createLoading}
          onClick={() => {
            const tempErrors = validate();
            const tempValues = { ...value };
            tempValues.remove_users = deleteGroup.users.filter(
              (user) => !tempValues.users.includes(user),
            );
            if (!Object.keys(tempErrors).length)
              saveUser(tempValues, Object.keys(deleteGroup).length);
          }}
        />
      </ButtonWrapper>
    </>
  );
};

export default CreateUserForm;

CreateUserForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  saveUser: PropTypes.func,
  deleteGroup: PropTypes.object,
  createLoading: PropTypes.bool,
};
