import React from 'react';
import PropTypes from 'prop-types';
import Pagination from 'rc-pagination';
import localeInfo from 'rc-pagination/lib/locale/en_US';
import 'rc-pagination/assets/index.css';
import styled from 'styled-components';

const PaginationMain = styled.div`
    display:flex;
    align-items:center;
    flex-direction: row-reverse;
    margin-bottom:15px;
    gap: 12px;
    & select{
      cursor:pointer;
      border: 1px solid #d9d9d9;
      color: rgba(0, 0, 0, 0.85);
      padding: 4px 0;
    }
`;

const PageSizeSelector = ({ totalItems,pageSize, onPageSizeChange, pageSizeOptions }) => (
  <select value={pageSize} onChange={(e) => onPageSizeChange(parseInt(e.target.value))}>
    {pageSizeOptions.length > 0 && pageSizeOptions.map((item) => (
      <option disabled={totalItems < item} key={item} value={item}>
        {item}
      </option>
    ))}
  </select>
);
const PaginationComponent = ({ pageSizeOptions, activePage, pageSize, onChangePage, onPageSizeChange, totalItems, pageSizeChange }) => (
  <PaginationMain>
    <Pagination
      current={activePage}
      pageSize={pageSize}
      onChange={onChangePage}
      defaultCurrent={activePage}
      total={totalItems}
      locale={localeInfo}
    />
    {pageSizeChange && <PageSizeSelector totalItems={totalItems} pageSize={pageSize} pageSizeOptions={pageSizeOptions} onPageSizeChange={onPageSizeChange} />}
  </PaginationMain>
);

PaginationComponent.defaultProps = {
  activePage: 1,
  pageSize: 20,
  onChangePage: () => { },
  onPageSizeChange: () => { },
  totalItems: 0,
};

PaginationComponent.propTypes = {
  activePage: PropTypes.number,
  pageSize: PropTypes.number,
  onChangePage: PropTypes.func,
  onPageSizeChange: PropTypes.func,
  totalItems: PropTypes.number,
};

export default PaginationComponent;
