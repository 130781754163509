import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Loader } from 'semantic-ui-react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Layout from '../../Components/Layout';
import { saveStatus, editStatus, viewStatus } from '../../Api';
import AddStatusForm from './AddStatusForm';

const Header = styled.div`
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.25em;
    text-transform: capitalize;
    padding: 20px 0 0 35px;
`;

const AddStatus = props => {
    const { history, location, match: { params: { id } } } = props;
    const [status, setStatus] = useState({ status_name: '', status_code: ''});
    const [loading, load] = useState(false)

    useEffect(() => {
        if (id) {
            load(true);
            viewStatus(id).then(response => {
                load(false);
                setStatus(response.data);
            }).catch(error => {
                load(false);
                const errorMsg =
                        typeof error.response.data === 'object' ?
                            Object.values(error.response.data)[0] : 'Unable to fetch status.';
                ToastsStore.error(errorMsg);
            });
        }
      }, [id]);

    const addStatus = ({ values, setSubmitting, resetForm }) => {
        setSubmitting(true);
        let newValues = { status_name: values.status_name.trim(), status_code: values.status_code.trim() };
        if (!id) {
            load(true);
            saveStatus(newValues).then(() => {
            setSubmitting(false);
            resetForm(true);
            ToastsStore.success("Status created successfully.");
            setTimeout(() => {
                history.push('/settings');
                resetForm(true);
            }, 2000);
            }).catch(error => {
                const errorMsg =
                        typeof error.response.data === 'object' ?
                            Object.values(error.response.data)[0] : 'Unable to create status.';
                ToastsStore.error(errorMsg);
                setSubmitting(false);
                load(false);
            });
        } else {
            editStatus(newValues, id).then(response => {
                load(true);
                ToastsStore.success("Status updated successfully.");
                setTimeout(() => {
                    history.push('/settings');
                    resetForm(true);
                }, 2000);
                }).catch(error => {
                    load(false);
                    const errorMsg =
                            typeof error.response.data === 'object' ?
                                Object.values(error.response.data)[0] : 'Unable to update status.';
                    ToastsStore.error(errorMsg);
                    setSubmitting(false);
                });
        }
    }
    
    return (
        <Layout location={location}>
            <Header>
                {id ? 'Edit' : 'Add'} Status
            </Header>
            {(!id || (id && status.id)) && !loading && (
                <AddStatusForm
                    addStatus={addStatus}
                    status={status || {status_name: '', status_code: ''}}
                    history={props.history}
                />
            )}
            <Loader active={loading}/>
            <ToastsContainer
                position={ToastsContainerPosition.BOTTOM_LEFT}
                store={ToastsStore}
            />
        </Layout>
    )
};

export default AddStatus;