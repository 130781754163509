import { PageHeader } from 'antd';
import { React, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory, withRouter } from 'react-router-dom';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { Button, Container } from 'semantic-ui-react';
import styled from 'styled-components';

const VideoPreview = ({ location, match: { params } }) => {
  const history = useHistory();

  const transformationURL = history?.location?.state?.transformationURL;
  // const transformedURL = transformationURL.substring(1, transformationURL.length - 1);
  const cleanedURL = transformationURL?.replace(/^[/,]+|[/,]+$/g, '');

  const PageTitle = styled.div`
    font-size: 24px;
    font-weight: 700;
    line-height: 1.4;
    text-transform: uppercase;
  `;

  const Wrapper = styled(Container)`
    display: flex;
    flex-direction: column;
    margin-left: 50px;
    padding-top: 100px;
  `;

  useEffect(() => {
    history.listen((newLocation, action) => {
      var currentPathname = '';
      var currentSearch = '';
      if (action === 'PUSH') {
        if (newLocation.pathname !== currentPathname || newLocation.search !== currentSearch) {
          // Save new location
          currentPathname = newLocation.pathname;
          currentSearch = newLocation.search;

          // Clone location object and push it to history
          history.push({
            pathname: newLocation.pathname,
            search: newLocation.search,
          });
        }
      } else {
        // Send user back if they try to navigate back
        history.go(1);
      }
    });
  }, [history]);

  const videoURL = `https://res.cloudinary.com/dxk35ktti/video/upload/${cleanedURL}/v1690448380/${params.id}`;
  const mp4URL = `${videoURL}.mp4`;
  // const webmURL = `${videoURL}.webm`;
  // const ogvURL = `${videoURL}.ogv`;

  // useEffect(() => {
  //   const videoPlayer = document.getElementById('videoPlayer');
  //   const mp4Source = document.getElementById('mp4Source');
  //   // const webmSource = document.getElementById('webmSource');
  //   // const ogvSource = document.getElementById('ogvSource');

  //   // const tryWebM = () => {
  //   //   mp4Source.remove();
  //   //   webmSource.setAttribute('src', webmURL);
  //   //   videoPlayer.load();
  //   // };

  //   // const tryOGV = () => {
  //   //   webmSource.remove();
  //   //   ogvSource.setAttribute('src', ogvURL);
  //   //   videoPlayer.load();
  //   // };

  //   // mp4Source.addEventListener('error', () => {
  //   //   tryWebM();
  //   // });

  //   // webmSource.addEventListener('error', () => {
  //   //   tryOGV();
  //   // });
  // }, [mp4URL]);

  const saveVideoToDigest = () => {
    // const cloudinary = {
    //   cloudinary: {
    //     transform: cleanedURL,
    //   }
    // };
    ToastsStore.success('Digest saved successfully');
    // saveDigestVideo(params.id, cloudinary)
    //   .then((res) => {
    //     const result = res.data;
    //     console.log('result', result);
    //     ToastsStore.success("Digest saved successfully");
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     ToastsStore.error("Something went wrong. Please try again");
    //   });
  };

  return (
    <>
      <PageHeader location={location} />
      <Helmet>
        <title>XND</title>
      </Helmet>
      <Wrapper>
        <PageTitle>Preview Video</PageTitle>
        <div>
          <video controls width="100%" id="videoPlayer">
            <source id="mp4Source" src={mp4URL} type="video/mp4" />
            {/* <source id="webmSource" src={webmURL} type="video/webm" />
            <source id="ogvSource" src={ogvURL} type="video/ogg" /> */}
            Your browser does not support the video tag.
          </video >
          <Button onClick={saveVideoToDigest}>Save Video to Digest</Button>
        </div >
      </Wrapper >
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
    </>
  );
};

export default withRouter(VideoPreview);
