import React from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import icons from './icons';

const Svg = styled.svg`
  display: inline-flex;
  vertical-align: middle;
  line-height: 1;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;

  path {
    fill: ${(props) => props.color};
  }
`;

const Icon = (props) => {
  const { name, color, size, ...otherProps } = props;
  const icon = icons[props.name];

  /* Check if the color is one of the predefined colors, else fallback to hex */
  // const color = props.theme.colors.base[propsColor] || propsColor;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Icon.Element size={size} {...otherProps}>
      <Svg width={size} height={size} viewBox={`0 0 ${icon.width} ${icon.height}`} color={color}>
        {icon.elements.map((element, index) => {
          if (element.type === 'path')
            if (typeof element.value === 'object')
              /* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
              return <path {...element.value.props} key={index} d={element.value.path} />;
            else return <path d={element.value} key={index} />;
          if (element.type === 'rect') return <rect fill={color} {...element.value} key={index} />;
          if (element.type === 'circle')
            return <circle fill={color} {...element.value} key={index} />;
          return null;
        })}
      </Svg>
    </Icon.Element>
  );
};

Icon.Element = styled.i`
  display: inline-block;
  line-height: 1;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

Icon.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  name: PropTypes.string,
};

Icon.defaultProps = {
  size: 24,
};

export default withTheme(Icon);
