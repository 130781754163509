import React, { useRef } from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import {

    Button,
    Icon,
} from 'semantic-ui-react';
import FAQAnswerEditor from './FAQAnswerEditor';

const FAQQuestionsSection = (props) => {
    const { initialValues, handleRegenerateAnswers, regenerateAnswersList, handleSaveAllQuestions, updateSavingAllQuestionsLoader } = props;
    const editorRef = useRef(null);

    function removePTagsWithinList(data) {
        var tempDiv = document.createElement('div');
        tempDiv.innerHTML = data;
        var ulElements = tempDiv.querySelectorAll('ul');
        ulElements.forEach(function (ulElement) {
            var pTagsWithinList = ulElement.querySelectorAll('li p');
            pTagsWithinList.forEach(function (pTag) {
                var textContent = pTag.textContent;
                var textNode = document.createTextNode(textContent);
                pTag.parentNode.replaceChild(textNode, pTag);
            });
        });
        return tempDiv.innerHTML;
    }

    return <div>
        <Formik
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={initialValues}
            validate={validate}
            onSubmit={async (values) => {
                handleSaveAllQuestions(values)
            }}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <FieldArray name="faqs">
                        {({ _ }) => (
                            <div>
                                {values.faqs.length > 0 &&
                                    values.faqs.map((faq, index) => (
                                        <div key={index} style={{ display: "flex", flexDirection: "column" }}>
                                            <label htmlFor={`faqs.${index}.question`} className={`question`}>Question</label>
                                            <Field
                                                name={`faqs.${index}.question`}
                                                placeholder="Enter your question"
                                                type="text"
                                                className={`question-filed`}
                                                disabled={regenerateAnswersList.includes(index + 1) || updateSavingAllQuestionsLoader}
                                            />
                                            <ErrorMessage
                                                name={`faqs.${index}.question`}
                                                component="div"
                                                className="field-error"
                                            />

                                            <label htmlFor={`faqs.${index}.answer`} className={`answer-label`}>Answer</label>
                                            {initialValues.faqs[index].is_regenerated ? <>
                                                <label className='answer-generation'>Answer generation in progress please come back after few moments.</label>
                                            </> :
                                                <>
                                                    <Field
                                                        name={`faqs.${index}.answer`}
                                                        type="answer"
                                                        className='textarea-box'
                                                        render={({ field, form: { touched, errors } }) => {
                                                            return (
                                                                <FAQAnswerEditor
                                                                    editorRef={editorRef}
                                                                    data={values.faqs[index].answer}
                                                                    handleEditorChange={(content) => {
                                                                        const parsedData = removePTagsWithinList(content)
                                                                        setFieldValue(
                                                                            field.name,
                                                                            parsedData,
                                                                        )
                                                                    }}
                                                                    onBlur={field.onBlur}
                                                                    name={field.name}
                                                                    disabled={regenerateAnswersList.includes(index + 1) || updateSavingAllQuestionsLoader}
                                                                />
                                                            )
                                                        }}
                                                    />
                                                    {/* <Field
                                                        name={`faqs.${index}.answer`}
                                                        placeholder="Enter Answer"
                                                        type="answer"
                                                        as="textarea"
                                                        className='textarea-box'
                                                        disabled={regenerateAnswersList.includes(index + 1) || updateSavingAllQuestionsLoader}
                                                    /> */}
                                                    <ErrorMessage
                                                        name={`faqs.${index}.answer`}
                                                        component="div"
                                                        className="field-error"
                                                    />
                                                    <div className='iconWrp'>
                                                        <Button type="button" className={regenerateAnswersList.includes(index + 1) ? 'syncBtn syncBtn-active' : 'syncBtn'} onClick={() => handleRegenerateAnswers(index + 1)}
                                                            disabled={regenerateAnswersList.includes(index + 1) || updateSavingAllQuestionsLoader}>
                                                            <Icon className='sync-icon' name="sync" size="size" />
                                                        </Button>
                                                    </div>

                                                </>
                                            }
                                            <div className='end-section'>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </FieldArray>
                    <button type="submit" style={{ cursor: "pointer" }} className='update-btn' disabled={regenerateAnswersList.length !== 0 || updateSavingAllQuestionsLoader}>
                        {
                            updateSavingAllQuestionsLoader ? 'Updating...' : 'Update'}
                    </button>
                </Form>
            )}
        </Formik>
    </div>
};

const validate = values => {
    const errors = {};

    if (!values.faqs || values.faqs.length === 0) {
        errors.faqs = 'Please add at least one FAQ';
    } else {
        values.faqs.forEach((faq, index) => {
            if (!faq.question || !faq.question.trim()) {
                if (!errors.faqs) {
                    errors.faqs = [];
                }
                errors.faqs[index] = { ...errors.faqs[index], question: 'Please enter a question' };
            }

            const answerWithoutHtml = faq.answer.replace(/<[^>]*>/g, ''); // Remove HTML tags
            if ((!answerWithoutHtml || !answerWithoutHtml.trim()) && !faq.is_regenerated) {
                if (!errors.faqs) {
                    errors.faqs = [];
                }
                errors.faqs[index] = { ...errors.faqs[index], answer: 'Please generate answers or enter answer manually.' };
            }
        });
    }
    return errors;
};

export default FAQQuestionsSection;
