import { useCallback, useEffect } from 'react';

const OutsideClick = ({ elementRef, handleOutsideClick, activeOutsideClick }) => {
  const handleClick = useCallback(
    event => {
      if (event.persist) event.persist();
      if (elementRef && elementRef.current && !elementRef.current.contains(event.target)) {
        handleOutsideClick();
      }
      return event;
    },
    [elementRef, handleOutsideClick],
  );

  useEffect(() => {
    if (activeOutsideClick) {
      document.addEventListener('click', handleClick);
      document.addEventListener('ontouchstart', handleClick);
    }

    return () => {
      document.removeEventListener('click', handleClick);
      document.removeEventListener('ontouchstart', handleClick);
    };
  }, [elementRef, handleOutsideClick, activeOutsideClick, handleClick]);

  return null;
};

export default OutsideClick;
