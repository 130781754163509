import React, { useReducer, useEffect, useCallback } from 'react';
import {
  // Input,
  Icon,
  Loader,
} from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import produce from 'immer';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Layout from '../../Components/Layout';
import { fetchPolls } from '../../Api';
import TableView from '../../Components/TableView';
import Graph from '../../Components/Graph';

// const SearchWrapper = styled.div`
//   width: 100%;
//   .input {
//     width: 100%;
//   }
// `;

const ListWrapper = styled.div`
  position: relative;
  z-index: 50;

  .ui.checkbox {
    min-height: 14px;
  }
`;

const Question = styled.span`
    display: flex;
    justify-content: space-between;
`;

const QuesGroup = styled(Icon.Group)`
  font-style: normal;
  font-weight: bold;
  display: flex !important;
  align-items: center;
`;

const QuesIcon = styled(Icon)`
  margin-left: 5px !important;
`;

const initialValue = {
  pollList: [],
  digestLoading: true,
  currentPage: 1,
  digestsTotalCount: 0,
  sortOrder: 'desc',
  sortBy: 'digest',
  searchQuery: '',
  pollCountIndex: -1,
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'SET_POLL_LIST':
        draft.pollList = action.value;
        draft.digestLoading = false;
        draft.digestsTotalCount = action.totalCount;
        draft.selectedDigestsId = [];
        draft.selectAllDigest = false;
        break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        draft.selectedDigestsId = [];
        draft.selectAllDigest = false;
        break;
      case 'SET_SORT':
        if (action.sortBy.dataIndex === draft.sortBy) {
          draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          draft.sortOrder = 'asc';
          draft.sortBy = action.sortBy.dataIndex;
        }
        break;
      case 'SET_DIGEST_LOADING':
        draft.digestLoading = action.value;
        break;
      case 'SET_SEARCH_QUERY':
        draft.searchQuery = action.value;
        break;
      case 'VIEW_POLL_COUNT':
        if (draft.pollCountIndex === action.index)
            draft.pollCountIndex = -1;
        else draft.pollCountIndex = action.index;
    }
  });

const AdminPoll = ({ location }) => {

  const [state, dispatch] = useReducer(reducer, initialValue);

  const {
    pollList,
    digestLoading,
    digestsTotalCount,
    currentPage,
    sortBy,
    sortOrder,
    searchQuery,
    pollCountIndex,
  } = state;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDigestsList = useCallback(() => {
    // dispatch({ type: 'SET_DIGEST_LOADING', value: true });
    fetchPolls({
      page: currentPage,
      sortBy,
      sortOrder,
      search: searchQuery,
      // digest_types: ,
    }).then(response => {
      if(response.data.length === 0 && currentPage > 1) {
        dispatch({ type: 'SET_CURRENT_PAGE', value: currentPage - 1});
      } else {
        dispatch({ type: 'SET_POLL_LIST', value: response.data.results, totalCount: response.data.count });
      }
    })
    .catch(error => {
      if (window) window.Raven.captureException(error.response);
      dispatch({ type: 'SET_POLL_LIST', value: [], totalCount: 0 });
      ToastsStore.error('Something went wrong!');
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, sortBy, sortOrder, searchQuery]);
  
  useEffect(() => {
    fetchDigestsList();
  }, [fetchDigestsList, currentPage]);
  
  const columns = [
    {
      title: 'Id',
      dataIndex: 'digest',
      key: 'digest',
      sorter: true,
      width: 60,
    },
    {
        title: 'Question',
        dataIndex: 'question',
        key: 'question',
        width: 200,
        render: (value, data, index) => {
            const choiceCounts = data.choices.map(choice => choice.choice_count);
            const totalVotes = choiceCounts.reduce((a,b) => a + b, 0);
            return (
                    <div>
                        <Question>
                            {value.question}
                            <QuesGroup>
                                ({totalVotes} votes)
                                <QuesIcon name={pollCountIndex === index ? "chevron circle down" : "chevron circle up" } />
                            </QuesGroup>
                        </Question>
                        {pollCountIndex === index && (
                            <Graph data={data.choices} totalVotes={totalVotes || 1} />
                            // data.poll_choices.map(count => (
                            //     <div>
                            //         {count.choice} {count.choice_count}
                            //     </div>
                            // ))
                        )}
                    </div>
            );
        }
    },
    {
        title: 'Most polled value',
        dataIndex: 'choices',
        key: 'choices',
        width: 110,
        render: (value, data) => {
            const mostPolled = value.reduce((max, current) => current.choice_count >= max.choice_count ? current : max)
            return <span>{mostPolled ? mostPolled.choice : ''}</span>
        }
    },
  ];

  return (
      <Layout location={location}>
         <Helmet>
            <title>XND</title>
          </Helmet>
          <ListWrapper>
          {!digestLoading ? (
              <TableView
                tableColumns={columns}
                data={pollList}
                pagination= {true}
                totalItems={digestsTotalCount}
                activePage={currentPage}
                onPageChange={value => dispatch({ type: 'SET_CURRENT_PAGE', value })}
                toggleSortOrder={sortBy => dispatch({ type: 'SET_SORT', sortBy })}
                sortBy={sortBy}
                sortOrder={sortOrder === 'asc' ? 'ascend' : 'descend'}
                // scroll={{x: false, y: false }}
                onRow={(record, index) => ({
                  onClick: (e) => {
                    dispatch({ type: 'VIEW_POLL_COUNT', index })
                  },
                })}
              />
          ) : <Loader active={digestLoading} />}
        </ListWrapper>
        <ToastsContainer
          position={ToastsContainerPosition.BOTTOM_LEFT}
          store={ToastsStore}
        />
      </Layout>
  );
};

export default AdminPoll;