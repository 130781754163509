import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { Button, Icon, Form } from 'semantic-ui-react';
import { autoGeneratePassword } from '../../Utils/trimValues';

const MainForm = styled(Form)`
  padding: 20px;
`;

const FormFields = styled.div`
  padding: 20px;
  input {
    margin-top: 10px !important;
  }
`;

const ButtonWeapper = styled.div`
  padding: 20px;
`;

const FieldSelect = styled(Field)`
  height: 40px !important;
  margin-top: 10px !important;
`;

const FieldError = styled.span`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const CustomField = styled(Field)`
  border: ${(param) => param.error && '1px solid #cc0000 !important'};
`;

const AutoGenerate = styled.div`
  cursor: pointer;
  text-decoration: underline;
  > i {
    text-decoration: none;
    padding-left: 5px;
  }
`;

const Copy = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const PasswordField = styled.div`
  display: flex;
  justify-content: space-between;
`;

const GeneratePassword = styled.div`
  margin: 10px 0;
  padding: 10px 0;
  display: flex;
  align-items: center;
  border: 1px solid rgba(34, 36, 38, 0.15);
  justify-content: center;
  background: #f0f8ff;
`;

const AutoGenerateWrapper = styled.div`
  padding: 20px;
`;

const CreateUserForm = (props) => {
  const { roles, closeModal, createUser } = props;

  const [generatedPassword, setPassword] = useState(null);

  const generatePassword = () => {
    const pass = autoGeneratePassword();
    setPassword(pass);
  };

  const copyPassword = async (setFieldValue) => {
    await setFieldValue('password', generatedPassword);
    setFieldValue('password2', generatedPassword);
  };
  return (
    <Formik
      initialValues={{ name: '', password: '', password2: '', email: '', roles: '' }}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        createUser({ values, setSubmitting });
      }}
    >
      {({ handleSubmit, errors, touched, handleChange, setFieldValue }) => (
        <MainForm onSubmit={handleSubmit}>
          <FormFields>
            <label htmlFor="username">Name:</label>
            <CustomField
              name="name"
              type="text"
              placeholder="name"
              error={errors.name && touched.name}
            />
            <FieldError>
              <ErrorMessage name="name" />
            </FieldError>
          </FormFields>
          <FormFields>
            <label htmlFor="email">Email:</label>
            <Field name="email" type="email" placeholder="email" />
            <FieldError>
              <ErrorMessage name="email" />
            </FieldError>
          </FormFields>
          <PasswordField>
            <FormFields>
              <label htmlFor="password">Password:</label>
              <CustomField
                name="password"
                type="password"
                placeholder="password"
                error={errors.password && touched.password}
              />
              <FieldError>
                <ErrorMessage name="password" />
              </FieldError>
            </FormFields>
            <AutoGenerateWrapper>
              <AutoGenerate onClick={generatePassword}>
                Auto-Generate Password
                <Icon name="question circle" title="This will auto-generate a password for you" />
              </AutoGenerate>
              {generatedPassword && (
                <>
                  <GeneratePassword>{generatedPassword}</GeneratePassword>
                  <Copy onClick={() => copyPassword(setFieldValue)}>
                    Copy
                    <Icon name="copy" />
                  </Copy>
                </>
              )}
            </AutoGenerateWrapper>
          </PasswordField>
          <FormFields>
            <label htmlFor="password">Confirm Password:</label>
            <CustomField
              name="password2"
              type="password"
              placeholder="password"
              error={errors.password2 && touched.password2}
            />
            <FieldError>
              <ErrorMessage name="password2" />
            </FieldError>
          </FormFields>
          <FormFields>
            <label htmlFor="roles">Role:</label>
            <FieldSelect as="select" name="roles" onChange={handleChange}>
              {roles.map((role) => (
                <option value={role.key} key={role.key}>
                  {role.text}
                </option>
              ))}
            </FieldSelect>
            <FieldError>
              <ErrorMessage name="roles" />
            </FieldError>
          </FormFields>
          <ButtonWeapper>
            <Button type="button" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button positive type="submit">
              <Icon name="save" /> Save
            </Button>
          </ButtonWeapper>
        </MainForm>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.name || !values.name.trim().length) {
    errors.name = 'Please enter name';
  }
  if (!values.password && !values.password.trim()) {
    errors.password = 'Please enter password';
  } else if (values.password && values.password.trim() && values.password.trim().length < 4)
    errors.password = 'Password should contain atleast 4 characters';
  if (
    values.password &&
    (!values.password2 || values.password.trim() !== values.password2.trim())
  ) {
    errors.password2 = 'Confirm password should be same as password';
  }
  if (!values.roles) {
    errors.roles = 'Please select roles';
  }
  if (!values.email) {
    errors.email = 'Please select email';
  }
  return errors;
};

CreateUserForm.propTypes = {
  roles: PropTypes.array,
  closeModal: PropTypes.func,
  createUser: PropTypes.func,
};

export default CreateUserForm;
