import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { SubText } from '../../Components/SubText';
import Icon from '../../Components/Icon';

const Container = styled.div`
  flex: 1;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-wrap: wrap;
`;

const PillWrapper = styled.div`
  background-color: #f3f3f3;
  height: 30px;
  border-radius: 10.5px;
  padding: 2.5px 24px 2.5px 10px;
  position: relative;
  margin: 5px 5px 5px 0;
`;

const IconWrapper = styled.div`
  position: absolute;
  top: 3;
  right: 0;
  bottom: 0px;
  padding: 2px 5px 5px;
  cursor: pointer;
`;

const TagPillsFilter = ({ tagsData, handleTagsMethod }) => {
  console.log('tagsData', tagsData);

  // (!tagsData.length) return null;

  return (
    <Container>
      {tagsData.map((filterKey) => (
        <PillWrapper key={filterKey}>
          <SubText color="#4a4a4a" fontWeight={300}>
            {filterKey.title}
            {/* {showTags(filterKey)} */}
          </SubText>
          <IconWrapper onClick={() => handleTagsMethod(filterKey.id)}>
            <Icon
              name="fillClose"
              size={15}
              color="#009fa2"

              // data-test={`remove-pill.${filterKey.split(' ').join('_')}`}
            />
          </IconWrapper>
        </PillWrapper>
      ))}
    </Container>
  );
};

TagPillsFilter.propTypes = {
  tagsData: PropTypes.object,
  handleTagsMethod: PropTypes.func,
};

export default TagPillsFilter;
