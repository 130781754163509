/* eslint-disable no-unused-expressions */
import React, { useReducer, useEffect, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import renderHTML from 'react-render-html';
import styled from 'styled-components';
import produce from 'immer';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import Moment from 'moment';
import { Input, Icon, Image, Loader, Modal, Button } from 'semantic-ui-react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import TableView from '../../Components/TableView';
import Layout from '../../Components/Layout';
import {
  fetchTagList,
  deleteTagApi,
  editTagApi,
  createNewTagApi,
  fetchTagsGroups,
} from '../../Api';
import TagForm from './TagForm';
import { trimObjectValues } from '../../Utils/trimValues';
const PAGE_SIZE = 10;
const fields = [
  { text: '', value: '' },
  { text: 'No', value: false },
  { text: 'Yes', value: true },
];

const MainLayout = styled.div`
  padding: 20px;
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SectionTitle = styled.div`
  font-size: 2.125rem;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1.25em;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  min-width: 6%;
`;

const SearchWrapper = styled.div`
  width: 100%;
  border: 2px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  .input {
    width: 100%;
  }
  input {
    border: none !important;
  }
`;

const ListWrapper = styled.div`
  .ui.checkbox {
    min-height: 14px;
  }
`;

const TableWrapper = styled.div`
  margin-top: 20px;
`;

const ImageWrapper = styled.div`
  min-height: 50px;
  overflow: hidden;
`;

const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

const AddUser = styled(Button)`
  cursor: pointer;
`;

const Description = styled.div`
  white-space: break-spaces;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 0 5px;
`;

const initialValue = {
  searchQuery: '',
  search: '',
  loading: false,
  tags: [],
  totalTags: 0,
  selectedTags: [],
  currentPage: 1,
  tagLoading: true,
  selectedTagsId: [],
  sortOrder: 'desc',
  sortBy: 'modified_at',
  data: {
    // id: '',
    title: '',
    slug: '',
    cover_image: '',
    description: '',
    is_tag_group: false,
    tag_group: '',
    is_series: false,
    is_stream: false,
    is_default_follow: false,
    is_internal: false,
  },
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_SEARCH_QUERY':
        draft.searchQuery = action.value;
        if (!action.value) {
          draft.search = '';
          draft.currentPage = 1;
        }
        break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        break;
      case 'SET_SELECTED_STATUS':
        draft.selectedStatus = draft.selectedStatus === action.value ? '' : action.value;
        draft.currentPage = 1;
        break;
      case 'SET_SORT':
        if (action.sortBy.dataIndex === draft.sortBy) {
          draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          draft.sortOrder = 'asc';
          draft.sortBy = action.sortBy.dataIndex;
        }
        break;
      case 'SET_TAGS':
        draft.tagList = action.data;
        draft.totalTags = action.total;
        draft.tagLoading = false;
        break;
      case 'SEARCH_TAG':
        draft.search = action.value;
        draft.currentPage = 1;
        break;
      case 'SET_DELETE_MODAL':
        draft.openDeleteModal = action.value;
        if (action.value) {
          draft.deleteTagId = action.deleteTagId;
        } else {
          draft.deleteTagId = null;
          draft.deleteTagLoading = false;
        }
        break;
      case 'SET_DELETE_TAG_LOADING':
        draft.deleteTagLoading = action.value;
        break;
      case 'SET_TAG_LOADING':
        draft.tagLoading = action.value;
        break;
      case 'SET_ADD_TAGS_MODAL':
        draft.openAddTagsModal = action.value;
        if (action.data) draft.data = action.data;
        else
          draft.data = {
            //id: '',
            title: '',
            slug: '',
            is_tag_group: false,
            tag_group: '',
            cover_image: '',
            description: '',
            is_series: false,
            is_stream: false,
            is_default_follow: false,
            is_internal: false,
          };
        break;
      case 'SET_SELECTED_SERIES':
        if (draft.selectedSeries === action.value) draft.selectedSeries = '';
        else draft.selectedSeries = action.value;
        draft.currentPage = 1;
        break;
      case 'SET_DEFAULT_FOLLOW':
        if (draft.defaultFollow === action.value) draft.defaultFollow = '';
        else draft.defaultFollow = action.value;
        draft.currentPage = 1;
        break;
      case 'SET_SELECTED_STREAM':
        if (draft.selectedStream === action.value) draft.selectedStream = '';
        else draft.selectedStream = action.value;
        draft.currentPage = 1;
        break;
      case 'SET_SELECTED_INTERNAL':
        if (draft.selectedInternal === action.value) draft.selectedInternal = '';
        else draft.selectedInternal = action.value;
        draft.currentPage = 1;
        break;
    }
  });

const Tags = ({ location }) => {
  const [tagsList, setTagsList] = useState([]);
  const history = useHistory();
  const querySelectedStatus = queryString.parse(location.search).selectedStatus;
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    search: queryString.parse(location.search).search || '',
    searchQuery: queryString.parse(location.search).search || '',
    sortBy:
      !querySelectedStatus || querySelectedStatus === 'created_at' ? 'created_at' : 'modified_at',
    selectedInternal: queryString.parse(location.search).isInternal || '',
    selectedSeries: queryString.parse(location.search).IsSeries || '',
    defaultFollow: queryString.parse(location.search).IsDefaultFollowed || '',
    selectedStream: queryString.parse(location.search).IsStream || '',
  });
  console.log('tagsList', tagsList);
  const {
    searchQuery,
    search,
    loading,
    tagList,
    data,
    totalTags,
    currentPage,
    sortBy,
    sortOrder,
    tagLoading,
    deleteTagId,
    deleteTagLoading,
    openDeleteModal,
    openAddTagsModal,
    selectedSeries,
    defaultFollow,
    selectedStream,
    selectedInternal,
  } = state;
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: tagList ? tagList.length : 0,
      width: 60,
    },
    {
      title: 'Name',
      dataIndex: 'title',
      key: 'title',
      sorter: tagList ? tagList.length : 0,
      width: 120,
    },
    {
      title: 'Image',
      dataIndex: 'cover_image',
      key: 'image',
      width: 80,
      render: (value) => {
        if (value && value.includes('https://www.youtube.com/watch?v'))
          return (
            <ImageWrapper>
              <Image
                src={`https://i3.ytimg.com/vi/${value.substring(
                  value.indexOf('=') + 1,
                  value.length,
                )}/hqdefault.jpg`}
              />
            </ImageWrapper>
          );
        if (value)
          return (
            <ImageWrapper>
              <Image src={value} />
            </ImageWrapper>
          );
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 200,
      render: (value) => (
        <Description>
          {renderHTML(value ? value.split(' ').slice(0, 100).join(' ') : '')}
        </Description>
      ),
    },
    {
      title: 'Share Text',
      dataIndex: 'share_text',
      key: 'share_text',
      width: 200,
      render: (value) => <Description>{renderHTML(value)}</Description>,
    },
    {
      title: 'Slug',
      dataIndex: 'slug',
      key: 'slug',
      width: 120,
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      sorter: tagList ? tagList.length : 0,
      width: 140,
      render: (value) => <span>{Moment(value).format('MMM DD,YYYY hh:mm A')}</span>,
    },
    {
      title: 'Modified At',
      dataIndex: 'modified_at',
      key: 'modified_at',
      sorter: tagList ? tagList.length : 0,
      width: 140,
      render: (value) => <span>{Moment(value).format('MMM DD,YYYY hh:mm A')}</span>,
    },
    {
      title: 'Series',
      dataIndex: 'is_series',
      key: 'is_series',
      width: 80,
      boolean: true,
      filter: true,
      filterOptions: fields,
      render: (value) => <span>{value ? 'Yes' : 'No'}</span>,
      onFilterSelect: (value) => dispatch({ type: 'SET_SELECTED_SERIES', value }),
      selectedOption: selectedSeries === '' ? '' : selectedSeries.toString(),
    },
    // {
    //   title: 'Followable',
    //   dataIndex: 'is_followable',
    //   key: 'is_followable',
    //   width: 105,
    //   boolean: true,
    //   filter: true,
    //   filterOptions: fields,
    //   onFilterSelect: (value) => dispatch({ type: 'SET_SELECTED_FOLLOWABLE', value }),
    //   selectedOption: selectedFollowable === '' ? '' : selectedFollowable.toString(),
    // },
    {
      title: 'Default Followed',
      dataIndex: 'is_default_follow',
      key: 'is_default_follow',
      width: 145,
      boolean: true,
      filter: true,
      filterOptions: fields,
      render: (value) => <span>{value ? 'Yes' : 'No'}</span>,
      onFilterSelect: (value) => dispatch({ type: 'SET_DEFAULT_FOLLOW', value }),
      selectedOption: defaultFollow === '' ? '' : defaultFollow.toString(),
    },
    {
      title: 'Stream',
      dataIndex: 'is_stream',
      key: 'is_stream',
      width: 80,
      boolean: true,
      filter: true,
      filterOptions: fields,
      render: (value) => <span>{value ? 'Yes' : 'No'}</span>,
      onFilterSelect: (value) => dispatch({ type: 'SET_SELECTED_STREAM', value }),
      selectedOption: selectedStream === '' ? '' : selectedStream.toString(),
    },
    {
      title: 'Is Internal',
      dataIndex: 'is_internal',
      key: 'is_internal',
      width: 110,
      boolean: true,
      filter: true,
      filterOptions: fields,
      render: (value) => <span>{value ? 'Yes' : 'No'}</span>,
      onFilterSelect: (value) => dispatch({ type: 'SET_SELECTED_INTERNAL', value }),
      selectedOption: selectedInternal === '' ? '' : selectedInternal.toString(),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      fixed: 'right',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (value, row) => (
        <div className="action-wrapper">
          <ActionWrapper>
            <IconWrapper
              onClick={() => {
                dispatch({
                  type: 'SET_ADD_TAGS_MODAL',
                  value: true,
                  data: row,
                });
              }}
            >
              <div title="Edit">
                <Icon name="edit" size="large" color={10} />
              </div>
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                dispatch({ type: 'SET_DELETE_MODAL', value: true, deleteTagId: row.id });
              }}
            >
              <div title="Delete">
                <Icon name="archive" size="large" />
              </div>
            </IconWrapper>
          </ActionWrapper>
        </div>
      ),
    },
  ];

  useEffect(() => {
    let searchStr = '';
    if (selectedInternal !== '')
      if (!searchStr) searchStr = `?isInternal=${selectedInternal}`;
      else searchStr = `${searchStr}&isInternal=${selectedInternal}`;
    if (selectedSeries !== '')
      if (!searchStr) searchStr = `?IsSeries=${selectedSeries}`;
      else searchStr = `${searchStr}&IsSeries=${selectedSeries}`;
    if (defaultFollow !== '')
      if (!searchStr) searchStr = `?IsDefaultFollowed=${defaultFollow}`;
      else searchStr = `${searchStr}&IsDefaultFollowed=${defaultFollow}`;
    if (selectedStream !== '')
      if (!searchStr) searchStr = `?IsStream=${selectedStream}`;
      else searchStr = `${searchStr}&IsStream=${selectedStream}`;
    if (search)
      if (!searchStr) searchStr = `?search=${search}`;
      else searchStr += `&search=${search}`;
    history.push({
      pathname: '/tags',
      search: searchStr,
    });
  }, [
    history,
    location.search,
    search,
    selectedInternal,
    selectedSeries,
    defaultFollow,
    selectedStream,
  ]);

  const fetchData = useCallback(async () => {
    dispatch({ type: 'SET_TAG_LOADING', value: true });
    const response = await fetchTagList({
      search,
      page: currentPage,
      size: PAGE_SIZE,
      sortBy,
      sortOrder,
      selectedInternal,
      selectedSeries,
      defaultFollow,
      selectedStream,
    });
    dispatch({ type: 'SET_TAGS', data: response.data.results, total: response.data.count });
  }, [
    search,
    currentPage,
    sortBy,
    sortOrder,
    selectedInternal,
    selectedSeries,
    defaultFollow,
    selectedStream,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getTagsGroup = () => {
    fetchTagsGroups()
      .then((response) => {
        response.data.results.unshift({
          id: null,
          title: 'Select group',
          description: null,
          cover_image: null,
          language_id: null,
          created_at: new Date(),
          modified_at: new Date(),
          config: {},
        });
        setTagsList(response.data.results);
      })
      .catch((error) => {
        console.log('🚀 ~ file: TagForm.js fetchTagsGroupList ~ error', error);
      });
  };
  useEffect(() => {
    getTagsGroup();
  }, [tagList]);
  const searchTags = (value) => {
    dispatch({ type: 'SET_SEARCH_QUERY', value });
  };

  const handleKeyDown = (keyDownEvent) => {
    if (keyDownEvent.keyCode === 32) {
      keyDownEvent.stopPropagation();
    }
    if ((keyDownEvent.keyCode === 67 && keyDownEvent.altKey) || keyDownEvent.keyCode === 191) {
      keyDownEvent.preventDefault();
    }

    if (keyDownEvent.keyCode === 13) {
      keyDownEvent.preventDefault();
      dispatch({ type: 'SEARCH_TAG', value: searchQuery });
    }

    if (keyDownEvent.keyCode === 27) {
      keyDownEvent.preventDefault();
    }
  };

  const closeModal = () => {
    dispatch({ type: 'SET_ADD_TAGS_MODAL', value: false });
  };

  const createTag = ({ values, setSubmitting }) => {
    setSubmitting(true);
    const payload = trimObjectValues(values, { removeBlanks: true });
    // let newPayload = {}
    //payload.is_tag_group === false ? (payload.tag_group = null) : payload.tag_group;
    console.log('payload data', payload);
    if (!values.id) {
      createNewTagApi(payload)
        .then(() => {
          ToastsStore.success('Tag created successfully.');
          setTimeout(() => fetchData(), 1000);
          setSubmitting(false);
          closeModal();
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          const errorMsg =
            typeof error.response.data === 'object'
              ? Object.values(error.response.data)[0]
              : 'Unable to create tag.';
          ToastsStore.error(errorMsg);
          setSubmitting(false);
        });
    } else {
      let modifiedPayload = { ...payload };
      modifiedPayload['tag_group'] = payload?.tag_group?.id;
      editTagApi(modifiedPayload, values.id)
        .then(() => {
          ToastsStore.success('Tag updated successfully.');
          setTimeout(() => fetchData(), 1000);
          setSubmitting(false);
          closeModal();
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          const errorMsg =
            typeof error.response.data === 'object'
              ? Object.values(error.response.data)[0]
              : 'Unable to edit tag.';
          ToastsStore.error(errorMsg);
          setSubmitting(false);
        });
    }
  };

  return (
    <Layout location={location}>
      <Helmet>
        <title>XND</title>
      </Helmet>
      <MainLayout>
        <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
        <Flex>
          <SectionTitle> Tag Manager</SectionTitle>
          <ButtonWrapper>
            <AddUser
              icon="add user"
              content="Add Tag"
              positive
              labelPosition="right"
              onClick={() => {
                dispatch({
                  type: 'SET_ADD_TAGS_MODAL',
                  value: true,
                });
              }}
            />
          </ButtonWrapper>
        </Flex>
        <ButtonWrapper>
          <SearchWrapper>
            <Input
              icon="search"
              placeholder="Search..."
              value={searchQuery}
              disabled={loading}
              onKeyDown={handleKeyDown}
              onChange={(e, tag) => searchTags(tag.value)}
              iconPosition="left"
              action={
                <Icon
                  name="x"
                  link
                  className={searchQuery.length ? 'pull-right left-auto' : 'hiddenField'}
                  onClick={() => searchTags('')}
                />
              }
            />
          </SearchWrapper>
        </ButtonWrapper>
        <ListWrapper>
          <TableWrapper>
            <TableView
              tableColumns={columns}
              data={tagList}
              pagination
              pageSize={PAGE_SIZE}
              totalItems={totalTags}
              activePage={currentPage}
              onPageChange={(value) => dispatch({ type: 'SET_CURRENT_PAGE', value })}
              toggleSortOrder={(sort) => dispatch({ type: 'SET_SORT', sortBy: sort })}
              sortBy={sortBy}
              sortOrder={sortOrder === 'asc' ? 'ascend' : 'descend'}
              onRow={(record) => ({
                onClick: (e) => {
                  dispatch({
                    type: 'SET_ADD_TAGS_MODAL',
                    value: true,
                    data: record,
                  });
                },
              })}
            />
          </TableWrapper>
          {tagLoading && (
            <LoaderWrapper>
              <Loader active={tagLoading} />
            </LoaderWrapper>
          )}
          {openDeleteModal && (
            <Modal size="tiny" open={openDeleteModal} onClose={() => {}}>
              <Modal.Content>Are you sure you want to delete this tag ?</Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  disabled={deleteTagLoading}
                  onClick={() => {
                    dispatch({ type: 'SET_DELETE_MODAL', value: false, deleteTagId: null });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  positive
                  content="Yes delete"
                  disabled={deleteTagLoading}
                  onClick={() => {
                    dispatch({ type: 'SET_DELETE_TAG_LOADING', value: true });
                    deleteTagApi({ id: deleteTagId })
                      .then(() => {
                        fetchData();
                        dispatch({ type: 'SET_DELETE_MODAL', value: false });
                        ToastsStore.success('Tag deleted successfully.');
                      })
                      .catch((error) => {
                        if (window) window.Raven.captureException(error.response);
                        dispatch({ type: 'SET_DELETE_MODAL', value: false });
                        if (error.response.status === 400) {
                          ToastsStore.error(
                            (error.response.data &&
                              error.response.data.title &&
                              error.response.data.title[0]) ||
                              'Something went wrong',
                          );
                        } else {
                          ToastsStore.error(
                            (error.response && error.response.message) || 'Something went wrong',
                          );
                        }
                      });
                  }}
                />
              </Modal.Actions>
            </Modal>
          )}
          {openAddTagsModal && (
            <Modal open={openAddTagsModal} onClose={closeModal} closeIcon>
              <Modal.Header>Tag Manager</Modal.Header>
              <TagForm
                closeModal={closeModal}
                createTag={createTag}
                data={data}
                tagList={tagsList}
                tagIds={deleteTagId}
              />
            </Modal>
          )}
        </ListWrapper>
      </MainLayout>
    </Layout>
  );
};

Tags.propTypes = {
  location: PropTypes.object,
};

export default Tags;
