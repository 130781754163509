import React from 'react';
import PropTypes from 'prop-types';
//import styled from 'styled-components';
import TableView from '../../Components/TableView';

// const List = styled.li`
//   list-style: none;
//   text-align: left;
//   padding: 10px 0;
//   border-bottom: 1px solid #d8d8d8 !important;
// `;

const VideoReportTable = ({ data }) => {
  const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (value) => value || '-',
      align: 'center',
    },
    {
      title: 'Tag Titles',
      dataIndex: 'tag_titles',
      key: 'tag_titles',
      render: (value) => value || '-',
      align: 'center',
    },
    {
      title: 'Views',
      dataIndex: 'views',
      key: 'views',
      render: (value) => value || '-',
      align: 'center',
    },
    {
      title: 'Collabartor Names',
      dataIndex: 'collab_names',
      key: 'collab_names',
      render: (value) => value || '-',
      align: 'center',
    },
    {
      title: 'Avg view %',
      dataIndex: 'avg_view',
      key: 'avg_watch',
      render: (value) => value || '-',
      align: 'center',
    },
    {
      title: 'Like Count',
      dataIndex: 'like_count',
      key: 'like_count',
      render: (value) => value,
      align: 'center',
    },
    {
      title: 'Dislike Count',
      dataIndex: 'dislike_count',
      key: 'dislike_count',
      render: (value) => value,
      align: 'center',
    },
    {
      title: 'Share Count',
      dataIndex: 'share_count',
      key: 'share_count',
      render: (value) => value,
      align: 'center',
    },
    {
      title: 'Total_time',
      dataIndex: 'total_time',
      key: 'total_time',
      render: (value) => value || '-',
      align: 'center',
    },
  ];
  return (
    <>
      <TableView tableColumns={columns} data={data}  />
    </>
  );
};

VideoReportTable.propTypes = {
  data: PropTypes.object,
};

export default VideoReportTable;
