/* eslint-disable consistent-return */
import React, { useReducer, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import produce from 'immer';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Button, Icon, Loader, Accordion, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Layout from '../../Components/Layout';
import {
  fetchUserList,
  fetchListDetails,
  createGroup,
  deleteGroupApi,
  editGroupApi,
} from '../../Api';
import TableView from '../../Components/TableView';
import Pagination from '../../Components/Pagination';
import CreateUserForm from './CreateUserForm';

const SectionTitle = styled.div`
  font-size: 2.125rem;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1.25em;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 300px;
`;

const TitleTab = styled(Accordion.Title)`
  box-shadow: #d8d8d8 0px 0px 10px -2px;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: ${(props) => (props.active ? '#f6f6f6' : '#ffffff')};
  font-weight: ${(props) => (props.active ? 900 : 500)};
  z-index: 2;
  border-radius: 0;
  box-shadow: none;
  border: 1px solid #e9e9e9;
  border-image: initial;
  border-left: ${(props) => (props.active ? '8px solid #f32826' : '1px solid #e9e9e9')};
  overflow: hidden;
  padding: 20px 10px 20px 14px !important;
  justify-content: left;

  i {
    margin-right: 10px;
  }
`;

const AccordionStyled = styled.div`
  margin-top: 30px;
  display: flex;
  height: auto;
`;

const AccordionPanel = styled.div`
  // height: 100%;
  // display: flex;
`;

const AccordionContent = styled.div`
  display: block;
  background-color: rgb(255, 255, 255);
  position: relative;
  border-image: initial;
  overflow: auto;
  margin: 0px;
  flex: 1;
`;

const AccordionIcon = styled(Icon)`
  color: #f32826;
  font-size: 14px !important;
  font-weight: bolder !important;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 0 30px;

  .rc-pagination-prev,
  .rc-pagination-next,
  rc-pagination-item-active {
    outline: none;
    border-color: transparent;
  }
`;

const AddUser = styled(Button)`
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Tabs = styled.div`
  width: 20%;
`;

const MainLayout = styled.div`
  padding: 20px;
  height: 100vh;
`;

const TableWrapper = styled.div`
  // margin-top: 20px;
  tr {
    cursor: auto;
  }
`;

const ButtonWrapper = styled.div`
  padding: 0px 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const initialValue = {
  loading: true,
  errors: {},
  active: 0,
  totalItems: 0,
  disableTagFollowed: false,
  addNotifVisible: true,
  userList: [],
  currentPage: 1,
  currentUserPage: 1,
  sortOrder: 'desc',
  sortBy: 'id',
  userDetails: [],
  createLoading: false,
  deleteGroup: { users: [], remove_users: [], id: '', title: '', description: '' },
  activeSegment: {},
  // activeSegmentOriginal: {},
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_ACTIVE':
        if (draft.active !== action.tab) {
          draft.active = action.tab;
          draft.activeSegment = action.segment;
          draft.currentUserPage = 1;
        }
        break;
      case 'SET_USER_LIST':
        draft.userList = action.value.results;
        draft.activeSegment = { ...action.value.results[0] };
        draft.active = 0;
        draft.totalItems = action.value.count;
        draft.loading = false;
        break;
      case 'SET_INITIAL_GROUP':
        draft.activeSegmentOriginal = { ...action.value.results[0] };
        draft.active = 0;
        break;
      case 'SET_USER_VALUE':
        draft.userDetails = action.value.results;
        draft.totalCount = action.value.count;
        break;
      case 'GROUP_CREATE':
        draft.groupCreate = action.value;
        if (!action.value) draft.createLoading = false;
        draft.deleteGroup = { users: [], remove_users: [], id: '', title: '', description: '' };
        break;
      case 'SET_CREATE_LOADING':
        draft.createLoading = action.value;
        break;
      case 'SET_LOADING':
        draft.loading = action.value;
        break;
      case 'SET_DELETE_MODAL':
        draft.openDeleteModal = action.value;
        if (action.value) {
          draft.deleteGroup = action.deleteGroup;
        } else {
          draft.deleteGroup = { users: [], remove_users: [], id: '', title: '', description: '' };
          draft.deleteLoading = false;
        }
        break;
      case 'SET_EDIT_MODAL':
        draft.groupCreate = action.value;
        if (action.value) {
          draft.deleteGroup = action.deleteGroup;
        } else {
          draft.deleteGroup = { users: [], remove_users: [], id: '', title: '', description: '' };
          draft.createLoading = false;
        }
        break;
      case 'SET_DELETE_GROUP_LOADING':
        draft.deleteLoading = action.value;
        break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        draft.currentUserPage = 1;
        break;
      case 'SET_CURRENT_USER_PAGE':
        draft.currentUserPage = action.value;
        break;
    }
  });

const ViewGroups = ({ location }) => {
  const [state, dispatch] = useReducer(reducer, initialValue);
  const history = useHistory();
  const {
    totalItems,
    userList,
    loading,
    active,
    userDetails,
    totalCount,
    currentPage,
    currentUserPage,
    groupCreate,
    createLoading,
    openDeleteModal,
    activeSegment,
    deleteGroup,
    deleteLoading,
  } = state;

  const columns = [
    {
      title: 'Id',
      dataIndex: 'user.id',
      key: 'user.id',
      width: 150,
    },
    {
      title: 'Name',
      dataIndex: 'user.name',
      key: 'name',
      width: 320,
    },
    {
      title: 'Email',
      dataIndex: 'user.email',
      key: 'email',
      width: 370,
    },
    {
      title: 'Phone Number',
      dataIndex: 'user.phone',
      key: 'phone',
      width: 370,
    },
  ];

  const fetchUserData = useCallback(async () => {
    if (activeSegment.id) {
      const response = await fetchListDetails({
        id: activeSegment.id,
        page: currentUserPage,
      });
      dispatch({ type: 'SET_USER_VALUE', value: response.data });
    }
  }, [currentUserPage, activeSegment.id]);

  const fetchData = useCallback(
    async (deletedPage) => {
      const response = await fetchUserList({ page: deletedPage || currentPage });
      dispatch({
        type: 'SET_USER_LIST',
        value: response.data,
      });
      if (currentPage === 1) dispatch({ type: 'SET_INITIAL_GROUP', value: response.data });
    },
    [currentPage],
  );

  const closeUserModal = () => {
    dispatch({ type: 'GROUP_CREATE', value: false });
  };

  const saveUser = async (values) => {
    dispatch({ type: 'SET_CREATE_LOADING', value: true });
    if (!values.id) {
      try {
        await createGroup({ values });
        ToastsStore.success('Group created successfully.');
        closeUserModal();
        dispatch({ type: 'SET_LOADING', value: true });
        setTimeout(() => fetchData(), 1000);
      } catch (e) {
        ToastsStore.error('Unable to create group');
      }
    } else {
      try {
        await editGroupApi({ values });
        ToastsStore.success('Group updated successfully.');
        closeUserModal();
        dispatch({ type: 'SET_LOADING', value: true });
        setTimeout(() => fetchData(), 1000);
      } catch (e) {
        ToastsStore.error('Unable to update group');
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const setActiveTab = async (tab, id, segment) => {
    if (tab !== active) {
      dispatch({ type: 'SET_ACTIVE', tab, segment });
    }
  };

  return (
    <Layout location={location}>
      <Helmet>
        <title>XND</title>
      </Helmet>
      <MainLayout>
        <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
        <Flex>
          <SectionTitle>View Groups</SectionTitle>
          <Flex>
            <AddUser
              icon="add user"
              content="Create Group"
              positive
              labelPosition="right"
              onClick={() => dispatch({ type: 'GROUP_CREATE', value: true })}
            />
            <AddUser
              icon="add user"
              content="Create Notification"
              positive
              labelPosition="right"
              onClick={() => history.push('/notification/create')}
            />
          </Flex>
        </Flex>
        {loading ? (
          <LoaderWrapper>
            <Loader active={loading} />
          </LoaderWrapper>
        ) : (
          <>
            <AccordionStyled fluid exclusive={false}>
              <Tabs>
                {userList.map((segment, index) => {
                  const currentSegment = active === index;
                  return (
                    <AccordionPanel
                      key={segment.id}
                      onClick={() => setActiveTab(index, segment.id, segment)}
                    >
                      <TitleTab active={currentSegment} index={segment.segment_id}>
                        <AccordionIcon
                          name={currentSegment ? 'chevron up' : 'chevron down'}
                          active={currentSegment}
                        />
                        {segment.title}
                      </TitleTab>
                      {/* {userDetails.length && activeSegment === index && ( */}

                      {/* )} */}
                    </AccordionPanel>
                  );
                })}
              </Tabs>
              <AccordionContent>
                <ButtonWrapper>
                  <Button
                    positive
                    onClick={() =>
                      dispatch({
                        type: 'SET_EDIT_MODAL',
                        value: true,
                        deleteGroup: activeSegment,
                      })
                    }
                    disabled={createLoading}
                  >
                    Edit
                  </Button>
                  <Button
                    content="Delete"
                    negative
                    disabled={createLoading}
                    onClick={() => {
                      dispatch({
                        type: 'SET_DELETE_MODAL',
                        value: true,
                        deleteGroup: activeSegment,
                      });
                    }}
                  />
                </ButtonWrapper>
                <TableWrapper>
                  <TableView
                    tableColumns={columns}
                    data={userDetails}
                    pagination
                    pageSize={10}
                    totalItems={totalCount}
                    activePage={currentUserPage}
                    onPageChange={(value) => dispatch({ type: 'SET_CURRENT_USER_PAGE', value })}
                    toggleSortOrder={(sortBy) => dispatch({ type: 'SET_SORT', sortBy })}
                  />
                </TableWrapper>
              </AccordionContent>
            </AccordionStyled>

            {totalItems > 10 && (
              <PaginationWrapper>
                <Pagination
                  activePage={currentPage}
                  totalItems={totalItems}
                  totalPages={Math.round(totalItems / 10)}
                  onChangePage={(current) => dispatch({ type: 'SET_CURRENT_PAGE', value: current })}
                  pageSize={10}
                />
              </PaginationWrapper>
            )}
          </>
        )}
      </MainLayout>
      <Modal open={groupCreate} onClose={closeUserModal} closeIcon>
        <Modal.Header>{deleteGroup.id ? 'Edit Group' : 'Create Group'}</Modal.Header>
        <CreateUserForm
          closeModal={closeUserModal}
          saveUser={saveUser}
          createLoading={createLoading}
          deleteGroup={deleteGroup}
        />
      </Modal>
      {openDeleteModal && (
        <Modal size="tiny" open={openDeleteModal} onClose={() => {}}>
          <Modal.Content>Are you sure you want to delete the group?</Modal.Content>
          <Modal.Actions>
            <Button
              negative
              disabled={deleteLoading}
              onClick={() => {
                dispatch({
                  type: 'SET_DELETE_MODAL',
                  value: false,
                  deleteGroup: { users: [], remove_users: [], id: '', title: '', description: '' },
                });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              content="Yes delete"
              disabled={deleteLoading}
              onClick={async () => {
                dispatch({ type: 'SET_DELETE_GROUP_LOADING', value: true });
                try {
                  dispatch({ type: 'SET_LOADING', value: true });
                  await deleteGroupApi({ id: deleteGroup.id });
                  setTimeout(() => fetchData(1), 1000);
                  dispatch({ type: 'SET_DELETE_MODAL', value: false });
                  ToastsStore.success('Group deleted successfully.');
                } catch (error) {
                  dispatch({ type: 'SET_LOADING', value: true });
                  if (window) window.Raven.captureException(error.response);
                  dispatch({ type: 'SET_DELETE_MODAL', value: false });
                  if (error.response.status === 400) {
                    ToastsStore.error(
                      (error.response.data &&
                        error.response.data.title &&
                        error.response.data.title[0]) ||
                        'Something went wrong',
                    );
                  } else {
                    ToastsStore.error(
                      (error.response && error.response.message) || 'Something went wrong',
                    );
                  }
                }
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
    </Layout>
  );
};

ViewGroups.propTypes = {
  location: PropTypes.string,
};

export default ViewGroups;
