import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Container } from 'semantic-ui-react';
import FeedsMenu from '../FeedMenu';
import PageHeader from '../Header';

const Wrapper = styled(Container)`
  width: 95% !important;
  padding-left: ${(props) => (props.feed ? '235px' : 0)};
  margin-right: 0;
`;

const Content = styled.div`
  margin-left: 50px;
  padding-top: 100px;

  @media (max-width: 768px) {
    margin-left: 0px;
    padding-top: 50px;
  }
`;

const Layout = (props) => (
  <>
    <PageHeader
      location={props.location}
      isDashboard={props.isDashboard}
      disabledUrl={props.disabledUrl}
      urlString={props.urlString}
      path={props.path}
      errors={props.errors}
      clearDigestsListFilter={props.clearDigestsListFilter || null}
      isDigestList={props.isDigestList}
    />
    {props.feed && <FeedsMenu location={props.location} />}
    <Wrapper feed={props.feed}>
      <Content>{props.children}</Content>
    </Wrapper>
  </>
);

Layout.propTypes = {
  location: PropTypes.object,
  isDashboard: PropTypes.bool,
  disabledUrl: PropTypes.bool,
  urlString: PropTypes.string,
  path: PropTypes.string,
  errors: PropTypes.string,
  clearDigestsListFilter: PropTypes.func,
  feed: PropTypes.bool,
  isDigestList: PropTypes.bool,
  children: PropTypes.array,
};

export default Layout;
