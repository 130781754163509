import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

const Para = styled.div``;

const Label = styled.label``;

const ToggleButton = styled.span`
  width: 145px;
  height: 50px;
  display: inline-block;
  vertical-align: middle;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 30px;
  position: relative;
  margin-left: 1%;
  div {
    z-index: 10;
    color: white;
    font-size: 1.7em;
    margin: 0;
  }
  .correct {
    left: 14px;
    top: -6px;
  }
  .wrong {
    right: 9px;
    top: -6px;
  }
  .neutral-icon {
    left: 62px;
    top: -6px;
    opacity: 0.5;
  }
  label {
    z-index: 9;
    display: flex;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
  }
  input[type='radio'] {
    display: none;
  }
  i {
    z-index: 99;
    font-size: 18px;
  }
  .neutral + label {
    position: absolute;
    left: 33%;
    transition: transform 1s;
  }

  .neutral:checked + label {
    border: 2px solid rgba(0, 0, 0, 1);
    background: rgb(238, 238, 238);
    background: -moz-linear-gradient(top, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(238, 238, 238, 1)),
      color-stop(100%, rgba(204, 204, 204, 1))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(238, 238, 238, 1) 0%,
      rgba(204, 204, 204, 1) 100%
    );
    background: -o-linear-gradient(top, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
    background: -ms-linear-gradient(top, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
    background: linear-gradient(to bottom, rgba(238, 238, 238, 1) 0%, rgba(204, 204, 204, 1) 100%);
  }

  .on + label {
    position: absolute;
    left: 0;
    text-align: center;
  }

  .on:checked + label {
    background: rgb(32, 213, 50);
    background: -moz-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(32, 213, 50, 1)),
      color-stop(100%, rgba(28, 195, 1, 1))
    );
    background: -webkit-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: -o-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: -ms-linear-gradient(top, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    background: linear-gradient(to bottom, rgba(32, 213, 50, 1) 0%, rgba(28, 195, 1, 1) 100%);
    -moz-animation-duration: 0.4s;
    -moz-animation-name: slidein;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: slidein;
    animation-duration: 0.4s;
    animation-name: slidein;
    -webkit-transform: translateZ(0);
  }

  .no + label {
    position: absolute;
    right: 0;
    text-align: center;
  }

  .no:checked + label {
    background: red;
    -moz-animation-duration: 0.4s;
    -moz-animation-name: slideno;
    -webkit-animation-duration: 0.4s;
    -webkit-animation-name: slideno;
    animation-duration: 0.4s;
    animation-name: slideno;
    -webkit-transform: translateZ(0);
    background: rgb(230, 108, 103);
    background: -moz-linear-gradient(top, rgba(230, 108, 103, 1) 0%, rgba(221, 79, 75, 1) 100%);
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(230, 108, 103, 1)),
      color-stop(100%, rgba(221, 79, 75, 1))
    );
    background: -webkit-linear-gradient(top, rgba(230, 108, 103, 1) 0%, rgba(221, 79, 75, 1) 100%);
    background: -o-linear-gradient(top, rgba(230, 108, 103, 1) 0%, rgba(221, 79, 75, 1) 100%);
    background: -ms-linear-gradient(top, rgba(230, 108, 103, 1) 0%, rgba(221, 79, 75, 1) 100%);
    background: linear-gradient(to bottom, rgba(230, 108, 103, 1) 0%, rgba(221, 79, 75, 1) 100%);
  }

  @keyframes slidein {
    from {
      transform: translate(50px, 0);
    }
    to {
      transform: translate(0px, 0px);
    }
  }

  @keyframes slideno {
    from {
      transform: translate(-50px, 0);
    }
    to {
      transform: translate(0px, 0px);
    }
  }

  @keyframes returnLeft {
    from {
      transform: translate(-50px, 0);
    }
    to {
      transform: translate(0px, 0);
    }
  }

  @keyframes returnRight {
    from {
      transform: translate(50px, 0);
    }
    to {
      transform: translate(0px, 0);
    }
  }
`;

// eslint-disable-next-line react/jsx-props-no-spreading
const Toggle = (props) => (
  <ToggleButton id={props.id}>
    {/* <Para className="correct" onClick={() => props.onClick(true)}>
      <Icon name="check circle" size="small" onClick={() => props.onClick(true)} />
      <input
        type="radio"
        name={props.id}
        className="on"
        id="on"
        value
        checked={props.on === true}
        onChange={() => props.onClick(true)}
      />
    </Para>
    <Para className="neutral-icon" onClick={() => props.onClick(null)}>
      <Icon name="dot circle" size="small" onClick={() => props.onClick(null)}/>
      <input
        type="radio"
        name={props.id}
        className="neutral"
        id="null"
        value={null}
        checked={props.on === null}
        onChange={() => props.onClick(null)}
      />
    </Para>
    <Para className="wrong" onClick={() => props.onClick(false)}>
      <Icon name="times circle" size="small" onClick={() => props.onClick(false)}/>
      <input
        type="radio"
        name={props.id}
        className="no"
        id="off"
        value={false}
        checked={props.on === false}
        onChange={() => props.onClick(false)}
      />
    </Para> */}
    <Para
      className="correct"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(true);
      }}
    >
      <input
        type="radio"
        name={props.id}
        className="on"
        id="on"
        value
        checked={props.on === true}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onClick(true);
        }}
      />
      <Label htmlFor="on" onClick={(e) => props.onClick(true)}>
        <Icon name="check circle" size="small" />
      </Label>
    </Para>
    <Para
      className="correct"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(true);
      }}
    >
      <input
        type="radio"
        name={props.id}
        className="neutral"
        id="null"
        value={null}
        checked={props.on === null}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onClick(null);
        }}
      />
      <Label
        htmlFor="null"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onClick(null);
        }}
      >
        <Icon name="dot circle" size="small" onClick={() => props.onClick(null)} />
      </Label>
    </Para>
    <Para
      className="correct"
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onClick(true);
      }}
    >
      <input
        type="radio"
        name={props.id}
        className="no"
        id="off"
        value={false}
        checked={props.on === false}
        onChange={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onClick(false);
        }}
      />
      <Label
        htmlFor="off"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          props.onClick(false);
        }}
      >
        <Icon name="times circle" size="small" onClick={() => props.onClick(false)} />
      </Label>
    </Para>
  </ToggleButton>
);

export default Toggle;

Toggle.propTypes = {
  on: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
};
