/* eslint-disable consistent-return */
import React, { useReducer, useEffect, useCallback, useState } from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { Helmet } from 'react-helmet';
import { useHistory, useLocation } from 'react-router-dom';
import produce from 'immer';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Button, Loader, Message, Icon, Modal } from 'semantic-ui-react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import Layout from '../../Components/Layout';
import 'react-datetime/css/react-datetime.css';
import {
  fetchNotificationHistory,
  fetchUserListFull,
  fetchChannelsApi,
  cancelNotificationApi,
  fetchUsers,
} from '../../Api';
import TableView from '../../Components/TableView';
import FilterPills from '../Digests/FilterPills';
import { notificationTypes } from './constants';
import moment from 'moment';
import DateFilter from './DateFilter';

const statusArray = [
  { key: 'scheduled', value: 'SCHEDULED', text: 'SCHEDULED' },
  { key: 'complete', value: 'COMPLETE', text: 'COMPLETE' },
];

const SectionTitle = styled.div`
  font-size: 2.125rem;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1.25em;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalSaveButton = styled(Button)`
  background-color: #009fa2 !important;
  color: #fff !important;
`;

const IconWrapper = styled.div`
  padding: 0 5px;
  cursor: pointer;
`;

const TableHeader = styled.div`
  height: 50px;
  left: 53px;
  width: calc(100% - 60px);
  background-color: #fff;
  z-index: 5;
  display: flex;
  align-items: center;
  padding: 5px;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 300px;
`;

const Label = styled.div`
  margin: ${({ m }) => m || 0};
  margin-right: 30px;
  color: ${({ error }) => (error ? '#FF5722' : 'rgba(0,0,0,.87)')} !important;
  width: ${({ w }) => w || '20%'};
`;

const TableWrapper = styled.div`
  margin-top: 20px;
  tr {
    cursor: auto;
  }
`;

const AddUser = styled(Button)`
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Status = styled(Message)`
  padding: 0.5em 1em !important;
  margin: 0.3em 0 !important;
`;

const MainLayout = styled.div`
  padding: 20px;
  position: relative;
`;

const initialValue = {
  loading: true,
  errors: {},
  totalItems: 0,
  disableTagFollowed: false,
  addNotifVisible: true,
  notificationList: [],
  notificationType: [],
  selectedUser: '',
  selectedChannel: '',
  selectedStatus: '',
  selectedGroup: '',
  currentPage: 1,
  sortOrder: 'desc',
  sortBy: 'id',
  userList: [],
  filterObject: {},
  filterLoading: false,
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_SELECTED_TYPE': {
        draft.notificationType = action.value;
        draft.currentPage = 1;
        if (action.value.length) {
          const notifSelected =
            notificationTypes.filter((val) => action.value.includes(val.value)) || [];
          draft.filterObject.notificationType = notifSelected.slice();
        } else delete draft.filterObject.notificationType;
        break;
      }
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        break;
      case 'SET_SELECTED_USER':
        draft.selectedUser = action.value;
        draft.currentPage = 1;
        if (action.value) {
          const userSelected =
            draft.userList.filter((val) => val.value === draft.selectedUser) || null;
          draft.filterObject.selectedUser = userSelected[0].text;
        } else if (draft.filterObject.selectedUser) delete draft.filterObject.selectedUser;
        break;
      case 'SET_SELECTED_CHANNEL':
        draft.selectedChannel = action.value;
        draft.currentPage = 1;
        if (action.value) {
          const userSelected =
            draft.channelList.filter((val) => val.value === action.value) || null;
          draft.filterObject.selectedChannel = userSelected[0].text;
        } else if (draft.filterObject.selectedChannel) delete draft.filterObject.selectedChannel;
        break;
      case 'SET_SELECTED_GROUP':
        draft.selectedGroup = action.value;
        draft.currentPage = 1;
        if (action.value) {
          const userSelected =
            draft.groupList.filter((val) => val.value === draft.selectedGroup) || null;
          draft.filterObject.selectedGroup = userSelected[0].text;
        } else if (draft.filterObject.selectedGroup) delete draft.filterObject.selectedGroup;
        break;
      case 'SET_SELECTED_DASHBOARD_USER':
        draft.selectedDashboardUser = action.value;
        draft.currentPage = 1;
        if (action.value) {
          draft.filterObject.selectedDashboardUser = action.option.text;
        } else if (draft.filterObject.selectedDashboardUser)
          delete draft.filterObject.selectedDashboardUser;
        break;
      case 'SET_SELECTED_STATUS':
        draft.selectedStatus = action.value;
        draft.currentPage = 1;
        if (action.value) {
          const userSelected = statusArray.filter((val) => val.value === action.value) || null;
          draft.filterObject.selectedStatus = userSelected[0].text;
        } else if (draft.filterObject.selectedStatus) delete draft.filterObject.selectedStatus;
        break;
      case 'SET_USER_LIST':
        draft.userList = action.value;
        if (action.value.length) {
          action.value.unshift({ type: '', value: '' });
          if (draft.selectedUser) {
            const userSelected =
              action.value.filter((val) => val.value === draft.selectedUser) || null;
            draft.filterObject.selectedUser = userSelected[0].text;
          }
        }
        if (draft.notificationType) {
          const notifSelected =
            notificationTypes.filter((val) => draft.notificationType.includes(val.value)) || [];
          draft.filterObject.notificationType = notifSelected.slice();
        }
        break;

      case 'SET_DASHBOARD_USER_LIST':
        draft.dashboardUsers = action.value;
        if (action.value) {
          const userSelected =
            action.value.filter((val) => val.value === draft.selectedDashboardUser) || null;
          if (userSelected[0]) {
            draft.filterObject.selectedDashboardUser = userSelected[0].text;
          }
        } else if (draft.filterObject.selectedDashboardUser)
          delete draft.filterObject.selectedDashboardUser;
        break;
      case 'SET_CHANNEL_LIST':
        draft.channelList = action.value;
        if (draft.selectedChannel) {
          const userSelected =
            action.value.filter((val) => val.value === draft.selectedChannel) || null;
          draft.filterObject.selectedChannel = userSelected[0].text;
        }
        break;
      case 'SET_GROUP_LIST':
        draft.groupList = action.value;
        if (draft.selectedGroup) {
          const userSelected =
            action.value.filter((val) => val.value === draft.selectedGroup) || null;
          draft.filterObject.selectedGroup = userSelected[0].text;
        }
        break;
      case 'SET_NOTIFICATION_LIST':
        draft.notificationList = action.value;
        draft.filterLoading = false;
        draft.totalItems = action.count;
        draft.loading = false;
        break;
      case 'SET_LOADING':
        draft.loading = action.value;
        break;
      case 'SET_VALUE': {
        if (action.index !== undefined) {
          const listData = draft.data.notification_data[action.index];
          if (action.apiData) {
            listData.notif_content_data.article_id = action.value;
            listData.title = action.title;
            listData.image = action.image;
            draft.loading = false;
          } else if (!action.subKey) listData[action.key] = action.value;
          else {
            listData.notif_content_data[action.subKey] = action.value;
          }
          draft.data.notification_data[action.index] = { ...listData };
        } else draft.data = action.value;
        break;
      }
      case 'SET_SORT':
        if (action.sortBy.dataIndex === draft.sortBy || action.sortBy.key === draft.sortBy) {
          draft.sortOrder = draft.sortOrder === 'asc' ? 'desc' : 'asc';
        } else {
          draft.sortOrder = 'asc';
          draft.sortBy = action.sortBy.dataIndex;
        }
        break;
      case 'SET_FILTER':
        draft.filterObject = action.value;
        break;
      case 'SET_FILTER_LOADING':
        draft.filterLoading = action.value;
        draft.digestLoading = true;
        break;
      case 'CONFIRM_CANCEL':
        draft.confirmCancel = action.value;
        break;
    }
  });

const Notifications = ({ location }) => {
  const { search } = useLocation();
  const querySelectedStatus = queryString.parse(location.search).selectedStatus;
  let userUrl = queryString.parse(location.search).selectedUser;
  if (userUrl && userUrl !== 'all')
    userUrl = parseInt(queryString.parse(location.search).selectedUser, 10);
  else userUrl = 'all';
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    notificationType: queryString.parse(location.search).notificationType
      ? queryString.parse(location.search).notificationType.split(',')
      : [],
    selectedChannel: queryString.parse(location.search).selectedChannel
      ? parseInt(queryString.parse(location.search).selectedChannel, 10)
      : '',
    selectedUser: userUrl,
    selectedGroup: queryString.parse(location.search).selectedGroup
      ? parseInt(queryString.parse(location.search).selectedGroup, 10)
      : '',
    selectedDashboardUser: queryString.parse(location.search).selectedDashboardUser
      ? parseInt(queryString.parse(location.search).selectedDashboardUser, 10)
      : '',
    selectedStatus: queryString.parse(location.search).selectedStatus
      ? queryString.parse(location.search).selectedStatus
      : '',
    sortBy:
      !querySelectedStatus || querySelectedStatus === 'broadcast_id'
        ? 'broadcast_id'
        : 'modified_at',
  });

  const [showDateFilter, setShowDateFilter] = useState(false);

  const [filterDateValues, setFilterDateValues] = useState({
    fromDate: queryString.parse(search).startDate || null,
    toDate: queryString.parse(search).endDate || null,
  });

  const {
    notificationType,
    selectedUser,
    selectedChannel,
    selectedGroup,
    selectedStatus,
    notificationList,
    totalItems,
    currentPage,
    loading,
    sortBy,
    sortOrder,
    userList,
    filterObject,
    filterLoading,
    channelList,
    groupList,
    confirmCancel,
    dashboardUsers,
    selectedDashboardUser,
  } = state;

  const history = useHistory();

  const columns = [
    {
      title: 'BID',
      dataIndex: 'broadcast.id',
      key: 'notification_data_id',
      sorter: notificationList ? notificationList.length : 0,
      width: 70,
    },
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      sorter: notificationList ? notificationList.length : 0,
      width: 60,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: notificationList ? notificationList.length : 0,
      width: 170,
    },
    {
      title: 'Text',
      dataIndex: 'text',
      key: 'text',
      sorter: notificationList ? notificationList.length : 0,
      width: 170,
    },
    {
      title: 'Type',
      dataIndex: 'notification_type',
      key: 'notification_type',
      multiple: true,
      width: 100,
      filter: true,
      filterOptions: notificationTypes,
      onFilterSelect: (value, options) => {
        dispatch({ type: 'SET_FILTER_LOADING', value: true });
        dispatch({ type: 'SET_SELECTED_TYPE', value, options });
      },
      selectedOption: notificationType,
    },
    {
      title: 'Users',
      dataIndex: 'broadcast',
      key: 'broadcast',
      filter: true,
      filterOptions: userList,
      render: (value) => {
        if (value.user_list_type) {
          const list = userList.filter((val) => val.key === value.user_list_type.id);
          return <span>{list && list[0] ? list[0].text : ''}</span>;
        }
        if (value.is_all) return <span>all | { value?.package?.name?.split('.').at(-1).substring(0,10) } </span>;
        if (value.user_type) return <span>channel follower</span>;
      },
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_USER', value, option }),
      width: 110,
      selectedOption: selectedUser,
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      filter: true,
      filterOptions: channelList,
      render: (value) => (value ? <span>{value.name}</span> : <span></span>),
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_CHANNEL', value, option }),
      width: 100,
      selectedOption: selectedChannel,
    },
    {
      title: 'Group',
      dataIndex: 'group',
      key: 'group',
      filter: true,
      filterOptions: groupList,
      render: (value) => (value ? <span>{value.name}</span> : <span></span>),
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_GROUP', value, option }),
      width: 100,
      selectedOption: selectedGroup,
    },
    {
      title: 'Send By',
      dataIndex: 'broadcast.send_by',
      key: 'send_by',
      filter: true,
      filterOptions: dashboardUsers,
      render: (value) => (value ? <span>{value.name}</span> : <span></span>),
      onFilterSelect: (value, option) =>
        dispatch({ type: 'SET_SELECTED_DASHBOARD_USER', value, option }),
      width: 100,
      selectedOption: selectedDashboardUser,
    },
    {
      title: 'Report',
      dataIndex: 'report',
      key: 'report',
      width: 130,
      render: (value) => {
        let opens = '0.00';
        if (value.sent_status.success_count !== 0)
          opens = ((value.sent_status.open_count / value.sent_status.success_count) * 100).toFixed(
            2,
          );
        return (
          <div>
            <Flex>
              <Label>Total:</Label>
              <Status color="orange">{value.sent_status.total}</Status>
            </Flex>
            <Flex>
              <Label>Success:</Label>
              <Status color="green">{value.sent_status.success_count}</Status>
            </Flex>
            <Flex>
              <Label>Failure:</Label>
              <Status color="red">{value.sent_status.fail_count}</Status>
            </Flex>
            <Flex>
              <Label>Opens:</Label>
              <Status color="yellow">{`${opens}%`}</Status>
            </Flex>
          </div>
        );
      },
    },
    {
      title: 'Time and Date',
      dataIndex: 'modified_at',
      key: 'modified_at',
      sorter: notificationList ? notificationList.length : 0,
      width: 100,
      render: (value) => <span>{Moment(value).format('MMM DD,YYYY hh:mm A')}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'broadcast.status',
      key: 'status',
      filter: true,
      filterOptions: statusArray,
      width: 100,
      onFilterSelect: (value, option) => dispatch({ type: 'SET_SELECTED_STATUS', value, option }),
      render: (value) => <span>{value}</span>,
      selectedOption: selectedStatus,
    },
    {
      title: 'Schedule Time and Date',
      dataIndex: 'broadcast.schedule_datetime',
      key: 'schedule_datetime',
      sorter: notificationList ? notificationList.length : 0,
      width: 110,
      render: (value) => <span>{value ? Moment(value).format('MMM DD,YYYY hh:mm A') : ''}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: 80,
      fixed: 'right',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (value, data) => (
        <div className="action-wrapper">
          <ActionWrapper>
            <IconWrapper
              onClick={() => {
                history.push({
                  pathname: '/notification/create',
                  state: { data },
                });
              }}
            >
              <div title="Copy">
                <Icon name="copy" size="large" />
              </div>
            </IconWrapper>
            {data.broadcast.status === 'SCHEDULED' && (
              <>
                <IconWrapper
                  onClick={() => {
                    cancelNotificationConfirm(data.broadcast.id);
                  }}
                >
                  <div title="Cancel">
                    <Icon name="cancel" size="large" />
                  </div>
                </IconWrapper>
              </>
            )}
          </ActionWrapper>
        </div>
      ),
    },
  ];

  const handleFetchUsers = useCallback(async () => {
    try {
      const response = await fetchUserListFull();
      const groups = response.data.results.map((val) => ({
        key: val.id,
        value: val.id,
        text: val.title,
      }));
      groups.unshift({
        key: 'channel_follower',
        value: 'channel_follower',
        text: 'Channel Followers Only',
      });
      groups.unshift({ key: 'all', value: 'all', text: 'All' });
      dispatch({
        type: 'SET_USER_LIST',
        value: groups,
      });
    } catch {
      ToastsStore.error('Unable to fetch notifications.');
    }
  }, []);

  const fetchDashbaordUsers = () => {
    fetchUsers(1, 50)
      .then((response) => {
        const users = response.data.results.map((val) => ({
          key: val.id,
          value: val.id,
          text: val.name,
        }));
        dispatch({
          type: 'SET_DASHBOARD_USER_LIST',
          value: users,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    handleFetchUsers();
    fetchDashbaordUsers();
  }, [handleFetchUsers]);

  useEffect(() => {
    if (selectedStatus) dispatch({ type: 'SET_SELECTED_STATUS', value: selectedStatus });
  }, [filterObject.selectedStatus, selectedStatus]);

  const fetchChannels = useCallback(async (type) => {
    if (type === 'channel') {
      try {
        const response = await fetchChannelsApi('channel');
        const groups = response.data.results.map((val) => ({
          key: val.id,
          value: val.id,
          text: val.name,
        }));
        dispatch({
          type: 'SET_CHANNEL_LIST',
          value: groups,
        });
      } catch (error) {
        ToastsStore.error('Unable to fetch channel list.');
      }
    } else {
      try {
        const response = await fetchChannelsApi('group');
        const groups = response.data.results.map((val) => ({
          key: val.id,
          value: val.id,
          text: val.name,
        }));
        dispatch({
          type: 'SET_GROUP_LIST',
          value: groups,
        });
      } catch (error) {
        ToastsStore.error('Unable to fetch channel list.');
      }
    }
  }, []);

  useEffect(() => {
    fetchChannels('channel');
    fetchChannels('groups');
  }, [fetchChannels]);

  useEffect(() => {
    let searchStr = '';
    if (selectedUser) searchStr = `?selectedUser=${selectedUser}`;
    if (selectedChannel)
      if (!searchStr) searchStr = `?selectedChannel=${selectedChannel}`;
      else searchStr += `&selectedChannel=${selectedChannel}`;
    if (selectedGroup)
      if (!searchStr) searchStr = `?selectedGroup=${selectedGroup}`;
      else searchStr += `&selectedGroup=${selectedGroup}`;
    if (selectedDashboardUser)
      if (!searchStr) searchStr = `?selectedDashboardUser=${selectedDashboardUser}`;
      else searchStr += `&selectedDashboardUser=${selectedDashboardUser}`;
    if (selectedStatus)
      if (!searchStr) searchStr = `?selectedStatus=${selectedStatus}`;
      else searchStr += `&selectedStatus=${selectedStatus}`;
    if (notificationType.length)
      if (!searchStr) searchStr = `?notificationType=${notificationType}`;
      else searchStr += `&notificationType=${notificationType}`;

    history.push({
      pathname: '/notifications',
      search: queryString.stringify({
        ...queryString.parse(search),
        ...queryString.parse(searchStr),
      }),
    });
  }, [
    history,
    notificationType,
    selectedUser,
    selectedChannel,
    selectedGroup,
    selectedStatus,
    selectedDashboardUser,
    search,
  ]);

  const fetchData = useCallback(async () => {
    dispatch({ type: 'SET_LOADING', value: true });
    let sort = sortBy;
    if (sort === 'broadcast.id') sort = 'notification_data_id';
    else if (sort === 'title') sort = 'notification_data_title';
    else if (sort === 'modified_at') sort = 'modified_at';
    else if (sort === 'broadcast.schedule_datetime') sort = 'schedule_datetime';
    const paramObj = {
      notificationType: notificationType.join(','),
      users: selectedUser,
      channel: selectedChannel,
      group: selectedGroup,
      page: currentPage,
      status: selectedStatus,
      sortBy: sort,
      sortOrder,
      sendBy: selectedDashboardUser,
    };

    if (filterDateValues.fromDate) {
      paramObj.startDate = moment(filterDateValues.fromDate).format('DD-MM-YYYY HH:mm:ss');
    }
    if (filterDateValues.toDate) {
      paramObj.endDate = moment(filterDateValues.toDate).format('DD-MM-YYYY HH:mm:ss');
    }
    const response = await fetchNotificationHistory(paramObj);

    dispatch({
      type: 'SET_NOTIFICATION_LIST',
      value: response.data.results,
      count: response.data.count,
    });
  }, [
    notificationType,
    currentPage,
    sortBy,
    sortOrder,
    selectedUser,
    selectedChannel,
    selectedGroup,
    selectedStatus,
    selectedDashboardUser,
    filterDateValues,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSelectFilter = (key, value) => {
    dispatch({ type: 'SET_FILTER_LOADING', value: true });
    const tempFilter = filterObject.notificationType
      ? JSON.parse(JSON.stringify(filterObject.notificationType))
      : [];
    const category = JSON.parse(JSON.stringify(notificationType)) || [];
    if (key === 'selectedUser') {
      dispatch({ type: 'SET_SELECTED_USER', value: '' });
    } else if (key === 'selectedChannel') {
      dispatch({ type: 'SET_SELECTED_CHANNEL', value: '' });
    } else if (key === 'selectedGroup') {
      dispatch({ type: 'SET_SELECTED_GROUP', value: '' });
    } else if (key === 'selectedDashboardUser') {
      dispatch({ type: 'SET_SELECTED_DASHBOARD_USER', value: '' });
    } else if (key === 'selectedStatus') {
      dispatch({ type: 'SET_SELECTED_STATUS', value: '' });
    } else if (key === 'notificationType') {
      const index = tempFilter.findIndex((val) => val.value === value.value);
      tempFilter.splice(index, 1);
      const index1 = notificationType.findIndex((val) => val === value.value);
      category.splice(index1, 1);
      if (!tempFilter.length) delete tempFilter[key];
      dispatch({ type: 'SET_SELECTED_TYPE', value: category, options: tempFilter });
    }
  };

  const cancelNotificationConfirm = (value) => {
    dispatch({ type: 'CONFIRM_CANCEL', value });
  };

  const cancelNotification = async () => {
    cancelNotificationConfirm('');
    cancelNotificationApi(confirmCancel)
      .then(() => {
        ToastsStore.success('Notication cancelled');
        fetchData();
      })
      .catch(() => {
        ToastsStore.error('Unable to cancel notification');
      });
  };

  // const generateTokenModal = () => {
  //   dispatch({ type: 'SET_TOKEN' });
  // };

  // const setToken = (value) => {
  //   ToastsStore.success('FCM Token copied');
  //   dispatch({ type: 'SET_VALUE', value: { ...data, token: value } });
  // };

  // const fetchSource = (index) => window.URL.createObjectURL(data.notification_data[index].image);

  return (
    <Layout location={location}>
      <Helmet>
        <title>XND | Notifications</title>
      </Helmet>
      <MainLayout>
        <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
        <Flex>
          <SectionTitle>Notification History</SectionTitle>
          <Flex>
            <AddUser
              icon="filter"
              content="Filter by Date"
              positive
              labelPosition="right"
              onClick={() => setShowDateFilter(!showDateFilter)}
            />

            <AddUser
              icon="add user"
              content="Create Notification"
              positive
              labelPosition="right"
              onClick={() => history.push('/notification/create')}
            />
            <AddUser
              icon="eye"
              content="View Groups"
              positive
              labelPosition="right"
              onClick={() => history.push('/notifications/groups')}
            />
          </Flex>
        </Flex>
        {showDateFilter ? (
          <DateFilter
            setFilterDateValues={setFilterDateValues}
            filterDateValues={filterDateValues}
            handleClose={() => setShowDateFilter(false)}
          />
        ) : null}
        {loading || filterLoading ? (
          <LoaderWrapper>
            <Loader active={loading} />
          </LoaderWrapper>
        ) : (
          <TableWrapper>
            {Object.keys(filterObject).length > 0 && (
              <TableHeader>
                <FilterPills filterObject={filterObject} onSelectFilter={onSelectFilter} />
              </TableHeader>
            )}

            <TableView
              tableColumns={columns}
              data={notificationList}
              pagination
              pageSize={10}
              totalItems={totalItems}
              activePage={currentPage}
              onPageChange={(value) => dispatch({ type: 'SET_CURRENT_PAGE', value })}
              toggleSortOrder={(sort) => dispatch({ type: 'SET_SORT', sortBy: sort })}
              sortBy={sortBy}
              sortOrder={sortOrder === 'asc' ? 'ascend' : 'descend'}
            />
          </TableWrapper>
        )}
        {confirmCancel && (
          <Modal size="tiny" open={confirmCancel} onClose={() => cancelNotificationConfirm('')}>
            <Modal.Content>
              <div>Are you sure you want to cancel the notification?</div>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => cancelNotificationConfirm('')}> No </Button>
              <ModalSaveButton onClick={cancelNotification}>Yes</ModalSaveButton>
            </Modal.Actions>
          </Modal>
        )}
      </MainLayout>
    </Layout>
  );
};

Notifications.propTypes = {
  location: PropTypes.object,
};

export default Notifications;
