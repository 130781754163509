import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Wrapper = styled.div`
  position: relative;
`;

const SliderWrapper = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 100%;

  @media (max-width: 768px) {
    overflow: auto;
    width: 95%;
    height: 38px;
    margin: 0 auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-track-piece {
      width: 0px;
      height: 0px;
      background: transparent; /* make scrollbar transparent */
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
`;

const Count = styled.div`
  background: ${({ active }) => (active ? '#0152cc' : '#e1ebf7')};
  color: ${({ active }) => (active ? '#fff' : '#rgba(0,0,0,0.50)')};
  padding: 2px 6px;
  margin-left: 5px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap !important;
  transform: ${({ translatex }) => `translateX(${translatex}px)`};
  transition: transform 0.2s;
`;

const SliderItem = styled.div`
  display: block;
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 14px;
  display: flex;
  align-items: flex-end;
  padding: ${({ active }) => (active ? '7px 20px' : '7px 20px')};
  font-size: '14px';
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  letter-spacing: 1.2px;

  color: ${({ active }) => (active ? 'rgba(0,0,0,0.80)' : 'rgba(0,0,0,0.50)')};
  border-bottom: ${({ active }) => (active ? '2px solid #0152cc' : 'none')};
  &:hover {
    color: #333;
  }

  @media (max-width: 768px) {
    padding: 10px 10px 10px;
    font-size: 14px;
    line-height: 18px;
  }
`;
const PrevIcon = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.6' : '.8')};
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  &:hover {
    opacity: ${({ disabled }) => (disabled ? '.6' : '1')};
  }

  @media (max-width: 768px) {
    left: -5px;
  }
`;
const NextIcon = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  opacity: ${({ disabled }) => (disabled ? '.6' : '.9')};
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  &:hover {
    opacity: ${({ disabled }) => (disabled ? '.6' : '1')};
  }
  @media (max-width: 768px) {
    right: -5px;
  }
`;
const Slider = ({ sliderData, selectedStatus, onClickItem, total }) => {
  const sliderWrapperRef = useRef(null);
  const sliderRef = useRef(null);
  const [translatex, setTranslatex] = useState(0);
  const [tocuhStart, setTouchStart] = useState(null);
  const [addEvnetListener, setAddEvnetListener] = useState(false);
  const [isSetSelectedCategory, setIsSetSelectedCatgegory] = useState(false);
  const onClickNextPrev = (type) => {
    if (sliderRef.current && sliderWrapperRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
      if (window.innerWidth > 768) {
        if (type === 'next') {
          if (sliderWidth > sliderWrapperWidth) {
            if (sliderWrapperWidth + -translatex < sliderWidth) {
              let translatexValue = sliderWidth - (sliderWrapperWidth + -translatex);
              translatexValue = -translatex + sliderWrapperWidth;
              setTranslatex(-translatexValue);
            }
          }
        } else if (type === 'prev') {
          if (translatex < 0) {
            let translatexValue = translatex + sliderWrapperWidth;

            if (translatexValue > 0) {
              translatexValue = 0;
            }

            setTranslatex(translatexValue);
          }
        }
      } else {
        const leftScroll = sliderWrapperRef.current.scrollLeft;
        if (type === 'next') {
          sliderWrapperRef.current.scroll(leftScroll + 200, 0);
        } else if (type === 'prev') {
          sliderWrapperRef.current.scroll(leftScroll - 200, 0);
        }
      }
    }
  };

  const onTouchStart = (event) => {
    setTouchStart(event.touches[0].clientX);
  };

  const onTouchEnd = (event) => {
    setTouchStart(null);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onTouchMove = (event) => {
    if (tocuhStart && sliderRef.current && sliderWrapperRef.current) {
      const valueX = event.touches[0].clientX;
      const xDiff = valueX - tocuhStart;
      let translateValue = translatex;
      if (xDiff > 0 && Math.abs(xDiff) > 5) {
        translateValue = translatex + 10;
        if (translateValue > 0) {
          translateValue = 0;
        }
      } else if (xDiff < 0 && Math.abs(xDiff) > 5) {
        translateValue = translatex - 10;
        const sliderWidth = sliderRef.current.offsetWidth;
        const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
        if (-translateValue > sliderWidth - sliderWrapperWidth) {
          translateValue = -(sliderWidth - sliderWrapperWidth);
        }
      }
      setTranslatex(translateValue);
    }
  };

  useEffect(() => {
    if (addEvnetListener) {
      if (sliderRef.current) {
        sliderRef.current.addEventListener('touchmove', onTouchMove);
        sliderRef.current.addEventListener('touchstart', onTouchStart);
        sliderRef.current.addEventListener('touchstart', onTouchEnd);
      }
    }

    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('touchmove', onTouchMove);
        sliderRef.current.removeEventListener('touchstart', onTouchStart);
        sliderRef.current.removeEventListener('touchstart', onTouchEnd);
      }
    };
  }, [addEvnetListener, onTouchMove]);

  const checkNextButtonDisable = () => {
    if (sliderRef.current && sliderWrapperRef.current) {
      const sliderWidth = sliderRef.current.offsetWidth;
      const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
      if (Math.abs(translatex) >= sliderWidth - sliderWrapperWidth) {
        return true;
      }

      return false;
    }
  };

  useEffect(() => {
    if (selectedStatus) {
      setIsSetSelectedCatgegory(false);
    }
  }, [selectedStatus]);

  const showSelectedCategory = () => {
    let selectedItem = null;
    sliderData.forEach((item) => {
      if (selectedStatus === item.slug) {
        selectedItem = item;
      }
    });

    if (selectedItem && sliderRef.current && sliderWrapperRef.current) {
      const selectedElem = document.getElementById(`${selectedItem.id}-status`);
      setIsSetSelectedCatgegory(true);
      const selectedItemPositionRight = selectedElem.offsetLeft + selectedElem.offsetWidth;
      const sliderWrapperWidth = sliderWrapperRef.current.offsetWidth;
      if (selectedItemPositionRight > sliderWrapperWidth) {
        const scrollValue = selectedItemPositionRight - sliderWrapperWidth;
        if (window.innerWidth > 768) {
          setTranslatex(-scrollValue);
        } else {
          sliderWrapperRef.current.scroll(scrollValue, 0);
        }
      }
    } else {
      setIsSetSelectedCatgegory(true);
    }
  };
  return (
    <>
      {sliderData && sliderData.length > 0 && (
        <Wrapper>
          <PrevIcon disabled={translatex === 0} onClick={() => onClickNextPrev('prev')}>
            {/* <Image src={arrowLeft} /> */}
            <Icon name="angle left" />
          </PrevIcon>
          <NextIcon disabled={checkNextButtonDisable()} onClick={() => onClickNextPrev('next')}>
            {/* <Image src={arrowRight} /> */}
            <Icon name="angle right" />
          </NextIcon>
          <SliderWrapper ref={sliderWrapperRef}>
            <Container
              ref={(a) => {
                if (!a) return;
                const categoryList = a.querySelectorAll('.category-item');
                let totalWidth = 0;
                categoryList.forEach((categoryItem) => {
                  totalWidth += categoryItem.offsetWidth;
                });
                a.style.width = `${totalWidth}px`;
                sliderRef.current = a;
                setAddEvnetListener(true);
                if (selectedStatus && !isSetSelectedCategory) {
                  showSelectedCategory();
                }
              }}
              translatex={translatex}
            >
              <SliderItem
                onClick={() => onClickItem('')}
                className="category-item"
                key="all"
                id="all-status"
                active={!selectedStatus}
              >
                <div>All</div>
                {total ? <Count active={!selectedStatus}>{total}</Count> : <></>}
              </SliderItem>
              {sliderData.map((item) => (
                <SliderItem
                  onClick={() => onClickItem(item)}
                  className="category-item"
                  key={item.id}
                  id={`${item.id}-status`}
                  active={selectedStatus === item.slug || undefined}
                >
                  <div>{item.status_name}</div>
                  {item.count ? <Count active={selectedStatus === item.slug || undefined}>{item.count}</Count>:<></>}
                </SliderItem>
              ))}
            </Container>
          </SliderWrapper>
        </Wrapper>
      )}
    </>
  );
};

export default Slider;
