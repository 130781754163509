import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import TableView from '../../Components/TableView';

const ScoreTable = styled.div`
  .rc-table,
  .rc-table-content {
    position: inherit;
  }
`;

const QualityTable = ({ data }) => {
  const columns = [
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      render: (value) => (
        <div style={{ display: 'block' }}>{moment(value).format('MMM DD, YYYY')}</div>
      ),
      align: 'center',
      width: 100,
      // fixed: 'left',
    },
    {
      title: 'Number of digests',
      dataIndex: 'total_digests',
      key: 'total_digests',
      render: (value) => value || 0,
      align: 'center',
      width: 100,
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (value) => value || 0,
      align: 'center',
      width: 100,
    },
    {
      // title: 'Language / Grammar',
      title: 'Language / Grammar /// Apt Title',
      dataIndex: 'content_quality',
      key: 'content_quality',
      render: (value) => value || 0,
      align: 'center',
      width: 100,
    },
    {
      // title: 'Accuracy/ Length/ Research',
      title: 'Accuracy / Research /// Clip Selection / Flow',
      dataIndex: 'content_accuracy',
      key: 'content_accuracy',
      render: (value) => value || 0,
      align: 'center',
      width: 150,
    },
    {
      // title: 'Video, Category, Tags',
      title: 'Video, Category, Tags /// Category, Tags',
      dataIndex: 'content_operational',
      key: 'content_operational',
      render: (value) => value || 0,
      align: 'center',
      width: 150,
    },
    {
      // title: 'Plagiarism',
      title: 'Plagiarism /// Segment Cuts',
      dataIndex: 'plagiarism_score',
      key: 'plagiarism_score',
      render: (value) => value || 0,
      align: 'center',
      width: 100,
    },
    {
      // title: 'Length / Brevity',
      title: 'Length / Brevity ///  Length / Brevity',
      dataIndex: 'content_length',
      key: 'content_length',
      render: (value) => value || 0,
      align: 'center',
      width: 100,
    },
    {
      title: 'Segment Total Length',
      dataIndex: 'segment_length',
      key: 'segment_length',
      render: (value) => value || 0,
      align: 'center',
      width: 150,
    },
    {
      title: 'Source Video Length',
      dataIndex: 'video_timestamp_length',
      key: 'video_timestamp_length',
      render: (value) => value || 0,
      align: 'center',
      width: 150,
    },
  ];
  return (
    <ScoreTable>
      <TableView tableColumns={columns} data={data} scroll={{ x: 500, y: false }} />
    </ScoreTable>
  );
};

QualityTable.propTypes = {
  data: PropTypes.object,
};

export default QualityTable;
