/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useReducer, useRef, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import produce from 'immer';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import {
  Button,
  Select,
  Modal,
  Dropdown,
  Loader,
  Input,
  Image,
  Icon,
  Checkbox,
  Message,
  Progress,
} from 'semantic-ui-react';
import moment from 'moment';
import Chips from 'react-chips';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Layout from '../../Components/Layout';
import {
  fetchDigests,
  fetchCategoriesData,
  fetchTagsData,
  fetchSources,
  fetchArticlesFormat,
  sendNotificationApi,
  fetchDigestEditData,
  uploadNotificationImage,
  fetchProgress,
  fetchUserListFull,
  fetchPills,
  fetchChannelsApi,
  fetchChannelByGroup,
  fetchCityList,
  fetchPackages,
} from '../../Api';
import UploadImageComponent from './UploadImage';
import { notificationTypes } from '../Notifications/constants';

const SectionTitle = styled.div`
  font-size: 2.125rem;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1.25em;
`;

const LoaderWrapper = styled.div`
  position: relative;
  height: 300px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  min-width: 6%;
  margin-top: ${({ mt }) => mt || 0};
`;

const AddUser = styled(Button)`
  cursor: pointer;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FlexFields = styled.div`
  display: flex;
  margin: 30px 20px;
  align-items: center;

  > div {
    color: ${({ error }) => (error ? '#FF5722' : 'rgba(0,0,0,.87)')} !important;
  }
  input,
  .ui.selection.dropdown {
    border: ${({ error }) =>
      error ? '1px solid #FF5722' : '1px solid rgba(34,36,38,.15)'} !important;
  }
  .no-border {
    border: none !important;
  }
  .ui.selection.dropdown .menu > .item {
  }
`;

const FieldErrorMessage = styled.span`
  font-size: 12px;
  line-height: 12px;
  margin-top: 5px;
  color: #cc0000;
  font-weight: 300;
`;

const SelectCustom = styled(Select)`
  width: 100%;
`;

const Container = styled.div`
  display: grid;
  width: 100%;
`;

const SubHeading = styled.div`
  text-decoration: underline;
  font-size: 1.25rem;
  font-weight: 700;
  margin-top: 40px;
`;

const MainLayout = styled.div`
  padding: 20px;
`;

const Label = styled.div`
  margin: ${({ m }) => m || 0};
  margin-right: 30px;
  color: ${({ error }) => (error ? '#FF5722' : 'rgba(0,0,0,.87)')} !important;
  width: ${({ w }) => w || '20%'};
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${({ isYtVideo }) => (isYtVideo ? 'auto' : '287px')};
  overflow: hidden;
  width: 60%;
  img {
    width: 100%;
  }
`;

const CopyIconImage = styled.div`
  padding: 8px 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px #000;
  position: absolute;
  right: 10px;
  bottom: ${({ b }) => b || '50px'};
  background-color: #fff;
  cursor: pointer;

  i {
    margin-right: 0;
    color: #000;
  }
`;

const ImageContainer = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  height: calc(100% - 37px);
  margin-top: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const DateTimeWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-top: ${({ mt }) => mt || 0};
  .rdt {
    width: ${({ w }) => w || 'auto'};
    height: 100%;
    input {
      width: 100%;
      height: 100%;
      font-family: Metropolis, sans-serif !important;
      text-transform: uppercase;
      border: 1px solid rgba(34, 36, 38, 0.15) !important;
      color: rgba(0, 0, 0, 0.87) !important;
      border-radius: 0.28571429rem;
      outline: none !important;
      padding: 10px !important;
    }
  }

  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const ChipsCustom = styled.div`
  width: 100%;

  input {
    border: none !important;
  }
`;

const ChipWrapper = styled.div`
  position: relative;
  background-color: rgb(233, 233, 233);
  color: rgb(128, 128, 128);
  font-size: 14px;
  font-weight: 500;
  padding: 5px 30px 5px 10px;
  margin: 0 10px 0 0px;
  border-radius: 4px;
  font-family: Metropolis, sans-serif;
`;

const ChipCloseIcon = styled.div`
  background-color: #fff;
  padding: 1px 2px;
  position: absolute;
  top: 4px;
  right: 2px;
  border-radius: 50%;
  cursor: pointer;
  i {
    color: rgb(128, 128, 128);
    margin: 0;
  }
`;

const FlexArray = styled.div``;

const initialValue = {
  loading: -1,
  data: {
    notification_datas: [{ object_id: '' }],
    title: 'Alert',
    tag_followed: false,
    read: { tags: [], categories: [], keywords: [] },
    bottom_tab_index: '1',
    cities: [],
    states: [],
  },
  errors: {},
  disableTagFollowed: false,
  addNotifVisible: true,
  progress: { percentage: 0, total: 0, success_count: 0, fail_count: 0 },
  pageLoading: true,
  channelList: [],
  userList: [],
  packageList: [],
  groupList: [],
  articles: [],
  confirm: false,
  channels: [],
  tags: [],
  filterTags: [],
  cities: [],
  states: [],
};

const dynamicFields = [
  {
    key: 1,
    value: '1',
    text: 'Briefing',
  },
  // {
  //   key: 0,
  //   value: '0',
  //   text: 'Discover',
  // },
  {
    key: 2,
    value: '2',
    text: 'DailyBrief TV',
  },
  {
    key: 3,
    value: '3',
    text: 'Notification',
  },
];

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'CONFIRM_MODAL': {
        draft.confirm = !draft.confirm;
        draft.selectedType = action.value;
        if (action.submit) draft.submit = action.submit;
        else draft.submit = '';
        break;
      }
      case 'SET_TOKEN':
        draft.tokenModal = !draft.tokenModal;
        break;
      case 'SET_ATTENDEES':
        draft.attendees = action.value;
        break;
      case 'SET_PUBLICATION':
        draft.publication = action.value;
        break;
      case 'SET_FORMATTED_ARTICLE':
        draft.formattedArticle = action.value;
        break;
      case 'SET_ARTICLES':
        draft.articles[action.index] = action.values;
        draft.loading = -1;
        break;
      case 'SET_ALL_ARTICLES':
        draft.allArticles = action.values;
        break;
      case 'SET_CATEGORIES':
        draft.categories = action.value;
        draft.loading = -1;
        break;
      case 'SET_TAGS':
        draft.tags[action.index] = action.value;
        break;
      case 'SET_ALL_TAGS':
        draft.tagList = action.value;
        if (!draft.tags[0] || !draft.tags[0].length) draft.tags[0] = action.value;
        draft.loading = -1;
        break;
      case 'SET_PILLS':
        draft.pills = action.value;
        draft.loading = -1;
        break;
      case 'SET_HOSTS':
        draft.hosts = action.value;
        draft.loading = -1;
        break;
      case 'SET_FORMATTED_ARTICLES':
        draft.formattedArticles = action.value;
        draft.loading = -1;
        break;
      case 'SET_LOADING':
        draft.loading = action.value;
        break;
      case 'SET_PAGE_LOADING':
        draft.pageLoading = action.value;
        break;
      case 'SET_TYPE':
        draft.testing = action.valType;
        break;
      case 'SET_ARTICLE':
        draft.selectedArticle = action.value;
        break;
      case 'SET_CATEGORY':
        draft.category = action.value;
        break;
      case 'SET_TOKEN_VALUE':
        draft.token = action.value;
        break;
      case 'UPLOAD_IMAGE_STATUS':
        draft.uploadImage = action.status;
        break;
      case 'SET_VALUE': {
        if (action.index !== undefined) {
          const listData = draft.data.notification_datas[action.index];
          if (action.apiData) {
            listData.object_id = action.value;
            listData.title = action.title;
            listData.text = action.text;
            listData.imageUrl = action.imageUrl;
            listData.channel = action.channel;
            listData.group = action.group;
            draft.loading = -1;
          } else if (!action.subKey) listData[action.key] = action.value;
          else {
            listData.object_id = action.value;
          }
          draft.data.notification_datas[action.index] = { ...listData };
          // draft.scheduledTime = draft.data.schedule_datetime;
        } else {
          draft.data = action.value;
          // draft.filterTags = [];
          // draft.cities = [];
        }
        break;
      }
      // case 'RESET_FORM':
      //   draft.data = { notification_datas: [{ object_id: '' }], tag_followed: false };
      //   break;
      case 'SET_ERRORS':
        draft.errors = action.value;
        break;
      case 'ADD_LIST':
        draft.data.tag_followed = false;
        draft.disableTagFollowed = true;
        if (draft.data.notification_type === 'Articles')
          draft.articles.push([...draft.allArticles]);
        draft.channelList.push([]);
        if (draft.data.notification_type === 'Tags') draft.tags.push([...draft.tagList]);
        draft.data.notification_datas.push({
          object_id: '',
          text: '',
          subtext: '',
          imageUrl: '',
          title: 'Alert',
          channel: '',
          group: '',
          // notif_id: '',
          notification_type: draft.data.notification_type,
        });
        break;
      case 'REMOVE_NOTIFICATION':
        draft.data.notification_datas.splice(action.index, 1);
        if (draft.data.notification_datas.length === 1) draft.disableTagFollowed = false;
        break;
      case 'HIDE_ADD_NOTIF':
        draft.addNotifVisible = action.value;
        break;
      case 'SHOW_REMOVE_NOTIF':
        draft.showRemoveNotif = !draft.showRemoveNotif;
        break;
      case 'REMOVE_NOTIFS':
        draft.data.notification_datas.length = 1;
        draft.addNotifVisible = false;
        draft.data.user_list_type = 'registration_ids';
        draft.bottom_tab_index = 1;
        draft.showRemoveNotif = !draft.showRemoveNotif;
        break;
      case 'SET_USER_LIST':
        draft.userList = action.value;
        break;
      case 'SET_PACKAGE_LIST':
        draft.packageList = action.value;

        draft.data.app_package_name = action.value.find((el) => el.isDefualt === true).value;

        break;
      case 'SET_CHANNEL_LIST':
        draft.channelList[action.index] = action.value;
        break;
      case 'SET_GROUP_LIST':
        draft.groupList = action.value;
        break;
      case 'SHOW_PROGRESS':
        draft.showProgressModal = action.open;
        draft.currentStatus = action.value;
        draft.progress = { percentage: 0, total: 0, success_count: 0, fail_count: 0 };
        break;
      case 'SHOW_PROGRESS_STATUS':
        draft.progress = action.value;
        break;
      case 'SCHEDULE_NOTIF':
        if (!draft.scheduleNotification)
          draft.scheduledTime = action.time || moment(new Date()).add(5, 'minutes');
        else draft.scheduledTime = null;
        draft.scheduleNotification = !draft.scheduleNotification;
        break;
      case 'SET_DATE_TIME':
        draft.scheduledTime = action.value;
        break;
      case 'INITIALIZE_DATA':
        if (draft.data && draft.data.read) {
          draft.data = {
            ...action.initialData,
            read: draft.data.read,
          };
        } else {
          draft.data = {
            ...action.initialData,
            read: { tags: [], categories: [], keywords: [] },
          };
        }
        draft.data.cities = draft.data.cities || [];
        draft.data.states = draft.data.states || [];
        break;
      case 'FILTER_TAGS':
        draft.filterTags = action.chips;
        draft.data.read.tags = action.chips.map((chip) => chip.id);
        break;
      case 'FILTER_CITIES':
        draft.cities = action.chips;
        draft.data.cities = action.chips.map((chip) => chip.city);
        draft.data.states = [];
        draft.states = [];
        break;
      case 'FILTER_STATES':
        draft.states = action.chips;
        draft.data.states = action.chips.map((chip) => chip.state);
        draft.data.cities = [];
        draft.cities = [];
        break;
      case 'SET_FILTER_CATEGORIES':
        draft.data.read.categories = action.value;
        break;
      case 'SET_FILTER_DATETIME':
        draft.data.read.datetime_from = action.value;
        break;
      case 'FILTER_KEYWORDS':
        draft.data.read.keywords = action.chips;
        break;
    }
  });

const Notifications = ({ location }) => {
  // const initialData = {};

  const onSearchChange = async ({ value, index, addListValue, showEmpty }) => {
    try {
      const response = await fetchDigests(value);
      const values = response.data.results.length
        ? response.data.results.map((val) => ({
            key: val.id,
            value: val.id,
            image:
              val.cover_pic && val.cover_pic.includes('https://www.youtube.com/watch?v')
                ? `https://i3.ytimg.com/vi/${val.cover_pic.substring(
                    val.cover_pic.indexOf('=') + 1,
                    val.cover_pic.length,
                  )}/hqdefault.jpg`
                : val.custom_thumbnail,
            text: `${val.title} (${val.id})`,
          }))
        : [];
      if (
        showEmpty &&
        (!values.length || !(values.length && values.some((article) => article.key === showEmpty)))
      ) {
        ToastsStore.error('Article does not exist.');
      } else {
        dispatch({ type: 'SET_ARTICLES', values, index: index || 0 });
        if (!value) dispatch({ type: 'SET_ALL_ARTICLES', values });

        if (addListValue) dispatch({ type: 'ADD_LIST' });
      }
    } catch {
      console.error('Unable to fetch articles');
    }
  };

  const generateObject = useCallback(async ({ value, content, text, showEmpty }) => {
    if (value === 'Articles') {
      onSearchChange({
        value: text || '',
        showEmpty,
      });
    } else if (value === 'Categories') {
      try {
        const categoriesRes = await fetchCategoriesData();
        const categoryVal = categoriesRes.data.results.map((category) => ({
          id: category.id,
          key: category.id,
          value: category.id,
          text: category.title,
        }));
        if (
          showEmpty &&
          (!categoryVal.length ||
            !(categoryVal.length && categoryVal.some((category) => category.id === showEmpty)))
        ) {
          ToastsStore.error('Category does not exist.');
        } else dispatch({ type: 'SET_CATEGORIES', value: categoryVal });
      } catch {
        ToastsStore.error('Unable to fetch categories.');
      }
    } else if (value === 'Tags') {
      try {
        const tagsRes = await fetchTagsData({
          searchString: content ? content.title.split(' ')[0].toLowerCase() : '',
        });
        const tagValues = tagsRes.data.results.map((tag) => ({
          id: tag.id,
          key: tag.id,
          value: tag.id,
          text: tag.title,
        }));
        if (
          showEmpty &&
          (!tagValues.length ||
            !(tagValues.length && tagValues.some((tag) => tag.id === showEmpty)))
        ) {
          ToastsStore.error('Tag does not exist.');
        } else if (!content) dispatch({ type: 'SET_ALL_TAGS', value: tagValues });
        else dispatch({ type: 'SET_TAGS', value: tagValues, index: 0 });
      } catch (error) {
        ToastsStore.error('Unable to fetch tags.');
      }
    } else if (value === 'Publications') {
      try {
        const hostRes = await fetchSources();
        const hostValues = hostRes.data.results.map((host) => ({
          id: host.id,
          key: host.id,
          value: host.id,
          text: host.brand_name,
        }));
        if (
          showEmpty &&
          (!hostValues.length ||
            !(hostValues.length && hostValues.some((host) => host.id === showEmpty)))
        ) {
          ToastsStore.error('Host does not exist.');
        } else dispatch({ type: 'SET_HOSTS', value: hostValues });
      } catch {
        ToastsStore.error('Unable to fetch publications.');
      }
    } else if (value === 'Pills') {
      try {
        const pillRes = await fetchPills({ searchString: '' });
        const pillValues = pillRes.data.results.map((pill) => ({
          id: pill.id,
          key: pill.id,
          value: pill.id,
          text:
            pill.content_object.title ||
            pill.content_object.brand_name ||
            pill.content_object.section_name,
        }));
        if (
          showEmpty &&
          (!pillValues.length ||
            !(pillValues.length && pillValues.some((pill) => pill.id === showEmpty)))
        ) {
          ToastsStore.error('Pill does not exist.');
        } else dispatch({ type: 'SET_PILLS', value: pillValues });
      } catch {
        ToastsStore.error('Unable to fetch pills.');
      }
    } else {
      try {
        const articleList = await fetchArticlesFormat();
        const articleValues = articleList.data.results.map((article) => ({
          id: article.id,
          key: article.id,
          value: article.id,
          text: article.section_name,
        }));
        if (
          showEmpty &&
          (!articleValues.length ||
            !(articleValues.length && articleValues.some((article) => article.id === showEmpty)))
        ) {
          ToastsStore.error('Article does not exist.');
        } else dispatch({ type: 'SET_FORMATTED_ARTICLES', value: articleValues });
      } catch {
        ToastsStore.error('Unable to fetch articles.');
      }
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, initialValue);
  const modalInputRef = useRef(null);
  const {
    showProgressModal,
    articles,
    loading,
    categories,
    tags,
    pills,
    hosts,
    formattedArticles,
    uploadImage,
    data,
    errors,
    disableTagFollowed,
    addNotifVisible,
    showRemoveNotif,
    userList,
    packageList,
    progress,
    currentStatus,
    pageLoading,
    channelList,
    groupList,
    confirm,
    submit,
    testing,
    scheduleNotification,
    scheduledTime,
    allArticles,
    filterTags,
    selectedType,
    cities,
    states,
  } = state;

  const fetchChannelById = async (id, idx) => {
    try {
      const response = await fetchChannelByGroup(id);
      const groups = response.data.channel_list.map((val) => ({
        key: val.id,
        value: val.id,
        text: val.name,
      }));
      dispatch({
        type: 'SET_CHANNEL_LIST',
        value: groups,
        index: idx,
      });
    } catch (error) {
      ToastsStore.error('Unable to fetch channel list.');
    }
  };

  useEffect(() => {
    const initialData = {};

    if (location.state && location.state.data) {
      const dataVal = location.state.data;

      initialData.notification_type = dataVal.notification_type;
      initialData.bottom_tab_index =
        typeof dataVal.bottom_tab_index !== 'undefined' ? dataVal.bottom_tab_index : 1;
      generateObject({
        value: dataVal.notification_type,
        content: dataVal.content_object,
        text: dataVal.notification_type !== 'Home' ? dataVal.content_object.slug : '',
        showEmpty: dataVal.notification_type !== 'Home' ? dataVal.content_object.id : '',
      });
      let users;
      if (dataVal.broadcast.user_list_type) {
        users = dataVal.broadcast.user_list_type.id;
      } else if (dataVal.broadcast.user_type) {
        users = dataVal.broadcast.user_type;
      } else users = 'all';
      initialData.user_list_type = users;
      if (dataVal.broadcast.schedule_datetime)
        dispatch({ type: 'SCHEDULE_NOTIF', time: dataVal.broadcast.schedule_datetime });
      initialData.scheduledTime = dataVal.broadcast.schedule_datetime;
      fetchChannelById(dataVal.group.id, 0)
        .then(() => {
          if (dataVal.notification_type !== 'Home') {
            initialData.notification_datas = [
              {
                object_id: dataVal.content_object.id,
                text: dataVal.text,
                subtext: dataVal.subtext,
                imageUrl: dataVal.imageUrl,
                title: dataVal.title,
                channel: dataVal.channel.id,
                group: dataVal.group.id,
              },
            ];
          } else {
            initialData.notification_datas = [
              {
                text: dataVal.text,
                subtext: dataVal.subtext,
                imageUrl: dataVal.imageUrl,
                title: dataVal.title,
                channel: dataVal.channel.id,
                group: dataVal.group.id,
              },
            ];
          }
          dispatch({ type: 'INITIALIZE_DATA', initialData });
          dispatch({ type: 'SET_PAGE_LOADING', value: false });
        })
        .catch((error) => {
          ToastsStore.error('Unable to fetch channel list');
        });
    } else dispatch({ type: 'SET_PAGE_LOADING', value: false });
  }, [location.state, generateObject]);

  const yesterday = moment().subtract(1, 'day');
  const disablePastDt = (current) => current.isAfter(yesterday);

  const tomorrow = moment().add(1, 'day');
  const disableFutureDt = (current) => tomorrow.isAfter(current);

  const history = useHistory();
  const intervalRef = useRef(null);

  // console.log('oo', data);

  // const disableTokenGenerator = data.user_list_type !== 'registration_ids';

  const fetchData = useCallback(async () => {
    const response = await fetchUserListFull();

    const groups = response.data.results.map((val) => ({
      key: val.id,
      value: val.id,
      text: val.title,
    }));

    groups.unshift({
      key: 'channel_follower',
      value: 'channel_follower',
      text: 'Channel Followers Only',
    });
    groups.unshift({ key: 'all', value: 'all', text: 'All' });
    dispatch({
      type: 'SET_USER_LIST',
      value: groups,
    });
  }, []);

  const fetchChannels = useCallback(async () => {
    try {
      const response = await fetchChannelsApi('group');
      const groups = response.data.results.map((val) => ({
        key: val.id,
        value: val.id,
        text: val.name,
      }));
      dispatch({
        type: 'SET_GROUP_LIST',
        value: groups,
      });
    } catch (error) {
      ToastsStore.error('Unable to fetch channel list.');
    }
  }, []);

  const fetchTagSuggestions = async ({ value, index, addListValue }) => {
    try {
      const response = await fetchTagsData({ searchString: value });
      const tagValues = response.data.results.map((tag) => ({
        id: tag.id,
        key: tag.id,
        value: tag.id,
        text: tag.title,
      }));
      if (typeof index !== 'undefined') dispatch({ type: 'SET_TAGS', value: tagValues, index });
      if (addListValue) {
        dispatch({ type: 'SET_ALL_TAGS', value: tagValues });
        if (addListValue) dispatch({ type: 'ADD_LIST' });
      }
    } catch (error) {
      ToastsStore.error('Unable to fetch tags');
    }
  };

  const fetchCategories = async () => {
    try {
      const categoriesRes = await fetchCategoriesData();
      const categoryVal = categoriesRes.data.results.map((category) => ({
        id: category.id,
        key: category.id,
        value: category.id,
        text: category.title,
      }));
      dispatch({ type: 'SET_CATEGORIES', value: categoryVal });
    } catch {
      ToastsStore.error('Unable to fetch categories.');
    }
  };

  const fetchPackageList = async () => {
    try {
      const packagesRes = await fetchPackages();

      const packageVal = packagesRes.data.results.map((packageItem) => ({
        id: packageItem.id,
        key: packageItem.id,
        value: packageItem.name,
        text: packageItem.name,
        isDefualt: packageItem.is_default,
      }));

      dispatch({ type: 'SET_PACKAGE_LIST', value: packageVal });
    } catch {
      ToastsStore.error('Unable to fetch categories.');
    }
  };

  useEffect(() => {
    fetchData();
    fetchCategories();
    fetchPackageList();
  }, [fetchData]);

  useEffect(() => {
    fetchChannels();
  }, [fetchChannels]);

  // const generateTokenModal = () => {
  //   dispatch({ type: 'SET_TOKEN' });
  // };

  const handleChangeNotificationType = async (value) => {
    dispatch({ type: 'SET_LOADING', value: 0 });
    const val = {
      notification_datas: [
        {
          object_id: '',
          text: '',
          subtext: '',
          imageUrl: '',
          title: 'Alert',
          group: '',
          channel: '',
          // notif_id: '',
          notification_type: value,
        },
      ],
      notification_type: value,
    };
    dispatch({
      type: 'SET_VALUE',
      value: {
        ...val,
        user_list_type: data.user_list_type,
        bottom_tab_index: data.bottom_tab_index,
        cities: data.cities || [],
        states: data.states || [],
        read: data.read || [],
        app_package_name: data.app_package_name,
      },
    });
    generateObject({ value });
  };

  // const setToken = (value) => {
  //   ToastsStore.success('FCM Token copied');
  //   dispatch({ type: 'SET_VALUE', value: { ...data, token: value } });
  // };

  const closeProgressModal = (valType) => {
    dispatch({ type: 'SHOW_PROGRESS', open: false, value: -1 });
    dispatch({ type: 'SET_PAGE_LOADING', value: false });
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    if (!(valType && typeof valType === 'string'))
      handleChangeValue({ type: 'notification_type', value: '' });
  };

  const fetchSource = (index) =>
    window.URL.createObjectURL(data.notification_datas[index].imageUrl);
  const checkErrors = (type, value, index) => {
    const errorVal = { notification_datas: [] };

    let count = 0;
    if (
      (type === 'user_list_type' && !value) ||
      (type !== 'user_list_type' && !data.user_list_type)
    )
      errorVal.user_list_type = 'Please select attendees';

    if (
      (type === 'app_package_name' && !value) ||
      (type !== 'app_package_name' && !data.app_package_name)
    )
      errorVal.app_package_name = 'Please select Package';

    // if (
    //   (type === 'registration_ids' && !value) ||
    //   (type !== 'registration_ids' && data.user_list_type === 'registration_ids' && !data.token)
    // )
    //   errorVal.token = 'Please generate a token';
    data.notification_datas.forEach((val, id) => {
      if (!errorVal.notification_datas[index]) errorVal.notification_datas.push({});
      const idx = index === undefined ? id : index;
      if (
        (type === 'title' && !(value && value.trim())) ||
        (type !== 'title' && !(val.title && val.title.trim()))
      )
        errorVal.notification_datas[idx].title = 'Please enter title';
      if (
        (type === 'object_id' && !value) ||
        (type !== 'object_id' && !val.object_id && data.notification_type !== 'Home')
      ) {
        if (data.notification_type === 'Categories') {
          errorVal.notification_datas[idx].object_id = 'Please select category';
        } else if (data.notification_type === 'Formatted articles') {
          errorVal.notification_datas[idx].object_id = 'Please select formatted article';
        } else if (data.notification_type === 'Publications') {
          errorVal.notification_datas[idx].object_id = 'Please select publication';
        } else if (data.notification_type === 'Tags') {
          errorVal.notification_datas[idx].object_id = 'Please select tag';
        } else if (data.notification_type === 'Articles') {
          errorVal.notification_datas[idx].object_id = 'Please select article';
        } else if (data.notification_type === 'Pills') {
          errorVal.notification_datas[idx].object_id = 'Please select pill';
        }
      }
      if (
        (type === 'text' && !(value && value.trim())) ||
        (type !== 'text' && !(val.text && val.text.trim()))
      )
        errorVal.notification_datas[idx].text = 'Please enter text';
      if ((type === 'channel' && !value) || (type !== 'channel' && !val.channel))
        errorVal.notification_datas[idx].channel = 'Please enter channel';
      if ((type === 'group' && !value) || (type !== 'group' && !val.group))
        errorVal.notification_datas[idx].group = 'Please enter group';
      if (errorVal.notification_datas[idx] && !Object.keys(errorVal.notification_datas[idx]).length)
        count += 1;
    });
    if (count === data.notification_datas.length) delete errorVal.notification_datas;
    dispatch({ type: 'SET_ERRORS', value: errorVal });
    return errorVal;
  };

  const showProgress = async (id, index, length, valType) => {
    const response = await fetchProgress(id);
    if (response.data.notification_data[index]) {
      const progressValue = response.data.notification_data[index].report.sent_status;
      if (parseInt(progressValue.percentage, 10) !== 100) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        intervalRef.current = setInterval(showProgress, 5000, id, index, length, valType);
        dispatch({ type: 'SHOW_PROGRESS_STATUS', value: progressValue });
      } else if (index < length) {
        dispatch({ type: 'SHOW_PROGRESS', open: true, value: index, length });
        dispatch({ type: 'SHOW_PROGRESS_STATUS', value: progressValue });
        setTimeout(() => {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
          showProgress(id, index + 1, length, valType);
          if (index + 1 !== length)
            dispatch({ type: 'SHOW_PROGRESS', open: true, value: index + 1, length });
        }, 5000);
      } else {
        closeProgressModal(valType);
      }
    } else {
      closeProgressModal(valType);
    }
  };

  const confirmSubmit = (valType) => {
    const newData = JSON.parse(JSON.stringify(data));
    if (valType && typeof valType === 'string') newData.user_list_type = newData.test_user;
    newData.bottom_tab_index = data.bottom_tab_index;
    newData.cities = data.cities;
    newData.states = data.states;
    const tagFollowed = newData.tag_followed;
    delete newData.notification_type;
    const arrayList = [];
    // data.app_config_ids = [];
    data.notification_datas.map(async (val, index) => {
      const newVal = { ...val };
      // newVal.notif_id = Date.parse(new Date()) / 1000;
      newVal.notification_type = data.notification_type;
      if (typeof val.imageUrl === 'object') {
        try {
          const formData = new FormData();
          formData.append('image_file', val.imageUrl);
          try {
            const image = await uploadNotificationImage(formData);
            newVal.imageUrl = image.data.image_file_url;
            arrayList.push(newVal);
          } catch {
            ToastsStore.error('Unable to save image');
          }
        } catch (e) {
          ToastsStore.error('Unable to send notification');
        }
      } else arrayList.push(newVal);
      if (arrayList.length === data.notification_datas.length) {
        newData.notification_datas = [...arrayList];
        delete newData.notification_type;
        delete newData.tag_followed;
        try {
          if (!scheduleNotification || (valType && typeof valType === 'string')) {
            const read = JSON.parse(JSON.stringify(data.read));
            const filters = { read };
            newData.filters = JSON.parse(JSON.stringify(filters));
            delete newData.read;
            delete newData.app_package_name;
            newData.app_config_data = [
              {
                app_package_name: data.app_package_name,
                app_config_ids: [],
              },
            ];

            const progressValue = await sendNotificationApi(newData, tagFollowed);
            showProgress(
              progressValue.data.broadcast.id,
              0,
              data.notification_datas.length,
              valType,
            );
            dispatch({ type: 'SHOW_PROGRESS', value: 0, open: true });
            if (!(valType && typeof valType === 'string')) {
              dispatch({
                type: 'SET_VALUE',
                value: {
                  notification_datas: [],
                  tag_followed: false,
                  cities: [],
                  states: [],
                  read: { tags: [], categories: [], keywords: [] },
                },
              });
            }
            ToastsStore.success('Sending notification.');
            return arrayList;
          }
          newData.schedule_datetime = scheduledTime;
          if (scheduleNotification && moment(new Date()).add(5, 'minutes').isAfter(scheduledTime)) {
            ToastsStore.error('Please select atleast five minute more than current time');
            dispatch({ type: 'SET_PAGE_LOADING', value: false });
          } else {
            await sendNotificationApi(newData, tagFollowed);
            ToastsStore.success('Notification scheduled.');
            dispatch({
              type: 'SET_VALUE',
              value: {
                notification_datas: [],
                tag_followed: false,
                cities: [],
                states: [],
                read: { tags: [], categories: [], keywords: [] },
              },
            });
            dispatch({ type: 'SCHEDULE_NOTIF' });
            dispatch({ type: 'SET_PAGE_LOADING', value: false });
          }
        } catch (error) {
          if (error.response.status === 400)
            ToastsStore.error(
              'Number of users in the list selected, are lesser than the number of notification versions.',
            );
          else ToastsStore.error('Unable to send notifications.');
          dispatch({ type: 'SET_PAGE_LOADING', value: false });
        }
      }
    });
  };

  const sendNotification = async (type) => {
    dispatch({ type: 'SET_PAGE_LOADING', value: true });
    dispatch({ type: 'SET_TYPE', valType: type });
    const error = checkErrors();

    if (!Object.keys(error).length) {
      try {
        if (data.user_list_type === 'all' && !type)
          dispatch({ type: 'CONFIRM_MODAL', submit: true, value: 'all' });
        else if (data.user_list_type === 'channel_follower' && !type)
          dispatch({ type: 'CONFIRM_MODAL', submit: true, value: 'channel_follower' });
        else confirmSubmit(type);
      } catch {
        ToastsStore.error('Unable to send notifications.');
        dispatch({ type: 'SET_PAGE_LOADING', value: false });
      }
    } else dispatch({ type: 'SET_PAGE_LOADING', value: false });
  };

  const scheduleNotif = async () => {
    dispatch({ type: 'SCHEDULE_NOTIF' });
  };

  const fetchDigestData = async (value, index) => {
    try {
      const values = await fetchDigestEditData({ id: value });
      let imageUrl = '';
      if (
        values.data.custom_thumbnail &&
        !values.data.cover_pic.includes('https://www.youtube.com/watch?v')
      )
        imageUrl = values.data.custom_thumbnail;
      else
        imageUrl = `https://i3.ytimg.com/vi/${values.data.cover_pic.substring(
          values.data.cover_pic.indexOf('=') + 1,
          values.data.cover_pic.length,
        )}/hqdefault.jpg`;
      dispatch({
        type: 'SET_VALUE',
        apiData: true,
        text: values.data.notification_text || values.data.title,
        title: values.data.notification_title || 'Alert',
        value,
        imageUrl,
        subkey: 'article_id',
        index,
      });
    } catch {
      ToastsStore.error('Unable to fetch article data');
    }
  };

  const addList = () => {
    if (!allArticles && data.notification_type === 'Articles')
      onSearchChange({ value: '', addListValue: true });
    else if (!allArticles && data.notification_type === 'Tags')
      fetchTagSuggestions({ value: '', addListValue: true });
    else dispatch({ type: 'ADD_LIST' });
  };

  const removeList = (index) => {
    dispatch({ type: 'REMOVE_NOTIFICATION', index });
  };

  const validateVersions = (value) => {
    if (data.notification_datas.length === 1) {
      dispatch({ type: 'SET_VALUE', value: { ...data, user_list_type: value } });
      dispatch({ type: 'HIDE_ADD_NOTIF', value: false });
    } else {
      dispatch({ type: 'SHOW_REMOVE_NOTIF', value });
    }
  };

  const remove = () => {
    dispatch({ type: 'CONFIRM_MODAL' });
    dispatch({ type: 'SET_VALUE', value: { ...data, user_list_type: '' } });
    dispatch({ type: 'HIDE_ADD_NOTIF', value: true });
    if (submit) dispatch({ type: 'SET_PAGE_LOADING', value: false });
  };

  const addAll = () => {
    dispatch({ type: 'CONFIRM_MODAL' });
    dispatch({ type: 'HIDE_ADD_NOTIF', value: true });
    if (submit) {
      confirmSubmit(submit);
    } else if (selectedType === 'all') {
      dispatch({ type: 'SET_VALUE', value: { ...data, user_list_type: 'all' } });
    } else dispatch({ type: 'SET_VALUE', value: { ...data, user_list_type: 'channel_follower' } });
  };

  const handleChangeValue = ({ type, value, index }) => {
    // const tempData = JSON.parse(JSON.stringify(data));
    if (type === 'notification_type') handleChangeNotificationType(value, index);
    else if (type === 'bottom_tab_index')
      dispatch({ type: 'SET_VALUE', value: { ...data, bottom_tab_index: value } });
    // else if (type === 'channel')
    //   dispatch({ type: 'SET_VALUE', value: { ...data, channel: value } });
    // else if (type === 'group') dispatch({ type: 'SET_VALUE', value: { ...data, group: value } });
    else if (type === 'tag_followed') {
      dispatch({ type: 'SET_VALUE', value: { ...data, tag_followed: value } });
    } else if (type === 'object_id') {
      if (data.notification_type === 'Articles') {
        dispatch({ type: 'SET_LOADING', value: index });
        fetchDigestData(value, index);
      } else dispatch({ type: 'SET_VALUE', value, index, subKey: true });
    } else if (type === 'user_list_type') {
      if (value === 'registration_ids') validateVersions(value);
      else if (value === 'all' || value === 'channel_follower')
        dispatch({ type: 'CONFIRM_MODAL', value });
      else {
        dispatch({ type: 'SET_VALUE', value: { ...data, user_list_type: value } });
        dispatch({ type: 'HIDE_ADD_NOTIF', value: true });
      }
    } else if (type === 'test_user') {
      dispatch({ type: 'SET_VALUE', value: { ...data, test_user: value } });
      dispatch({ type: 'HIDE_ADD_NOTIF', value: true });
    } else if (type === 'app_package_name') {
      dispatch({ type: 'SET_VALUE', value: { ...data, app_package_name: value } });
    } else {
      if (type === 'group') {
        fetchChannelById(value, index);
      }
      dispatch({ type: 'SET_VALUE', value, index, key: type });
    }
    if (Object.keys(errors).length) {
      checkErrors(type, value, index);
    }
  };

  const fetchTags = async (value) => {
    try {
      const response = await fetchTagsData({ searchString: value });
      const val = response.data.results;
      return val.filter((item) => !data.read.tags.includes(item.id));
    } catch (error) {
      console.log('error', error);
    }
    return [];
  };

  const handleSelectTags = (chips) => {
    dispatch({ type: 'FILTER_TAGS', chips });
  };

  const fetchCities = async (value) => {
    try {
      const response = await fetchCityList({ search: value });
      const val = response.data.cities;
      return val.filter((item) => !data.cities.includes(item.city));
    } catch (error) {
      console.error('error', error);
    }
    return [];
  };

  const fetchStates = async (value) => {
    try {
      const response = await fetchCityList({ stateSearch: value });
      const val = response.data.states;
      return val.filter((item) => !data.states.includes(item.state));
    } catch (error) {
      console.error('error', error);
    }
    return [];
  };

  const handleSelectCities = (chips) => {
    dispatch({ type: 'FILTER_CITIES', chips });
  };

  const handleSelectStates = (chips) => {
    dispatch({ type: 'FILTER_STATES', chips });
  };

  const handleSelectKeywords = (chips) => {
    dispatch({ type: 'FILTER_KEYWORDS', chips });
  };

  return (
    <Layout location={location}>
      <Helmet>
        <title>XND</title>
      </Helmet>
      <MainLayout>
        <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
        <Flex>
          <SectionTitle> Notifications</SectionTitle>
          {/* <AddUser
            icon="add user"
            content="Generate FCM Token"
            positive
            labelPosition="right"
            disabled={disableTokenGenerator}
            onClick={generateTokenModal}
          /> */}
          <AddUser
            icon="eye"
            content="View Groups"
            positive
            labelPosition="right"
            onClick={() => history.push('/notifications/groups')}
          />
        </Flex>
        {!pageLoading ? (
          <>
            <SubHeading>Type of Notification</SubHeading>
            <FlexFields>
              <Label>Notification Type</Label>
              <SelectCustom
                placeholder="Notification Type"
                options={notificationTypes}
                value={data.notification_type}
                onChange={(e, selected) => {
                  handleChangeValue({ type: 'notification_type', value: selected.value });
                }}
              />
            </FlexFields>
            <FlexFields>
              <Label>Dynamic Field</Label>
              <SelectCustom
                placeholder="Dynamic Field"
                options={dynamicFields}
                value={data.bottom_tab_index}
                onChange={(e, selected) => {
                  handleChangeValue({ type: 'bottom_tab_index', value: selected.value });
                }}
              />
            </FlexFields>
            {data.notification_type === 'Tags' && (
              <FlexFields>
                <Checkbox
                  label="Send to followers"
                  checked={data.tag_followed}
                  disabled={disableTagFollowed}
                  onClick={(e, selected) => {
                    if (!disableTagFollowed) {
                      handleChangeValue({
                        type: 'tag_followed',
                        value: selected.checked,
                      });
                    }
                  }}
                />
              </FlexFields>
            )}
            {data.notification_type && (
              <>
                <SubHeading>Send To</SubHeading>
                <FlexFields>
                  <Label>Select Tags</Label>
                  <ChipsCustom>
                    <Chips
                      placeholder="Select Tags"
                      className="no-border"
                      value={JSON.parse(JSON.stringify(filterTags))}
                      onChange={handleSelectTags}
                      fetchSuggestions={fetchTags}
                      title="Tag"
                      renderChip={(chipData) => (
                        <ChipWrapper>
                          {chipData.title || chipData}
                          <ChipCloseIcon
                            onClick={() => {
                              let index;
                              if (chipData.id)
                                index = filterTags.findIndex((tag) => tag.id === chipData.id);
                              else index = filterTags.findIndex((tag) => tag === chipData);
                              const tagsData = JSON.parse(JSON.stringify(filterTags));
                              tagsData.splice(index, 1);
                              handleSelectTags(tagsData);
                            }}
                          >
                            <Icon size="small" name="close" />
                          </ChipCloseIcon>
                        </ChipWrapper>
                      )}
                      renderSuggestion={(val) => <span>{val.title}</span>}
                      suggestionsFilter={(opt, val) =>
                        data.read.tags.findIndex((tag) => tag.id === opt.id) === -1
                      }
                    />
                  </ChipsCustom>
                </FlexFields>
                <FlexFields>
                  <Label>Select Categories</Label>
                  <Dropdown
                    placeholder="Select Categories"
                    fluid
                    value={data.read.categories}
                    multiple
                    search
                    selection
                    onChange={(e, val) =>
                      dispatch({ type: 'SET_FILTER_CATEGORIES', value: val.value })
                    }
                    options={categories}
                  />
                </FlexFields>
                <FlexFields>
                  <Label>Select Datetime from</Label>
                  <DateTimeWrapper w="100%" style={{ width: '100%' }}>
                    <Datetime
                      value={
                        data.read.datetime_from
                          ? moment(new Date(data.read.datetime_from))
                          : moment(new Date())
                      }
                      dateFormat="MMM DD YYYY"
                      timeFormat="hh:mm A"
                      isValidDate={disableFutureDt}
                      onChange={(value) => {
                        if (value.isAfter(moment(new Date())))
                          ToastsStore.error('Please select past date and time');
                        else
                          dispatch({
                            type: 'SET_FILTER_DATETIME',
                            value: new Date(value.format('MMM DD YYYY hh:mm A')),
                          });
                      }}
                    />
                  </DateTimeWrapper>
                </FlexFields>
                <FlexFields>
                  <Label>Select Keywords</Label>
                  <ChipsCustom>
                    <Chips
                      placeholder="Select Keywords"
                      className="no-border"
                      value={JSON.parse(JSON.stringify(data.read.keywords))}
                      onChange={handleSelectKeywords}
                      createChipKeys={[13]}
                      title="Tag"
                      renderChip={(chipData) => (
                        <ChipWrapper>
                          {chipData.title || chipData}
                          <ChipCloseIcon
                            onClick={() => {
                              const index = data.read.keywords.findIndex((tag) => tag === chipData);
                              const keywordsData = JSON.parse(JSON.stringify(data.read.keywords));
                              keywordsData.splice(index, 1);
                              handleSelectKeywords(keywordsData);
                            }}
                          >
                            <Icon size="small" name="close" />
                          </ChipCloseIcon>
                        </ChipWrapper>
                      )}
                      renderSuggestion={(val) => <span>{val.title}</span>}
                      suggestionsFilter={(opt, val) =>
                        data.read.tags.findIndex((tag) => tag.id === opt.id) === -1
                      }
                    />
                  </ChipsCustom>
                </FlexFields>
                <FlexFields>
                  <Label>Select Cities</Label>
                  <ChipsCustom>
                    <Chips
                      placeholder="Select Cities"
                      className="no-border"
                      value={JSON.parse(JSON.stringify(cities))}
                      onChange={handleSelectCities}
                      fetchSuggestions={fetchCities}
                      title="Tag"
                      renderChip={(chipData) => (
                        <ChipWrapper>
                          {`${chipData.city}(${chipData.count})`}
                          <ChipCloseIcon
                            onClick={() => {
                              let index;
                              if (chipData.city)
                                index = cities.findIndex((tag) => tag.city === chipData.city);
                              const tagsData = JSON.parse(JSON.stringify(cities));
                              tagsData.splice(index, 1);
                              handleSelectCities(tagsData);
                            }}
                          >
                            <Icon size="small" name="close" />
                          </ChipCloseIcon>
                        </ChipWrapper>
                      )}
                      renderSuggestion={(val) => <span>{`${val.city}(${val.count})`}</span>}
                      suggestionsFilter={(opt, val) =>
                        data.read.tags.findIndex((tag) => tag.id === opt.id) === -1
                      }
                    />
                  </ChipsCustom>
                </FlexFields>
                <FlexFields>
                  <Label>Select States</Label>
                  <ChipsCustom>
                    <Chips
                      placeholder="Select States"
                      className="no-border"
                      value={JSON.parse(JSON.stringify(states))}
                      onChange={handleSelectStates}
                      fetchSuggestions={fetchStates}
                      title="Tag"
                      renderChip={(chipData) => (
                        <ChipWrapper>
                          {`${chipData.state}(${chipData.count})`}
                          <ChipCloseIcon
                            onClick={() => {
                              let index;
                              if (chipData.state)
                                index = states.findIndex((tag) => tag.state === chipData.state);
                              const tagsData = JSON.parse(JSON.stringify(states));
                              tagsData.splice(index, 1);
                              handleSelectStates(tagsData);
                            }}
                          >
                            <Icon size="small" name="close" />
                          </ChipCloseIcon>
                        </ChipWrapper>
                      )}
                      renderSuggestion={(val) => <span>{`${val.state}(${val.count})`}</span>}
                      suggestionsFilter={(opt, val) =>
                        data.read.tags.findIndex((tag) => tag.id === opt.id) === -1
                      }
                    />
                  </ChipsCustom>
                </FlexFields>
              </>
            )}
            <SubHeading>Notification Info</SubHeading>
            {!data.notification_type && (
              <Label m="30px 40px" w="100%">
                Please select the above type to proceed
              </Label>
            )}
            {data.notification_type && (
              <>
                <FlexFields error={errors.app_package_name}>
                  <Label>Package</Label>
                  <Container>
                    <Dropdown
                      placeholder="Select Package"
                      value={data.app_package_name}
                      options={packageList}
                      selection
                      search
                      onChange={(e, selected) => {
                        handleChangeValue({ type: 'app_package_name', value: selected.value });
                      }}
                    />
                    <FieldErrorMessage>{errors.app_package_name}</FieldErrorMessage>
                  </Container>
                </FlexFields>
                <FlexFields error={errors.user_list_type}>
                  <Label>Users*</Label>
                  <Container>
                    <Dropdown
                      placeholder="Select users"
                      value={data.user_list_type}
                      options={userList}
                      selection
                      search
                      onChange={(e, selected) => {
                        handleChangeValue({ type: 'user_list_type', value: selected.value });
                      }}
                    />
                    <FieldErrorMessage>{errors.user_list_type}</FieldErrorMessage>
                  </Container>
                </FlexFields>
                {/* {data.user_list_type === 'registration_ids' && (
              <FlexFields error={errors.token}>
                <Label>FCM Token*</Label>
                <Container>
                  <Input placeholder="Token" disabled value={data.token} />
                  <FieldErrorMessage>{errors.token}</FieldErrorMessage>
                </Container>
              </FlexFields>
            )} */}
                <FlexArray>
                  {data.notification_datas.map((val, index) => (
                    <div key={`1-${index + 1}`}>
                      {loading === index ? (
                        <LoaderWrapper>
                          <Loader active />
                        </LoaderWrapper>
                      ) : (
                        <>
                          <Label>Version {index + 1}</Label>
                          {data.notification_type === 'Publications' && (
                            <FlexFields
                              error={
                                errors.notification_datas &&
                                errors.notification_datas[index] &&
                                errors.notification_datas[index].object_id
                              }
                            >
                              <Label>Publications*</Label>
                              <Container>
                                <Dropdown
                                  placeholder="Select a publication"
                                  selection
                                  search
                                  options={hosts}
                                  value={val.object_id || ''}
                                  onChange={(e, selected) => {
                                    handleChangeValue({
                                      type: 'object_id',
                                      value: selected.value,
                                      title: e.target.textContent,
                                      index,
                                    });
                                  }}
                                />
                                <FieldErrorMessage>
                                  {errors.notification_datas &&
                                    errors.notification_datas[index] &&
                                    errors.notification_datas[index].object_id}
                                </FieldErrorMessage>
                              </Container>
                            </FlexFields>
                          )}
                          {data.notification_type === 'Formatted articles' && (
                            <FlexFields
                              error={
                                errors.notification_datas &&
                                errors.notification_datas[index] &&
                                errors.notification_datas[index].object_id
                              }
                            >
                              <Label>Formatted Article*</Label>
                              <Container>
                                <Dropdown
                                  placeholder="Select an article"
                                  selection
                                  search
                                  value={val.object_id || ''}
                                  options={formattedArticles}
                                  onChange={(e, selected) => {
                                    handleChangeValue({
                                      type: 'object_id',
                                      value: selected.value,
                                      title: e.target.textContent,
                                      index,
                                    });
                                  }}
                                />
                                <FieldErrorMessage>
                                  {errors.notification_datas &&
                                    errors.notification_datas[index] &&
                                    errors.notification_datas[index].object_id}
                                </FieldErrorMessage>
                              </Container>
                            </FlexFields>
                          )}
                          {data.notification_type === 'Articles' && (
                            <FlexFields
                              error={
                                errors.notification_datas &&
                                errors.notification_datas[index] &&
                                errors.notification_datas[index].object_id
                              }
                            >
                              <Label>Article*</Label>
                              <Container>
                                <Dropdown
                                  placeholder="Select an article"
                                  selection
                                  className="wrap-words"
                                  search
                                  onSearchChange={(e, value) => {
                                    onSearchChange({ value: value.searchQuery, index });
                                  }}
                                  value={val.object_id || ''}
                                  options={articles[index]}
                                  onChange={(e, selected) => {
                                    handleChangeValue({
                                      type: 'object_id',
                                      value: selected.value,
                                      // title: e.target.textContent,
                                      index,
                                    });
                                  }}
                                />
                                <FieldErrorMessage>
                                  {errors.notification_datas &&
                                    errors.notification_datas[index] &&
                                    errors.notification_datas[index].object_id}
                                </FieldErrorMessage>
                              </Container>
                            </FlexFields>
                          )}
                          {data.notification_type === 'Pills' && (
                            <FlexFields
                              error={
                                errors.notification_datas &&
                                errors.notification_datas[index] &&
                                errors.notification_datas[index].object_id
                              }
                            >
                              <Label>Pill*</Label>
                              <Container>
                                <Dropdown
                                  placeholder="Select a pill"
                                  selection
                                  className="wrap-words"
                                  search
                                  value={val.object_id || ''}
                                  options={pills}
                                  onChange={(e, selected) => {
                                    handleChangeValue({
                                      type: 'object_id',
                                      value: selected.value,
                                      // title: e.target.textContent,
                                      index,
                                    });
                                  }}
                                />
                                <FieldErrorMessage>
                                  {errors.notification_datas &&
                                    errors.notification_datas[index] &&
                                    errors.notification_datas[index].object_id}
                                </FieldErrorMessage>
                              </Container>
                            </FlexFields>
                          )}
                          {data.notification_type === 'Categories' && (
                            <FlexFields
                              error={
                                errors.notification_datas &&
                                errors.notification_datas[index] &&
                                errors.notification_datas[index].object_id
                              }
                            >
                              <Label>Category*</Label>
                              <Container>
                                <Dropdown
                                  placeholder="Select a category"
                                  selection
                                  search
                                  value={val.object_id || ''}
                                  options={categories}
                                  onChange={(e, selected) => {
                                    handleChangeValue({
                                      type: 'object_id',
                                      value: selected.value,
                                      title: e.target.textContent,
                                      index,
                                    });
                                  }}
                                />
                                <FieldErrorMessage>
                                  {errors.notification_datas &&
                                    errors.notification_datas[index] &&
                                    errors.notification_datas[index].object_id}
                                </FieldErrorMessage>
                              </Container>
                            </FlexFields>
                          )}
                          {data.notification_type === 'Tags' && (
                            <FlexFields
                              error={
                                errors.notification_datas &&
                                errors.notification_datas[index] &&
                                errors.notification_datas[index].object_id
                              }
                            >
                              <Label>Tags*</Label>
                              <Container>
                                <Dropdown
                                  placeholder="Select a tag"
                                  selection
                                  search
                                  options={tags[index]}
                                  onSearchChange={(e, value) => {
                                    fetchTagSuggestions({ value: value.searchQuery, index });
                                  }}
                                  value={val.object_id || ''}
                                  onChange={(e, selected) => {
                                    handleChangeValue({
                                      type: 'object_id',
                                      value: selected.value,
                                      title: e.target.textContent,
                                      index,
                                    });
                                  }}
                                />
                                <FieldErrorMessage>
                                  {errors.notification_datas &&
                                    errors.notification_datas[index] &&
                                    errors.notification_datas[index].object_id}
                                </FieldErrorMessage>
                              </Container>
                            </FlexFields>
                          )}
                          <FlexFields
                            error={
                              errors.notification_datas &&
                              errors.notification_datas[index] &&
                              errors.notification_datas[index].title
                            }
                          >
                            <Label>Title*</Label>
                            <Container>
                              <Input
                                placeholder="Title"
                                value={val.title}
                                onChange={(e, selected) => {
                                  handleChangeValue({
                                    type: 'title',
                                    value: selected.value,
                                    index,
                                  });
                                }}
                              />
                              <FieldErrorMessage>
                                {errors.notification_datas &&
                                  errors.notification_datas[index] &&
                                  errors.notification_datas[index].title}
                              </FieldErrorMessage>
                            </Container>
                          </FlexFields>

                          <FlexFields
                            error={
                              errors.notification_datas &&
                              errors.notification_datas[index] &&
                              errors.notification_datas[index].text
                            }
                          >
                            <Label>Text*</Label>
                            <Container>
                              <Input
                                placeholder="Text"
                                value={val.text}
                                onChange={(e, selected) => {
                                  handleChangeValue({ type: 'text', value: selected.value, index });
                                }}
                              />
                              <FieldErrorMessage>
                                {errors.notification_datas &&
                                  errors.notification_datas[index] &&
                                  errors.notification_datas[index].text}
                              </FieldErrorMessage>
                            </Container>
                          </FlexFields>
                          <FlexFields>
                            <Label>Sub-Text</Label>
                            <Container>
                              <Input
                                placeholder="Sub-Text"
                                value={val.subtext}
                                onChange={(e, selected) => {
                                  handleChangeValue({
                                    type: 'subtext',
                                    value: selected.value,
                                    index,
                                  });
                                }}
                              />
                            </Container>
                          </FlexFields>
                          <FlexFields
                            error={
                              errors.notification_datas &&
                              errors.notification_datas[index] &&
                              errors.notification_datas[index].group
                            }
                          >
                            <Label>Group*</Label>
                            <Container>
                              <Dropdown
                                placeholder="Select group"
                                options={groupList}
                                value={val.group}
                                selection
                                search
                                onChange={(e, selected) => {
                                  handleChangeValue({
                                    type: 'group',
                                    value: selected.value,
                                    index,
                                  });
                                }}
                              />
                              <FieldErrorMessage>
                                {errors.notification_datas &&
                                  errors.notification_datas[index] &&
                                  errors.notification_datas[index].group}
                              </FieldErrorMessage>
                            </Container>
                          </FlexFields>
                          {val.group && (
                            <>
                              <FlexFields
                                error={
                                  errors.notification_datas &&
                                  errors.notification_datas[index] &&
                                  errors.notification_datas[index].channel
                                }
                              >
                                <Label>Channel*</Label>
                                <Container>
                                  <Dropdown
                                    placeholder="Select channel"
                                    options={channelList[index]}
                                    value={val.channel}
                                    selection
                                    search
                                    onChange={(e, selected) => {
                                      handleChangeValue({
                                        type: 'channel',
                                        value: selected.value,
                                        index,
                                      });
                                    }}
                                  />
                                  <FieldErrorMessage>
                                    {errors.notification_datas &&
                                      errors.notification_datas[index] &&
                                      errors.notification_datas[index].channel}
                                  </FieldErrorMessage>
                                </Container>
                              </FlexFields>
                            </>
                          )}
                          <FlexFields>
                            <Label w="16%">Image</Label>
                            {uploadImage === index + 1 && (
                              <UploadImageComponent
                                onSave={(image) => {
                                  handleChangeValue({
                                    type: 'imageUrl',
                                    value: image,
                                    index: uploadImage - 1,
                                  });
                                  dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: -1 });
                                }}
                                code="200"
                                localImageUrl={
                                  data.notification_datas[uploadImage - 1].imageUrl || null
                                }
                                open={uploadImage}
                                modalInputRef={modalInputRef}
                                onClose={() => {
                                  dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: -1 });
                                }}
                              />
                            )}
                            <ImageWrapper>
                              <ImageContainer>
                                {val.imageUrl && (
                                  <>
                                    <Image
                                      src={
                                        typeof val.imageUrl === 'object'
                                          ? fetchSource(index)
                                          : val.imageUrl
                                      }
                                    />
                                    <CopyIconImage
                                      onClick={() => {
                                        handleChangeValue({
                                          type: 'imageUrl',
                                          value: '',
                                          index,
                                        });
                                      }}
                                      b="100px"
                                    >
                                      <Icon name="cancel" size="large" />
                                    </CopyIconImage>
                                  </>
                                )}
                                <CopyIconImage
                                  onClick={() =>
                                    dispatch({ type: 'UPLOAD_IMAGE_STATUS', status: index + 1 })
                                  }
                                >
                                  <Icon name="plus" size="large" />
                                </CopyIconImage>
                              </ImageContainer>
                            </ImageWrapper>
                          </FlexFields>
                          {data.notification_datas && data.notification_datas.length > 1 && (
                            <ButtonWrapper>
                              <Button
                                negative
                                icon="minus"
                                type="submit"
                                onClick={() => removeList(index)}
                              >
                                Remove Notification
                              </Button>
                            </ButtonWrapper>
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </FlexArray>
                <ButtonWrapper
                  mt={data.notification_datas && data.notification_datas.length > 1 ? '30px' : 0}
                >
                  {addNotifVisible && (
                    <AddUser
                      icon="plus"
                      type="button"
                      disabled={loading !== -1}
                      content="Add Notification"
                      tertiary
                      labelPosition="right"
                      onClick={addList}
                    />
                  )}
                </ButtonWrapper>
                <ButtonWrapper>
                  <FlexFields>
                    <Label>Test Users*</Label>
                    <Container>
                      <Dropdown
                        placeholder="Select users"
                        value={data.test_user}
                        options={userList.filter(
                          (list) => list.value !== 'channel_follower' && list.value !== 'all',
                        )}
                        selection
                        search
                        onChange={(e, selected) => {
                          handleChangeValue({
                            type: 'test_user',
                            value: selected.value,
                          });
                        }}
                      />
                    </Container>
                  </FlexFields>
                </ButtonWrapper>
                <ButtonWrapper mt="30px">
                  <Checkbox
                    label="Schedule Notification"
                    checked={scheduleNotification}
                    onClick={() => {
                      scheduleNotif();
                    }}
                  />
                </ButtonWrapper>
                {scheduleNotification && (
                  <DateTimeWrapper mt="30px">
                    <Datetime
                      value={
                        scheduledTime
                          ? moment(new Date(scheduledTime))
                          : moment(new Date()).add(5, 'minutes')
                      }
                      dateFormat="MMM DD YYYY"
                      timeFormat="hh:mm A"
                      isValidDate={disablePastDt}
                      onChange={(value) => {
                        if (typeof value === 'object') {
                          if (moment(new Date()).add(5, 'minutes').isAfter(value))
                            ToastsStore.error(
                              'Please select atleast five minute more than current time',
                            );
                          else
                            dispatch({
                              type: 'SET_DATE_TIME',
                              value: new Date(value.format('MMM DD YYYY hh:mm A')),
                            });
                        }
                      }}
                    />
                  </DateTimeWrapper>
                )}
                <ButtonWrapper mt="20px">
                  <Button
                    type="button"
                    disabled={loading !== -1 || !data.test_user}
                    content="Test Notification"
                    tertiary
                    onClick={() => sendNotification('test')}
                  />
                  <Button
                    positive
                    type="submit"
                    onClick={() => sendNotification(null)}
                    disabled={loading !== -1}
                  >
                    {scheduleNotification ? 'Schedule Notification' : 'Send Notification'}
                  </Button>
                </ButtonWrapper>
              </>
            )}
          </>
        ) : (
          <LoaderWrapper>
            <Loader active />
          </LoaderWrapper>
        )}
      </MainLayout>
      {/* <Modal open={tokenModal} onClose={generateTokenModal} closeIcon>
        <Modal.Header>FCM Token Generator</Modal.Header>
        <GenerateTokenForm closeModal={generateTokenModal} setTokenField={setToken} />
      </Modal> */}
      {confirm && (
        <Modal size="tiny" open={confirm}>
          <Modal.Content>
            {`This will send notification to all the ${
              selectedType === 'all' ? 'users' : 'channel followers'
            }. Are you sure you want to
            proceed ?`}
          </Modal.Content>
          <Modal.Actions>
            <Button positive content="Yes" onClick={addAll} />
            <Button tertiary onClick={remove} content="No" />
          </Modal.Actions>
        </Modal>
      )}
      {showRemoveNotif && (
        <Modal size="tiny" open={showRemoveNotif}>
          <Modal.Content>
            Adding FCM Token will remove all but the first notification. Do you want to proceed?
          </Modal.Content>
          <Modal.Actions>
            <Button
              negative
              onClick={() => {
                dispatch({ type: 'REMOVE_NOTIFS' });
              }}
              content="Remove"
            />
            <Button
              positive
              content="Cancel"
              onClick={() => {
                dispatch({ type: 'SHOW_REMOVE_NOTIF' });
              }}
            />
          </Modal.Actions>
        </Modal>
      )}
      {showProgressModal && (
        <Modal
          size="tiny"
          open={showProgressModal}
          closeIcon
          onClose={() => closeProgressModal(testing)}
        >
          <Modal.Header>{`Sending Notification ${currentStatus + 1}`}</Modal.Header>
          <Modal.Content>
            <Flex>
              <Label>Total:</Label>
              <Message color="orange">{progress.total}</Message>
            </Flex>
            <Flex>
              <Label>Success:</Label>
              <Message color="green">{progress.success_count}</Message>
            </Flex>
            <Flex>
              <Label>Failure:</Label>
              <Message color="red">{progress.fail_count}</Message>
            </Flex>
            <Progress
              percent={parseInt(progress.percentage, 10)}
              indicating
              style={{ marginTop: '20px' }}
            />
          </Modal.Content>
        </Modal>
      )}
    </Layout>
  );
};

Notifications.propTypes = {
  location: PropTypes.object,
};

export default Notifications;
