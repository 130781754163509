import React, { useEffect, useReducer, useRef } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Loader, Dimmer, Modal, Button } from 'semantic-ui-react';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';

import Layout from '../../../Components/Layout';
import VideoColumn from './VideoColumn';
import SegmentColumn from './SegmentColumn';
import {
  createDigest,
  fetchDigestEditData,
  updateDigest,
  fetchVideoSummaryTags,
  fetchTranscriptData,
  fetchTagsData,
  fetchCategoriesData,
} from '../../../Api';
import AutoSummaryAlert from './AutoSummaryAlert';

const getUnique = (value, index, self) => {
  return self.findIndex((data) => data.id === value.id) === index;
};
const Wrapper = styled.div`
  margin: 0 auto;
  display: flex;
`;

const GridColumn = styled.div`
  width: 50%;
  padding: 50px 15px;
  border-right: ${({ borderRight }) => (borderRight ? 1 : 0)}px solid #d8d8d8;
  height: calc(100vh - 70px);
  overflow: auto;
`;

const GridContent = styled.div`
  width: 90%;
  margin: 0 auto;
`;

const convertSecondsToTimeFormat = (time) => {
  if (time !== 'undefined') {
    const date = new Date(null);
    date.setMilliseconds(time * 1000);
    return date.toISOString().substr(11, 11).replace('.', ':');
  }
};

const initialValue = {
  urlString: null,
  segmentList: [],
  videoSummary: [],
  setVideoPublishLoading: false,
  activeSegment: {
    title: '',
    timestamp_start: convertSecondsToTimeFormat(0),
    timestamp_end: convertSecondsToTimeFormat(1),
    description: '',
    watch_label: '',
  },
  loading: true,
  activeSegmentIndex: 1,
  segmentError: null,
  previewMode: false,
  subtitleMode: false,
  activeTranscript: null,
  videoTranscriptData: [],
  autoSummary: [],
  title: '',
  digest: '',
  tag: [],
  categories: [],
  data: { categories: [], tag: [] },
  confirmCategoryAdd: '',
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_URL_STRING':
        draft.urlString = action.value;
        break;
      case 'SET_SEGMENT_LIST':
        draft.segmentList = action.value;
        draft.segmentError = null;
        draft.activeSegment = {
          title: '',
          timestamp_start: convertSecondsToTimeFormat(0),
          timestamp_end: convertSecondsToTimeFormat(1),
          description: '',
          watch_label: '',
        };
        draft.autoSummaryPopup = false;
        draft.activeSegmentIndex = draft.segmentList.length + 1;
        break;
      case 'SET_ACTIVE_SEGMENT':
        draft.activeSegment = action.value;
        draft.activeSegmentIndex = action.value.segment_id || action.value.index || 1;
        break;
      case 'SET_SEGEMENT_ERROR':
        draft.segmentError = action.value;
        break;
      case 'SET_VIDEO_PUBLISH_REQUEST':
        draft.setVideoPublishLoading = action.value;
        break;
      case 'SET_INIT_STATUS':
        draft.initialStatus = action.status;
        draft.setVideoPublishLoading = false;
        break;
      case 'SET_URL_DATA':
        draft.data = { tag: action.tag, categories: action.categories };
        draft.urlString = action.urlString;
        draft.title = action.title;
        draft.digest = action.digest;
        draft.tag = action.tag;
        draft.categories = action.categories;
        draft.initialStatus = action.status;
        draft.slug = action.slug;
        draft.setVideoPublishLoading = false;
        draft.activeSegmentIndex = action.value.length + 1;
        draft.segmentList = action.value.map((segment) => ({
          ...segment,
          timestamp_start: convertSecondsToTimeFormat(segment.timestamp_start),
          timestamp_end: convertSecondsToTimeFormat(segment.timestamp_end),
        }));
        break;
      case 'SET_DATA':
        draft[action.fieldType] = action.value;
        break;
      case 'SET_VIDEO_SUMMARY_TAGS':
        draft.videoSummary = action.value.map((summary) => ({
          key: summary.id,
          value: summary.id,
          text: summary.watch_label,
        }));
        break;
      case 'LOADING':
        draft.loading = action.value;
        break;
      case 'VIDEO_DURATION':
        draft.formattedTime = action.formattedTime;
        break;
      case 'SET_PREVIEW':
        draft.previewMode = !draft.previewMode;
        draft.subtitleMode = false;
        break;
      case 'SET_SUBTITLE':
        draft.subtitleMode = !draft.subtitleMode;
        draft.previewMode = false;
        break;
      case 'SET_ACTIVE_TRANSCRIPT':
        draft.activeTranscript = JSON.parse(JSON.stringify(action.value));
        if (action.manuallyChange) {
          draft.activeTranscript.manuallyChange = true;
        }
        break;
      case 'SET_AUTO_SUMMARY_POPUP':
        draft.autoSummaryPopup = action.value;
        break;
      case 'SET_TRANSCRIPT_DATA':
        draft.videoTranscriptData = action.transcriptData
          ? action.transcriptData.map((item, index) => {
              item.index = index;
              return item;
            })
          : [];
        draft.autoSummary = (action.autoSummary && action.autoSummary.summary) || [];
        break;
      case 'SET_EXTRA_TAGS':
        draft.extraTags = action.value.filter(getUnique);
        break;
      case 'SET_EXTRA_CATEGORIES':
        draft.extraCategories = action.value.filter(getUnique);
        break;
      case 'SET_DATA_CHANGES':
        draft.data = { tag: action.value.tag, categories: action.value.categories };
        break;
      case 'CONFIRM_CATEGORY_ADD':
        draft.confirmCategoryAdd = action.value;
        break;
    }
  });

const VideoSummaryCreate = ({ location, match: { params } }) => {
  const history = useHistory();
  const videoRef = useRef(null);
  const activeSegmentRef = useRef(null);
  let selectedTag = {};
  const [state, dispatch] = useReducer(reducer, initialValue);

  const {
    urlString,
    segmentList,
    activeSegment,
    activeSegmentIndex,
    segmentError,
    videoSummary,
    setVideoPublishLoading,
    loading,
    formattedTime,
    previewMode,
    videoTranscriptData,
    activeTranscript,
    subtitleMode,
    autoSummaryPopup,
    autoSummary,
    title,
    digest,
    tag,
    categories,
    extraTags,
    extraCategories,
    data,
    confirmCategoryAdd,
    initialStatus,
    slug,
  } = state;

  useEffect(() => {
    if (activeSegment) {
      activeSegmentRef.current = activeSegment;
    }
  }, [activeSegment]);

  const fetchTranscriptSummaryData = (urlString) => {
    const validUrl =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (urlString.match(validUrl)) {
      let video_id = urlString.match(validUrl)[1];
      fetchTranscriptData({ youtubeId: video_id }).then((response) => {
        dispatch({
          type: 'SET_TRANSCRIPT_DATA',
          transcriptData: response.data.transcript,
          autoSummary: response.data.auto_summary,
        });
      });
      dispatch({ type: 'LOADING', value: false });
    }
  };

  useEffect(() => {
    if (location.state && location.state.urlString && !urlString) {
      dispatch({ type: 'SET_URL_STRING', value: location.state.urlString });

      fetchTranscriptSummaryData(location.state.urlString);
    }
  }, [location, urlString]);

  useEffect(() => {
    fetchTagsData({}).then((tagsRes) => {
      dispatch({ type: 'SET_EXTRA_TAGS', value: tagsRes.data.results });
    });

    fetchCategoriesData().then((categoriesRes) => {
      dispatch({ type: 'SET_EXTRA_CATEGORIES', value: categoriesRes.data.results });
    });
  }, []);

  useEffect(() => {
    let changes = false;
    let tempData = JSON.parse(JSON.stringify(data));
    if (Object.keys(tempData).length > 1) {
      if (extraTags && extraTags.length > 0) {
        const tags = tempData.tag.concat(extraTags).filter(getUnique);
        if (tags.length !== tempData.tag.length) {
          tempData.tag = tags;
          changes = true;
        }
      }
      if (extraCategories && extraCategories.length > 2) {
        const category = tempData.categories.concat(extraCategories).filter(getUnique);
        if (category.length !== tempData.categories.length) {
          tempData.categories = category;
          changes = true;
        }
      }
    }
    //  else if(extractError) {
    //   if (extraTags && extraTags.length > 0) {
    //     const tags = extraTags.filter(getUnique);
    //     if (!tag || tags.length !== tag.length) {
    //       data.tag = tags;
    //       changes = true;
    //     }
    //   }
    //   if (extraCategories && extraCategories.length > 0) {
    //     const category = extraCategories.filter(getUnique);
    //     if (!categories || category.length !== categories.length) {
    //       data.categories = category;
    //       changes = true;
    //     }
    //   }
    // }
    if (changes) {
      dispatch({ type: 'SET_DATA_CHANGES', value: tempData });
    }
  });

  useEffect(() => {
    if (params && params.id) {
      dispatch({ type: 'SET_VIDEO_PUBLISH_REQUEST', value: true });
      fetchDigestEditData({ id: params.id }).then((response) => {
        if (response && response.data) {
          dispatch({
            type: 'SET_URL_DATA',
            value: response.data.video_summary.summary,
            urlString: response.data.cover_pic,
            title: response.data.title,
            digest: response.data.digest,
            tag: response.data.tag,
            categories: response.data.categories,
            status: response.data.status,
            slug: response.data.slug,
          });
          fetchTranscriptSummaryData(response.data.cover_pic);
        }
      });
    }
  }, [params]);

  useEffect(() => {
    fetchVideoSummaryTags().then((response) => {
      dispatch({ type: 'SET_VIDEO_SUMMARY_TAGS', value: response.data.results });
    });
  }, []);

  const togglePreview = () => {
    dispatch({ type: 'SET_PREVIEW' });
  };

  const toggleSubtitle = () => {
    dispatch({ type: 'SET_SUBTITLE' });
  };

  const onChangeActiveSegment = ({ type, value }) => {
    const data = JSON.parse(JSON.stringify(activeSegment));
    if (type === 'categories') {
      dispatch({ type: 'SET_DATA', value, fieldType: type });
    } else if (type === 'tag') {
      dispatch({ type: 'SET_DATA', value, fieldType: type });
    } else if (type === 'dtitle') {
      dispatch({ type: 'SET_DATA', value, fieldType: 'title' });
    } else if (type === 'digest') {
      dispatch({ type: 'SET_DATA', value, fieldType: 'digest' });
    } else if (type === 'title' || type === 'description') {
      data[type] = value;
    } else if (type === 'video_summary_tag') {
      const summary = videoSummary.filter((val) => val.key === value);
      if (!summary.length) {
        data.watch_label = null;
      } else {
        data.watch_label = value;
      }
    } else if (type === 'timestamp_start_ms' && value) {
      let start = data.timestamp_start || '00:00:00:00';
      let time = start.split(':');
      time[3] = value > 99 || value < 0 ? time[3] : value;
      data.timestamp_start = `${time[0]}:${time[1]}:${time[2]}:${time[3]}`;
    } else if (type === 'timestamp_end_ms' && value) {
      let end = data.timestamp_end || '00:00:00:00';
      let time = end.split(':');
      time[3] = value > 99 || value < 0 ? time[3] : value;
      data.timestamp_end = `${time[0]}:${time[1]}:${time[2]}:${time[3]}`;
    } else {
      data[type] = value ? value.format('HH:mm:ss:SS') : null;
      if (type === 'timestamp_start' && value !== null) {
        const startTimeMoment = moment(
          `${moment().format('DD:MM:YY')} ${value.format('HH:mm:ss:SS')}`,
          'DD:MM:YY HH:mm:ss:SS',
        );
        const endTimeMoment = moment(
          `${moment().format('DD:MM:YY')} ${activeSegment.timestamp_end}`,
          'DD:MM:YY HH:mm:ss:SS',
        );
        if (startTimeMoment.isAfter(endTimeMoment)) {
          data.timestamp_end = null;
        }
      }
    }
    dispatch({
      type: 'SET_ACTIVE_SEGMENT',
      value: { ...data, index: data.id || segmentList.length + 1 },
    });
  };

  const onChangeTranscriptSegment = ({ start, end }) => {
    const data = JSON.parse(JSON.stringify(activeSegmentRef.current));
    data.timestamp_start = start ? start.format('HH:mm:ss:SS') : null;
    data.timestamp_end = end ? end.format('HH:mm:ss:SS') : null;
    dispatch({
      type: 'SET_ACTIVE_SEGMENT',
      value: { ...data, index: data.id || segmentList.length + 1 },
    });
  };

  const convertTimeToSeconds = (time) => {
    const a = time.split(':'); // split it at the colons
    if (a.length === 2) {
      a.unshift('00');
    } else if (a.length === 3) {
      a.unshift('00');
      a.unshift('00');
    }
    return +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;
  };
  const onPublishVideoArticle = (status, continueEditing) => {
    dispatch({ type: 'SET_VIDEO_PUBLISH_REQUEST', value: true });
    let data = JSON.parse(JSON.stringify(segmentList));
    data = data.map((segment) => {
      segment.timestamp_start = convertTimeToSeconds(segment.timestamp_start);
      segment.timestamp_end = convertTimeToSeconds(segment.timestamp_end);
      const tag = videoSummary.filter((summary) => summary.text === segment.watch_label);
      if (tag.length) segment.video_summary_tag = tag[0].key;
      return segment;
    });

    let summaryData = {};
    summaryData.video_summary = {
      summary: data,
    };
    summaryData.type = 255;
    summaryData.image = urlString;
    summaryData.title = title;
    summaryData.categories = categories.map((category) => category.id || category);
    summaryData.content = digest;
    summaryData.tags = tag.map((tag) => tag.id || tag);
    summaryData.status = status;
    summaryData.default_preview_count = 1;

    if (params && params.id) {
      updateDigest({ data: summaryData, id: params.id })
        .then((response) => {
          ToastsStore.success('Video digest Updated successfully.');
          if (!continueEditing) history.push('/');
          else {
            dispatch({ type: 'SET_INIT_STATUS', status });
          }
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          ToastsStore.error('Something went wrong.');
        });
    } else {
      createDigest({ data: { ...summaryData, type: '255' } })
        .then((response) => {
          ToastsStore.success('Video digest created successfully.');
          if (!continueEditing) history.push('/');
        })
        .catch((error) => {
          if (window) window.Raven.captureException(error.response);
          ToastsStore.error('Something went wrong.');
        });
    }
  };

  const showDuration = () => {
    videoRef.current.pauseVideo();
    const duration = videoRef.current.getDuration();
    let seconds = parseInt(duration);
    dispatch({ type: 'VIDEO_DURATION', formattedTime: seconds });
  };

  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  const disabledHours = () => {
    const hours = range(0, 60);
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    hours.splice(0, hour + 1);
    return hours;
  };

  const disabledMinutes = (h) => {
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
    if (h === hour) return range(min + 1, 60);
    else if (min > 0) return range(0);
    return [];
  };

  const disabledSeconds = (h, m) => {
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
    const sec = Math.floor(moment.duration(formattedTime, 'seconds').seconds());
    if (h === hour && m === min) return range(sec, 60);
    else if (sec > 0) return range(0);
    return [];
  };

  const disabledEndHours = () => {
    const hourStart = Math.floor(
      moment.duration(activeSegment.timestamp_start, 'seconds').asHours(),
    );
    const hours = range(0, 60);
    const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
    return hours.filter((hr) => hr < hourStart || hr > hour);
  };

  const disabledEndMinutes = (h) => {
    if (activeSegment.timestamp_start !== null) {
      const mins = range(0, 60);
      const hourStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').asHours(),
      );
      const minStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').minutes(),
      );
      const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
      const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
      if (h === hour && hourStart !== hour) return range(min + 1, 60);
      else if (h === hour && hourStart === hour)
        return mins.filter((time) => time < minStart || time > min);
      else if (min > 0) return range(0);
      return [];
    }
  };

  const disabledEndSeconds = (h, m) => {
    if (activeSegment.timestamp_start !== null) {
      const secs = range(0, 60);
      const hourStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').asHours(),
      );
      const minStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').minutes(),
      );
      const secStart = Math.floor(
        moment.duration(activeSegment.timestamp_start.substr(0, 8), 'seconds').seconds(),
      );
      const hour = Math.floor(moment.duration(formattedTime, 'seconds').asHours());
      const min = Math.floor(moment.duration(formattedTime, 'seconds').minutes());
      const sec = Math.floor(moment.duration(formattedTime, 'seconds').seconds());

      if (h === hour && hourStart !== hour) {
        if (m === min && minStart !== min) return range(sec, 60);
        else return range(0);
      } else if (h === hour && hourStart === hour) {
        if (m === min && minStart !== min) return range(secStart, 60);
        else if (m === min && minStart === min)
          return secs.filter((time) => time <= secStart || time >= sec);
        else if (m !== min && minStart === m) return range(0, secStart);
        else return range(0);
      }
      return [];
    }
  };

  const handleCopyAutoSummary = () => {
    if (autoSummary && autoSummary.length > 0) {
      const data = autoSummary.map((segment) => ({
        ...segment,
        timestamp_start: convertSecondsToTimeFormat(segment.timestamp_start),
        timestamp_end: convertSecondsToTimeFormat(segment.timestamp_end),
      }));
      dispatch({ type: 'SET_SEGMENT_LIST', value: data });
    }
  };

  const fetchSuggestions = (value, chips) => {
    let chipsTemp = !chips || !chips[0] || typeof chips === 'function' ? {} : chips[0];
    return new Promise((resolve, reject) => {
      if (value.length >= 1 && !Object.keys(chipsTemp).length) {
        const originalCategory = data.categories || [];
        let filtered = originalCategory
          .filter((opt) => selectedTag.id !== opt.id)
          .filter((opt) => categories.findIndex((category) => category.id === opt.id) === -1)
          .filter((opt) => opt.title.toLowerCase().includes(value.toLowerCase()));
        resolve(filtered);
      } else if (!value && !Object.keys(chipsTemp).length) {
        const originalCategory = data.categories || [];
        let selectedTagTemp = selectedTag || {};
        const originalCategories = originalCategory.categories || [];
        let filterdData = originalCategories
          .filter((opt) => selectedTagTemp.id !== opt.id)
          .filter((opt) => selectedTag.id !== opt.id)
          .filter((opt) => categories.findIndex((category) => category.id === opt.id) === -1);
        resolve(filterdData);
      } else if (!value && Object.keys(chipsTemp).length) {
        const originalCategory = data.categories || [];
        let filtered = originalCategory.filter((opt) => opt.id !== chipsTemp.id);
        resolve(filtered);
      } else {
        resolve([]);
      }
    });
  };

  const fetchTagSuggestions = (value, chips) => {
    let chipsTemp = !chips || !chips[0] || typeof chips === 'function' ? {} : chips[0];
    return new Promise((resolve, reject) => {
      if (value.length >= 1 && !Object.keys(chipsTemp).length) {
        const originalTags = data.tag || [];
        let filtered = originalTags
          .filter((opt) => selectedTag.id !== opt.id)
          .filter((opt) => tag.findIndex((tag) => tag.id === opt.id) === -1)
          .filter((opt) => opt.title.toLowerCase().includes(value.toLowerCase()));
        resolve(filtered);
      } else if (!value && !Object.keys(chipsTemp).length) {
        const originalTags = tag || [];
        let filterdData = originalTags
          .filter((opt) => selectedTag.id !== opt.id)
          .filter((opt) => tag.findIndex((tag) => tag.id === opt.id) === -1);
        resolve(filterdData);
      } else if (!value && Object.keys(chipsTemp).length) {
        const originalTags = tag || [];
        let filtered = originalTags.filter((opt) => opt.id !== chipsTemp.id);
        resolve(filtered);
      } else {
        resolve([]);
      }
    });
  };

  return (
    <Layout
      isDashboard
      disabledUrl={params && params.id}
      urlString={urlString}
      path="/video/create"
      location={location}
    >
      {loading || setVideoPublishLoading ? (
        <Dimmer active inverted>
          <Loader size="large">Loading</Loader>
        </Dimmer>
      ) : (
        <Wrapper>
          <Helmet>
            <title>XND</title>
          </Helmet>
          <GridColumn borderRight style={{ marginLeft: 30 }}>
            <GridContent>
              <VideoColumn
                showDuration={showDuration}
                convertSecondsToTimeFormat={convertSecondsToTimeFormat}
                urlString={urlString}
                initialStatus={initialStatus}
                slug={slug}
                startTime={activeSegment.timestamp_start}
                onSetActiveSegment={onChangeActiveSegment}
                videoRef={videoRef}
                segmentList={segmentList}
                onPublishVideoArticle={onPublishVideoArticle}
                setVideoPublishLoading={setVideoPublishLoading}
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                disabledSeconds={disabledSeconds}
                togglePreview={togglePreview}
                toggleSubtitle={toggleSubtitle}
                previewMode={previewMode}
                subtitleMode={subtitleMode}
                videoTranscriptData={videoTranscriptData}
                activeTranscript={activeTranscript}
                onChangeTranscript={(value) => {
                  if (value) {
                    dispatch({ type: 'SET_ACTIVE_TRANSCRIPT', value });
                  }
                }}
              />
            </GridContent>
          </GridColumn>
          <GridColumn>
            <GridContent>
              <SegmentColumn
                segmentError={segmentError}
                videoRef={videoRef}
                disabledEndHours={disabledEndHours}
                disabledEndMinutes={disabledEndMinutes}
                disabledEndSeconds={disabledEndSeconds}
                disabledHours={disabledHours}
                disabledMinutes={disabledMinutes}
                disabledSeconds={disabledSeconds}
                loading={loading}
                videoSummary={videoSummary}
                segmentList={segmentList}
                activeSegment={activeSegment}
                activeSegmentIndex={activeSegmentIndex}
                previewMode={previewMode}
                activeTranscript={activeTranscript}
                videoTranscriptData={videoTranscriptData}
                subtitleMode={subtitleMode}
                autoSummary={autoSummary}
                title={title}
                digest={digest}
                tag={tag}
                categories={categories}
                fetchSuggestions={fetchSuggestions}
                fetchTagSuggestions={fetchTagSuggestions}
                hanldeAddTags={(chips) => {
                  selectedTag = { ...chips[chips.length - 1] };
                  let selectedData = chips || [];
                  onChangeActiveSegment({ type: 'tag', value: selectedData });
                  fetchTagSuggestions('', chips);
                }}
                onChangeCategoriesOption={(chips, removed) => {
                  selectedTag = { ...chips[chips.length - 1] };
                  if (
                    !removed &&
                    !data.categories.filter(
                      (category) => category.id === chips[chips.length - 1].id,
                    ).length
                  ) {
                    dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: chips });
                  } else {
                    let selectedData = chips || [];
                    onChangeActiveSegment({ type: 'categories', value: selectedData });
                    fetchSuggestions('', chips);
                  }
                }}
                onHandleClickAutoSummary={() => {
                  if (segmentList && segmentList.length > 0) {
                    dispatch({ type: 'SET_AUTO_SUMMARY_POPUP', value: true });
                  } else {
                    handleCopyAutoSummary();
                  }
                }}
                onSegmentSave={() => {
                  if (activeSegment) {
                    const { title, watch_label } = activeSegment;
                    if (!title || !title.trim()) {
                      dispatch({ type: 'SET_SEGEMENT_ERROR', value: 'Please enter segment title' });
                    } else if (!tag.length) {
                      dispatch({ type: 'SET_SEGEMENT_ERROR', value: 'Please select digest tags' });
                    } else if (!categories.length) {
                      dispatch({ type: 'SET_SEGEMENT_ERROR', value: 'Please select categories' });
                    } else if (!state.title || !state.title.trim()) {
                      dispatch({ type: 'SET_SEGEMENT_ERROR', value: 'Please enter digest title' });
                    } else if (!digest || !digest.trim()) {
                      dispatch({ type: 'SET_SEGEMENT_ERROR', value: 'Please enter content' });
                    } else {
                      const startTimeMoment = moment(
                        `${moment().format('DD:MM:YY')} ${activeSegment.timestamp_start}`,
                        'DD:MM:YY HH:mm:ss:SSS',
                      );
                      const endTimeMoment = moment(
                        `${moment().format('DD:MM:YY')} ${activeSegment.timestamp_end}`,
                        'DD:MM:YY HH:mm:ss:SSS',
                      );
                      if (endTimeMoment.isAfter(startTimeMoment)) {
                        let data = JSON.parse(JSON.stringify(segmentList));
                        const activeData = JSON.parse(
                          JSON.stringify({
                            ...activeSegment,
                            title: activeSegment.title.trim(),
                            description: activeSegment.description
                              ? activeSegment.description.trim()
                              : '',
                          }),
                        );
                        const summary = videoSummary.filter((value) => value.key === watch_label);
                        if (activeData.id) {
                          data = data.map((item) => {
                            if (item.id === activeData.id) {
                              if (!summary.length) activeData.watch_label = null;
                              else activeData.watch_label = summary[0].text;
                              return activeData;
                            }
                            return item;
                          });
                        } else {
                          activeData.id = segmentList.length + 1;
                          data.push(activeData);
                          if (summary.length) data[data.length - 1].watch_label = summary[0].text;
                        }
                        data.sort(function (a, b) {
                          const dateA = moment(
                            `${moment().format('DD:MM:YY')} ${a.timestamp_start}`,
                            'DD:MM:YY HH:mm:ss:SSS',
                          );
                          const dateB = moment(
                            `${moment().format('DD:MM:YY')} ${b.timestamp_start}`,
                            'DD:MM:YY HH:mm:ss:SSS',
                          );
                          return dateA - dateB; //sort by date ascending
                        });
                        data.forEach((val, index) => {
                          val.segment_id = index + 1;
                        });
                        dispatch({ type: 'SET_SEGMENT_LIST', value: data });
                      } else {
                        dispatch({
                          type: 'SET_SEGEMENT_ERROR',
                          value: 'Start time must be less than end time.',
                        });
                      }
                    }
                  }
                }}
                onChangeActiveSegment={onChangeActiveSegment}
                onChangeTranscriptSegment={onChangeTranscriptSegment}
                onClickSegment={(value, rsestSegment) => {
                  console.log('value, rsestSegment',value, rsestSegment);
                  if (videoRef && videoRef.current && !rsestSegment) {
                    const a = value.timestamp_start.split(':'); // split it at the colons
                    const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;
                    if (typeof videoRef.current.seekTo === 'function') {
                      videoRef.current.seekTo(parseFloat(seconds));
                      videoRef.current.playVideo();
                    }
                  }
                  let watchLabel = videoSummary.filter(
                    (summary) => summary.text === value.watch_label,
                  );
                  let label = '';
                  if (watchLabel.length) label = watchLabel[0].key;
                  dispatch({
                    type: 'SET_ACTIVE_SEGMENT',
                    value: { ...value, watch_label: label },
                    index: value.id || segmentList.length + 1,
                  });
                }}
                removeSegment={(value) => {
                  const index = segmentList.findIndex(
                    (item) => item.segment_id === value.segment_id,
                  );
                  let data = JSON.parse(JSON.stringify(segmentList));
                  data.splice(index, 1);
                  if (activeSegment.id === value.id) {
                    dispatch({
                      type: 'SET_ACTIVE_SEGMENT',
                      value: {
                        title: null,
                        timestamp_start: convertSecondsToTimeFormat(0),
                        timestamp_end: convertSecondsToTimeFormat(1),
                        description: '',
                      },
                      index: segmentList.length + 1,
                    });
                  }
                  dispatch({ type: 'SET_SEGMENT_LIST', value: data });
                }}
                onChangeTranscript={(value) =>
                  dispatch({ type: 'SET_ACTIVE_TRANSCRIPT', value, manuallyChange: true })
                }
              />
            </GridContent>
          </GridColumn>
          <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
          {confirmCategoryAdd && (
            <Modal size="tiny" open={confirmCategoryAdd} onClose={() => {}}>
              <Modal.Content>
                This will create a new category. New category should not be created until absolutely
                necessary. Are you sure you want to update?
              </Modal.Content>
              <Modal.Actions>
                <Button
                  negative
                  // disabled={deleteDigestsLoading}
                  onClick={() => {
                    dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: '' });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  positive
                  content="Add"
                  // disabled={deleteDigestsLoading}
                  onClick={() => {
                    selectedTag = { ...confirmCategoryAdd[confirmCategoryAdd.length - 1] };
                    let selectedData = confirmCategoryAdd || [];
                    onChangeActiveSegment({ type: 'categories', value: selectedData });
                    fetchSuggestions('', confirmCategoryAdd);
                    dispatch({ type: 'CONFIRM_CATEGORY_ADD', value: '' });
                  }}
                />
              </Modal.Actions>
            </Modal>
          )}
        </Wrapper>
      )}
      <AutoSummaryAlert
        active={autoSummaryPopup}
        onHandleNo={() => dispatch({ type: 'SET_AUTO_SUMMARY_POPUP', value: false })}
        onHandleYes={handleCopyAutoSummary}
      />
    </Layout>
  );
};

export default VideoSummaryCreate;
