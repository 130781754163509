import React, { useReducer, useEffect } from 'react';
import { Modal, Input, Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import produce from 'immer';
import styled from 'styled-components';

const ModalInput = styled.div`
  width: 100%;
  .input,
  input {
    width: 100%;
  }
`;

const DropzoneWrapper = styled.div`
  margin-bottom: 30px;
  border-radius: 4px;
  position: relative;
  border: none;
  /* height: 30px; */
  outline: none;
  &:hover {
    border: none;
    cursor: pointer;
  }
`;

const DropzoneContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: 'space-between';
  position: relative;
  justify-content: center;
  margin-right: 35px;
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${({ isYtVideo }) => (isYtVideo ? 'auto' : '287px')};
  overflow: hidden;
  width: 60%;
  img {
    width: 100%;
  }
`;

// const ModalTemp = styled(Modal)`
//   @media (max-width: 768px) {
//     .header {
//       font-size: 15px !important;
//     }
//   }
// `;

const CamIcon = styled(Icon)`
  color: #fff;
  position: absolute;
  bottom: 46%;
  left: 46%;
  width: 16px;
  height: 14px;
`;

const ImageContainer = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  height: calc(100% - 37px);
  margin-top: 20px;
`;

const LogoImg = styled.img`
  width: 100%;
  height: 100%;
`;

const ModalContent = styled(Modal.Content)``;

const Separator = styled.div`
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FieldErrorMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_IMAGE_URL':
        draft.imageUrl = action.value;
        break;
      case 'SET_ORIGINAL_IMAGE_URL':
        draft.originalImageUrl = action.value;
        break;
      case 'SET_CROP_IMAGE_ZOOM':
        draft.cropImageZoom = action.value;
        break;
      case 'SET_CROP_VALUE':
        draft.cropValue = action.value;
        break;
      case 'SET_CROP_IMAGES':
        draft.cropImages = action.value;
        break;
      case 'SET_IMAGE_CROP_AREA':
        draft.imageCropArea = action.value;
        break;
      case 'SET_STORE_CROP_IMAGE':
        draft.storeCropLoading = action.value;
        break;
      case 'CONFIRM_UPDATION':
        draft.confirmUpdate = !draft.confirmUpdate;
        break;
      case 'VALID_IMAGE':
        draft.validImage = action.value;
        break;
    }
  });

const initialState = {
  imageUrl: '',
  originalImageUrl: '',
  cropImageZoom: 1,
  cropValue: { x: 0, y: 0 },
  cropImages: '',
  imageCropArea: null,
  storeCropLoading: false,
  confirmUpdate: false,
  validImage: '',
};

const UploadImage = ({ open, modalInputRef, onClose, onSave, localImageUrl, noUrl }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { imageUrl, originalImageUrl, storeCropLoading, validImage } = state;

  useEffect(() => {
    if (localImageUrl) {
      dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: localImageUrl });
    }
  }, [localImageUrl]);

  const checkImage = (url) => {
    const image = new Image();

    image.onload = () => {
      // image exists and is loaded
      dispatch({ type: 'VALID_IMAGE', value: '' });
      dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: url });
    };
    image.onerror = () => {
      // image did not load
      dispatch({ type: 'VALID_IMAGE', value: 'Invalid Image' });
    };

    image.src = url;
  };

  return (
    <div>
      <Modal size="tiny" open={open} onClose={onClose} className="responsive-modal">
        <Modal.Header>{!noUrl ? 'Enter Image Url/ Upload Image' : 'Upload Image'}</Modal.Header>
        <ModalContent height={100}>
          <ModalInput>
            {!noUrl && (
              <>
                <Input
                  ref={modalInputRef}
                  value={imageUrl}
                  onChange={(e) => {
                    dispatch({ type: 'SET_IMAGE_URL', value: e.target.value });
                    checkImage(e.target.value);
                    // dispatch({ type: 'SET_IMAGE_URL', value: e.target.value });
                  }}
                />
                <Separator>OR</Separator>
              </>
            )}
            <Dropzone
              onDrop={(acceptedFiles) => {
                dispatch({ type: 'VALID_IMAGE', value: '' });
                dispatch({ type: 'SET_ORIGINAL_IMAGE_URL', value: acceptedFiles[0] });
              }}
              accept="image/jpeg, image/png"
            >
              {({ getRootProps, getInputProps }) => (
                <>
                  {/* eslint-disable react/jsx-props-no-spreading */}
                  <DropzoneWrapper {...getRootProps()}>
                    <input {...getInputProps()} />
                    <DropzoneContent>
                      <ImageWrapper>
                        <ImageContainer>
                          {originalImageUrl && (
                            <LogoImg
                              src={
                                typeof originalImageUrl === 'object'
                                  ? window.URL.createObjectURL(originalImageUrl)
                                  : originalImageUrl
                              }
                              alt="preview"
                            />
                          )}
                        </ImageContainer>
                      </ImageWrapper>
                      <CamIcon name="camera" size="large" />
                    </DropzoneContent>
                  </DropzoneWrapper>
                </>
              )}
            </Dropzone>
            <FieldErrorMessage>{validImage}</FieldErrorMessage>
          </ModalInput>
        </ModalContent>
        <Modal.Actions>
          <Button negative onClick={onClose} disabled={storeCropLoading}>
            Cancel
          </Button>
          <Button
            disabled={!(originalImageUrl && !validImage)}
            loading={storeCropLoading}
            positive
            content="Save"
            onClick={() => {
              onSave(originalImageUrl);
            }}
          />
        </Modal.Actions>
      </Modal>
    </div>
  );
};

UploadImage.propTypes = {
  open: PropTypes.func,
  modalInputRef: PropTypes.instanceOf,
  onClose: PropTypes.func,
  onSave: PropTypes.func,
  localImageUrl: PropTypes.string,
};

export default UploadImage;
