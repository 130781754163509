import React, { useReducer, useEffect, useCallback, useRef } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Loader, Input, Button, Icon, TextArea, Dropdown, Accordion } from 'semantic-ui-react';
import 'antd/dist/antd.css';
import 'antd/es/date-picker/style/css';
import { TimePicker } from 'antd';
import Chips from 'react-chips';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import TableView from '../../../../Components/TableView';
import TranscriptList from '../TranscriptList';
import { convertSecondsToTimeFormat } from 'Utils/convertTime';
const SegmentFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const FieldTitle = styled.div`
  width: 150px;
`;

const SegmentFieldWrapper = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  .ant-time-picker-clear {
    display: none;
  }
`;

const TitleInput = styled(Input)`
  width: 128px;
`;

const TitleTextarea = styled(TextArea)`
  width: 250px;
  max-height: 100px;
  resize: none;
  padding: 5px;
`;
const SegmentHeader = styled.div`
  text-align: center;
  font-size: 18px;
  font-weight: 900;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
  button {
    width: 100%;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
`;

const SegmentListWrapper = styled.div`
  margin-top: 30px;
`;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 0 5px;
`;

const ErrorMessage = styled.div`
  color: #cb3837;
  margin-top: 10px;
`;

const ButtonsContainer = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleTab = styled(Accordion.Title)`
  box-shadow: #d8d8d8 0px 0px 10px -2px;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  background-color: ${props => (props.active ? '#f6f6f6': '#ffffff')};
  font-weight: ${props => (props.active ? 900 : 500 )};
  z-index: 2;
  border-radius: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #e9e9e9;
  border-image: initial;
  border-left: ${props => (props.active ? '8px solid #f32826' : '1px solid #e9e9e9')};
  overflow: hidden;
  padding: 20px 10px 20px 14px !important;
`;

const Tag = styled.div`
  width: 115px;
  border-bottom-left-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 10px;
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: ${params => (params.mustWatch ? '#f32826' : '#3bb300' )};
  color: #ffffff;
  text-align: center;
  padding: 2px 5px 3px;
  text-align: center;
  text-transform: uppercase;
  padding: 2px 5px 3px;
`;

const Time = styled.div`
  font-size: 12px;
  color: ${props => (props.active ? '#00000' : '#b0b0b0 !important')};
`;

const AccordionPanel = styled.div`
  margin-bottom: 25px;
  position: relative;
`;

const AccordionContent = styled(Accordion.Content)`
  display: block;
  background-color: rgb(255, 255, 255);
  max-height: 200px;
  position: relative;
  padding: 10px !important;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(233, 233, 233);
  border-image: initial;
  overflow: auto;
  margin: -4px auto 0px;
`;

const AccordionIcon = styled(Icon)`
  color: #f32826;
  font-size: 14px !important;
  font-weight: bolder !important;
`;

const CopyAutoSummaryButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;
const CopyAutoSummaryButton = styled(Button)`
  width: 195px;;
  background-color: #f32826 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 900 !important;
  cursor: pointer;
`; 

const ChipWrapper = styled.div`
  background-color: #f32826;
  padding: 3px 20px 3px 5px;
  margin: 3px;
  color: #fff;
  border-radius: 13px;
  font-weight: 700;
  position: relative;
`;

const ChipCloseIcon = styled.div`
  background-color: #fff;
  padding: 1px 2px;
  position: absolute;
  top: 1.26px;
  right: 2px;
  border-radius: 50%;
  cursor: pointer;
  i {
    color: #f32826;
    margin: 0;
  }
`;


const SegmentColumn = ({
  segmentList,
  onSegmentSave,
  onChangeActiveSegment,
  onChangeTranscriptSegment,
  activeSegment,
  segmentError,
  onClickSegment,
  removeSegment,
  activeSegmentIndex,
  videoSummary,
  loading,
  disabledEndHours,
  disabledEndMinutes,
  disabledEndSeconds,
  disabledHours,
  disabledMinutes,
  disabledSeconds,
  previewMode,
  videoRef,
  activeTranscript,
  videoTranscriptData,
  subtitleMode,
  onHandleClickAutoSummary,
  autoSummary,
  title,
  digest,
  tag,
  categories,
  onChangeTranscript,
  onChangeCategoriesOption,
  fetchSuggestions,
  hanldeAddTags,
  fetchTagSuggestions,
}) => {

  const dragSelectRef = useRef(null);
  /* eslint-disable no-param-reassign, default-case */
  const reducer = (state, action) =>
    produce(state, draft => {
      switch(action.type) {
        case 'SET_ACTIVE':
          if (draft.active !== action.tab) {
            draft.active = action.tab;
            break;
          }
      }
    });

  const columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      key: 'index',
      render: (value, row, index) => <span>{index + 1}</span>,
      width: 50,
    },
    {
      title: 'Segment Title',
      dataIndex: 'title',
      key: 'title',
      width: 200,
    },
    {
      title: 'Start Time',
      dataIndex: 'timestamp_start',
      key: 'startTime',
      width: 150,
    },
    {
      title: 'End Time',
      dataIndex: 'timestamp_end',
      key: 'endTime',
      width: 150,
    },
    {
      title: 'Tag',
      dataIndex: 'watch_label',
      key: 'watchLabel',
      width: 150,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: 150,
    },
    {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      fixed: 'right',
      onCell: () => ({
        onClick: (e) => {
          e.stopPropagation();
        },
      }),
      render: (value, data) => (
        <div className="action-wrapper">
          <ActionWrapper>
            <IconWrapper
              onClick={() => {
                removeSegment(data);
              }}
            >
              <Icon name="archive" size="large" />
            </IconWrapper>
          </ActionWrapper>
        </div>
      ),
    },
  ];

  const [state, dispatch] = useReducer(reducer, {
    active: 0,
  });

  const { active } = state;


  const playVideo = useCallback((interval, videoState) => {
    if (!videoState && segmentList[active] && videoRef.current) {
      const timeStart = segmentList[active].timestamp_start.split(':'); 
      const seconds = (+timeStart[0]) * 60 * 60 + (+timeStart[1]) * 60 + (+timeStart[2]);
      const timeEnd = segmentList[active].timestamp_end.split(':'); 
      const secondsEnd = (((+timeEnd[0]) * 60 * 60 + (+timeEnd[1]) * 60 + (+timeEnd[2])));
      videoRef.current.seekTo(parseFloat(seconds));
      interval = setInterval(() => {
        let time = videoRef.current.getCurrentTime();
        if ( parseInt(time, 10) === secondsEnd) {
          let activeTab = active === segmentList.length - 1 ? 0 : active + 1;
          dispatch({ type: 'SET_ACTIVE', tab: activeTab });
        }
      }, 1000);
    } else clearInterval(interval);
  }, [videoRef, active, segmentList])

 useEffect(() => {
  let interval;
  if (previewMode) {
     playVideo(interval);
  } else if (videoRef.current) {
    playVideo(interval, 'pause');
  }
 }, [previewMode, playVideo, videoRef]);

  useEffect(() => {
    if (dragSelectRef.current) {
      dragSelectRef.current.clearSelection();
    }
  }, [segmentList])
  const setActiveTab = tab => {
    if (tab !== active) {
      dispatch({ type: 'SET_ACTIVE', tab });
    }
  }

  const blurMilliSecs = time => {
    if (time === 'timestamp_start_ms') {
      let timeStart = activeSegment.timestamp_start;
      let start = timeStart || "00:00:00:00";
      let time = start.split(':');
      if (!time[3]) {
        time[3] = 0;
        onChangeActiveSegment({ type: 'timestamp_start_ms', value: `${time[0]}:${time[1]}:${time[2]}:${time[3]}`});
      }
    }
  }

  return (
    loading ?
      (
        <Loader active={loading} />
      )
    :(
      <div>
      <Helmet>
        <title>{title ? title : 'XND'}</title>
      </Helmet>
        {subtitleMode && (
          <TranscriptList 
            activeSegment={activeSegment}
            dragSelectRef={dragSelectRef}
            activeTranscript={activeTranscript}
            videoTranscriptData={videoTranscriptData}
            onChangeTranscript={onChangeTranscript}
            onSelectTranscript={value => {

              let currentSegmentStartTime = null;
              let currentSegmentEndTime = null;
              if(value.length === 1) {
                currentSegmentStartTime = videoTranscriptData[value[0]].start;
                currentSegmentEndTime = (videoTranscriptData[value[0]].start + videoTranscriptData[value[0]].duration).toFixed(2);
              } else if(value.length > 1) {
                currentSegmentStartTime = videoTranscriptData[value[0]].start;
                currentSegmentEndTime = (videoTranscriptData[value[value.length - 1]].start + videoTranscriptData[value[value.length - 1]].duration).toFixed(2);
              }

              currentSegmentStartTime = convertSecondsToTimeFormat(currentSegmentStartTime);
              currentSegmentEndTime = convertSecondsToTimeFormat(currentSegmentEndTime);

              currentSegmentStartTime = moment(`${moment().format('DD:MM:YY')} ${currentSegmentStartTime}`, 'DD:MM:YY HH:mm:ss:SS');
              currentSegmentEndTime = moment(`${moment().format('DD:MM:YY')} ${currentSegmentEndTime}`, 'DD:MM:YY HH:mm:ss:SS');
              onChangeTranscriptSegment({ start: currentSegmentStartTime, end: currentSegmentEndTime })
            }}
          />
        )}
        { !previewMode ? (
          <div>
            <SegmentFormWrapper>
              <SegmentHeader>{`Segment ${activeSegmentIndex}`}</SegmentHeader>
              <SegmentFieldWrapper>
                <FieldTitle>Digest Title</FieldTitle>
                <TitleInput 
                  onChange={e => onChangeActiveSegment({ type: 'dtitle', value: e.target.value })}
                  value={title}
                />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>Content</FieldTitle>
                <TitleTextarea 
                  onChange={e => onChangeActiveSegment({ type: 'digest', value: e.target.value })}
                  value={digest}
                />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>Select Category</FieldTitle>
                <Chips
                  value={JSON.parse(JSON.stringify(categories))}
                  onChange={onChangeCategoriesOption}
                  fetchSuggestions={fetchSuggestions}
                  alwaysRenderSuggestions={true}
                  createChipKeys={[13]}
                  renderChip={chipData => (
                    <ChipWrapper>
                      {chipData.title || chipData}
                      <ChipCloseIcon
                        onClick={() => {
                          let index;
                          if (chipData.id) index = categories.findIndex(tag => tag.id === chipData.id);
                          else index = categories.findIndex(tag => tag === chipData);
                          const tagsData = JSON.parse(JSON.stringify(categories));
                          tagsData.splice(index, 1);
                          onChangeCategoriesOption(tagsData, true);
                        }}
                      >
                        <Icon size="small" name="close" />
                      </ChipCloseIcon>
                    </ChipWrapper>
                  )}
                  renderSuggestion={data => <span>{data.title}</span>}
                  suggestionsFilter={(opt, val) => {
                    return categories.findIndex(tag => tag.id === opt.id) === -1;
                  }}
                />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>Digest Tags</FieldTitle>
                <Chips
                  value={JSON.parse(JSON.stringify(tag))}
                  onChange={hanldeAddTags}
                  fetchSuggestions={fetchTagSuggestions}
                  alwaysRenderSuggestions={true}
                  createChipKeys={[13]}
                  renderChip={chipData => (
                    <ChipWrapper>
                      {chipData.title || chipData}
                      <ChipCloseIcon
                        onClick={() => {
                          let index;
                          if (chipData.id) index = tag.findIndex(tag => tag.id === chipData.id);
                          else index = tag.findIndex(tag => tag === chipData);
                          const tagsData = JSON.parse(JSON.stringify(tag));
                          tagsData.splice(index, 1);
                          hanldeAddTags(tagsData);
                        }}
                      >
                        <Icon size="small" name="close"/>
                      </ChipCloseIcon>
                    </ChipWrapper>
                  )}
                  renderSuggestion={data => <span>{data.title}</span>}
                  suggestionsFilter={(opt, val) => {
                    return tag.findIndex(tag => tag.id === opt.id) === -1;
                  }}
                />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>Segment Title</FieldTitle>
                <TitleInput 
                  onChange={e => onChangeActiveSegment({ type: 'title', value: e.target.value })}
                  value={activeSegment.title}
                />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>Start Time</FieldTitle>
                <TimePicker
                  disabledHours={disabledHours}
                  disabledMinutes={disabledMinutes}
                  disabledSeconds={disabledSeconds}
                  onChange={value => onChangeActiveSegment({ type: 'timestamp_start', value })}
                  value={activeSegment.timestamp_start ? moment(`${moment().format('DD:MM:YY')} ${activeSegment.timestamp_start}`, 'DD:MM:YY HH:mm:ss') : moment('00:00:00', 'HH:mm:ss')}
                />
                <Input
                  type="number"
                  min="0"
                  max="99"
                  onBlur={e => blurMilliSecs('timestamp_start_ms')}
                  onChange={e => onChangeActiveSegment({ type: 'timestamp_start_ms', value: e.target.value })}
                  value={activeSegment.timestamp_start ?  activeSegment.timestamp_start.split(':')[3]: '00'} />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>End Time</FieldTitle>
                <TimePicker
                  disabledHours={disabledEndHours}
                  disabledMinutes={disabledEndMinutes}
                  disabledSeconds={disabledEndSeconds}
                  onChange={value => onChangeActiveSegment({ type: 'timestamp_end', value })}
                  value={activeSegment.timestamp_end ? moment(`${moment().format('DD:MM:YY')} ${activeSegment.timestamp_end}`, 'DD:MM:YY HH:mm:ss') : moment('00:00:00', 'HH:mm:ss')}
                />
                
                <Input
                  type="number"
                  min="0"
                  max="99"
                  onChange={e => onChangeActiveSegment({ type: 'timestamp_end_ms', value: e.target.value })}
                  value={activeSegment.timestamp_end ?  activeSegment.timestamp_end.split(':')[3]: '00'} />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>Description</FieldTitle>
                <TitleTextarea
                  onChange={e => onChangeActiveSegment({ type: 'description', value: e.target.value })}
                  value={activeSegment.description}
                />
              </SegmentFieldWrapper>
              <SegmentFieldWrapper>
                <FieldTitle>Tag</FieldTitle>
                <Dropdown
                  placeholder='Select Tag'
                  fluid
                  selection
                  options={videoSummary}
                  onChange={(event, data) => {
                    onChangeActiveSegment({ type: 'video_summary_tag', value: data.value });
                  }}
                  value={activeSegment.watch_label}
                />
              </SegmentFieldWrapper>
              <ErrorMessage>{segmentError}</ErrorMessage>
              <ButtonsContainer>
                <ButtonWrapper>
                  <Button onClick={onSegmentSave}>Save</Button>
                </ButtonWrapper>
                <ButtonWrapper>
                <Button
                  onClick={() => {
                    if (dragSelectRef.current) {
                      dragSelectRef.current.clearSelection();
                    }
                    onClickSegment({
                      title: '',
                      timestamp_start: null,
                      timestamp_end: null,
                      description: '',
                    }, true)
                  }}
                >Clear</Button>
                </ButtonWrapper>
              </ButtonsContainer>
            </SegmentFormWrapper>
            <SegmentListWrapper>
              {autoSummary && autoSummary.length > 0 && (
                <CopyAutoSummaryButtonWrapper>
                  <CopyAutoSummaryButton
                    onClick={onHandleClickAutoSummary}
                  >
                    Copy auto summary
                  </CopyAutoSummaryButton>
                </CopyAutoSummaryButtonWrapper>
              )}
              <TableView
                tableColumns={columns}
                data={segmentList || []}
                pagination={true}
                totalItems={0}
                scroll={{x: false, y: false}}
                onRow={record => ({
                  onClick: (e) => {
                    onClickSegment(record);
                  },
                })}
              />
            </SegmentListWrapper>
          </div>
         ) : (
          <Accordion fluid exclusive={false}>
           {segmentList.map((segment, index) => {
             const activeSegment = active === index;
             let timeStart = segment.timestamp_start;
             let timeEnd;
             if (timeStart.indexOf('00') === 0) {
               timeStart = timeStart.substr(3,5);
               timeEnd = segment.timestamp_end.substr(3,5);
             } else {
              timeStart = timeStart.substr(0,8);
              timeEnd = segment.timestamp_end.substr(0,8);
             }
             return(
            <AccordionPanel key={segment.id} onClick={() => setActiveTab(index)}>
              <TitleTab active={activeSegment} index={segment.segment_id}>
                <AccordionIcon name={activeSegment ? 'chevron up' : 'chevron down'} active={activeSegment}/>
                  {segment.title}
                  {segment.watch_label && <Tag mustWatch={segment.watch_label === "Must Watch"}>{segment.watch_label}</Tag>}
                  <Time active={activeSegment}>{timeStart} - {timeEnd}</Time>
              </TitleTab>
              {segment.description && (
                <AccordionContent active={activeSegment}>
                <p>{segment.description}</p>
                </AccordionContent>
              )}
              </AccordionPanel>
             )})}
           </Accordion>
         )}
      </div>
      )
  );
};

export default SegmentColumn;
