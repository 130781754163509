import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { saveDigestVideo } from 'Api';
import { Helmet } from 'react-helmet';
import { Button, Container, Dropdown, Input, TextArea } from 'semantic-ui-react';
import styled from 'styled-components';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import PageHeader from '../../Components/Header';
import Toggle from '../../Components/Form/Toggle';

const Wrapper = styled(Container)`
  display: flex !important;
  flex-direction: column;
  margin-left: 50px;
  padding-top: 100px;
`;

const PageTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
`;

const FormFieldWrapper = styled.div`
  display: block;
  align-items: center;
  width: 100%;
  .ui.fluid.dropdown {
    width: 90%;
  }
`;

const FormFieldWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const FormFieldTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 100px;
`;

const FormFieldValue = styled.div`
  //   width: 30%;
`;

// const FormIcon = styled.div`
//   display: flex;
//   gap: 15px;
//   margin-top: 40px;
//   i {
//     font-size: 30px;
//   }
// `;

const InputWrapper = styled.div``;

const InputField = styled(Input)`
  width: 90%;
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

const TextareaFiled = styled(TextArea)`
  width: 100%;
  outline: none;
  border-radius: 4px;
  padding: 10px;
`;

const Flex = styled.div`
  display: block;
  align-items: center;
  width: 100%;
  text-align: start;
`;

const ToggleWrapper = styled.div`
  text-align: center;
  span {
    margin-right: 5px;
    justify-content: center;
    margin: 0px;
  }
`;

const FormFieldBox = styled.div`
  border: 1px solid #c9c9c9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.175) !important;
  background-color: #ffff;
  margin-bottom: 15px;
`;

const Show404 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const VideoEdit = ({ location, match: { params } }) => {
  const [addPadding, setAddPadding] = useState(true);
  const [addSubtitle, setAddSubtitle] = useState(false);
  const [cropHeightWidth, setCropHeightWidth] = useState({ height: null, width: null });
  const [generatedURL, setGeneratedURL] = useState(null);
  const [mp4, setMP4] = useState('');
  const [isVideo, setIsVideo] = useState(null);
  const [key, setKey] = useState(0);

  const [borders, setBorderes] = useState({ width: null, colour: null });

  const [boxes, setBoxes] = useState([]);
  const [selectScreen, setSelectScreen] = useState('');
  const [selectGauto, setSelectGauto] = useState('');
  const [customGauto, setCustomGauto] = useState('');
  const [cropSelect, setCropSelect] = useState('');
  const [selectFontType, setSelectFontType] = useState('');
  const [selectFontSize, setSelectFontSize] = useState();
  const [customFontType, setCustomFontType] = useState('');
  const [customFontSize, setCustomFontSize] = useState();
  // const [textPosition, setTextPosition] = useState('');
  const [gAutoOptions, setGAutoOptions] = useState([
    { text: 'Face', value: 'face' },
    { text: 'Faces', value: 'faces' },
    { text: 'Add custom', value: 'add_custom' },
  ]);
  const keysArray = [
    { text: 'Center', value: 'center' },
    { text: 'South', value: 'south' },
    { text: 'North', value: 'north' },
    { text: 'East', value: 'east' },
    { text: 'West', value: 'west' },
    { text: 'North West', value: 'north_west' },
    { text: 'South East', value: 'south_east' },
    { text: 'North East', value: 'north_east' },
    { text: 'South West', value: 'south_west' },
  ];
  const screenOptions = [
    { text: 'Landscape', value: { height: '720', width: '1280' } },
    { text: 'Square', value: { height: '720', width: '720' } },
    { text: 'Large Portrait', value: { height: '1280', width: '720' } },
    { text: 'Small Portrait', value: { height: '960', width: '720' } },
  ];

  const cropOptions = [
    { text: 'C_Crop', value: 'c_crop' },
    { text: 'C_Fill', value: 'c_fill' },
  ];

  const [fontTypeOptions, setFontTypeOptions] = useState([
    { text: 'Helvetica', value: 'Helvetica' },
    { text: 'Montserrat', value: 'Montserrat' },
    { text: 'Poppins', value: 'Poppins' },
    { text: 'Oswald', value: 'Oswald' },
    { text: 'Verdana', value: 'Verdana' },
    { text: 'Add custom', value: 'add_custom' },
  ]);

  const [fontSizeOptions, setFontSizeOptions] = useState([
    { text: '60', value: 60 },
    { text: '65', value: 65 },
    { text: 'Add custom', value: 'add_custom' },
  ]);

  const textPositioningOption = [
    { text: '0.01', value: 0.01 },
    { text: '0.02', value: 0.02 },
    { text: '0.03', value: 0.03 },
    { text: '0.04', value: 0.04 },
    { text: '0.05', value: 0.05 },
    { text: '0.06', value: 0.06 },
    { text: '0.07', value: 0.07 },
    { text: '0.08', value: 0.08 },
    { text: '0.09', value: 0.09 },
    { text: '0.1', value: 0.1 }
  ];

  const handleDropdownChange = (e, { value }) => {
    setSelectGauto(value);
  };

  const handleCustomValueChange = (e) => {
    if (e.target.value !== '') {
      setCustomGauto(e.target.value);
    }
  };

  const handleAddCustomOption = () => {
    if (customGauto && customGauto !== 'add_custom') {
      setGAutoOptions((prev) => [
        ...prev,
        { text: customGauto.toLocaleUpperCase(), value: customGauto.toString() },
      ]);
      setSelectGauto(customGauto);
      setCustomGauto('');
    }
  };

  const handleFontType = (e, props, index) => {
    handleBoxChange(props, index, 'fontType');
    const { value } = props;
    setSelectFontType(value);
  }

  const handleCustomFontTypeChange = (e) => {
    if (e.target.value !== '') {
      setCustomFontType(e.target.value);
    }
  };

  const handleAddCustomFontTypeOption = (index) => {
    if (customFontType && customFontType !== 'add_custom') {
      setFontTypeOptions((prev) => [
        ...prev,
        { text: customFontType, value: customFontType.toString() },
      ]);
      setSelectFontType(customFontType);
      setCustomFontType('');
      handleBoxChange(customFontType, index, 'fontType');
    }
  };

  const handleFontSize = (e, props, index) => {
    handleBoxChange(props, index, 'fontSize')
    const { value } = props;
    setSelectFontSize(value)
  }

  const handleCustomFontSizeChange = (e) => {
    if (e.target.value !== '') {
      setCustomFontSize(e.target.value);
    }
  };

  const handleAddCustomFontSizeOption = (index) => {
    if (customFontSize && customFontSize !== 'add_custom') {
      setFontSizeOptions((prev) => [
        ...prev,
        { text: customFontSize.toString(), value: customFontSize },
      ]);
      setSelectFontSize(customFontSize);
      setCustomFontSize('');
      handleBoxChange(customFontSize, index, 'fontSize');
    }
  };

  const handleTextPosition = (e, props, index) => {
    handleBoxChange(props, index, 'fontPosition')
    // const { value } = props;
    // setTextPosition(value);
  }

  const handleChangeCropHeightWidth = (e, type) => {
    let value = e.target.value;

    if (type === 'height') {
      setCropHeightWidth({ height: value, width: cropHeightWidth.width });
      // width = (height / targetAspectRatio).toFixed(0);
    }
    if (type === 'width') {
      setCropHeightWidth({ width: value, height: cropHeightWidth.height });
    }
  };

  const generateTransformationURL = () => {
    const parts = [];

    if (cropHeightWidth.height || cropHeightWidth.width) {
      if (cropSelect === 'c_crop') {
        parts.push(
          `c_crop,${cropHeightWidth.height && `h_${cropHeightWidth.height},`}${cropHeightWidth.width && `w_${cropHeightWidth.width}`
          }`,
        );
      } else {
        parts.push(
          `c_fill,g_auto:${selectGauto},${cropHeightWidth.height && `h_${cropHeightWidth.height},`}${cropHeightWidth.width && `w_${cropHeightWidth.width}`
          }`,
        );
      }
      if (addPadding) {
        parts.push('b_blurred:1000,c_pad,h_1920,w_1080');
        parts.push('e_fade:-2000');
      }
    }

    if (borders.colour) {
      const modifiedColor = borders?.colour?.replace('#', ':');
      parts.push(`bo_${borders.width || 2}px_solid_rgb${modifiedColor}`);
    }

    if (generatedURL != null) {
      parts.push(generatedURL);
    }

    return parts.join('/');
  };
  const url = generateTransformationURL();
  const cleanedURL = url?.replace(/^[/,]+|[/,]+$/g, '');

  const handlePreview = () => {
    const videoURL = `https://res.cloudinary.com/dxk35ktti/video/upload/${cleanedURL}/v1690448380/${params.id}`;
    setMP4(`${videoURL}.mp4`);
    setKey((prevKey) => prevKey + 1); // Increment key to force remount
    fetch(videoURL).then((res) => {
      if (res.status === 404) {
        setIsVideo(res.status);
      }
    }).catch((error) => {
      console.log('error :>> ', error);
    })
  };

  const saveVideoToDigest = () => {
    const cloudinary = {
      cloudinary: { transform: cleanedURL }
    };
    saveDigestVideo(params.id, cloudinary)
      .then((res) => {
        ToastsStore.success('Digest saved successfully');
      })
      .catch((error) => {
        console.error(error);
        ToastsStore.error('Something went wrong. Please try again');
      });
  };

  const removeBox = (index) => {
    const updatedBoxes = boxes.filter((_, i) => i !== index);
    setBoxes(updatedBoxes);
  };

  const handleBoxChange = (e, index, property) => {
    const updatedBoxes = [...JSON.parse(JSON.stringify(boxes))];
    let value;
    const position =
      property === 'g_textPosition'
        ? keysArray.find((i) => i.value === value)?.text
        : e?.target?.getAttribute('data-position');

    if (keysArray.find((i) => i.value === value)?.text) {
      value = null;
    }

    if (e.target) {
      value = e.target.value;
    } else if (e.value) {
      value = e.value;
    } else {
      value = e;
    }

    if (value && position) {
      updatedBoxes[index][property] = `${value} ${position}`;
    } else if (value) {
      updatedBoxes[index][property] = value;
    } else if (position) {
      updatedBoxes[index][property] = position;
    }
    setBoxes(updatedBoxes);
  };

  const addBox = () => {
    const newBox = {
      text: '',
      fontType: '',
      g_textPosition: '',
      color: '',
      fontSize: '',
      s_timestamp: '',
      e_timestamp: '',
      textPosition: '',
      fontPosition: '',
    };
    setBoxes([...boxes, newBox]);
  };

  const createTextURL = () => {
    const concatenatedURLs = boxes.map((box) => {
      const urlParts = [];

      if (box.color) {
        const modifiedColor = box.color.replace('#', ':');
        urlParts.push(`co_rgb${modifiedColor || 'black'},`);
      }
      if (box.text) {
        const modifiedText = encodeURIComponent(encodeURIComponent(box.text));
        urlParts.push(
          `c_fit,h_480,w_960,l_text:${box.fontType || 'Arial'}_${box.fontSize || 25}_bold_${box.textPosition || 'center'
          }:${modifiedText}/`,
        );
      }
      if (box.g_textPosition) {
        urlParts.push(`fl_layer_apply,g_${box.g_textPosition || 'center'},y_${box.fontPosition || 0.1},`);
      }
      if (box.e_timestamp) {
        const decodedTimestamp = decodeURIComponent(box.e_timestamp);
        urlParts.push(`eo_${decodedTimestamp},`);
      }

      if (box.s_timestamp) {
        const decodedTimestamp = decodeURIComponent(box.s_timestamp);
        urlParts.push(`so_${decodedTimestamp}`);
      }

      if (urlParts.length > 0) {
        return urlParts;
      } else {
        return null;
      }
    });
    let multipleText = '';

    const concatenatedURL = concatenatedURLs.map(
      (arrayD) => (multipleText += arrayD?.filter((url) => url !== null)?.join('') + '/'),
    );
    setGeneratedURL(multipleText);
    console.log(concatenatedURL, multipleText, 'concatenatedURLconcatenatedURLconcatenatedURL');
    alert('Data added');
  };

  return (
    <>
      <PageHeader location={location} />
      <Helmet>
        <title>XND | Edit Video</title>
      </Helmet>
      <Wrapper>
        <PageTitle>Video Edit</PageTitle>
        <FormFieldWrap>
          <FormFieldWrapper>
            <FormFieldTitle>Resolution</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField placeholder="Fixed 9:16" value="Fixed 9:16" disabled />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormFieldTitle>Crop</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <Dropdown
                  placeholder="Select Option"
                  fluid
                  value={cropSelect}
                  search
                  selection
                  onChange={(e, props) => {
                    setCropSelect(props.value);
                  }}
                  options={cropOptions}
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
          <FormFieldWrapper></FormFieldWrapper>
        </FormFieldWrap>
        <FormFieldWrapper>
          <FormFieldTitle>G Auto</FormFieldTitle>
          <FormFieldValue>
            <InputWrapper>
              <Dropdown
                placeholder="Select G_Auto"
                fluid
                value={selectGauto}
                search
                selection
                options={gAutoOptions}
                onChange={handleDropdownChange}
              />
              {selectGauto === 'add_custom' && (
                <>
                  <Input
                    placeholder="Enter custom value"
                    type="text"
                    onChange={handleCustomValueChange}
                  />
                  <Button onClick={handleAddCustomOption}>Add Custom Option</Button>
                </>
              )}
            </InputWrapper>
          </FormFieldValue>
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormFieldTitle>Screen</FormFieldTitle>
          <FormFieldValue>
            <InputWrapper>
              <Dropdown
                placeholder="Select Screen"
                fluid
                value={selectScreen}
                search
                selection
                onChange={(e, props) => {
                  setSelectScreen(props.value);
                  setCropHeightWidth(props.value);
                }}
                options={screenOptions}
              />
            </InputWrapper>
          </FormFieldValue>
        </FormFieldWrapper>
        <FormFieldWrap>
          <FormFieldWrapper>
            <FormFieldTitle>Crop</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField placeholder="Fixed-Faces" value="Fixed-Faces" disabled />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormFieldTitle>Height</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField
                  placeholder="Height"
                  type="number"
                  onChange={(e) => handleChangeCropHeightWidth(e, 'height')}
                  value={cropHeightWidth.height || ''}
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormFieldTitle>Width</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField
                  placeholder="Width"
                  type="number"
                  onChange={(e) => handleChangeCropHeightWidth(e, 'width')}
                  value={cropHeightWidth.width || ''}
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
        </FormFieldWrap>
        {console.log(borders, 'bors=>>')}
        <FormFieldWrap>
          <FormFieldWrapper>
            <FormFieldTitle>Borders</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField
                  placeholder="Enter Borders Pixels"
                  onChange={(e) =>
                    setBorderes({ width: Number(e.target.value), colour: borders.colour })
                  }
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>

          <FormFieldWrapper>
            <FormFieldTitle>Enter color of Border</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField
                  placeholder="Select Colour"
                  type="color"
                  onChange={(e) => setBorderes({ width: borders.width, colour: e.target.value })}
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
        </FormFieldWrap>

        <FormFieldWrap>
          <FormFieldWrapper>
            <FormFieldTitle>Padding</FormFieldTitle>
            <ToggleWrapper>
              <Flex>
                <Toggle on={addPadding} onClick={() => setAddPadding(!addPadding)} />
              </Flex>
            </ToggleWrapper>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormFieldTitle>Padding Ratio</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField
                  placeholder="FIXED-(1080*1920)"
                  value="FIXED-(1080*1920)"
                  disabled
                  onChange={(e) => console.log('e', e.target.value)}
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormFieldTitle>Padding Background</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField
                  placeholder="FIXED-BLUR(1000)"
                  value="FIXED-BLUR(1000)"
                  disabled
                  onChange={(e) => console.log('e', e.target.value)}
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
        </FormFieldWrap>
        {/* <>
          <div>
            {formData.map((item, index) => (
              <div key={index}>
                <InputField
                  placeholder={item.key}
                  value={item.value}
                  onChange={(e) => updateFormData(item.key, e.target.value, index)}
                />
                <Button onClick={() => removePair(index)}>Remove</Button>
              </div>
            ))}
            <Button onClick={() => addPair()}>ADD</Button>
            <Button onClick={() => createURL()}>CREATE URL</Button>
          </div>
        </> */}
        {/* <FormFieldBox>
          <FormFieldWrap>
            <FormFieldWrapper>
              <FormFieldTitle>TextArea</FormFieldTitle>
              <TextareaFiled
                placeholder="Enter text here"
                onChange={(e) => handleTextOnVideoChange(e, 'text')}
              ></TextareaFiled>
            </FormFieldWrapper>
          </FormFieldWrap>
          <FormFieldWrap>
            <FormFieldWrapper>
              <FormFieldTitle>Select Font type</FormFieldTitle>
              <Dropdown
                placeholder="Select Font type"
                fluid
                // value={selectedAuthors}
                multiple
                search
                selection
                onChange={(e, props) => console.log('dropdown change => ', props.value)}
                options={[]}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormFieldTitle>Select Position</FormFieldTitle>
              <Dropdown
                placeholder="Select Position"
                fluid
                // value={selectedAuthors}
                search
                selection
                onChange={(e, props) => handleTextOnVideoChange(props, 'g_textPosition')}
                options={keysArray}
              />
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormFieldTitle>Enter Font Color</FormFieldTitle>
              <FormFieldValue>
                <InputWrapper>
                  <InputField
                    placeholder="Select Colour"
                    type="color"
                    onChange={(e) => handleTextOnVideoChange(e, 'color')}
                  />
                </InputWrapper>
              </FormFieldValue>
            </FormFieldWrapper>
          </FormFieldWrap>
          <FormFieldWrap>
            <FormFieldWrapper>
              <FormFieldTitle>Enter Font Size</FormFieldTitle>
              <FormFieldValue>
                <InputWrapper>
                  <InputField
                    placeholder="Enter Font Size"
                    onChange={(e) => handleTextOnVideoChange(e, 'fontSize')}
                  />
                </InputWrapper>
              </FormFieldValue>
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormFieldTitle>Enter Timestamp</FormFieldTitle>
              <FormFieldValue>
                <InputWrapper>
                  <InputField
                    placeholder="Enter Starting Timestamp"
                    onChange={(e) => handleTextOnVideoChange(e, 's_timestamp')}
                  />
                </InputWrapper>
              </FormFieldValue>
              <br />
              <FormFieldValue>
                <InputWrapper>
                  <InputField
                    placeholder="Enter Ending Timestamp"
                    onChange={(e) => handleTextOnVideoChange(e, 'e_timestamp')}
                  />
                </InputWrapper>
              </FormFieldValue>
            </FormFieldWrapper>
            <FormFieldWrapper>
              <FormFieldWrapper>
                <FormFieldValue>
                  <FormIcon
                    onClick={(e) => handleTextOnVideoChange(e, 'textPosition')}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className="align left icon" data-position="left"></i>
                    <i className="align center icon" data-position="center"></i>
                    <i className="align right icon" data-position="right"></i>
                    <i className="align justify icon" data-position="justify"></i>
                  </FormIcon>
                </FormFieldValue>
              </FormFieldWrapper>
            </FormFieldWrapper>
          </FormFieldWrap>
          <Button onClick={() => createTextURL()}>Add Text</Button>
          {/* <Button onClick={addBox}>+</Button>
          <Button onClick={addBox}>Remove</Button> */}
        {/* </FormFieldBox> */}
        {/* //////nishi
         */}
        <div>
          {boxes.map((box, index) => (
            <div key={index}>
              <FormFieldBox>
                <FormFieldWrap>
                  <FormFieldWrapper>
                    <FormFieldTitle>TextArea</FormFieldTitle>
                    <TextareaFiled
                      value={box.text}
                      placeholder="Enter text here"
                      onChange={(e) => handleBoxChange(e, index, 'text')}
                    ></TextareaFiled>
                  </FormFieldWrapper>
                </FormFieldWrap>
                <FormFieldWrap>
                  <FormFieldWrapper>
                    <FormFieldTitle>Select Font type</FormFieldTitle>
                    <Dropdown
                      placeholder="Select Font type"
                      fluid
                      // value={selectFontType}
                      search
                      selection
                      onChange={(e, props) => handleFontType(e, props, index)}
                      options={fontTypeOptions}
                    />
                    {selectFontType === 'add_custom' && (
                      <>
                        <Input
                          placeholder="Enter custom value"
                          type="text"
                          onChange={handleCustomFontTypeChange}
                        />
                        <Button onClick={() => handleAddCustomFontTypeOption(index)}>Add Custom Option</Button>
                      </>
                    )}
                  </FormFieldWrapper>
                  <FormFieldWrapper>
                    <FormFieldTitle>Select Position</FormFieldTitle>
                    <Dropdown
                      placeholder="Select Position"
                      fluid
                      // value={selectedAuthors}
                      search
                      //   value={boxes.g_textPosition}
                      selection
                      onChange={(e, props) => {
                        console.log('e,props', e, props);
                        handleBoxChange(props, index, 'g_textPosition');
                      }}
                      options={keysArray}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper>
                    <FormFieldTitle>Enter Font Color</FormFieldTitle>
                    <FormFieldValue>
                      <InputWrapper>
                        <InputField
                          placeholder="Select Colour"
                          type="color"
                          onChange={(e) => handleBoxChange(e, index, 'color')}
                        />
                      </InputWrapper>
                    </FormFieldValue>
                  </FormFieldWrapper>
                </FormFieldWrap>
                <FormFieldWrap>
                  <FormFieldWrapper>
                    <FormFieldTitle>Enter Font Size</FormFieldTitle>
                    <FormFieldValue>
                      <InputWrapper>
                        <Dropdown
                          placeholder="Select Font Size"
                          fluid
                          // value={selectFontSize}
                          search
                          selection
                          onChange={(e, props) => handleFontSize(e, props, index)}
                          options={fontSizeOptions}
                        />
                        {selectFontSize === 'add_custom' && (
                          <>
                            <Input
                              placeholder="Enter custom value"
                              type="text"
                              onChange={handleCustomFontSizeChange}
                            />
                            <Button onClick={() => handleAddCustomFontSizeOption(index)}>Add Custom Option</Button>
                          </>
                        )}
                      </InputWrapper>
                    </FormFieldValue>
                  </FormFieldWrapper>
                  <FormFieldWrapper>
                    <FormFieldTitle>Select Text Position</FormFieldTitle>
                    <Dropdown
                      placeholder="Select Text Position"
                      fluid
                      // value={selectedAuthors}
                      //   value={boxes.g_textPosition}
                      selection
                      onChange={(e, props) => handleTextPosition(e, props, index)}
                      options={textPositioningOption}
                    />
                  </FormFieldWrapper>
                  <FormFieldWrapper>
                    <FormFieldTitle>Enter Timestamp</FormFieldTitle>
                    <FormFieldValue>
                      <InputWrapper>
                        <InputField
                          type="number"
                          placeholder="Enter Starting Timestamp"
                          onChange={(e) => handleBoxChange(e, index, 's_timestamp')}
                        />
                      </InputWrapper>
                    </FormFieldValue>
                    <br />
                    <FormFieldValue>
                      <InputWrapper>
                        <InputField
                          type="number"
                          placeholder="Enter Ending Timestamp"
                          onChange={(e) => handleBoxChange(e, index, 'e_timestamp')}
                        />
                      </InputWrapper>
                    </FormFieldValue>
                  </FormFieldWrapper>
                  <FormFieldWrapper>
                    <FormFieldWrapper>
                      {/* <FormFieldValue>
                        <FormIcon
                          onClick={(e) => handleBoxChange(e, index, 'textPosition')}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="align left icon" data-position="left"></i>
                          <i className="align center icon" data-position="center"></i>
                          <i className="align right icon" data-position="right"></i>
                          <i className="align justify icon" data-position="justify"></i>
                        </FormIcon>
                      </FormFieldValue> */}
                      <i
                        className={`align left icon${box.textPosition === 'left' ? ' active' : ''}`}
                        data-position="left"
                        style={{cursor: "pointer"}}
                        onClick={(e) => handleBoxChange('left', index, 'textPosition')}
                      ></i>
                      <i
                        className={`align center icon${box.textPosition === 'center' ? ' active' : ''
                          }`}
                        data-position="center"
                        style={{cursor: "pointer"}}
                        onClick={(e) => handleBoxChange('center', index, 'textPosition')}
                      ></i>
                      <i
                        className={`align right icon${box.textPosition === 'right' ? ' active' : ''
                          }`}
                        data-position="right"
                        style={{cursor: "pointer"}}
                        onClick={(e) => handleBoxChange('right', index, 'textPosition')}
                      ></i>
                      <i
                        className={`align justify icon${box.textPosition === 'justify' ? ' active' : ''
                          }`}
                        data-position="justify"
                        style={{cursor: "pointer"}}
                        onClick={(e) => handleBoxChange('justify', index, 'textPosition')}
                      ></i>
                    </FormFieldWrapper>
                  </FormFieldWrapper>
                </FormFieldWrap>
                {/* Other input fields for font type, position, color, etc. */}
                <Button onClick={() => removeBox(index)}>Remove Text</Button>
              </FormFieldBox>
            </div>
          ))}
          <Button onClick={() => addBox()}>Add Text</Button>
          <Button onClick={() => createTextURL()}>Create Text URL</Button>
        </div>
        <br />
        <FormFieldWrap>
          <FormFieldWrapper>
            <FormFieldTitle>Audio Fade</FormFieldTitle>
            <FormFieldValue>
              <InputWrapper>
                <InputField
                  placeholder="Fixed 0.2sec"
                  value="Fixed 0.2sec"
                  disabled
                  onChange={(e) => console.log('e', e.target.value)}
                />
              </InputWrapper>
            </FormFieldValue>
          </FormFieldWrapper>
          <FormFieldWrapper>
            <FormFieldTitle>Subtitles</FormFieldTitle>
            <ToggleWrapper>
              <Flex>
                <Toggle on={addSubtitle} onClick={() => setAddSubtitle(!addSubtitle)} />
              </Flex>
            </ToggleWrapper>
          </FormFieldWrapper>
        </FormFieldWrap>

        <FormFieldWrapper>
          <Button onClick={handlePreview}>Click to Preview Video</Button>
        </FormFieldWrapper>
        <br />
        {mp4 && isVideo !== 404 && (
          <div key={key}>
            <video controls width="100%" id="videoPlayer">
              <source id="mp4Source" src={mp4} type="video/mp4" />
              {/* <source id="webmSource" src={webmURL} type="video/webm" />
            <source id="ogvSource" src={ogvURL} type="video/ogg" /> */}
              Your browser does not support the video tag.
            </video>
            <Button onClick={saveVideoToDigest}>Save Video to Digest</Button>
            <br />
          </div>)}
        {isVideo === 404 && <Show404 ><h3>404 Not Found</h3></Show404>}
        <br />
      </Wrapper>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />

      {/* {url} */}
    </>
  );
};
VideoEdit.propTypes = {
  location: PropTypes.object,
  match: PropTypes.shape({
    params: PropTypes.object,
  }),
};

export default VideoEdit;
