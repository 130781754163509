import React, { useReducer } from 'react';
import produce from 'immer';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Input, Button, Icon, Loader } from 'semantic-ui-react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Layout from '../../Components/Layout';
import { saveFeedCategory, editFeedCategory } from '../../Api/';


const Header = styled.div`
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.25em;
    margin-bottom: 2rem;
`;

const SubHeading = styled.div`
    color: #757575;
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.4em;
    margin-bottom: 2.5rem;
`;

const Label = styled.span`
  font-size: 17px;
  color: #000;
  line-height: 12px;
  margin-bottom: 20px;
  display: block;
  font-weight: 800;
  margin-top: 20px;
`;

const InputElement = styled(Input)`
  width: 100%;
  font-size: 16px !important;

  input {
    background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    &:focus {
      background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
    }
  }
`;

const FieldWrapper = styled.div`
    margin: 50 0;
`;

const ButtonWrapper = styled.div`
    margin-top: 25px;
`;

const initialValue = {
    title: '',
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'SET_TITLE':
          draft.title = action.value;
          break;
      case 'LOADING':
          draft.loading = !draft.loading;
          break;
    }
  });


const NewsBoard = props => {
    const [state, dispatch] = useReducer(reducer, initialValue);
    const { title, loading } = state;
   
    const categoryState = props.location.state;

    const saveCategory = () => {
        dispatch({ type: 'LOADING' });
        if (categoryState) {
            editFeedCategory({ title: title.trim(), id: categoryState.id }).then(response => {
                ToastsStore.success("Board updated successfully");
                setTimeout(() => {
                    props.history.push('/boards');
                }, 3000);
            }).catch(error => {
                dispatch({ type: 'LOADING' });
                ToastsStore.error("Unable to update Board.");
            })
        } else {
            saveFeedCategory({ title: title.trim() }).then(response => {
                ToastsStore.success("Board created successfully");
                setTimeout(() => {
                    props.history.push('/boards');
                }, 3000);
            }).catch(error => {
                dispatch({ type: 'LOADING' });
                ToastsStore.error("Unable to create Board.");
            })
        }
    };

    return (
        <Layout location={props.location} feed={true}>
            <Header>
                {categoryState ? 'Edit' : 'Create New'} Board
            </Header>
            <SubHeading>
                Save the most important articles you read so that you don't miss out on any.
            </SubHeading>
            {!loading ? (
                <>
                    <FieldWrapper>
                    <Label>Title</Label>
                    <InputElement
                        defaultValue={categoryState ? categoryState.title : ''}
                        onChange={e => {
                            dispatch({ type: 'SET_TITLE', value: e.target.value });
                        }}
                    />
                    </FieldWrapper>
                    <ButtonWrapper>
                        <Button
                            positive
                            type="submit"
                            onClick={saveCategory}
                            disabled={!title || !title.trim()}
                        >
                            <Icon name='save' /> Save
                        </Button>
                        <Button
                            as={Link}
                            style={{ marginLeft: 20 }}
                            to="/boards"
                        >
                            <Icon name='remove' /> Cancel
                        </Button>
                    </ButtonWrapper>
                </>
            ) : (
                <Loader active={loading} />
            )}
            <ToastsContainer
                position={ToastsContainerPosition.BOTTOM_LEFT}
                store={ToastsStore}
            />
        </Layout>
    )
};

export default NewsBoard;