export const titleCase = (string) =>
  string &&
  string
    .trim()
    .toLowerCase()
    .replace('_', ' ')
    .split(' ')
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1, item.length))
    .join(' ');

export const isUpperCase = (str) => str.trim().toUpperCase() === str.trim();
export const isLowerCase = (str) => str.trim().toLowerCase() === str.trim();

export const titleizeWhenNotUpperCase = (str) => (isUpperCase(str) ? str : titleCase(str));
export const titleizeWhenNotTitleCase = (str) => (!isLowerCase(str) ? str : titleCase(str));
