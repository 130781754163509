/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import PropTypes from 'prop-types';
// import { Icon } from 'semantic-ui-react';
import Parser from 'html-react-parser';
import moment from 'moment';

const Wrapper = styled.div`
  margin-left: 5px;
`;

// const IconWrapper = styled.div`
//   height: 100%;
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   i {
//     height: auto;
//   }
// `;

const ImageUploadPopup = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ImageUploadPopupWrraper = styled.div`
  width: 75%;
  background-color: #fff;
  position: relative;
  border-radius: 7px;
`;

const ImageUploadPopupContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  padding: 45px 50px 53px;
`;

const ModalTitle = styled.div`
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 30px;
  line-height: 1.3;
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #d8d8d8;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding-top: 10px;
  background: #fff;
`;

const UrlWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const UrlContent = styled.div`
  height: 70px;
  color: ${({ disabled }) => (disabled ? '#b4b4b4' : '#000')};
  background: #eee;
  padding: 10px;
  border-radius: 5px;
  margin: 20px;
  cursor: pointer;
  text-align: center;
`;

const ModalFooter = styled.div`
  padding: 8px 10px;
  border-top: 1px solid #d8d8d8;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
`;

const CancelButton = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 1.3;
  padding: 7px 15px;
  color: #fff;
  background-color: #a9a9a9;
  margin: 0 15px;
  border-radius: 3px;
  cursor: pointer;
`;

const AddButton = styled.div`
  font-size: 16px;
  font-weight: 800;
  line-height: 1.3;
  padding: 7px 25px;
  color: #fff;
  background-color: #28b463;
  border-radius: 3px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const LeftWrapper = styled.div`
  font-size: 15px;
  color: #52575a;
  margin-top: ${({ mt }) => mt || '.53em'};
  display: block;
  font-weight: 900;
  min-width: 65px;
  span {
    display: inline-flex;
    margin-bottom: 5px;
  }
`;

const Box = styled.div`
  border: 2px solid #d4d4d5;
  padding: 10px;
  border-radius: 10px;
`;

const FieldWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
  padding-bottom: 10px;
  border-bottom: 1px solid #d4d4d5;
`;

const EditorWrapper = styled.div`
  flex: 1;
  .draftJsEmojiPlugin__buttonWrapper__1Dmqh:nth-child(2) {
    display: none;
  }
  .draftJsEmojiPlugin__buttonWrapper__1Dmqh:nth-child(4) {
    display: none;
  }
  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const ContentBox = styled.div`
  background-color: ${({ bgColor }) => bgColor || '#fff'} !important;
  box-sizing: border-box;
  cursor: text;
  border-radius: 2px;
  margin-bottom: 2em;
  width: 100%;
  .editor:global(.public-DraftEditor-content) {
    min-height: 140px;
  }
  img {
    max-width: 85%;
  }
`;

const VersionControl = ({ onChange, version, openVersion, setOpenVersion }) => {
  const [newData, compareData] = useState('');
  const [newTitle, compareTitle] = useState('');
  const [value, setValue] = useState({});
  const titleRef = useRef(null);

  const selectVersion = async (val) => {
    setValue(val);
    compareData(val.digest);
    compareTitle(val.title);
    setTimeout(() => titleRef.current.scrollIntoView());
  };

  return (
    <Wrapper>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
      {/* <IconWrapper
        onClick={() => {
          if (Object.keys(version).length) setOpenVersion(true);
          else ToastsStore.error('No version history found.');
        }}
      >
        <Icon name="history" size="large" />
      </IconWrapper> */}
      {version && openVersion && (
        <ImageUploadPopup>
          <ImageUploadPopupWrraper>
            <ImageUploadPopupContainer>
              <ModalTitle>Previous Versions</ModalTitle>
              <UrlWrapper>
                {Object.entries(version)
                  .slice(0)
                  .reverse()
                  .map(([key, val], index) => (
                    <UrlContent onClick={() => selectVersion(val)} disabled={index > 3}>
                      <div>{val.user ? val.user.name : ''}</div>
                      {moment.unix(key).isValid() ? (
                        <div style={{ marginTop: 10 }}>
                          {moment.unix(key).format('DD MMM YYYY hh:mm A')}
                        </div>
                      ) : null}
                    </UrlContent>
                  ))}
              </UrlWrapper>
              {Object.keys(value).length > 0 && (
                <Box ref={titleRef}>
                  <FieldWrapper>
                    <LeftWrapper mt="10px" title="Title">
                      <span>T</span>
                    </LeftWrapper>
                    <ContentBox>{Parser(newTitle)}</ContentBox>
                  </FieldWrapper>
                  <FieldWrapper>
                    <LeftWrapper mt="16px" title="Content">
                      <span>C</span>
                    </LeftWrapper>
                    <EditorWrapper>
                      <ContentBox>{Parser(newData)}</ContentBox>
                    </EditorWrapper>
                  </FieldWrapper>
                </Box>
              )}
              <ModalFooter>
                <CancelButton
                  onClick={() => {
                    setOpenVersion(false);
                  }}
                >
                  Cancel
                </CancelButton>
                <AddButton
                  onClick={() => {
                    onChange(value);
                    setOpenVersion(false);
                  }}
                  disabled={!Object.keys(value).length}
                >
                  Save
                </AddButton>
              </ModalFooter>
            </ImageUploadPopupContainer>
          </ImageUploadPopupWrraper>
        </ImageUploadPopup>
      )}
    </Wrapper>
  );
};

VersionControl.propTypes = {
  onChange: PropTypes.func,
  version: PropTypes.object,
};

export default VersionControl;
