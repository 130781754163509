import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Loader } from 'semantic-ui-react';
import Toggle from '../../Components/Form/Toggle';
import { fetchDailyReport } from '../../Api';

const Container = styled.div`
  margin-top: 10px;
`;

const LoaderWrapper = styled.div`
  height: 100px;
  position: relative;
`;

const TagsListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const ToggleWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const PillsItem = styled.div`
  padding: 5px 20px;
  border-radius: 15px;
  margin: 3px;
  background-color: ${({ isLessTarget }) => (isLessTarget ? '#ff4c4c' : '#3CB371')};
  color: #fff;
  font-size: 14px;
  line-height: 18px;
`;

const TagsPills = () => {
  const [tagsData, setTagsData] = useState([]);
  const [reportLoader, setReportLoader] = useState(true);
  const [showTags, setShowTags] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const sortList = (a, b) => {
    if (a.tag__order_by < b.tag__order_by) {
      return -1;
    }
    if (a.tag__order_by > b.tag__order_by) {
      return 1;
    }
    return 0;
  };
  const fetchDailyReportData = () => {
    setReportLoader(true);
    fetchDailyReport({
      // date : moment(selectedDate).format('YYYY-MM-DD HH:mm'),
      fromDate: moment(new Date().setHours(0, 0, 0, 0)).format('YYYY-MM-DD HH:mm'),
      toDate: moment(new Date()).format('YYYY-MM-DD HH:mm'),
    })
      .then((response) => {
        setReportLoader(false);
        setTagsData(
          response.data.tags
            .map((item) => ({
              ...item,
              ...item.tag__config,
            }))
            .filter((item) => item.is_dash_visible)
            .sort(sortList),
        );
      })
      .catch((error) => {
        setReportLoader(false);
        console.log('🚀 ~ file: index.js ~ line 1175 ~ fetchDailyReportData ~ error', error);
      });
  };

  // const startIntervalCalls = () => {
  //   setInterval(() => {
  //     fetchDailyReportData();
  //   }, 10000);
  // };

  useEffect(() => {
    console.log(
      '🚀 ~ file: TagsPills.js ~ line 85 ~ useEffect ~ window.innerWidth <= 768',
      window.innerWidth <= 768,
    );
    if (window.innerWidth <= 768) {
      setIsMobile(true);
    } else {
      setShowTags(true);
    }
  }, []);

  useEffect(() => {
    fetchDailyReportData();
    // setTimeout(() => {
    //   startIntervalCalls();
    // }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(!reportLoader, tagsData, showTags, '111111');
  return (
    <Container>
      {reportLoader && (
        <LoaderWrapper>
          <Loader active={reportLoader} />
        </LoaderWrapper>
      )}
      {!reportLoader && tagsData.length > 0 && isMobile && (
        <ToggleWrapper
          onClick={() => {
            setShowTags(!showTags);
          }}
        >
          Show tags:
          <Toggle on={showTags} />
        </ToggleWrapper>
      )}
      {!reportLoader && tagsData.length > 0 && showTags && (
        <TagsListContainer>
          {tagsData.map((item) => (
            <PillsItem
              key={item.tag__title}
              isLessTarget={item.count < item.target}
            >{`${item.tag__title} (P - ${item.count}, T - ${item.target})`}</PillsItem>
          ))}
        </TagsListContainer>
      )}
    </Container>
  );
};

export default TagsPills;
