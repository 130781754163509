import React, { createRef, useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import OutsideClick from '../OutsideClick';

const DropDownWrapper = styled.div`
  position: relative;
  width: ${({ customWidth }) => customWidth};
  height: ${({ height }) => height};
`;

const DropDownText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 15px;
  width: 100%;
  display: inline-block;
  font-weight: bold !important;
  font-size: 14px !important;
  color: rgba(0, 0, 0, 0.6);
  position: relative;
  cursor: pointer;
`;

const DropDownContent = styled.div`
  width: ${({ contentWidth }) => contentWidth || 'auto'};
  padding: 5px 10px;
  position: absolute;
  bottom: ${({ bottomPosition }) => `${-bottomPosition}px` || 0};
  left: ${({ position }) => (position === 'bottomLeft' ? 0 : 'auto')};
  right: ${({ position }) => (position === 'bottomRight' ? 0 : 'auto')};
  transform: translateY(100%);
  box-shadow: 0 -3px 20px hsla(0, 0%, 0%, 0.15), 0 3px 8px hsla(0, 0%, 0%, 0.1);
  border-radius: 4px;
  display: ${({ isShowDropDown }) => (isShowDropDown ? 'block' : 'none')};
  max-height: 250px;
  overflow-y: auto;
  white-space: normal;
  background-color: #fff;
  z-index: 2;
`;

const DropDownIcon = styled(Icon)`
  position: absolute;
  top: 2px;
  right: 0;
`;

const DropdownContentIcon = styled(Icon)`
  position: absolute;
  top: -11px;
  left: ${({ position }) => (position === 'bottomLeft' ? '20px' : 'auto')};
  right: ${({ position }) => (position === 'bottomRight' ? '20px' : 'auto')};
`;

const DropDown = ({
  text,
  children,
  position,
  bottomPosition,
  contentWidth,
  customWidth,
  height,
  dropDownIconColor,
  multiple,
  open,
  onSelect,
}) => {
  const [isShowDropDown, toggleDropDown] = useState(open && multiple ? open : false);
  const contentRef = createRef();
  return (
    <DropDownWrapper customWidth={customWidth} height={height}>
      <DropDownText
        className="dropdown-text-feild"
        onClick={() => {
          // toggleDropDown(true);
          toggleDropDown(!isShowDropDown);
        }}
      >
        {text}
        <DropDownIcon
          name={isShowDropDown ? 'caret up' : 'dropdown'}
          color={dropDownIconColor || 'white'}
          className="dropdown-icon"
        />
      </DropDownText>
      {isShowDropDown && (
        <>
          <DropDownContent
            position={position}
            contentWidth={contentWidth}
            bottomPosition={bottomPosition}
            ref={contentRef}
            isShowDropDown={isShowDropDown}
          >
            <DropdownContentIcon position={position} name="downUp" size={20} color="#fff" />
            {children}
          </DropDownContent>
          <OutsideClick
            elementRef={contentRef}
            handleOutsideClick={() => {
              toggleDropDown(false);
              if (multiple) onSelect();
            }}
            activeOutsideClick={isShowDropDown}
          />
        </>
      )}
    </DropDownWrapper>
  );
};

DropDown.defaultProps = {
  position: 'bottomRight',
  customWidth: '150px',
  contentWidth: '180px',
  height: '20px',
};

DropDown.propTypes = {
  text: PropTypes.object,
  children: PropTypes.object,
  position: PropTypes.string,
  bottomPosition: PropTypes.number,
  contentWidth: PropTypes.string,
  customWidth: PropTypes.string,
  height: PropTypes.string,
  dropDownIconColor: PropTypes.string,
  multiple: PropTypes.bool,
  open: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default DropDown;
