import { fetchHeadlinesApi, ReclusterHeadlinesAPI } from 'Api';
import React,{useState,useEffect} from 'react'
import { Button, Dropdown, Input, Modal } from 'semantic-ui-react'
import styled from 'styled-components';
import {  ToastsStore } from 'react-toasts';

  const FlexFields = styled.div`
    margin: 30px 20px;
    align-items: center;

    > div {
      color: ${({ error }) => (error ? '#FF5722' : 'rgba(0,0,0,.87)')} !important;
    }
    input,
    .ui.selection.dropdown {
      border: ${({ error }) =>
        error ? '1px solid #FF5722' : '1px solid rgba(34,36,38,.15)'} !important;
    }
    .no-border {
      border: none !important;
    }
    .ui.selection.dropdown .menu > .item {
    }
  `;

  const Label = styled.div`
        margin: ${({ m }) => m || 0};   
  `;
  const Container = styled.div`
    display: grid;
    width: 100%;
  `;
  const FieldErrorMessage = styled.span`
    font-size: 12px;
    line-height: 12px;
    margin-top: 5px;
    color: #cc0000;
    font-weight: 300;
  `;

  function ReClusterModal({headlinesList,ids,onClose}) {
      const [headlineData,setHeadlineData] = useState(headlinesList || [])
      const [selectedHeadline,setSelectedHeadline] = useState("")
      const [errors,setErrors] = useState("")

      useEffect(()=>{       
          headlinesList && setHeadlineData(headlinesList)
      },[headlinesList])

      const callSearchApi = (value) => {
        let finalList
          fetchHeadlinesApi({ search: value })
          .then((response) => {
            if (response.status === 200) {          
              finalList = []
              finalList = response.data.results.map((item) => {
                let newObj = { ...item, children: item.groups };
                delete newObj.groups;
                return newObj;
              })  
              setHeadlineData(finalList || []); 
            }
          })
          .catch((error) => {
            if (window) window.Raven.captureException(error.response);          
            ToastsStore.error('Something went wrong!');
          });
        };
      
        const validateValue = (value) => {  
          if (!value) {
              setErrors("Please select headline first")
          }
          else{
              setErrors("")
          }
        };

    return (
      <div>
            <Modal size="large" open={true}>
            <Modal.Header>Re-Cluster headlines</Modal.Header>
            <Modal.Content>
              <FlexFields>
                <Label m={"8px 0px"}>Select Headline*</Label>
                <Container>                             
                  <Dropdown
                    placeholder="Search headline here"
                    selection
                    search
                    className="wrap-words"
                    onSearchChange={(e, { searchQuery }) => callSearchApi(searchQuery)}
                    value={selectedHeadline?.text || ''}
                    options={(headlineData || []).map(item => ({
                      key: item.id,
                      value: item.text,
                      text: item.text,
                      id: item.id || "",
                    }))}
                    onChange={(e, selected) => {
                      const selectedItem = headlineData.find(item => item.text === selected.value);
                      setSelectedHeadline(selectedItem);    
                      validateValue(selected.value);
                    }}
                  />
                  <FieldErrorMessage>
                    {errors}
                  </FieldErrorMessage>
                </Container>
              </FlexFields>
              <FlexFields>
                <Label m={"8px 0px"}>Headline</Label>
                <Container>
                  <Input
                    placeholder="Selected Headline"
                    value={selectedHeadline?.text || ""}
                    readOnly                               
                  />                             
                </Container>
              </FlexFields>
            </Modal.Content>
            <Modal.Actions>
              <Button positive content="ReCluster" onClick={()=>{
                validateValue(selectedHeadline?.text);
                const payload = {
                  ids,
                  group_id: selectedHeadline?.children?.length 
                    ? selectedHeadline.children[selectedHeadline.children.length - 1]?.id
                    : selectedHeadline?.id
                };
                if (!errors && ids) {
                    ReclusterHeadlinesAPI(payload)
                    .then(() => {
                      ToastsStore.success('Recluster headlines');                  
                      onClose(true)
                    })
                    .catch(() => {          
                      ToastsStore.error('Something went wrong!');
                    }); 
                }
                }} />
              <Button tertiary onClick={()=>onClose(false)} content="Cancel" />
            </Modal.Actions>
          </Modal>
      </div>
    )
  }

export default ReClusterModal