import React, { useEffect, useReducer } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { Button, Icon, Input, Image, Loader, Dropdown, Message, Modal } from 'semantic-ui-react';
import Toggle from '../../Components/Form/Toggle';
import Layout from '../../Components/Layout';
import { fetchFeedSources, followNewsFeed, fetchAllFeedCategories,deleteFeedSource } from '../../Api/';
import Pagination from '../../Components/Pagination';


const SearchWrapper = styled.div`
  display: flex;
  .input {
    flex-grow: 1;
    margin-right: 30px;
  }
`;

const ButtonGroup = styled.div`
    display: flex;
    min-height: 40;
    margin-top: 20px;
`;

const Header = styled.div`
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.25em;
    text-transform: capitalize;
`;

const PageHeading = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    button {
        font-size: 15px;
        line-height: 1rem;
        font-weight: 600;
    }
`;

const SubHeading = styled.div`
    color: #9e9e9e;
    font-size: 11px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 1em;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
`;

const Sources = styled.div`
`;

const SearchList = styled.div`
    margin-top: 70px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;

  .rc-pagination-prev,
  .rc-pagination-next, rc-pagination-item-active {
    outline: none;
    border-color: transparent;
  }
`;

const SourceHeading = styled.div`
    color: #757575;
    font-size: 1.25rem;
    font-weight: 300;
    line-height: 1.5;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
`;

const FeedDesc = styled(Link)`
    display: flex;
    cursor: pointer;
    justify-content: space-between;
    align-items: start;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    max-width: 600px;
    padding: 1.5rem;
    img {
        min-width: 60px;
        height: 60px;
    }
    button {
        text-transform: uppercase;
    }
`;

const Desc = styled.div`
  flex-grow: 4;
  margin-left: 20px;
  margin-right: 20px;
  color: #757575;
  word-break: break-all;
`;

const RightButtons = styled.div`
    display: flex;
`;

const Brand = styled.div`
    color: #333333;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    margin-bottom: 5px;
`;

const BrandSource = styled.div`
    color: #9e9e9e;
    cursor: pointer;
    margin-bottom: 15px;
    &:hover {
        text-decoration: underline;
    }
`;

const FieldWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  font-weight: bold !important;
  font-size: 14px !important;
`;

const initialValue = {
    categories: [],
    followedList: [],
    loading: false,
    currentPage: 1,
    totalItems: 0,
    sortOrder: '',
    filter: { followed: false },
    feedList: [],
    openDeleteModal: {},
};

const SORT_ORDER_OPTIONS = [
    {
      key: '',
      value: '',
      text: '',
    },
    {
      key: 'id',
      value: 'id',
      text: 'Id',
    },
    {
      key: 'title',
      value: 'title',
      text: 'Title',
    },
];

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
        case 'CATEGORIES_SET':
          draft.categories = action.value;
          draft.categoryTitle = action.value.find(category => category.key === parseInt(action.categoryId, 10));
          break;
        case 'SET_SEARCH_QUERY':
          draft.searchQuery = action.value;
          break;
        case 'FEED_LIST_SET':
          draft.feedList = action.value;
          break;
        case 'SET_CURRENT_PAGE':
            draft.currentPage = action.value;
            break;
        case 'LOADING':
            draft.loading = !draft.loading;
            break;
        case 'SORT':
            draft.sortOrder = action.value;
            break;
        case 'FILTER':
            draft.filter = {...draft.filter, [action.field]: action.value};
            break;
        case 'SET_DELETE_MODAL':
            draft.openDeleteModal = action.value;
            break;
    }
  });

const NewsBoardDetails = props => {
    const [state, dispatch] = useReducer(reducer, initialValue);
    const { match: { params: { categoryId } } } = props;
    const {
        searchQuery,
        filter,
        feedList,
        categoryTitle,
        loading,
        currentPage,
        totalItems,
        sortOrder,
        openDeleteModal
    } = state;
    
    const fetchSources = follow => {
        dispatch({ type: 'LOADING' });
        let ordering = sortOrder;
            if (ordering === 'id') ordering = `-${ordering}`;
        const params = {
            followed: !follow || !filter.followed? '' : filter.followed,
            search: searchQuery,
            feed_categories: categoryId,
            ordering,
        }
        fetchFeedSources(params).then(response => {
            dispatch({ type: 'SET_DELETE_MODAL', value: {} });
            dispatch({ type: 'LOADING' });
            dispatch({ type: 'FEED_LIST_SET', value: response.data.results, totalItems: response.data.count });
        })
    };

    useEffect(() => {
        fetchAllFeedCategories().then(response => {
            let tempCategories = response.data.results || [];
            tempCategories = tempCategories.map(category => (
                        { key: category.id, value: category.id, text: category.title }
                    ));
            tempCategories.unshift({ key: '', value: '', text: 'None' });
            dispatch({ type: 'CATEGORIES_SET', value: tempCategories, categoryId  })
        });
    }, [categoryId]);

    useEffect(() => {
        dispatch({ type: 'LOADING' });
        let ordering = SORT_ORDER_OPTIONS.filter(field => field.value === sortOrder);
        if (ordering.length) ordering = ordering[0].key;
        else ordering = '';
        const params = {
            followed: filter.followed || '',
            search: searchQuery,
            feed_categories: categoryId,
            ordering,
        }
        fetchFeedSources(params).then(response => {
            dispatch({ type: 'LOADING' });
            dispatch({ type: 'FEED_LIST_SET', value: response.data.results, totalItems: response.data.count });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sortOrder, filter.followed, categoryId]);

    const followFeed = id => {
        dispatch({ type: 'LOADING' });
        followNewsFeed(id, categoryId).then(() => {
            dispatch({ type: 'LOADING' });
            fetchSources();
        })
    }

    const toggleFollowed = () => {
        dispatch({ type: 'FILTER', field: 'followed', value: !filter.followed });
    }

    const sortFeeds = (event, data) => {
        dispatch({ type: 'SORT', value: data.value });
    };

    const defaultSort = event => {
          event.stopPropagation();
          event.preventDefault();
          dispatch({ type: 'SORT', value: '' });
    };

    // prevent typing of alt+c
    const handleKeyDown =
      keyDownEvent => {
        if (keyDownEvent.keyCode === 32) {
          keyDownEvent.stopPropagation();
        }
        if ((keyDownEvent.keyCode === 67 && keyDownEvent.altKey) || keyDownEvent.keyCode === 191) {
          keyDownEvent.preventDefault();
        }
  
        if (keyDownEvent.keyCode === 13) {
          keyDownEvent.preventDefault();
          fetchSources();
        }
  
        if (keyDownEvent.keyCode === 27) {
          keyDownEvent.preventDefault();
        }
      };

      const deleteFeed = (event, feed) => {
        event.stopPropagation();
        event.preventDefault();
        dispatch({ type: 'SET_DELETE_MODAL', value: feed });
    }

    const deleteFeedConfirm = () => {
        dispatch({ type: 'LOADING' });
        deleteFeedSource(openDeleteModal.id).then(() => {
            ToastsStore.success(`Board ${openDeleteModal.title} deleted!`);
            dispatch({ type: 'LOADING' });
            fetchSources();
        }).catch(error => {
            dispatch({ type: 'LOADING' });
            ToastsStore.error(error.response);
        });
    }

    return (
            <Layout location={props.location} feed={true}>
                <SubHeading>
                    Board
                </SubHeading>
                <PageHeading>
                    <Header>
                        {categoryTitle && categoryTitle.text}
                    </Header>
                    <ButtonWrapper>
                        <Button
                            basic
                            to={`/board-details/${categoryId}/`}
                            as={Link}
                        >
                            <Icon name='list' /> View Feeds
                        </Button>
                        <Button
                            basic
                            to='/feed'
                            as={Link}
                        >
                            <Icon name='plus' /> Add Sources
                        </Button>
                    </ButtonWrapper>
                </PageHeading>
                    <Sources>
                        <SourceHeading>
                            Discover the best sources for any topic
                        </SourceHeading>
                        <SearchWrapper>
                            <Input
                                icon='search'
                                placeholder="Search feed. Entering website will create a new feed"
                                onKeyDown={handleKeyDown}
                                onChange={e => dispatch({ type: 'SET_SEARCH_QUERY', value: e.target.value })}
                            />
                            <ButtonWrapper>
                                <Dropdown
                                    trigger={
                                        <span>
                                        <Icon
                                            name="x"
                                            link
                                            className={sortOrder.length ? 'pull-right' : 'hiddenField'}
                                            onClick={defaultSort}
                                            data-test="clear-sort"
                                        />
                                        {sortOrder || 'Sort'}
                                        </span>
                                    }
                                    value={sortOrder}
                                    selectOnBlur={false}
                                    className="btn-group-dropdown selection"
                                    options={SORT_ORDER_OPTIONS}
                                    onChange={sortFeeds}
                                />
                            </ButtonWrapper>
                        </SearchWrapper>
                        <ButtonGroup>
                            <>
                                {/* <Input
                                    value={filterValue}
                                    onChange={filterValueChange}
                                    className={filter !== 'publish_date' ? '': 'hiddenField'}
                                    onKeyDown={handleKeyDown}
                                />  */}
                                    <FieldWrapper>
                                        Followed
                                    <Toggle on={filter.followed} onClick={toggleFollowed} />
                                </FieldWrapper>
                            </>
                        </ButtonGroup>
                        {!loading ? (
                                <>
                                <SearchList>
                                    {feedList.length > 0 ? (
                                        feedList.map(feed => (
                                            <FeedDesc to={`/board-details/${categoryId}/feed/${feed.host.id}/details`} key={feed.id}>
                                                <Image src={feed.host.logo_link}/>
                                                <Desc>
                                                    <Brand>{feed.host.brand_name}</Brand>
                                                    <BrandSource>{feed.url}</BrandSource>
                                                </Desc>
                                                <RightButtons>
                                                    <Button
                                                        basic
                                                        color={feed.followed ? 'grey' : 'green'}
                                                        onClick={(event) => {
                                                            event.preventDefault();
                                                            event.stopPropagation();
                                                            followFeed(feed.id);
                                                        }}
                                                    >
                                                        {feed.followed ? 'Unfollow' : 'Follow'}
                                                    </Button>
                                                        <Button
                                                            basic
                                                            to={{
                                                                state: {feed, categoryId},
                                                                pathname: `/feed/${feed.id}`,
                                                            }}
                                                            as={Link}
                                                            icon="edit"
                                                        />
                                                        <Button
                                                            basic
                                                            onClick={event => deleteFeed(event, feed)}
                                                            icon="cancel"
                                                        />
                                                </RightButtons>
                                            </FeedDesc>
                                        ))
                                    ): (
                                        <Message info className="margin-top-20">
                                            <Message.Header>No Data Found</Message.Header>
                                        </Message>
                                    )}
                                </SearchList>
                                {totalItems > 20 && (
                                    <PaginationWrapper>
                                    <Pagination
                                        activePage={currentPage}
                                        totalItems={totalItems}
                                        totalPages={Math.round(totalItems / 20)}
                                        onChangePage={current => dispatch({ type: 'SET_CURRENT_PAGE', value: current })}
                                        pageSize={20}
                                    />
                                    </PaginationWrapper>
                                )}
                            </>
                            ) : <Loader active={loading} /> 
                        }
                        {Object.keys(openDeleteModal).length > 0 && (
                            <Modal size="tiny" open={Object.keys(openDeleteModal).length}>
                                <Modal.Content>
                                    Are you sure you want to delete feed `{openDeleteModal.title}`?
                                </Modal.Content>
                                <Modal.Actions>
                                <Button
                                    negative
                                    disabled={loading}
                                    onClick={() => {
                                    dispatch({ type: 'SET_DELETE_MODAL', value: {} })
                                    }}
                                >Cancel</Button>
                                <Button
                                    positive
                                    content='Yes delete'
                                    disabled={loading}
                                    onClick={deleteFeedConfirm}
                                />
                                </Modal.Actions>
                            </Modal>
                        )}
                        <ToastsContainer
                            position={ToastsContainerPosition.BOTTOM_LEFT}
                            store={ToastsStore}
                        />
                    </Sources>
        </Layout>
    )
};

export default NewsBoardDetails;