/* eslint-disable react/prop-types */
import React, { useReducer } from 'react';
import styled from 'styled-components';
import { Formik, Field, ErrorMessage } from 'formik';
import produce from 'immer';
import PropTypes from 'prop-types';
import { Button, Icon, Form } from 'semantic-ui-react';
const MainForm = styled(Form)`
  padding: 20px;
`;

const FormFields = styled.div`
  padding: 20px;
  input {
    margin-top: 10px !important;
  }
`;

const ButtonWeapper = styled.div`
  padding: 20px;
`;

const FieldError = styled.span`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const CustomField = styled(Field)`
  border: ${(param) => param.error && '1px solid #cc0000 !important'};
`;

const ImageWrapper = styled.div`
  position: relative;
  height: ${({ isYtVideo }) => (isYtVideo ? 'auto' : '287px')};
  overflow: hidden;
  width: 60%;
  img {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  height: calc(100% - 37px);
  margin-top: 20px;

  img {
    width: 100%;
    height: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;

  label {
    width: 20%;
  }
`;


const FieldSelect = styled(Field)`
  height: 40px !important;
  margin-top: 10px !important;
  border: #85b7d9;
  &:focus {
    outline: none;
  }
`;
const initialValues = {
  uploadImage: false,
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'INVALID_IMAGE':
        draft.validImage = action.value;
        break;
      case 'INVALID_SERIES_IMAGE':
        draft.validSeriesImage = action.value;
        break;
    }
  });

const TagForm = (props) => {
  const { closeModal, createTag, data, tagList } = props;
  const [state, dispatch] = useReducer(reducer, initialValues);
  const { validImage, validSeriesImage } = state;

  console.log('data in TagForm', data);
  const checkImage = (url, setFieldValue, field) => {
    if (url) {
      const image = new Image();
      setFieldValue(field, url);

      image.onload = () => {
        // image exists and is loaded
        dispatch({ type: 'INVALID_IMAGE', value: '' });
        dispatch({ type: 'INVALID_SERIES_IMAGE', value: '' });
      };
      image.onerror = () => {
        // image did not load
        if (field === 'cover_image') dispatch({ type: 'INVALID_IMAGE', value: 'Invalid Image' });
        else dispatch({ type: 'INVALID_SERIES_IMAGE', value: 'Invalid Image' });
      };

      image.src = url;
    } else {
      setFieldValue(field, '');
      if (field === 'cover_image') dispatch({ type: 'INVALID_IMAGE', value: '' });
      dispatch({ type: 'INVALID_SERIES_IMAGE', value: '' });
    }
  };

  return (
    <Formik
      initialValues={{
        ...data,
        description: data.description || null,
        cover_image: data.cover_image || null,
        tag_group: data.tag_group || null,
        is_tag_group: false,
      }}
      validate={validate}
      onSubmit={(values, { setSubmitting }) => {
        if (!validImage && !validSeriesImage) createTag({ values, setSubmitting });
      }}
    >
      {({ handleSubmit, errors, touched, setFieldValue, values, handleChange }) => (
        <MainForm onSubmit={handleSubmit}>
          <FormFields>
            <label htmlFor="title">Name:</label>
            <CustomField
              name="title"
              type="text"
              placeholder="name"
              error={errors.title && touched.title}
            />
            {/* {touched.title && <FieldError>{errors.title}</FieldError>} */}
            <FieldError>
              <ErrorMessage name="title" />
            </FieldError>
          </FormFields>
          <FormFields>
            <label htmlFor="slug">Slug:</label>
            <CustomField
              name="slug"
              type="text"
              placeholder="slug"
              error={errors.slug && touched.slug}
            />
            {/* {touched.title && <FieldError>{errors.title}</FieldError>} */}
            <FieldError>
              <ErrorMessage name="slug" />
            </FieldError>
          </FormFields>
          <FormFields>
            <Flex>
              <label htmlFor="is_tag_group">Tag group</label>
              <CustomField
                name="is_tag_group"
                type="checkbox"
                error={errors.is_tag_group && touched.is_tag_group}
              />
              <FieldError>
                <ErrorMessage name="is_tag_group" />
              </FieldError>
            </Flex>
          </FormFields>
          {values.is_tag_group === false ? (
            <FormFields>
              <>
                <label htmlFor="tag_group">Select tag group</label>
                <FieldSelect
                  as="select"
                  name="tag_group"
                  value={values.tag_group}
                  onChange={handleChange}
                >
                  {tagList &&
                    tagList.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                </FieldSelect>
                <FieldError>
                  <ErrorMessage name="tag_group" />
                </FieldError>
              </>
            </FormFields>
          ) : null}

          <FormFields>
            <label htmlFor="cover_image">Image:</label>
            <CustomField
              name="cover_image"
              type="text"
              placeholder="cover_image"
              error={errors.cover_image && touched.cover_image}
              onChange={(e) => checkImage(e.target.value, setFieldValue, 'cover_image')}
            />
            {values.cover_image && !validImage && (
              <ImageWrapper>
                <ImageContainer>
                  <img src={values.cover_image} alt="cover" />
                </ImageContainer>
              </ImageWrapper>
            )}
            <FieldError>{validImage}</FieldError>
          </FormFields>
          <FormFields>
            <label htmlFor="description">Description:</label>
            <CustomField
              name="description"
              type="text"
              placeholder="description"
              error={errors.description && touched.description}
            />
            <FieldError>
              <ErrorMessage name="description" />
            </FieldError>
          </FormFields>
          <Flex>
            <label htmlFor="is_series">Series:</label>
            <CustomField
              name="is_series"
              type="checkbox"
              error={errors.is_series && touched.is_series}
            />
            <FieldError>
              <ErrorMessage name="is_series" />
            </FieldError>
          </Flex>
          {values.is_series && (
            <>
              <FormFields>
                <label htmlFor="series_icon">Series Icon:</label>
                <CustomField
                  name="series_icon"
                  type="text"
                  placeholder="series icon"
                  error={errors.series_icon && touched.series_icon}
                  onChange={(e) => checkImage(e.target.value, setFieldValue, 'series_icon')}
                />
                {values.series_icon && !validSeriesImage && (
                  <ImageWrapper>
                    <ImageContainer>
                      <img src={values.series_icon} alt="cover" />
                    </ImageContainer>
                  </ImageWrapper>
                )}
                <FieldError>{validSeriesImage}</FieldError>
              </FormFields>
              <FormFields>
                <label htmlFor="series_text">Series Text:</label>
                <CustomField
                  name="series_text"
                  type="text"
                  placeholder="series text"
                  error={errors.series_text && touched.series_text}
                />
                <FieldError>
                  <ErrorMessage name="series_text" />
                </FieldError>
              </FormFields>
            </>
          )}
          <Flex>
            <label htmlFor="is_stream">Stream:</label>
            <CustomField
              name="is_stream"
              type="checkbox"
              error={errors.is_stream && touched.is_stream}
            />
            <FieldError>
              <ErrorMessage name="is_stream" />
            </FieldError>
          </Flex>
          <Flex>
            <label htmlFor="is_default_follow">By Default Follow:</label>
            <CustomField
              name="is_default_follow"
              type="checkbox"
              error={errors.is_default_follow && touched.is_default_follow}
            />
            <FieldError>
              <ErrorMessage name="is_default_follow" />
            </FieldError>
          </Flex>
          <Flex>
            <label htmlFor="is_internal">Internal:</label>
            <CustomField
              name="is_internal"
              type="checkbox"
              error={errors.is_internal && touched.is_internal}
            />
            <FieldError>
              <ErrorMessage name="is_internal" />
            </FieldError>
          </Flex>
          <ButtonWeapper>
            <Button type="button" onClick={closeModal}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button positive type="submit">
              <Icon name="save" /> Save
            </Button>
          </ButtonWeapper>
        </MainForm>
      )}
    </Formik>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.title || !values.title.trim().length) {
    errors.title = 'Please enter name';
  }
  if (!values.slug || !values.slug.trim().length) {
    errors.slug = 'Please enter Slug';
  }

  return errors;
};

TagForm.propTypes = {
  closeModal: PropTypes.func,
  createTag: PropTypes.func,
  data: PropTypes.object,
};

export default TagForm;
