function resizeImage(img, maxWidth, maxHeight) {
  var ratio = 1;
  var width = img.width;
  var height = img.height;

  if (width > maxWidth) {
    ratio = maxWidth / width;
    width = maxWidth;
    height = height * ratio;
  }

  if (height > maxHeight) {
    ratio = maxHeight / height;
    height = maxHeight;
    width = width * ratio;
  }

  img.width = width;
  img.height = height;
}

export default resizeImage;
