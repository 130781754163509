import moment from 'moment';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Datetime from 'react-datetime';
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import queryString from 'query-string';

const DateTimeWrapper = styled.div`
  margin-right: 12px;
  font-weight: 600 !important;
  font-size: 16px !important;
  border: 2px solid #d4d4d5;
  .rdt {
    width: 100%;
    height: 100%;
    input {
      min-width: 250px;
      height: 100%;
      font-family: Metropolis, sans-serif !important;
      text-transform: uppercase;
      border: none !important;
      outline: none !important;
      color: rgba(0, 0, 0, 0.8) !important;
      padding: 8px 15px !important;
    }
  }

  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const ButtonCustom = styled(Button)`
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  align-items: end;
  float: right;
  margin: 12px;
`;

const DateFilter = ({ filterDateValues, setFilterDateValues, handleClose }) => {
  const history = useHistory();

  const [datesBeforeSubmission, setDatesBeforeSubmission] = useState({
    startDate: filterDateValues.fromDate || null,
    endDate: filterDateValues.toDate || null,
  });

  const onSubmit = (e) => {
    e.preventDefault();

    const diff = moment(datesBeforeSubmission.endDate).diff(
      moment(datesBeforeSubmission.startDate),
    );

    if (diff > 0) {
      setFilterDateValues({
        ...filterDateValues,
        fromDate: new Date(datesBeforeSubmission.startDate).toISOString(),
        toDate: new Date(datesBeforeSubmission.endDate).toISOString(),
      });

      const updateUrlObj = {
        ...queryString.parse(window.location.search),
        startDate: new Date(datesBeforeSubmission.startDate).toISOString(),
        endDate: new Date(datesBeforeSubmission.endDate).toISOString(),
      };

      history.push({
        pathname: `/notifications`,
        search: queryString.stringify(updateUrlObj),
      });

      handleClose();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <Container>
        <div>
          <p>From</p>
          <DateTimeWrapper>
            <Datetime
              dateFormat="DD MM YYYY"
              value={
                datesBeforeSubmission.startDate
                  ? moment(new Date(datesBeforeSubmission.startDate))
                  : null
              }
              onChange={(value) => {
                setDatesBeforeSubmission({ ...datesBeforeSubmission, startDate: value });
              }}
            />
          </DateTimeWrapper>
        </div>

        <div>
          <p>To</p>
          <DateTimeWrapper>
            <Datetime
              dateFormat="DD MM YYYY"
              value={
                datesBeforeSubmission.endDate
                  ? moment(new Date(datesBeforeSubmission.endDate))
                  : null
              }
              onChange={(value) => {
                setDatesBeforeSubmission({ ...datesBeforeSubmission, endDate: value });
              }}
            />
          </DateTimeWrapper>
        </div>

        <ButtonCustom onClick={onSubmit} type="submit" positive content="Done" />
      </Container>
    </form>
  );
};

export default DateFilter;
