import React from 'react';
import styled from 'styled-components';
import { Formik, Field, ErrorMessage } from 'formik';
import { Button, Icon, Form } from 'semantic-ui-react';

const MainForm = styled(Form)`
    padding: 20px;
`;

const FormFields = styled.div`
    padding: 20px;
    input {
        margin-top: 10px !important;
    }
`;

const ButtonWeapper = styled.div`
    padding: 20px;
`;

const FieldError = styled.span`
    font-size: 12px;
    line-height: 12px;
    margin-top: 2px;
    color: #cc0000;
    font-weight: 300;
`;

const CustomField = styled(Field)`
    border: ${param => (param.error && '1px solid #cc0000 !important')};
`;

const AddStatusForm = (props) => {
    const { addStatus, status, history } = props;

    return(
        <Formik
            initialValues={status}
            validate={validate}
            onSubmit={async (values, { setSubmitting, resetForm }) => {
                addStatus({ values, setSubmitting, resetForm });
          }}>
            {({ handleSubmit, errors, touched, resetForm }) => (
                <MainForm onSubmit={handleSubmit}>
                    <FormFields>
                        <label htmlFor="status_name">Status Name:</label>
                        <CustomField name="status_name" type="text" placeholder="Status Name" error={errors.name && touched.name}/>
                        <FieldError>
                            <ErrorMessage name="status_name" />
                        </FieldError>
                    </FormFields>
                    <FormFields>
                        <label htmlFor="status_code">Status Code:</label>
                        <Field name="status_code" type="text" placeholder="Status Code" />
                        <FieldError>
                            <ErrorMessage name="status_code" />
                        </FieldError>
                    </FormFields>
                    <ButtonWeapper>
                        <Button positive type="submit">
                            <Icon name='save' /> Save
                        </Button>
                        <Button onClick={() => history.push('/settings')}>
                            <Icon name='cancel' /> Cancel
                        </Button>
                    </ButtonWeapper>
                </MainForm>
            )}
        </Formik>
    )
};

const validate = values => {
    const errors = {};
    if (!values.status_name || !values.status_name.trim()) {
      errors.status_name = 'Please enter status name';
    }
    if (!values.status_code || !values.status_code.trim()) {
        errors.status_code = 'Please enter status code';
    } else if (values.status_code && values.status_code.trim() && values.status_code.trim().length > 5) {
        errors.status_code = 'Please ensure that status code does not contain more than 5 characters';
    }
    return errors;
  };

export default AddStatusForm;
