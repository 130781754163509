import React, { useRef, useEffect, useState } from 'react';
import { Header, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Slide } from 'react-slideshow-image';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import moment from 'moment';

const Container = styled.div``;
const ContentWrapper = styled.div`
  padding: 15px 10px;
`;

const CopyIconWrapper = styled.span`
  padding: 5px;
  margin-top: ${({ marginTop }) => `${marginTop || 0}px`};
  display: inline-block;
  font-size: 16px !important;
  line-height: 16px !important;
  vertical-align: top;
  cursor: pointer;
`;

const ImageContainer = styled.div`
  background-color: #dcdcdc;
  width: 100%;
  height: calc(100% - 37px);
  img {
    width: 100%;
    height: 100%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 250px;
  overflow: hidden;

  background: #dcdcdc url(${({ imageUrl }) => `"${imageUrl}"`});
  background-size: cover;
`;

const SliderWrapper = styled.div`
  position: relative;

  .indicators {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const CopyIconImage = styled.div`
  padding: 8px 5px;
  border-radius: 50%;
  box-shadow: 0px 0px 10px 0px #000;
  position: absolute;
  right: 10px;
  bottom: 10px;
  background-color: #fff;
  cursor: pointer;
`;

const ArrowIconImage = styled(CopyIconImage)`
  top: 105px;
  left: 77px;
  right: auto;
  position: fixed;
  bottom: auto;
`;

const ContextMenuItem = styled(MenuItem)`
  background: #fff;
  padding: 5px 10px;
  cursor: pointer;
`;

const ContextMenuWrapper = styled(ContextMenu)`
  box-shadow: 0 0 10px 3px #dcdcdc;
  border-radius: 5px;
  overflow: hidden;
`;

const DateWrapper = styled.div`
  display: inline-block;
  padding: 3px 10px;
  background-color: #f32826;
  border-radius: 6px;
  color: #fff;
  font-size: 12px;
  font-weight: 900;
`;

const ErrorMessageWrapper = styled.div`
  color: #cc0000;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  font-weight: 900;
`;

const OriginalPage = ({
  data,
  handleCopyText,
  extractError,
  showFullLeft,
  setShowFullLeft,
  setAspectRatio,
}) => {
  const contentRef = useRef(null);
  const [selectedStateObject, setSelectedTextObject] = useState(null);

  const onSelectMouseUp = (event) => {
    const selection = window.getSelection();
    if (selection.toString()) {
      setTimeout(() => {
        setSelectedTextObject({
          text: selection.toString(),
          position: {
            x: event.clientX,
            y: event.clientY,
          },
        });
      }, 100);
    } else {
      setSelectedTextObject(null);
    }
  };

  const handleOutsideClick = (event) => {
    if (
      contentRef.current &&
      contentRef.current !== event.target &&
      !contentRef.current.contains(event.target)
    ) {
      setSelectedTextObject(null);
    }
  };

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.addEventListener('mouseup', onSelectMouseUp);
      // contentRef.current.addEventListener('contextmenu', function (e) {
      //   alert("You've tried to open context menu"); //here you draw your own menu
      //   if(window.selection) {
      //     e.preventDefault();
      //   }
      // }, false);
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [contentRef]);

  const properties = {
    transitionDuration: 500,
    infinite: true,
    indicators: true,
    arrows: true,
    onChange: (oldIndex, newIndex) => {},
  };

  return (
    <Container>
      {extractError && (
        <ErrorMessageWrapper>
          {extractError === 'url can not be parsed'
            ? "Website can't be loaded currently"
            : extractError}
        </ErrorMessageWrapper>
      )}
      {!extractError && (
        <>
          {!showFullLeft && (
            <ImageContainer>
              <ArrowIconImage onClick={() => setShowFullLeft(!showFullLeft)}>
                <Icon name={showFullLeft ? 'arrow right' : 'arrow left'} size="large" />
              </ArrowIconImage>
            </ImageContainer>
          )}
          <div style={{ visibility: showFullLeft ? 'visible' : 'hidden' }}>
            <SliderWrapper>
              {data.images && Array.isArray(data.images) ? (
                <>
                  {data.images && data.images.length > 1 ? (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <Slide {...properties}>
                      {data.images.map((image) => (
                        <ImageWrapper imageUrl={image}>
                          <CopyIconImage
                            onClick={() => {
                              handleCopyText({ type: 'image', text: image });
                            }}
                          >
                            <Icon name="copy" size="large" />
                          </CopyIconImage>
                        </ImageWrapper>
                      ))}
                    </Slide>
                  ) : (
                    <ImageWrapper imageUrl={data.images[0]}>
                      <ArrowIconImage onClick={() => setShowFullLeft(!showFullLeft)}>
                        <Icon name={showFullLeft ? 'arrow right' : 'arrow left'} size="large" />
                      </ArrowIconImage>
                      <CopyIconImage
                        onClick={() => {
                          setAspectRatio(false);
                          handleCopyText({ type: 'image', text: data.images[0] });
                        }}
                      >
                        <Icon name="copy" size="large" />
                      </CopyIconImage>
                    </ImageWrapper>
                  )}
                </>
              ) : (
                <ImageContainer>
                  <ArrowIconImage onClick={() => setShowFullLeft(!showFullLeft)}>
                    <Icon name={showFullLeft ? 'arrow right' : 'arrow left'} size="large" />
                  </ArrowIconImage>
                </ImageContainer>
              )}
            </SliderWrapper>
            {data.title && (
              <Header textAlign="center" as="h1">
                {data.title}
                <CopyIconWrapper
                  title="Copy Title"
                  onClick={() => {
                    handleCopyText({ type: 'title', text: data.title });
                  }}
                >
                  <Icon name="copy" size="small" />
                </CopyIconWrapper>
              </Header>
            )}
            {data.subtitle && (
              <Header textAlign="center" as="h3">
                {data.subtitle}
                <CopyIconWrapper
                  title="Copy Subtitle"
                  marginTop={-6}
                  onClick={() => handleCopyText({ type: 'subtitle', text: data.subtitle })}
                >
                  <Icon name="copy" size="small" />
                </CopyIconWrapper>
              </Header>
            )}
            <DateWrapper>{moment(data.date).format('MMM DD YYYY hh:mm A')}</DateWrapper>
            <ContextMenuTrigger id="some_unique_identifier">
              <ContentWrapper ref={contentRef}>
                {data.content &&
                  (typeof data.content === 'object' ? (
                    <>
                      {data.content.map((para, index) => (
                        <p key={`${para}${index + 1}`}>{para}</p>
                      ))}
                    </>
                  ) : (
                    <>
                      {data.content.split(/\n/).map((para, index) => (
                        <p key={`${para}${index + 1}`}>{para}</p>
                      ))}
                    </>
                  ))}
              </ContentWrapper>
            </ContextMenuTrigger>
            {selectedStateObject && (
              <ContextMenuWrapper id="some_unique_identifier">
                <ContextMenuItem
                  onClick={() => {
                    handleCopyText({ type: 'content-start', text: selectedStateObject.text });
                    setSelectedTextObject(null);
                  }}
                >
                  Copy and Paste
                </ContextMenuItem>
                {/* <MenuItem divider />
              <ContextMenuItem
                onClick={() => {
                  handleCopyText({ type: 'content-end', text: selectedStateObject.text });
                  setSelectedTextObject(null);
                }}
              >
                Copy to End
              </ContextMenuItem> */}
              </ContextMenuWrapper>
            )}
          </div>
        </>
      )}
    </Container>
  );
};

OriginalPage.propTypes = {
  data: PropTypes.object,
  handleCopyText: PropTypes.func,
  extractError: PropTypes.string,
  showFullLeft: PropTypes.bool,
  setShowFullLeft: PropTypes.func,
  setAspectRatio: PropTypes.func,
};

export default OriginalPage;
