import React, { useReducer } from 'react';
import styled from 'styled-components';
import produce from 'immer';
import { Input, Icon } from 'semantic-ui-react';

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 20px;
`;

const PillWrapper = styled.div`
  background-color: ${(params) => (params.selected ? '#fff' : '#f3f3f3')};
  height: 25px;
  border: ${(params) => (params.selected ? '2px solid rgba(34,36,38,.15)' : 'none')};
  border-radius: 10.5px;
  display: flex;
  align-items: center;
  padding: 15px;
  position: relative;
  margin: 5px 5px 5px 0;
  cursor: pointer;
  input {
    border: none !important;
    padding: 0.3em !important;
  }
  i.icon {
    margin: 0.3em 0.25rem 0 0 !important;
  }
`;

const SubText = styled.div`
  color: #4a4a4a;
  font-weight: 300;
`;

const EditText = styled(Input)`
  padding: 0.3em !important;
`;

const CancelIcon = styled(Icon)`
  padding-bottom: 1.4em;
`;

const initialValue = {
  localFilters: {},
  field: '',
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_FIELD':
        draft.field = action.value;
        break;
    }
  });

const Pills = ({ filters, editField, edited, edit, current }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...initialValue,
    localFilters: filters,
  });

  const { field, localFilters } = state;

  if (!Object.keys(filters).length) return null;

  const editPill = (e, key) => {
    e.preventDefault();
    e.stopPropagation();
    edited(key);
    dispatch({ type: 'SET_FIELD', value: key.title || key.brand_name });
  };

  const editFields = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (field.trim() === edit.brand_name || field.trim() === edit.title) edited({});
    else editField(field.trim(), edit);
  };

  const cancelEdit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    edited({});
  };

  return (
    <Container>
      {localFilters.map((searchKey) => (
        <PillWrapper
          key={searchKey.id}
          onClick={(e) => editPill(e, searchKey)}
          selected={searchKey.id === edit.id && searchKey[current]}
        >
          {edit.id !== searchKey.id || (edit.id === searchKey.id && !searchKey[current]) ? (
            <SubText>{searchKey.title || searchKey.brand_name}</SubText>
          ) : (
            <>
              <EditText
                defaultValue={searchKey.title || searchKey.brand_name}
                onChange={(e) => dispatch({ type: 'SET_FIELD', value: e.target.value })}
                action={
                  <Icon
                    name="check"
                    onClick={(e) => editFields(e)}
                    disabled={!field || !field.trim()}
                  />
                }
              />
              <CancelIcon name="cancel" onClick={(e) => cancelEdit(e)} />
            </>
          )}
        </PillWrapper>
      ))}
    </Container>
  );
};

export default Pills;
