import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, Field, ErrorMessage } from 'formik';
import PropTypes from 'prop-types';
import { Button, Icon, Form, Dropdown, Loader } from 'semantic-ui-react';
import { editorScoreQualityOptions } from 'Container/Dashboard/StaticOptions';
import { fetchUsers } from 'Api';

const MainForm = styled(Form)`
  padding: 20px;
`;
const CustomLoader = styled(Loader)`  
   &:after {
    border-color: #fff #a6a6a6 #a6a6a6 !important;    
    width: 40px !important;
    height: 40px !important;
   }
`;
const LoaderWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 10px;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 200;
`;

const FormFields = styled.div`
  padding: 20px;
   input {
     margin-top: 10px !important;
   }
`;

const ButtonWeapper = styled.div`
  padding: 20px;  
`;

const DropDownFields = styled.div`
  display: flex;
  width:100%;
  gap:25px
`;

const FieldError = styled.span`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const CustomField = styled(Field)`
  border: ${(param) => param.error && '1px solid #cc0000 !important'};
`;
const EditorScoreLabel = styled.span`
  font-size: 14px;
  line-height: 14px;    
  margin-bottom: 6px; 
  display: block; 
`;

const EditorScoreWrapper = styled.div`   
  display: flex;
  flex-direction: column;
  align-items: flex-start; 
  margin-bottom: 10px;
  width: 50%;
`;

const EditorScoreDropDownWrapper = styled.div`
  width: 100%;
  margin-top: 5px;   
  Dropdown : {
  background-color:red;
  }
`;
const DigestDropDownWrapper = styled.div`  
  padding: 10px 0px;     
  .description{    
    margin-bottom: 15px !important;
    margin-left: 0 !important;
  }
  .ui.search.dropdown>input.search{
    margin-top:0px !important;
  }
`;

const CreateDigestForm = (props) => {
    const { closeModal, addDigest, notificationData, createDigestLoading } = props;
    const { id, title } = notificationData
    const [authorsOptions, setAuthorsOptions] = useState([])
    const fetchAuthors = useCallback(() => {
        fetchUsers(1, 50).then((res) => {
            const { results } = res.data
            setAuthorsOptions(results.filter((staff) => staff.is_active)
                .map((staff) => ({
                    ...staff,
                    key: staff.id,
                    value: staff.id,
                    text: staff.name,
                })))
        }).catch((err) => {
            setAuthorsOptions([])
        })
    }, []);

    useEffect(() => {
        fetchAuthors()
    }, [fetchAuthors])
    return (
        <>
            {createDigestLoading && <LoaderWrapper> <CustomLoader active /></LoaderWrapper>}
            <Formik
                initialValues={{
                    notification_id: id, title: title || '', digest_author:
                        parseInt(localStorage.getItem('loggedUserId'), 10) ?? "", editor_score_quality: 0, editor_score_relevance: 0
                }}
                validate={validate}
                onSubmit={(values) => {
                    addDigest({ values });
                }}
            >
                {({ handleSubmit, values, errors, touched, setFieldValue }) => (

                    < MainForm onSubmit={handleSubmit}>
                        <FormFields>
                            <label htmlFor="title">Title:</label>
                            <CustomField
                                name="title"
                                type="text"
                                placeholder="Title"
                                error={errors.title && touched.title}
                            />
                            <FieldError>
                                <ErrorMessage name="title" />
                            </FieldError>
                        </FormFields>
                        <FormFields>
                            <label htmlFor="digest_author">Author:</label>
                            <DigestDropDownWrapper>
                                <Dropdown
                                    placeholder="Select Author"
                                    defaultValue={values.digest_author}
                                    fluid
                                    clearable
                                    search
                                    selection
                                    onChange={(e, val) => {
                                        val.options.forEach((item) => {
                                            if (item.id === val.value) {
                                                setFieldValue("digest_author", item.id)
                                            }
                                        });
                                    }}
                                    options={authorsOptions}
                                />
                            </DigestDropDownWrapper>
                        </FormFields>
                        <FormFields>
                            <DropDownFields>
                                <EditorScoreWrapper>
                                    <EditorScoreLabel>Editor's Score Quality</EditorScoreLabel>
                                    <EditorScoreDropDownWrapper>
                                        <Dropdown
                                            placeholder="Select"
                                            name="editor_score_quality"
                                            fluid
                                            selection
                                            onChange={(e, val) => {
                                                setFieldValue("editor_score_quality", val.value)
                                            }}
                                            options={editorScoreQualityOptions}
                                        />
                                    </EditorScoreDropDownWrapper>
                                </EditorScoreWrapper>
                                <EditorScoreWrapper>
                                    <EditorScoreLabel>Editor's Score Relevance</EditorScoreLabel>
                                    <EditorScoreDropDownWrapper>
                                        <Dropdown
                                            name="editor_score_relevance"
                                            placeholder="Select"
                                            fluid
                                            selection
                                            onChange={(e, val) => {
                                                setFieldValue("editor_score_relevance", val.value)
                                            }}
                                            options={editorScoreQualityOptions}
                                        />
                                    </EditorScoreDropDownWrapper>
                                </EditorScoreWrapper>
                            </DropDownFields>
                        </FormFields>
                        <ButtonWeapper>
                            <Button type="button" onClick={closeModal}>
                                <Icon name="remove" /> Cancel
                            </Button>
                            <Button disabled={createDigestLoading} positive type="submit">
                                <Icon name="save" /> Save
                            </Button>
                        </ButtonWeapper>
                    </MainForm>
                )
                }
            </Formik >
        </>
    );
};
const validate = (values) => {
    const errors = {};
    if (!values.title || !values.title.trim().length) {
        errors.title = 'Please enter title';
    }
    return errors;
};

CreateDigestForm.propTypes = {
    closeModal: PropTypes.func,
    addDigest: PropTypes.func,
    notificationData: PropTypes.object,
};

export default CreateDigestForm;
