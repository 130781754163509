import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import 'react-datetime/css/react-datetime.css';
import { Button, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import { fetchUsers } from '../../Api';

const Wrapper = styled.div``;
const FilterFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const FilterFieldTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  margin-right: 10px;
  min-width: 200px;
`;

const DateTimeWrapper = styled.div`
  font-weight: 600 !important;
  font-size: 16px !important;
  border: 2px solid #d4d4d5;
  .rdt {
    width: 100%;
    height: 100%;
    input {
      width: 100%;
      height: 100%;
      font-family: Metropolis, sans-serif !important;
      text-transform: uppercase;
      border: none !important;
      outline: none !important;
      color: rgba(0, 0, 0, 0.8) !important;
      padding: 8px 15px !important;
    }
  }

  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const FilterFieldValue = styled.div`
  width: 30%;

  .ui.fluid.dropdown {
    border: 2px solid #d4d4d5;
  }
`;

const FieldError = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const checkFromDateIsbeforeTo = (from, to) => moment(to).isAfter(from);

const Filters = ({ handleFetchQualityReport }) => {
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date().setHours(0, 0, 0, 0)));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).add(1, 'm'));
  const [selectedAuthors, setSelectedAuthors] = useState('');
  const [userDataList, setUserDataList] = useState([]);
  const [errors, setErrors] = useState({});

  const handleClearFilter = () => {
    setSelectedFromDate(moment(new Date()));
    setSelectedToDate(moment(new Date()).add(1, 'm'));
    // setSelectedScore([]);
    setSelectedAuthors([]);
  };

  const fetchDailyReport = () => {
    const tempErrors = {};

    if (!checkFromDateIsbeforeTo(selectedFromDate, selectedToDate)) {
      tempErrors.selectedToDate = 'To date always larger than from date.';
    }

    if (Object.keys(tempErrors).length > 0) {
      setErrors(tempErrors);
    } else {
      handleFetchQualityReport({
        selectedAuthors,
        selectedFromDate,
        selectedToDate,
        // selectedScore,
      });
    }
  };

  useEffect(() => {
    fetchUsers(1, 100)
      .then((resposne) => {
        const data = (resposne.data.results || []).map((item) => ({
          ...item,
          key: item.id,
          value: item.id,
          text: item.name,
        }));
        setUserDataList(data);
      })
      .catch(() => {});
  }, []);

  return (
    <Wrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select From Date</FilterFieldTitle>
        <FilterFieldValue>
          <DateTimeWrapper>
            <Datetime
              // timeFormat={false}
              value={selectedFromDate}
              dateFormat="MMM DD YYYY"
              onChange={(value) => {
                if (typeof value === 'object') {
                  setSelectedFromDate(value);
                }
              }}
            />
          </DateTimeWrapper>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select To Date</FilterFieldTitle>
        <FilterFieldValue>
          <DateTimeWrapper>
            <Datetime
              // timeFormat={false}
              value={selectedToDate}
              dateFormat="MMM DD YYYY"
              onChange={(value) => {
                if (typeof value === 'object') {
                  setSelectedToDate(value);
                }
              }}
            />
          </DateTimeWrapper>
          <FieldError>{errors.selectedToDate}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Author</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Authors"
            fluid
            value={selectedAuthors}
            search
            selection
            onChange={(e, props) => setSelectedAuthors(props.value)}
            options={userDataList}
          />
          <FieldError>{errors.selectedAuthors}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <ButtonsWrapper>
        <Button
          positive
          disabled={!selectedAuthors}
          onClick={() => {
            fetchDailyReport();
          }}
        >
          Fetch Report
        </Button>
        <Button
          onClick={() => {
            handleClearFilter();
          }}
        >
          Clear
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

Filters.propTypes = {
  handleFetchQualityReport: PropTypes.func,
  value: PropTypes.string,
};

export default Filters;
