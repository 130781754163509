import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import styled from 'styled-components';


const ModalSaveButton = styled(Button)`
  background-color: #E12830 !important;
  color: #fff !important;
`;

const ModalContent = styled(Modal.Content)`
  font-size: 18px !important;
  font-weight: 700 !important;
  text-align: center;

  div {
    margin: 10px;
  }
`;

const AutoSummaryAlert = ({
  active,
  onHandleNo,
  onHandleYes
}) => (
  <Modal
    size="tiny"
    open={active}
  >
    <ModalContent>
        <div>Are you sure you want to proceed?</div>
        <div>You will lose current segments</div>
    </ModalContent>
    <Modal.Actions>
      <Button
        onClick={onHandleNo}
      >No</Button>
      <ModalSaveButton
        onClick={onHandleYes}
      >Yes</ModalSaveButton>
    </Modal.Actions>
  </Modal>
);

export default AutoSummaryAlert;