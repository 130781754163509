import React, { useEffect, useReducer, useCallback } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import Layout from '../../Components/Layout';
import CreateNewsFeedForm from './CreateNewsFeedForm';
import { fetchAllFeedCategories, saveFeeds, updateFeedSource } from '../../Api/';

const Header = styled.div`
    font-size: 2.125rem;
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height: 1.25em;
    margin-bottom: 2rem;
`;

const SubHeading = styled.div`
    color: #757575;
    font-size: 1.25rem;
    letter-spacing: 0;
    line-height: 1.4em;
    margin-bottom: 2.5rem;
`;

const initialValue = {
    categoryId: 0,
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, draft => {
    switch(action.type) {
      case 'CATEGORIES_SET':
            draft.categories = action.value;
            break;
    }
  });


const NewsFeedCreate = props => {
    const [state, dispatch] = useReducer(reducer, initialValue);
    const { categories } = state;
    const feedState = props.location.state;

    const fetchCategories = useCallback(() => {
        fetchAllFeedCategories().then(response => {
            dispatch({ type: 'CATEGORIES_SET', value: response.data.results })
        });
    }, []);

    useEffect(() => {
        fetchCategories();
    }, [fetchCategories]);

    const saveNewFeed = ({ values, setSubmitting, resetForm }) => {
        setSubmitting(true);
        if (feedState) {
            updateFeedSource({ title: values.title.trim(), id: feedState.feed.id }).then(response => {
                ToastsStore.success("Feed updated successfully");
                setSubmitting(false);
                resetForm(true);
                props.history.push(`/board-details/${feedState.categoryId}/sources/`)
            }).catch(error => {
                setSubmitting(false);
                ToastsStore.error("Unable to update Feed.");
            });
        } else {
            saveFeeds(values).then(response => {
                ToastsStore.success("Feed created successfully");
                setSubmitting(false);
                resetForm(true);
            }).catch(error => {
                setSubmitting(false);
                ToastsStore.error("Unable to create Feed.");
            });
        }
    };

    return (
        <Layout location={props.location} feed={true}>
            <Header>
            {feedState ? 'Edit' : 'Create New'} Feed
            </Header>
            <SubHeading>
                Add all the sources you read from so that you don't miss out on any.
            </SubHeading>
            {categories && (
                <CreateNewsFeedForm
                    saveFeeds={saveNewFeed}
                    categories={categories}
                    feedState={feedState ?
                            feedState.feed : {
                                url: '',
                                title: '',
                                categoryId: '',
                    }   }
                />
            )}
            <ToastsContainer
                position={ToastsContainerPosition.BOTTOM_LEFT}
                store={ToastsStore}
            />
        </Layout>
    )
};

export default NewsFeedCreate;