import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Datetime from 'react-datetime';
import PropTypes from 'prop-types';
import 'react-datetime/css/react-datetime.css';
import { Button, Dropdown, Icon } from 'semantic-ui-react';
import moment from 'moment';
import Chips from 'react-chips';
import {
  fetchUsers,
  fetchArticlesFormat,
  fetchDigestType,
  fetchTagsData,
  fetchCategoriesData,
  fetchLanguages,
} from '../../Api';

const Wrapper = styled.div``;
const FilterFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

const FilterFieldTitle = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  margin-right: 10px;
  min-width: 200px;
`;

const DateTimeWrapper = styled.div`
  font-weight: 600 !important;
  font-size: 16px !important;
  border: 2px solid #d4d4d5;
  .rdt {
    width: 100%;
    height: 100%;
    input {
      width: 100%;
      height: 100%;
      font-family: Metropolis, sans-serif !important;
      text-transform: uppercase;
      border: none !important;
      outline: none !important;
      color: rgba(0, 0, 0, 0.8) !important;
      padding: 8px 15px !important;
    }
  }

  @media (max-width: 768px) {
    font-size: 11px !important;
  }
`;

const FilterFieldValue = styled.div`
  width: 30%;

  .ui.fluid.dropdown {
    border: 2px solid #d4d4d5;
  }
`;

const FieldError = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const ChipWrapper = styled.div`
  position: relative;
  background-color: rgb(233, 233, 233);
  color: rgb(128, 128, 128);
  font-size: 14px;
  font-weight: 500;
  padding: 5px 30px 5px 10px;
  margin: 0 10px 0 0px;
  border-radius: 4px;
  font-family: Metropolis, sans-serif;
`;

const ChipCloseIcon = styled.div`
  background-color: #fff;
  padding: 1px 2px;
  position: absolute;
  top: 4px;
  right: 2px;
  border-radius: 50%;
  cursor: pointer;
  i {
    color: rgb(128, 128, 128);
    margin: 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
`;

const checkFromDateIsbeforeTo = (from, to) => moment(to).isAfter(from);

const Filters = ({ handleFetchDailyReport }) => {
  const [selectedFromDate, setSelectedFromDate] = useState(moment(new Date().setHours(0, 0, 0, 0)));
  const [selectedToDate, setSelectedToDate] = useState(moment(new Date()).add(1, 'm'));
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCatgegories, setSelectedCatgegories] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState('');
  const [selectedChipIdsTags, setSelectedChipIdsTags] = useState([]);
  const [selectedAuthors, setSelectedAuthors] = useState([]);
  // const [selectedScore, setSelectedScore] = useState([]);
  const [selectedReviewer, setSelectedReviewer] = useState([]);
  const [selectedFormats, setSelectedFormats] = useState([]);
  const [selectedDigestTypes, setSelectedDigestTypes] = useState([]);
  const [userDataList, setUserDataList] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [languagesData, setLanguagesData] = useState([]);
  const [digestFormatList, setDigestFormatList] = useState([]);
  const [digestTypeList, setDigestTypeList] = useState([]);
  const [errors, setErrors] = useState({});

  const handleClearFilter = () => {
    setSelectedFromDate(moment(new Date()));
    setSelectedToDate(moment(new Date()).add(1, 'm'));
    setSelectedTags([]);
    setSelectedCatgegories([]);
    setSelectedLanguages('');
    // setSelectedScore([]);
    setSelectedAuthors([]);
    setSelectedReviewer([]);
    setSelectedFormats([]);
    setSelectedDigestTypes([]);
  };

  const fetchDailyReport = () => {
    const tempErrors = {};

    if (!checkFromDateIsbeforeTo(selectedFromDate, selectedToDate)) {
      tempErrors.selectedToDate = 'To date always larger than from date.';
    }

    if (Object.keys(tempErrors).length > 0) {
      setErrors(tempErrors);
    } else {
      handleFetchDailyReport({
        selectedCatgegories,
        selectedAuthors,
        selectedReviewer,
        selectedFormats,
        selectedDigestTypes,
        selectedFromDate,
        selectedToDate,
        selectedTags,
        selectedLanguages,
        // selectedScore,
      });
    }
  };

  useEffect(() => {
    fetchDailyReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hanldeSelectTags = (chips) => {
    setSelectedTags(chips);
    setSelectedChipIdsTags(chips.map((chip) => chip.id));
  };

  const fetchTagSuggestions = async (value, chips) => {
    try {
      const response = await fetchTagsData({ searchString: value });
      const data = response.data.results;
      return data.filter((item) => !selectedChipIdsTags.includes(item.id));
    } catch (error) {
      console.error(error);
    }
    return [];
  };

  useEffect(() => {
    fetchUsers(1, 100)
      .then((resposne) => {
        const data = (resposne.data.results || []).map((item) => ({
          ...item,
          key: item.id,
          value: item.id,
          text: item.name,
        }));
        setUserDataList(data);
      })
      .catch(() => {});

    fetchArticlesFormat()
      .then((resposne) => {
        const data = (resposne.data.results || []).map((item) => ({
          ...item,
          key: item.id,
          value: item.id,
          text: item.section_name,
        }));
        setDigestFormatList(data);
      })
      .catch(() => {});
    fetchDigestType()
      .then((resposne) => {
        const data = (resposne.data.results || []).map((item) => ({
          ...item,
          key: item.id,
          value: item.id,
          text: item.title,
        }));
        setDigestTypeList(data);
      })
      .catch(() => {});
    fetchCategoriesData()
      .then((resposne) => {
        const data = (resposne.data.results || []).map((item) => ({
          ...item,
          key: item.id,
          value: item.id,
          text: item.title,
        }));
        setCategoriesData(data);
      })
      .catch(() => {});
    fetchLanguages()
      .then((resposne) => {
        const data = (resposne.data.results || []).map((item) => ({
          ...item,
          key: item.id,
          value: item.id,
          text: item.title,
        }));
        setLanguagesData(data);
      })
      .catch(() => {});
  }, []);

  return (
    <Wrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select From Date</FilterFieldTitle>
        <FilterFieldValue>
          <DateTimeWrapper>
            <Datetime
              // timeFormat={false}
              value={selectedFromDate}
              dateFormat="MMM DD YYYY"
              onChange={(value) => {
                if (typeof value === 'object') {
                  setSelectedFromDate(value);
                }
              }}
            />
          </DateTimeWrapper>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select To Date</FilterFieldTitle>
        <FilterFieldValue>
          <DateTimeWrapper>
            <Datetime
              // timeFormat={false}
              value={selectedToDate}
              dateFormat="MMM DD YYYY"
              onChange={(value) => {
                if (typeof value === 'object') {
                  setSelectedToDate(value);
                }
              }}
            />
          </DateTimeWrapper>
          <FieldError>{errors.selectedToDate}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      {/* <FilterFieldWrapper>
        <FilterFieldTitle>Select Score</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Score"
            fluid
            value={selectedScore}
            multiple
            search
            selection
            onChange={(e, props) => setSelectedScore(props.value)}
            options={EditorScoreOptions}
          />
          <FieldError>{errors.selectedAuthors}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper> */}
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Authors</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Authors"
            fluid
            value={selectedAuthors}
            multiple
            search
            selection
            onChange={(e, props) => setSelectedAuthors(props.value)}
            options={userDataList}
          />
          <FieldError>{errors.selectedAuthors}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Reviewer</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Reviewer"
            fluid
            value={selectedReviewer}
            multiple
            search
            selection
            onChange={(e, props) => setSelectedReviewer(props.value)}
            options={userDataList}
          />
          <FieldError>{errors.selectedReviewer}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Formats</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Formats"
            fluid
            value={selectedFormats}
            multiple
            search
            selection
            onChange={(e, props) => setSelectedFormats(props.value)}
            options={digestFormatList}
          />
          <FieldError>{errors.selectedFormats}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Digest Types</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Digest Types"
            fluid
            value={selectedDigestTypes}
            multiple
            search
            selection
            onChange={(e, props) => setSelectedDigestTypes(props.value)}
            options={digestTypeList}
          />
          <FieldError>{errors.selectedDigestTypes}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Tags</FilterFieldTitle>
        <FilterFieldValue>
          <Chips
            placeholder="Select Tags"
            value={JSON.parse(JSON.stringify(selectedTags))}
            onChange={hanldeSelectTags}
            fetchSuggestions={fetchTagSuggestions}
            // alwaysRenderSuggestions={true}
            // createChipKeys={[13]}
            title="Tag"
            renderChip={(chipData) => (
              <ChipWrapper>
                {chipData.title || chipData}
                <ChipCloseIcon
                  onClick={() => {
                    let index;
                    if (chipData.id)
                      index = selectedTags.findIndex((tag) => tag.id === chipData.id);
                    else index = selectedTags.findIndex((tag) => tag === chipData);
                    const tagsData = JSON.parse(JSON.stringify(selectedTags));
                    tagsData.splice(index, 1);
                    hanldeSelectTags(tagsData);
                  }}
                >
                  <Icon size="small" name="close" />
                </ChipCloseIcon>
              </ChipWrapper>
            )}
            renderSuggestion={(data) => <span>{data.title}</span>}
            suggestionsFilter={(opt, val) =>
              selectedTags.findIndex((tag) => tag.id === opt.id) === -1
            }
          />
          <FieldError>{errors.selectedTags}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Categories</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Categories"
            fluid
            value={selectedCatgegories}
            multiple
            search
            selection
            onChange={(e, props) => setSelectedCatgegories(props.value)}
            options={categoriesData}
          />
          <FieldError>{errors.selectedCatgegories}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <FilterFieldWrapper>
        <FilterFieldTitle>Select Language</FilterFieldTitle>
        <FilterFieldValue>
          <Dropdown
            placeholder="Select Language"
            fluid
            value={selectedLanguages}
            // multiple
            search
            selection
            clearable
            onChange={(e, props) => {
              setSelectedLanguages(props.value);
            }}
            options={languagesData}
          />
          <FieldError>{errors.selectedCatgegories}</FieldError>
        </FilterFieldValue>
      </FilterFieldWrapper>
      <ButtonsWrapper>
        <Button
          positive
          onClick={() => {
            fetchDailyReport();
          }}
        >
          Fetch Report
        </Button>
        <Button
          onClick={() => {
            handleClearFilter();
          }}
        >
          Clear
        </Button>
      </ButtonsWrapper>
    </Wrapper>
  );
};

Filters.propTypes = {
  handleFetchDailyReport: PropTypes.func,
  value: PropTypes.string,
};

export default Filters;
