import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Loader, Dimmer } from 'semantic-ui-react';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { fetchDailyReport, fetchQualityReport } from '../../Api';
import AuthorTable from './authorTable';
import DigestScoreTable from './digestScoreTable';
import QualityTable from './qualityTable';
import CatgeoryTable from './categoryTable';
import FormatTable from './formatTable';
import ReviewerTable from './reviewerTable';
import StatusTable from './statusTable';
import TagsTable from './tagsTable';
import TypeTable from './typeTable';
import NotificationTable from './notificationTable';
import VideoReportTable from './videoReportTable';
import Layout from '../../Components/Layout';
import Filters from './Filters';
import Toggle from '../../Components/Form/Toggle';
import FilterQuality from './FilterQuality';

const Wrapper = styled.div`
  padding: 0 20px;
  position: relative;
`;

const DimmerWrapper = styled.div`
  height: 200px;
  position: relative;
`;

const PageTitle = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  text-transform: uppercase;
`;

const SectionWrapper = styled.div`
  padding: 20px 0 30px;
  border-bottom: 1px solid #d8d8d8;
`;

// const SelectedDateText = styled.div`
//   font-size: 20px;
//   font-weight: 700;
//   line-height: 1.4;
//   margin-Top: 15px;
//   color: rgba(0, 0, 0, .7);
// `;

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 900;
  line-height: 1.4;
  margin-bottom: 15px;
`;

const SectionTableWrapper = styled.div`
  .rc-table-scroll,
  .rc-table {
    min-height: unset !important;
  }

  tr {
    cursor: unset;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const ToggleWrapper = styled.div`
  text-align: center;
  margin: 20px 0;

  span {
    margin-right: 5px;
    justify-content: center;
  }
`;
const ValueText = styled.span`
  font-size: 15px;
  font-weight: 100;
`;
const DailyReport = ({ location }) => {
  // const [selectedDate, setSelectedDate] = useState(moment(new Date()));
  const [loadingReport, setLoadingReport] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [showDigestList, setShowDigestList] = useState(-1);
  const [showTagDigestList, setShowTagDigestList] = useState(-1);
  const [qualityReport, showQualityReport] = useState(false);

  const openDigestList = (val, e) => {
    if (showDigestList !== val) setShowDigestList(val);
    else setShowDigestList(-1);
    if (e && e.relatedTarget) e.relatedTarget.click();
  };

  const openTagDigestList = (val, e) => {
    if (showTagDigestList !== val) setShowTagDigestList(val);
    else setShowDigestList(-1);
    if (e && e.relatedTarget) e.relatedTarget.click();
  };

  const handleFetchDailyReport = ({
    selectedCatgegories,
    selectedAuthors,
    // selectedScore,
    selectedReviewer,
    selectedFormats,
    selectedDigestTypes,
    selectedFromDate,
    selectedToDate,
    selectedTags,
    selectedLanguages,
  }) => {
    setShowDigestList(-1);
    setLoadingReport(true);
    fetchDailyReport({
      // date : moment(selectedDate).format('YYYY-MM-DD HH:mm'),
      fromDate: moment(selectedFromDate).format('YYYY-MM-DD HH:mm'),
      toDate: moment(selectedToDate).format('YYYY-MM-DD HH:mm'),
      authorUserIds: (selectedAuthors.length && selectedAuthors) || undefined,
      // editorScoreQuality: (selectedScore.length && selectedScore) || undefined,
      reviewerUserIds: (selectedReviewer.length && selectedReviewer) || undefined,
      formatIds: (selectedFormats.length && selectedFormats) || undefined,
      categoryIds: (selectedCatgegories.length && selectedCatgegories) || undefined,
      tagIds: (selectedTags.length && selectedTags) || undefined,
      typeIds: (selectedDigestTypes.length && selectedDigestTypes) || undefined,
      languageId: selectedLanguages || null,
    })
      .then((response) => {
        setReportData(response.data);

        setLoadingReport(false);
      })
      .catch((error) => {
        setLoadingReport(false);
      });
  };

  const handleFetchQualityReport = ({ selectedAuthors, selectedFromDate, selectedToDate }) => {
    setShowDigestList(-1);
    setLoadingReport(true);
    fetchQualityReport({
      fromDate: moment(selectedFromDate).format('YYYY-MM-DD HH:mm'),
      toDate: moment(selectedToDate).format('YYYY-MM-DD HH:mm'),
      authorUserIds: [selectedAuthors] || undefined,
    })
      .then((response) => {
        setReportData(response.data);

        setLoadingReport(false);
      })
      .catch((error) => {
        setLoadingReport(false);
      });
  };

  useEffect(() => {
    // handleFetchDailyReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout location={location}>
      <Wrapper
        onClick={() => {
          openDigestList(-1);
          openTagDigestList(-1);
        }}
      >
        <Helmet>
          <title>XND | Daily Report</title>
        </Helmet>
        <Flex>
          <PageTitle>Daily Report</PageTitle>
          <ToggleWrapper>
            <Flex>
              <Toggle
                on={qualityReport}
                onClick={() => {
                  setReportData(null);
                  showQualityReport(!qualityReport);
                }}
              />
              Quality Report
            </Flex>
          </ToggleWrapper>
        </Flex>
        {!qualityReport ? (
          <>
            <Filters handleFetchDailyReport={handleFetchDailyReport} />
            {loadingReport && (
              <DimmerWrapper>
                <Dimmer active inverted>
                  <Loader active />
                </Dimmer>
              </DimmerWrapper>
            )}
            {reportData && !loadingReport && (
              <>
                {/* <SelectedDateText>{`Report Date - ${moment(selectedDate).format('MMM DD YYYY hh:mm A')}`}</SelectedDateText> */}
                <SectionWrapper>
                  {process.env.REACT_APP_ENV === 'clip-production' &&
                  reportData.avg_video_length !== 0 ? (
                      <SectionTitle>
                      Avg Video Length:
                        <ValueText>
                          {'  '}
                          {reportData.avg_video_length}
                        </ValueText>
                      </SectionTitle>
                    ) : null}

                  {reportData.avg_word_count !== 0 ? (
                    <SectionTitle>
                      Avg Word Count:
                      <ValueText>
                        {'  '}
                        {reportData.avg_word_count}
                      </ValueText>
                    </SectionTitle>
                  ) : null}

                  <SectionTitle>Digest Score:</SectionTitle>
                  <SectionTableWrapper>
                    <DigestScoreTable
                      data={reportData.editor_score_quality}
                      openDigestList={openDigestList}
                      showDigestList={showDigestList}
                    />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Digest Authors:</SectionTitle>
                  <SectionTableWrapper>
                    <AuthorTable data={reportData.author} />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Digest Catgeory:</SectionTitle>
                  <SectionTableWrapper>
                    <CatgeoryTable data={reportData.category} />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Digest Format:</SectionTitle>
                  <SectionTableWrapper>
                    <FormatTable data={reportData.format_id} />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Digest Reviewer:</SectionTitle>
                  <SectionTableWrapper>
                    <ReviewerTable data={reportData.reviewer} />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Digest Status:</SectionTitle>
                  <SectionTableWrapper>
                    <StatusTable data={reportData.status} />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Digest Tags:</SectionTitle>
                  <SectionTableWrapper>
                    <TagsTable
                      data={reportData.tags}
                      openTagDigestList={openTagDigestList}
                      showTagDigestList={showTagDigestList}
                    />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Digest Types:</SectionTitle>
                  <SectionTableWrapper>
                    <TypeTable data={reportData.type_id} />
                  </SectionTableWrapper>
                </SectionWrapper>
                <SectionWrapper>
                  <SectionTitle>Notifications:</SectionTitle>
                  <SectionTableWrapper>
                    <NotificationTable data={[reportData.notification]} />
                  </SectionTableWrapper>
                </SectionWrapper>
                {/* <SectionWrapper>
                  <SectionTitle>Published Videos Report:</SectionTitle>
                  <SectionTableWrapper>
                    {reportData.notification.metrics.not_include_all_channel_slugs.length > 0 ? (
                      <VideoReportTable
                        data={reportData.notification.metrics.not_include_all_channel_slugs}
                      />
                    ) : (
                      <p>No Data</p>
                    )}
                  </SectionTableWrapper>
                </SectionWrapper> */}
                <SectionWrapper>
                  <SectionTitle>
                    Published Videos Report:{'  '}
                    <CSVLink data={reportData.video_stats} filename="public_report.csv">
                      Download
                    </CSVLink>
                  </SectionTitle>
                  <SectionTableWrapper>
                    {reportData.video_stats.length > 0 ? (
                      <VideoReportTable data={reportData.video_stats} />
                    ) : (
                      <p>No Data</p>
                    )}
                  </SectionTableWrapper>
                </SectionWrapper>
              </>
            )}
          </>
        ) : (
          <>
            <FilterQuality handleFetchQualityReport={handleFetchQualityReport} />
            {loadingReport && (
              <DimmerWrapper>
                <Dimmer active inverted>
                  <Loader active />
                </Dimmer>
              </DimmerWrapper>
            )}
            {reportData && !loadingReport && (
              <>
                {/* <SelectedDateText>{`Report Date - ${moment(selectedDate).format('MMM DD YYYY hh:mm A')}`}</SelectedDateText> */}
                <SectionWrapper>
                  <SectionTitle>Quality:</SectionTitle>
                  <SectionTableWrapper>
                    <QualityTable
                      data={reportData.results}
                      openDigestList={openDigestList}
                      showDigestList={showDigestList}
                    />
                  </SectionTableWrapper>
                </SectionWrapper>
              </>
            )}
          </>
        )}
      </Wrapper>
    </Layout>
  );
};

DailyReport.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

export default DailyReport;
