import React from 'react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';

const activeMenuColor = 'rgb(233, 30, 99)';

const menuWrapperAnimation = keyframes`
  0% {
    transform: translateY(100%);
  }

  5% {
    display: block;
  }

  100% {
    tranform: translate(0%);
  }
`;

const MenuMain = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  scroll-height: ${({ height }) => height};
  z-index: 10;
  background-color: #fff;
  width: 70px;
  overflow: hidden !important;
  transition: all 300ms ease 0s;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 6px, rgba(0, 0, 0, 0.15) 0px 3px 6px;
  &:hover {
    width: 300px !important;
    .vertical.menu {
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    top: unset;
    left: 0;
    bottom: 0;
    right: 0;
    max-height: 65vh;
    overflow: auto !important;
    padding: 30px 0;
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    /* animation: ${menuWrapperAnimation} 1s ease; */
    transform: translateY(${({ isShowSideBar }) => (isShowSideBar ? '0%' : '100%')});

    &:hover {
      width: 100% !important;
    }
  }
`;

const Menu = styled.div`
  width: 300px !important;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
`;

const IconWrapper = styled.span`
  width: 60px;
  text-align: center;
  display: inline-block;
`;

const MenuItem = styled.div`
  width: 100%;
  white-space: nowrap;
  display: flex;
  color: rgb(103, 128, 152);
  font-weight: 600;
  padding: 15px 15px 15px 0;
  font-size: 16px;
  line-height: 18px;
  color: ${({ active }) => active && activeMenuColor};
  border-left: 5px solid ${({ active }) => (active ? activeMenuColor : 'transparent')};

  &:hover {
    color: ${({ active }) => (active ? activeMenuColor : 'rgb(103, 128, 152)')};
    border-left: 5px solid ${activeMenuColor};
  }
`;

const LinkItemText = styled.span`
  margin-left: 10px;
`;

const ActionLink = styled(({ active, ...rest }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Link {...rest} />
))``;

const matchActiveMenu = (path, testPath) => {
  const matchData = testPath.filter((url) =>
    matchPath(path, {
      path: url,
      exact: true,
      strict: false,
    }),
  );

  return matchData.length > 0;
};

export const Sidebar = (props) => (
  <MenuMain isShowSideBar={props.isShowSideBar}>
    <Menu vertical size="large">
      <MenuItem
        title="Digests"
        as={ActionLink}
        to="/"
        height={document.body.scrollHeight}
        active={matchActiveMenu(props.location.pathname, ['/'])}
      >
        <IconWrapper>
          <Icon name="home" />
        </IconWrapper>
        <LinkItemText>Dashboard</LinkItemText>
      </MenuItem>
      <MenuItem
        title="Add News Digest"
        as={ActionLink}
        to="/digest-create"
        active={matchActiveMenu(props.location.pathname, ['/digest-create', '/digests/:id'])}
      >
        <IconWrapper>
          <Icon name="newspaper outline" />
        </IconWrapper>
        <LinkItemText>Add News Digest</LinkItemText>
      </MenuItem>
      {/* <MenuItem
        title="Add Video Digest"
        as={ActionLink}
        to="/video/search"
        active={matchActiveMenu(props.location.pathname, ['/video/search', '/video/:id'])}
      >
        <IconWrapper>
          <Icon name="video camera" />
        </IconWrapper>
        <LinkItemText>Add Video Digest</LinkItemText>
      </MenuItem> */}
      <MenuItem
        title="View Polls"
        as={ActionLink}
        to="/poll"
        active={matchActiveMenu(props.location.pathname, ['/poll'])}
      >
        <IconWrapper>
          <Icon name="chart bar" />
        </IconWrapper>
        <LinkItemText>View Polls</LinkItemText>
      </MenuItem>
      {localStorage.getItem('privilege') === 'SUPER_USER_PERM' && (
        <>
          <MenuItem
            title="Settings"
            as={ActionLink}
            to="/settings"
            height={document.body.scrollHeight}
            active={matchActiveMenu(props.location.pathname, [
              '/settings',
              '/status-add',
              '/settings/:id',
            ])}
          >
            <IconWrapper>
              <Icon name="settings" />
            </IconWrapper>
            <LinkItemText>Settings</LinkItemText>
          </MenuItem>
          <MenuItem
            title="Users"
            as={ActionLink}
            to="/users"
            active={matchActiveMenu(props.location.pathname, [`/users`])}
          >
            <IconWrapper>
              <Icon name="user" />
            </IconWrapper>
            <LinkItemText>Users</LinkItemText>
          </MenuItem>
          <MenuItem
            title="News Feeds"
            as={ActionLink}
            to="/boards"
            active={matchActiveMenu(props.location.pathname, [
              `/boards`,
              '/board-details/:categoryId',
              '/feed/details/:id',
              '/board',
              '/feed',
            ])}
          >
            <IconWrapper>
              <Icon name="feed" />
            </IconWrapper>
            <LinkItemText>News Feeds</LinkItemText>
          </MenuItem>

          <MenuItem
            title="Daily Reports"
            as={ActionLink}
            to="/dailyReports"
            active={matchActiveMenu(props.location.pathname, ['/dailyReports'])}
          >
            <IconWrapper>
              <Icon name="table" />
            </IconWrapper>
            <LinkItemText>Daily Reports</LinkItemText>
          </MenuItem>
          <MenuItem
            title="Notifications"
            as={ActionLink}
            to="/notifications?selectedUser=all"
            active={matchActiveMenu(props.location.pathname, [
              '/notifications',
              '/notifications/groups',
              '/notification/create',
            ])}
          >
            <IconWrapper>
              <Icon name="bell" />
            </IconWrapper>
            <LinkItemText>Notifications</LinkItemText>
          </MenuItem>
          <MenuItem
            title="Tags"
            as={ActionLink}
            to="/tags"
            active={matchActiveMenu(props.location.pathname, ['/tags'])}
          >
            <IconWrapper>
              <Icon name="tags" />
            </IconWrapper>
            <LinkItemText>Tags</LinkItemText>
          </MenuItem>
          <MenuItem
            title="Headlines"
            as={ActionLink}
            to="/headlines"
            active={matchActiveMenu(props.location.pathname, ['/headlines'])}
          >
            <IconWrapper>
              <Icon name="newspaper" />
            </IconWrapper>
            <LinkItemText>Headlines</LinkItemText>
          </MenuItem>
        </>
      )}
    </Menu>
  </MenuMain>
);

Sidebar.propTypes = {
  currentUser: PropTypes.shape({ privilege: PropTypes.string }),
  location: PropTypes.shape({ pathname: PropTypes.string }),
};

Sidebar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Sidebar;
