export const editorScoreQualityOptions = [
  {
    key: 0,
    value: 0,
    text: 0,
  },
  {
    key: 1,
    value: 1,
    text: 1,
  },
  {
    key: 2,
    value: 2,
    text: 2,
  },
  {
    key: 3,
    value: 3,
    text: 3,
  },
  {
    key: 4,
    value: 4,
    text: 4,
  },
  {
    key: 5,
    value: 5,
    text: 5,
  },
];

export const shelfLifeOption = [
  {
    key: 'DAYS',
    value: 'DAYS',
    text: 'DAYS',
  },
  {
    key: 'WEEKS',
    value: 'WEEKS',
    text: 'WEEKS',
  },
  {
    key: 'MONTHS',
    value: 'MONTHS',
    text: 'MONTHS',
  },
  {
    key: 'YEARS',
    value: 'YEARS',
    text: 'YEARS',
  },
];

export const clickActionsData = [
  {
    key: 'expand2',
    id: 'EXPAND2',
    value: 'EXPAND2',
    text: 'EXPAND2',
  },
  {
    key: 'expand',
    id: 'EXPAND',
    value: 'EXPAND',
    text: 'EXPAND',
  },
  {
    key: 'details',
    id: 'DETAILS',
    value: 'DETAILS',
    text: 'DETAILS',
  },
  {
    key: 'category',
    id: 'category',
    value: 'category',
    text: 'CATEGORY',
  },
  {
    key: 'tag',
    id: 'tag',
    value: 'tag',
    text: 'TAG',
  },
  {
    key: 'host',
    id: 'host',
    value: 'host',
    text: 'HOST',
  },
];
