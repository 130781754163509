/* eslint-disable camelcase */
import React, { useRef, useReducer, useEffect, useCallback } from 'react';
import produce from 'immer';
import styled from 'styled-components';
import { Button, Input, Select, Checkbox } from 'semantic-ui-react';
import 'antd/dist/antd.css';
import 'antd/es/date-picker/style/css';
import { TimePicker } from 'antd';
import moment from 'moment';
import Toggle from '../../../../Components/Form/Toggle';
import { fetchAllStatuses } from '../../../../Api';

const VideoWrapper = styled.div`
  width: 100%;
  position: relative;
  /* padding-bottom: 50px; */
`;

const VideoFooter = styled.div`
  /* width: 100%;
  position: absolute;
  bottom: 0;
  left: 0; */
  padding: 5px 15px;
  height: 50px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  i {
    color: #fff;
  }
`;

// const PlayPauseButton = styled.div`
//   padding: 5px;
//   cursor: pointer;
// `;

const VideoFooterTimeWrapper = styled.div`
  span {
    font-size: 14px;
    color: #fff;
    font-weight: 900;
    margin-right: 10px;
  }

  .ant-time-picker-clear {
    display: none;
  }
`;

const SummaryWrapper = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const MarkAsReportWrapper = styled.div`
  width: 50%;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  button {
    width: 135px;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
`;

const PublishButtonWrapper = styled.div`
  margin-top: 40px;
  display: flex;
  width: 75%;
  justify-content: space-between;

  button {
    width: 14em;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
  }
  .selection,
  .ui.selection.visible.dropdown > .text:not(.default) {
    max-width: 14em;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
    text-align: center;
  }
  a {
    width: 100%;
    background-color: #f32826 !important;
    color: #fff !important;
    font-weight: 900 !important;
    cursor: pointer;
    display: inline-block;
    min-height: 1em;
    vertical-align: baseline;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    margin: 0 0.25em 0 0;
    padding: 0.78571429em 1.5em 0.78571429em;
    text-transform: none;
    text-shadow: none;
    line-height: 1em;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    border-radius: 0.28571429rem;
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    user-select: none;
    transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease, box-shadow 0.1s ease,
      background 0.1s ease;
    -webkit-tap-highlight-color: transparent;
  }
`;

const FieldWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
  font-weight: bold !important;
  font-size: 14px !important;
`;

const CheckboxWrapper = styled(Checkbox)`
  margin-left: 10px;
`;

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_PLAYING_VIDEO':
        draft.playingVideo = action.value;
        break;
      case 'SET_VIDEO_CURRENT_TIME':
        draft.currentTime = action.value;
        break;
      case 'SET_STATUSES':
        draft.statuses = action.value.map((status) => ({
          key: status.status_name,
          value: status.status_name,
          text: status.status_name,
        }));
        break;
      case 'SET_CURRENT_STATUS':
        draft.status = action.value;
        break;
      case 'CONTINUE_EDIT':
        draft.continueEditing = !draft.continueEditing;
        break;
    }
  });

let fe_url = 'https://news.travelnewsdigest.in';
switch (process.env.REACT_APP_ENV) {
  case 'oped-production':
    fe_url = 'https://news.getdailybrief.com';
    break;
  case 'clip-production':
    fe_url = 'https://watch.sphr.tv'; // 'https://theclipapp.com';
    break;
  case 'oped-staging':
    fe_url = 'https://staging-site.opiniondigest.in';
    break;
  case 'und-production':
    fe_url = 'https://read.urbannewsdigest.in';
    break;
  case 'und-staging':
    fe_url = 'https://staging-site.urbannewsdigest.in';
    break;
  case 'staging':
    fe_url = 'https://staging-site.travelnewsdigest.in';
    break;
  case 'production':
    fe_url = 'https://news.travelnewsdigest.in';
    break;
}

const VideoColumn = ({
  urlString,
  onSetActiveSegment,
  videoRef,
  segmentList,
  onPublishVideoArticle,
  setVideoPublishLoading,
  showDuration,
  disabledHours,
  disabledMinutes,
  disabledSeconds,
  startTime,
  previewMode,
  subtitleMode,
  togglePreview,
  toggleSubtitle,
  onChangeTranscript,
  videoTranscriptData,
  activeTranscript,
  initialStatus,
  slug,
  mobilePreviewData,
}) => {
  const intervalRef = useRef(null);
  const currentVideoTimeRef = useRef(0);
  const activeTranscriptRef = useRef(null);
  const [state, dispatch] = useReducer(reducer, {
    playingVideo: false,
    currentTime: null,
    env: `${fe_url}/digests/${slug}`,
  });

  const { playingVideo, currentTime, statuses, status, continueEditing, env } = state;

  useEffect(() => {
    if (
      videoRef.current &&
      typeof videoRef.current.pauseVideo &&
      typeof videoRef.current.playVideo
    ) {
      if (previewMode) videoRef.current.playVideo();
      else videoRef.current.pauseVideo();
    }
  }, [previewMode, videoRef]);

  useEffect(() => {
    activeTranscriptRef.current = activeTranscript;
    if (activeTranscript && activeTranscript.manuallyChange) {
      videoRef.current.seekTo(parseFloat(activeTranscript.start));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTranscript]);

  useEffect(() => {
    fetchAllStatuses().then((response) => {
      dispatch({ type: 'SET_STATUSES', value: response.data.results, total: response.data.total });
    });
  }, []);

  useEffect(() => {
    if (videoRef.current) {
      if (playingVideo) {
        videoRef.current.playVideo();
      } else {
        videoRef.current.pauseVideo();
      }
    }
  }, [playingVideo, videoRef]);

  useEffect(() => {
    if (playingVideo && !activeTranscriptRef.current) {
      onChangeTranscript(videoTranscriptData[0]);
    }
    //  else if (playingVideo && activeTranscriptRef.current) setTimeout(() => dispatch({ type: 'SET_PLAYING_VIDEO', value: true }));
  }, [onChangeTranscript, playingVideo, videoTranscriptData]);

  useEffect(() => {
    dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: startTime });
  }, [startTime]);

  const checkActiveTranscript = (time) => {
    if (time === 0) {
      onChangeTranscript(videoTranscriptData[0]);
    } else {
      videoTranscriptData.forEach((item) => {
        const startTime = item.start;
        const endTime = (item.start + item.duration).toFixed(2);
        if (time >= startTime && time < endTime) {
          onChangeTranscript(item);
        }
      });
    }
  };

  useEffect(() => {
    if (playingVideo && videoRef && videoRef.current && !intervalRef.current) {
      intervalRef.current = setInterval(() => {
        if (playingVideo && videoRef && videoRef.current) {
          const time = videoRef.current.getCurrentTime();
          const date = new Date(null);
          date.setMilliseconds(time * 1000);
          const timeFormat = date.toISOString().substr(11, 11).replace('.', ':');

          const transcriptValue = activeTranscriptRef.current;
          // const transcriptEndValue = (transcriptValue.start + transcriptValue.duration).toFixed(2);
          if (Math.abs(time - currentVideoTimeRef.current) > 0.3) {
            checkActiveTranscript(time);
          } else if (transcriptValue) {
            const transcriptEndValue = (transcriptValue.start + transcriptValue.duration).toFixed(
              2,
            );
            if (time >= transcriptEndValue) {
              onChangeTranscript(videoTranscriptData[transcriptValue.index + 1]);
            }
          }
          currentVideoTimeRef.current = time;
          dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: timeFormat });
        }
      }, 1);
    } else {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playingVideo, videoRef, videoTranscriptData]);

  const setMillisecs = (e) => {
    const { value } = e.target;
    const time = currentTime || '00:00:00:00';
    const a = time.split(':');

    a[3] = value > 99 || value < 0 ? a[3] : value;
    const s = `${a[0]}:${a[1]}:${a[2]}:${a[3]}`;
    const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;

    videoRef.current.seekTo(parseFloat(seconds));
    dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: s });
    setTimeout(() => videoRef.current.pauseVideo());
  };

  const createYTRef = useCallback(() => {
    const validUrl =
      /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    if (urlString.match(validUrl)) {
      const video_id = urlString.match(validUrl)[1];
      videoRef.current = new window.YT.Player(`youtubePlayer`, {
        videoId: video_id,
        width: '100%',
        events: {
          onReady: showDuration,
        },
      });
      videoRef.current.addEventListener('onStateChange', (state) => {
        if (state.data === 1) {
          dispatch({ type: 'SET_PLAYING_VIDEO', value: true });
        }

        if (state.data === 2) {
          videoRef.current.pauseVideo();
          dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
        }
      });
    }
  }, [showDuration, urlString, videoRef]);

  const loadVideo = useCallback(() => {
    if (urlString && !videoRef.current && window.YT) {
      if (typeof window.YT.Player === 'undefined') {
        window.onYouTubeIframeAPIReady = () => {
          createYTRef();
        };
      } else {
        createYTRef();
      }
    }
  }, [createYTRef, urlString, videoRef]);

  useEffect(() => {
    loadVideo();
  }, [loadVideo, playingVideo, showDuration, urlString, videoRef]);

  return (
    <div>
      <VideoWrapper>
        <div id="youtubePlayer" />
        <VideoFooter>
          <VideoFooterTimeWrapper
            onClick={() => {
              dispatch({ type: 'SET_PLAYING_VIDEO', value: false });
            }}
          >
            <TimePicker
              disabledHours={disabledHours}
              disabledMinutes={disabledMinutes}
              disabledSeconds={disabledSeconds}
              value={
                currentTime
                  ? moment(`${moment().format('DD:MM:YY')} ${currentTime}`, 'DD:MM:YY HH:mm:ss:SS')
                  : moment('00:00:00', 'HH:mm:ss:fff')
              }
              size="large"
              onChange={(value) => {
                if (value && value.format('HH:mm:ss:SS') && videoRef.current) {
                  const a = value.format('HH:mm:ss:SS').split(':'); // split it at the colons
                  const seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2] + +a[3] / 100;
                  videoRef.current.seekTo(parseFloat(seconds));
                  dispatch({ type: 'SET_VIDEO_CURRENT_TIME', value: value.format('HH:mm:ss:SS') });
                  if (!playingVideo) {
                    setTimeout(() => videoRef.current.pauseVideo());
                    videoRef.current.playVideo();
                  }
                }
              }}
            />
            {!previewMode && (
              <Input
                value={currentTime ? currentTime.split(':')[3] : 0}
                type="number"
                onChange={setMillisecs}
                min="0"
                max="99"
              />
            )}
          </VideoFooterTimeWrapper>
        </VideoFooter>
      </VideoWrapper>
      <SummaryWrapper>
        <MarkAsReportWrapper>
          <Button
            onClick={() => {
              const time = currentTime
                ? moment(`${moment().format('DD:MM:YY')} ${currentTime}`, 'DD:MM:YY HH:mm:ss:SSS')
                : moment('00:00:00', 'HH:mm:ss:fff');
              onSetActiveSegment({ type: 'timestamp_start', value: time });
            }}
          >
            {' '}
            Mark As Start
          </Button>
          <Button
            onClick={() => {
              const time = currentTime
                ? moment(`${moment().format('DD:MM:YY')} ${currentTime}`, 'DD:MM:YY HH:mm:ss:SSS')
                : moment('00:00:00', 'HH:mm:ss:fff');
              onSetActiveSegment({ type: 'timestamp_end', value: time });
            }}
          >
            {' '}
            Mark As End
          </Button>
        </MarkAsReportWrapper>
        <FieldWrapper>
          Continue Editing
          <CheckboxWrapper
            checked={continueEditing}
            onClick={() => dispatch({ type: 'CONTINUE_EDIT' })}
          />
        </FieldWrapper>
        <PublishButtonWrapper>
          <Select
            placeholder="Status"
            options={statuses}
            defaultValue={initialStatus || 'Draft'}
            onChange={(e, data) => {
              dispatch({ type: 'SET_CURRENT_STATUS', value: data.value });
            }}
          />
          <Button
            onClick={(e) => onPublishVideoArticle(status, continueEditing)}
            disabled={segmentList.length === 0 || setVideoPublishLoading}
          >
            Save
          </Button>
        </PublishButtonWrapper>
        {initialStatus === 'Published' && (
          <PublishButtonWrapper>
            <a href={env} target="_blank" rel="noopener noreferrer">
              View published page
            </a>
          </PublishButtonWrapper>
        )}
        {segmentList.length > 0 && (
          <FieldWrapper>
            Preview Mode
            <Toggle on={previewMode} onClick={togglePreview} />
          </FieldWrapper>
        )}
        {videoTranscriptData && videoTranscriptData.length > 0 && (
          <FieldWrapper>
            Subtitle Mode
            <Toggle on={subtitleMode} onClick={toggleSubtitle} />
          </FieldWrapper>
        )}
      </SummaryWrapper>
    </div>
  );
};

export default VideoColumn;
