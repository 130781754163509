/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Image, Input, Button, Icon } from 'semantic-ui-react';
import { fetchLanguages } from '../../Api';
import Logo from './logo.jpg';
import UndLogo from './undLogo.png';
import OPEDLogo from './opedLogo.png';
import ClipLogo from './clipLogo.png';
import Sidebar from '../Sidebar';
import DropDownRadio from './DropDownRadio';

const MainWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 500;
  background-color: #fff;
`;

const Wrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 10px 0 #d8d8d8;

  @media (max-width: 580px) {
    padding: 10px 10px;
  }
`;

// const LeftMenuWrapper = styled.div`
//   @media (max-width: 768px) {
//     padding: 10px 0px 10px 10px;
//   }
// `;

const LeftMenuWrapper = styled.div``;

const LogoutCta = styled.div`
  font-size: 14px;
  font-weight: 900;
  cursor: pointer;
  padding: 5px 10px;

  i {
    font-weight: 200;
    font-size: 1.2em;
  }

  @media (max-width: 580px) {
    padding: 5px 0 5px 10px;
  }
`;

const LogoWrapper = styled.div`
  /* width: 230px; */
  height: 52px;
  cursor: pointer;

  img {
    width: auto;
    height: 100%;
  }

  @media (max-width: 768px) {
    height: 40px;
  }
  // @media (max-width: 568px) {
  //   height: 24px;
  // }
  // @media (max-width: 425px) {
  //   height: 20px;
  // }
  // @media (max-width: 375px) {
  //   height: 17px;
  // }
  // @media (max-width: 357px) {
  //   height: 16px;
  // }
`;

const InputWrapper = styled.div`
  text-align: center;
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const InputField = styled(Input)`
  width: 70%;

  @media (max-width: 768px) {
    width: 100%;

    input {
      padding: 0.5em !important;
      border-radius: 4px !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
`;

const ButtonWrapper = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 70px;
  padding: 5px 10px;
  color: #fff !important;
  font-size: 16px;
  font-weight: 900;
  background-color: #f32826 !important;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  @media (max-width: 768px) {
    width: 30px;
    padding: 0.5em !important;
    border-radius: 4px !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

const HamburgerMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    width: 18px;
    margin-right: 12px;
    display: block;
  }
`;

const SidebarWrapper = styled.div`
  width: 70px;
  @media (max-width: 768px) {
    width: auto;
    ${({ isShowSideBar }) =>
    isShowSideBar
      ? `
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.6);
      border: 0;
      z-index: 10;
    `
      : ``}
  }
`;

const Go = styled.div`
  position: relative;
  .ui.disabled.button {
    pointer-events: auto !important;
  }
`;

const FieldErrorMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 12px;
  color: #cc0000;
  font-weight: 300;
  display: flex;
  justify-content: center;
  margin-left: 135px;
`;

const Body = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
`;

const SourceRedirect = styled.a`
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  color: #000;
`;

const RightWrapper = styled.div`
  /* width: 20%; */
  display: flex;
  align-items: center;
`;

const validUrl =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
const urlPattern = /(http(s?):)\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/;
const validVideoUrl =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const GoButton = ({ onClick, disabled, urlString }) => (
  <Go>
    {urlString && urlString.trim() !== '' && (
      <SourceRedirect ourceRedirect href={urlString} target="_blank">
        <Icon name="external alternate" />
      </SourceRedirect>
    )}
    <ButtonWrapper onClick={onClick} disabled={disabled} title="Add your News Url here">
      Go
    </ButtonWrapper>
  </Go>
);

GoButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  urlString: PropTypes.string,
};

const Header = (props) => {
  const {
    urlString,
    isDashboard,
    disabledUrl,
    path,
    location,
    errors,
    clearDigestsListFilter,
    isDigestList,
  } = props;
  const history = useHistory();
  const match = useRouteMatch('/login');
  const isUnd =
    process.env.REACT_APP_ENV === 'und-production' || process.env.REACT_APP_ENV === 'und-staging';
  const isOped =
    process.env.REACT_APP_ENV === 'oped-production' || process.env.REACT_APP_ENV === 'oped-staging';

  const isClip = process.env.REACT_APP_ENV === 'clip-production';
  const [url, setUrl] = useState(urlString);
  const [sourceUrl, setSourceUrl] = useState(urlString);
  let imgSrc = Logo;
  if (isOped) {
    imgSrc = OPEDLogo;
  } else if (isUnd) {
    imgSrc = UndLogo;
  } else if (isClip) {
    imgSrc = ClipLogo;
  }
  const [isShowSideBar, setIsShowSideBar] = useState(false);
  const [languagesList, setLanguagesList] = useState([]);

  useEffect(() => {
    fetchLanguages()
      .then((response) => {
        setLanguagesList(response.data.results);
      })
      .catch((error) => {
        console.log('error', error);
      });
  }, []);

  useEffect(() => {
    if (urlString) {
      setUrl(urlString);
      setSourceUrl(urlString);
    }
  }, [urlString]);
  const [error, setError] = useState(false);

  const [isMobile, setMobile] = useState(false);

  const handleResize = useCallback(() => {
    if (window.innerWidth <= 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, []);

  useEffect(() => {
    handleResize();
  }, [handleResize]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [handleResize]);

  return (
    <MainWrapper>
      <Wrapper>
        {!match && (
          <LeftMenuWrapper>
            <HamburgerMenu
              onClick={() => {
                setIsShowSideBar(!isShowSideBar);
              }}
            >
              <Icon name="bars" color="black" />
            </HamburgerMenu>
            {/* <div style={{ width: '70px' }}> */}
            <SidebarWrapper
              isShowSideBar={isShowSideBar}
              onClick={() => {
                if (window.innerWidth <= 768) {
                  setIsShowSideBar(!isShowSideBar);
                }
              }}
            >
              <Sidebar
                pageWrapId="page-wrap"
                outerContainerId="outer-container"
                location={location}
                isShowSideBar={isShowSideBar}
                noOverlay
              />
            </SidebarWrapper>
          </LeftMenuWrapper>
        )}
        <Body>
          <LogoWrapper
            onClick={() => {
              history.push('/');
              if (clearDigestsListFilter) {
                clearDigestsListFilter();
              }
            }}
          >
            <Image src={imgSrc} />
          </LogoWrapper>
          {isDashboard && !isMobile && (
            <InputWrapper>
              <InputField
                icon={
                  <GoButton
                    urlString={sourceUrl}
                    disabled={error || !url}
                    onClick={() => {
                      if (!disabledUrl && url) {
                        if (path.includes('video')) {
                          if (url.match(validUrl)) {
                            history.push(path, {
                              urlString: url,
                            });
                            window.location.reload();
                          } else {
                            setError(true);
                          }
                        } else if (url.match(urlPattern)) {
                          history.push(path, {
                            urlString: url,
                          });
                        } else if (url.match(validVideoUrl)) {
                          history.push(path, {
                            urlString: url,
                          });
                        } else {
                          setError(true);
                        }
                      }
                    }}
                  />
                }
                placeholder="Enter URL"
                onChange={(e) => {
                  const val = e.target.value ? e.target.value.trim() : '';
                  if (path.includes('video')) {
                    if (val && val.match(validUrl)) {
                      setError(false);
                    } else {
                      setError(true);
                    }
                  } else if (val && val.match(urlPattern)) {
                    setUrl(val);
                    setError(false);
                  } else setError(true);

                  setSourceUrl(val);
                }}
                defaultValue={urlString}
                onKeyUp={(e) => {
                  if (e.which === 13 && urlString) {
                    if (path.includes('video')) {
                      if (url && url.match(validUrl)) {
                        history.push(path, {
                          urlString: url,
                        });
                        window.location.reload();
                      }
                    } else if (url.match(urlPattern)) {
                      history.push(path, {
                        urlString: url,
                      });
                    } else {
                      setError(true);
                    }
                  }
                  const val = e.target.value ? e.target.value.trim() : '';
                  setSourceUrl(val);
                }}
              />
            </InputWrapper>
          )}
          <RightWrapper>
            {languagesList && languagesList.length > 0 && !match && isDigestList && (
              <DropDownRadio data={languagesList} />
            )}
            {!match && (
              <LogoutCta
                onClick={() => {
                  localStorage.removeItem('token');
                  localStorage.removeItem('role');
                  localStorage.removeItem('privilege');
                  history.push('/login');
                }}
              >
                {!isMobile ? 'LOGOUT' : <Icon name="sign out" />}
              </LogoutCta>
            )}
          </RightWrapper>
        </Body>
      </Wrapper>
      {isDashboard && isMobile && (
        <InputWrapper>
          <InputField
            icon={
              <GoButton
                urlString={sourceUrl}
                disabled={error || !url}
                onClick={() => {
                  if (!disabledUrl && url) {
                    if (path.includes('video')) {
                      if (url.match(validUrl)) {
                        history.push(path, {
                          urlString: url,
                        });
                        window.location.reload();
                      } else {
                        setError(true);
                      }
                    } else if (url.match(urlPattern)) {
                      history.push(path, {
                        urlString: url,
                      });
                    } else {
                      setError(true);
                    }
                  }
                }}
              />
            }
            placeholder="Enter URL"
            onChange={(e) => {
              const val = e.target.value ? e.target.value.trim() : '';
              if (path.includes('video')) {
                if (val && val.match(validUrl)) {
                  setError(false);
                } else {
                  setError(true);
                }
              } else if (val && val.match(urlPattern)) {
                setUrl(val);
                setError(false);
              } else setError(true);

              setSourceUrl(val);
            }}
            defaultValue={urlString}
            onKeyUp={(e) => {
              if (e.which === 13 && urlString) {
                if (path.includes('video')) {
                  if (url && url.match(validUrl)) {
                    history.push(path, {
                      urlString: url,
                    });
                    window.location.reload();
                  }
                } else if (url.match(urlPattern)) {
                  history.push(path, {
                    urlString: url,
                  });
                } else {
                  setError(true);
                }
              }
              const val = e.target.value ? e.target.value.trim() : '';
              setSourceUrl(val);
            }}
          />
        </InputWrapper>
      )}

      <FieldErrorMessage>{errors}</FieldErrorMessage>
    </MainWrapper>
  );
};

Header.propTypes = {
  urlString: PropTypes.string,
  isDashboard: PropTypes.bool,
  disabledUrl: PropTypes.bool,
  location: PropTypes.object,
  errors: PropTypes.string,
  clearDigestsListFilter: PropTypes.func,
  path: PropTypes.string,
};

export default Header;
