import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Moment from 'moment';
import PropTypes from 'prop-types';
import { Image, Icon, Checkbox, Menu } from 'semantic-ui-react';
import styled from 'styled-components';
import DropDown from '../../Components/TableView/DropDown';
import { createDropDown } from '../../Components/TableView';
import TwitterPlayer from 'Components/TwitterPlayer';

const Block = styled.div`
  margin-bottom: 20px;
  border: 1px solid #dcd9d9;
  border-radius: 4px;
  padding-bottom: ${({ padding }) => (!padding ? '01px' : '0')};
`;

const DropDownText = styled.span`
  font-size: 16px;
  color: ${({ activeFiler }) => (activeFiler ? '#0152cc' : 'rgba(0,0,0,0.6)')};
`;

const Header = styled.div``;

const Label = styled.div`
  width: 30%;
  font-weight: bold;
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  padding: 10px;
`;

const InputWrapper = styled(Flex)`
  display: block;
  > div {
    width: 100%;
  }
`;

const InputField = styled.div`
  margin-top: ${({ mt }) => mt || 0};
`;

const ImageField = styled(Image)`
  width: 80px;
  height: 80px;
  margin-top: 10px;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
`;

// const ImageWrapper = styled.div`
//   min-height: 50px;
//   overflow: hidden;
// `;

const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const IconWrapper = styled.div`
  padding: 0 5px;
`;
const NewTabIconWrapper = styled(Link)`
  padding: 0 5px;
  display: block;

  i {
    color: rgba(0, 0, 0, 0.77) !important;
  }
`;

const MenuWrapper = styled(Menu)`
  padding: 0 5px;
  background: transparent !important;
  border: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  > a {
    padding: 0 !important;
    background: transparent !important;
  }
  .item:before {
    background: transparent !important;
  }
`;
const TwitterCoverPic = styled.div`
.twitter-embed{
  position:unset;
  width:80px;
}
iframe{
  width:80px !important;
  height:60px !important;
  img{
    background-color:red;
  }
}
`;

const MobileList = ({
  digestsList,
  selectedDigestsId,
  selectAllDigest,
  selectAllDigests,
  selectDigest,
  videoCodes,
  enabledField,
  feUrl,
  setDraftModal,
  setDeleteModal,
  categoriesList,
  selectedCategory,
  categoryFilterSelect,
  selectedSource,
  sources,
  sourceFilterSelect,
  editDigest,
}) => {
  const history = useHistory();
  const displayImage = (data) => {
    if (data.cover_pic && data.cover_pic.includes('.mp4')) {
      return data.custom_thumbnail; 
    } else { 
      return data.cover_pic; 
    }
};
  return (
    <div>
      <Header>
        <Flex>
          <Label>Select All</Label>
          <Checkbox
            checked={
              (digestsList.length && selectedDigestsId.length === digestsList.length) ||
              selectAllDigest
            }
            indeterminate={
              !selectAllDigest &&
              selectedDigestsId.length > 0 &&
              selectedDigestsId.length < digestsList.length
            }
            onClick={selectAllDigests}
          />
        </Flex>
      </Header>
      <Header>
        <Flex>
          <DropDown
            dropDownIconColor={selectedCategory ? '#0152cc' : '#000'}
            text={<DropDownText activeFiler={selectedCategory}>Categories</DropDownText>}
            bottomPosition={12}
            position="bottomRight"
            contentWidth="135px"
          >
            {createDropDown(categoriesList, categoryFilterSelect, selectedCategory)}
          </DropDown>
          <DropDown
            dropDownIconColor={selectedSource ? '#0152cc' : '#000'}
            text={<DropDownText activeFiler={selectedSource}>Sources</DropDownText>}
            bottomPosition={12}
            position="bottomRight"
          >
            {createDropDown(sources, sourceFilterSelect, selectedSource)}
          </DropDown>
        </Flex>
      </Header>
      {digestsList.map((data) => (
        <Block onClick={() => editDigest({ data })} padding={data.status === 'Published'}>
          <Flex>
            <Label>Select</Label>
            <Checkbox
              onClick={(e) => {
                e.stopPropagation();
                selectDigest({ data });
              }}
              checked={selectedDigestsId.indexOf(data.id) !== -1}
            />
          </Flex>
          <Flex>
            <Label>Id</Label>
            <InputField>{data.id}</InputField>
          </Flex>
          <InputWrapper>
            <Label>Digest Title</Label>
            <InputField mt="10px">{data.title}</InputField>
          </InputWrapper>
          <InputWrapper>
            <Label>Cover Pic</Label>
            {(data.custom_thumbnail && data.custom_thumbnail.includes('https://twitter.com/')) 
            || (data?.curated_tweet?.link && data?.curated_tweet?.link.includes('https://twitter.com/')) ? 
            (<TwitterCoverPic>
              <TwitterPlayer tweetLink={data?.custom_thumbnail || data?.curated_tweet?.link} /> 
            </TwitterCoverPic>)           
            : <ImageField src={displayImage(data)} />}
          </InputWrapper>
          <InputWrapper>
            <Label>First Published At</Label>
            <InputField mt="10px">
              {data.first_published_at ? (
                <span>{Moment(data.first_published_at).format('MMM DD,YYYY hh:mm A')}</span>
              ) : (
                ''
              )}
            </InputField>
          </InputWrapper>
          <Flex>
            <Label>Categories</Label>
            <InputField>{data.categories.map((item) => item.title).join(', ')}</InputField>
          </Flex>
          <Flex>
            <Label>Sources</Label>
            <InputField>{data.source.host}</InputField>
          </Flex>
          <Flex>
            <Label>Created At</Label>
            <InputField>
              {data.created_at ? (
                <span>{Moment(data.created_at).format('MMM DD,YYYY hh:mm A')}</span>
              ) : (
                ''
              )}
            </InputField>
          </Flex>
          <Flex>
            <Label>Modified At</Label>
            <InputField>
              {data.modified_at ? (
                <span>{Moment(data.modified_at).format('MMM DD,YYYY hh:mm A')}</span>
              ) : (
                ''
              )}
            </InputField>
          </Flex>
          <div className="action-wrapper">
            <ActionWrapper>
              <IconWrapper
                onClick={(e) => {
                  e.stopPropagation();
                  if (videoCodes.includes(data.type.code)) {
                    history.push(`/video/${data.id}`, {
                      urlString: data.source.link,
                    });
                  }
                  // if (newsCodes.includes(data.type.code))
                  else {
                    history.push(`/digests/${data.id}`, {
                      urlString: data.source.link,
                    });
                  }
                }}
              >
                <div title="Edit">
                  <Icon
                    name="edit"
                    size="large"
                    disabled={!enabledField}
                    color={!enabledField ? 11 : 10}
                  />
                </div>
              </IconWrapper>
              {localStorage.getItem('privilege') !== 'STAFF_USER_PERM' && (
                <IconWrapper
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteModal({ value: true, deletedId: [data.id] });
                  }}
                >
                  <div title="Delete">
                    <Icon name="archive" size="large" />
                  </div>
                </IconWrapper>
              )}
              {data.status !== 'Published' ? (
                <IconWrapper
                  onClick={(e) => {
                    e.stopPropagation();
                    setDraftModal({
                      status: true,
                      digestStatusValue: data.status,
                      data,
                    });
                  }}
                >
                  <div title="Publish">
                    <Icon name="upload" size="large" />
                  </div>
                </IconWrapper>
              ) : (
                <>
                  <IconWrapper
                    onClick={(e) => {
                      e.stopPropagation();
                      setDraftModal({
                        status: true,
                        digestStatusValue: data.status,
                        data,
                      });
                    }}
                  >
                    <div title="Save to draft">
                      <Icon name="save" size="large" />
                    </div>
                  </IconWrapper>
                  <MenuWrapper>
                    <Menu.Item
                      title="View published page"
                      href={`${feUrl}/digests/${data.slug}`}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Icon name="eye" size="large" />
                    </Menu.Item>
                  </MenuWrapper>
                </>
              )}
              <NewTabIconWrapper
                to={`/digests/${data.id}`}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div title="Digest">
                  <Icon name="external alternate" size="large" />
                </div>
              </NewTabIconWrapper>
              
              <NewTabIconWrapper
                to={`/video/edit/${data.id}`}
                target="_blank"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div title="Edit">
                {/* <IconWrapper> */}
              <Icon name="pencil alternate" />
            {/* </IconWrapper> */}
                  {/* <Icon name="external alternate" size="large" /> */}
                </div>
              </NewTabIconWrapper>
            </ActionWrapper>
          </div>
        </Block>
      ))}
    </div>
  );
};

MobileList.propTypes = {
  digestsList: PropTypes.array,
  selectedDigestsId: PropTypes.array,
  selectAllDigest: PropTypes.bool,
  selectAllDigests: PropTypes.func,
  selectDigest: PropTypes.func,
  videoCodes: PropTypes.array,
  enabledField: PropTypes.bool,
  feUrl: PropTypes.string,
  setDraftModal: PropTypes.func,
  categoriesList: PropTypes.array,
  selectedCategory: PropTypes.array,
  selectedSource: PropTypes.array,
  sources: PropTypes.array,
  setDeleteModal: PropTypes.func,
  sourceFilterSelect: PropTypes.func,
  categoryFilterSelect: PropTypes.func,
  editDigest: PropTypes.func,
};

export default MobileList;
