import React, { useEffect, useReducer, useCallback } from 'react';
import produce from 'immer';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, Loader, Modal, Dimmer } from 'semantic-ui-react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import styled from 'styled-components';
import Pagination from '../../Components/Pagination';
import Layout from '../../Components/Layout';
import { fetchStatuses, deleteStatus } from '../../Api';
import { Helmet } from 'react-helmet';

const Status = styled.div`
  display: flex;
  cursor: ${(props) => (props.editable ? 'cursor' : 'not-allowed')};
  background: ${(props) => (props.editable ? '#fff' : '#eceeef')};
  justify-content: space-between;
  height: 60px;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  margin-bottom: 1rem;
  padding: 0 1.5rem;
  text-transform: capitalize;
`;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 15px;

  .rc-pagination-prev,
  .rc-pagination-next,
  rc-pagination-item-active {
    outline: none;
    border-color: transparent;
  }
`;

const ButtonWrapper = styled(Link)`
  display: flex;
  justify-content: flex-end;
  min-width: 6%;
  margin-bottom: 20px;
`;

const ButtonTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const SectionTitle = styled.div`
  font-size: 2.125rem;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1.25em;
`;

const initialValues = {
  statuses: [],
  loading: false,
  currentPage: 1,
};

/* eslint-disable no-param-reassign, default-case */
const reducer = (state, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case 'SET_STATUSES':
        draft.statuses = action.value;
        draft.totalStatuses = action.total;
        break;
      case 'SET_LOADING':
        draft.loading = !draft.loading;
        break;
      case 'DELETE_STATUS':
        draft.deleteElement = action.value;
        break;
      case 'SET_CURRENT_PAGE':
        draft.currentPage = action.value;
        break;
    }
  });

const Settings = ({ location }) => {
  const [state, dispatch] = useReducer(reducer, initialValues);

  const { statuses, loading, deleteElement, currentPage, totalStatuses } = state;

  const fetchStatus = useCallback(() => {
    dispatch({ type: 'SET_LOADING' });
    fetchStatuses(currentPage)
      .then((response) => {
        dispatch({
          type: 'SET_STATUSES',
          value: response.data.results,
          total: response.data.total,
        });
        dispatch({ type: 'SET_LOADING' });
      })
      .catch((error) => {
        const errorMsg =
          typeof error.response.data === 'object'
            ? Object.values(error.response.data)[0]
            : 'Unable to fetch status.';
        ToastsStore.error(errorMsg);
        dispatch({ type: 'SET_LOADING' });
      });
  }, [currentPage]);

  useEffect(() => {
    fetchStatus();
  }, [fetchStatus]);

  const deleteStatusConfirm = () => {
    dispatch({ type: 'SET_LOADING' });
    deleteStatus(deleteElement)
      .then((response) => {
        dispatch({ type: 'DELETE_STATUS', value: '' });
        ToastsStore.success('Status deleted successfully');
        setTimeout(() => {
          fetchStatus();
          dispatch({ type: 'SET_LOADING' });
        }, 2000);
      })
      .catch((error) => {
        const errorMsg =
          typeof error.response.data === 'object'
            ? Object.values(error.response.data)[0]
            : 'Unable to fetch status.';
        ToastsStore.error(errorMsg);
        dispatch({ type: 'SET_LOADING' });
      });
  };

  return (
    <Layout location={location}>
      <ButtonTitleWrapper>
        <SectionTitle>All Statuses</SectionTitle>
        <ButtonWrapper to="/status-add">
          <Button icon="plus" content="Add Status" positive labelPosition="right" />
        </ButtonWrapper>
      </ButtonTitleWrapper>
      <Helmet>
          <title>XND | Settings</title>
        </Helmet>
      {statuses.map((status) => (
        <Status key={status.id} editable={status.editable}>
          {status.status_name}
          {status.editable && (
            <div>
              <Button basic to={`/settings/${status.id}`} as={Link} icon="edit" />
              <Button
                basic
                onClick={(event) => dispatch({ type: 'DELETE_STATUS', value: status.id })}
                icon="cancel"
              />
            </div>
          )}
        </Status>
      ))}
      {totalStatuses > 20 && (
        <PaginationWrapper>
          <Pagination
            activePage={currentPage}
            totalItems={totalStatuses}
            totalPages={Math.round(totalStatuses / 20)}
            onChangePage={(current) => dispatch({ type: 'SET_CURRENT_PAGE', value: current })}
            pageSize={20}
          />
        </PaginationWrapper>
      )}
      <Dimmer active={loading} inverted>
        <Loader active={loading} />
      </Dimmer>
      {deleteElement && (
        <Modal size="tiny" open={deleteElement}>
          <Modal.Content>All digest present in this status would be moved to Draft.</Modal.Content>
          <Modal.Actions>
            <Button
              negative
              disabled={loading}
              onClick={() => {
                dispatch({ type: 'DELETE_STATUS', value: '' });
              }}
            >
              Cancel
            </Button>
            <Button
              positive
              content="Yes delete"
              disabled={loading}
              onClick={deleteStatusConfirm}
            />
          </Modal.Actions>
        </Modal>
      )}
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
    </Layout>
  );
};

Settings.propTypes = {
  location: PropTypes.object,
};

export default Settings;
