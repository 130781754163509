import React, { useState } from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';
import { ToastsStore } from 'react-toasts';
import PropTypes from 'prop-types';
import Chips from 'react-chips';
import styled from 'styled-components';
import { addTagsDigests, fetchTagsData } from '../../Api';

const ChipWrapper = styled.div`
  position: relative;
  background-color: rgb(233, 233, 233);
  color: rgb(128, 128, 128);
  font-size: 14px;
  font-weight: 500;
  padding: 5px 30px 5px 10px;
  margin: 0 10px 0 0px;
  border-radius: 4px;
  font-family: Metropolis, sans-serif;
`;

const ChipCloseIcon = styled.div`
  background-color: #fff;
  padding: 1px 2px;
  position: absolute;
  top: 4px;
  right: 2px;
  border-radius: 50%;
  cursor: pointer;
  i {
    color: rgb(128, 128, 128);
    margin: 0;
  }
`;

const Wrapper = styled.div`
  #react-autowhatever-1 {
    background-color: #000;
    ul {
      max-height: 150px;
      overflow: auto;
    }
  }
`;

const AddTagsModal = ({ onCloseModal, digestIds }) => {
  const [addTagsLoading, setAddTagsLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedChipIdsTags, setSelectedChipIdsTags] = useState([]);

  const hanldeAddTags = (chips) => {
    console.log('🚀 ~ file: index.js ~ line 49 ~ hanldeAddTags ~ chips', selectedTags);
    // const tags = { ...chips[chips.length - 1] };
    setSelectedTags(chips);
    setSelectedChipIdsTags(chips.map((chip) => chip.id));
  };

  const fetchTagSuggestions = async (value, chips) => {
    try {
      const response = await fetchTagsData({ searchString: value });
      const data = response.data.results;
      return data.filter((item) => !selectedChipIdsTags.includes(item.id));
      // eslint-disable-next-line no-empty
    } catch (error) {}
    return [];
  };

  const createTagsPostObject = (data) =>
    data.map((item) => {
      if (typeof item === 'string') {
        return {
          title: item,
        };
      }
      return {
        id: item.id,
      };
    });

  return (
    <Modal size="tiny" open onClose={() => {}}>
      <Modal.Header>Add Tags</Modal.Header>
      <Modal.Content>
        <Wrapper>
          <Chips
            placeholder="Add tags"
            value={JSON.parse(JSON.stringify(selectedTags))}
            onChange={hanldeAddTags}
            fetchSuggestions={fetchTagSuggestions}
            // alwaysRenderSuggestions={true}
            createChipKeys={[13]}
            title="Tag"
            renderChip={(chipData) => (
              <ChipWrapper>
                {chipData.title || chipData}
                <ChipCloseIcon
                  onClick={() => {
                    let index;
                    if (chipData.id)
                      index = selectedTags.findIndex((tag) => tag.id === chipData.id);
                    else index = selectedTags.findIndex((tag) => tag === chipData);
                    const tagsData = JSON.parse(JSON.stringify(selectedTags));
                    tagsData.splice(index, 1);
                    hanldeAddTags(tagsData);
                  }}
                >
                  <Icon size="small" name="close" />
                </ChipCloseIcon>
              </ChipWrapper>
            )}
            renderSuggestion={(data) => <span>{data.title}</span>}
            suggestionsFilter={(opt, val) =>
              selectedTags.findIndex((tag) => tag.id === opt.id) === -1
            }
          />
        </Wrapper>
      </Modal.Content>
      <Modal.Actions>
        <Button
          negative
          disabled={addTagsLoading}
          onClick={() => {
            onCloseModal();
          }}
        >
          Cancel
        </Button>
        <Button
          positive
          content="Add Tags"
          disabled={addTagsLoading || (selectedTags || []).length === 0}
          onClick={() => {
            setAddTagsLoading(true);
            addTagsDigests({ digests: digestIds, tags: createTagsPostObject(selectedTags) })
              .then(() => {
                onCloseModal();
                ToastsStore.success('Tags added successfully.');
              })
              .catch((error) => {
                if (window) window.Raven.captureException(error.response);
                onCloseModal();
                if (error.response.status === 400) {
                  ToastsStore.error(
                    (error.response.data &&
                      error.response.data.title &&
                      error.response.data.title[0]) ||
                      'Something went wrong',
                  );
                } else {
                  ToastsStore.error(
                    (error.response && error.response.message) || 'Something went wrong',
                  );
                }
              });
          }}
        />
      </Modal.Actions>
    </Modal>
  );
};

AddTagsModal.propTypes = {
  onCloseModal: PropTypes.func,
  digestIds: PropTypes.array,
};

export default AddTagsModal;
