import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import DragSelect from 'dragselect';

import { 
  // convertSecondsToTimeFormat,
  convertTimeToSeconds 
} from 'Utils/convertTime';

const Wrapper = styled.div``;

const TranscriptListWrapper = styled.div`
  width: calc(100% - 100px);
  margin: 0 auto;
  height: 600px;
  overflow: auto;
  user-select: none;
  margin-bottom: 20px;
  border: 1px solid #d8d8d8;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .ds-selected {
    background-color: #FFFF99;
  }
`;

const TranscriptListItem = styled.div`
  margin: 5px;
  display: inline-block;
`;

const TranscriptListItemContent = styled.div`
  /* padding: 10px; */
  display: inline-block;
  /* display: flex; */
  /* align-items: center; */
  /* border-bottom: 1px solid #d8d8d8; */
  /* padding-left: ${({ active }) => active ? 10 : 15}px; */
  /* border-left: ${({ active }) => active ? 5 : 0 }px solid ${({ active }) => active ? 'red' : '#d8d8d8'}; */
`;

// const TranscriptListItemTime = styled.div`
//   margin-right: 10px;
//   width: 75px;
//   font-size: 14px;
//   color: #696969;
// `;

const TranscriptListItemTitle = styled.div`
  /* width: calc(100% - 85px); */
  padding: 0 10px;
  font-size: 16px;
  font-weight: ${({ active }) => active ? 900 : 500};
`;

// const fetchParentElem = (elem) => {
//   if(elem) {
//     const elemClassNames = (elem.getAttribute('class') && elem.getAttribute('class').split(' ')) || [];

//     if (elemClassNames.includes('transcriptListItem')) {
//       return elem
//     }
//     return fetchParentElem(elem.parentElement);
//   }

//   return null;
// }

const TranscriptList = ({ 
  dragSelectRef,
  activeTranscript,
  onSelectTranscript,
  activeSegment ,
  videoTranscriptData,
  onChangeTranscript
}) => {
  const containerRef = useRef(null);
  const selectedListItemsRef = useRef(null);

  useEffect(() => {
    if (activeSegment && (activeSegment.id || activeSegment.segment_id) && dragSelectRef.current) {
      const activeSegmentStart = convertTimeToSeconds(activeSegment.timestamp_start);
      const activeSegmentEnd = convertTimeToSeconds(activeSegment.timestamp_end);
      const selectedTranscript = [];
      videoTranscriptData.forEach(item => {
        const itemStart = item.start;
        const itemEnd = (item.start + item.duration).toFixed(2);
        if ((itemStart >= activeSegmentStart || (itemStart < activeSegmentStart && itemEnd > activeSegmentStart )) && item.start < activeSegmentEnd) {
          selectedTranscript.push(item.index);
        }
      })
      
      const selecetdTranscriptElem = selectedTranscript.map((item) => {
        return document.querySelector(`#transcript-item-${item}`)
      });

      if(selectedTranscript && selectedTranscript[0] > 2) {
        const activeElem = document.querySelector(`#transcript-item-${selectedTranscript[0] - 2}`);
        if (activeElem) {
          containerRef.current.scrollTop = activeElem.offsetTop;
        }
      }

      if(dragSelectRef.current) {
        dragSelectRef.current.setSelection(selecetdTranscriptElem);
      }
    }
  }, [activeSegment, dragSelectRef, videoTranscriptData])
  useEffect(() => {
    if (activeTranscript && activeTranscript.index - 2 > 0) {
      const activeElem = document.querySelector(`#transcript-item-${activeTranscript.index - 2}`);
      if (activeElem) {
        containerRef.current.scrollTop = activeElem.offsetTop;
      }
    } else if (activeTranscript && containerRef.current.scrollTop > 0) {
      containerRef.current.scrollTop = 0;
    }
  }, [activeTranscript]);

  useEffect(() => {
    if (containerRef.current && !dragSelectRef.current) {
      dragSelectRef.current = new DragSelect({
        selectables: document.getElementsByClassName('transcriptListItem'),
        area: containerRef.current,
        multiSelectMode: false,
        autoScrollSpeed: 10,
        onDragMove: props => {
          if (selectedListItemsRef.current) {
            selectedListItemsRef.current = null;
            dragSelectRef.current.clearSelection();
          }
        },
        callback: (selectedItem, event) => {
          let selectedList = selectedListItemsRef.current;
          if (selectedItem.length === 1) {
            event.preventDefault();
            onChangeTranscript(videoTranscriptData[selectedItem[0].getAttribute('data-key')]);
            selectedItem = selectedList;
            dragSelectRef.current.setSelection(selectedItem);
            selectedList = selectedItem ? selectedItem.map(elem => elem.getAttribute('data-key')) : [];
          } else {
            selectedItem = selectedItem.sort((a, b) => {
              const first = a.getAttribute('data-key');
              const second = b.getAttribute('data-key');
              if(first < second) {
                return -1;
              }
              if(first > second) {
                return 1;
              }
              return 0;
            });

            selectedList = selectedItem.map(elem => elem.getAttribute('data-key'));
            selectedListItemsRef.current = selectedItem;
          }
            // selectedItem.forEach((item, index) => {
            //   if(index > 0) {
            //     const activeIndex = item.getAttribute('data-key');
            //     const latestIndex = selectedItem[index - 1].getAttribute('data-key');
            //     if(Math.abs(latestIndex - activeIndex) > 1) {
            //       if (selectedItem.length > 2 && index === 1) {
            //         dragSelectRef.current.removeSelection([selectedItem[index - 1]]);
            //         itemData.splice(itemData.length - 1, 1);
            //         itemData.push(item);
            //       } else {
            //         dragSelectRef.current.removeSelection([item]);
            //       }
            //     } else {
            //       itemData.push(item);
            //     }
            //   } else {
            //     itemData.push(item);
            //   }
            // });
          // }
          onSelectTranscript(selectedList.sort());
        }
      });
    }
  }, [containerRef, dragSelectRef, onChangeTranscript, onSelectTranscript, videoTranscriptData]);

  return (
    <Wrapper>
      <TranscriptListWrapper 
        className="transcriptWrapper"
        ref={containerRef}
      >
        {videoTranscriptData.map((item, index) => (
            <TranscriptListItem 
              className="transcriptListItem" 
              key={index}
              data-key={index}
              id={`transcript-item-${index}`}
            >
              <TranscriptListItemContent
                active={activeTranscript && activeTranscript.index === item.index}
              >
                {/* <TranscriptListItemTime>
                  <div>{`${convertSecondsToTimeFormat(item.start, true)} -`}</div>
                  <div>{convertSecondsToTimeFormat((item.start + item.duration).toFixed(2), true)}</div>
                </TranscriptListItemTime> */}
                <TranscriptListItemTitle
                  active={activeTranscript && activeTranscript.index === item.index}
                >{item.text}</TranscriptListItemTitle>
              </TranscriptListItemContent>
            </TranscriptListItem>
          ))}
        </TranscriptListWrapper>
    </Wrapper>
  );
}
export default TranscriptList;