import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import OutsideClick from '../OutsideClick';
import { setAxiosAuthorizationHeader } from '../../Utils/axios';

const Container = styled.div`
  position: relative;
`;

const SelectedBox = styled.div`
  padding: 5px 25px 5px 15px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
  width: 110px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media (max-width: 580px) {
    width: 90px;
    padding: 5px 15px 5px 10px;
    margin-left: 10px;
  }
`;

const DropDownIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
`;

const DropDownContent = styled.div`
  position: absolute;
  left: 0;
  bottom: -5px;
  transform: translateY(100%);
  box-shadow: 0px 0px 14px -2px rgba(0, 0, 0, 0.5);
  min-width: 100%;
  background-color: #fff;
  z-index: 10;
`;
const DropDownItem = styled.div`
  padding: 7px 10px;
  font-size: 16px;
  border-bottom: ${({ hideBorder }) => (hideBorder ? 0 : 1)}px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  display: ${({ disable }) => (disable ? 'none' : 'block')};
  @media (max-width: 580px) {
    padding: 5px 8px;
    font-size: 14px;
  }
`;

const DropDownRadio = ({ data }) => {
  const [isShowDropDown, setIsShowDropDown] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  useEffect(() => {
    const localStorageData = localStorage.getItem('selectedLanguage');
    if (localStorageData) {
      setSelectedData(JSON.parse(localStorageData));
    } else {
      setSelectedData(data.filter((item) => item.code === 'en')[0]);
    }
  }, [data]);
  const contentRef = useRef(null);
  return (
    <Container>
      <SelectedBox
        onClick={() => {
          setIsShowDropDown(!isShowDropDown);
        }}
      >
        {selectedData ? selectedData.title : 'Select Language'}
        <DropDownIcon
          name={isShowDropDown ? 'caret up' : 'dropdown'}
          size={15}
          // color={'#fff'}
          className="dropdown-icon"
        />
      </SelectedBox>
      {isShowDropDown && (
        <>
          <DropDownContent ref={contentRef} isShowDropDown={isShowDropDown}>
            {data.map((item, index) => (
              <DropDownItem
                key={item.id}
                disable={selectedData && item.id === selectedData.id}
                hideBorder={index === data.length - 1}
                onClick={() => {
                  if (!selectedData || (selectedData && item.id !== selectedData.id)) {
                    setSelectedData(item);
                    setIsShowDropDown(false);
                    localStorage.setItem('selectedLanguage', JSON.stringify(item));
                    setAxiosAuthorizationHeader();
                    setTimeout(() => {
                      // window.location.reload();
                      window.location.href = '/';
                    }, 500);
                  }
                }}
              >
                {item.title}
              </DropDownItem>
            ))}
          </DropDownContent>
          <OutsideClick
            elementRef={contentRef}
            handleOutsideClick={() => {
              setIsShowDropDown(false);
              // if (multiple) onSelect();
            }}
            activeOutsideClick={isShowDropDown}
          />
        </>
      )}
    </Container>
  );
};

export default DropDownRadio;
