import React, { useState } from 'react';
import { Container, Header, Input, Button } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import PageHeader from '../../../Components/Header';

const Wrapper = styled(Container)`
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;

const UrlWrapper = styled.div`
  transform: translateY(-50%);
`;

const InputWrapper = styled.div`
  padding: 20px;
`;

const InputField = styled(Input)`
  width: 90%;
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

const ButtonWrapper = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 70px;
  padding: 5px 10px; 
  color: #fff !important;
  font-size: 16px;
  font-weight: 900;
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  background: #f32826 !important
`;

const FieldErrorMessage = styled.div`
  font-size: 12px;
  line-height: 12px;
  margin-top: 2px;
  color: #cc0000;
  font-weight: 300;
`;

const GoButton = ({ onClick, disabled }) => (
  <ButtonWrapper onClick={onClick} disabled={disabled}>
    Go
  </ButtonWrapper>
);

const validUrl = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;

const VideoSearch = ({ location }) => {
  const history = useHistory();
  const [urlText, setUrltext] = useState(null);
  const [error, setError] = useState(null);
  const onClickGo = () => {
    if(urlText.match(validUrl))
      history.push("/video/create", {
        urlString: urlText
      });
    else {
      setError('Please Enter valid url');
      setTimeout(() => setError(null), 5000);
    }
  };
  return (
    <>
      <PageHeader location={location} />
      <Helmet>
        <title>XND</title>
      </Helmet>
      <Wrapper textAlign='center'>
        <UrlWrapper>
          <Header as='h1'>Enter Youtube Link</Header>
          <InputWrapper>
            <InputField
              icon={<GoButton disabled={!urlText} onClick={onClickGo}/>}
              placeholder="Enter URL"
              autoFocus
              onChange={e => setUrltext(e.target.value.trim())}
              defaultValue={urlText}
              onKeyUp={e => {
                if (e.which === 13 && urlText) {
                  onClickGo()
                }
              }}
            />
          </InputWrapper>
          <FieldErrorMessage>{error}</FieldErrorMessage>
        </UrlWrapper>
      </Wrapper>
    </>
  )
};

export default VideoSearch;